export default {
  POSTS_FEED_PLACEHOLDERS_COUNT: 3,
}

export const VirtualizationConfig = {
  VISIBLE_POSTS_COUNT: 5,
  SCROLL_STEP: 1,
  TOP_PADDING: 200,
  APPROXIMATE_COMPONENT_HEIGHT: 580,
}
