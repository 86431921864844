// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatVoiceMessage_container_1_jUN{display:flex;align-items:center;gap:10px}.ChatVoiceMessage_container_1_jUN .ChatVoiceMessage_playButton_1R2CC{display:flex;align-items:center;justify-content:center;padding:10px;width:50px;height:50px;border-radius:50%;background-color:#fff;flex-shrink:0;cursor:pointer}.ChatVoiceMessage_container_1_jUN .ChatVoiceMessage_playButton_1R2CC svg{fill:var(--supster-secondary)}.ChatVoiceMessage_container_1_jUN .ChatVoiceMessage_content_2pt60{display:flex;flex-direction:column;align-items:stretch;gap:5px;width:100%}.ChatVoiceMessage_container_1_jUN .ChatVoiceMessage_content_2pt60 .ChatVoiceMessage_spectrogram_2HwBH{height:30px;width:200px}.ChatVoiceMessage_container_1_jUN .ChatVoiceMessage_content_2pt60 .ChatVoiceMessage_duration_2GH1f{flex-shrink:0;font-size:12px;line-height:15px}", ""]);
// Exports
exports.locals = {
	"container": "ChatVoiceMessage_container_1_jUN",
	"playButton": "ChatVoiceMessage_playButton_1R2CC",
	"content": "ChatVoiceMessage_content_2pt60",
	"spectrogram": "ChatVoiceMessage_spectrogram_2HwBH",
	"duration": "ChatVoiceMessage_duration_2GH1f"
};
module.exports = exports;
