<template>
  <div :class="$style.form" @submit="onSubmit">
    <auth-input
      ref="email"
      :rules="rules.email"
      v-model="formData.email"
      :class="$style.input"
      type="email"
      :title="$t('login.email')"
      mode="inner-title"
      placeholder="..."
      isNewPassword
    />
    <auth-input
      ref="password"
      :rules="rules.password"
      v-model="formData.password"
      :class="$style.input"
      type="password"
      :title="$t('login.password')"
      mode="inner-title"
      placeholder="..."
      isNewPassword
    />
    <auth-input
      v-model="formData.repeatPassword"
      :class="$style.input"
      type="password"
      :title="$t('login.repeatPassword')"
      mode="inner-title"
      placeholder="..."
      isNewPassword
      :forseError="isPasswordMatch || serverError"
    />

    <div :class="$style.buttonContainer">
      <v-button
        size="xl-1"
        color="primary"
        :is-disabled="!isEmptyValid || isInputsValid || !!isPasswordMatch || !!serverError"
        :is-loading="isLoading"
        :class="$style.button"
        @click="onSubmit"
      >
        {{ $t('proceed') }}
      </v-button>
    </div>
    <i18n key="agreement" :class="[$style.text, $style.agreement]" path="agreement" tag="p">
      <template #privacyPolicy>
        <router-link
          @click.native="closeProfileDrawer"
          :to="
            Trans.i18nRoute({
              name: 'Documents',
              params: {
                slug: privacyDocId,
              },
            })
          "
          :class="[$style.link]"
          >{{ $t('withPrivacyPolicy') }}
        </router-link>
      </template>
      <template #rulesPlatform>
        <router-link
          @click.native="closeProfileDrawer"
          :to="
            Trans.i18nRoute({
              name: 'Documents',
              params: {
                slug: userAgreementDocId,
              },
            })
          "
          :class="[$style.link]"
          >{{ $t('withRulesPlatform') }}
        </router-link>
      </template>
    </i18n>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vRequired, vEmail, vPassword } from '@utils/validations'
import VButton from '@elements/VButton.vue'
import AuthInput from '@components/elements/inputs/list/AuthInput.vue'
import Trans from '@plugins/i18n/translation'

export default Vue.extend({
  name: 'RegistrationForm',
  components: {
    VButton,
    AuthInput,
  },

  data() {
    return {
      formData: {
        email: '',
        password: '',
        repeatPassword: '',
      },
      isInputsValid: false,
      isPasswordMatch: '',
      serverError: '',
      isLoading: false,
      Trans,
    }
  },
  computed: {
    ...mapGetters('Countries', ['privacyDocId', 'userAgreementDocId']),
    ...mapGetters('Client', ['clientId']),
    rules() {
      return {
        email: [vRequired(), vEmail()],
        password: [vRequired(), vPassword()],
      }
    },
    isEmptyValid() {
      return !!this.formData.password && !!this.formData.email && !!this.formData.repeatPassword
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Page', ['setPageIsFooter']),
    ...mapActions('Client', ['putClientForRegisitration', 'postClientCode']),
    closeProfileDrawer() {
      this.setDrawer({ name: 'login', isVisible: false })
    },
    async validateInputs() {
      const email = await this.$refs.email.validate()
      const pass = await this.$refs.password.validate()
      return !!email || !!pass
    },
    validatePasswordMatch() {
      if (this.formData.password === this.formData.repeatPassword) {
        this.isPasswordMatch = ''
        return false
      }
      this.isPasswordMatch = this.$t('errors.login.passwordsNotMatch')
      return true
    },
    async onSubmit() {
      this.isInputsValid = (await this.validateInputs()) || this.validatePasswordMatch()
      if (this.isInputsValid) {
        return
      }
      await this.send()
    },
    async send() {
      this.isLoading = true
      const { error, token } = await this.postClientCode({
        email: this.formData.email,
        type: 3,
      })
      if (error) {
        this.serverError = this.$t('errors.login.emailAlreadyInUse')
      } else {
        this.$emit('submit', {
          email: this.formData.email,
          password: this.formData.password,
          emailToken: token,
        })
      }
      this.isLoading = false
    },
  },
  watch: {
    formData: {
      handler() {
        this.isInputsValid = false
        this.isPasswordMatch = ''
        this.serverError = ''
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on('resend-code', () => {
      this.send()
    })
  },
  destroyed() {
    this.$bus.$off('resend-code')
  },
})
</script>

<style lang="scss" module>
.form {
  grid-auto-rows: min-content;
  grid-auto-flow: row;
}

.caption {
  padding: 15px;
  color: rgba(black, 0.5);
  font-size: 14px;
  text-align: left;
}

.inner {
  display: grid;
  row-gap: 9px;
}

.input {
  margin: 0;
  padding: 0;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

.buttonContainer {
  padding: 15px;
}

.button {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 9px;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.agreement {
  padding: 15px 45px;
  text-align: center;
  border-top: 1px solid $secondary-light;
  box-shadow: inset 0 -1px 0 #e5e3e3;
}

.link {
  color: black;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
</style>
