/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M18 9L0 0l4.605 9L0 18l18-9zM5.495 8.545L2.278 2.257 15.764 9 2.278 15.743l3.217-6.287L5.728 9l-.233-.455z"/>'
  }
})
