// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CreateChannelModal_content_3twiK{display:grid;row-gap:20px}.CreateChannelModal_file_3ZTRl{width:100%;max-height:200px}", ""]);
// Exports
exports.locals = {
	"content": "CreateChannelModal_content_3twiK",
	"file": "CreateChannelModal_file_3ZTRl"
};
module.exports = exports;
