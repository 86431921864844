/* eslint-disable camelcase */
import PromotionalApi from '@services/promotional'
import PostsApi from '@services/post'
import ChatsApi from '@modules/chat/api/chat'
import { Actions } from './types'

const actions: Actions = {
  async getPromotionalList({ commit, state }, { app_id, limit, scroll }) {
    try {
      const promotionalList = await PromotionalApi.getPromotionals({
        app_id,
        limit,
        page: state.page,
      })
      commit('setPromotionalList', promotionalList)
      if (scroll) {
        promotionalList?.length ? scroll.loaded() : scroll.complete()
      }
    } catch (error) {
      commit('setIsDenied', true)
    }
  },
  async getPromotional({ commit }, id) {
    try {
      commit('Page/setPageLoading', false, { root: true })
      const promotional = await PromotionalApi.getPromotional(id)
      commit('setPromotional', promotional)
      return promotional
    } catch (error) {
      commit('setIsDenied', true)
      return null
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async getPromotionalMedia({ commit, dispatch, state }, { scroll }: { scroll: InfiniteType }) {
    try {
      let media
      if (state.modal.type === 'posts') {
        media = await PostsApi.getPosts({
          promotional_page_id: state.promotional?.id,
          page: state.modal[state.modal.type].page,
          limit: 30,
        })
      } else {
        const { data } = await ChatsApi.getChats({
          promotional_page_id: state.promotional?.id,
          page: state.modal[state.modal.type].page,
          limit: 30,
        })
        media = data
      }
      commit('setPromotionalMedia', media)
      if (scroll) {
        media.length ? scroll.loaded() : scroll.complete()
      }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    }
  },
}

export default actions
