import { DataType as AppType } from '@services/app/types'

export enum EventType {
  CREATED = 'created',
  UPDATED = 'updated',
  REMOVED = 'removed',
  READ = 'read',
  PAID = 'paid',
  NOT_PAID = 'not_paid',
  CONVERTED = 'converted',
}

export enum EntityType {
  STORY = 'story',
  CHANNEL = 'channel',
  CHANNEL_MESSAGE = 'channel_message',
  ACCESS_TOKEN = 'access_token',
  ORDER = 'order',
  MEDIA_OBJECT = 'media_object',
}

export interface WebSocketMessage {
  data: {
    event_type: EventType
    event_sub_type: string
    entity_type: EntityType
    entities: Array<any>
    apps: null | Array<AppType>
    new_message_channels_count: number
  }
}
