export default {
  desktopPageLabels: {
    promotional: { label: 'Choose Subscription', desc: 'Unveil all GPT Features' },
    chat: { label: '{appName}', desc: 'Model: {version}' },
    supportchat: { label: 'Supster Support Chat', desc: '' },
    chats: { label: 'Chats List', desc: '' },
    profile: { label: 'Profile', desc: '' },
    subscriptions: { label: 'Subscriptions', desc: '' },
    subscription: { label: 'Subscription', desc: '' },
    orders: { label: 'Orders', desc: '' },
    settings: { label: 'Settings', desc: '' },
    documents: { label: 'Documents', desc: '' },
    feed: { label: 'Feed', desc: '' },
    search: { label: 'Search', desc: '' },
    post: { label: 'Post', desc: '' },
    verification: {
      label: 'Verification',
      desc: '',
    },
    clientedit: { label: 'Client edit', desc: '' },
    notifications: { label: 'Notifications', desc: '' },
    postcreate: {
      label: 'Post creating',
      desc: '',
    },
  },
}
