var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.tagName,{tag:"component",class:[
    _vm.$style.container,
    _vm.$style[_vm.size],
    _vm.$style[_vm.color],
    _vm.isDisabledPadding && _vm.$style.noPadding,
    _vm.isDiv && _vm.$style.div,
    _vm.isCircle && _vm.$style.circle,
  ],attrs:{"type":_vm.type,"disabled":(_vm.pageLoading && _vm.isLoading) || _vm.isDisabled},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click', $event)}}},[_vm._t("default"),_c('overlay-loading-loader',{attrs:{"is-loading":_vm.isLoading,"delay":0,"size":_vm.loaderSize}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }