<template>
  <VGrid :length="chatIdsLength" class-name="chats" @infinite="loadChats">
    <VChatPreview
      v-for="id in chatIds"
      :key="`chat-${id}`"
      :id="id"
      :ref="`chat-${id}`"
      :chat="getVisibleChat(id)"
      @joined="onJoinChat"
    />
    <template #placeholder>
      <PChatPreview v-for="i in 30" :key="i" />
    </template>
  </VGrid>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'
import { partition } from 'lodash'
import VGrid from '@layouts/VGrid.vue'
import PChatPreview from '@placeholders/PChatPreview.vue'
import Config from '@config/index'
import { getChatIds } from '@helpers/chats'
import { addSubscribedChat } from '@modules/chats/services'
import { Tab } from '@modules/profile/constants'
import VChatPreview from '@layouts/VChatPreview.vue'

let observer = null

export default Vue.extend({
  name: 'SearchChats',
  components: { PChatPreview, VChatPreview, VGrid },
  data() {
    return {
      cacheHandlersMapId: null,
    }
  },
  computed: {
    ...mapGetters('Search/Chats', ['chatIds', 'chatIdsLength', 'visibleChats']),
    ...mapGetters('Search', ['searchQuery']),
  },
  methods: {
    ...mapActions('Search/Chats', ['getChats']),
    ...mapMutations('Profile', ['setProfileContentItem']),
    ...mapMutations('Chats', ['deleteChatFromChats']),
    ...mapMutations('Search/Chats', [
      'resetVisibleChats',
      'resetChats',
      'setChats',
      'setChat',
      'deleteVisibleChats',
      'addVisibleChats',
    ]),
    async loadChats(scroll) {
      const chats = await this.getChats(scroll)
      this.connectObserver(getChatIds(chats))
    },
    getVisibleChat(id) {
      return this.visibleChats[id]
    },
    addCacheHandler() {
      this.cacheHandlersMapId = this.$cacheManager.add({
        regExpURL: Config.URLRegExps.SEARCH_POSTS,
        cacheHandlers: [(chats) => this.setChats(chats ?? [])],
      })
    },
    deleteCacheHandler() {
      if (this.cacheHandlersMapId) {
        this.$cacheManager.remove([this.cacheHandlersMapId])
      }
    },
    async refreshSearch() {
      this.resetChats()
      await this.loadChats()
    },
    onInputSearchQuery: debounce(async function () {
      await this.refreshSearch()
    }, 250),
    observerCallback(entries) {
      const [activeChatElements, inactiveChatElements] = partition(
        entries,
        (entry) => entry.isIntersecting
      )
      this.deleteVisibleChats(inactiveChatElements.map((entry) => Number(entry.target.id)))
      this.addVisibleChats(activeChatElements.map((entry) => Number(entry.target.id)))
    },
    observeChatElement(chatId) {
      const itemRef = `chat-${chatId}`
      const observedElement = this.$refs[itemRef][0].$el
      if (observedElement) {
        observer.observe(this.$refs[itemRef][0].$el)
      }
    },
    connectObserver(chatIds) {
      if (!observer) {
        observer = new IntersectionObserver(this.observerCallback, { threshold: 0 })
      }
      chatIds.forEach(this.observeChatElement)
    },
    onJoinChat(chat) {
      this.setProfileContentItem({ contentItem: chat, tab: Tab.CHATS })
      this.setChat(chat)
      if (chat.is_subscribed) {
        addSubscribedChat({ chat, store: this.$store })
      } else {
        this.deleteChatFromChats(chat.id)
      }
    },
  },
  mounted() {
    this.$bus.$on('search-input-input', this.onInputSearchQuery)
    this.$bus.$on('search-input-close', this.onInputSearchQuery)
    this.connectObserver(this.chatIds)
  },
  beforeDestroy() {
    this.deleteCacheHandler()
    this.resetVisibleChats()
    this.$bus.$off('search-input-input')
    this.$bus.$off('search-input-close')
  },
})
</script>
