import { drawerNames } from '@config/drawer-names'
import { Module } from './types'

import mutations from './mutations'
import getters from './getters'

const Drawers: Module = {
  state: () => ({
    drawers: {
      auth: false,
      noname: false,
      payment: false,
      phone: false,
      cart: false,
      'comment-edit': false,
      'comment-report': false,
      login: false,
      'post-menu': false,
      'upload-menu': false,
      'chats-filter': false,
      'claim-accepted': false,
      'claim-confirm': false,
      blocking: false,
      'access-denied': false,
      permission: false,
      'chat-share': false,
      [drawerNames.LOCALES]: false,
    },
    params: null,
  }),
  mutations,
  getters,
  namespaced: true,
}

export default Drawers
