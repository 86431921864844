import { Actions } from '@store/cart/types'

const actions: Actions = {
  async postCart({ dispatch, commit }, params) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const order = await dispatch('Order/postOrder', params, { root: true })
      commit('Order/setOrder', order, { root: true })
      return order
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
