// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatGptWelcome_container_1CIfH{display:flex;flex-direction:column;gap:21px;align-items:center;justify-content:center;width:100%;padding:27px 12px 12px 12px;color:var(--supster-secondary)}.ChatGptWelcome_container_1CIfH .ChatGptWelcome_message_1m92R{font-size:15px;line-height:22px;white-space:break-spaces;text-align:center;word-break:break-word}.ChatGptWelcome_container_1CIfH .ChatGptWelcome_block_2Fawd{display:flex;flex-direction:column;gap:15px;align-items:center;width:100%}.ChatGptWelcome_container_1CIfH .ChatGptWelcome_block_2Fawd .ChatGptWelcome_title_3MRwz{font-weight:500;font-size:15px;line-height:22px}.ChatGptWelcome_container_1CIfH .ChatGptWelcome_block_2Fawd .ChatGptWelcome_items_22ZQ6{display:flex;flex-direction:column;gap:3px;width:100%}", ""]);
// Exports
exports.locals = {
	"container": "ChatGptWelcome_container_1CIfH",
	"message": "ChatGptWelcome_message_1m92R",
	"block": "ChatGptWelcome_block_2Fawd",
	"title": "ChatGptWelcome_title_3MRwz",
	"items": "ChatGptWelcome_items_22ZQ6"
};
module.exports = exports;
