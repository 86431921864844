/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attention-bold': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M13.78 10.691l-5.39-8.92c-.62-1.03-2.17-1.03-2.79 0l-5.38 8.92c-.62 1.03.15 2.31 1.39 2.31h10.78c1.24 0 2.01-1.28 1.39-2.31zM7 12.001c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm1-4c0 .55-.45 1-1 1s-1-.45-1-1v-3c0-.55.45-1 1-1s1 .45 1 1v3z"/>'
  }
})
