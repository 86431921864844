/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { GetParams } from './types'
import { Post } from '../../common-types/post'

export default {
  getPosts: async (params: GetParams): Promise<Post[]> => {
    const { data }: ResType<Post[]> = await axiosJWT.get('posts', { params })
    return data === null ? [] : data
  },
  getPost: async (id: number): Promise<Post> => {
    const { data }: ResType<Post> = await axiosJWT.get(`posts/${id}`)
    return data
  },
  postPost: async (post: Post): Promise<Post> => {
    const { data }: ResType<Post> = await axiosJWT.post('posts', post)
    return data
  },
  putPost: async (id: number, post: Post): Promise<Post> => {
    const { data }: ResType<Post> = await axiosJWT.put(`posts/${id}`, post)
    return data
  },
  deletePost: async (id: number): Promise<boolean> => {
    const { success }: { success: boolean } = await axiosJWT.delete(`posts/${id}`)
    return success
  },
  postPostLike: async (id: number): Promise<Post> => {
    const { data }: ResType<Post> = await axiosJWT.post(`posts/${id}/like`)
    return data
  },
  postPostUnlike: async (id: number): Promise<Post> => {
    const { data }: ResType<Post> = await axiosJWT.post(`posts/${id}/unlike`)
    return data
  },
}
