import { getApps, initializeApp } from 'firebase/app'
import { getMessaging, isSupported, Messaging, onMessage } from 'firebase/messaging'

export const vapidKey =
  'BLvr5t-cUA_oXJ61eWckO4RIqfJYtGxOwQFo0ITTbh78cu5SO0V-aWXkeuz4sfR2-F0HuWcsBR2RvT7Q5tcLVl8'

const firebaseConfig = {
  apiKey: 'AIzaSyCQRgDmZSoEHzVkqPSggRsZu2GrH39A8vY',
  authDomain: 'supster-65ea7.firebaseapp.com',
  projectId: 'supster-65ea7',
  storageBucket: 'supster-65ea7.appspot.com',
  messagingSenderId: '794897323024',
  appId: '1:794897323024:web:3d8d5bba371b259fef6695',
  measurementId: 'G-LHMDMB6T91',
}

const apps = getApps()
const app = !apps.length ? initializeApp(firebaseConfig) : apps[0]

export async function getMessagingApp(): Promise<Messaging | null> {
  return (await isSupported()) ? getMessaging(app) : null
}

export const listenMessaging = (context: any) => {
  onMessage(getMessaging(app), (payload) => {
    context.store.commit('Notifications/setNotification', payload)
  })
}

export default { getMessagingApp, vapidKey }
