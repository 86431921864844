/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'openai-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.995 9.075l-.25-.14-.69-.4v-1.08l.94-.54.14.08.79.46v1.08l-.94.54h.01zm4.11-6.35c-.29 0-.57.04-.84.12h-.03l-4.18 2.41v1.36l2.63-1.52c.11-.06.25-.06.36 0l4.22 2.43c.47-.53.74-1.21.74-1.92 0-1.6-1.3-2.9-2.89-2.9l-.01.02zm-10.22 4.99l4.23 2.44 1.16-.67-.76-.44-1.87-1.08a.353.353 0 01-.18-.31v-4.86c-.19-.04-.39-.07-.59-.07-1.58 0-2.88 1.3-2.88 2.9 0 .78.33 1.54.89 2.09zm12.21.56l-4.24-2.45-1.16.67.76.44 1.87 1.08c.11.06.18.18.18.31v4.86c.19.04.39.07.59.07 1.6 0 2.89-1.3 2.89-2.9 0-.79-.33-1.54-.89-2.09v.01zm-8.92-.82l1.16.67v-3.06c0-.13.07-.24.18-.31l4.21-2.43a2.877 2.877 0 00-2.74-1.97c-1.34 0-2.49.9-2.81 2.2V7.455zm-1.3 5.81c.28 0 .55-.04.81-.12l4.23-2.44v-1.34l-.76.44-1.87 1.08c-.05.03-.12.05-.18.05s-.12-.02-.18-.05l-4.19-2.45c-.47.53-.74 1.21-.74 1.92 0 1.6 1.3 2.9 2.89 2.9l-.01.01zm6.92-4.72l-1.16-.67v3.04c0 .13-.07.24-.18.31l-4.22 2.43a2.885 2.885 0 002.75 1.99c1.34 0 2.49-.9 2.81-2.2V8.545z" _fill="#000"/>'
  }
})
