import { union } from 'lodash'
import Vue from 'vue'
import { replaceEntityMediaObject } from '@helpers/media-objects'
import { getPostIds } from '@helpers/posts'
import { PostIds } from '@common-types/post'
import { Mutations } from '../types'
import FeedCache from '../services/cache'

const mutations: Mutations = {
  setFeed(state, posts) {
    FeedCache.setItems(posts)
    state.postIds = getPostIds(posts)
    state.blockedPostCount = 0
    state.page = 2
  },
  pushFeed(state, posts) {
    FeedCache.addItems(posts)
    state.postIds = union(state.postIds, getPostIds(posts))
    state.page += 1
  },
  setFeedNewPost(state, post) {
    FeedCache.setItem(post)
    if (state.startIndex === 0) {
      state.visiblePosts.unshift(post)
    }
    state.postIds.unshift(post.id)
  },
  setFeedPost(state, post) {
    const oldPost = FeedCache.getItem(post.id)
    const oldPostMediaObjects = oldPost?.media_objects
    const newPostMediaObjects = post.media_objects ?? oldPostMediaObjects ?? []
    const newPost = { ...post, media_objects: newPostMediaObjects }
    FeedCache.setItem(newPost)
    state.visiblePosts = state.visiblePosts.map((item) => (item.id === post.id ? newPost : item))
  },
  deleteFeedPost(state, postId) {
    FeedCache.deleteItem(postId)
    state.postIds = state.postIds.filter((id) => id !== postId)
    state.visiblePosts = state.visiblePosts.filter((item) => item.id !== postId)
    Vue.delete(state.componentsHeights, postId)
  },
  setIntersectedPostId(state, postId) {
    state.intersectedPostId = postId
  },
  replaceFeedMediaObject(state, mediaObject) {
    FeedCache.replaceMediaObject(mediaObject)
    state.visiblePosts = state.visiblePosts.map((post) =>
      replaceEntityMediaObject(post, mediaObject)
    )
  },
  resetFeed(state) {
    state.postIds = []
    state.intersectedPostId = null
    state.blockedPostCount = 0
    state.page = 1
    state.visiblePosts = []
    state.componentsHeights = {}
  },
  setFeedPage(state, page) {
    state.page = page
  },
  setBlockedPostCount(state, count) {
    state.blockedPostCount = count
  },
  setVisiblePosts(state, postIds: PostIds) {
    state.visiblePosts = FeedCache.getItems(postIds)
  },
  addComponentHeight(state, { postId, height }) {
    Vue.set(state.componentsHeights, postId, height)
  },
  setStartIndex(state, index) {
    state.startIndex = index
  },
}

export default mutations
