import { Module } from '../types'
import getters from './getters'
import mutations from './mutations'

const module: Module = {
  state: () => ({
    toasts: [],
  }),
  getters,
  mutations,
  namespaced: true,
}

export default module
