<template>
  <v-popover
    :is-shown="isSelected"
    :class="[$style.message, isSelected && $style.selectedMessage]"
    :arrow-padding="20"
    container=".chat-popover-container"
    :placement="placement"
    :triggers="[]"
    :show-triggers="[]"
    :hide-triggers="[]"
    :popper-triggers="[]"
    :popperShowTriggers="[]"
    :popper-hide-triggers="[]"
    instant-move
    shift
    shift-cross-axis
    :boundary="boundary"
    :auto-hide="false"
  >
    <chat-message
      :key="`message-${message ? message.id || message.tempId : ''}`"
      :message="message"
      :markdown-body="markdownBody"
      :is-my-message="isMyMessage"
      :is-selected="isSelected"
      v-longpress="{ handler: selectMessage, args: message, defaultHandler: onClose }"
      @contextmenu.native.prevent="selectMessage(message)"
    />
    <template #content>
      <chat-message-menu
        :isMyMessage="getIsMyMessage(message)"
        :isPinned="isPinned"
        @edit="onEdit"
        @pin="onPin"
        @unpin="onUnpin"
        @reply="onReply"
        @delete="onDelete"
        @click-item="onClose"
      />
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VPopover from '@elements/VPopover.vue'
import ChatMessageMenu from './ChatMessageMenu.vue'
import ChatMessage from './ChatMessage.vue'

export default Vue.extend({
  name: 'ChatMessagePopover',

  components: {
    VPopover,
    ChatMessage,
    ChatMessageMenu,
  },

  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    isMyMessage: {
      type: Boolean,
      default: false,
    },
    boundary: {
      type: Element,
      default: null,
    },
    markdownBody: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters('Client', ['clientId']),
    ...mapGetters('Chat', [
      'chat',
      'selectedMessageId',
      'hasPinnedMessage',
      'isChannel',
      'getIsMyMessage',
      'localId',
    ]),
    isPinned() {
      return this.hasPinnedMessage(this.message.id ?? null)
    },
    placement() {
      return this.isMyMessage ? 'bottom-end' : 'bottom-start'
    },
    notChannelOwner() {
      return this.isChannel && this.clientId !== this.chat.client?.id
    },
    isSelected() {
      return this.selectedMessageId === this.message.id
    },
  },
  methods: {
    ...mapActions('Chat', ['deleteChatMessage', 'putChatMessage']),
    ...mapMutations('Page', ['setPageLoading']),
    ...mapMutations('Chat', [
      'setReferencedMessageId',
      'setIsReply',
      'setIsEdit',
      'pushMessage',
      'deletePinnedMessage',
      'decreasePinnedMessagesPointer',
      'increasePinnedMessagesPointer',
      'unshiftPinnedMessage',
      'resetPinnedMessagesPointer',
    ]),
    selectMessage(message) {
      const canNotBeSelected = !message || this.selectedMessageId || this.notChannelOwner

      if (canNotBeSelected) {
        return
      }
      this.$emit('select', message)
    },
    onEdit() {
      this.setReferencedMessageId(this.message?.id ?? null)
      this.setIsEdit(true)
    },
    onReply() {
      this.setIsEdit(false)
      this.setReferencedMessageId(this.message?.id ?? null)
      this.setIsReply(true)
    },
    async onPin() {
      const message = await this.putChatMessage({
        id: this.message?.id,
        local_id: this.localId,
        pinned_status: 1,
      })
      if (message) {
        this.unshiftPinnedMessage(message)
        this.resetPinnedMessagesPointer()
      }
    },
    async onUnpin() {
      const message = await this.putChatMessage({
        id: this.message?.id,
        local_id: this.localId,
        pinned_status: 0,
      })
      if (message) {
        this.deletePinnedMessage(message.id)
        this.decreasePinnedMessagesPointer()
      }
    },
    async onDelete() {
      if (!this.selectedMessageId) {
        return
      }
      this.setPageLoading(true)
      await this.deleteChatMessage(this.selectedMessageId)
      this.setPageLoading(false)
    },
    onClose() {
      this.$emit('close-menu')
    },
  },
})
</script>

<style lang="scss" module>
.selectedMessage {
  z-index: 2;
}
</style>
