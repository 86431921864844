/* eslint-disable camelcase */

export enum ConvertStatuses {
  CONVERTED = 1,
  CONVERTING = 0,
}

export interface MediaObject {
  client_id: number
  content_url: string
  convert_status: ConvertStatuses
  dash: string | null
  duration: number | null
  height: number | null
  hls: string | null
  id: number
  mime: string
  preview_url: string | null
  width: number | null
}

export type MediaObjects = MediaObject[]

export type FileParams = {
  width: number | null
  height: number | null
  duration?: number | null
}

export type PostMediaCallback = ({ progress, fileId }: { progress: number; fileId: string }) => void

export interface PostMediaParams {
  file: File
  fileId: string
  fileResolution: FileParams
  /* eslint-disable no-shadow */
  callback?: PostMediaCallback
}
