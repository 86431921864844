// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoadingLoader_icon_3ZJtg{fill:var(--supster-secondary)}", ""]);
// Exports
exports.locals = {
	"icon": "LoadingLoader_icon_3ZJtg"
};
module.exports = exports;
