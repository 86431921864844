<template>
  <div :class="$style.form" @submit="onSubmit">
    <mask-input
      v-if="isPhoneType"
      v-model="formData.phone"
      :class="$style.input"
      type="tel"
      :title="$t('phone')"
      mode="inner-title"
      :mask="mask"
      masked
      :tokens="symbolsToken"
      isAuthInput
      placeholder="..."
    />
    <auth-input
      v-if="isEmailType"
      v-model="formData.email"
      :class="$style.input"
      type="email"
      :title="$t('login.email')"
      mode="inner-title"
      placeholder="..."
    />
    <auth-input
      v-model="formData.password"
      :class="$style.input"
      type="password"
      :title="$t('login.password')"
      mode="inner-title"
      placeholder="..."
      :forseError="serverError"
    />

    <div @click="$emit('recovery')" :class="$style.recovery">
      {{ $t('login.forgotPassword') }}
    </div>
    <div :class="$style.buttonContainer">
      <v-button
        size="xl-1"
        color="primary"
        :is-disabled="!isValid || !!serverError"
        :is-loading="isLoading"
        :class="$style.button"
        @click="onSubmit"
      >
        {{ $t('login.enter') }}
      </v-button>
    </div>
    <i18n key="agreement" :class="[$style.text, $style.agreement]" path="agreement" tag="p">
      <template #privacyPolicy>
        <router-link
          @click.native="closeProfileDrawer"
          :to="
            Trans.i18nRoute({
              name: 'Documents',
              params: {
                slug: privacyDocId,
              },
            })
          "
          :class="[$style.link]"
          >{{ $t('withPrivacyPolicy') }}
        </router-link>
      </template>
      <template #rulesPlatform>
        <router-link
          @click.native="closeProfileDrawer"
          :to="
            Trans.i18nRoute({
              name: 'Documents',
              params: {
                slug: userAgreementDocId,
              },
            })
          "
          :class="[$style.link]"
          >{{ $t('withRulesPlatform') }}
        </router-link>
      </template>
    </i18n>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MaskInput from '@elements/inputs/list/MaskInput.vue'
import VButton from '@elements/VButton.vue'
import AuthInput from '@components/elements/inputs/list/AuthInput.vue'
import Trans from '@plugins/i18n/translation'

export default Vue.extend({
  name: 'LoginForm',
  components: {
    MaskInput,
    VButton,
    AuthInput,
  },
  props: {
    formType: String,
  },
  data() {
    return {
      formData: {
        phone: '',
        password: '',
        email: '',
      },
      serverError: '',
      isLoading: false,
      symbolsToken: {
        '#': {
          pattern: /[0-9]/,
        },
      },
      Trans,
    }
  },
  computed: {
    ...mapGetters('Countries', ['privacyDocId', 'userAgreementDocId']),
    ...mapGetters('Page', ['pageIsFooter']),

    isEmailType() {
      return this.formType === 'email'
    },
    isPhoneType() {
      return this.formType === 'phone'
    },
    mask() {
      // return ['+# (###) ### ####']
      return ['+#######################']
    },
    isValid() {
      if (this.isEmailType) {
        return !!this.formData.password && !!this.formData.email
      }
      return !!this.formData.password && !!this.formData.phone
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapActions('Client', ['postClientLogin']),
    ...mapMutations('Page', ['setPageIsFooter']),

    async onSubmit() {
      this.isLoading = true
      const { error } = await this.postClientLogin({
        identifier: this.formData.phone || this.formData.email,
        password: this.formData.password,
      })
      if (error) {
        this.serverError = this.isEmailType
          ? this.$t('errors.login.loginEmail')
          : this.$t('errors.login.loginPhone')
      } else {
        this.$emit('submit')
      }
      this.isLoading = false
    },
  },
  watch: {
    formData: {
      handler() {
        this.serverError = ''
      },
      deep: true,
    },
  },
})
</script>

<style lang="scss" module>
.form {
  grid-auto-rows: min-content;
  grid-auto-flow: row;
}

.caption {
  padding: 15px;
  color: rgba(black, 0.5);
  font-size: 14px;
  text-align: left;
}

.inner {
  display: grid;
  row-gap: 9px;
}

.input {
  margin: 0;
  padding: 0;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

.buttonContainer {
  padding: 15px;
}

.button {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 9px;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.agreement {
  padding: 15px 45px;
  text-align: center;
  border-top: 1px solid $secondary-light;
  box-shadow: inset 0 -1px 0 #e5e3e3;
}

.link {
  color: black;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.recovery {
  display: inline-block;
  margin-top: 15px;
  padding: 0 15px;
  font-size: 12px;
  line-height: 18px;
  cursor: pointer;

  text-decoration-line: underline;
}
</style>
