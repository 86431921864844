import { Mutations } from './types'

const mutations: Mutations = {
  setCountries(state, countries) {
    state.countries = countries
  },
  setCurrentCountry(state, country) {
    state.currentCountry = country
  },
}

export default mutations
