// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Search_section_1V31H{display:grid;grid-template-rows:1fr;height:100%}.Search_tabs_1DBMs{position:sticky;box-shadow:inset 0 -1px 0 #e5e3e3}.Search_content_2MrHQ{display:grid;height:100%;row-gap:9px}.Search_post_322Mk:nth-child(24n+1),.Search_post_322Mk:nth-child(24n-10){grid-row:span 2;grid-column:span 2}.Search_noData_1nUku{position:absolute!important;top:50%;left:50%;margin-top:40px;transform:translate(-50%,-50%)}", ""]);
// Exports
exports.locals = {
	"section": "Search_section_1V31H",
	"tabs": "Search_tabs_1DBMs",
	"content": "Search_content_2MrHQ",
	"post": "Search_post_322Mk",
	"noData": "Search_noData_1nUku"
};
module.exports = exports;
