import { SubComment } from '@services/comments/types'
import { Thread } from '../types'
import { ConfiguredParams, Keys } from './types'

type Params<T extends Keys> = ConfiguredParams<'thread' | T>

export const createThread = (parentId: SubComment['id']): Thread => ({
  parentId,
  page: 1,
  isVisible: false,
  addedSubCommentsCount: 0,
  addedSubCommentIds: [],
  deletedSubCommentsCount: 0,
  hasMore: true,
})

export const addSubCommentId = ({ thread, subCommentId }: Params<'subCommentId'>): Thread => ({
  ...thread,
  isVisible: true,
  addedSubCommentsCount: thread.addedSubCommentsCount + 1,
  addedSubCommentIds: [...thread.addedSubCommentIds, subCommentId],
})

export default {
  createThread,
  addSubCommentId,
}
