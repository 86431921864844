<template>
  <div v-if="isMidjourney" :class="$style.container">
    <VButton
      v-for="(action, index) of actions"
      :key="index"
      color="primary"
      @click="onClick(action)"
    >
      {{ action }}
    </VButton>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import { hasMedia, isMyMessage } from '@modules/messages/services'
import { midjourneyActionsList } from '../config'

export default Vue.extend({
  name: 'ChatMessageMidjourneyActions',
  components: { VButton },
  props: {
    message: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    actions() {
      return midjourneyActionsList
    },
    ...mapGetters('Chat', ['isChatGPTBot']),
    isMyMessage() {
      return isMyMessage({ message: this.message, store: this.$store })
    },
    isMidjourney() {
      return (
        !this.isMyMessage &&
        this.isChatGPTBot &&
        hasMedia(this.message) &&
        this.message?.action_allowed
      )
    },
  },
  methods: {
    ...mapActions('Chat', ['postChatMessage']),
    ...mapMutations('Chat', ['setReferencedMessageId']),
    onClick(action) {
      this.setReferencedMessageId(this.message.id)
      this.$bus.$emit('send-midjourney-action', action)
    },
  },
})
</script>

<style module lang="scss">
.container {
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: 5px;
  gap: 5px;
}
</style>
