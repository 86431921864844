/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'card': {
    width: 30,
    height: 22,
    viewBox: '0 0 30 22',
    data: '<path pid="0" d="M26 0H4a4 4 0 00-4 4v14a4 4 0 004 4h22a4 4 0 004-4V4a4 4 0 00-4-4zm2 18c0 1.103-.897 2-2 2H4c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2h22c1.103 0 2 .897 2 2v14zm-3-9H5V5h20v4zM4 17h4v1H4v-1zm6 0h4v1h-4v-1zm6 0h4v1h-4v-1zm6 0h4v1h-4v-1z" _fill="#F20054"/>'
  }
})
