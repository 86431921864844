import md5 from 'md5'
import { checker } from '@helpers/index'
import { Client } from '@services/client/types'
import { Chat, ChatType, TemplateChat } from '@common-types/chat'

export const createChat = (
  params?: Omit<Partial<Chat>, 'id'> & {
    client_id?: Client['id']
    is_prompt?: boolean
  }
): TemplateChat => ({
  type: params?.type ?? ChatType.APP_SUPPORT_CHAT,
  temp: true,
  id: md5(Math.random().toString()),
  ...params,
})
export const isAppSupportChat = checker(ChatType.APP_SUPPORT_CHAT)
export const isAppSupportBung = (templateChat: TemplateChat | Chat): boolean =>
  isAppSupportChat(templateChat.type) && 'temp' in templateChat
export const needInitAppSupportChat = (chat: TemplateChat): boolean =>
  isAppSupportBung(chat) && chat.is_accessible === undefined

export const hasUnreadMessages = (chat: Chat): boolean =>
  chat.channel_subscription?.new_message_count > 0
