<template>
  <svg
    viewBox="0 0 58 58"
    xmlns="http://www.w3.org/2000/svg"
    :class="[$style.container, $style[size], isActive && $style.active]"
  >
    <g fill="none" fill-rule="evenodd">
      <circle :class="$style.mainCircle" fill="#E2264D" cx="29" cy="29" r="2" />
      <g v-for="i in 7" :key="i" :class="[$style.circles, $style[`circles${i}`]]">
        <circle :class="[$style.firstCircle, $style[color]]" cx="29" cy="29" r="4" />
        <circle :class="[$style.secondCircle, $style[color]]" cx="29" cy="29" r="4" />
      </g>
      <path
        :class="[$style.heart, $style[color]]"
        stroke="none"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        stroke-miterlimit="10"
        stroke-linejoin="round"
        d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94
        43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
      />
    </g>
  </svg>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VLikeIcon',
  props: {
    size: String,
    strokeWidth: {
      default: '2',
      type: String,
    },
    color: {
      default: 'primary',
      type: String,
    },
    isActive: Boolean,
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  align-self: center;
  justify-self: center;
  overflow: visible;

  &.sm {
    width: 18px;
    height: 18px;
  }

  &.xl {
    width: 24px;
    height: 24px;
  }
}

.heart {
  transform: scale(0.2);
  transform-origin: center;
  transition: transform $transition * 3, fill $transition * 2;
  animation: animate-heart-inactive $transition * 3 forwards;

  &.default {
    fill: transparent;
    stroke: currentColor;
  }

  &.secondary {
    fill: currentColor;
  }

  &.primary {
    fill: currentColor;
  }
}

.mainCircle {
  transform-origin: 50% 50%;
  opacity: 0;
  transition: opacity $transition;
}

.circles {
  opacity: 0;
}

.firstCircle,
.secondCircle {
  transform-origin: center;

  &.default {
    fill: currentColor;
  }

  &.secondary {
    fill: $secondary;
  }

  &.primary {
    fill: $primary-like-dark;
  }
}

.active {
  .heart {
    &.default {
      fill: currentColor;
    }

    &.secondary {
      fill: $secondary;
    }

    &.primary {
      fill: $primary-like-dark;
    }
  }

  .mainCircle {
    animation: animate-main-circle $transition * 3 forwards $transition * 0.5;
  }

  .circles {
    opacity: 1;
    transition: $transition opacity;
  }
}

/* stylelint-disable */

// Cosines and sines for angles from 0 to 6 * (360 / 7)
// Angles: 0, 51, 103, 154, 206, 258, 309

$cosines: (1, 0.62, -0.22, -0.89, -0.89, -0.2, 0.629);
$sines: (0, 0.77, 0.97, 0.43, -0.43, -0.97, -0.77);

$first-circle-distance: 170px;
$second-circle-distance: 230px;

@for $i from 1 through 7 {
  $cos: nth($cosines, $i);
  $sin: nth($sines, $i);

  .active .circles#{$i} {
    .firstCircle {
      opacity: 0;
      transform: scale(0.2) translate($first-circle-distance * $sin, $first-circle-distance * $cos);
      transition: $transition * 6 transform $transition * 0.7,
        $transition * 6 opacity cubic-bezier(0.55, -0.33, 0.35, 1.47);
    }

    .secondCircle {
      opacity: 0;
      transform: scale(0) translate($second-circle-distance * $cos, $second-circle-distance * $sin);
      transition: $transition * 6 transform $transition * 0.2,
        $transition * 6 opacity cubic-bezier(0.55, -0.33, 0.35, 1.47);
    }
  }
}

/* stylelint-enable */

@keyframes animate-main-circle {
  //
  25% {
    transform: scale(13);
    opacity: 1;
    stroke: transparent;
  }

  45% {
    transform: scale(17);
    opacity: 1;
    fill: transparent;
    stroke-width: 0.5;
  }

  100% {
    transform: scale(22);
    opacity: 1;
    fill: transparent;
    stroke-width: 0;
  }
}

@keyframes animate-heart-inactive {
  //
  40% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animate-heart-active {
  //
  40% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animate-heart-hover {
  //
  0%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(1);
  }

  50%,
  70% {
    transform: scale(1.1);
  }
}
</style>
