// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatGptVersionIndicator_container_d0jTi{height:0;display:flex;justify-content:center}.ChatGptVersionIndicator_container_d0jTi .ChatGptVersionIndicator_label_RejSO{position:fixed;width:-moz-fit-content;width:fit-content;color:var(--supster-primary-light-background);text-align:center;font-size:12px;font-weight:500;line-height:18px;border-radius:9px;border:1px solid var(--supster-primary-light-background);padding:0 9px;transform:translateY(-50%);background-color:#fff;z-index:11}", ""]);
// Exports
exports.locals = {
	"container": "ChatGptVersionIndicator_container_d0jTi",
	"label": "ChatGptVersionIndicator_label_RejSO"
};
module.exports = exports;
