import { ChatsFilterNames } from '@config/index'
import {
  addChats,
  unshiftChat,
  resetUnreadMessageCount,
  updateChatsItem,
  incrementChatsUnreadCount,
} from '../services'
import { Mutations } from '../types'

const mutations: Mutations = {
  addChatsTab(state, chatsName: ChatsFilterNames) {
    state.chatsTabs.push({
      name: chatsName,
      chats: [],
      page: 1,
    })
  },
  setChats(state, { chatsName, chats }) {
    state.chatsTabs = state.chatsTabs.map((item) =>
      item.name === chatsName ? { ...item, chats, page: 2 } : item
    )
  },
  resetChats(state, chatsName) {
    state.chatsTabs = state.chatsTabs.map((item) =>
      item.name === chatsName ? { ...item, chats: [], page: 1 } : item
    )
  },
  replaceChats(state, { chatsName, chats }) {
    state.chatsTabs = state.chatsTabs.map((item) =>
      item.name === chatsName ? { ...item, chats } : item
    )
  },
  updateChatsItem(state, chatFields) {
    state.chatsTabs = state.chatsTabs.map((item) => ({
      ...item,
      chats: updateChatsItem({ chats: item.chats, chatFields }),
    }))
  },
  incrementChatsUnreadCount(state, chatId) {
    state.chatsTabs = state.chatsTabs.map((item) => ({
      ...item,
      chats: incrementChatsUnreadCount({ chats: item.chats, chatId }),
    }))
  },
  setAppSupportChat(state, chat) {
    state.appSupportChat = chat
  },
  setChatsName(state, chatsName) {
    state.chatsName = chatsName
  },
  pushChats(state, { chatsName, chats }) {
    state.chatsTabs = state.chatsTabs.map((item) =>
      item.name === chatsName ? addChats(item, chats) : item
    )
  },
  setIsLoadingCompleted(state, is) {
    state.isLoadingCompleted = is
  },
  unshiftChat(state, { chatsName, chat, store }) {
    state.chatsTabs = state.chatsTabs.map((item) =>
      item.name === chatsName ? unshiftChat({ chatsTab: item, chat, store }) : item
    )
  },
  replaceChatInChats(state, chat) {
    state.chatsTabs = state.chatsTabs.map((item) => ({
      ...item,
      chats: item.chats?.map((chatItem) => (chatItem.id === chat.id ? chat : chatItem)) ?? null,
    }))
  },
  deleteChatFromChats(state, chatId) {
    state.chatsTabs = state.chatsTabs.map((item) => ({
      ...item,
      chats: item.chats?.filter((chatItem) => chatItem.id !== chatId) ?? null,
    }))
  },
  resetChatUnreadMessageCountInChats(state, chatId) {
    state.chatsTabs = state.chatsTabs.map((item) => ({
      ...item,
      chats: item.chats && resetUnreadMessageCount(item.chats, chatId),
    }))
  },
  setChatsUnreadCount(state, count) {
    state.chatsUnreadCount = count
  },
  decreaseChatsUnreadCount(state) {
    state.chatsUnreadCount = state.chatsUnreadCount > 0 ? state.chatsUnreadCount - 1 : 0
  },
  setIsEditChats(state, isEdit) {
    state.isEditChats = isEdit
  },
}

export default mutations
