/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-stories': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 6v4.543l2.944 2.638-.668.744L9 10.99V6h1zm9.651 1.419L15 13h3.369c-1.236 3.434-4.515 5.9-8.369 5.9A8.897 8.897 0 012.642 15H1.35C3.079 17.986 6.3 20 10 20a9.995 9.995 0 0010-10 9.98 9.98 0 00-.349-2.581zM1.631 7C2.867 3.566 6.146 1.1 10 1.1A8.896 8.896 0 0117.358 5h1.293C16.921 2.014 13.7 0 10 0A9.993 9.993 0 000 10c0 .894.129 1.757.349 2.582L5 7H1.631z" _fill="#EDD031"/>'
  }
})
