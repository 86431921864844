import { Comment, Comments } from '@services/comments/types'
import { ConfiguredParams, Keys } from './types'

type Params<T extends Keys> = ConfiguredParams<'comments' | T>

export const getComment = ({ comments, commentId }: Params<'commentId'>): Comment | null =>
  comments.find((comment) => comment.id === commentId) ?? null

export const decreaseSubCommentsCount = ({ comments, parentId }: Params<'parentId'>): Comments =>
  comments.map((comment) => {
    if (comment.id === parentId) {
      const subCommentsCount = comment.comment_count > 0 ? comment.comment_count - 1 : 0
      return { ...comment, comment_count: subCommentsCount }
    }
    return comment
  })

export default {
  decreaseSubCommentsCount,
}
