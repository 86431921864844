import md5 from 'md5'
import { TemplateMessage, PostMessageParams } from '@modules/chat/types'
import store from '@store/index'

import { isMyMessage } from '@modules/messages/services'
import { Message, MessageMode } from '@common-types/chat/chat-message'

export const createMessage = (params: Partial<TemplateMessage>): TemplateMessage => ({
  temp: true,
  body: null,
  mediaObjectsIds: [],
  channel_id: 0,
  client_id: 0,
  tempId: md5(new Date().toISOString()),
  ...params,
})

export const createMockMessage = (params: PostMessageParams & TemplateMessage): TemplateMessage => {
  const mode_app = params?.mode === MessageMode.APP ? store.getters['App/app'] : null
  const client = params?.mode === MessageMode.CLIENT ? store.getters['Client/client'] : null
  const parent = params?.parent_id ? store.getters['Chat/getChatMessage'](params?.parent_id) : null
  const { has_parent, local_id, mode, channel, mediaObjectsIds } = params ?? {}
  return createMessage({
    body: params?.body,
    parent,
    mode_app,
    channel,
    client,
    client_id: client?.id,
    channel_id: channel?.id,
    has_parent,
    local_id,
    mode,
    mediaObjectsIds,
  })
}

export const myLastMessage = (messages: Message[]): Message | null => {
  let message
  for (let i = messages.length - 1; i >= 0; i--) {
    if (isMyMessage({ message: messages[i], store })) {
      message = messages[i]
      break
    }
  }
  return message ?? null
}

export const lastMessageIsMine = (messages: Message[]): boolean => {
  const lastMessage = messages.length ? messages[messages.length - 1] : null
  return !!lastMessage && isMyMessage({ message: lastMessage, store })
}

export default {
  createMessage,
}
