import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'

import Profile from '@modules/profile/store'
import Search from '@modules/search/store'
import Notifications from '@modules/notifications/store'
import Chat from '@modules/chat/store'
import Chats from '@modules/chats/store'
import Prompts from '@modules/prompts/store'
import Feed from '@modules/feed/store'
import App from './app'
import Blocks from './blocks'
import Cart from './cart'
import Countries from './countries'
import Client from './client'
import Clients from './clients'
import Comments from './comments'
import Docs from './docs'
import Drawers from './drawers'
import Error from './error'
import Files from './files'
import Follow from './follow'
import Finance from './finance'
import Install from './install'
import Locale from './locale'
import Me from './me'
import Media from './media'
import Modals from './modals'
import Order from './order'
import Orders from './orders'
import Page from './page'
import Post from './post'
import Promotional from './promotional'
import Stories from './stories'
import Subscriptions from './subscriptions'
import Tag from './tag'
import User from './user'
import Verification from './verification'
import Toasts from '../modules/toasts/store'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    App,
    Blocks,
    Cart,
    Chat,
    Chats,
    Countries,
    Client,
    Clients,
    Comments,
    Docs,
    Drawers,
    Error,
    Feed,
    Files,
    Follow,
    Finance,
    Install,
    Locale,
    Me,
    Media,
    Modals,
    Order,
    Orders,
    Page,
    Prompts,
    Post,
    Profile,
    Promotional,
    Notifications,
    Search,
    Stories,
    Subscriptions,
    Tag,
    Toasts,
    User,
    Verification,
  },
  // plugins: process.env.NODE_ENV === 'production' ? [] : [createLogger()],
})

export default store
