// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppRightSection_container_2TkLq{height:100%;padding:48px;display:flex;flex-direction:column;align-items:flex-end;justify-content:space-between;position:relative}.AppRightSection_container_2TkLq.AppRightSection_open_zVhcl{z-index:5}.AppRightSection_container_2TkLq .AppRightSection_hidden_3C_9b{visibility:hidden}@media(max-width:768px){.AppRightSection_container_2TkLq{display:none}}.AppRightSection_container_2TkLq .AppRightSection_menu_3N6xM{width:100%}.AppRightSection_container_2TkLq .AppRightSection_menuOpen_2KvJL{transform:translateX(0)}", ""]);
// Exports
exports.locals = {
	"container": "AppRightSection_container_2TkLq",
	"open": "AppRightSection_open_zVhcl",
	"hidden": "AppRightSection_hidden_3C_9b",
	"menu": "AppRightSection_menu_3N6xM",
	"menuOpen": "AppRightSection_menuOpen_2KvJL"
};
module.exports = exports;
