<template>
  <transition name="fade-kit" appear>
    <div
      v-if="drawer(name)"
      :class="[$style.section, isMobile && isPWAInstalled && $style.pwa, isIOS && $style.ios]"
      @click.self="onClose"
    >
      <transition name="drawer-bottom-kit" :appear="overlayAnimate">
        <div :class="$style.container">
          <div v-if="title" :class="[$style.header, isBack && $style.isBack]">
            <v-button v-if="isBack" color="transparent-light" @click="$emit('back')">
              <icon name="back" width="9" height="17" />
            </v-button>
            <h2 :class="$style.title" v-html="title"></h2>
            <v-button
              :class="$style.buttonClose"
              v-if="isClose"
              color="transparent"
              @click="onClose"
            >
              <icon name="close" width="13" height="13" />
            </v-button>
          </div>
          <div :class="$style.content">
            <slot v-show="!pageLoading" />
          </div>
          <content-loader :class="$style.loader" :is-loading="pageLoading" :size="30" :delay="0" />
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import ContentLoader from '@loaders/list/ContentLoader.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VDrawer',
  components: { ContentLoader, VButton },
  props: {
    overlayAnimate: {
      default: true,
      type: Boolean,
    },
    name: String,
    isBack: Boolean,
    isClose: Boolean,
    title: String,
    isCloseOutside: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('Drawers', ['drawer']),
    ...mapGetters('Page', ['pageLoading', 'isMobile', 'isIOS', 'isPWAInstalled']),
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    onGoBack() {
      this.$emit('back')
    },
    onClose() {
      if (this.isCloseOutside) {
        setTimeout(() => {
          this.setDrawer({ name: this.name, isVisible: false })
          this.$emit('close')
        }, 300)
      }
    },
  },
})
</script>
<style lang="scss" module>
.section {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(#000000, 0.6);
  backdrop-filter: blur(3px);
  &.pwa {
    padding-bottom: 35px;
  }
  &.ios {
    padding-bottom: 80px;
  }
}

.container {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  width: calc(100% - 18px);
  max-height: 90vh;
  margin: 0 9px 0;
  overflow: hidden;
  color: $secondary;
  background-color: white;
  border-radius: 6px;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 15px;
  column-gap: 20px;

  &.isBack {
    grid-template-columns: auto 1fr auto;
  }

  & .title {
    align-self: center;
    width: 100%;
    overflow: hidden;
    color: $secondary;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.content {
  overflow-x: hidden;
  overflow-y: auto;

  @include scrollbar();
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonClose {
  svg {
    fill: #000;
  }
}
</style>
