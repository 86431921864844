/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { DataType, GetParams } from './types'

export default {
  getMoneys: async (params: GetParams): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosJWT.get('money_incomes', { params })
    return data === null ? [] : data
  },
  getMoney: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.get(`money_incomes/${id}`)
    return data
  },
}
