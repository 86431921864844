// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPostHeader_container_1Q7qG{display:flex;justify-content:space-between;gap:9px;padding:6px 8px 6px 6px;color:var(--supster-secondary)}.VPostHeader_container_1Q7qG .VPostHeader_avatarWrapper_4E-fh{flex-grow:1}.VPostHeader_center_ZSSq3{align-self:center}.VPostHeader_ml9_23Ujr{margin-left:9px}.VPostHeader_content_GtxfQ{display:grid;grid-template-columns:1fr auto;align-self:center}.VPostHeader_info_2BXVW{display:flex;flex-direction:column;justify-content:center;gap:5px}.VPostHeader_info_2BXVW,.VPostHeader_name_RO3o_{overflow:hidden}.VPostHeader_name_RO3o_{font-weight:500;font-size:15px;white-space:nowrap;text-overflow:ellipsis;width:100%}.VPostHeader_location_1Shgo,.VPostHeader_name_RO3o_{align-self:center}.VPostHeader_location_1Shgo{font-weight:400;font-size:12px;opacity:.4}.VPostHeader_menu_1QaxI{flex-basis:20px;flex-shrink:0;width:20px}", ""]);
// Exports
exports.locals = {
	"container": "VPostHeader_container_1Q7qG",
	"avatarWrapper": "VPostHeader_avatarWrapper_4E-fh",
	"center": "VPostHeader_center_ZSSq3",
	"ml9": "VPostHeader_ml9_23Ujr",
	"content": "VPostHeader_content_GtxfQ",
	"info": "VPostHeader_info_2BXVW",
	"name": "VPostHeader_name_RO3o_",
	"location": "VPostHeader_location_1Shgo",
	"menu": "VPostHeader_menu_1QaxI"
};
module.exports = exports;
