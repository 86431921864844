<template>
  <v-loader :is-loading="isLoading" :size="size" :delay="delay">
    <template #icon>
      <svg
        :class="$style.icon"
        :style="style"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
      >
        <!-- eslint-disable -->
        <path
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
        <!-- eslint-enable -->
      </svg>
    </template>
  </v-loader>
</template>
<script>
import Vue from 'vue'

import VLoader from '@loaders/VLoader.vue'

export default Vue.extend({
  name: 'LoadingLoader',
  components: { VLoader },
  props: {
    delay: Number,
    size: Number,
    isLoading: Boolean,
  },
  computed: {
    style() {
      return {
        top: `calc(50% - ${this.size}px / 2)`,
        left: `calc(50% - ${this.size}px / 2)`,
      }
    },
  },
})
</script>
<style lang="scss" module>
.icon {
  fill: $secondary;
}
</style>
