/* eslint-disable camelcase */
import ApiVerification from '@services/verification'
import { Actions } from './types'

const actions: Actions = {
  async getVerifications({ commit, dispatch }) {
    try {
      const verifications = await ApiVerification.getVerifications()
      commit('setVerification', verifications[0])
      if (verifications[0]?.id) {
        commit('setCode', verifications[0].code)
        commit('setVerificationId', verifications[0].id)
        // commit('setStep', 6)
      }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    }
  },
  async postVerification({ commit, dispatch }, params) {
    try {
      const { access_token, data } = await ApiVerification.postVerification(params)
      if (access_token) {
        commit('Me/setAccessToken', access_token, { root: true })
      }
      commit('Client/setClient', { verification_id: data.id }, { root: true })
      commit('setVerification', data)
      commit('setCode', data.code)
      commit('setVerificationId', data.id)
      // commit('setStep', 6)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    }
  },
  async putVerification({ commit, dispatch }, { id, verification }) {
    try {
      const response = await ApiVerification.putVerification(id, verification)
      if (response) {
        commit('setVerification', response)
      }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    }
  },
}

export default actions
