<template>
  <component
    :is="tagName"
    :type="type"
    :disabled="(pageLoading && isLoading) || isDisabled"
    :class="[
      $style.container,
      $style[size],
      $style[color],
      isDisabledPadding && $style.noPadding,
      isDiv && $style.div,
      isCircle && $style.circle,
    ]"
    @click.stop="$emit('click', $event)"
  >
    <slot />
    <overlay-loading-loader :is-loading="isLoading" :delay="0" :size="loaderSize" />
  </component>
</template>
<script>
import Vue from 'vue'

import { mapGetters } from 'vuex'
import OverlayLoadingLoader from '@loaders/list/OverlayLoadingLoader.vue'

export default Vue.extend({
  name: 'VButton',
  components: { OverlayLoadingLoader },
  props: {
    type: {
      type: String,
      default: 'button',
      validator: (v) => ['button', 'submit'].includes(v),
    },
    color: {
      default: 'default',
      validator: (v) =>
        [
          'default',
          'primary',
          'white',
          'shadow',
          'shadow-light',
          'shadow-light-2',
          'shadow-dark',
          'transparent',
          'transparent-light',
          'black-primary',
          'border-primary',
          'black-primary-light',
        ].includes(v),
      type: String,
    },
    size: {
      default: 'md',
      validator: (v) => ['sm', 'md', 'lg', 'xl', 'xl-1', 'xxl', 'xxxl', 'xxxxl'].includes(v),
      type: String,
    },
    isLoading: Boolean,
    isDisabled: Boolean,
    isCircle: Boolean,
    isDisabledPadding: Boolean,
    isDiv: Boolean,
  },
  computed: {
    ...mapGetters('Page', ['pageLoading']),
    tagName() {
      return this.isDiv ? 'div' : 'button'
    },
    loaderSize() {
      switch (this.size) {
        case 'xs':
          return 3
        case 'sm':
          return 4
        case 'md':
          return 5
        case 'lg':
          return 7
        case 'xl':
          return 9
        case 'xl-1':
          return 10
        case 'xxl':
          return 10
        case 'xxxl':
          return 12
        case 'xxxxl':
          return 15
        default:
          return 7
      }
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  column-gap: 5px;
  outline: none;
  cursor: pointer;
  transition: opacity $transition;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &.default {
    color: white;
    background-color: $secondary;
    border: 1px solid $secondary;
  }

  &.primary {
    color: $primary-light-text-color;
    background-color: $primary-light-background;
    border: 1px solid $primary-light-background;
  }

  &.black-primary {
    color: $primary;
    background-color: $main-text-color;
    border: 1px solid $main-text-color;
  }

  &.black-primary-light {
    color: $primary-dark-background;
    background-color: black;
    border: 1px solid black;
  }

  &.transparent {
    color: $main-text-color;
    background: transparent;
    border-color: transparent;
  }

  &.transparent-light {
    color: $secondary;
    background: transparent;
    border-color: transparent;
  }

  &.white {
    color: $secondary;
    background: white;
    border-color: white;
  }

  &.shadow {
    color: $secondary;
    background: $secondary-light;
    border-color: $secondary-light;
  }

  &.shadow-light {
    color: $secondary;
    background: $shadow;
    border-color: $shadow;
  }

  &.shadow-light-2 {
    color: $secondary;
    background: $shadow-light-2;
    border-color: $shadow-light-2;
  }

  &.shadow-dark {
    color: $secondary;
    background: $secondary-medium;
    border-color: $secondary-medium;
  }

  &.border-primary {
    color: $secondary;
    background: transparent;
    border: 1px solid $primary;
  }
  &.sm {
    padding: 6px;
    font-size: 10px;
    line-height: 10px;
    border-radius: 3px;
  }

  &.md {
    padding: 6px 9px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 6px;
  }

  &.lg {
    padding: 10.2px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 6px;
  }

  &.xl {
    padding: 11px;
    font-size: 15px;
    line-height: 14px;
    border-radius: 6px;
  }
  &.xl-1 {
    padding: 13px;
    font-size: 15px;
    line-height: 14px;
    border-radius: 6px;
  }
  &.xxl {
    padding: 16.2px;
    font-size: 15px;
    line-height: 14px;
    border-radius: 6px;
  }
  &.xxxl {
    padding: 20.2px;
    font-size: 16px;
    line-height: 14px;
    border-radius: 6px;
  }
  &.xxxxl {
    padding: 26px;
    font-size: 18px;
    line-height: 14px;
    border-radius: 6px;
  }

  &.circle {
    width: auto;
    border-radius: 100%;

    &.sm {
      padding: 3px;
    }

    &.md {
      padding: 7px;
    }
  }

  &.noPadding {
    padding: 0 !important;
  }

  &.div {
    cursor: auto;

    &:hover {
      transform: translate(0, 0);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      transform: translate(0, 0);
      opacity: 0.4;
    }
  }
}
</style>
