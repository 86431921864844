<template>
  <div :class="$style.container">
    <icon name="logo" :class="$style.supsterLogo" width="92" />
    <div :class="$style.content">
      <div :class="$style.pageLabel">{{ pageLabel }}</div>
      <div v-if="pageDesc" :class="$style.pageDesc">{{ pageDesc }}</div>
    </div>
    <div :class="$style.copyright">© 2023. Supster</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { GptVersionLabels } from '@modules/chat/config'
import ConfigDesktopPageLabels from '@config/desktop-page-labels'

export default Vue.extend({
  name: 'AppLeftSection',
  computed: {
    ...mapGetters('Chat', ['chatGptVersion', 'gptVersion']),
    ...mapGetters('App', [
      'appNameCapitalized',
      'isSplitTestApp4',
      'isSplitTestApp6',
      'isSplitTestApp5',
    ]),
    pageName() {
      const pageName = this.$route.name?.toLocaleLowerCase()
      return ConfigDesktopPageLabels[pageName] ?? pageName
    },
    pageLabel() {
      return this.$t(`desktopPageLabels.${this.pageName}.label`, {
        appName: this.appNameCapitalized(),
      })
    },
    isHiddenDesc() {
      return (
        ((this.isSplitTestApp4 || this.isSplitTestApp6 || this.isSplitTestApp5) &&
          this.pageName === 'chat') ||
        (this.isSplitTestApp6 && this.pageName === 'promotional')
      )
    },
    pageDesc() {
      const version = GptVersionLabels[this.chatGptVersion] ?? GptVersionLabels[this.gptVersion]
      return (
        !this.isHiddenDesc &&
        this.$t(`desktopPageLabels.${this.pageName}.desc`, {
          version,
        })
      )
    },
  },
})
</script>

<style lang="scss" module>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px;
  color: $main-text-color;
  width: 500px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 32px;
    font-weight: 800;
    padding-bottom: 200px;

    .pageLabel {
      font-size: 88px;
      line-height: 88px;
      letter-spacing: -4.4px;
    }

    .pageDesc {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -1.2px;
    }
  }

  .copyright {
    font-size: 12px;
    line-height: 18px;
  }

  @media (max-width: $width) {
    display: none;
  }
}
</style>
