// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CommentEditDrawer_drawer_2ERQL>*{padding:0 15px 15px 15px}.CommentEditDrawer_input_10Q6j{margin:0;padding:0}.CommentEditDrawer_buttonWrapper_2M_Xj{padding:15px}", ""]);
// Exports
exports.locals = {
	"drawer": "CommentEditDrawer_drawer_2ERQL",
	"input": "CommentEditDrawer_input_10Q6j",
	"buttonWrapper": "CommentEditDrawer_buttonWrapper_2M_Xj"
};
module.exports = exports;
