// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NewPasswordForm_form_3qSuG{grid-auto-rows:min-content;grid-auto-flow:row}.NewPasswordForm_input_iRJKs{margin:0;padding:0}.NewPasswordForm_buttonContainer_LfYa5{padding:15px}.NewPasswordForm_button_uMIod{display:grid;grid-auto-flow:column;justify-content:center;-moz-column-gap:9px;column-gap:9px}", ""]);
// Exports
exports.locals = {
	"form": "NewPasswordForm_form_3qSuG",
	"input": "NewPasswordForm_input_iRJKs",
	"buttonContainer": "NewPasswordForm_buttonContainer_LfYa5",
	"button": "NewPasswordForm_button_uMIod"
};
module.exports = exports;
