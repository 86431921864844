import i18n from '@plugins/i18n'

if ('serviceWorker' in navigator) {
  import('workbox-window').then(({ Workbox }) => {
    const wb = new Workbox('/sw.js')

    const showSkipWaitingPrompt = () => {
      wb.addEventListener('controlling', () => {
        window.location.reload()
      })
      // eslint-disable-next-line no-alert, no-restricted-globals
      const updateAccepted = confirm(i18n.t('newVersionAvailable'))

      if (updateAccepted) {
        wb.messageSkipWaiting()
      }
    }

    wb.addEventListener('waiting', (event) =>
      process.env.NODE_ENV === 'production' ? showSkipWaitingPrompt(event) : wb.messageSkipWaiting()
    )

    wb.register()
  })
}
