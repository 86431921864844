// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileActivation_section_2Nc8L{display:grid;grid-gap:12px;padding:15px;background:var(--supster-primary);border-radius:6px}.ProfileActivation_title_1I7h3{font-weight:500;font-size:15px;line-height:22px}.ProfileActivation_text_3VQkp{font-weight:400;font-size:12px;line-height:18px}", ""]);
// Exports
exports.locals = {
	"section": "ProfileActivation_section_2Nc8L",
	"title": "ProfileActivation_title_1I7h3",
	"text": "ProfileActivation_text_3VQkp"
};
module.exports = exports;
