<template>
  <div ref="tabs" :class="$style.section">
    <div v-show="isTabsVisible" :class="[$style.tabs, className]">
      <VButton
        v-for="({ value, name, icon, disabled }, index) in tabs"
        :color="activeIndex === index ? activeTabColor : color"
        :key="`tab-${index}`"
        :class="[
          $style.tab,
          $style[size],
          isDefaultTabColor && $style.default,
          activeIndex === index && $style.active,
          coverType && $style.cover,
        ]"
        :is-disabled="disabled"
        @click="onChangeTab(index, name)"
      >
        <icon v-if="icon" :name="icon" :class="$style.icon" />
        <span :class="[$style.text, { [$style.cover]: coverType }]">
          {{ value }}
        </span>
      </VButton>
    </div>
    <slot name="header"></slot>
    <div :key="templateName" :class="$style.container">
      <div :class="$style.content">
        <slot v-if="isSingleTab" name="content"></slot>
        <slot v-else :name="templateName" />
      </div>
    </div>
    <slot name="footer"></slot>
  </div>
</template>
<script>
import Vue from 'vue'

import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VTabs',
  components: { VButton },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    tabs: {
      require: true,
      type: Array,
    },
    color: {
      type: String,
      default: 'transparent',
    },
    className: String,
    size: String,
    isTabsVisible: {
      type: Boolean,
      default: true,
    },
    isSingleTab: Boolean,
  },
  data() {
    return {
      transitionName: 'slide-leave',
    }
  },
  watch: {
    activeIndex(newValue, oldValue) {
      this.transitionName = newValue <= oldValue ? 'slide-leave' : 'slide-enter'
    },
  },
  computed: {
    templateName() {
      return `tab-${this.tabs[this.activeIndex]?.name}` ?? 'default'
    },
    isDefaultTabColor() {
      return this.color === 'transparent' || this.color === 'transparent-light'
    },
    activeTabColor() {
      return this.size ? 'primary' : 'transparent-light'
    },
    coverType() {
      return this.className.includes('cover')
    },
  },
  methods: {
    onChangeTab(index, name) {
      this.$emit('update:active-index', index)
      this.$emit('tab', name)
    },
  },
})
</script>
<style lang="scss" module>
.section {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.tabs {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr;
}

.tab {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  align-self: center;
  justify-content: center;
  width: 100%;
  padding: 15px 0 !important;
  font-weight: 400;
  column-gap: 9px;
  border-radius: 0 !important;

  &.cover {
    height: 100%;
    padding: 7px 15px !important;

    &.default {
      color: $grey-dark;
      background: $secondary-light;
      opacity: 1;
    }

    &.active {
      color: var(--supster-secondary);
      background: transparent;
    }
  }

  &.sm {
    padding: 5px 0 !important;

    &:first-child {
      border-radius: 6px 0 0 6px !important;
    }

    &:last-child {
      border-radius: 0 6px 6px 0 !important;
    }
  }

  &:hover {
    transform: translate(0, 0);
  }

  &.default {
    opacity: 0.3;

    &:hover {
      opacity: 0.7;
    }
  }

  &.active {
    opacity: 1;
  }
}

.wrapper {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  align-self: center;
  padding: 15px 0;
  column-gap: 9px;
}

.icon {
  justify-self: center;
  width: 18px;
  height: 18px;
}

.text {
  align-self: center;
  justify-self: center;
  font-size: 12px;

  &.cover {
    font-size: 15px;
    line-height: 22px;
  }
}
</style>
