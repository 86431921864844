<template>
  <transition name="scroll-button" appear>
    <div v-if="isVisible" :class="$style.element">
      <div :class="$style.content" @click="$emit('click')">
        <icon name="arrow-bottom" width="17" height="9" />
        <transition name="fade">
          <div v-if="count" :class="$style.count">{{ countToShow }}</div>
        </transition>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue'
import { shortAbbreviateNumber } from '@utils/numbers'

export default Vue.extend({
  name: 'ChatDownScroller',
  props: {
    isVisible: Boolean,
    count: Number,
  },
  computed: {
    countToShow() {
      return shortAbbreviateNumber(this.count) ?? 0
    },
  },
})
</script>
<style lang="scss" module>
.element {
  position: absolute;
  right: 20px;
  bottom: 10px;
  width: 35px;
  height: 35px;
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: white;
  background: black;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.count {
  position: absolute;
  top: -9px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  background-color: $secondary;
  border-radius: 9px;
  transform: translateX(-50%);
}
</style>
