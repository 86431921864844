/* eslint-disable camelcase */
import { axios, axiosJWT } from '@plugins/axios'
import { Client, ClientBot, PostParams, PutEmailParams } from './types'

export default {
  postClient: async (client: PostParams): Promise<ResType<Client>> => {
    const { access_token, data }: ResType<Client> = await axios.post('clients', client)
    return { access_token, data }
  },
  putClient: async (id: number, client: Client): Promise<ResType<Client>> => {
    const { access_token, data }: ResType<Client> = await axiosJWT.put(`clients/${id}`, client)
    return { access_token, data }
  },
  putClientEmail: async (id: number, params: PutEmailParams): Promise<ResType<Client>> => {
    const { access_token, data }: ResType<Client> = await axiosJWT.put(`clients/${id}`, params)
    return { access_token, data }
  },
  postClientLogin: async (loginData: {
    identifier: string
    password: string
  }): Promise<ResType<undefined>> => {
    const data: ResType<undefined> = await axiosJWT.post('clients/login', loginData)
    return data
  },
  postClientCode: async ({
    phone,
    email,
    type,
  }: {
    phone?: string
    email?: string
    type?: number
  }): Promise<ResType<undefined>> => {
    const data: ResType<undefined> = await axiosJWT.post('clients/code', { phone, email, type })
    return data
  },
  postClientCodeAuth: async (code: string, token: string): Promise<ResType<undefined>> => {
    const data: ResType<undefined> = await axiosJWT.post('clients/code/auth', { code, token })
    return data
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getClient: async (id: number): Promise<any> => {
    return axiosJWT.get(`clients/${id}`)
  },
  getAppSupportChatIsAccessible: async (clientId: number): Promise<boolean> => {
    const { data } = await axiosJWT.get(`clients/${clientId}/chat-accessible`)
    return data
  },
  getClientBot: (app_id: number): Promise<ResType<ClientBot[]>> =>
    axiosJWT.get(`clients?owner_app_id=${app_id}`),
}
