/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star-bubble': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M16.97 7.03c-.12-.36-.43-.61-.8-.67l-4.31-.66-1.94-4.13a1.005 1.005 0 00-1.82 0L6.16 5.7l-4.31.66c-.37.06-.68.32-.8.67-.12.36-.03.75.23 1.02l3.15 3.23-.74 4.57c-.06.38.1.76.41.98.17.12.37.18.58.18.17 0 .33-.04.48-.12L9 14.77l3.84 2.12c.15.08.32.12.48.12.2 0 .4-.06.58-.18.31-.22.47-.6.41-.98l-.74-4.57 3.15-3.23c.26-.27.35-.66.23-1.02h.02zM8.42 12.3c-.25 0-.45-.2-.45-.45s.2-.45.45-.45.45.2.45.45-.2.45-.45.45zm-.24-3.22c-.54 0-.98-.44-.98-.98s.44-.98.98-.98.98.44.98.98-.44.98-.98.98zm2.45 1.8c-.34 0-.61-.27-.61-.61 0-.34.27-.61.61-.61.34 0 .61.27.61.61 0 .34-.27.61-.61.61z" _fill="#000"/>'
  }
})
