/* eslint-disable camelcase */
import { Getters } from './types'

const getters: Getters = {
  someClient: (state) => (clientId: number) => state.clients.find(({ id }) => id === clientId),
  someClientAvatarImage: (state) => (clientId: number, type: 'content_url' | 'preview_url') => {
    const client = state.clients.find(({ id }) => id === clientId)
    return client?.avatar?.[type]
  },
  someClientName: (state) => (clientId: number) => {
    const client = state.clients.find(({ id }) => id === clientId)
    return client?.name
  },
}

export default getters
