// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AdminNoVerified_container_IOQ0C{display:grid;grid-gap:18px;padding:20px;text-align:center}.AdminNoVerified_title_CdBv8{color:var(--supster-secondary);font-weight:500;font-size:15px;line-height:24px}.AdminNoVerified_description_U9S2f{color:#737373;font-weight:400;font-size:12px;line-height:18px}", ""]);
// Exports
exports.locals = {
	"container": "AdminNoVerified_container_IOQ0C",
	"title": "AdminNoVerified_title_CdBv8",
	"description": "AdminNoVerified_description_U9S2f"
};
module.exports = exports;
