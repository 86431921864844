<template>
  <section :class="$style.section">
    <div :class="$style.aside">
      <icon :class="$style.icon" name="attention" />
      <img :class="$style.image" src="@assets/images/svg/supster.svg" alt="Supster" />
    </div>
    <div :class="$style.content">
      <h4 :class="$style.title">{{ $t('verifications.title') }}</h4>
      <p :class="$style.text">
        {{ $t('verifications.text') }}
      </p>
      <span :class="[$style.text, $style.dark]">
        {{ $t('verifications.description') }}
      </span>
      <div :class="$style.wrapper">
        <router-link to="/verification" :class="$style.link">
          <icon :class="$style.icon" name="check-pattern" />
          <span> {{ $t('verifications.link') }}</span>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ProfileVerification',
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 6px;
  overflow: hidden;
  background: $dark;
  border-radius: 6px;
}

.aside {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 15px 10px;
  background-color: black;
  row-gap: 15px;
}

.content {
  display: grid;
  grid-auto-flow: row;
  padding: 12px 15px;
  row-gap: 6px;
}

.icon {
  justify-self: center;
  width: 18px;
  height: 16px;
  fill: $primary;
}

.image {
  align-self: flex-end;
  width: 28px;
  height: 92px;
}

.title {
  color: $primary;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.text {
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  &.dark {
    color: $secondary-medium;
  }
}

.wrapper {
  display: flex;
  margin-top: 6px;
}

.link {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  color: $primary;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  column-gap: 6px;
  transition: opacity $transition;

  &:hover {
    opacity: 0.7;
  }
}
</style>
