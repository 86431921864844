import { Claims } from '@config/claims'

export default {
  claims: {
    [Claims.DONT_LIKE]: "Just don't like it",
    [Claims.SPAM]: "It's spam",
    [Claims.SEXUAL]: 'Nudity or sexual activity',
    [Claims.HATE]: 'Hate speech or symbols',
    [Claims.FALSE_INFORMATION]: 'False information',
    [Claims.BULLYING]: 'Bullying or harassment',
    [Claims.VIOLENCE]: 'Violence or dangerous organizations',
    [Claims.SCAM]: 'Scam or fraud',
    [Claims.INTELLECTUAL]: 'Intellectual property violation',
    [Claims.SALE_ILLEGAL]: 'Sale of illegal or regulated goods',
    [Claims.SUICIDE]: 'Suicide or self-injury',
    [Claims.EATING_DISORDERS]: 'Eating disorders',
    [Claims.SUICIDE_OR_EATING_DISORDERS]: 'Suicide, self-injury or eating disorders',
    [Claims.SOMETHING_ELSE]: 'Something else',
  },
}
