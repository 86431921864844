/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'goblet': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<path pid="0" d="M14 15.5a.5.5 0 01-.5.5h-9a.5.5 0 110-1h9a.5.5 0 01.5.5zm-8-2a.5.5 0 00.5.5h5a.5.5 0 000-1h-5a.5.5 0 00-.5.5zM9.5 12a.5.5 0 000-1h-1a.5.5 0 100 1h1zM18 0v1.6a4 4 0 01-4 4h-.06C13.64 8.075 11.555 10 9 10c-2.556 0-4.64-1.925-4.94-4.4H4a4 4 0 01-4-4V0h5v1h8V0h5zM4 1H1v.6c0 1.654 1.345 3 3 3V1zm13 0h-3v3.6c1.655 0 3-1.346 3-3V1z"/>'
  }
})
