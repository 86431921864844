// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/images/svg/desktop-background.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".App_page_3ybD_{position:relative;display:flex;justify-content:space-between;width:100%;height:100vh;height:calc(var(--vh, 1vh)*100);min-height:300px;margin-right:auto;margin-left:auto;overflow:hidden;color:var(--supster-main-text-color);background-color:var(--supster-primary);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:cover}.App_container_1yQEW{position:fixed!important;bottom:0;left:50%;z-index:4;width:100%;height:100%;transform:translateX(-50%)}.App_container_1yQEW.App_desktop_5dBoL{max-width:470px}@media only screen and (max-width:470px){.App_container_1yQEW.App_desktop_5dBoL{left:0;transform:translateX(0)}}.App_container_1yQEW.App_mobile_34ynW{max-width:768px}@media only screen and (max-width:768px){.App_container_1yQEW.App_mobile_34ynW{left:0;transform:translateX(0)}}@media(min-width:768px){.App_container_1yQEW{height:calc(100% - 48px);overflow:hidden;border:8px solid #000;border-bottom:none;border-radius:24px 24px 0 0}}.App_content_pIJBB{top:0;bottom:0;display:grid;grid-template-rows:auto 1fr auto;justify-self:center;width:100%;max-width:768px;height:100%;min-height:unset;background-color:#fff;transition:all .7s}.App_content_pIJBB.App_open_uF4ml{left:0;transform:translateX(-270px)}@media(min-width:768px){.App_content_pIJBB.App_open_uF4ml{transform:translateX(0)}}.App_content_pIJBB.App_noHeader_1deE9{grid-template-rows:1fr auto}.App_content_pIJBB.App_noFooter_O4k79{grid-template-rows:auto 1fr}.App_content_pIJBB.App_fullHeight_kM_Wa{grid-template-rows:1fr}", ""]);
// Exports
exports.locals = {
	"page": "App_page_3ybD_",
	"container": "App_container_1yQEW",
	"desktop": "App_desktop_5dBoL",
	"mobile": "App_mobile_34ynW",
	"content": "App_content_pIJBB",
	"open": "App_open_uF4ml",
	"noHeader": "App_noHeader_1deE9",
	"noFooter": "App_noFooter_O4k79",
	"fullHeight": "App_fullHeight_kM_Wa"
};
module.exports = exports;
