import md5 from 'md5'
import { axiosJWT } from '@plugins/axios'
import { Message } from '@common-types/chat/chat-message'
import { PostMessageParams, PutMessageParams } from '../types'

export default {
  getChatMessages: (
    id: number,
    params: ReqParamsType
  ): Promise<{ data: Message[] | null; pinned_message: Message | null }> => {
    const uid = md5(new Date().toISOString()).slice(0, 5)
    return axiosJWT.get(`channels/${id}/channel_messages`, {
      params: {
        ...params,
        cache: uid,
      },
    })
  },
  postChatMessage: async (postParams: PostMessageParams): Promise<Message> => {
    const { data } = await axiosJWT.post('channel_messages', postParams)
    return data
  },
  putChatMessage: async (message: PutMessageParams): Promise<Message> => {
    const { data } = await axiosJWT.put(`channel_messages/${message.id}`, message)
    return data
  },
  deleteChatMessage: async (id: number): Promise<DefaultResponse> =>
    axiosJWT.delete(`channel_messages/${id}`),
  postChatMessagesReadAll: async (id: number): Promise<Message> => {
    const { data } = await axiosJWT.post(`channels/${id}/read_all`)
    return data
  },
}
