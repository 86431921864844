<template>
  <div :class="$style.content">
    <transition name="fade-fast-kit">
      <svg v-if="progress" :class="$style.progress" :height="radius * 2" :width="radius * 2">
        <template v-if="isRect">
          <rect
            fill="transparent"
            stroke-linecap="square"
            :class="[$style.circle, $style[color]]"
            :style="{ strokeDashoffset: strokeDashOffset }"
            x="2"
            y="2"
            :width="radius * 2 - 4"
            :height="radius * 2 - 4"
            :stroke-width="4"
            rx="45"
          />
          <defs>
            <linearGradient
              id="gradient_primary"
              x1="45"
              y1="90"
              x2="45"
              y2="0"
              gradientUnits="userSpaceOnUse"
              gradientTransform="rotate(90)"
            >
              <stop offset="0.117318" stop-color="var(--supster-primary-light-shade)" />
              <stop offset="0.877095" stop-color="var(--supster-primary-dark-shade)" />
            </linearGradient>
          </defs>
        </template>
        <circle
          v-else
          fill="transparent"
          stroke-linecap="round"
          :class="[$style.circle, $style[color], $style[`around-${environment}`]]"
          :stroke-dasharray="`${circleLength} ${circleLength}`"
          :style="{ strokeDashoffset: strokeDashOffset }"
          :stroke-width="strokeWidth"
          :r="innerRadius"
          :cx="radius"
          :cy="radius"
        />
      </svg>
    </transition>
    <template v-if="icon">
      <transition name="slide-up-kit" mode="out-in">
        <icon :class="[$style.icon, $style[color]]" :key="icon" :name="icon" />
      </transition>
    </template>
    <slot v-else />
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ProgressRing',
  props: {
    color: {
      type: String,
      default: 'default',
    },
    radius: Number,
    progress: {
      default: 100,
      type: Number,
    },
    strokeWidth: [String, Number],
    icon: String,
    isRect: Boolean,
    environment: {
      default: 'light',
      type: String,
      validator: (v) => ['light', 'dark', 'primary'].includes(v),
    },
  },
  computed: {
    innerRadius() {
      return this.radius - Number(this.strokeWidth) / 2
    },
    circleLength() {
      return this.innerRadius * 2 * Math.PI
    },
    strokeDashOffset() {
      return this.circleLength * (1 - Math.max(this.progress, 1) / 100)
    },
  },
})
</script>
<style lang="scss" module>
.content {
  position: relative;
  width: auto;
  height: auto;
}

.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  transform: rotate(-90deg) scaleY(-1);
  transform-origin: center;
  transition: all $transition;
  will-change: transform;
  transform-box: fill-box;

  &.default {
    stroke: $secondary-light;
  }

  &.around-primary {
    stroke: $main-text-color;
  }
  &.around-dark {
    stroke: $primary-dark-background;
  }
  &.around-light {
    stroke: $primary-light-background;
  }

  &.primary {
    stroke: $primary;
  }

  &.secondary {
    stroke: $secondary;
  }

  &.white {
    stroke: white;
  }

  &.gradient {
    stroke: url('#gradient_primary');
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);

  &.primary {
    color: $primary;
  }

  &.secondary {
    color: $secondary;
  }

  &.white {
    stroke: white;
  }

  &.default {
    color: $secondary-light;
  }
}
</style>
