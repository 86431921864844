// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPostFooter_container_jRnu-{display:grid;grid-gap:12px;padding:12px}.VPostFooter_body_2efMs{white-space:pre}.VPostFooter_buttons_3rGqN{display:grid;grid-auto-columns:min-content;grid-auto-flow:column;-moz-column-gap:9px;column-gap:9px}.VPostFooter_button_2gPUn{justify-self:flex-start;width:auto}.VPostFooter_sendTip_240E6{align-self:center}.VPostFooter_date_36JFf{font-size:12px;line-height:18px;opacity:.4}", ""]);
// Exports
exports.locals = {
	"container": "VPostFooter_container_jRnu-",
	"body": "VPostFooter_body_2efMs",
	"buttons": "VPostFooter_buttons_3rGqN",
	"button": "VPostFooter_button_2gPUn",
	"sendTip": "VPostFooter_sendTip_240E6",
	"date": "VPostFooter_date_36JFf"
};
module.exports = exports;
