import { replaceEntityMediaObject } from '@helpers/media-objects'
import { Mutations } from './types'

const mutations: Mutations = {
  setPost(state, post) {
    state.post = post
  },
  changePost(state, post) {
    if (state.post && state.post.id === post.id) {
      state.post = { ...state.post, ...post }
    }
  },
  replacePostMediaObject(state, mediaObject) {
    state.post = state.post ? replaceEntityMediaObject(state.post, mediaObject) : null
  },
  setPostMeta(state, postMeta) {
    state.postMeta = postMeta
  },
}

export default mutations
