import { Client } from '@services/client/types'
import { MediaObject } from '@services/media/types'
import { DataType as AppType } from '@services/app/types'
import { Chat } from '.'

export enum MessageMode {
  CLIENT = 1,
  CHANNEL = 2,
  APP = 3,
}

export enum MessageType {
  MIXED = 1,
  EVENT = 2,
  VOICE = 3,
  ACTION = 4,
}

export enum MessageErrors {
  NO_ERRORS = 0,
  NEED_SUBSCRIPTION = 1,
  UNKNOWN = 2,
  SWEARING = 3,
}

export interface Message {
  body: string | null
  channel: Chat
  channel_id: number
  client: Client
  client_id: number
  created: DateType
  has_parent: boolean
  id: number
  local_id: number | null
  media_objects: MediaObject[] | null
  mode: MessageMode
  mode_app: AppType
  name: string
  parent: Message | null
  pinned_status: number
  replies_count: number
  sort_index: number | null
  read: DateType | null
  status: number
  type: number
  updated: DateType
  need_to_buy_subscription: boolean
  error_code: MessageErrors
  action_allowed: boolean
}
