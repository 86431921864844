/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload': {
    width: 15,
    height: 20,
    viewBox: '0 0 15 20',
    data: '<path pid="0" d="M15 7.957v9.085A2.96 2.96 0 0112.043 20H2.957A2.96 2.96 0 010 17.042V7.957A2.96 2.96 0 012.958 5H5v1H2.958A1.96 1.96 0 001 7.957v9.085A1.96 1.96 0 002.958 19h9.085A1.96 1.96 0 0014 17.042V7.957A1.96 1.96 0 0012.043 6H10V5h2.043A2.96 2.96 0 0115 7.957zM5.5 3a.498.498 0 00.354-.147L7 1.707V11.5a.5.5 0 101 0V1.707l1.146 1.146a.498.498 0 00.708 0 .5.5 0 000-.707l-2-2a.5.5 0 00-.708 0l-2 2A.5.5 0 005.5 3z"/>'
  }
})
