/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings-colored': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M15.54 11.464c-.05.14-.11.28-.17.41l.9 2.578-1.81 1.809-2.58-.89c-.13.06-.27.12-.41.17L10.28 18H7.72l-1.19-2.459c-.14-.05-.28-.11-.41-.17l-2.58.89-1.81-1.809.9-2.579c-.06-.13-.12-.27-.17-.41L0 10.275V7.716l2.46-1.19c.05-.14.11-.28.17-.41l-.9-2.578 1.81-1.809 2.58.9c.13-.06.27-.12.41-.17L7.72 0h2.56l1.19 2.459c.14.05.28.11.41.17l2.58-.9 1.81 1.809-.9 2.579c.06.13.12.27.17.41L18 7.715v2.558l-2.46 1.19z" _fill="#000"/><circle pid="1" cx="9" cy="9" r="3.5" _stroke="#fff" _fill="#000"/>'
  }
})
