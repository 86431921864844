<template>
  <div ref="media" :class="$style.media">
    <v-video
      v-if="isVideo"
      ref="video"
      :id="media.id"
      :src="contentUrl"
      :hls="hls"
      :duration="duration"
      :preview="previewUrl"
      :is-convert="isConvert"
      :class="[$style.video, ...innerClasses]"
      :is-slider="isSlider"
      :size="videoSize"
      :is-ready-to-load="videoReady"
      :is-active="videoActive"
      :is-player="isPlayer"
      :no-play="noPlay"
      :no-control="videoNoControl"
      :is-autoplay="computerIsAutoplay"
      :is-loop="videoLoop"
      :is-small="isSmall"
      :mute="isObserved"
      :is-paused="isPaused"
      @error="$emit('video-error')"
      @playing="$emit('load')"
    />
    <v-image
      v-else
      :class="[$style.image, ...innerClasses]"
      :src="contentUrl"
      :srcset="previewUrl"
      is-lazy
      v-on="$listeners"
    >
      <template #content>
        <slot name="badges"></slot>
      </template>
    </v-image>
  </div>
</template>

<script>
import Vue from 'vue'
import VImage from '@elements/VImage.vue'
import VVideo from '@components/elements/video/VVideo.vue'

export default Vue.extend({
  name: 'VMedia',
  components: { VImage, VVideo },
  props: {
    media: {
      type: Object,
      default: () => ({}),
    },
    videoSize: {
      type: String,
      default: 'lg',
    },
    videoReady: {
      type: Boolean,
      default: true,
    },
    videoActive: {
      type: Boolean,
      default: true,
    },
    videoLoop: {
      type: Boolean,
      default: true,
    },
    innerClasses: {
      type: Array,
      default: () => [],
    },
    isAutoplay: {
      type: Boolean,
      default: false,
    },
    isPaused: Boolean,
    videoNoControl: Boolean,
    isObserved: {
      type: Boolean,
      default: false,
    },
    isSlider: Boolean,
    isPlayer: Boolean,
    noPlay: Boolean,
  },
  data() {
    return {
      isIntersecting: false,
      observer: null,
      elementWidth: null,
    }
  },
  computed: {
    contentUrl() {
      return this.media?.content_url ?? null
    },
    previewUrl() {
      return this.media?.preview_url ?? null
    },
    hls() {
      return this.media?.hls ?? null
    },
    duration() {
      return this.media?.duration
    },
    isConvert() {
      return !!this.media?.convert_status
    },
    isVideo() {
      return !!this.media?.mime?.includes('video')
    },
    computerIsAutoplay() {
      return this.isAutoplay || (this.isObserved && this.isIntersecting)
    },
    isSmall() {
      return this.elementWidth < 180
    },
  },
  mounted() {
    this.elementWidth = this.$refs.media.clientWidth
  },
})
</script>

<style lang="scss" module>
.media {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video {
  position: absolute;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
