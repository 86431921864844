import { Module } from './types'
import getters from './getters'

const module: Module = {
  state: () => ({}),
  getters,
  namespaced: true,
}

export default module
