// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPostPreviewSmall_container_1xzIx{position:relative;padding-bottom:100%;overflow:hidden;cursor:pointer}.VPostPreviewSmall_container_1xzIx .VPostPreviewSmall_image_30PSK{display:block;position:absolute;width:100%;height:100%;-o-object-fit:cover;object-fit:cover}.VPostPreviewSmall_container_1xzIx .VPostPreviewSmall_image_30PSK.VPostPreviewSmall_blur__Yic6{filter:blur(10px)}.VPostPreviewSmall_container_1xzIx .VPostPreviewSmall_iconWrapper_Fiz-s{position:absolute;bottom:0;left:0}.VPostPreviewSmall_container_1xzIx .VPostPreviewSmall_iconWrapper_Fiz-s svg{width:30px;height:30px}", ""]);
// Exports
exports.locals = {
	"container": "VPostPreviewSmall_container_1xzIx",
	"image": "VPostPreviewSmall_image_30PSK",
	"blur": "VPostPreviewSmall_blur__Yic6",
	"iconWrapper": "VPostPreviewSmall_iconWrapper_Fiz-s"
};
module.exports = exports;
