import i18n from '@plugins/i18n'
import { Tab } from '@modules/profile/constants'
import { Actions, PutMessageParams, TemplateMessage } from '@modules/chat/types'
import { isChannel, isChatCommon } from '@helpers/chats'
import Config from '@modules/chat/config'
import { saveTempMessageInLocalStorage } from '@modules/messages/helpers'
import { Chat } from '@common-types/chat'
import { MessageType } from '@common-types/chat/chat-message'
import ChatApi from '../api/chat'

const actions: Actions = {
  async getChat({ commit, dispatch }, { id, isClientId = false }) {
    try {
      commit('setIsChatLoading', true)
      const chat = isClientId ? await ChatApi.getChatByClientId(id) : await ChatApi.getChat(id)
      commit('setChat', chat)
      return chat
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    } finally {
      commit('setIsChatLoading', false)
    }
  },
  async postChat({ dispatch, commit }, params) {
    try {
      commit('setIsChatLoading', true)
      const chat = await ChatApi.postChat(params)
      commit('setChat', chat)
      dispatch('Chats/unshiftChat', chat, { root: true })
      const isCommon = isChatCommon(chat.type) || isChannel(chat.type)
      if (isCommon) {
        const content = {
          contentItem: chat,
          tab: Tab.CHATS,
        }
        commit('Profile/addProfileContentItem', content, { root: true })
      }
      commit('Search/pushSearchChat', chat, { root: true })
      return chat
    } catch (error) {
      dispatch(
        'notify',
        {
          type: 'error',
          title: `${i18n.t('errors.chats.caption')}`,
          text: `${i18n.t('errors.chats.descriptionCreateDialog')}`,
        },
        { root: true }
      )
      return null
    } finally {
      commit('setIsChatLoading', false)
    }
  },
  async postChatCopy({ dispatch, commit }, secretKey) {
    try {
      commit('setIsChatLoading', true)
      const chat = await ChatApi.postChatCopy({ secret_key: secretKey })
      commit('setChat', chat)
      return chat
    } catch (error) {
      dispatch(
        'notify',
        {
          type: 'error',
          title: `${i18n.t('errors.chats.caption')}`,
          text: `${i18n.t('errors.chats.descriptionCreateDialog')}`,
        },
        { root: true }
      )
      return null
    } finally {
      commit('setIsChatLoading', false)
    }
  },
  async updateChat({ commit, state }, chatFields) {
    commit('updateChat', chatFields)
    commit('Chats/replaceChatInChats', state.chat, { root: true })
  },
  async setChatNewMessageCount({ commit, state }, messageCount) {
    commit('setChatNewMessageCount', messageCount)
    commit('Chats/replaceChatInChats', state.chat, { root: true })
  },
  async deleteChat({ dispatch, commit }, id) {
    try {
      await ChatApi.deleteChat(id)
      commit('Chats/deleteChatFromChats', id, { root: true })
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    }
  },
  async getChatMessages(
    { dispatch, commit, getters },
    {
      id,
      scroll,
      isBottomDirection,
      isRefresh,
    }: { id: number; scroll: InfiniteType; isBottomDirection: boolean; isRefresh: boolean }
  ) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const page = isBottomDirection ? getters.prevPage : getters.nextPage
      const isReset =
        !isBottomDirection && !isRefresh && getters.prevPage === 0 && getters.nextPage === 1
      const { data: messages, pinned_message: pinnedMessage } = await ChatApi.getChatMessages(id, {
        page,
        limit: Config.PAGE_LIMIT,
      })
      if (!messages) {
        if (scroll) {
          scroll.complete()
        }
        return null
      }
      const pinnedMessages = messages?.filter((message) => message.pinned_status === 1)

      if (pinnedMessages && pinnedMessages.length) {
        commit('pushPinnedMessages', pinnedMessages)
      }
      if (pinnedMessage) {
        commit('pushPinnedMessages', [pinnedMessage])
      }
      if (getters.chat) {
        const addingMutationName = isRefresh
          ? 'refreshMessages'
          : isReset
          ? 'setMessages'
          : isBottomDirection
          ? 'pushMessages'
          : 'unshiftMessages'

        commit(addingMutationName, messages?.reverse())
        commit(isBottomDirection ? 'decreasePrevPage' : 'increaseNextPage')
      }
      if (scroll) {
        messages?.length ? scroll.loaded() : scroll.complete()
      }
      return messages
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      if (scroll) {
        scroll.complete()
      }
      return null
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postChatMessage({ dispatch, commit, getters }, data) {
    try {
      const message = await ChatApi.postChatMessage(data)
      commit('pushMessage', message)
      commit('deleteTempMessage', data.tempId)
      return message
    } catch (error: any) {
      if (data.type === MessageType.VOICE) {
        commit('deleteTempMessage', data.tempId)
      } else if (error?.message && error?.message === 'Network Error') {
        const tempMessage = getters.getTempMessage(data.tempId)
        saveTempMessageInLocalStorage({ tempMessage, channelId: data.channel_id })
      }
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    }
  },
  async putChatMessage({ dispatch, commit }, messageFields: PutMessageParams) {
    try {
      const message = await ChatApi.putChatMessage(messageFields)
      commit('replaceMessage', message)
      return message
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    }
  },
  async putChat({ dispatch, commit }, { id, data }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const chat = await ChatApi.putChat(id, data)
      commit('setChat', chat)
      commit('Chats/replaceChatInChats', chat, { root: true })
      return chat
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postTempChatMessages({ dispatch, getters }) {
    const { chatTempMessages }: { chatTempMessages: TemplateMessage[] } = getters
    const { chatId, localId } = getters
    for await (const tempMessage of chatTempMessages) {
      const message = {
        ...tempMessage,
        channel_id: tempMessage.channel_id ?? chatId,
        local_id: localId,
      }
      await dispatch('postTempChatMessage', message)
    }
  },
  async postTempChatMessage({ dispatch, commit }, tempMessage: TemplateMessage) {
    try {
      const message = await ChatApi.postChatMessage(tempMessage as any)
      commit('pushMessage', message)
      commit('deleteTempMessage', tempMessage.tempId)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    }
  },
  async postChatSubscription({ dispatch, commit }, params): Promise<Chat | null> {
    try {
      const chat = await ChatApi.postChatSubscription(params)
      commit(
        'Toasts/pushToast',
        {
          type: 'success',
          title: `${i18n.t('success.joinChannel.caption')}`,
          message: `${i18n.t('success.joinChannel.description')}`,
        },
        { root: true }
      )
      return chat
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    }
  },
  async deleteChatSubscription({ dispatch, commit }, id): Promise<boolean | null | undefined> {
    try {
      const success = await ChatApi.deleteChatSubscription(id)

      commit(
        'Toasts/pushToast',
        {
          type: 'success',
          message: `${i18n.t('success.leaveChannel.description')}`,
        },
        { root: true }
      )
      return success
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    }
  },
  async postChatMessagesReadAll({ dispatch, commit }, chatId) {
    return ChatApi.postChatMessagesReadAll(chatId)
      .then(() => {
        commit('setChatNewMessageCount', 0)
      })
      .catch((error) => {
        dispatch('Error/notifyServerError', error, { root: true })
      })
  },
  async deleteChatMessage({ dispatch, commit }, messageId) {
    return ChatApi.deleteChatMessage(messageId)
      .then(({ success }) => {
        if (success) {
          commit('deleteMessage', messageId)
        } else {
          dispatch(
            'Toasts/pushToast',
            {
              type: 'error',
              title: "Can't delete message",
            },
            { root: true }
          )
        }
      })
      .catch((error) => {
        dispatch('Error/notifyServerError', error, { root: true })
      })
  },
}

export default actions
