/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leave': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<path pid="0" d="M6 7h4.375m0 0L8.5 8.875M10.375 7L8.5 5.125m1.875-1.875v-.625a1.25 1.25 0 00-1.25-1.25h-6.25a1.25 1.25 0 00-1.25 1.25v8.75a1.25 1.25 0 001.25 1.25h6.25a1.25 1.25 0 001.25-1.25v-.625" _stroke="#000" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
