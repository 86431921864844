<template>
  <div :class="[$style.container, !isProductPage && $style.small, isProductPage && $style.product]">
    <h2 v-if="isProductPage" :class="$style.name">{{ name }}</h2>
    <div :class="$style.price">
      <h2 :class="[$style.current, !isProductPage && $style.small]">
        {{ currency }}{{ currentPrice }}
      </h2>
      <s v-if="previousPrice" :class="[$style.old, !isProductPage && $style.small]"
        >{{ currency }}{{ previousPrice }}</s
      >
    </div>
    <v-read-more
      v-if="!isProductPage && description"
      size="md"
      :content="description"
      name="readOn"
    />
    <div v-if="isProductPage" :class="$style.separator"></div>
    <div :class="$style.content">
      <div :class="$style.seller">
        <span v-if="isProductPage" :class="$style.description">{{ $t('seller') }}:</span>
        <div :class="[$style.wrapper, !isProductPage && $style.small]">
          <p :class="$style.title">{{ seller }}</p>
          <v-star-rating size="sm" :value="ratingStar" />
        </div>
      </div>
      <div v-if="isProductPage" :class="$style.delivery">
        <span :class="$style.description">{{ $t('delivery') }}:</span>
        <p :class="$style.title">{{ delivery }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VReadMore from '@layouts/VReadMore.vue'
import VStarRating from '@layouts/VStarRating.vue'

export default Vue.extend({
  name: 'VPostPreviewFooter',
  components: { VReadMore, VStarRating },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('App', ['appCurrencySymbol']),
    isProductPage() {
      return this.$route.name === 'Product'
    },
    name() {
      return this.product?.name ?? ''
    },
    currency() {
      return this.product?.app?.currency?.symbol || this.appCurrencySymbol()
    },
    currentPrice() {
      return this.product?.price ?? 0
    },
    previousPrice() {
      return this.product?.old_price ?? 0
    },
    seller() {
      return this.product?.app?.name ?? ''
    },
    delivery() {
      return this.product.delivery || 0
    },
    description() {
      return this.product?.description ?? ''
    },
    ratingStar() {
      return this.product?.rating_sum
        ? Math.round(this.product?.rating_sum / this.product?.review_count)
        : 0
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  padding: 21px;
  row-gap: 15px;
  background: white;

  &.small {
    padding: 15px;
    row-gap: 9px;
  }

  &.product {
    box-shadow: inset 0 -1px 0 $secondary;
  }
}

.name {
  font-size: 15px;
  line-height: 22px;
}

.price {
  display: flex;
  gap: 9px;
  align-items: center;

  & .current {
    color: black;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;

    &.small {
      align-self: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }

  & .old {
    align-content: flex-start;
    color: $secondary-medium;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    &.small {
      align-self: center;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.separator {
  width: 100%;
  border-top: 1px solid $secondary-light;
}

.content {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto;
  align-items: flex-start;
  justify-content: space-between;
}

.seller,
.delivery {
  display: grid;
  grid-gap: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  & .title {
    color: black;
  }

  & .description {
    color: $secondary-medium;
  }

  & .wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 6px;

    &.small {
      grid-auto-flow: row;
    }
  }
}

.delivery {
  justify-content: flex-end;

  & .description,
  & .title {
    text-align: right;
  }
}
</style>
