// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatsFilterDrawer_userPreview_36nmp{padding:9px;border-bottom:1px solid #e5e3e3}.ChatsFilterDrawer_list_3b3Mx{height:inherit}", ""]);
// Exports
exports.locals = {
	"userPreview": "ChatsFilterDrawer_userPreview_36nmp",
	"list": "ChatsFilterDrawer_list_3b3Mx"
};
module.exports = exports;
