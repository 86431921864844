// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatMediaItem_mediaWrapper_10cfX{position:relative;flex:1;min-width:80px;max-height:450px;overflow:hidden;border-radius:6px;height:150px}.ChatMediaItem_mediaWrapper_10cfX .ChatMediaItem_media_1vdoG{position:relative!important}.ChatMediaItem_mediaWrapper_10cfX .ChatMediaItem_content_1XYq2{position:static!important}.ChatMediaItem_horizontal_3zuH7{flex:2;min-width:136px;min-height:78px}.ChatMediaItem_monoHorizontal_1PXx-{min-width:280px;height:158px}.ChatMediaItem_wide_1cY_W{height:auto}", ""]);
// Exports
exports.locals = {
	"mediaWrapper": "ChatMediaItem_mediaWrapper_10cfX",
	"media": "ChatMediaItem_media_1vdoG",
	"content": "ChatMediaItem_content_1XYq2",
	"horizontal": "ChatMediaItem_horizontal_3zuH7",
	"monoHorizontal": "ChatMediaItem_monoHorizontal_1PXx-",
	"wide": "ChatMediaItem_wide_1cY_W"
};
module.exports = exports;
