/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" d="M12.8 12.8h1.067V16H0v-3.2h1.067v2.133H12.8V12.8zm-1.225-4.425l-4.642 4.642-4.641-4.642.75-.75 3.358 3.35V0h1.067v10.975l3.358-3.35.75.75z" _fill="#000" fill-opacity=".72"/>'
  }
})
