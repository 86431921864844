<template>
  <div :class="$style.section">
    <div :class="[$style.content, isEditable && $style.edit]">
      <p :class="[$style.text, isEditable && $style.edit]">
        {{ $t('followers') }}
        <span><v-count :count="appFollowers(currentApp)" /></span>
      </p>
      <router-link
        to="/following"
        :class="[$style.text, isEditable && $style.edit]"
        v-if="
          (isClientOwner && !isOtherProfilePage) ||
          (!isClientOwner && isMyProfilePage && !isOtherProfilePage)
        "
      >
        {{ $t('follow') }}
        <span><v-count :count="clientFollowCount" /></span>
      </router-link>
      <p :class="[$style.text, isEditable && $style.edit]">
        {{ $t('posts') }}
        <span><v-count :count="appPostsCount(currentApp)" /></span>
      </p>
    </div>
    <v-textarea
      v-if="isEditable && isEdit"
      v-model="textareaValue"
      :value="textareaValue"
      :class="[$style.textarea, isEditable && $style.edit]"
    />
    <v-read-more
      v-else
      :isEdit="isEditable"
      :btnWrapClass="$style['btn-wrap']"
      :class="[placeholder && $style.placeholder, $style.profile]"
      size="md"
      :content="settingDescription"
      name="more"
    >
      <v-button
        v-if="isClientOwner && !isEditable && !isOtherProfilePage"
        color="primary"
        @click="editProfile"
      >
        {{ $t('editProfile') }}
      </v-button>
      <v-button v-if="appBlockId(currentApp) || appIsBlocked" color="primary" @click="unblockApp">
        {{ $t('block.unblock') }}
      </v-button>
      <v-button
        v-if="isOtherProfilePage && !appBlockId(currentApp) && !appIsBlocked"
        size="md"
        :color="isAppFollowed(currentApp) ? 'border-primary' : 'primary'"
        @click="onSetAppSubscription"
        >{{ $t(`followAction.${isAppFollowed(currentApp) ? 'unfollow' : 'follow'}`) }}
      </v-button>
    </v-read-more>
    <transition name="fade">
      <div v-if="isEditable" :class="$style.buttons">
        <transition name="fade">
          <v-button v-if="isEdit" is-circle color="primary" @click="onEdit(false)">
            <icon name="close" width="13" height="13" />
          </v-button>
        </transition>
        <v-button color="primary" is-circle @click="onEdit(isEdit)">
          <icon v-if="isEdit" name="check" width="13" height="13" />
          <icon v-else name="edit" width="13" height="13" />
        </v-button>
      </div>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VReadMore from '@layouts/VReadMore.vue'
import VCount from '@layouts/VCount.vue'
import VTextarea from '@elements/VTextarea.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'ProfileInfo',
  components: { VTextarea, VReadMore, VButton, VCount },
  props: {
    isEditable: Boolean,
  },
  data() {
    return {
      textareaValue: '',
      isEdit: false,
      processedSubId: null,
    }
  },
  computed: {
    ...mapGetters('App', [
      'appId',
      'isAppFollowed',
      'appFollowers',
      'appDescription',
      'appPostsCount',
      'appMySubId',
      'appBlockId',
      'appIsBlocked',
      'currentApp',
      'otherApp',
    ]),
    ...mapGetters('Profile', ['profileClientApp']),
    ...mapGetters('Client', ['isClientOwner', 'clientFollowCount']),
    ...mapGetters('Blocks', ['getBlockId']),
    isOtherProfilePage() {
      return this.$route?.name === 'OtherProfile'
    },
    isMyProfilePage() {
      return this.$route?.name === 'MyProfile'
    },
    editOwner() {
      return this.isEditable && this.isClientOwner
    },
    editClient() {
      return this.isEditable && !this.isClientOwner
    },
    currentAppId() {
      return this.currentApp?.id ?? null
    },
    placeholder() {
      return this.editClient && !this.profileClientApp?.description
    },
    currentAppBlockId() {
      return this.currentApp?.i_blocked_app?.id ?? null
    },
    settingDescription() {
      let text = ''
      if (this.editClient) {
        text = this.profileClientApp?.description?.length
          ? this.profileClientApp?.description
          : this.$t('profileDescPlaceholder')
      }
      if (this.editOwner) {
        text = this.profileClientApp?.description?.length
          ? this.profileClientApp?.description
          : this.appDescription(this.currentApp)
      }
      if (!this.isMyProfilePage) {
        text = this.appDescription(this.currentApp)
      }
      return text
    },
  },
  methods: {
    ...mapActions('App', ['putApp', 'setAppSubscription']),
    ...mapActions('Me', ['getMe']),
    ...mapMutations('App', ['setClientApp']),
    ...mapActions('Blocks', ['deleteAppBlock']),
    ...mapMutations('Feed', ['resetFeed']),
    ...mapMutations('Profile', ['setProfileClientApp']),
    editProfile() {
      this.$router.push({ name: 'MyProfile' })
    },
    onEdit(isEdit) {
      this.isEdit = !this.isEdit
      if (isEdit) {
        this.setProfileClientApp({ description: this.textareaValue })
      } else {
        this.textareaValue = this.editClient
          ? this.profileClientApp?.description || ''
          : this.appDescription(this.currentApp)
      }
    },
    async onSetAppSubscription() {
      await this.setAppSubscription({
        id: this.isAppFollowed(this.currentApp)
          ? this.appMySubId(this.currentApp)
          : this.appId(this.currentApp),
        isFollowed: this.isAppFollowed(this.currentApp),
      })
      this.resetFeed()
      this.getMe({})
    },
    unblockApp() {
      if (this.currentAppId) {
        this.deleteAppBlock({
          appName: this.currentApp?.name,
          blockId: this.appBlockId(this.currentApp) ?? this.getBlockId(this.currentApp?.name),
        })
      } else {
        this.$notify({
          type: 'error',
          title: `${this.$t('appNotFound')}`,
        })
      }
    },
  },

  mounted() {
    this.textareaValue = ''
    this.$bus.$on('edit-profile', async () => {
      this.isEdit = false
    })
    this.$bus.$on('back', async () => {
      this.isEdit = false
    })
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  align-self: flex-start;
  row-gap: 12px;
}

.placeholder {
  opacity: 0.5;
}

.profile {
  span {
    color: $secondary-medium;
  }

  svg {
    fill: $secondary-medium;
  }
}

.content {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-gap: 12px;
  color: #000;

  &.edit {
    color: $grey-dark;
  }
}

.text {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  color: $grey-dark;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;

  & span {
    color: #000;
    font-weight: 500;
  }

  &.edit {
    color: $secondary-medium;

    span {
      color: $secondary-medium;
    }
  }
}

.textarea textarea {
  width: 90%;
  height: 62px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.buttons {
  position: absolute;
  right: 20px;
  bottom: 10px;
  display: grid;
  grid-template-rows: repeat(2, auto);

  button:nth-child(2) {
    margin-top: 5px;
  }
}
</style>
