import { Module } from './types'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const module: Module = {
  state: () => ({
    countries: null,
    currentCountry: null,
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default module
