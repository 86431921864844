<template>
  <div :class="[$style.toast, $style[toast.type]]">
    <div :class="$style.iconWrapper">
      <icon :name="iconParams.name" :color="iconParams.color" :class="$style.icon" />
    </div>
    <div :class="$style.text">
      <div :class="$style.title">{{ toast.title }}</div>
      <div :class="$style.message">{{ toast.message }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { IconParams } from '@/modules/toasts/config'

export default Vue.extend({
  name: 'VToast',
  props: {
    toast: Object,
    default: () => ({}),
  },
  computed: {
    iconParams() {
      return IconParams[this.toast.type]
    },
  },
})
</script>

<style lang="scss" module>
.toast {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: white;
  color: black;
  padding: 15px;
  border-radius: 6px;
  gap: 15px;

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    border-radius: 50%;
    background: $primary;

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  .text {
    color: black;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;

    .title {
      font-weight: 500;
    }

    .message {
      font-weight: 400;
    }
  }
}

.error {
  background: $error;

  .iconWrapper {
    background: black;
  }

  .text {
    color: white;
  }
}
</style>
