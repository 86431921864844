// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPostPreviewBlocked_container_1RBmL{position:relative;width:100%;height:100%;padding-bottom:100%;overflow:hidden}.VPostPreviewBlocked_container_1RBmL .VPostPreviewBlocked_image_zS4oZ{display:block;-o-object-fit:cover;object-fit:cover;-o-object-position:center;object-position:center;filter:blur(10px)}.VPostPreviewBlocked_container_1RBmL .VPostPreviewBlocked_content_3RSDh,.VPostPreviewBlocked_container_1RBmL .VPostPreviewBlocked_image_zS4oZ{position:absolute;width:100%;height:100%}.VPostPreviewBlocked_container_1RBmL .VPostPreviewBlocked_content_3RSDh{display:flex;flex-direction:column;align-items:center;justify-content:center}.VPostPreviewBlocked_container_1RBmL .VPostPreviewBlocked_labelContainer_36da4{position:absolute;bottom:0;width:100%;padding:6px}.VPostPreviewBlocked_container_1RBmL .VPostPreviewBlocked_labelContainer_36da4 .VPostPreviewBlocked_label_2z4zh{display:block;width:100%;padding:6px;color:#fff;font-weight:500;font-size:12px;font-family:Roboto,sans-serif;font-style:normal;line-height:12px;text-align:center;background-color:#000;border-radius:6px 6px 3px 3px;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"container": "VPostPreviewBlocked_container_1RBmL",
	"image": "VPostPreviewBlocked_image_zS4oZ",
	"content": "VPostPreviewBlocked_content_3RSDh",
	"labelContainer": "VPostPreviewBlocked_labelContainer_36da4",
	"label": "VPostPreviewBlocked_label_2z4zh"
};
module.exports = exports;
