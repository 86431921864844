/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 19,
    height: 13,
    viewBox: '0 0 19 13',
    data: '<path pid="0" d="M18.854.854l-12 12a.498.498 0 01-.708 0l-6-6a.5.5 0 11.708-.708L6.5 11.793 18.146.146a.5.5 0 11.707.708z"/>'
  }
})
