import { SubComment } from '@services/comments/types'
import Config from '@config/index'
import { Getters } from './types'
import { getComment } from './services/comments'

const getters: Getters = {
  isCommentEdit: (state) => state.isEdit,
  isCommentReply: (state) => state.isReply,

  comments: (state) => state.comments,
  commentsLength: (state) => state.comments.length,
  commentsPage: (state) => state.page,
  commentsSourceId: (state) => state.sourceId,
  selectedCommentParams: (state) => state.selectedCommentParams,
  selectedComment: (state, getters) => {
    const { commentId, parentId } = state.selectedCommentParams ?? {}
    return parentId ? getters.getSubComment(commentId, parentId) : getters.getComment(commentId)
  },
  extraCommentsCount: (state) => -state.deletedCommentsCount,
  getComment: (state) => (commentId: number) => getComment({ comments: state.comments, commentId }),
  getIsSelectedComment: (s, getters) => (commentId: number) =>
    !!(commentId && getters.selectedCommentParams?.commentId === commentId),
  getThread: (state) => (parentId: number) => state.threadsMap[parentId] ?? null,
  getThreadHasMore: (s, getters) => (parentId: number) =>
    getters.getThread(parentId)?.hasMore ?? null,
  getThreadPage: (state, getters) => (parentId: number) =>
    getters.getThread(parentId)?.page ?? null,
  getThreadVisibility: (s, getters) => (parentId: number) =>
    getters.getThread(parentId)?.isVisible ?? false,
  getSubComments: (state) => (parentId: number) => state.subCommentsMap[parentId] ?? null,
  getSubComment: (s, getters) => (commentId: number, parentId: number) =>
    getters.getSubComments(parentId)?.find(({ id }: SubComment) => id === commentId) ?? null,
  getSubCommentsLength: (s, getters) => (parentId: number) =>
    getters.getSubComments(parentId)?.length ?? null,
  getAddedSubCommentsCount: (s, getters) => (parentId: number) =>
    getters.getThread(parentId)?.addedSubCommentsCount ?? null,
  getExtraSubCommentsCount: (state, getters) => (parentId: number) => {
    const thread = getters.getThread(parentId)
    return thread && -thread.deletedSubCommentsCount
  },
  getPageCorrectionRatio: (s, getters) => (parentId: number) => {
    const extraCommentsCount = parentId
      ? getters.getExtraSubCommentsCount(parentId)
      : getters.extraCommentsCount
    const pageLimit = parentId
      ? Config.Comments.SUB_COMMENTS_PAGE_LIMIT
      : Config.Comments.COMMENTS_PAGE_LIMIT
    const fraction = extraCommentsCount / pageLimit
    return Math.floor(fraction)
  },
}

export default getters
