<template>
  <div :class="[$style.wrap, !goToAppAction && $style.default]" @click="onGoToProfile">
    <div
      :class="[$style.section, $style[size], isRect && $style.rect]"
      :style="{ backgroundColor: innerColor }"
    >
      <v-avatar-count v-if="count" :count="count" :size="size" />
      <progress-ring
        :class="$style.content"
        :progress="progressCount"
        :color="progressColor"
        :stroke-width="progressWidth || progressWidthSize"
        :radius="progressSectionSize / 2"
        :environment="environment"
        :is-rect="isRect"
      >
        <v-avatar-image
          v-if="(src || srcset) && !isError"
          :src="src"
          :srcset="srcset"
          :size="size"
          :is-rect="isRect"
          :class="noProgress && $style.image"
          @error="isError = true"
        />
        <v-avatar-icon
          v-else
          :size="size"
          :color="color"
          :icon-name="imageIcon"
          :environment="environment"
        />
      </progress-ring>
      <slot />
    </div>
    <slot name="rightContent" />
  </div>
</template>
<script>
import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'
import ProgressRing from '@layouts/ProgressRing.vue'
import VAvatarCount from './list/VAvatarCount.vue'
import VAvatarImage from './list/VAvatarImage.vue'
import VAvatarIcon from './list/VAvatarIcon.vue'

export default Vue.extend({
  name: 'VAvatar',
  components: { VAvatarCount, ProgressRing, VAvatarImage, VAvatarIcon },
  props: {
    src: String,
    srcset: String,
    size: {
      default: 'sm',
      validator: (v) => ['xs', 'sm', 'md', 'md-2', 'md-3', 'md-4', 'md-5', 'lg', 'xl'].includes(v),
      type: String,
    },
    goToAppAction: {
      default: true,
      type: Boolean,
    },
    count: Number,
    color: String,
    progressCount: Number,
    progressColor: String,
    progressWidth: [String, Number],
    imageIcon: String,
    isRect: Boolean,
    noProgress: Boolean,
    innerColor: String,
    toApp: Object,
    environment: {
      default: 'light',
      type: String,
      validator: (v) => ['light', 'dark', 'primary'].includes(v),
    },
  },
  data() {
    return {
      isError: false,
    }
  },
  watch: {
    src() {
      this.isError = false
    },
  },
  computed: {
    ...mapGetters('App', ['appId', 'appOriginalId', 'otherApp']),
    ...mapGetters('Locale', ['locale']),
    progressSectionSize() {
      switch (this.size) {
        case 'xs':
          return 33
        case 'sm':
          return 36
        case 'md':
          return 42
        case 'md-2':
          return 46
        case 'md-3':
          return 54
        case 'md-4':
          return 68
        case 'md-5':
          return 80
        case 'lg':
          return 90
        case 'xl':
          return 122
        default:
          return 42
      }
    },
    progressWidthSize() {
      if (['lg', 'xl'].includes(this.size)) {
        return 4
      }
      if (['xs', 'sm'].includes(this.size)) {
        return 2
      }
      return 2.5
    },
    profileLink() {
      if (this.appOriginalId() === this.appOriginalId(this.toApp)) {
        return { name: 'Profile', params: { locale: this.locale } }
      }
      return { name: 'OtherProfile', params: { locale: this.locale, id: this.appId(this.toApp) } }
    },
  },
  methods: {
    ...mapActions('App', ['replaceOtherApp']),
    async onGoToProfile() {
      if (this.toApp) {
        if (this.appOriginalId(this.toApp) !== this.appOriginalId(this.otherApp)) {
          this.replaceOtherApp(this.toApp)
        }
        await this.$router.push(this.profileLink)
      }
    },
  },
})
</script>

<style lang="scss" module>
.section {
  position: relative;
  display: grid;
  flex-shrink: 0;
  border-radius: 100%;

  &.rect {
    border-radius: 47px;
  }

  &.xs {
    width: 33px;
    height: 33px;
  }

  &.sm {
    width: 36px;
    height: 36px;
  }

  &.md {
    width: 42px;
    height: 42px;
  }

  &.md-2 {
    width: 46px;
    height: 46px;
  }

  &.md-3 {
    width: 54px;
    height: 54px;
  }
  &.md-4 {
    width: 68px;
    height: 68px;
  }
  &.md-5 {
    width: 80px;
    height: 80px;
  }

  &.lg {
    width: 90px;
    height: 90px;
  }

  &.xl {
    width: 122px;
    height: 122px;
  }
}

.wrap {
  display: flex;
  cursor: pointer;
  &.default {
    cursor: default;
  }
}

.content {
  // z-index: 1;
  display: grid;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;

  .image {
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
</style>
