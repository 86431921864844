<template>
  <div ref="post" :data-post-id="postId" :class="$style.section">
    <v-post-header :post="post" />
    <v-post-preview :post="post" is-footer />
    <v-post-footer :post="post" />
  </div>
</template>
<script>
import Vue from 'vue'
import VPostPreview from '@layouts/postPreview/VPostPreview.vue'
import VPostHeader from './list/VPostHeader.vue'
import VPostFooter from './list/VPostFooter.vue'

export default Vue.extend({
  name: 'VPost',
  components: {
    VPostHeader,
    VPostPreview,
    VPostFooter,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    observers: {
      type: Array,
      default: () => [],
    },
    isAutoPlay: Boolean,
  },
  computed: {
    hasObservers() {
      return !!this.observers.length
    },
    postId() {
      return this.post?.id
    },
  },
  methods: {
    observe() {
      if (this.hasObservers) {
        this.observers.forEach((observer) => {
          observer.observe(this.$refs.post)
        })
      }
    },
    unobserve() {
      if (this.hasObservers) {
        this.observers.forEach((observer) => {
          observer.unobserve(this.$refs.post)
        })
      }
    },
    emitElementHeight() {
      const height = this.$el.offsetHeight
      this.$emit('mounted', { height, postId: this.post?.id })
    },
  },
  mounted() {
    this.emitElementHeight()
    this.$nextTick(() => {
      this.observe()
    })
  },
  async beforeDestroy() {
    this.unobserve()
  },
})
</script>
<style lang="scss" module>
.section {
  position: relative;
  display: grid;
  box-shadow: inset 0 -1px 0 $secondary;

  &:last-child {
    box-shadow: none;
  }
}
.placeholder {
  height: 500px;
}
</style>
