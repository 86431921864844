/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rings-background': {
    width: 360,
    height: 432,
    viewBox: '0 0 360 432',
    data: '<circle pid="0" opacity=".09" cx="180" cy="216" r="107" _stroke="currentColor" stroke-width="2"/><circle pid="1" opacity=".1" cx="180" cy="216" r="160" _stroke="url(#paint0_linear_265_141)" stroke-width="4"/><circle pid="2" opacity=".12" cx="180" cy="216" r="213" _stroke="url(#paint1_linear_265_141)" stroke-width="6"/><defs><linearGradient id="paint0_linear_265_141" x1="180" y1="54" x2="180" y2="589" gradientUnits="userSpaceOnUse"><stop stop-color="currentColor"/><stop offset="1" stop-color="currentColor" stop-opacity="0"/></linearGradient><linearGradient id="paint1_linear_265_141" x1="180" y1="0" x2="180" y2="597" gradientUnits="userSpaceOnUse"><stop stop-color="currentColor"/><stop offset="1" stop-color="currentColor" stop-opacity="0"/></linearGradient></defs>'
  }
})
