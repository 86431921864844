import chroma from 'chroma-js'

enum ExceptedColors {
  SUPSTER_YELLOW = '#EDD031',
}

export enum Shades {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

const Config = {
  lightnessOffset: 0.14,
  saturationOffset: 0.12,
  darkAverage: 0.65,
  tooDarkAverage: 0.35,
  tooLightAverage: 0.75,
}

const lightening = (param: number, variance: number, limit: number): number => {
  const result = param + variance
  return result > limit ? limit : result
}

const darkening = (param: number, variance: number): number => {
  const result = param - variance
  return result < 0 ? 0 : result
}

const exceptedColorsDarkness = {
  [ExceptedColors.SUPSTER_YELLOW]: {
    [Shades.DARK]: false,
    [Shades.LIGHT]: false,
  },
}

const exceptedColorsShades = {
  [ExceptedColors.SUPSTER_YELLOW]: {
    [Shades.DARK]: (hex: string) => {
      const [red, green, blue] = chroma(hex).rgb()
      const r = darkening(red, 14)
      const g = darkening(green, 58)
      const b = darkening(blue, 1)
      return chroma(r, g, b, 'rgb').hex()
    },
    [Shades.LIGHT]: (hex: string) => hex,
  },
}
export const isDarkColor = (hex: string, hard?: boolean): boolean => {
  const hexUpperCase = hex.toUpperCase() as ExceptedColors
  const average = hard ? Config.tooDarkAverage : Config.darkAverage
  return exceptedColorsDarkness[hexUpperCase]?.[Shades.DARK] ?? chroma(hex).get('hsl.l') < average
}

export const isTooLight = (hex: string): boolean => {
  const hexUpperCase = hex.toUpperCase() as ExceptedColors
  return (
    exceptedColorsDarkness[hexUpperCase]?.[Shades.LIGHT] ??
    chroma(hex).get('hsl.l') > Config.tooLightAverage
  )
}

const getStandardShade = (hex: string, shade: Shades): string => {
  const [hue, saturation, lightness] = chroma(hex).hsl()
  const sat =
    shade === Shades.DARK
      ? darkening(saturation, Config.saturationOffset)
      : lightening(saturation, Config.saturationOffset, 1)
  const light =
    shade === Shades.DARK
      ? darkening(lightness, Config.lightnessOffset)
      : lightening(lightness, Config.lightnessOffset, 1)
  return chroma(hue, sat, light, 'hsl').hex()
}

export const getShade = (hex: string, shade: Shades): string => {
  const hexUpperCase = hex.toUpperCase() as ExceptedColors
  if (exceptedColorsShades[hexUpperCase]) {
    return exceptedColorsShades[hexUpperCase][shade](hex)
  }
  return getStandardShade(hex, shade)
}

export default {
  isDarkColor,
}
