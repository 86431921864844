<template>
  <v-drawer
    name="chat-name-edit"
    :title="isChannel ? $t('channelName') : $t('chatName')"
    @close="onClose"
  >
    <transition-group :class="$style.drawer" tag="div" name="scroll-group" appear>
      <text-input
        key="textInput"
        v-model="chatName"
        placeholder="..."
        :class="$style.input"
        radius="0"
        padding="15px"
        :rules="rules"
        shadow
        @error="error = $event"
      />
      <div key="confirmButton" :class="$style.buttonWrapper">
        <v-button
          color="primary"
          :class="$style.button"
          size="xl"
          :is-loading="pageLoading"
          :is-disabled="error"
          @click="onConfirm"
        >
          {{ $t('confirm.confirm') }}
        </v-button>
      </div>
    </transition-group>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import { Tab } from '@/modules/profile/constants'
import { vLength } from '@utils/validations'

export default Vue.extend({
  name: 'ChatNameEditDrawer',
  components: { VDrawer, VButton, TextInput },
  data() {
    return {
      chatName: '',
      error: false,
    }
  },
  computed: {
    ...mapGetters('Page', ['pageLoading']),
    ...mapGetters('Chat', ['chat', 'chatId', 'isChannel']),

    rules() {
      return [vLength(0, 30)]
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Page', ['setPageTitle']),
    ...mapActions('Chat', ['putChat']),
    ...mapMutations('Profile', ['setProfileContentItem']),
    ...mapMutations('Search', ['setSearchContentItem']),
    onClose() {
      this.chatName = ''
      this.setDrawer({ name: 'chat-name-edit', isVisible: false })
    },
    async onConfirm() {
      const chat = await this.putChat({
        id: this.chatId,
        data: {
          name: this.chatName,
        },
      })
      this.setPageTitle({ name: chat?.name })
      this.setProfileContentItem({ contentItem: this.chat, tab: Tab.CHATS })
      this.setSearchContentItem({ contentItem: this.chat, tab: Tab.CHATS })
      this.onClose()
    },
  },
  watch: {
    chatId() {
      this.chatName = this.chat?.name
    },
  },
  mounted() {
    this.chatName = this.chat?.name
  },
})
</script>

<style lang="scss" module>
.drawer {
  & > * {
    padding: 0 15px 15px 15px;
  }
}

.input {
  margin: 0;
  padding: 0;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.buttonWrapper {
  padding: 15px;
}
</style>
