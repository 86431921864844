// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VFooter_footer_1YGN-{width:100%;box-shadow:0 0 5px rgba(0,0,0,.08)}.VFooter_section_1eKKz{position:relative;z-index:500;width:100%;background-color:var(--supster-footer-background)}.VFooter_section_1eKKz.VFooter_ios_25gGd{padding-bottom:18px}.VFooter_list_2N5iw{justify-content:stretch;width:100%}.VFooter_item_3o9AV,.VFooter_list_2N5iw{display:flex}.VFooter_item_3o9AV{flex-grow:1;justify-content:center}.VFooter_link_3Xz39{position:relative;display:grid;grid-auto-flow:row;width:100%;padding:12px 0;color:#a09f9f;font-size:12px;line-height:12px;cursor:pointer;transition:opacity .3s,background .3s;row-gap:12px}.VFooter_link_3Xz39:hover{opacity:.7}.VFooter_link_3Xz39.VFooter_active_32hRp{color:var(--supster-primary);background:var(--supster-footer-gradient)}.VFooter_icon_3aRLP{justify-self:center;width:18px;height:18px;color:currentColor;transition:color .3s}.VFooter_icon_3aRLP.VFooter_plus_2MWCM{width:14px;height:14px}.VFooter_text_MY8BZ{color:currentColor;font-weight:400;transition:color .3s}.VFooter_button_YsVc9,.VFooter_text_MY8BZ{justify-self:center}.VFooter_button_YsVc9{align-self:center;width:auto!important;padding:10px!important;color:#a09f9f!important;border:1px solid #a09f9f!important;border-radius:100%!important}.VFooter_button_YsVc9:hover{transform:translate(0);opacity:.7}.VFooter_counter_3YMaC{position:absolute;top:4px;left:50%;z-index:2;display:flex;align-items:center;justify-content:center;min-width:16px;min-height:16px;padding:3px;color:var(--supster-main-text-color);font-weight:500;font-size:10px;line-height:10px;background-color:var(--supster-primary);border-radius:8px;transform:translateX(2px)}", ""]);
// Exports
exports.locals = {
	"footer": "VFooter_footer_1YGN-",
	"section": "VFooter_section_1eKKz",
	"ios": "VFooter_ios_25gGd",
	"list": "VFooter_list_2N5iw",
	"item": "VFooter_item_3o9AV",
	"link": "VFooter_link_3Xz39",
	"active": "VFooter_active_32hRp",
	"icon": "VFooter_icon_3aRLP",
	"plus": "VFooter_plus_2MWCM",
	"text": "VFooter_text_MY8BZ",
	"button": "VFooter_button_YsVc9",
	"counter": "VFooter_counter_3YMaC"
};
module.exports = exports;
