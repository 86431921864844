<template>
  <div :class="[$style.item, isShop && $style.shop]">
    <slot />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'PPostPreview',
  props: { isShop: Boolean },
})
</script>

<style lang="scss" module>
.item {
  width: 100%;
  padding-top: 100%;
  background: $grey;
  box-shadow: inset -1px -1px 0 #ffffff;

  &.shop {
    grid-column: span 2;
  }

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
