var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"tabs",class:_vm.$style.section},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTabsVisible),expression:"isTabsVisible"}],class:[_vm.$style.tabs, _vm.className]},_vm._l((_vm.tabs),function({ value, name, icon, disabled },index){return _c('VButton',{key:`tab-${index}`,class:[
        _vm.$style.tab,
        _vm.$style[_vm.size],
        _vm.isDefaultTabColor && _vm.$style.default,
        _vm.activeIndex === index && _vm.$style.active,
        _vm.coverType && _vm.$style.cover,
      ],attrs:{"color":_vm.activeIndex === index ? _vm.activeTabColor : _vm.color,"is-disabled":disabled},on:{"click":function($event){return _vm.onChangeTab(index, name)}}},[(icon)?_c('icon',{class:_vm.$style.icon,attrs:{"name":icon}}):_vm._e(),_c('span',{class:[_vm.$style.text, { [_vm.$style.cover]: _vm.coverType }]},[_vm._v(" "+_vm._s(value)+" ")])],1)}),1),_vm._t("header"),_c('div',{key:_vm.templateName,class:_vm.$style.container},[_c('div',{class:_vm.$style.content},[(_vm.isSingleTab)?_vm._t("content"):_vm._t(_vm.templateName)],2)]),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }