import Config from '@modules/profile/config'

export enum Tab {
  IMAGES = 'IMAGES',
  VIDEOS = 'VIDEOS',
  PRODUCTS = 'PRODUCTS',
  CHATS = 'CHATS',
}

export enum PostsSource {
  IMAGES = 'Images',
  VIDEOS = 'Videos',
}

export default {
  Tab,
}
export const chatDefaultParams = {
  followed: false,
  limit: Config.PAGE_LIMIT,
  type: [1, 2],
  'order[firstly_app_type]': 'desc',
}

export const postDefaultParams = {
  direction: 'desc',
  limit: Config.PAGE_LIMIT,
  random: false,
}
