var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-drawer',{attrs:{"name":"noname","title":_vm.$t('enter.name')},on:{"close":_vm.onClose}},[_c('transition-group',{class:_vm.$style.drawer,attrs:{"tag":"div","name":"scroll-group","appear":""}},[_c('div',{key:"description",class:[_vm.$style.text, _vm.$style.description]},[_vm._v(" "+_vm._s(_vm.$t('toLeaveCommentNeedEnterName'))+" ")]),_c('text-input',{key:"input",ref:"inputName",class:_vm.$style.input,attrs:{"placeholder":_vm.$t('yourName'),"radius":"0","padding":"15px","rules":_vm.rules,"shadow":"","autofocus":true},on:{"error":function($event){_vm.error = $event}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('i18n',{key:"agreement",class:[_vm.$style.text, _vm.$style.agreement],attrs:{"path":"agreement","tag":"p"},scopedSlots:_vm._u([{key:"privacyPolicy",fn:function(){return [_c('router-link',{class:[_vm.$style.link],attrs:{"to":_vm.Trans.i18nRoute({
              name: 'Documents',
              params: {
                slug: _vm.privacyDocId,
              },
            })}},[_vm._v(_vm._s(_vm.$t('withPrivacyPolicy')))])]},proxy:true},{key:"rulesPlatform",fn:function(){return [_c('router-link',{class:[_vm.$style.link],attrs:{"to":_vm.Trans.i18nRoute({
              name: 'Documents',
              params: {
                slug: _vm.userAgreementDocId,
              },
            })}},[_vm._v(_vm._s(_vm.$t('withRulesPlatform')))])]},proxy:true}])}),_c('div',{key:"confirmButton",class:_vm.$style.buttonWrapper},[_c('v-button',{class:_vm.$style.button,attrs:{"color":"primary","size":"xl","is-loading":_vm.pageLoading,"is-disabled":!_vm.name || _vm.error},on:{"click":_vm.onConfirm}},[_vm._v(_vm._s(_vm.$t('confirm.confirm')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }