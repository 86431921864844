import { Getters } from './types'

const getters: Getters = {
  user: (state) => state.user,
  userEmail: (state) => state.user?.email ?? null,
  isUserOwner: (state, g, rs, rootGetters) =>
    !!(state.user?.id && state.user.id === rootGetters['App/appUserId']()),
}

export default getters
