import { RequestParams } from '@modules/prompts/config'
import { Actions, Prompt } from '../types'
import PromptsApi from '../services/api'

const actions: Actions = {
  async getPrompts({ commit, dispatch, rootGetters }, params = {}) {
    try {
      const prompts = await PromptsApi.getPrompts({
        ...RequestParams,
        app_id: rootGetters['App/appId'](),
        ...params,
      })
      commit('setPrompts', prompts)
      return prompts
    } catch (e) {
      await dispatch('Error/notifyServerError', e, { root: true })
      return false
    }
  },
  async getPrompt({ dispatch }, promptId: Prompt['id']) {
    try {
      return await PromptsApi.getPrompt(promptId)
    } catch (e) {
      await dispatch('Error/notifyServerError', e, { root: true })
      return false
    }
  },
}

export default actions
