<template>
  <div :class="containerClasses">
    <div v-for="i in getPostCount(index)" :key="i" :class="$style.post" />
  </div>
</template>

<script>
import Vue from 'vue'
import { GridBlockTypes } from '@modules/search/modules/posts/config'

export default Vue.extend({
  name: 'PSearchPostsBlock',
  props: {
    type: {
      type: String,
      default: GridBlockTypes.COMMON,
      validator: (value) => Object.values(GridBlockTypes).includes(value),
    },
    index: Number,
  },
  computed: {
    containerClasses() {
      return {
        [this.$style.container]: true,
        [this.$style.bigLeft]: this.type === GridBlockTypes.BIG_LEFT,
        [this.$style.bigRight]: this.type === GridBlockTypes.BIG_RIGHT,
      }
    },
  },
  methods: {
    getPostCount(index) {
      return index % 2 === 0 ? 6 : 3
    },
  },
})
</script>

<style lang="scss" module>
.container {
  height: 315px;
  overflow: hidden;
  grid-template-rows: repeat(2, auto);
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  background: white;
  border: 5px solid white;
  border-bottom: none;

  &.bigLeft {
    grid-template-columns: 1fr 2fr;
    grid-auto-flow: column;

    .post:last-child {
      grid-row: 1 / 3;
    }
  }
  &.bigRight {
    grid-template-columns: 2fr 1fr;

    .post:first-child {
      grid-row: 1 / 3;
    }
  }

  .post {
    background: $grey;
    height: 100%;
    border-radius: 5px;
  }
}
</style>
