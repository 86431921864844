import Vue from 'vue'
import { Mutations } from './types'

const mutations: Mutations = {
  setModal(state, { name, isVisible }: { name: string; isVisible: boolean }) {
    Vue.set(state.modals, name, isVisible)
  },
}

export default mutations
