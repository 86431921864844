/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'comment': {
    width: 14,
    height: 12,
    viewBox: '0 0 14 12',
    data: '<path pid="0" d="M6.27 11.997L14 12l-2.306-3.141c.479-.85.75-1.824.75-2.859 0-3.314-2.785-6-6.222-6C2.786 0 0 2.686 0 6c0 3.258 2.697 5.895 6.053 5.984v.013h.14l.03.003c.015 0 .03-.003.046-.003z"/>'
  }
})
