/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vk': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.586 0c6.304 0 9.456 0 11.435 1.979C28 3.885 28 7.109 28 13.413v1.173c0 6.304 0 9.456-1.979 11.435C24.115 28 20.891 28 14.586 28h-1.172c-6.304 0-9.456 0-11.435-1.979C0 24.115 0 20.891 0 14.586v-1.172C0 7.11 0 3.958 1.979 1.979 3.885 0 7.109 0 13.414 0h1.172zm6.084 19.864h2.052c.587 0 .88-.293.587-.88-.22-.586-.88-1.466-1.76-2.419-.257-.343-.615-.687-.912-.971-.21-.201-.389-.373-.48-.494-.293-.367-.22-.514 0-.88 0 0 2.492-3.518 2.785-4.691.147-.44 0-.733-.586-.733h-2.052c-.513 0-.733.293-.88.586 0 0-1.026 2.566-2.492 4.178-.513.513-.66.66-.953.66-.073 0-.293-.147-.293-.587v-4.03c0-.514-.147-.734-.586-.734h-3.226c-.293 0-.513.22-.513.44 0 .198.11.33.247.498.22.268.514.625.56 1.481v3.005c0 .66-.074.807-.367.807-.66 0-2.346-2.493-3.372-5.425-.146-.586-.366-.806-.88-.806H5.498c-.586 0-.733.293-.733.586 0 .514.66 3.226 3.226 6.744 1.685 2.419 4.031 3.738 6.23 3.738 1.32 0 1.466-.293 1.466-.806v-1.833c0-.586.073-.732.513-.732.293 0 .806.146 2.052 1.319.391.39.69.718.935.987.628.689.904.992 1.484.992z" _fill="#2787F5"/>'
  }
})
