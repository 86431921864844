import { Thread, ThreadsMap } from '../types'
import { ConfiguredParams, Keys } from './types'
import { createThread, addSubCommentId as addSubCommentIdToThread } from './thread'

type Params<T extends Keys> = ConfiguredParams<'threadsMap' | T>

export const putThreadProperties = ({
  threadsMap,
  parentId,
  ...properties
}: Params<'parentId'> & Partial<Thread>): ThreadsMap => {
  const currentThread = threadsMap[parentId]
  if (!currentThread) {
    return threadsMap
  }
  const updatedThread = { ...currentThread, ...properties }
  return {
    ...threadsMap,
    [parentId]: updatedThread,
  }
}

export const setThreadProperties = ({
  threadsMap,
  parentId,
  ...properties
}: Params<'parentId'> & Partial<Thread>): ThreadsMap => {
  const currentThread = threadsMap[parentId]
  const updatedThreadMap = { ...threadsMap, [parentId]: currentThread ?? createThread(parentId) }
  return putThreadProperties({ threadsMap: updatedThreadMap, parentId, ...properties })
}

export const setOtherThreadsInvisible = ({
  threadsMap,
  parentId,
}: Params<'parentId'>): ThreadsMap => {
  const entries = [...Object.entries(threadsMap)]
  const updatedEntries = entries.map(([id, thread]) =>
    Number(id) === parentId ? [id, thread] : [id, { ...thread, isVisible: false }]
  )
  return Object.fromEntries(updatedEntries)
}

export const addSubCommentId = ({
  threadsMap,
  parentId,
  subCommentId,
  hasMore,
}: Params<'parentId' | 'subCommentId' | 'hasMore'>): ThreadsMap => {
  const currentThread = threadsMap[parentId] ?? { ...createThread(parentId), hasMore }
  const updatedThread = addSubCommentIdToThread({ thread: currentThread, subCommentId })
  const updatedThreadsMap = setOtherThreadsInvisible({ threadsMap, parentId })
  return {
    ...updatedThreadsMap,
    [parentId]: updatedThread,
  }
}

export const setThreadIsVisible = ({ threadsMap, parentId }: Params<'parentId'>): ThreadsMap => {
  const updatedThreadsMap = setThreadProperties({
    threadsMap,
    parentId,
    isVisible: true,
  })
  return setOtherThreadsInvisible({ threadsMap: updatedThreadsMap, parentId })
}

export const setAddedSubCommentsCount = ({
  threadsMap,
  parentId,
  addedSubCommentsCount,
}: Params<'parentId' | 'addedSubCommentsCount'>): ThreadsMap => {
  const currentThread = threadsMap[parentId]
  if (!currentThread) {
    return threadsMap
  }
  const updatedThread = { ...currentThread, addedSubCommentsCount }
  return {
    ...threadsMap,
    [parentId]: updatedThread,
  }
}

export const setDeletedSubCommentsCount = ({
  threadsMap,
  parentId,
  deletedSubCommentsCount,
}: Params<'parentId' | 'deletedSubCommentsCount'>): ThreadsMap => {
  const currentThread = threadsMap[parentId]
  if (!currentThread) {
    return threadsMap
  }
  const updatedThread = { ...currentThread, deletedSubCommentsCount }
  return {
    ...threadsMap,
    [parentId]: updatedThread,
  }
}

export const removeThreads = ({ threadsMap, parentIds }: Params<'parentIds'>): ThreadsMap => {
  const entries = [...Object.entries(threadsMap)]
  const updatedEntries = entries.filter(([id]) => !parentIds.includes(Number(id)))
  return Object.fromEntries(updatedEntries)
}

export const increaseThreadPage = ({ threadsMap, parentId }: Params<'parentId'>): ThreadsMap => {
  const currentThread = threadsMap[parentId]
  if (!currentThread) {
    return threadsMap
  }
  const updatedThread = { ...currentThread, page: currentThread.page + 1 }
  return {
    ...threadsMap,
    [parentId]: updatedThread,
  }
}

export const decreaseThreadPage = ({ threadsMap, parentId }: Params<'parentId'>): ThreadsMap => {
  const currentThread = threadsMap[parentId]
  if (!currentThread) {
    return threadsMap
  }
  const page = currentThread.page > 0 ? currentThread.page - 1 : 0
  const updatedThread = { ...currentThread, page }
  return {
    ...threadsMap,
    [parentId]: updatedThread,
  }
}

export const cleanUpThread = ({
  threadsMap,
  parentId,
  commentId,
}: Params<'parentId' | 'commentId'>): ThreadsMap => {
  const currentThread = threadsMap[parentId]
  if (!currentThread) {
    return threadsMap
  }
  const { addedSubCommentIds, addedSubCommentsCount, deletedSubCommentsCount } = currentThread
  const deletedCount = deletedSubCommentsCount + 1
  const tempThread = { ...currentThread, deletedSubCommentsCount: deletedCount }
  const hasCommentId = !!addedSubCommentIds.includes(commentId)
  if (!hasCommentId) {
    return {
      ...threadsMap,
      [parentId]: tempThread,
    }
  }
  const addedIds = addedSubCommentIds.filter((id) => id !== commentId) ?? null
  const addedCount = addedSubCommentsCount > 0 ? addedSubCommentsCount - 1 : 0
  const updatedThread = {
    ...currentThread,
    addedSubCommentsCount: addedCount,
    addedSubCommentIds: addedIds,
  }
  return {
    ...threadsMap,
    [parentId]: updatedThread,
  }
}

export default {}
