<template>
  <div :class="$style.profileBlock">
    <icon :class="$style.icon" name="lock" width="80" height="90" color="#E5E3E3" />
    <div :class="$style.desc">{{ $t('block.app.profile') }}</div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ProfileBlocking',
})
</script>

<style lang="scss" module>
.profileBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;

  .icon {
    margin-bottom: 9px;
  }

  .desc {
    color: #e5e3e3;
    font-weight: 500;
    font-size: 24px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    line-height: 28px;
    text-align: center;
  }
}
</style>
