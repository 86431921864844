// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Profile_section_1F4r0{display:flex;flex-direction:column;min-height:100%}.Profile_section_1F4r0 .Profile_header_2ojqA{position:relative;display:grid;grid-template-columns:auto 1fr;padding:21px;-moz-column-gap:21px;column-gap:21px;background:#fff}@media screen and (max-width:330px){.Profile_section_1F4r0 .Profile_header_2ojqA{padding-right:17px;padding-left:17px;-moz-column-gap:15px;column-gap:15px}}.Profile_section_1F4r0 .Profile_header_2ojqA .Profile_menu_1qTxB{position:absolute;top:21px;right:0;width:45px;padding-top:0;padding-bottom:0}.Profile_section_1F4r0 .Profile_activation_1fDPU{margin:10px}", ""]);
// Exports
exports.locals = {
	"section": "Profile_section_1F4r0",
	"header": "Profile_header_2ojqA",
	"menu": "Profile_menu_1qTxB",
	"activation": "Profile_activation_1fDPU"
};
module.exports = exports;
