import Vue from 'vue'
import vueScrollBehavior from 'vue-scroll-behavior'
import router from '@router/index'

// Using vueScrollBehavior
Vue.use(vueScrollBehavior, {
  router, // The router instance
  el: '#main', // Custom element
  ignore: [], // ignore some routes, they will directly scroll to the top
  delay: 10, // Delay by a number of milliseconds
})
