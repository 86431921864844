// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VToast_toast_2812I{justify-content:flex-start;background:#fff;color:#000;padding:15px;border-radius:6px;gap:15px}.VToast_toast_2812I,.VToast_toast_2812I .VToast_iconWrapper_1P6az{display:flex;align-items:center}.VToast_toast_2812I .VToast_iconWrapper_1P6az{justify-content:center;padding:9px;border-radius:50%;background:var(--supster-primary)}.VToast_toast_2812I .VToast_iconWrapper_1P6az .VToast_icon_VBdZ2{width:12px;height:12px}.VToast_toast_2812I .VToast_text_2PNZM{color:#000;font-family:Roboto,sans-serif;font-style:normal;font-size:12px;line-height:18px}.VToast_toast_2812I .VToast_text_2PNZM .VToast_title_3aRST{font-weight:500}.VToast_toast_2812I .VToast_text_2PNZM .VToast_message_3hayd{font-weight:400}.VToast_error_19p_n{background:#fb4e4e}.VToast_error_19p_n .VToast_iconWrapper_1P6az{background:#000}.VToast_error_19p_n .VToast_text_2PNZM{color:#fff}", ""]);
// Exports
exports.locals = {
	"toast": "VToast_toast_2812I",
	"iconWrapper": "VToast_iconWrapper_1P6az",
	"icon": "VToast_icon_VBdZ2",
	"text": "VToast_text_2PNZM",
	"title": "VToast_title_3aRST",
	"message": "VToast_message_3hayd",
	"error": "VToast_error_19p_n"
};
module.exports = exports;
