/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'channels': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M14 0H4a4 4 0 00-4 4v10a4 4 0 004 4h10a4 4 0 004-4V4a4 4 0 00-4-4zm3 14c0 1.654-1.346 3-3 3h-4c-1.654 0-3-1.346-3-3v-4c0-1.654 1.346-3 3-3h4c1.654 0 3 1.346 3 3v4zm-3-8h-4a4 4 0 00-4 4v4c0 1.201.54 2.267 1.38 3H7c-1.654 0-3-1.346-3-3V7c0-1.654 1.346-3 3-3h7c1.654 0 3 1.346 3 3v.38A3.974 3.974 0 0014 6zm0-3H7a4 4 0 00-4 4v7c0 1.201.54 2.267 1.38 3H4c-1.654 0-3-1.346-3-3V4c0-1.654 1.346-3 3-3h10c1.654 0 3 1.346 3 3v.38A3.974 3.974 0 0014 3z"/>'
  }
})
