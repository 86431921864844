import { MediaObject, MediaObjects } from '@services/media/types'
import { Message } from '@common-types/chat/chat-message'
import { Post } from '@common-types/post'

type Entity = Message | Post

export const replaceEntityMediaObject = <T extends Entity>(
  entity: T,
  mediaObject: MediaObject
): T => {
  const mediaObjects =
    entity.media_objects?.map((media) => (media.id === mediaObject.id ? mediaObject : media)) ??
    null
  return { ...entity, media_objects: mediaObjects }
}

export const hasVideo = (mediaObjects: MediaObjects): boolean =>
  mediaObjects?.some((item) => item.mime.includes('video'))
export default {
  replaceEntityMediaObject,
}
