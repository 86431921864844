// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatMessageActions_container_vaocS .ChatMessageActions_button_3yksC{position:relative;z-index:2;display:flex;align-items:center;justify-content:center;width:100%;height:40px;padding:10px 20px;color:#fff;text-transform:uppercase;background:#000;border-radius:6px;cursor:pointer}.ChatMessageActions_container_vaocS .ChatMessageActions_button_3yksC:hover{opacity:.9}.ChatMessageActions_container_vaocS .ChatMessageActions_button_3yksC:before{position:absolute;top:0;left:0;z-index:-1;width:100%;height:100%;background:var(--supster-primary);border-radius:6px;opacity:.5;content:\"\"}.ChatMessageActions_container_vaocS .ChatMessageActions_buttonIcon_2BckH{position:absolute;top:0;right:0;margin:3px;transform:rotate(45deg)}", ""]);
// Exports
exports.locals = {
	"container": "ChatMessageActions_container_vaocS",
	"button": "ChatMessageActions_button_3yksC",
	"buttonIcon": "ChatMessageActions_buttonIcon_2BckH"
};
module.exports = exports;
