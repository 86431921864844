/* eslint-disable camelcase */
import OrdersApi from '@services/order'
import { Actions } from './types'

const actions: Actions = {
  async getOrder({ dispatch, commit }, id) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const order = await OrdersApi.getOrder(id)
      commit('setOrder', order)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postOrder({ dispatch, commit }, params) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const order = await OrdersApi.postOrder(params)
      commit('setOrder', order)
      return order
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
