/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-bottom': {
    width: 17,
    height: 9,
    viewBox: '0 0 17 9',
    data: '<path pid="0" d="M8.146 8.854l-8-8a.498.498 0 010-.708.5.5 0 01.708 0L8.5 7.793 16.146.146a.5.5 0 11.707.708l-8 8a.5.5 0 01-.707 0z"/>'
  }
})
