// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PChatMessage_container_2csYQ{width:100%;display:flex;justify-content:flex-start;margin-bottom:10px}.PChatMessage_container_2csYQ .PChatMessage_section_1554s{width:80%;display:grid;grid-gap:9px;grid-template-columns:54px 1fr;align-items:center;padding:9px;background:#fff;box-shadow:inset 0 -1px 0 #e5e3e3;border-radius:10px;border-top-left-radius:0}.PChatMessage_even_251KD{justify-content:end}.PChatMessage_even_251KD .PChatMessage_section_1554s{grid-template-columns:1fr 54px;border-top-right-radius:0;border-top-left-radius:10px}.PChatMessage_avatar_2nYu7{width:54px;height:54px;padding:2px;background:#fff;border:2px solid #f2f2f2;border-radius:45px}.PChatMessage_avatarContent_2EMcx{width:46px;height:46px;background:#f2f2f2;border-radius:50%}.PChatMessage_content_2UJTs{display:grid;grid-gap:12px}.PChatMessage_title_3qxfl{width:65%;height:12px}.PChatMessage_description_2kn6U,.PChatMessage_title_3qxfl{background:#f2f2f2;border-radius:6px}.PChatMessage_description_2kn6U{width:45%;height:9px}", ""]);
// Exports
exports.locals = {
	"container": "PChatMessage_container_2csYQ",
	"section": "PChatMessage_section_1554s",
	"even": "PChatMessage_even_251KD",
	"avatar": "PChatMessage_avatar_2nYu7",
	"avatarContent": "PChatMessage_avatarContent_2EMcx",
	"content": "PChatMessage_content_2UJTs",
	"title": "PChatMessage_title_3qxfl",
	"description": "PChatMessage_description_2kn6U"
};
module.exports = exports;
