<template>
  <div
    :href="isVideo ? null : contentUrl"
    :data-src="isVideo ? null : contentUrl"
    :data-video="isVideo ? dataVideo : null"
    data-sub-html=" "
    :data-lg-size="lgSize"
    :data-poster="isVideo ? previewUrl : null"
    :class="[
      'lightgallery-element',
      $style.mediaWrapper,
      isHorizontal && $style.horizontal,
      isMonoHorizontal && $style.monoHorizontal,
      isChatGPTBot && $style.wide,
    ]"
  >
    <v-media
      :media="media"
      :inner-classes="[$style.content]"
      :is-observed="isVideo && index === 0"
      :no-play="index > 0"
      :class="$style.media"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VMedia from '@layouts/VMedia.vue'

export default Vue.extend({
  name: 'ChatMediaItem',
  components: { VMedia },
  props: {
    media: {
      type: Object,
      default: () => ({}),
    },
    index: Number,
    isMonoHorizontal: Boolean,
  },
  computed: {
    ...mapGetters('Files', ['filesResolution']),
    ...mapGetters('Chat', ['isChatGPTBot']),
    contentUrl() {
      return this.media?.content_url ?? null
    },
    previewUrl() {
      return this.media?.preview_url ?? null
    },
    mediaMime() {
      return this.media?.mime?.includes('quicktime') ? 'video/mp4' : this.media?.mime
    },
    dataVideo() {
      return `{
        "source": [{ "src": "${this.media.content_url}", "type": "${this.mediaMime}" }],
        "attributes": { "preload": true, "playsinline": true, "controls": true }}`
    },
    isVideo() {
      return this.mediaMime?.includes('video') ?? false
    },
    isImage() {
      return this.mediaMime?.includes('image') ?? false
    },
    dimensions() {
      return this.media.width && this.media.height
        ? `${this.media.width}-${this.media.height}`
        : null
    },
    fileDimensions() {
      const { width, height } = this.filesResolution?.[this.media.id] ?? {}
      return width && height ? `${width}-${height}` : null
    },
    lgSize() {
      return this.dimensions ?? (this.isImage && this.fileDimensions) ?? null
    },
    isHorizontal() {
      return this.media.width > this.media.height
    },
  },
})
</script>

<style lang="scss" module>
.mediaWrapper {
  position: relative;
  flex: 1;
  min-width: 80px;
  max-height: 450px;
  overflow: hidden;
  border-radius: 6px;
  height: 150px;

  .media {
    position: relative !important;
  }

  .content {
    position: static !important;
  }
}

.horizontal {
  flex: 2;
  min-width: 136px;
  min-height: 78px;
}

.monoHorizontal {
  min-width: 280px;
  height: 158px;
}
.wide {
  height: auto;
}
</style>
