<template>
  <div>
    <VDrawer
      name="post-menu"
      :is-back="!isSelectingAction"
      :title="drawerTitle"
      @close="onClose"
      @back="onBack"
    >
      <transition-group :class="$style.content" name="scroll-horizontal">
        <VList key="actions" v-if="isSelectingAction" :list="menu" @click="onMenu" />
        <VList key="claims" v-if="isSelectingClaims" :list="claims" @click="onSelectClaim"></VList>
      </transition-group>
    </VDrawer>
    <ConfirmModal
      :is-visible.sync="isDeleteModal"
      :title="$t('post.confirmTitle')"
      @confirm="onDelete"
      @cancel="onCloseDeleteModal"
      @close="onCloseDeleteModal"
    />
    <VModal
      :is-visible="isReportModal"
      :title="$t('report.title')"
      :description="$t('report.description')"
    >
      <VButton color="primary" size="xl" @click="onCloseReportModal"
        >{{ $t('confirm.ok') }}
      </VButton>
    </VModal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VModal from '@modals/VModal.vue'
import VList from '@elements/VList.vue'
import ConfirmModal from '@modals/list/ConfirmModal.vue'
import VButton from '@elements/VButton.vue'
import { ToastTypes } from '@modules/toasts/constants'
import { ProfileClaims, PostClaims } from './config'

export default Vue.extend({
  name: 'VReportMenu',
  components: { VDrawer, VModal, VList, ConfirmModal, VButton },
  props: { isPostPage: Boolean },
  data() {
    return {
      isDeleteModal: false,
      isReportModal: false,
      isBlockModal: false,
      isSelectingAction: true,
      isSelectingClaims: false,
      isReportUser: false,
    }
  },
  computed: {
    ...mapGetters('App', ['appName', 'appClientId']),
    ...mapGetters('Client', ['clientId', 'isClientOwner']),
    ...mapGetters('Post', ['post', 'postId']),
    ...mapGetters('Drawers', ['drawerParams']),
    ...mapGetters('Locale', ['locale']),
    menu() {
      return [
        {
          name: 'report-user',
          title: this.$t('report.user'),
          hidden: this.isPostOwner,
        },
        {
          name: 'report-post',
          title: this.$t('report.post'),
          hidden: this.isPostOwner || this.fromProfile,
        },
        {
          name: 'block-user',
          title: this.$t('blockUser'),
          hidden: this.isPostOwner,
        },
        {
          name: 'block-post',
          title: this.$t('block.app.post'),
          hidden: this.isPostOwner || this.fromProfile,
        },
        { name: 'edit', title: this.$t('post.edit'), hidden: !this.isPostOwner },
        { name: 'delete', title: this.$t('post.delete'), hidden: !this.isPostOwner },
      ]
    },
    postAppName() {
      return this.post?.app?.name ?? null
    },
    postAppId() {
      return this.post?.app?.id ?? null
    },
    profileAppName() {
      return this.drawerParams?.app?.name ?? null
    },
    profileAppId() {
      return this.drawerParams?.app?.id ?? null
    },
    fromProfile() {
      return this.drawerParams?.fromProfile ?? false
    },
    isPostOwner() {
      return this.clientId === this.post?.app?.user?.client?.id
    },
    drawerTitle() {
      return (this.isSelectingClaims && this.$t('report.reason')) || ''
    },
    claims() {
      return this.fromProfile || this.isReportUser
        ? this.getClaimList(ProfileClaims)
        : this.getClaimList(PostClaims)
    },

    transitionName() {
      return this.isSelectingAction ? 'scroll-group' : 'scroll-horizontal'
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapActions('Post', ['deletePost']),
    ...mapMutations('Toasts', ['pushToast']),

    getClaimList(claims) {
      return claims.map((claimName) => {
        return {
          name: claimName,
          title: this.$t(`claims.${claimName}`),
        }
      })
    },
    async onMenu(link) {
      if (link.includes('report')) {
        this.isSelectingClaims = true
        this.isSelectingAction = false
        this.isReportUser = link === 'report-user'
      }
      if (link.includes('block')) {
        const params = {
          isPostPage: this.isPostPage,
          appId: this.fromProfile ? this.profileAppId : this.postAppId,
          appName: this.fromProfile ? this.profileAppName : this.postAppName,
        }
        this.onClose()
        this.setDrawer({
          name: 'blocking',
          isVisible: true,
          params,
        })
      }
      if (link === 'edit') {
        this.$router.push({ name: 'PostEdit', params: { id: this.postId, locale: this.locale } })
        this.onClose()
      }
      if (link === 'delete') {
        this.isDeleteModal = true
      }
    },
    async onDelete() {
      this.isDeleteModal = false
      this.onClose()
      const success = await this.deletePost(this.postId)
      if (!success) {
        this.pushToast({
          type: ToastTypes.ERROR,
          title: this.$t('post.deleting'),
          message: this.$t('post.deleteError'),
        })
        return
      }
      if (this.isPostPage) {
        this.$router.go(-1)
      }
    },
    onCloseReportModal() {
      this.isReportModal = false
      this.onClose()
    },
    onBack() {
      this.isSelectingAction = true
      this.isSelectingClaims = false
    },
    onSelectClaim(claimName) {
      this.onClose()
      this.setDrawer({
        name: 'claim-confirm',
        isVisible: true,
        params: {
          claimName,
        },
      })
    },
    onClose() {
      this.onBack()
      this.setDrawer({ name: 'post-menu', isVisible: false })
    },
    onCloseDeleteModal() {
      this.isDeleteModal = false
      this.onClose()
    },
  },
})
</script>

<style lang="scss" module>
.content {
  position: relative;
  overflow: hidden;
  background: $shadow;
  box-shadow: inset 0 1px 0 $secondary-light;
}
</style>
