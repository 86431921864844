// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VBurger_burger_3QU4f{justify-content:center;width:100%;height:100%;cursor:pointer}.VBurger_burger_3QU4f,.VBurger_button_35_YH{display:flex;align-items:center}.VBurger_button_35_YH{position:relative;width:21px;height:11px;background-color:transparent;transition:all .2s ease-in}.VBurger_buttonOpen_2Ubhh{transform:rotate(180deg)}.VBurger_wrapper_9NQOi,.VBurger_wrapper_9NQOi:after,.VBurger_wrapper_9NQOi:before{display:block;width:100%;height:1px;background:var(--supster-main-text-color);transition:all .25s cubic-bezier(.4,0,.2,1)}.VBurger_wrapper_9NQOi:before{transform:translateY(-5px)}.VBurger_wrapper_9NQOi:after,.VBurger_wrapper_9NQOi:before{position:absolute;left:0;content:\"\"}.VBurger_wrapper_9NQOi:after{transform:translateY(5px)}.VBurger_wrapperOpen_wWjC4{background:transparent!important}.VBurger_wrapperOpen_wWjC4:before{transform:rotate(-45deg)}.VBurger_wrapperOpen_wWjC4:after{transform:rotate(45deg)}", ""]);
// Exports
exports.locals = {
	"burger": "VBurger_burger_3QU4f",
	"button": "VBurger_button_35_YH",
	"buttonOpen": "VBurger_buttonOpen_2Ubhh",
	"wrapper": "VBurger_wrapper_9NQOi",
	"wrapperOpen": "VBurger_wrapperOpen_wWjC4"
};
module.exports = exports;
