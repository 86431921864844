import { PostId } from '@common-types/post'
import { VirtualizationConfig } from '../config'
import { Getters } from '../types'

const getters: Getters = {
  postIds: (state) => state.postIds,
  postIdsLength: (state) => state.postIds.length,
  visiblePostsIds: (s, getters) =>
    getters.postIds.slice(
      getters.startIndex,
      getters.startIndex + VirtualizationConfig.VISIBLE_POSTS_COUNT
    ),
  topHiddenPostIds: (s, getters) => getters.postIds.slice(0, getters.startIndex),
  wrapperPaddingTop: (state, getters) =>
    getters.topHiddenPostIds.reduce((acc: number, postId: PostId) => {
      const height = state.componentsHeights[postId] ?? getters.averageComponentHeight
      return acc + height
    }, 0),
  wrapperPaddingBottom: (state, getters) =>
    state.postIds.slice(state.startIndex + VirtualizationConfig.VISIBLE_POSTS_COUNT + 1).length *
    getters.averageComponentHeight,
  startIndex: (state) => state.startIndex,
  componentsHeights: (state) => state.componentsHeights,
  averageComponentHeight: (state) => {
    const heights = Object.values(state.componentsHeights)
    const averageHeight =
      heights.length && heights.reduce((acc, height) => acc + height, 0) / heights.length
    return averageHeight || VirtualizationConfig.APPROXIMATE_COMPONENT_HEIGHT
  },
  visiblePosts: (state) => state.visiblePosts,
  intersectedPostId: (state) => state.intersectedPostId,
}

export default getters
