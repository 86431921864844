import { Getters } from './types'

const getters: Getters = {
  post: (state) => state.post,
  postAppName: (state) => state.post?.app?.name ?? null,
  postAuthorAvatar: (state) => (type: 'content_url' | 'preview_url') =>
    state.post?.app?.image[type] ?? null,
  postCreated: (state) => state.post?.created ?? null,
  postId: (state) => state.post?.id ?? null,
  postName: (state) => state.post?.name ?? null,
  postBody: (state) => state.post?.body ?? null,
  postProduct: (state) => state.post?.products?.[0] ?? null,
  postMeta: (state) => state.postMeta,
}

export default getters
