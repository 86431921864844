export const VirtualizationConfig = {
  VISIBLE_POSTS_COUNT: 5,
  SCROLL_STEP: 1,
  TOP_PADDING: 200,
  APPROXIMATE_COMPONENT_HEIGHT: 580,
}

export default {
  REQUESTS_PARAMS: {
    direction: 'desc',
    is_accessible: false,
    limit: 5,
    random: false,
    subscribed: true,
  },
}
