import { BindingValue, SwipeDirection, Handler } from '@plugins/eventSwipe/types'
import config from '@plugins/eventSwipe/config'

export const isAccessibleHandlers = (value: BindingValue): boolean => {
  const { args, ...handlers } = value
  return Object.values(handlers).every((handler) => !handler || typeof handler === 'function')
}

export const getSwipeDirection = (
  offsetX: number,
  offsetY: number
): [SwipeDirection, SwipeDirection] | null => {
  const absX = Math.abs(offsetX)
  const absY = Math.abs(offsetY)
  const maxDistance = Math.max(absX, absY)
  if (maxDistance < config.MIN_DISTANCE) {
    return null
  }
  if (absX > absY) {
    const directionX = offsetX > 0 ? SwipeDirection.RIGHT : SwipeDirection.LEFT
    return [directionX, SwipeDirection.HORISONTAL]
  }
  const directionY = offsetY > 0 ? SwipeDirection.DOWN : SwipeDirection.UP
  return [directionY, SwipeDirection.VERTICAL]
}

export default {
  isAccessibleHandlers,
  getSwipeDirection,
}
