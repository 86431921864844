// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionExpiredDrawer_content_2G13D{padding:20px;display:flex;flex-direction:column;gap:20px}.SubscriptionExpiredDrawer_content_2G13D .SubscriptionExpiredDrawer_iconWrapper_Sc5AO{height:200px;display:flex;justify-content:center;align-items:center}.SubscriptionExpiredDrawer_content_2G13D .SubscriptionExpiredDrawer_text_1-BvB{text-align:center}.SubscriptionExpiredDrawer_content_2G13D .SubscriptionExpiredDrawer_button_2_gVd{height:50px}", ""]);
// Exports
exports.locals = {
	"content": "SubscriptionExpiredDrawer_content_2G13D",
	"iconWrapper": "SubscriptionExpiredDrawer_iconWrapper_Sc5AO",
	"text": "SubscriptionExpiredDrawer_text_1-BvB",
	"button": "SubscriptionExpiredDrawer_button_2_gVd"
};
module.exports = exports;
