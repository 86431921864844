export default {
  CHATS: /\/api\/.*\/channels\?(?=.*followed=true)(?=.*page=\d)(?=.*order\[firstly_app_type]=desc)/,
  FEED: /\/api.*\/posts\?(?=.*direction=\w+)(?=.*page=\d)(?=.*is_accessible=false)(?=.*random=false)(?=.*subscribed=true)/,
  POST: /\/api\/.*\/posts\/\d+$/,
  MESSAGES: (id: number): RegExp =>
    new RegExp(`/api/.*/channels/${id}/channel_messages\\?(?=.*page=\\d)`),
  CHAT: /\/api\/.*\/channels\/\d+$/,
  PROFILE_CHATS: /\/api\/app\/v1\/channels\?(?=.*followed=\w+)(?=.*app_id=\w+)\S+$/, // \/api.*\/channels\?.*followed=false.*&app_id=/,
  PROFILE_POSTS:
    /\/api\/app\/v1\/posts\?(?=.*direction=\w+)(?=.*random=\w+)(?=.*app_id=\w+)(?=.*exists\[products]=\w+)(?=.*media_type=\w+)\S+$/,
  PROFILE_IMAGES:
    /\/api\/app\/v1\/posts\?(?=.*direction=\w+)(?=.*random=\w+)(?=.*app_id=\w+)(?=.*exists\[products]=\w+)(?=.*media_type=image)\S+$/,
  PROFILE_VIDEOS:
    /\/api\/app\/v1\/posts\?(?=.*direction=\w+)(?=.*random=\w+)(?=.*app_id=\w+)(?=.*exists\[products]=\w+)(?=.*media_type=video)\S+$/,
  SEARCH_POSTS:
    /\/api\/.*\/posts\?(?=.*direction=\w+)(?=.*exists\[products]=\w+)(?=.*is_accessible=\w+)(?=.*random=true)(?=.*limit=\d+)(?=.*page=\d+)(?=.*search=.*)/,
  COMMENTS: /\/api.*\/comments\?(?=.*post_id=\d+)/,
  SUBSCRIPTIONS: /\/api\/.*\/subscriptions?(?=.*page=\d)(?=.*limit=\d+)/,
  ORDERS: /\/api\/.*\/orders?(?=.*page=\d)(?=.*limit=\d+)(?=.*order\[created]=\w+)/,
  NEW_MESSAGE_COUNT: /\/api\/.*\/channels?(?=.*exists\[new_message_count]=true)(?=.*page=\d)/,
}
