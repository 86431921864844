<template>
  <div :class="$style.container">
    <v-image :class="$style.image" :src="src" :srcset="srcset" />
    <div :class="$style.content">
      <div :class="$style.wrapper">
        <v-button
          size="md"
          color="transparent"
          :class="$style.delete"
          is-disabled-padding
          @click="removeCartProduct(product.id)"
        >
          <icon name="close" width="13" height="13" />
        </v-button>
        <p :class="$style.name">{{ name }}</p>
      </div>
      <div :class="$style.footer">
        <v-counter
          :value="count"
          @add="setCartProductCount({ id: product.id, type: 'increment' })"
          @remove="setCartProductCount({ id: product.id, type: 'decrement' })"
        />
        <span :class="$style.price">{{ currency }}{{ price }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import VImage from '@elements/VImage.vue'
import VCounter from '@layouts/VCounter.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'CartProduct',
  components: { VImage, VCounter, VButton },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('App', ['appCurrencySymbol']),
    ...mapGetters('Cart', ['cartProductCount']),
    src() {
      return this.product?.media_objects[0]?.content_url ?? ''
    },
    srcset() {
      return this.product?.media_objects[0]?.preview_url ?? ''
    },
    name() {
      return this.product?.name ?? ''
    },
    count() {
      return this.cartProductCount(this.product.id)
    },
    currency() {
      return this.product?.app?.currency?.symbol || this.appCurrencySymbol()
    },
    price() {
      return this.product.price
    },
  },
  methods: {
    ...mapMutations('Cart', ['removeCartProduct', 'setCartProductCount']),
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: grid;
  grid-template-columns: 90px 1fr;
  padding: 15px;
  column-gap: 15px;
  background-color: white;
}

.delete {
  position: absolute !important;
  top: 0;
  right: 0;
  width: auto !important;
}

.image {
  width: 90px !important;
  height: 90px !important;
  overflow: hidden;
  border-radius: 6px;
}

.content {
  display: grid;
  grid-template-rows: 1fr auto;
  padding: 9px 0;
  color: $secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  row-gap: 9px;
}

.wrapper {
  position: relative;
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  row-gap: 9px;
}

.name {
  width: calc(100% - 15px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.properties {
  color: $secondary-medium;
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: space-between;
  column-gap: 10px;
}

.price {
  align-self: center;
  font-weight: 500;
}
</style>
