import { Module, CartProductType } from './types'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const cartJSON = localStorage.getItem('cart')
const cartLS: CartProductType[] | null = cartJSON ? JSON.parse(cartJSON) : null
const module: Module = {
  state: () => ({
    products: cartLS,
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default module
