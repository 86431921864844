import { RouteConfig } from 'vue-router'
import ProfileVideosFeed from '@modules/profile/modules/profile-videos/modules/profile-videos-feed/ProfileVideosFeed.vue'
import Profile from '@modules/profile/Profile.vue'
import ProfileImagesFeed from '@modules/profile/modules/profile-images/modules/profile-images-feed/ProfileImagesFeed.vue'
import Chat from '@modules/chat/Chat.vue'
import Chats from '@modules/chats/Chats.vue'
import PaymentResult from '@views/PaymentResult.vue'
import SearchFeed from '@modules/search/modules/posts/modules/search-feed/SearchFeed.vue'
import Search from '@modules/search/Search.vue'
import Feed from '@modules/feed/Feed.vue'
import RoutesNames from './routes-names'

const navigation: Array<RouteConfig> = [
  {
    path: 'install',
    name: RoutesNames.INSTALL,
    meta: { requiresAuth: false },
    component: () => import('@views/Install.vue'),
  },
  {
    path: 'feed',
    name: RoutesNames.FEED,
    meta: { requiresAuth: true },
    props: true,
    component: Feed,
  },
  {
    path: 'post/:id',
    name: RoutesNames.POST,
    meta: { requiresAuth: true },
    props: true,
    component: Feed,
  },
  {
    path: 'post/:id/comments',
    name: RoutesNames.POST_COMMENTS,
    meta: { requiresAuth: true },
    props: true,
    component: () => import('@views/PostComments.vue'),
  },
  {
    path: 'product/:id',
    name: RoutesNames.PRODUCT,
    meta: { requiresAuth: true },
    props: true,
    component: () => import('@views/product/Product.vue'),
  },
  {
    path: 'broadcast/:id',
    name: RoutesNames.BROADCAST,
    meta: { requiresAuth: true },
    props: true,
    component: () => import('@views/broadcast/Broadcast.vue'),
  },
  {
    path: 'search',
    name: RoutesNames.SEARCH,
    meta: { requiresAuth: true },
    props: true,
    component: Search,
  },
  {
    path: 'feed/:id',
    name: RoutesNames.SEARCH_FEED,
    meta: { requiresAuth: true },
    props: true,
    component: SearchFeed,
  },
  {
    path: 'tags/:id',
    name: RoutesNames.TAG,
    meta: { requiresAuth: true },
    component: () => import('@views/tag/Tag.vue'),
  },
  {
    path: 'chats',
    name: RoutesNames.CHATS,
    meta: { requiresAuth: true },
    props: true,
    component: Chats,
  },
  {
    path: 'chats/share',
    name: RoutesNames.CHAT_SHARED,
    meta: { requiresAuth: true },
    component: Chat,
  },
  {
    path: 'chats/:id',
    name: RoutesNames.CHAT,
    meta: { requiresAuth: true },
    component: Chat,
  },
  {
    path: 'new-gpt-chat',
    name: RoutesNames.NEW_GPT_CHAT,
    meta: { requiresAuth: true },
    component: Chat,
  },
  {
    path: 'support-chat',
    name: RoutesNames.SUPPORT_CHAT,
    meta: { requiresAuth: true },
    component: Chat,
  },
  {
    path: 'profile',
    name: RoutesNames.PROFILE,
    meta: { requiresAuth: true },
    props: true,
    component: Profile,
  },
  {
    path: 'profile/:id',
    name: RoutesNames.OTHER_PROFILE,
    meta: { requiresAuth: true },
    props: true,
    component: Profile,
  },
  {
    path: 'profile/images-feed/:id',
    name: RoutesNames.PROFILE_IMAGES_FEED,
    meta: { requiresAuth: true },
    props: true,
    component: ProfileImagesFeed,
  },
  {
    path: 'profile/videos-feed/:id',
    name: RoutesNames.PROFILE_VIDEOS_FEED,
    meta: { requiresAuth: true },
    props: true,
    component: ProfileVideosFeed,
  },
  {
    path: 'my-profile',
    name: RoutesNames.MY_PROFILE,
    meta: { requiresAuth: true },
    props: true,
    component: Profile,
  },
  {
    path: 'stories/:id',
    name: RoutesNames.STORIES,
    meta: { requiresAuth: true },
    props: true,
    component: () => import('@views/Stories.vue'),
  },
  {
    path: 'documents/:slug',
    name: RoutesNames.DOCUMENTS,
    meta: { requiresAuth: false },
    props: true,
    component: () => import('@views/Documents.vue'),
  },
  {
    path: 'introduction/:step',
    name: RoutesNames.INTRODUCTION,
    meta: { requiresAuth: false },
    props: true,
    component: () => import('@modules/introduction/Introduction.vue'),
  },
  {
    path: 'payment/success',
    name: RoutesNames.PAYMENT_SUCCESS,
    meta: { requiresAuth: false },
    props: true,
    component: PaymentResult,
  },
]

export const routeNamesGPT = [
  'Documents',
  'Install',
  'Chats',
  'ChatShared',
  'Chat',
  'NewGptChat',
  'SupportChat',
  'MyProfile',
  'Promotional',
  'Subscriptions',
  'Orders',
  'Order',
  'settings',
  'notifications',
  'ClientEdit',
  'Introduction',
  'PaymentSuccess',
]

export const routeNamesTestCode6 = [
  'Documents',
  'Install',
  'Chats',
  'Chat',
  'SupportChat',
  'MyProfile',
  'Promotional',
  'Subscriptions',
  'Orders',
  'Order',
  'settings',
  'notifications',
  'ClientEdit',
  'Introduction',
  'PaymentSuccess',
]

export default navigation
