<template>
  <div :class="$style.toasts">
    <transition-group name="list">
      <VToast v-for="toast in toasts" :key="toast.id" :toast="toast" :class="$style.toast" />
    </transition-group>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VToast from '@/modules/toasts/VToast.vue'

export default Vue.extend({
  name: 'Toasts',
  components: {
    VToast,
  },
  computed: {
    ...mapGetters('Toasts', ['toasts']),
  },
})
</script>

<style lang="scss" module>
.toasts {
  display: flex;
  flex-direction: column;
  padding: 6px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1002;
}
</style>
