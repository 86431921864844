/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nesting': {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    data: '<path pid="0" d="M2 8h8V0H2v8zM1 2v7h7v1H0V2h1z"/>'
  }
})
