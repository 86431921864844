import { VirtualizationConfig } from '@modules/feed/config'
import { PostId, PostMeta } from '@common-types/post'
import { splitPosts } from '@helpers/posts'
import { Getters } from '../types'

const getters: Getters = {
  startIndex: (state) => state.startIndex,
  postIds: (s, getters, rs, rootGetters) => {
    const postMeta: PostMeta = rootGetters['Post/postMeta']
    const postIds = rootGetters['Search/Posts/postIds']
    return splitPosts({ postIds, postId: postMeta?.postId })
  },
  postIdsLength: (s, getters) => getters.postIds.length,
  visiblePostsIds: (s, getters) =>
    getters.postIds.slice(
      getters.startIndex,
      getters.startIndex + VirtualizationConfig.VISIBLE_POSTS_COUNT
    ),
  averageComponentHeight: (state) => {
    const heights = Object.values(state.componentsHeights)
    const averageHeight =
      heights.length && heights.reduce((acc, height) => acc + height, 0) / heights.length
    return averageHeight || VirtualizationConfig.APPROXIMATE_COMPONENT_HEIGHT
  },
  visiblePosts: (state) => state.visiblePosts,
  topHiddenPostIds: (s, getters) => getters.postIds.slice(0, getters.startIndex),
  wrapperPaddingTop: (state, getters) =>
    getters.topHiddenPostIds.reduce((acc: number, postId: PostId) => {
      const height = state.componentsHeights[postId] ?? getters.averageComponentHeight
      return acc + height
    }, 0),
  wrapperPaddingBottom: (state, getters) =>
    getters.postIds.slice(state.startIndex + VirtualizationConfig.VISIBLE_POSTS_COUNT + 1).length *
    getters.averageComponentHeight,
}

export default getters
