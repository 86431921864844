<template>
  <VGrid :length="postIdsLength" is-infinite @infinite="loadPosts">
    <div :class="$style.wrapper" :style="wrapperStyles">
      <ProfilePostsBlock
        v-for="(postIds, index) in visibleBlocks"
        :key="getKey(postIds)"
        :class="$style.block"
        :post-ids="postIds"
        :id="index"
        type="common"
        source="Videos"
        :ref="`block-${index}`"
      />
    </div>
    <template #placeholder>
      <PSearchPostsBlock v-for="i in 4" :key="i" :index="i" type="common" />
    </template>
  </VGrid>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VGrid from '@layouts/VGrid.vue'
import Config from '@config/index'
import PSearchPostsBlock from '@placeholders/PSearchPostsBlock.vue'
import ProfilePostsBlock from '@modules/profile/components/ProfilePostsBlock.vue'

import { GridBlockTypes, VirtualizationConfig } from './config'

export default Vue.extend({
  name: 'ProfileVideos',
  components: { ProfilePostsBlock, PSearchPostsBlock, VGrid },
  data() {
    return {
      cacheHandlersMapId: null,
    }
  },
  computed: {
    ...mapGetters('Profile/Videos', [
      'postIds',
      'postIdsLength',
      'visibleBlocks',
      'visiblePosts',
      'wrapperPaddingTop',
      'wrapperPaddingBottom',
      'postBlocks',
      'startIndex',
    ]),
    wrapperStyles() {
      return {
        paddingTop: `${this.wrapperPaddingTop}px`,
        paddingBottom: `${this.wrapperPaddingBottom}px`,
      }
    },
    blockTypes() {
      return {
        1: GridBlockTypes.BIG_LEFT,
        3: GridBlockTypes.BIG_RIGHT,
      }
    },
  },
  methods: {
    ...mapActions('Profile/Videos', ['getPosts']),
    ...mapMutations('Profile/Videos', [
      'resetVisiblePosts',
      'resetPosts',
      'setPosts',
      'setVisiblePosts',
      'setStartIndex',
    ]),
    async loadPosts(scroll) {
      await this.getPosts(scroll)
    },
    addCacheHandler() {
      this.cacheHandlersMapId = this.$cacheManager.add({
        regExpURL: Config.URLRegExps.PROFILE_POSTS,
        cacheHandlers: [(posts) => this.setPosts(posts ?? [])],
      })
    },
    deleteCacheHandler() {
      if (this.cacheHandlersMapId) {
        this.$cacheManager.remove([this.cacheHandlersMapId])
      }
    },
    async refreshSearch() {
      this.resetPosts()
      await this.getPosts()
    },
    scrollListener(event) {
      const scrollTopPadding = event.target.scrollTop - VirtualizationConfig.BLOCK_HEIGHT - 400
      const scrollTop = scrollTopPadding > 0 ? scrollTopPadding : 0
      const startIndex =
        Math.floor(scrollTop / VirtualizationConfig.BLOCK_HEIGHT) * VirtualizationConfig.SCROLL_STEP
      this.checkStartIndex(startIndex)
    },
    checkStartIndex(index) {
      if (this.startIndex !== index) {
        this.setStartIndex(index)
      }
    },
    connectScrollWatcher() {
      const mainWrapper = document.getElementById('main')
      mainWrapper.addEventListener('scroll', this.scrollListener)
    },
    deleteScrollWatcher() {
      const mainWrapper = document.getElementById('main')
      mainWrapper.removeEventListener('scroll', this.scrollListener)
    },
    getKey(postIds) {
      return `block-${postIds[0]}`
    },
  },
  watch: {
    visibleBlocks: {
      handler() {
        this.setVisiblePosts(this.visibleBlocks.flat())
      },
      immediate: true,
    },
  },
  created() {
    this.addCacheHandler()
  },
  mounted() {
    this.connectScrollWatcher()
  },
  beforeDestroy() {
    this.deleteCacheHandler()
    this.deleteScrollWatcher()
  },
})
</script>
<style module lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
</style>
