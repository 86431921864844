// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppLeftSection_container_2SSNj{height:100%;justify-content:space-between;padding:48px;color:var(--supster-main-text-color);width:500px}.AppLeftSection_container_2SSNj,.AppLeftSection_container_2SSNj .AppLeftSection_content_j-0yn{display:flex;flex-direction:column}.AppLeftSection_container_2SSNj .AppLeftSection_content_j-0yn{justify-content:center;align-items:flex-start;row-gap:32px;font-weight:800;padding-bottom:200px}.AppLeftSection_container_2SSNj .AppLeftSection_content_j-0yn .AppLeftSection_pageLabel_BnP6V{font-size:88px;line-height:88px;letter-spacing:-4.4px}.AppLeftSection_container_2SSNj .AppLeftSection_content_j-0yn .AppLeftSection_pageDesc_3Fvrm{font-size:24px;line-height:30px;letter-spacing:-1.2px}.AppLeftSection_container_2SSNj .AppLeftSection_copyright_3XhYt{font-size:12px;line-height:18px}@media(max-width:768px){.AppLeftSection_container_2SSNj{display:none}}", ""]);
// Exports
exports.locals = {
	"container": "AppLeftSection_container_2SSNj",
	"content": "AppLeftSection_content_j-0yn",
	"pageLabel": "AppLeftSection_pageLabel_BnP6V",
	"pageDesc": "AppLeftSection_pageDesc_3Fvrm",
	"copyright": "AppLeftSection_copyright_3XhYt"
};
module.exports = exports;
