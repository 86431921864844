import { GptVersion } from '@common-types/chat'

export enum MenuItemIds {
  EDIT = 'EDIT',
  PIN = 'PIN',
  UNPIN = 'UNPIN',
  REPLY = 'REPLY',
  DELETE = 'DELETE',
}

export const GptVersionLabels = {
  [GptVersion.GPT_3]: 'GPT-3,5 turbo 16k ',
  [GptVersion.GPT_4]: 'GPT-4',
  [GptVersion.MIDJOURNEY]: 'Midjourney',
}

export const excludeRules = {
  myMessage: (isMyMessage: boolean, isVoiceMessage: boolean): MenuItemIds[] | null =>
    isMyMessage
      ? isVoiceMessage
        ? [MenuItemIds.EDIT]
        : null
      : [MenuItemIds.EDIT, MenuItemIds.DELETE],
  pinnedMessage: (isPinned: boolean): MenuItemIds[] =>
    isPinned ? [MenuItemIds.PIN] : [MenuItemIds.UNPIN],
  commonChat: (isChatCommon: boolean): MenuItemIds[] | null =>
    isChatCommon ? [MenuItemIds.PIN, MenuItemIds.UNPIN] : null,
}

export const menuItems = [
  {
    id: MenuItemIds.EDIT,
    name: MenuItemIds.EDIT,
    label: 'edit',
    event: 'edit',
    icon: 'edit-outline',
    iconWidth: '13',
    iconHeight: '13',
  },
  {
    id: MenuItemIds.PIN,
    name: MenuItemIds.PIN,
    label: 'pin',
    event: 'pin',
    icon: 'pin',
    iconWidth: '13',
    iconHeight: '13',
  },
  {
    id: MenuItemIds.UNPIN,
    name: MenuItemIds.PIN,
    label: 'unpin',
    event: 'unpin',
    icon: 'unpin',
    iconWidth: '13',
    iconHeight: '13',
  },
  {
    id: MenuItemIds.REPLY,
    name: MenuItemIds.REPLY,
    label: 'reply',
    event: 'reply',
    icon: 'reply',
    iconWidth: '13',
    iconHeight: '13',
  },
  {
    id: MenuItemIds.DELETE,
    name: MenuItemIds.DELETE,
    label: 'delete',
    event: 'delete',
    icon: 'trash',
    iconWidth: '13',
    iconHeight: '13',
  },
]

export const midjourneyActions = {
  V1: 'V1',
  V2: 'V2',
  V3: 'V3',
  V4: 'V4',
  U1: 'U1',
  U2: 'U2',
  U3: 'U3',
  U4: 'U4',
}

export const midjourneyActionsList = Object.keys(midjourneyActions)

export default {
  PAGE_LIMIT: 20,
}
