import { Actions } from '../types/store'
import Config from '../config'

const actions: Actions = {
  async getPosts({ commit, dispatch, state, rootGetters }, scroll: InfiniteType) {
    const currentApp = rootGetters['App/currentApp']
    const app_id = rootGetters['App/appId'](currentApp)
    const params = {
      ...Config.REQUESTS_PARAMS,
      app_id,
      page: state.page,
    }
    const posts = await dispatch('Post/getPosts', params, { root: true })
    commit('pushPosts', posts)
    if (scroll) {
      posts?.length ? scroll.loaded() : scroll.complete()
    }
    return posts
  },
}

export default actions
