<template>
  <div :class="$style.grid">
    <infinite-loading
      v-if="(isReversed || isBothDirection) && length && isInfinite"
      direction="top"
      :distance="1000"
      slot="append"
      @infinite="$emit('infinite', $event)"
    >
      <template slot="spinner">
        <content-loader :is-loading="length && !nodata" :size="15" :delay="delay" />
      </template>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
    <div :class="[$style.content, $style[className], containerClass]">
      <template v-if="isPlaceholder">
        <slot name="placeholder" />
      </template>
      <slot v-else />
      <!-- <slot name="placeholder" /> -->
    </div>
    <transition name="fade">
      <div
        v-if="(noData || nodataText) && nodata && hasSlot('no-data')"
        :class="$style.noDataWrapper"
      >
        <slot name="no-data" />
      </div>
      <v-no-data
        v-else-if="(noData || nodataText) && nodata"
        :key="length"
        size="sm"
        :text="nodataText"
      />
    </transition>
    <infinite-loading
      v-if="(!isReversed || isBothDirection) && length && isInfinite"
      ref="infiniteLoading"
      :direction="isReversed ? 'bottom' : null"
      slot="append"
      :distance="1000"
      @infinite="isBothDirection ? $emit('infinite-bottom', $event) : $emit('infinite', $event)"
    >
      <template slot="spinner">
        <content-loader :is-loading="length && !nodata" :size="15" :delay="delay" />
      </template>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ContentLoader from '@loaders/list/ContentLoader.vue'
import VNoData from '@layouts/VNoData.vue'

export default Vue.extend({
  name: 'VGrid',
  components: { ContentLoader, VNoData },
  props: {
    length: [String, Number, Boolean],
    delay: Number,
    className: String,
    containerClass: String,
    isInfinite: {
      type: Boolean,
      default: true,
    },
    isReversed: Boolean,
    isBothDirection: Boolean,
    noData: Boolean,
    nodataText: String,
    notLengthWatch: Boolean,
    isFirstLoad: Boolean,
  },
  watch: {
    length(newLength, oldLength) {
      if (this.notLengthWatch) {
        return
      }
      const needReset = newLength && newLength < oldLength
      if (needReset) {
        if (this.$refs.infiniteLoading) {
          const { status } = this.$refs.infiniteLoading
          if (status === 0) {
            this.$refs.infiniteLoading.stateChanger.reset()
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters('Page', ['pageLoading', 'pageIsOffline']),
    nodata() {
      return this.length === 0
    },
    isPlaceholder() {
      return (this.pageLoading || this.pageIsOffline) && !this.length
    },
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name]
    },
  },
  mounted() {
    if (!this.length && (this.isInfinite || this.isFirstLoad)) {
      this.$emit('infinite')
    }
  },
})
</script>
<style lang="scss" module>
.grid {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;

  &Reversed {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.content {
  display: grid;

  &:not(.posts) {
    grid-auto-rows: min-content;
  }

  &.shop {
    grid-template-columns: repeat(6, 1fr);
    padding: 6px;
    column-gap: 6px;
    row-gap: 12px;
  }

  &.posts {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5px;
    row-gap: 1.5px;
  }

  &.subscriptions {
    grid-gap: 12px;
  }

  &.orders {
    padding: 6px;
    row-gap: 6px;
  }
}
.noDataWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
