// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileAdd_section_2AhCm{position:relative;cursor:pointer}.ProfileAdd_preview_18f9B{width:50px;color:#7b8b8a}.ProfileAdd_button_1hhC1{position:absolute;right:3px;bottom:3px;width:auto}.ProfileAdd_button_1hhC1 .ProfileAdd_icon_3vijL{width:33px;height:33px;fill:none}", ""]);
// Exports
exports.locals = {
	"section": "ProfileAdd_section_2AhCm",
	"preview": "ProfileAdd_preview_18f9B",
	"button": "ProfileAdd_button_1hhC1",
	"icon": "ProfileAdd_icon_3vijL"
};
module.exports = exports;
