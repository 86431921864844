// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatNameEditDrawer_drawer_1BOlm>*{padding:0 15px 15px 15px}.ChatNameEditDrawer_input_1Gxeb{margin:0;padding:0}.ChatNameEditDrawer_text_2s4rh{font-weight:400;font-size:12px;line-height:18px}.ChatNameEditDrawer_buttonWrapper_7xDvx{padding:15px}", ""]);
// Exports
exports.locals = {
	"drawer": "ChatNameEditDrawer_drawer_1BOlm",
	"input": "ChatNameEditDrawer_input_1Gxeb",
	"text": "ChatNameEditDrawer_text_2s4rh",
	"buttonWrapper": "ChatNameEditDrawer_buttonWrapper_7xDvx"
};
module.exports = exports;
