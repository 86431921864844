import { axiosJWT } from '@plugins/axios'
import { DataType, GetParams } from './types'

export default {
  getOrderHistories: async (params: GetParams): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosJWT.get('order_histories', { params })
    return data === null ? [] : data
  },
  getOrderHistory: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.get(`order_histories/${id}`)
    return data
  },
}
