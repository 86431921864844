// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VNoData_section_2FGTd{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:100%;padding:50px 0;background:transparent}.VNoData_title_3_4mq{display:block;text-align:center}.VNoData_title_3_4mq.VNoData_sm_23vlC{font-size:18px}.VNoData_title_3_4mq.VNoData_md_2vnYA{font-size:22px}.VNoData_title_3_4mq.VNoData_lg_wxgP5{font-size:26px}.VNoData_title_3_4mq.VNoData_xl_17HgI{font-size:30px}", ""]);
// Exports
exports.locals = {
	"section": "VNoData_section_2FGTd",
	"title": "VNoData_title_3_4mq",
	"sm": "VNoData_sm_23vlC",
	"md": "VNoData_md_2vnYA",
	"lg": "VNoData_lg_wxgP5",
	"xl": "VNoData_xl_17HgI"
};
module.exports = exports;
