export enum SwipeDirection {
  DOWN = 'DOWN',
  UP = 'UP',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  HORISONTAL = 'HORISONTAL',
  VERTICAL = 'VERTICAL',
}

export type Handler = (event: TouchEvent, args: any) => void

export type BindingValue = Partial<Record<SwipeDirection, Handler> & { args: any }>

export default {}
