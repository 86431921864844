<template>
  <div>
    <v-drawer name="chats-filter">
      <v-list :class="$style.list" :list="menu" @click="onMenu($event)" :checkedName="chatsName" />
    </v-drawer>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VList from '@elements/VList.vue'
import { ChatsFilterNames } from '@config/index'

export default Vue.extend({
  name: 'ChatsFilterDrawer',
  components: { VDrawer, VList },
  props: { isPostPage: Boolean },
  computed: {
    ...mapGetters('Comments', ['selectedComment']),
    ...mapGetters('Chats', ['chatsName']),
    ...mapGetters('Client', ['isClientOwner']),
    menu() {
      return [
        {
          name: ChatsFilterNames.OWN,
          link: 'my-channels',
          title: this.$t('myChannels'),
          hidden: !this.isClientOwner,
        },
        {
          name: ChatsFilterNames.FOLLOWED,
          link: 'my-subscriptions',
          title: this.$t('mySubscriptions'),
        },
        { name: ChatsFilterNames.PRIVATE, link: 'private-chats', title: this.$t('privateChats') },
        { name: 'cancel', link: 'cancel', color: 'grey', title: this.$t('cancel') },
      ]
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Chats', ['setChatsName']),
    async onMenu(name) {
      if (name !== 'cancel') {
        this.setChatsName(name === this.chatsName ? ChatsFilterNames.ALL : name)
      }
      this.setDrawer({ name: 'chats-filter', isVisible: false })
    },
  },
})
</script>

<style lang="scss" module>
.userPreview {
  padding: 9px;
  border-bottom: 1px solid $secondary-light;
}

.list {
  height: inherit;
}
</style>
