/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots-3': {
    width: 3,
    height: 15,
    viewBox: '0 0 3 15',
    data: '<g filter="url(#filter0_d_1001_50)" _fill="#fff"><path pid="0" d="M2 1a1 1 0 11-2 0 1 1 0 012 0zM2 7a1 1 0 11-2 0 1 1 0 012 0zM1 14a1 1 0 100-2 1 1 0 000 2z"/></g><defs><filter id="filter0_d_1001_50" x="0" y="0" width="3" height="15" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="1" dy="1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1001_50"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1001_50" result="shape"/></filter></defs>'
  }
})
