// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VGrid_grid_bW2lx{position:relative;width:100%;height:100%;background-color:transparent}.VGrid_gridReversed_1-GBR{display:flex;flex-direction:column;justify-content:flex-end}.VGrid_content_1BOpO{display:grid}.VGrid_content_1BOpO:not(.VGrid_posts_1ESg6){grid-auto-rows:min-content}.VGrid_content_1BOpO.VGrid_shop_qVT3I{grid-template-columns:repeat(6,1fr);padding:6px;-moz-column-gap:6px;column-gap:6px;row-gap:12px}.VGrid_content_1BOpO.VGrid_posts_1ESg6{grid-template-columns:repeat(3,1fr);-moz-column-gap:1.5px;column-gap:1.5px;row-gap:1.5px}.VGrid_content_1BOpO.VGrid_subscriptions_1wmuT{grid-gap:12px}.VGrid_content_1BOpO.VGrid_orders_fM60a{padding:6px;row-gap:6px}.VGrid_noDataWrapper_1l8vy{position:absolute;width:100%;height:100%}", ""]);
// Exports
exports.locals = {
	"grid": "VGrid_grid_bW2lx",
	"gridReversed": "VGrid_gridReversed_1-GBR",
	"content": "VGrid_content_1BOpO",
	"posts": "VGrid_posts_1ESg6",
	"shop": "VGrid_shop_qVT3I",
	"subscriptions": "VGrid_subscriptions_1wmuT",
	"orders": "VGrid_orders_fM60a",
	"noDataWrapper": "VGrid_noDataWrapper_1l8vy"
};
module.exports = exports;
