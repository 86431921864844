<template>
  <div :class="$style.container">
    <PChatMessage v-for="i in 8" :key="i" :even="i % 2 === 0" />
  </div>
</template>

<script>
import Vue from 'vue'
import PChatMessage from '@placeholders/PChatMessage.vue'

export default Vue.extend({
  name: 'ChatLoadingPlaceholder',
  components: { PChatMessage },
})
</script>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
</style>
