import { Getters } from './types'

const getters: Getters = {
  cartProducts: (state) => state.products,
  cartProductsLength: (state) => state.products?.length ?? 0,
  cartProductCount: (state) => (id: number) => {
    const product = state.products?.find(({ id: productId }) => productId === id)
    return product ? product.count : 0
  },
}

export default getters
