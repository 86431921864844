/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-chat-group': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M16 0H2a2 2 0 00-2 2v10a2 2 0 002 2h8l6 4v-4a2 2 0 002-2V2a2 2 0 00-2-2zm1 12c0 .551-.449 1-1 1h-1v3.131l-4.445-2.963-.252-.168H2c-.551 0-1-.449-1-1V2c0-.551.449-1 1-1h14c.551 0 1 .449 1 1v10zM4 4h8v1H4V4zm5 3H4V6h5v1zM4 8h6v1H4V8z"/>'
  }
})
