import PromotionalApi from '@services/promotional'
import { FileType } from '@store/files/types'
import { chatDefaultParams, postDefaultParams, Tab } from '../constants'
import { mediaTypes } from '../services'
import { Actions } from '../types'

const actions: Actions = {
  async getProfileContent(
    { commit, dispatch, state },
    { scroll, tab, app_id }: { scroll: InfiniteType; tab: Tab; app_id: string }
  ) {
    const isChats = tab === Tab.CHATS
    const actionName = isChats ? 'Chats/getChats' : 'Post/getPosts'
    const page = state.pages[tab]
    const defaultParams = isChats ? chatDefaultParams : postDefaultParams
    const chatParams = { ...defaultParams, app_id, page }
    const postParams = {
      ...chatParams,
      'exists[products]': tab === Tab.PRODUCTS,
      media_type: mediaTypes[tab],
    }
    const params = isChats ? chatParams : postParams
    const data = await dispatch(actionName, params, { root: true })
    const commitName = page === 1 ? 'setProfileContent' : 'pushProfileContent'
    commit(commitName, { content: data, tab })
    if (scroll) {
      data.length ? scroll.loaded() : scroll.complete()
    }
  },
  async getProfileTariffs({ commit, dispatch }, { app_id }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const tariffs = await PromotionalApi.getPromotionals({
        app_id,
        trial: true,
      })
      commit('setProfileTariffs', tariffs)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async updateProfile({ commit, dispatch }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      await dispatch('updateProfileAvatar')
      await dispatch('updateProfileDescription')
      await dispatch('updateProfileCover')
      return true
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return false
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async updateProfileAvatar({ commit, dispatch, state }) {
    if (state.clientApp?.avatar) {
      const media = await dispatch('Media/postMedia', state.clientApp?.avatar?.data, { root: true })
      await dispatch('App/putApp', { app: { image_id: media[0].id } }, { root: true })
      commit({ type: 'Files/resetFiles' }, { root: true })
      commit('setProfileClientApp', {
        avatar: null,
      })
      return true
    }
    return true
  },
  async updateProfileDescription({ commit, dispatch, state }) {
    if (state.clientApp?.description) {
      await dispatch(
        'App/putApp',
        { app: { description: state.clientApp?.description } },
        { root: true }
      )
      commit('setProfileClientApp', {
        description: null,
      })
    }
    return true
  },
  async updateProfileCover({ commit, dispatch, state }) {
    if (state.clientApp?.header_images?.length) {
      const result = await Promise.all(
        state.clientApp?.header_images.map(async (item: FileType, index: number) => {
          if (item.data) {
            commit('Files/setFiles', [state.clientApp?.header_images?.[index]], {
              root: true,
            })
            const media = await dispatch('Media/postMedia', item, {
              root: true,
            })
            commit('Files/resetFiles', true, {
              root: true,
            })
            return media[0].id
          }
          return item.id
        })
      )
      await dispatch(
        'App/putApp',
        { app: { header_images: state.clientApp?.coverMode === 1 ? [result[0]] : result } },
        { root: true }
      )

      commit('setProfileClientApp', {
        header_images: null,
      })
    }
    return true
  },
}

export default actions
