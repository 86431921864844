<template>
  <div :class="$style.userPreview">
    <v-avatar :src="avatarUrl" :srcset="avatarPreviewUrl" size="md-3" />

    <div :class="$style.desc">
      <span :class="$style.title">{{ userTitle }}</span>
      <span :class="$style.subtitle">
        <slot name="subtitle" />
        <div v-if="!hasSubtitleSlot">{{ userSubTitle }}</div>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VAvatar from '@components/layouts/avatar/VAvatar.vue'

export default Vue.extend({
  name: 'VUserPreview',

  props: {
    userAvatar: {
      type: Object,
      default: () => ({}),
    },
    userTitle: String,
    userSubTitle: String,
  },

  components: {
    VAvatar,
  },

  computed: {
    avatarUrl() {
      return this.userAvatar?.content_url ?? ''
    },
    avatarPreviewUrl() {
      return this.userAvatar?.preview_url ?? ''
    },
    hasSubtitleSlot() {
      return !!this.$slots.subtitle
    },
  },
})
</script>

<style lang="scss" module>
.userPreview {
  display: flex;
  align-items: center;

  .desc {
    display: flex;
    flex-direction: column;
    gap: 9px;
    justify-content: center;
    margin-left: 9px;

    .title {
      font-weight: 500;
      font-size: 15px;
    }
    .subtitle {
      color: $secondary-medium;
      font-size: 12px;
    }
  }
}
</style>
