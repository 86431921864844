/* eslint-disable camelcase */
import ClientApi from '@services/client'
import { Actions } from './types'

const actions: Actions = {
  async postClient({ commit, dispatch }, client) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { access_token, data } = await ClientApi.postClient(client)
      commit('Me/setAccessToken', access_token, { root: true })
      commit('setClient', data)
      return data
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async putClient({ commit, dispatch }, { id, client }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { access_token, data } = await ClientApi.putClient(id, client)
      commit('Me/setAccessToken', access_token, { root: true })
      commit('setClient', data)
      return data
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async putClientEmail({ commit, dispatch }, { id, params }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { access_token, data } = await ClientApi.putClientEmail(id, params)
      commit('Me/setAccessToken', access_token, { root: true })
      commit('setClient', data)
      return data
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return { error }
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },

  async putClientForRegisitration({ commit }, { id, client }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const data = await ClientApi.putClient(id, client)
      commit('Me/setAccessToken', data.access_token, { root: true })
      commit('setClient', data)
      return data.access_token
    } catch (error) {
      return { error }
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },

  async postClientCode(
    { commit, dispatch },
    data: {
      phone?: string
      email?: string
      type?: number
    }
  ) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { success, token } = await ClientApi.postClientCode(data)
      if (success) {
        commit('setClientLoginToken', token)
      }
      return { success, token }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return { error }
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postClientLogin({ commit }, loginData: { identifier: string; password: string }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const data = await ClientApi.postClientLogin(loginData)
      if (data.access_token) {
        commit('Me/setAccessToken', data.access_token, { root: true })
      }
      return data
    } catch (error) {
      return { error }
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postClientCodeAuth({ commit, dispatch }, { code, token }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { access_token, user } = await ClientApi.postClientCodeAuth(code, token)
      if (access_token) {
        commit('Me/setAccessToken', access_token, { root: true })
      }
      return { access_token, user }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return { error }
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async getClientBot({ commit, dispatch, rootGetters }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { data } = await ClientApi.getClientBot(rootGetters['App/appOriginalId']())
      const clientBot = data?.[0]
      commit('setClientBot', clientBot)
      return clientBot
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
