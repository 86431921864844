<template>
  <form :class="$style.form" @submit.prevent="$emit('submit')">
    <slot />
    <v-button
      v-if="isButtonSlot"
      :is-disabled="!isValid"
      :color="color"
      :is-loading="isLoading"
      size="lg"
      type="submit"
    >
      <slot name="button" />
    </v-button>
  </form>
</template>
<script>
import Vue from 'vue'

import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VForm',
  components: { VButton },
  provide() {
    return {
      form: this,
    }
  },
  props: {
    color: String,
    isLoading: Boolean,
  },
  data() {
    return {
      inputs: [],
    }
  },
  computed: {
    isButtonSlot() {
      return !!this.$slots.button
    },
    isValid() {
      return this.inputs.every((i) => !i.error)
    },
  },
  watch: {
    isValid: {
      handler() {
        this.$emit('input', this.isValid)
      },
      immediate: true,
    },
  },
  methods: {
    registerInput(input) {
      if (this.inputs.includes(input)) {
        return
      }
      this.inputs = [...this.inputs, input]
    },
    unregisterInput(input) {
      this.inputs = this.inputs.filter((i) => i !== input)
    },
    validate() {
      for (const input of this.inputs) {
        if (typeof input.validate === 'function' && !input.validate()) {
          break
        }
      }
    },
  },
})
</script>
<style lang="scss" module>
.form {
  display: grid;
  row-gap: 20px;
}
</style>
