<template>
  <div :class="[$style.content, isReferenced && $style.overInput, isPinned && $style.pinned]">
    <div v-if="isLoading" :class="$style.layout">
      <LoadingLoader is-loading :delay="0" :size="10" color="white" />
    </div>
    <icon
      v-if="isReferenced"
      :name="prependIcon"
      color="black"
      width="13"
      height="13"
      :class="$style.prependIcon"
    />
    <div
      :class="[
        $style.divide,
        isMyMessage && $style.myDivide,
        isReferenced && $style.inputDivide,
        isPinned && $style.pinnedDivide,
      ]"
    />
    <div v-if="isDeleted" :class="[$style.reference, $style.deleted]">
      {{ $t('deletedMessage') }}
    </div>
    <div v-else :class="$style.reference">
      <transition-group name="swipe-up">
        <div :key="`${targetMessage.id}`" :class="$style.referenceWrapper">
          <VAvatarImage v-if="isCoverImage" :class="$style.cover" :src="coverImage" />

          <div :class="[$style.desc, isPinned && $style.pinnedDesc]">
            <div :class="$style.title">{{ alterTitle || title }}</div>
            <div v-if="shortText" :class="$style.subTitle">{{ shortText }}</div>
          </div>
        </div>
      </transition-group>
    </div>

    <div v-if="isAppendButton" :class="$style.close" @click.stop="onClose">
      <icon
        :name="appendIcon"
        :color="appendIconColor"
        width="13"
        :height="isPinned ? '17' : '13'"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VAvatarImage from '@layouts/avatar/list/VAvatarImage.vue'
import LoadingLoader from '@loaders/list/LoadingLoader.vue'

import { MessageType } from '@common-types/chat/chat-message'

export default Vue.extend({
  name: 'ChatMessageReference',

  components: {
    LoadingLoader,
    VAvatarImage,
  },

  props: {
    message: {
      type: [Object, null],
      default: null,
    },
    alterTitle: {
      type: String,
      default: '',
    },
    isPinned: Boolean,
    isReplied: Boolean,
    isReferenced: Boolean,
    isLoading: Boolean,
    isMyMessage: Boolean,
  },
  computed: {
    ...mapGetters('Chat', [
      'chatId',
      'chatMessageIsReply',
      'chatMessageIsEdit',
      'referencedMessage',
    ]),
    isVoiceMessage() {
      return this.targetMessage?.type === MessageType.VOICE
    },
    isCoverImage() {
      return !this.isVoiceMessage && !!this.coverImage
    },
    isDeleted() {
      return this.isReplied && !this.message
    },
    targetMessage() {
      return this.message ?? this.referencedMessage
    },
    serderName() {
      return this.targetMessage?.client?.name ?? ''
    },
    shortText() {
      return this.targetMessage?.body?.slice(0, 100) ?? this.mediaPlaceholder
    },
    mediaObjects() {
      return this.targetMessage?.media_objects ?? []
    },
    mediaPlaceholder() {
      if (this.isVoiceMessage) {
        return this.$t('voiceMessage')
      }
      const mediaCount = this.mediaObjects.length
      const isPhoto = this.mediaObjects.every(({ mime }) => mime.includes('image'))
      const isVideo = this.mediaObjects.every(({ mime }) => mime.includes('video'))
      const placeholder = isPhoto ? 'photo' : isVideo ? 'video' : 'media'
      const translate = this.$t(placeholder)
      return mediaCount > 1 ? `${mediaCount} ${translate}` : translate
    },
    coverImage() {
      const mediaObject = this.targetMessage?.media_objects?.[0]
      const previewUrl = mediaObject?.preview_url
      const contentUrl = mediaObject?.content_url
      return previewUrl ?? contentUrl ?? null
    },
    title() {
      return this.isReplied
        ? this.serderName
        : this.chatMessageIsEdit
        ? this.$t('editing')
        : this.chatMessageIsReply
        ? this.$t('replyTo', [this.serderName])
        : this.serderName
    },
    isAppendButton() {
      return this.isPinned || this.isReferenced
    },
    prependIcon() {
      return this.chatMessageIsReply ? 'reply' : 'edit'
    },
    appendIcon() {
      return this.isPinned ? 'pin' : 'close'
    },
    appendIconColor() {
      return this.isPinned ? 'white' : 'black'
    },
  },
  methods: {
    ...mapMutations('Chat', ['resetReferencedMessageId', 'setIsReply', 'setIsEdit']),
    onClose() {
      this.resetReferencedMessageId()
      this.setIsReply(false)
      this.setIsEdit(false)
      this.$emit('close')
    },
  },
})
</script>
<style lang="scss" module>
.content {
  display: flex;
  align-items: center;
  width: 100%;
  background: transparent;
  cursor: pointer;
  position: relative;

  &.overInput {
    background-color: white;
    box-shadow: inset 0 1px 0 $secondary-light;
  }

  &.pinned {
    background-color: $dark;
  }

  .layout {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0.1;
    background-color: white;
  }

  .prependIcon {
    flex-shrink: 0;
    margin: 12px;
  }

  .divide {
    flex-shrink: 0;
    width: 1px;
    height: 36px;
    background: black;

    &.myDivide {
      background: $main-text-color;
    }

    &.inputDivide {
      background: linear-gradient(
        0deg,
        #df9630 11.73%,
        #e09a30 32.68%,
        #edd031 65.92%,
        #edd031 87.71%
      );
    }

    &.pinnedDivide {
      width: 3px;
      margin-right: 3px;
      background: $primary-dark-background;
    }
  }

  .reference {
    flex: 1;
    margin: 0 6px;
    overflow: hidden;
    max-height: 34px;

    .referenceWrapper {
      gap: 6px;
      justify-self: stretch;
      display: flex;
      overflow: hidden;

      .cover {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 6px;
      }

      .desc {
        display: flex;
        flex-direction: column;
        flex-shrink: 1;
        gap: 3px;
        justify-content: center;
        overflow: hidden;

        .title {
          overflow: hidden;
          font-weight: 500;
          font-size: 15px;
          font-family: Roboto, sans-serif;
          line-height: 15px;
          letter-spacing: 0;
          white-space: nowrap;
          text-align: left;
          text-overflow: ellipsis;
        }

        .subTitle {
          overflow: hidden;
          font-weight: 400;
          font-size: 12px;
          font-family: Roboto, sans-serif;
          line-height: 16px;
          letter-spacing: 0;
          white-space: nowrap;
          text-align: left;
          text-overflow: ellipsis;
        }
      }

      .pinnedDesc {
        gap: 7px;
        color: white;

        .title {
          font-weight: 400;
          font-size: 12px;
          opacity: 0.5;
        }

        .subTitle {
          // color: white;
        }
      }
    }
  }

  .deleted {
    color: $secondary-dark;
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    margin-top: 1px;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
