import { uniqBy } from 'lodash'
import ChatApi from '@modules/chat/api/chat'
import i18n from '@plugins/i18n'
import { ChatsFilters } from '@config/index'
import { Chat, Chats } from '@common-types/chat'
import { Actions } from '../types'
import { TestCode6FixedChatsIds } from '../contstants'

const actions: Actions = {
  async getChats({ commit, dispatch }, params = {}) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { data: chats } = await ChatApi.getChats(params)
      return chats
    } catch (error) {
      dispatch(
        'notify',
        {
          type: 'error',
          title: `${i18n.t('errors.chats.caption')}`,
          text: `${i18n.t('errors.chats.descriptionLoad')}`,
        },
        { root: true }
      )
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async getChatsInState({ commit, dispatch, getters, rootGetters }, { scroll, params, chatsName }) {
    const chatsParams = getters.chatsParams(chatsName) ?? {}
    const isSplitTestApp6 = rootGetters['App/isSplitTestApp6']
    let chats: Chats = []
    if (isSplitTestApp6 && (getters.chatsPage ?? 1) < 2) {
      const promises = TestCode6FixedChatsIds.map((chatId) => {
        return ChatApi.getChat(chatId)
      })
      chats = await Promise.all(promises)
    }
    const regularChats = await dispatch('getChats', {
      ...params,
      ...chatsParams,
      followed: true,
      limit: 15,
      page: getters.chatsPage ?? 1,
      'order[firstly_app_type]': 'desc',
    })
    chats = uniqBy([...chats, ...regularChats], (item) => item.id)
    if (scroll) {
      chats?.length ? scroll.loaded() : scroll.complete()
    }
    if (!chats?.length) {
      commit('setIsLoadingCompleted', true)
      return
    }
    if (!getters.chats.length) {
      commit('addChatsTab', chatsName)
    }
    const mutationName = getters.chatsPage === 1 ? 'setChats' : 'pushChats'
    commit(mutationName, { chatsName, chats })
  },
  async getChatsUnreadCount({ dispatch, commit }) {
    try {
      const { total } = await ChatApi.getChats({
        page: 1,
        limit: 1,
        'exists[new_message_count]': true,
      })
      commit('setChatsUnreadCount', total)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    }
  },
  unshiftChat({ commit, state, rootGetters }, chat: Chat) {
    const clientId = rootGetters['Client/clientId']
    state.chatsTabs.forEach((chatsTab) => {
      const filter = ChatsFilters[chatsTab.name]
      if (filter && filter(chat, { clientId })) {
        commit('unshiftChat', { chatsName: chatsTab.name, chat, store: { rootGetters } })
      }
    })
  },
  swapChat({ dispatch, commit, getters }, chat: Chat) {
    const currentChat = getters.chats.find((item: Chat) => item.id === chat.id)
    commit('deleteChatFromChats', chat?.id)
    dispatch('unshiftChat', currentChat ?? chat)
  },
}

export default actions
