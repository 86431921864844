/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'profile': {
    width: 18,
    height: 17,
    viewBox: '0 0 18 17',
    data: '<path pid="0" d="M9 10c2.757 0 5-2.243 5-5s-2.243-5-5-5-5 2.243-5 5 2.243 5 5 5zm0-9c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4zm9 12.687V15.5a.5.5 0 11-1 0v-1.813a.697.697 0 00-.706-.687H1.706a.698.698 0 00-.706.687V15.5a.5.5 0 11-1 0v-1.813C0 12.757.765 12 1.706 12h14.588c.94 0 1.706.757 1.706 1.687zM13 16.5a.5.5 0 01-.5.5h-7a.5.5 0 110-1h7a.5.5 0 01.5.5z"/>'
  }
})
