/* eslint-disable camelcase */
import SubscriptionsApi from '@services/subscription'
import { Actions } from './types'

const actions: Actions = {
  async getSubscriptions({ commit, dispatch, getters }, { scroll, params = {} }) {
    try {
      commit('Page/setPageLoading', false, { root: true })
      const subscriptions = await SubscriptionsApi.getSubscriptions({
        limit: 20,
        page: getters.subscriptionsPage,
        ...params,
      })
      if (subscriptions.length) {
        commit('addSubscriptions', subscriptions)
      }
      if (scroll) {
        subscriptions?.length ? scroll.loaded() : scroll.complete()
      }
      return subscriptions
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postSubscriptionCancel({ commit, dispatch }, id) {
    try {
      commit('Page/setPageLoading', false, { root: true })
      const subscription = await SubscriptionsApi.postSubscriptionCancel(id)
      commit('changeSubscription', subscription)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postSubscriptionMonthTrial({ commit, dispatch }, id) {
    try {
      commit('Page/setPageLoading', false, { root: true })
      const subscription = await SubscriptionsApi.postSubscriptionMonthTrial(id)
      commit('changeSubscription', subscription)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      throw error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
