<template>
  <span>{{ number }}</span>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { abbreviateNumber, shortAbbreviateNumber } from '@utils/numbers'

export default Vue.extend({
  name: 'VCount',
  props: { count: Number, isShortAbbreviate: Boolean },
  computed: {
    ...mapGetters('Locale', ['locale']),
    number() {
      return this.isShortAbbreviate
        ? shortAbbreviateNumber(this.count)
        : abbreviateNumber(this.count, this.locale)
    },
  },
})
</script>
