/* eslint-disable camelcase */
import { axios, axiosJWT } from '@plugins/axios'
import { DataType, GetParams } from './types'

export default {
  getMe: async (params: GetParams = null, isLoggedIn = true): Promise<ResType<DataType>> => {
    const { access_token, data, app, client, user }: ResType<DataType> = isLoggedIn
      ? await axiosJWT.get('me', { params })
      : await axios.get('me', { params })
    return { access_token, data, app, client, user }
  },
}
