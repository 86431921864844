// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VVideoButton_button_1rsbt{position:absolute!important;left:3px;width:auto!important}.VVideoButton_icon_3VJ0V{display:block;fill:currentColor}", ""]);
// Exports
exports.locals = {
	"button": "VVideoButton_button_1rsbt",
	"icon": "VVideoButton_icon_3VJ0V"
};
module.exports = exports;
