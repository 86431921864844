/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 9,
    height: 17,
    viewBox: '0 0 9 17',
    data: '<path pid="0" d="M.146 16.146a.5.5 0 10.708.707l8-8a.498.498 0 000-.707l-8-8a.5.5 0 10-.708.708L7.793 8.5.146 16.146z"/>'
  }
})
