/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { Country, GetParams } from './types'

export default {
  getCountries: async (params: GetParams): Promise<Country> => {
    const { data }: ResType<Country> = await axiosJWT.get('countries', { params })
    return data
  },
  getCountry: async (id: number): Promise<Country> => {
    const { data }: ResType<Country> = await axiosJWT.get(`countries/${id}`)
    return data
  },
  getCurrentCountry: async (): Promise<Country> => {
    const { data }: ResType<Country> = await axiosJWT.get('countries/current')
    return data
  },
}
