// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VList_list_24f9k{display:grid;grid-auto-rows:min-content;grid-auto-flow:row;width:100%;height:100%}.VList_item_2x4vy{padding:17px 15px;color:var(--supster-secondary);box-shadow:inset 0 -1px 0 #e5e3e3;cursor:pointer;transition:opacity .3s}.VList_item_2x4vy:hover{opacity:.6}.VList_item_2x4vy.VList_disabled_2UC4r{cursor:not-allowed}.VList_item_2x4vy.VList_disabled_2UC4r:hover{opacity:1}.VList_item_2x4vy.VList_checked_17ro4{background-color:var(--supster-primary);color:var(--supster-main-text-color)}.VList_item_2x4vy.VList_red_CPJOO{color:#fb4e4e}.VList_item_2x4vy.VList_grey_2XnWp{color:#fff;background:#737373}.VList_content_zBcD5{display:grid;grid-template-columns:auto 1fr;-moz-column-gap:15px;column-gap:15px}.VList_content_zBcD5.VList_disabled_2UC4r{opacity:.2}.VList_content_zBcD5.VList_checked_17ro4{grid-template-columns:1fr auto}.VList_icon_34JIG,.VList_image_u9W31{align-self:center}.VList_icon_34JIG{width:30px;height:30px}.VList_title_2owJt{align-self:center;font-weight:400;font-size:15px;line-height:14px}", ""]);
// Exports
exports.locals = {
	"list": "VList_list_24f9k",
	"item": "VList_item_2x4vy",
	"disabled": "VList_disabled_2UC4r",
	"checked": "VList_checked_17ro4",
	"red": "VList_red_CPJOO",
	"grey": "VList_grey_2XnWp",
	"content": "VList_content_zBcD5",
	"icon": "VList_icon_34JIG",
	"image": "VList_image_u9W31",
	"title": "VList_title_2owJt"
};
module.exports = exports;
