// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VCounter_section_3BL2-{display:grid;grid-auto-columns:min-content;grid-auto-flow:column;-moz-column-gap:3px;column-gap:3px}.VCounter_amount_3L9mg,.VCounter_section_3BL2-{align-self:center}.VCounter_amount_3L9mg{min-width:30px;font-size:12px;line-height:12px;text-align:center}.VCounter_button_3_lgl{align-self:center}.VCounter_button_3_lgl:hover{transform:none}.VCounter_icon_2kuSY{width:6px;height:12px;color:currentColor}", ""]);
// Exports
exports.locals = {
	"section": "VCounter_section_3BL2-",
	"amount": "VCounter_amount_3L9mg",
	"button": "VCounter_button_3_lgl",
	"icon": "VCounter_icon_2kuSY"
};
module.exports = exports;
