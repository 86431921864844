import { createChat } from '@modules/chat/helpers/chat'
import { Getters, Prompt } from '../types'

const getters: Getters = {
  getPrompts: (state) => state.prompts,
  getPromptById: (s, getters) => (promptId: Prompt['id']) =>
    getters.getPrompts?.find((prompt: Prompt) => prompt.id === promptId),
  getPromptChats: (s, getters, rs, rootGetters) => {
    const client = rootGetters['Client/client']
    const clientBot = rootGetters['Client/clientBot']
    return getters.getPrompts
      ? getters.getPrompts.map((prompt: Prompt) =>
          createChat({
            type: 3,
            name: prompt.name,
            image: prompt.image,
            is_prompt: true,
            chat_gpt_system_prompt: prompt,
            is_accessible: true,
            client_id: clientBot.id,
            client,
            recipient: clientBot,
          })
        )
      : []
  },
}

export default getters
