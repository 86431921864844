// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PhoneVerification_text_B6tip{padding:0 15px 15px;font-weight:400;font-size:12px;line-height:18px}.PhoneVerification_field_3jj1I{display:grid;grid-gap:15px;grid-template-columns:auto 1fr;align-items:center;padding:0 15px;background:#f8f8f8;border-top:1px solid #e5e3e3;border-bottom:1px solid #e5e3e3}.PhoneVerification_field_3jj1I input{background:#f8f8f8;border:0}.PhoneVerification_description_2EL7Z{color:#a09f9f;font-weight:400;font-size:12px;line-height:18px}.PhoneVerification_buttons_33UpP{display:grid;grid-gap:15px;grid-template-columns:auto auto;justify-content:space-between;padding:15px;box-shadow:inset 0 -1px 0 #e5e3e3}.PhoneVerification_code_2faRk,.PhoneVerification_phone_3FRCV{display:grid;grid-gap:6px;cursor:pointer}.PhoneVerification_code_2faRk span,.PhoneVerification_phone_3FRCV span{display:grid;grid-gap:6px;grid-template-columns:min-content auto;align-items:center;font-weight:400;font-size:12px;line-height:18px}.PhoneVerification_code_2faRk p,.PhoneVerification_phone_3FRCV p{font-weight:500;font-size:15px;line-height:14px}.PhoneVerification_code_2faRk p{text-align:right}.PhoneVerification_codeDisabled_yZbNz{opacity:.5;cursor:auto}.PhoneVerification_button_YRh8U{padding:15px}.PhoneVerification_url_eklVQ{padding:0 15px 15px;color:#a09f9f;font-weight:400;font-size:12px;line-height:18px;box-shadow:inset 0 -1px 0 #e5e3e3}.PhoneVerification_url_eklVQ a{color:var(--supster-secondary);font-weight:500}.PhoneVerification_actions_2X5BG{display:grid;grid-template-columns:1fr 1fr;height:48px;box-shadow:inset 0 -1px 0 #e5e3e3}.PhoneVerification_action_3vy1w{display:grid;grid-auto-flow:column;grid-gap:6px;align-items:center;justify-content:center;cursor:pointer}.PhoneVerification_action_3vy1w:first-child{box-shadow:inset -1px 0 0 #e5e3e3}.PhoneVerification_action_3vy1w span{font-weight:500;font-size:12px;line-height:18px}", ""]);
// Exports
exports.locals = {
	"text": "PhoneVerification_text_B6tip",
	"field": "PhoneVerification_field_3jj1I",
	"description": "PhoneVerification_description_2EL7Z",
	"buttons": "PhoneVerification_buttons_33UpP",
	"code": "PhoneVerification_code_2faRk",
	"phone": "PhoneVerification_phone_3FRCV",
	"codeDisabled": "PhoneVerification_codeDisabled_yZbNz",
	"button": "PhoneVerification_button_YRh8U",
	"url": "PhoneVerification_url_eklVQ",
	"actions": "PhoneVerification_actions_2X5BG",
	"action": "PhoneVerification_action_3vy1w"
};
module.exports = exports;
