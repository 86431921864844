import { checker } from '@helpers/index'
import { EntityType, EventType } from './types'

export const isMessage = checker(EntityType.CHANNEL_MESSAGE)
export const isChannel = checker(EntityType.CHANNEL)
export const isToken = checker(EntityType.ACCESS_TOKEN)
export const isStory = checker(EntityType.STORY)
export const isOrder = checker(EntityType.ORDER)
export const isMediaObject = checker(EntityType.MEDIA_OBJECT)

export const isCreated = checker(EventType.CREATED)
export const isUpdated = checker(EventType.UPDATED)
export const isRemoved = checker(EventType.REMOVED)
export const isRead = checker(EventType.READ)
export const isPaid = checker(EventType.PAID)
export const notPaid = checker(EventType.NOT_PAID)
export const isConverted = checker(EventType.CONVERTED)

export default {
  isMessage,
  isChannel,
  isToken,
  isStory,
  isOrder,
  isMediaObject,
  isCreated,
  isUpdated,
  isRemoved,
  isRead,
  isPaid,
  notPaid,
  isConverted,
}
