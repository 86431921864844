/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron': {
    width: 6,
    height: 12,
    viewBox: '0 0 6 12',
    data: '<path pid="0" d="M5.87 6.336l-5 5.5a.5.5 0 11-.74-.672L4.824 6 .13.836A.5.5 0 01.87.163l5 5.5a.5.5 0 010 .673z"/>'
  }
})
