// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileStories_section_IoV7r{display:grid;grid-auto-columns:min-content;grid-auto-flow:column;grid-gap:3px;padding:6px;overflow-x:auto;overflow-y:hidden;-webkit-overflow-scrolling:touch;scrollbar-width:none;-ms-overflow-style:none}.ProfileStories_section_IoV7r::-webkit-scrollbar{display:none}.ProfileStories_add_1D386{width:46px;height:46px}", ""]);
// Exports
exports.locals = {
	"section": "ProfileStories_section_IoV7r",
	"add": "ProfileStories_add_1D386"
};
module.exports = exports;
