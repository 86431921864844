import { Post, PostId } from '@common-types/post'
import VirtualizationCache from '@services/virtualization-cache'
import { MediaObject } from '@services/media/types'
import { replaceEntityMediaObject } from '@helpers/media-objects'

class FeedCache extends VirtualizationCache<PostId, Post> {
  constructor() {
    super()
  }

  replaceMediaObject(mediaObject: MediaObject): void {
    this.cacheMap.forEach((item) => {
      this.setItem(replaceEntityMediaObject(item, mediaObject))
    })
  }
}
export default new FeedCache()
