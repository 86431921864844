import Vue from 'vue'
import i18n from '@plugins/i18n'
import MediaApi from '@services/media'
import { MediaObject } from '@services/media/types'
import { FileType } from '@store/files/types'
import { Actions } from './types'
import { getFileParams } from './helpers'

const actions: Actions = {
  async postMedia({ state, commit, rootGetters }, callback = undefined) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      commit({ type: 'Files/resetFilesError' }, { root: true })
      const files: FileType[] = rootGetters['Files/filesNotComplete']
      if (!files) {
        return state.media
      }
      const promises: Promise<void | MediaObject>[] = files.map(async ({ data, id }) => {
        const resolution = await getFileParams(data)
        return MediaApi.postMedia({ file: data, fileId: id, fileResolution: resolution, callback })
          .then((mediaItem) => {
            commit('pushMediaItem', mediaItem)
            commit('Files/setFileResolution', { resolution, mediaId: mediaItem.id }, { root: true })
            commit('Files/setFileComplete', { fileId: id, complete: true }, { root: true })
            return mediaItem
          })
          .catch((error) => {
            commit('Files/setFileError', { fileId: id, error: true }, { root: true })
            throw error
          })
      })
      return Promise.all(promises)
    } catch (error) {
      Vue.notify({
        type: 'error',
        title: `${i18n.t('errors.files.caption')}`,
        text: `${i18n.t('errors.files.descriptionLoad')}`,
      })
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },

  async postMediaItem({ commit, dispatch, rootGetters }, { fileId, callback }) {
    commit('Page/setPageLoading', true, { root: true })
    const files: FileType[] = rootGetters['Files/files']
    const file = files?.find(({ id }) => id === fileId) ?? null
    if (!file) {
      Vue.notify({
        type: 'error',
        title: `${i18n.t('errors.files.caption')}`,
        text: `${i18n.t('errors.files.descriptionNotFound')}`,
      })
      return null
    }
    commit('Files/setFileError', { fileId, error: false }, { root: true })
    const resolution = await getFileParams(file.data)
    return MediaApi.postMedia({ file: file.data, fileId, fileResolution: resolution, callback })
      .then((mediaItem) => {
        commit('pushMediaItem', mediaItem)
        dispatch(
          'Files/setFileResolution',
          { file: file.data, mediaId: mediaItem.id },
          { root: true }
        )
        commit('Files/setFileComplete', { fileId, complete: true }, { root: true })
        return mediaItem
      })
      .catch((error) => {
        commit('Files/setFileError', { fileId, error: true }, { root: true })
        Vue.notify({
          type: 'error',
          title: `${i18n.t('errors.files.caption')}`,
          text: `${i18n.t('errors.files.descriptionLoad')}`,
        })
        return error
      })
      .finally(() => {
        commit('Page/setPageLoading', false, { root: true })
      })
  },
}

export default actions
