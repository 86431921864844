// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SearchInput_element_2jkA6{position:relative}.SearchInput_content_kqCuO,.SearchInput_element_2jkA6{width:100%}.SearchInput_icon_1GmOo{position:absolute;top:11px;left:15px;width:14px;height:14px;color:#a09f9f;transition:color .3s}.SearchInput_icon_1GmOo.SearchInput_primary_7v-jP{color:var(--supster-primary)!important}.SearchInput_input_1MQoQ{width:100%;padding:8px 15px 8px 38px;color:var(--supster-main-background);font-weight:400;font-size:15px;word-wrap:break-word;background-color:var(--supster-footer-background);border:1px solid var(--supster-footer-background);border-radius:18px;outline:none;transition:border .3s,background .3s,width .3s cubic-bezier(.5,.2,.5,.9)}.SearchInput_input_1MQoQ::-moz-placeholder{overflow:hidden;color:#a09f9f;white-space:nowrap;text-overflow:ellipsis;opacity:.5}.SearchInput_input_1MQoQ::placeholder{overflow:hidden;color:#a09f9f;white-space:nowrap;text-overflow:ellipsis;opacity:.5}.SearchInput_input_1MQoQ:disabled{cursor:not-allowed}.SearchInput_input_1MQoQ:focus{background-color:var(--supster-main-text-color);border-color:var(--supster-main-text-color)}.SearchInput_input_1MQoQ:focus~.SearchInput_icon_1GmOo{color:var(--supster-main-background)}.SearchInput_input_1MQoQ.SearchInput_error_1onN0{border:1px solid #fb4e4e}.SearchInput_input_1MQoQ.SearchInput_value_23l2F{width:93%}@media only screen and (max-width:500px){.SearchInput_input_1MQoQ.SearchInput_value_23l2F{width:90%}}.SearchInput_button_2Q9_z{position:absolute!important;top:0;right:0;align-self:center;justify-self:center;width:auto!important;padding:12px 6px!important}", ""]);
// Exports
exports.locals = {
	"element": "SearchInput_element_2jkA6",
	"content": "SearchInput_content_kqCuO",
	"icon": "SearchInput_icon_1GmOo",
	"primary": "SearchInput_primary_7v-jP",
	"input": "SearchInput_input_1MQoQ",
	"error": "SearchInput_error_1onN0",
	"value": "SearchInput_value_23l2F",
	"button": "SearchInput_button_2Q9_z"
};
module.exports = exports;
