// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VAvatarImage_section_3BOpn{align-self:center;justify-self:center;width:100%;height:100%;-o-object-fit:cover;object-fit:cover;overflow:hidden;border-radius:100%}.VAvatarImage_section_3BOpn.VAvatarImage_rect_1Jb22{border-radius:39px}.VAvatarImage_section_3BOpn.VAvatarImage_sm_11OFL,.VAvatarImage_section_3BOpn.VAvatarImage_xs_1c5sl{width:calc(100% - 8px);height:calc(100% - 8px)}.VAvatarImage_section_3BOpn.VAvatarImage_md_1Nwsa,.VAvatarImage_section_3BOpn.VAvatarImage_md-2_3Zig6,.VAvatarImage_section_3BOpn.VAvatarImage_md-3_1bvxt,.VAvatarImage_section_3BOpn.VAvatarImage_md-4_3QGcG,.VAvatarImage_section_3BOpn.VAvatarImage_md-5_2YHnj{width:calc(100% - 9px);height:calc(100% - 9px)}.VAvatarImage_section_3BOpn.VAvatarImage_lg_1RSo9{width:calc(100% - 15px);height:calc(100% - 15px)}.VAvatarImage_section_3BOpn.VAvatarImage_xl_39vuP{width:calc(100% - 17px);height:calc(100% - 17px)}", ""]);
// Exports
exports.locals = {
	"section": "VAvatarImage_section_3BOpn",
	"rect": "VAvatarImage_rect_1Jb22",
	"sm": "VAvatarImage_sm_11OFL",
	"xs": "VAvatarImage_xs_1c5sl",
	"md": "VAvatarImage_md_1Nwsa",
	"md-2": "VAvatarImage_md-2_3Zig6",
	"md-3": "VAvatarImage_md-3_1bvxt",
	"md-4": "VAvatarImage_md-4_3QGcG",
	"md-5": "VAvatarImage_md-5_2YHnj",
	"lg": "VAvatarImage_lg_1RSo9",
	"xl": "VAvatarImage_xl_39vuP"
};
module.exports = exports;
