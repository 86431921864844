<template>
  <div
    v-if="notChatsPage"
    :class="[$style.notifications, notification && $style.open, isReply && $style.high]"
    @click.stop.prevent="closeNotification"
  >
    <Notification
      v-if="notification"
      ref="notification"
      :is-reply="isReply"
      v-swipe="swipeOptions"
      @click.native.stop.prevent="handleClick"
      @close="closeNotification"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { SwipeDirection } from '@plugins/eventSwipe/types'
import Config from '@modules/notifications/config'
import Notification from './components/Notification.vue'
import { createMessageHandler, setNotificationTimer, stopNotificationTimer } from './services/index'

export default {
  name: 'Notifications',
  components: { Notification },
  inject: {
    centrifugeProvider: {
      default() {
        return {
          message: null,
        }
      },
    },
  },
  data() {
    return {
      isReply: false,
      messageHandler: null,
    }
  },
  computed: {
    ...mapGetters('Notifications', ['notification']),
    ...mapGetters('Locale', ['locale']),
    notChatsPage() {
      return !Config.DEPRECATED_PAGES.includes(this.$route.name)
    },
    swipeOptions() {
      return {
        [SwipeDirection.DOWN]: this.swipeDownHandler,
        [SwipeDirection.UP]: this.closeNotification,
        [SwipeDirection.HORISONTAL]: this.closeNotification,
      }
    },
    notificationLink() {
      return this.notification
        ? { name: 'Chats', params: { id: this.notification.chatId, locale: this.locale } }
        : { name: 'Chats' }
    },
    isChatsPage() {
      return ['Chats', 'Chat', 'SupportChat', 'NewGptChat'].includes(this.$route.name)
    },
  },
  watch: {
    'centrifugeProvider.message': {
      handler(value) {
        if (value?.data && this.messageHandler && !this.isChatsPage) {
          this.setNotificationMessage(value?.data)
          this.messageHandler(value?.data)
        }
      },
      immediate: true,
    },
    isReply(value) {
      value ? stopNotificationTimer() : setNotificationTimer(this.$store)
    },
  },
  created() {
    this.messageHandler = createMessageHandler(this.$store)
  },
  methods: {
    ...mapMutations('Notifications', ['setNotification', 'setNotificationMessage']),

    handleClick() {
      if (this.isReply) {
        this.isReply = false
      } else {
        this.goToChat()
        this.setNotification(null)
      }
    },
    goToChat() {
      this.$router.push(this.notificationLink)
    },
    swipeDownHandler() {
      if (this.isReply) {
        return
      }
      this.isReply = true
    },
    closeNotification() {
      this.setNotification(null)
      this.isReply = false
    },
  },
}
</script>

<style lang="scss" module>
.notifications {
  position: absolute;
  top: 0;
  z-index: 3;
  width: 100%;
  min-height: 80px;
  padding: 42px 6px 0 6px;
  overflow: visible;
  color: $secondary;
  transform: translateY(-100%);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease, transform 0.3s ease;

  &.open {
    transform: translateY(0);
  }

  &.high {
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1.5px);
  }
}
</style>
