/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { SubscriptionGetParams, SubscriptionPostParams } from '@modules/chat/types'
import { Chat } from '@common-types/chat'

export default {
  getChatSubscriptions: async (params: SubscriptionGetParams): Promise<Chat> => {
    const { data }: ResType<Chat> = await axiosJWT.get('channel_subscriptions', { params })
    return data
  },
  postChatSubscription: async (subscription: SubscriptionPostParams): Promise<Chat> => {
    const { data }: ResType<Chat> = await axiosJWT.post('channel_subscriptions', subscription)
    return data
  },
  deleteChatSubscription: async (id: number): Promise<boolean | undefined> => {
    const { success }: ResType<boolean | undefined> = await axiosJWT.delete(
      `channel_subscriptions/${id}`
    )
    return success
  },
}
