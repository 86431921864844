/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'paypal': {
    width: 24,
    height: 28,
    viewBox: '0 0 24 28',
    data: '<path pid="0" d="M20.434 2.111C19.123.634 16.753 0 13.72 0H4.92c-.62 0-1.148.446-1.245 1.051L.01 24.03a.75.75 0 00.746.863H6.19l1.364-8.557-.042.268a1.252 1.252 0 011.24-1.051h2.582c5.072 0 9.044-2.037 10.204-7.93.035-.174.064-.343.09-.51-.146-.076-.146-.076 0 0 .346-2.177-.002-3.66-1.194-5.002z" _fill="#27346A"/><path pid="1" d="M9.633 6.33c.145-.069.307-.107.476-.107h6.9c.817 0 1.579.053 2.275.164a9.652 9.652 0 011.39.328c.342.113.661.245.954.399.346-2.179-.002-3.66-1.194-5.003C19.122.634 16.753 0 13.72 0H4.918c-.62 0-1.147.446-1.244 1.051L.01 24.029a.75.75 0 00.745.864H6.19l2.83-17.75c.059-.365.295-.663.613-.814z" _fill="#27346A"/><path pid="2" d="M21.538 7.623c-1.16 5.892-5.132 7.93-10.204 7.93H8.75a1.25 1.25 0 00-1.24 1.05L5.815 27.245a.656.656 0 00.653.756h4.58c.542 0 1.004-.39 1.088-.92l.045-.23.863-5.41.056-.298c.085-.53.546-.92 1.088-.92h.685c4.438 0 7.912-1.782 8.927-6.936.424-2.154.204-3.952-.917-5.216a4.368 4.368 0 00-1.254-.956c-.026.166-.055.335-.09.51z" _fill="#2790C3"/><path pid="3" d="M20.414 6.635a8.547 8.547 0 00-.548-.138 10.44 10.44 0 00-.58-.11 14.483 14.483 0 00-2.277-.164h-6.9a1.1 1.1 0 00-1.088.92l-1.466 9.193-.042.268a1.25 1.25 0 011.239-1.051h2.583c5.072 0 9.044-2.037 10.204-7.93a14.1 14.1 0 00.09-.51 6.305 6.305 0 00-1.215-.478z" _fill="#1F264F"/>'
  }
})
