// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatDonateButton_button_30Cyq .ChatDonateButton_icon_3gMOH{margin-right:9px}.ChatDonateButton_button_30Cyq .ChatDonateButton_text_3c-r7{color:var(--supster-primary);font-weight:500;font-size:15px;line-height:22px}", ""]);
// Exports
exports.locals = {
	"button": "ChatDonateButton_button_30Cyq",
	"icon": "ChatDonateButton_icon_3gMOH",
	"text": "ChatDonateButton_text_3c-r7"
};
module.exports = exports;
