/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'webmoney': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.27 23.282l3.01 3.446a16 16 0 01-1.969 1.314A14.789 14.789 0 0114.944 30C6.713 30 0 23.262 0 15S6.713 0 14.944 0c2.346 0 4.568.548 6.547 1.522.31.148.621.311.931.49l-1.374 1.236-2.067-2.145-3.489 3.123-2.072-2.27-6.498 5.88 4.166 4.608-1.637 1.446 4.103 4.616-1.627 1.441 5.863 6.551 3.48-3.216zm-2.297-8.875l-3.2-3.567-3.553 3.212 3.2 3.567 3.553-3.212zm2.528 6.164l-3.2-3.567-3.553 3.212 3.2 3.567 3.553-3.212zM9.677 7.961l3.2 3.567 3.553-3.212-3.2-3.567-3.553 3.212zm6.521-1.728l2.4 2.675 2.665-2.409-2.4-2.675-2.665 2.409zm9.8 11.299L23.6 14.856l-2.665 2.409 2.4 2.675 2.665-2.408zm-2.72 5.136l2.4 2.675 2.665-2.409-2.4-2.675-2.665 2.409zm2.674-10.6l1.777-1.607-1.6-1.783-1.777 1.605 1.6 1.784zm-2.27-5.3l1.776-1.605-1.6-1.784-1.776 1.606 1.6 1.783zm4.541 10.598L30 15.76l-1.6-1.783-1.777 1.605 1.6 1.784zm-4.503-5.488l-2.4-2.675-2.665 2.409 2.4 2.675 2.665-2.409z" _fill="#135EAB"/>'
  }
})
