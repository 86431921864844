<template>
  <v-grid
    v-if="hasGrid"
    :key="`${tabName}-${currentAppName}`"
    :length="profileContentLength(tabName)"
    :no-data="!profileContentLength(tabName)"
    @infinite="onGetMedia"
    :class-name="className"
    :class="!profileContentLength(tabName) && $style.grid"
  >
    <component
      v-for="(media, index) in profileContent(tabName)"
      :key="`${tabName}-${media.id}`"
      :is="componentName"
      :post="media"
      :chat="media"
      :class="[
        isShop && $style.product,
        ((index + 1) % 5 === 1 || (index + 1) % 5 === 2) && $style.big,
      ]"
      :tab-name="tabName"
      :is-product-small="!((index + 1) % 5 === 1 || (index + 1) % 5 === 2)"
      :is-footer="(index + 1) % 5 === 1 || (index + 1) % 5 === 2"
      @joined="onJoinChat"
    />
    <template #placeholder>
      <component v-for="i in 30" :key="i" :is="placeholderName" :is-shop="isShop" />
    </template>
    <template #no-data>
      <ProfileEmptyTab :tab-name="tabName" />
    </template>
  </v-grid>
  <div v-else-if="isEditable && !isClientOwner && isPosts" :class="$style.add">
    <div :class="$style.container" v-for="i in [0, 1, 2]" :key="`media-${i}`">
      <v-image v-if="contentSrc(i)" :class="$style.image" :src="contentSrc(i)" />
      <profile-add v-else :class="$style.image" @upload="onUpload" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VGrid from '@layouts/VGrid.vue'
import VPostPreview from '@layouts/postPreview/VPostPreview.vue'
import PPostPreview from '@placeholders/PPostPreview.vue'
import VChatPreview from '@layouts/VChatPreview.vue'
import PChatPreview from '@placeholders/PChatPreview.vue'
import VImage from '@elements/VImage.vue'
import Config from '@config/index'
import { Tab } from '@modules/profile/constants'
import { addSubscribedChat } from '@modules/chats/services'
import ProfileEmptyTab from '@modules/profile/components/ProfileEmptyTab.vue'
import ProfileAdd from './ProfileAdd.vue'

export default Vue.extend({
  name: 'ProfileMedia',
  components: {
    ProfileEmptyTab,
    VGrid,
    VPostPreview,
    PPostPreview,
    VChatPreview,
    PChatPreview,
    VImage,
    ProfileAdd,
  },
  props: {
    tabName: String,
    isEditable: Boolean,
  },
  data() {
    return {
      cacheHandlersMapIds: [],
    }
  },
  computed: {
    ...mapGetters('App', ['appId', 'currentApp']),
    ...mapGetters('Client', ['isClientOwner']),
    ...mapGetters('Profile', ['profileContent', 'profileContentLength', 'profileClientApp']),
    componentName() {
      if (this.isChats) {
        return 'v-chat-preview'
      }
      return 'v-post-preview'
    },
    placeholderName() {
      if (this.isChats) {
        return 'p-chat-preview'
      }
      return 'p-post-preview'
    },
    clientMedia() {
      return this.currentApp?.[this.tabName] ?? null
    },
    clientProfileMediaLength() {
      return this.profileClientApp?.[this.tabName]?.length ?? 0
    },
    currentAppName() {
      return this.currentApp?.name ?? ''
    },
    hasGrid() {
      return !this.isEditable || (this.isEditable && this.isClientOwner)
    },
    className() {
      return this.isPosts ? 'posts' : this.isShop ? 'shop' : ''
    },
    isShop() {
      return this.tabName === Tab.PRODUCTS
    },
    isPosts() {
      return this.tabName === Tab.IMAGES || this.tabName === Tab.VIDEOS
    },
    isChats() {
      return this.tabName === Tab.CHATS
    },
  },
  methods: {
    ...mapMutations('App', ['setClientApp']),
    ...mapMutations('Profile', [
      'setProfileContentItem',
      'setProfileContent',
      'setProfileClientApp',
    ]),
    ...mapActions('Profile', ['getProfileContent']),
    ...mapMutations('Search/Chats', { setSearchChat: 'setChat' }),
    ...mapMutations('Chats', ['deleteChatFromChats']),
    async onGetMedia(scroll) {
      await this.getProfileContent({
        scroll,
        tab: this.tabName,
        app_id: this.appId(this.currentApp),
      })
    },
    contentSrc(i) {
      return this.profileClientApp?.[this.tabName]?.[i]?.url ?? null
    },
    onUpload(file) {
      this.setProfileClientApp({
        [this.tabName]: [...(this.profileClientApp[this.tabName] || []), file[0]],
      })
    },
    onJoinChat(contentItem) {
      this.setProfileContentItem({ contentItem, tab: Tab.CHATS })
      this.setSearchChat(contentItem)
      if (contentItem.is_subscribed) {
        addSubscribedChat({ chat: contentItem, store: this.$store })
      } else {
        this.deleteChatFromChats(contentItem.id)
      }
    },
  },
  created() {
    const chatsCacheHandlersMapId = this.$cacheManager.add({
      regExpURL: Config.URLRegExps.PROFILE_CHATS,
      cacheHandlers: [
        (content) => {
          this.setProfileContent({ content, tab: Tab.CHATS })
        },
      ],
    })
    const imagesCacheHandlersMapId = this.$cacheManager.add({
      regExpURL: Config.URLRegExps.PROFILE_IMAGES,
      cacheHandlers: [
        (content) => {
          return this.setProfileContent({ content, tab: Tab.IMAGES })
        },
      ],
    })
    const videosCacheHandlersMapId = this.$cacheManager.add({
      regExpURL: Config.URLRegExps.PROFILE_VIDEOS,
      cacheHandlers: [
        (content) => {
          return this.setProfileContent({ content, tab: Tab.VIDEOS })
        },
      ],
    })
    this.cacheHandlersMapIds.push(
      chatsCacheHandlersMapId,
      imagesCacheHandlersMapId,
      videosCacheHandlersMapId
    )
  },
  beforeDestroy() {
    if (this.cacheHandlersMapIds) {
      this.$cacheManager.remove(this.cacheHandlersMapIds)
    }
  },
})
</script>

<style lang="scss" module>
.grid {
  flex: 1;
}
.product {
  grid-column: span 2;

  &.big {
    grid-column: span 3;
  }
}

.add {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 6px;
  column-gap: 1.5px;
  row-gap: 1.5px;
  border-top: 1px solid $secondary-light;

  & .container {
    position: relative;
    padding-bottom: 100%;
    overflow: hidden;

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }
  }

  .image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
}
</style>
