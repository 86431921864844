import store from '@store/index'
import i18n from '.'

const supportedLocales = 'ru,en'

const defaultLocale = 'ru'

const Trans = {
  get defaultLocale(): string {
    return defaultLocale
  },

  get supportedLocales(): string[] {
    return supportedLocales.split(',')
  },

  get currentLocale(): string {
    return i18n.locale
  },

  set currentLocale(newLocale: string) {
    store.commit('Locale/setLocale', newLocale)
    i18n.locale = newLocale
  },

  async switchLanguage(newLocale: string): Promise<void> {
    Trans.currentLocale = newLocale
    const htmlTag = document.querySelector('html')

    if (htmlTag) {
      htmlTag.setAttribute('lang', newLocale)
    }
    localStorage.setItem('locale', newLocale)
  },

  isLocaleSupported(locale: string): boolean {
    return Trans.supportedLocales.includes(locale)
  },

  getUserLocale(): { locale: string; localeNoRegion: string } {
    const locale = window.navigator.language || Trans.defaultLocale
    return {
      locale,
      localeNoRegion: locale.split('-')[0],
    }
  },

  getPersistedLocale(): string | null {
    const persistedLocale = localStorage.getItem('locale')
    if (persistedLocale) {
      if (Trans.isLocaleSupported(persistedLocale)) {
        return persistedLocale
      }
      return null
    }
    return null
  },

  guessDefaultLocale(): string {
    const userPersistedLocale = Trans.getPersistedLocale()
    if (userPersistedLocale) {
      return userPersistedLocale
    }

    const userPreferredLocale = Trans.getUserLocale()

    if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale
    }

    if (Trans.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion
    }

    return Trans.defaultLocale
  },

  async routeMiddleware(to: any, _from: any, next: any): Promise<void> {
    const paramLocale = to.params.locale
    if (typeof paramLocale === 'string') {
      if (!Trans.isLocaleSupported(paramLocale)) {
        return next(Trans.guessDefaultLocale())
      }
      await Trans.switchLanguage(paramLocale)
    }

    return next()
  },

  i18nRoute(to: any): any {
    if (to) {
      return {
        ...to,
        params: {
          locale: Trans.currentLocale,
          ...to.params,
        },
      }
    }
    return false
  },
}

export default Trans
