<template>
  <div :class="[$style.container, isBlur && $style.blur]">
    <swiper
      v-if="mediaLength > 1 && (isFeedPage || isPostPage)"
      v-model="sliderIndex"
      :class="[$style.slider, isProduct && 'product']"
      pagination
    >
      <swiper-slide v-for="(item, index) in media" :key="item.id" :style="item.id">
        <v-media
          :media="item"
          :video-ready="scenesReadyToLoad.includes(index)"
          :video-active="sliderIndex === index"
          :is-autoplay="isAutoplay"
        >
          <template #badges>
            <div :class="$style.cover"></div>
            <v-post-preview-badges
              v-if="isAccessible || isProduct"
              :post="post"
              :product="product"
              :is-product="isProduct"
              :is-footer="isFooter"
              :is-product-small="isProductSmall"
            />
          </template>
        </v-media>
      </swiper-slide>
    </swiper>
    <div v-else>
      <v-media
        :videoNoControl="!isFeedPage && !isPostPage"
        :media="mediaFirstItem"
        :video-size="videoSize"
        :is-autoplay="isAutoplay"
      >
        <template #badges>
          <!-- <div :class="$style.cover"></div> -->
          <v-post-preview-badges
            v-if="isAccessible || isProduct"
            :post="post"
            :product="product"
            :is-product="isProduct"
            :is-footer="isFooter"
            :is-product-small="isProductSmall"
          />
        </template>
      </v-media>
      <div :class="$style.infoicons">
        <icon v-if="mediaLength > 1" name="multi-content-white" />
        <icon v-if="isVideo" name="video-white" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import VMedia from '@layouts/VMedia.vue'
import VPostPreviewBadges from './VPostPreviewBadges.vue'

import 'swiper/swiper-bundle.css'

SwiperCore.use([Pagination])

export default Vue.extend({
  name: 'VPostPreviewMedia',
  components: { Swiper, SwiperSlide, VMedia, VPostPreviewBadges },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    isBlur: Boolean,
    isFooter: Boolean,
    isProductSmall: Boolean,
  },
  data() {
    return {
      sliderIndex: 0,
      scenesReadyToLoad: [],
      isAutoplay: false,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        freeMode: true,
        scrollbar: true,
        mousewheel: true,
      },
    }
  },
  computed: {
    ...mapGetters('Feed', ['intersectedPostId']),
    isFeedPage() {
      return this.$route.name === 'Feed'
    },
    media() {
      return this.post?.products ? this.post?.products[0]?.media_objects : this.post?.media_objects
    },
    mediaLength() {
      return this.media?.length
    },
    mediaFirstItem() {
      return this.media?.[0]
    },
    videoSize() {
      return this.isFeedPage || this.isPostPage ? 'lg' : 'sm'
    },
    isVideo() {
      return this.post?.media_objects?.some((item) => item.mime.includes('video'))
    },
    isAccessible() {
      return this.post?.is_accessible ?? false
    },
    product() {
      if (this.post?.products) {
        return this.post?.products[0]
      }
      return null
    },
    isProduct() {
      return !!this.product
    },
    isPostPage() {
      return !!this.$route?.params?.id
    },
  },
  watch: {
    sliderIndex: {
      handler(currentIndex) {
        for (let i = currentIndex; i <= currentIndex + 1; i++) {
          if (!this.scenesReadyToLoad.includes(i)) {
            this.scenesReadyToLoad.push(i)
          }
        }
      },
      immediate: true,
    },
    intersectedPostId: {
      handler(postId) {
        this.isAutoplay = postId === this.post?.id
      },
      immediate: true,
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;

  &.blur {
    filter: blur(10px);
  }
}

.infoicons {
  position: absolute;
  bottom: 0;
  left: 0;

  svg {
    width: 30px;
    height: 30px;
  }
}

.slider {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
}

.cover {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;

  height: 42px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.45) 100%);
}
</style>
