<template>
  <VButton
    :class="$style.container"
    :color="color"
    :is-disabled-padding="isDisabledPadding"
    size="md"
    @click="checkClientName"
    :is-loading="isLoading"
  >
    <VLikeIcon :color="iconColor" size="xl" :is-active="isLiked" />
    <VCount :class="$style.likes" :count="count" />
  </VButton>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VCount from '@layouts/VCount.vue'
import VLikeIcon from './VLikeIcon.vue'

export default Vue.extend({
  name: 'VLike',
  components: { VCount, VButton, VLikeIcon },
  props: {
    color: String,
    iconColor: { type: String, default: 'primary' },
    isDisabledPadding: Boolean,
    isLoading: Boolean,
    count: Number,
    isLiked: Boolean,
  },
  computed: {
    ...mapGetters('Client', ['clientName']),
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapActions('Post', ['setPostLike']),
    checkClientName() {
      if (!this.clientName) {
        this.setDrawer({ name: 'noname', isVisible: true })
        this.$bus.$on('noname-confirm', this.onLike)
      } else {
        this.onLike()
      }
    },
    async onLike() {
      this.$emit('like')
    },
  },
})
</script>

<style lang="scss" module>
.container {
  padding: 0 9px 0 2px !important;
  border-radius: 100px !important;
  column-gap: 0;
}

.likes {
  padding-top: 1px;
}
</style>
