// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CommentReportDrawer_userPreview_KFU9v{padding:9px;border-bottom:1px solid #e5e3e3}.CommentReportDrawer_list_5JPXW{height:inherit}", ""]);
// Exports
exports.locals = {
	"userPreview": "CommentReportDrawer_userPreview_KFU9v",
	"list": "CommentReportDrawer_list_5JPXW"
};
module.exports = exports;
