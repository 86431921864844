<template>
  <div :class="containerClasses">
    <!--    <VPostPreviewSmall-->
    <!--      v-for="postId in postIds"-->
    <!--      :key="`post-${postId}`"-->
    <!--      :post="visiblePosts[postId]"-->
    <!--      :class="$style.post"-->
    <!--      @click="onPostClick"-->
    <!--    />-->
    <VPostPreview
      v-for="postId in postIds"
      :key="`post-${postId}`"
      :post="visiblePosts[postId]"
      :class="$style.post"
      @click="onPostClick"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VPostPreviewSmall from '@layouts/postPreview/VPostPreviewSmall.vue'
import VPostPreview from '@layouts/postPreview/VPostPreview.vue'
import { GridBlockTypes } from '@modules/search/modules/posts/config'

export default Vue.extend({
  name: 'SearchPostsBlock',
  components: {
    VPostPreview,
    // VPostPreviewSmall
  },
  props: {
    postIds: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: GridBlockTypes.COMMON,
      validator: (value) => Object.values(GridBlockTypes).includes(value),
    },
  },
  computed: {
    ...mapGetters('Search/Posts', ['visiblePosts']),
    containerClasses() {
      return {
        [this.$style.container]: true,
        [this.$style.bigLeft]: this.type === GridBlockTypes.BIG_LEFT,
        [this.$style.bigRight]: this.type === GridBlockTypes.BIG_RIGHT,
      }
    },
  },
  methods: {
    onPostClick(redirectLink) {
      if (redirectLink?.name === 'Post') {
        const link = { ...redirectLink, name: 'SearchFeed' }
        return this.$router.push(link)
      }
      return this.$router.push(redirectLink)
    },
  },
})
</script>

<style module lang="scss">
.container {
  height: 315px;
  overflow: hidden;
  grid-template-rows: repeat(2, auto);
  display: grid;
  gap: 1px;
  grid-template-columns: 1fr 1fr 1fr;

  &.bigLeft {
    grid-template-columns: 1fr 2fr;
    grid-auto-flow: column;

    .post:last-child {
      grid-row: 1 / 3;
    }
  }
  &.bigRight {
    grid-template-columns: 2fr 1fr;

    .post:first-child {
      grid-row: 1 / 3;
    }
  }
}
</style>
