<template>
  <div :class="$style.container" @click="onClick">
    <VImage :class="[$style.image, isHidden && $style.blur]" :src="src" :srcset="srcset" />
    <VPostPreviewBlocked v-if="isHidden" :post="post" :class="$style.container" />
    <div :class="$style.iconWrapper">
      <icon v-if="hasSeveralMedia" name="multi-content-white" />
      <icon v-else-if="hasVideo" name="video-white" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VImage from '@elements/VImage.vue'
import VPostPreviewBlocked from '@layouts/postPreview/list/VPostPreviewBlocked.vue'

export default Vue.extend({
  name: 'VPostPreviewSmall',
  components: { VPostPreviewBlocked, VImage },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('Blocks', ['getIsAppBlocked']),
    ...mapGetters('Locale', ['locale']),
    mediaObjects() {
      return this.post?.media_objects ?? []
    },
    firstMedia() {
      return this.mediaObjects[0]
    },
    hasSeveralMedia() {
      return this.mediaObjects.length > 1
    },
    hasVideo() {
      return this.mediaObjects.some((item) => item.mime.includes('video'))
    },
    src() {
      return this.firstMedia?.content_url
    },
    srcset() {
      return this.firstMedia?.preview_url
    },
    isHidden() {
      return this.getIsAppBlocked(this.post?.app?.name)
    },
    isProduct() {
      return !!this.post?.product
    },
    promoId() {
      return this.post?.promotional_page_id
    },
    productRedirectLink() {
      return { name: 'Product', params: { locale: this.locale, id: this.post?.id } }
    },
    promoRedirectLink() {
      return {
        name: 'Promotional',
        params: { locale: this.locale, id: this.promoId },
        query: { post_id: this.post?.id },
      }
    },
    postRedirectLink() {
      return { name: 'Post', params: { locale: this.locale, id: this.post?.id } }
    },
    redirectLink() {
      return this.isProduct
        ? this.productRedirectLink
        : this.promoId
        ? this.promoRedirectLink
        : this.postRedirectLink
    },
  },
  methods: {
    ...mapMutations('Post', ['setPost', 'setPostMeta']),
    onClick() {
      this.setPostMeta({ postId: this.post?.id })
      this.$emit('click', this.redirectLink)
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  cursor: pointer;

  .image {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.blur {
      filter: blur(10px);
    }
  }

  .iconWrapper {
    position: absolute;
    bottom: 0;
    left: 0;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
