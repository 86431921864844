// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VDrawer_section_RepV2{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1000;display:flex;align-items:flex-end;justify-content:center;width:100%;height:100%;background-color:rgba(0,0,0,.6);-webkit-backdrop-filter:blur(3px);backdrop-filter:blur(3px)}.VDrawer_section_RepV2.VDrawer_pwa_3uPlG{padding-bottom:35px}.VDrawer_section_RepV2.VDrawer_ios_1HJjV{padding-bottom:80px}.VDrawer_container_Zc84U{position:relative;display:grid;grid-template-rows:auto 1fr;width:calc(100% - 18px);max-height:90vh;max-height:calc(var(--vh, 1vh)*90);margin:0 9px 0;overflow:hidden;color:var(--supster-secondary);background-color:#fff;border-radius:6px}.VDrawer_header_1oCRe{display:grid;grid-template-columns:1fr auto;align-items:center;padding:15px;-moz-column-gap:20px;column-gap:20px}.VDrawer_header_1oCRe.VDrawer_isBack_1agBe{grid-template-columns:auto 1fr auto}.VDrawer_header_1oCRe .VDrawer_title_1etoI{align-self:center;width:100%;overflow:hidden;color:var(--supster-secondary);font-weight:500;font-size:15px;line-height:15px;white-space:nowrap;text-overflow:ellipsis}.VDrawer_content_2r9hU{overflow-x:hidden;overflow-y:auto}.VDrawer_content_2r9hU::-webkit-scrollbar{width:5px;background:transparent}.VDrawer_content_2r9hU::-webkit-scrollbar-thumb{background:#181a22;border-radius:8px;cursor:pointer}.VDrawer_content_2r9hU::-webkit-scrollbar-thumb:hover{background:#24272f}.VDrawer_content_2r9hU::-webkit-scrollbar-track{background:hsla(0,0%,100%,.1)}.VDrawer_content_2r9hU::-webkit-scrollbar-corner{display:none}.VDrawer_loader_1PgPR{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.VDrawer_buttonClose_1zJ5I svg{fill:#000}", ""]);
// Exports
exports.locals = {
	"section": "VDrawer_section_RepV2",
	"pwa": "VDrawer_pwa_3uPlG",
	"ios": "VDrawer_ios_1HJjV",
	"container": "VDrawer_container_Zc84U",
	"header": "VDrawer_header_1oCRe",
	"isBack": "VDrawer_isBack_1agBe",
	"title": "VDrawer_title_1etoI",
	"content": "VDrawer_content_2r9hU",
	"loader": "VDrawer_loader_1PgPR",
	"buttonClose": "VDrawer_buttonClose_1zJ5I"
};
module.exports = exports;
