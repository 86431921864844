/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-white': {
    width: 30,
    height: 29,
    viewBox: '0 0 30 29',
    data: '<g filter="url(#filter0_d_3203_586)"><path pid="0" d="M20 5H10C7.79 5 6 6.79 6 9v10c0 2.21 1.79 4 4 4h10c2.21 0 4-1.79 4-4V9c0-2.21-1.79-4-4-4zm-8 13v-8l7 4-7 4z" _fill="#fff" fill-opacity=".85"/></g><defs><filter id="filter0_d_3203_586" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3203_586"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_3203_586" result="shape"/></filter></defs>'
  }
})
