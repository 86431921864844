// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileSubscribe_section_27d0E{display:grid;grid-gap:15px;padding-top:15px}.ProfileSubscribe_title_12D1w{font-weight:500;font-size:15px;line-height:15px;text-align:center}.ProfileSubscribe_button_2KriX{padding:0 15px 15px}", ""]);
// Exports
exports.locals = {
	"section": "ProfileSubscribe_section_27d0E",
	"title": "ProfileSubscribe_title_12D1w",
	"button": "ProfileSubscribe_button_2KriX"
};
module.exports = exports;
