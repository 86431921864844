import ClientApi from '@services/client'
import PromotionalApi from '@services/promotional'
import { isChatPersonal, isSupportChat } from '@helpers/chats'
import { isAppSupportChat } from '@modules/chat/helpers/chat'
import store from '@store/index'
import { Chat } from '@common-types/chat'
import { avatarUrlGettersMap, titleGettersMap } from './services-maps'

export const getTitle = (chat: Chat): string => {
  const titleGetter = titleGettersMap[chat.type] ?? titleGettersMap.default
  return titleGetter(chat)
}

export const getAvatarUrl = (chat: Chat): string => {
  const avatarUrlGetter = avatarUrlGettersMap[chat.type] ?? avatarUrlGettersMap.default
  return avatarUrlGetter(chat)
}

export const initProfileAppSupportChat = async (chat: Chat): Promise<void> => {
  const clientId = store.getters['App/appClientId'](chat.app)
  const name = store.getters['App/appSupportChatName'](chat.app)
  const image = store.getters['App/appSupportChatAvatar'](chat.app)
  const app_id = store.getters['App/appId'](chat.app)
  const isOwner = store.getters['Client/isClientOwner']
  const isMyChatApp = isOwner && store.getters['App/appId']() === app_id
  const is_accessible = !isMyChatApp && (await ClientApi.getAppSupportChatIsAccessible(clientId))
  const promotional = is_accessible
    ? null
    : await PromotionalApi.getPromotionals({
        app_id,
        trial: true,
      })
  const promotional_page_id = promotional?.[0]?.id ?? null
  const appSupportChat = {
    ...chat,
    is_accessible,
    promotional_page_id,
    name,
    image,
  }
  store.commit('Profile/setAppSupportChat', {
    appId: app_id,
    appSupportChat,
  })
}

export const isMyChannel = (chat: Chat): boolean => {
  const notOwner =
    isChatPersonal(chat.type) ||
    isAppSupportChat(chat.type) ||
    isSupportChat(chat.type) ||
    'temp' in chat
  return !notOwner && chat?.client?.id === store.getters['Client/clientId']
}
export default {
  getAvatarUrl,
  getTitle,
}
