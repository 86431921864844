/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add-2': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<rect pid="0" x="1.5" y="1.5" width="30" height="30" rx="15" _fill="#000"/><path pid="1" d="M16.5 11a1.5 1.5 0 00-1.5 1.5V15h-2.5a1.5 1.5 0 000 3H15v2.5a1.5 1.5 0 003 0V18h2.5a1.5 1.5 0 000-3H18v-2.5a1.5 1.5 0 00-1.5-1.5z" _fill="#fff"/><rect pid="2" x="1.5" y="1.5" width="30" height="30" rx="15" _stroke="#fff" stroke-width="3"/>'
  }
})
