import Vue from 'vue'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const marked = require('marked')

Vue.use({
  install() {
    Vue.marked = marked
    Vue.prototype.$marked = marked
  },
})
