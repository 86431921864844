<template>
  <div>
    <v-drawer
      name="auth"
      :is-back="!!type"
      :is-close="!type && !isAfterPayment"
      :title="drawerTitle"
      @close="onClose"
      @back="onBack"
      :isCloseOutside="!isAfterPayment"
    >
      <transition-group name="scroll-group">
        <div v-if="!type" :class="$style.content" key="login">
          <v-button
            v-if="!isAfterPayment"
            size="xl-1"
            color="border-primary"
            :class="[$style.button, $style.reg]"
            @click="toLoginForm('email')"
          >
            {{ $t('login.enterToProfile') }}
          </v-button>
          <v-button @click="toRegistrationForm" size="xl-1" color="primary" :class="$style.button">
            {{ $t('login.register') }}
          </v-button>
        </div>
        <!-- <login-options v-if="isLoginOptions" key="options" @toLoginForm="toLoginForm" /> -->
        <login-form
          v-if="isLoginForm"
          key="login-form"
          :formType="formType"
          @recovery="toRecoveryForm"
          @submit="enterToApp"
        />
        <registration-form
          @submit="onSubmitRegistration"
          v-show="isRegistrationForm"
          key="registration-form"
        />
        <recovery-form
          v-show="isRecoveryForm"
          key="recovery-form"
          :is-loading="isLoadingPhone"
          :class="$style.phoneInput"
          :formType="formType"
          @submit="sendCodeToEmail"
          :isLoading="isLoading"
        />
        <code-form
          v-if="isCodeForm"
          :mode="mode"
          key="code"
          :class="$style.codeInput"
          :data="dataForRegistraion"
          @submit="sendCodeToBackend"
          @resend="sendCodeToBackend"
          @back="onBack"
          @clearError="error = ''"
          :email-token="emailToken"
          :password="password"
          :email="email"
          :isLoading="isLoading"
          :error="error"
        />
        <new-password-form @submit="sendNewPassword" v-if="isNewPasswordForm" key="new" />
      </transition-group>
    </v-drawer>
    <v-modal :is-visible="isVisibleRegisterModal" no-scrollbar>
      <div :class="$style.container">
        <icon :class="$style.icon" name="check-pattern-lg" />
        <div :class="$style.congrats">
          {{ $t('login.congrats') }}
        </div>
        <div :class="$style.congratsDesc">
          {{ $t('login.congratsDesc') }}
        </div>
        <v-button size="xl" color="primary" @click="enterToApp">
          {{ $t('login.enter') }}
        </v-button>
      </div>
    </v-modal>
    <v-modal :is-visible="isVisibleRecoveryModal" no-scrollbar>
      <div :class="$style.container">
        <icon :class="$style.icon" name="check-pattern-lg" />
        <div :class="$style.congrats">
          {{ $t('login.youPasswordRecovery') }}
        </div>
        <v-button size="xl" color="primary" @click="enterToApp">
          {{ $t('login.enter') }}
        </v-button>
      </div>
    </v-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VButton from '@elements/VButton.vue'
import VModal from '@modals/VModal.vue'
import CodeForm from './CodeForm.vue'
import LoginOptions from './LoginOptions.vue'
import LoginForm from './LoginForm.vue'
import NewPasswordForm from './NewPasswordForm.vue'
import RegistrationForm from './RegistrationForm.vue'
import RecoveryForm from './RecoveryForm.vue'

export default Vue.extend({
  name: 'AuthDrawer',
  components: {
    VDrawer,
    VButton,
    VModal,
    RecoveryForm,
    CodeForm,
    // LoginOptions,
    LoginForm,
    NewPasswordForm,
    RegistrationForm,
  },

  data() {
    return {
      type: '',
      dataForRegistraion: {},
      phoneNumber: '',
      token: '',
      clientId: '',
      isLoadingPhone: false,
      isVisibleRegisterModal: false,
      isVisibleRecoveryModal: false,
      formType: '',
      mode: '',
      isLoading: false,
      error: '',
      password: '',
      email: '',
      emailToken: '',
    }
  },
  computed: {
    ...mapGetters('Drawers', ['drawer', 'drawerParams']),
    ...mapGetters('Promotional', ['getRedirectUrl']),
    ...mapGetters('Client', ['clientLoginToken']),
    isLoginOptions() {
      return this.type === 'login-options'
    },
    isLoginForm() {
      return this.type === 'login-form'
    },
    isRegistrationForm() {
      return this.type === 'registration-form'
    },
    isRecoveryForm() {
      return this.type === 'recovery-form'
    },
    isCodeForm() {
      return this.type === 'code-form'
    },
    isNewPasswordForm() {
      return this.type === 'new-password-form'
    },
    isRegistrationMode() {
      return this.mode === 'registration'
    },
    phoneNumberOrigin() {
      return this.phoneNumber.replace(/[^0-9]/g, '')
    },
    isAfterPayment() {
      return this.drawerParams?.afterPayment ?? false
    },

    drawerTitle() {
      if (this.isLoginOptions) {
        return this.$t('login.loginOptions.title')
      }
      if (this.isLoginForm) {
        return this.$t('login.enterToAccount')
      }
      if (this.isRegistrationForm || (this.isCodeForm && this.isRegistrationMode)) {
        return this.$t('login.registration')
      }
      if (this.isCodeForm || this.isRecoveryForm || this.isNewPasswordForm) {
        return this.$t('login.passwordRecovery')
      }
      if (this.isAfterPayment) {
        return this.$t('login.afterPayment')
      }
      return this.$t('login.entertoYourProfile')
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapActions('Client', ['postClientCode', 'postClientCodeAuth', 'putClientForRegisitration']),
    ...mapActions('Order', ['postOrder']),
    onSubmitRegistration({ email, password, emailToken }) {
      this.email = email
      this.emailToken = emailToken
      this.password = password
      this.mode = 'registration'
      this.toCodeForm()
    },
    onClose() {
      this.type = ''
    },
    onBack() {
      if (this.isRecoveryForm) {
        this.toLoginForm('email')
      } else if (this.isNewPasswordForm) {
        this.toRecoveryForm(this.formType)
      } else if (this.isCodeForm) {
        if (this.mode === 'registration') {
          this.toRegistrationForm()
        } else {
          this.toRecoveryForm(this.formType)
        }
      } else {
        this.type = ''
      }
    },

    toLoginOptions() {
      this.type = 'login-options'
    },
    toLoginForm(formType) {
      this.type = 'login-form'
      this.formType = formType
    },
    toRegistrationForm() {
      this.type = 'registration-form'
      this.mode = 'registration'
    },
    toRecoveryForm(formType) {
      this.type = 'recovery-form'
      this.formType = formType
    },
    toNewPasswordForm() {
      this.type = 'new-password-form'
    },
    toCodeForm() {
      this.type = 'code-form'
    },

    async sendCodeToEmail(formData) {
      this.isLoading = true
      const { error, token } = await this.postClientCode({
        email: formData.email,
        type: 3,
      })
      if (token) {
        this.token = token
      }
      if (error) {
        this.$notify({
          type: 'error',
          title: `${this.$t('errors.login.caption')}`,
          text: `${this.$t('errors.login.descriptionLoad')}`,
        })
      } else {
        this.toCodeForm()
      }
      this.isLoading = false
    },
    async sendCodeToBackend(code) {
      this.isLoading = true
      const res = await this.postClientCodeAuth({
        code,
        token: this.token,
      })
      if (res.access_token) {
        this.token = res.access_token
      }
      if (res.user) {
        this.clientId = res.user.client.id
      }

      if (res.error) {
        if (res.error.data.error.includes('code is not correct')) {
          this.error = this.$t('errors.login.code')
        }
        this.$notify({
          type: 'error',
          title: `${this.$t('errors.title')}`,
          text: `${this.$t('errors.billing.noLink')}`,
        })
      } else {
        this.toNewPasswordForm()
      }
      this.isLoading = false
    },
    async sendNewPassword(password) {
      this.isLoading = true
      const { error } = await this.putClientForRegisitration({
        id: this.clientId,
        client: {
          password,
        },
      })
      if (error) {
        this.error = this.$t('errors.login.emailAlreadyInUse')
      } else {
        this.recoveryComplete()
      }
      this.isLoading = false
    },

    codeFormHandler() {
      if (!this.isRegistrationMode) {
        this.toNewPasswordForm()
      } else {
        this.registrationComplete()
      }
    },

    async onSubmitPhone(phoneNumber) {
      this.isLoadingPhone = true
      // if (phoneNumber) {
      //   this.phoneNumber = phoneNumber
      // }
      // const { error } = await this.postClientPhone(this.phoneNumberOrigin)
      // if (error) {
      //   this.$notify({
      //     type: 'error',
      //     title: `${i18n.t('errors.login.caption')}`,
      //     text: `${i18n.t('errors.login.descriptionLoad')}`,
      //   })
      // } else {
      //   this.type = 'code-input'
      // }
      this.toCodeForm()
      this.isLoadingPhone = false
    },
    recoveryComplete() {
      this.setDrawer({ name: 'auth', isVisible: false })
      this.isVisibleRecoveryModal = true
      this.reset()
    },
    nextLoginTry() {
      this.setDrawer({ name: 'auth', isVisible: true })
      this.isVisibleRecoveryModal = false
    },
    registrationComplete() {
      this.setDrawer({ name: 'auth', isVisible: false })
      this.reset()
      this.isVisibleRegisterModal = true
    },
    async enterToApp() {
      this.isVisibleRegisterModal = false
      if (this.getRedirectUrl) {
        this.$router.push(this.getRedirectUrl)
      } else {
        const { app_id, tariff_id, type } = this.drawerParams ?? {}
        if (tariff_id) {
          this.setDrawer({ name: 'auth', isVisible: false })
          await this.postOrder({
            app_id,
            tariff_id,
            type,
          })
          // eslint-disable-next-line no-undef
          // ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'payment-form-open')
          this.setDrawer({ name: 'payment', isVisible: true })
          return
        }
        this.$router.go(0)
      }
    },
    reset() {
      this.type = ''
      this.phoneNumber = ''
      this.dataForRegistraion = {}
      this.mode = ''
    },
  },
})
</script>

<style lang="scss" module>
.content {
  position: relative;
  display: grid;
  width: 100%;
  padding: 15px;
  overflow: hidden;
  box-shadow: inset 0 1px 0 $secondary-light;
}

.button {
  width: initial;
  &.reg {
    margin-bottom: 8px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  .icon {
    justify-self: center;
    width: 90px;
    height: 90px;
    margin-bottom: 15px;
    fill: $primary !important;
  }

  .congrats {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
  }
  .congratsDesc {
    margin-bottom: 15px;
    color: rgba(black, 0.5);
    font-size: 14px;
    text-align: center;
  }
}
</style>
