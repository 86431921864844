<template>
  <div :class="[$style.instruction_wrapper]">
    <div :class="[$style.instruction_notify, notifyActive && $style.active]">
      <div :class="[$style.row]">
        <div>
          <icon @click="closeNotify" :class="[$style.icon]" name="close" width="14" height="16" />
        </div>
        <div>
          <span :class="[$style.text]">{{ $t('install_instruction.notify_text') }}</span>
        </div>
        <div>
          <button @click="open" :class="[$style.button, $style.closeBtn]">
            {{ $t('install_instruction.install') }}
          </button>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="opened" :class="[$style.instruction, $style.ios]">
        <div :class="[$style.container]">
          <div @click="close" :class="[$style.section, $style.step0, $style.backButton]">
            <h1 :class="[$style.title]">
              <v-button @click="close" :class="$style.button" color="transparent">
                <icon name="back" width="9" height="17" />
              </v-button>
              {{ $t('install_instruction.inst_title') }}
            </h1>
          </div>

          <!-- IOS Instruction -->
          <div v-if="isIOS">
            <!-- step 1 -->
            <div :class="[$style.section, $style.step1]">
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-ios-step-0.png')"
                  alt="step 0"
                />
                <img v-else :src="require('@assets/images/install/step_0.png')" alt="step 0" />
              </div>
              <h2 :class="[$style.title]">{{ $t('install_instruction.inst_step1_title') }}</h2>
              <p :class="[$style.subtitle]">{{ $t('install_instruction.inst_step1_subtitle') }}</p>
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-ios-step-1.png')"
                  alt="step 0"
                />
                <img v-else :src="require('@assets/images/install/step_1.png')" alt="step 1" />
              </div>
            </div>
            <!-- step 2 -->
            <div :class="[$style.section, $style.step2]">
              <h2 :class="[$style.title]">{{ $t('install_instruction.inst_step2_title') }}</h2>
              <p :class="[$style.subtitle]">{{ $t('install_instruction.inst_step2_subtitle') }}</p>
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-ios-step-2.png')"
                  alt="step 0"
                />
                <img v-else :src="require('@assets/images/install/step_2.png')" alt="step 2" />
              </div>
            </div>
            <!-- step 3 -->
            <div :class="[$style.section, $style.step3]">
              <h2 :class="[$style.title]">{{ $t('install_instruction.inst_step3_title') }}</h2>
              <p :class="[$style.subtitle]">{{ $t('install_instruction.inst_step3_subtitle') }}</p>
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-ios-step-3.png')"
                  alt="step 0"
                />
                <img v-else :src="require('@assets/images/install/step_3.png')" alt="step 3" />
              </div>
            </div>
            <!-- step 4 -->
            <div :class="[$style.section, $style.step4]">
              <h2 :class="[$style.title]">{{ $t('install_instruction.inst_step4_title') }}</h2>
              <p :class="[$style.subtitle]">{{ $t('install_instruction.inst_step4_subtitle') }}</p>
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-ios-step-4.png')"
                  alt="step 0"
                />
                <img v-else :src="require('@assets/images/install/step_4.png')" alt="step 4" />
              </div>
            </div>
          </div>
          <div v-else>
            <!-- step 1 -->
            <div :class="[$style.section, $style.step1, $style.android]">
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-android-step-0.png')"
                  alt="step 0"
                />
                <img
                  v-else
                  :src="require('@assets/images/install/android/step_0.png')"
                  alt="step 0"
                />
              </div>
              <h2 :class="[$style.title]">
                {{ $t('install_instruction.androind.inst_step1_title') }}
              </h2>
              <p :class="[$style.subtitle]">
                <a :href="appUrl">
                  {{ appUrl }}
                </a>
                <v-button :class="$style.button" @click="copyUrl">
                  <icon name="copy" width="11" height="10" />
                </v-button>
              </p>
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-android-step-1.png')"
                  alt="step 0"
                />
                <img
                  v-else
                  :src="require('@assets/images/install/android/step_1.png')"
                  alt="step 1"
                />
              </div>
            </div>
            <!-- step 2 -->
            <div :class="[$style.section, $style.step2, $style.android]">
              <h2 :class="[$style.title]">
                {{ $t('install_instruction.androind.inst_step2_title') }}
              </h2>
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-android-step-2.png')"
                  alt="step 0"
                />
                <img
                  v-else
                  :src="require('@assets/images/install/android/step_2.png')"
                  alt="step 2"
                />
              </div>
            </div>
            <!-- step 3 -->
            <div :class="[$style.section, $style.step3, $style.android]">
              <h2 :class="[$style.title]">
                {{ $t('install_instruction.androind.inst_step3_title') }}
              </h2>
              <div :class="[$style.content]">
                <img
                  v-if="isGpt"
                  :src="require('@assets/images/install/gpt/gpt-android-step-3.png')"
                  alt="step 0"
                />
                <img
                  v-else
                  :src="require('@assets/images/install/android/step_3.png')"
                  alt="step 3"
                />
              </div>
            </div>
            <!-- step 4 -->
            <div :class="[$style.section, $style.step4, $style.android]">
              <h2 :class="[$style.title]">
                {{ $t('install_instruction.androind.inst_step4_title') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { Clipboard } from 'v-clipboard'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'Instruction',
  components: {
    VButton,
  },
  props: {
    isGpt: Boolean,
  },
  computed: {
    ...mapGetters('Page', ['isIOS']),

    appUrl() {
      return window.location.origin
    },
  },
  data() {
    return {
      opened: false,
      notifyActive: false,
    }
  },
  methods: {
    close() {
      this.opened = false
      this.notifyActive = true
    },
    closeNotify() {
      this.notifyActive = false
    },
    open() {
      this.opened = true
      this.notifyActive = true
    },
    copyUrl() {
      Clipboard.copy(this.appUrl)
    },
  },
  mounted() {
    setTimeout(() => {
      this.notifyActive = true
    }, 300)
  },
})
</script>

<style lang="scss" module>
.instruction_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  color: $secondary;
}

.instruction_notify {
  width: 100%;
  max-width: 420px;
  margin-top: -48px;
  transition: all 300ms ease;
  &.active {
    margin: 0 auto;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 0 12px 0 24px;
    background: #fff;
    box-shadow: 0px -1px 0px 0px $secondary-light inset;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 24px;
    padding: 0 6px;
    color: $primary-light-text-color;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    background-color: $primary-light-background;
    border-radius: 6px;
  }
  .icon {
    display: block;
    margin: -2px 0 0 0;
  }

  .text {
    display: block;
    max-width: 240px;
    padding: 0 10px;
    color: var(--supster-secondary);
    font-size: 12px;
    line-height: 15px;
  }
}

.instruction {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #271460;
  transition: all 300ms ease;
  .container {
    max-width: 370px;
    margin: 0 auto;
    padding: 12px 0 0;
    padding-top: 80px;
  }
  .title {
    position: relative;
    margin: 20px 0 0 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  .subtitle {
    display: block;
    margin: 6px 0 30px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    a {
      display: flex;
      display: inline-block;
      color: inherit;
      font-size: inherit;
      text-decoration: underline;
    }
  }
  .backButton {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding: 20px 0;
    background: #271460;
    .title {
      margin: 0 !important;
    }
  }
  .content {
    text-align: center;
    img {
      display: inline-block;
    }
  }

  .section.step0 {
    .title {
      margin-bottom: 37px;
    }
    .button {
      position: absolute;
      top: -6px;
      left: 20px;
      width: 32px;
      height: 32px;
      color: #fff;
      cursor: pointer;
    }
  }
  .section.step1 {
    .title {
      margin-top: 15px;
    }
    .button {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-left: 14px;
      padding: 0;
      color: #fff;
      background: rgba(255, 255, 255, 0.2);
      border: none;
    }
  }
  .section.step4 {
    padding-bottom: 36px;
    .subtitle {
      margin-bottom: 30px;
    }
  }
  .section.android {
    .title {
      margin-bottom: 35px;
    }
    &.step1 {
      .title {
        margin-bottom: 0;
      }
    }
  }
}
</style>
