import { Mutations } from './types'

const mutations: Mutations = {
  setFollowing(state, apps) {
    state.following.data = [...(state.following.data || []), ...apps]
    state.following.page++
  },
  setFollowingItemSubscribe(state, { id, isFollowed }) {
    if (state.following.data) {
      state.following.data.forEach((item) => {
        if (typeof id === 'number' && item.my_app_subscription?.id === id) {
          item.my_app_subscription = null
        }
        if (typeof id === 'string' && item.id === id) {
          item.my_app_subscription = { id: isFollowed }
        }
      })
    }
  },
  setFollowingQuery(state, query) {
    state.query = query
  },
  resetFollowing(state) {
    state.following.data = null
    state.following.page = 1
  },
  resetSearchQuery(state) {
    state.query = ''
  },
}

export default mutations
