import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'ChatObserverConnection',

  render() {
    return this.$scopedSlots.default()
  },

  computed: {
    ...mapGetters('Page', ['pageIsOffline']),
    ...mapGetters('Chat', ['chat', 'chatTempMessages', 'chatId']),

    paramsIsClientId() {
      return !!this.$route?.query?.isClientId
    },
    paramsId() {
      return Number(this.$route?.params?.id ?? 0)
    },
  },

  watch: {
    pageIsOffline(value) {
      if (!value) {
        setTimeout(async () => {
          if (!this.chat && this.paramsIsClientId) {
            this.resetChat()
            await this.getChat({
              id: this.paramsId,
              isClientId: this.paramsIsClientId,
            })
          }
          if (this.chatTempMessages) {
            await this.postTempChatMessages()
          }
          localStorage.removeItem(`temp-messages-${this.chatId}`)
        }, 2000)
      }
    },
  },

  methods: {
    ...mapMutations('Chat', ['resetChat']),
    ...mapActions('Chat', ['getChat', 'postTempChatMessages']),
  },
})
