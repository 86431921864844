<template>
  <div :class="$style.content" ref="dropdown">
    <v-popover
      offset="3"
      trigger="manual"
      :class="$style.popover"
      :container="$refs.dropdown"
      :boundaries-element="null"
      :popover-base-class="[$style.popoverBaseClass, 'tooltip', 'popover']"
      :open.sync="isOpen"
      :is-disabled="isLoading"
    >
      <v-input :title="title" :disabled="isDisabled" :error="error" :error-to-show="errorToShow">
        <v-dropdown
          :class="[$style.dropdown, className, $style[color]]"
          :icon-size="iconSize"
          :color="color"
          :class-name="className"
          :is-open="isOpen"
          :is-disabled="isLoading || isDisabled"
          :no-arrow="noArrow"
          @click="isOpen = !isOpen"
        >
          <template #name>
            <transition name="slide-up-kit" mode="out-in">
              <slot v-if="isLocale" name="locale-label" />
              <span
                v-else
                :class="{
                  [$style.placeholder]: placeholder && !currentValue,
                  [$style.smallText]: smallText,
                }"
                :key="currentValue"
              >
                {{ currentValue || placeholder }}
              </span>
            </transition>
            <overlay-loading-loader :is-loading="isLoading" :delay="0" :size="5" />
          </template>
        </v-dropdown>
      </v-input>
      <template #popover>
        <div v-if="isSearch" :class="$style.search">
          <text-input v-model="search" :class="$style.input" :placeholder="searchPlaceholder">
            <template #icon>
              <svg :class="$style.icon" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <!-- eslint-disable -->
                <path
                  d="M13.8535 13.1464L9.74261 9.03558C9.73669 9.02966 9.7287 9.02777 9.72253 9.02222C10.5194 8.06793 11 6.84045 11 5.5C11 2.46246 8.53754 0 5.5 0C2.4624 0 0 2.46246 0 5.5C0 8.5376 2.4624 11 5.5 11C6.84045 11 8.06793 10.5193 9.02222 9.72247C9.02777 9.72864 9.02966 9.73669 9.03558 9.74261L13.1464 13.8535C13.2441 13.9511 13.372 13.9999 13.4999 13.9999C13.6279 13.9999 13.7558 13.9511 13.8535 13.8535C14.0488 13.6581 14.0488 13.3417 13.8535 13.1464ZM5.5 10C3.01868 10 1 7.98132 1 5.5C1 3.01868 3.01868 1 5.5 1C7.98126 1 10 3.01868 10 5.5C10 7.98132 7.98126 10 5.5 10Z"
                />
                <!-- eslint-enable -->
              </svg>
            </template>
          </text-input>
        </div>
        <ul :class="$style.list">
          <template v-if="listItemsLength">
            <li
              v-for="(item, index) in filteredList"
              :key="`dropdown-${name}-${index}`"
              :class="[
                $style.item,
                currentValue === item.value && $style.itemActive,
                item.disabled && $style.itemDisabled,
              ]"
              @click="!item.disabled && onChange(item)"
            >
              <VLocaleLabel v-if="isLocale" :locale="item.value" />
              <p v-else>{{ item.value }}</p>
            </li>
          </template>
          <div v-else :class="$style.noData">
            <p v-if="!noDataText">No data</p>
            <slot else name="no-data" />
          </div>
        </ul>
      </template>
    </v-popover>
  </div>
</template>
<script>
import { VPopover } from 'v-tooltip'
import Vue from 'vue'
import validate from '@utils/validate'

import VDropdown from '@dropdowns/VDropdown.vue'
import OverlayLoadingLoader from '@loaders/list/OverlayLoadingLoader.vue'
import VInput from '@elements/inputs/VInput.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import VLocaleLabel from '@components/VLocaleLabel.vue'

export default Vue.extend({
  name: 'SelectDropdown',
  components: { VLocaleLabel, VInput, VDropdown, VPopover, TextInput, OverlayLoadingLoader },
  props: {
    title: {
      require: true,
      type: String,
    },
    name: {
      require: true,
      type: String,
    },
    className: [String, Array],
    list: {
      require: true,
      default: () => [],
      type: Array,
    },
    placeholder: {
      default: '. . .',
      type: String,
    },
    searchPlaceholder: {
      default: 'Search',
      type: String,
    },
    selectValue: [String, Number],
    rules: {
      default: () => [],
      type: Array,
    },
    color: {
      default: 'default',
      type: String,
    },
    iconSize: String,
    isSearch: Boolean,
    isDisabled: Boolean,
    isLoading: Boolean,
    noArrow: Boolean,
    smallText: Boolean,
    isLocale: Boolean,
  },
  data() {
    return {
      currentElement: null,
      search: '',
      error: '',
      errorToShow: '',
      isOpen: false,
    }
  },
  computed: {
    currentValue() {
      return this.currentElement?.value ?? this.selectValue
    },
    filteredList() {
      if (!this.search) {
        return this.list
      }
      const q = this.search.toLowerCase()
      return this.list.filter((o) => o.value.toLowerCase().includes(q))
    },
    listItemsLength() {
      return this.filteredList?.length ?? 0
    },
    noDataText() {
      return !!this.$slots['no-data']
    },
  },
  watch: {
    currentValue() {
      this.validate()
    },
  },
  async mounted() {
    this.error = await validate(this.rules, this.currentValue)
  },
  methods: {
    async validate() {
      this.error = await validate(this.rules, this.currentValue)
      this.errorToShow = this.error
      return this.error
    },
    onChange(element) {
      this.currentElement = element
      this.$emit('change', element)
      this.isOpen = !this.isOpen
    },
  },
})
</script>
<style lang="scss" module>
.dropdown {
  position: relative;

  &.default {
    @include input();
  }

  &.transparent {
    padding: 0;
  }

  &.transparent-light-background {
    .smallText {
      color: black;
    }
  }
}

.content {
  position: relative;
  display: block;
}

.popover {
  width: 100%;

  :global(.trigger) {
    width: 100%;
  }
}

.popoverBaseClass {
  min-width: max-content;
}

.popover.open {
  transform: rotate(-90deg);
}

.placeholder {
  color: $secondary-medium;
}

.list {
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  font-size: 15px;
  line-height: 14px;
  background: white;
}

.item {
  padding: 14px;
  color: $secondary;
  font-weight: 400;
  border-bottom: 2px solid $secondary;
  cursor: pointer;
  transition: opacity $transition, font-weight $transition;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    border: 0;
  }

  &Disabled {
    cursor: not-allowed;
    opacity: 0.4;

    &:hover {
      opacity: 0.4;
    }
  }
}

.search {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  background: white;
  border-bottom: 2px solid $secondary;
}

.input {
  border: 0;
  border-radius: 0;
}

.icon {
  width: 14px;
  height: 14px;
  color: $secondary;
}

.noData {
  padding: 14px;
  color: $secondary;
  font-weight: 400;
}
.smallText {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
</style>
