/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M13.948 5.266a1 1 0 00-.797-.671l-3.618-.553L7.905.575a1 1 0 00-1.81 0L4.467 4.042.85 4.595a1 1 0 00-.565 1.687l2.648 2.713-.627 3.844a1 1 0 001.47 1.036L7 12.092l3.224 1.783a.999.999 0 001.471-1.036l-.627-3.844 2.648-2.713a1 1 0 00.232-1.016zM10 8.658L10.708 13 7 10.95 3.292 13 4 8.658 1 5.584l4.146-.634L7 1l1.854 3.95L13 5.583l-3 3.075zM8 8a1 1 0 11-2 0 1 1 0 012 0z"/>'
  }
})
