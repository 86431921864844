<template>
  <v-modal
    :is-visible="isVisible"
    :title="title"
    is-confirm
    no-validation
    @update:is-visible="$emit('update:is-visible', $event)"
    @cancel="
      $emit('update:is-visible', false)
      $emit('cancel')
    "
    @submit="onConfirm"
    @close="$emit('close')"
  >
    <template #accept>
      {{ $t('confirm.yes') }}
    </template>
    <template #cancel>
      {{ $t('confirm.no') }}
    </template>
  </v-modal>
</template>
<script>
import Vue from 'vue'
import VModal from '@modals/VModal.vue'

export default Vue.extend({
  name: 'ConfirmModal',
  components: {
    VModal,
  },
  props: {
    title: String,
    isVisible: Boolean,
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', true)
      this.$emit('update:is-visible', false)
    },
  },
})
</script>
<style lang="scss" module></style>
