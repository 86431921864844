import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@store/index'
import { Client } from '@services/client/types'
import { GlobalService } from '@services/types'
import { ToastTypes } from '@modules/toasts/constants'
import i18n from '@plugins/i18n'
import ChatApi from '@modules/chat/api/chat'
import { createChat } from '@modules/chat/helpers/chat'
import { Prompt } from '@modules/prompts/types'
import { Chat, ChatType, TemplateChat } from '@common-types/chat'

type ServiceParams = {
  client: Client
  recipient: Client
  clientId: Client['id']
  progressCallback: () => void
  chatType: ChatType
  formData: {
    name: string
    description: string
    id: string
  }
}
type Service<T extends keyof ServiceParams, R> = GlobalService<ServiceParams, T, R>

const Mapper = {
  $store: store,
  ...mapGetters('Clients', ['someClient']),
  ...mapMutations('Chat', ['setChat']),
  ...mapActions('Chat', ['getChat', 'postChat']),
  ...mapActions('Clients', ['getClientFromId']),
  ...mapMutations('Toasts', ['pushToast']),
  ...mapGetters('Client', ['client', 'clientBot']),
  ...mapActions('Media', ['postMedia']),
  ...mapGetters('App', ['appId']),
  ...mapActions('Client', ['getClientBot']),
  ...mapGetters('Prompts', ['getPrompts', 'getPromptById']),
  ...mapActions('Prompts', ['getPrompt']),
}
export const setFakePersonalChat: Service<'client' | 'recipient', void> = ({
  client,
  recipient,
}) => {
  const fakeChat = createChat({
    recipient,
    client,
    type: 3,
    name: client.name,
    image: client.avatar,
  })
  Mapper.setChat(fakeChat)
}

export const initSupportChat = async (): Promise<void> => {
  const { data: supportChats } = await ChatApi.getChats({
    type: ChatType.SUPPORT_CHAT,
    page: 1,
    limit: 1,
  })
  const supportChat = supportChats?.[0]
  const chat =
    supportChat ??
    createChat({
      type: ChatType.SUPPORT_CHAT,
    })
  Mapper.setChat(chat)
}

export const initPersonalChatByClientId = async (clientId: Client['id']): Promise<void> => {
  const chat = await Mapper.getChat({ id: clientId, isClientId: true })
  if (chat) {
    return
  }
  const recipient = Mapper.someClient()(clientId) ?? (await Mapper.getClientFromId(clientId))
  if (!recipient) {
    Mapper.pushToast({
      type: ToastTypes.ERROR,
      title: i18n.t('errors.title'),
      message: i18n.t('errors.clients.notFounded'),
    })
    return
  }
  setFakePersonalChat({
    recipient,
    client: Mapper.client(),
  })
}

export const initChatById = async (chatId: Chat['id']): Promise<void> => {
  const chat = await Mapper.getChat({ id: chatId })
  if (!chat) {
    Mapper.pushToast({
      type: ToastTypes.ERROR,
      title: i18n.t('errors.title'),
      message: i18n.t('errors.chats.notFounded'),
    })
  }
}

export const initOtherChat: Service<'progressCallback' | 'chatType' | 'formData', void> = async ({
  progressCallback,
  chatType,
  formData,
}) => {
  const media = await Mapper.postMedia(progressCallback)
  await Mapper.postChat({
    app_id: chatType !== 3 ? Mapper.appId()() : null,
    name: formData.name,
    description: formData.description,
    image_id: media?.[0]?.id,
    type: chatType,
    client_id: formData?.id ? Number(formData.id) : null,
  })
}

const toastPromptDoesntExist = () => {
  Mapper.pushToast({
    type: ToastTypes.ERROR,
    title: i18n.t('errors.title'),
    message: i18n.t('errors.chats.notFounded'),
  })
}

export const addPromptToChat = async (
  promptId: Prompt['id'],
  chat: TemplateChat
): Promise<TemplateChat> => {
  const prompt = Mapper.getPromptById()(promptId) ?? (await Mapper.getPrompt(promptId))
  if (prompt) {
    return {
      ...chat,
      chat_gpt_system_prompt: prompt,
      is_prompt: true,
    }
  }
  toastPromptDoesntExist()
  return chat
}

export const initTempGptChat = async (promptId?: Prompt['id'] | null): Promise<void> => {
  if (!Mapper.clientBot()) {
    await Mapper.getClientBot()
  }
  const { id, name, avatar } = Mapper.clientBot()
  let chat = createChat({
    type: 3,
    client_id: id,
    name,
    image: avatar,
    client: Mapper.client(),
    recipient: Mapper.clientBot(),
  })
  if (promptId) {
    chat = await addPromptToChat(promptId, chat)
  }
  Mapper.setChat(chat)
}
export default {
  setFakePersonalChat,
}
