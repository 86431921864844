// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoNameDrawer_drawer_1DUQ0>*{padding:0 15px 15px 15px}.NoNameDrawer_input_3kavd{margin:0;padding:0}.NoNameDrawer_text_2kOhO{font-weight:400;font-size:12px;line-height:18px}.NoNameDrawer_agreement_3p_vs{padding:15px 45px;text-align:center;box-shadow:inset 0 -1px 0 #e5e3e3}.NoNameDrawer_link_2oCni{color:#000;font-weight:600;font-size:12px;text-decoration:underline;cursor:pointer}.NoNameDrawer_buttonWrapper_QcIyb{padding:15px}", ""]);
// Exports
exports.locals = {
	"drawer": "NoNameDrawer_drawer_1DUQ0",
	"input": "NoNameDrawer_input_3kavd",
	"text": "NoNameDrawer_text_2kOhO",
	"agreement": "NoNameDrawer_agreement_3p_vs",
	"link": "NoNameDrawer_link_2oCni",
	"buttonWrapper": "NoNameDrawer_buttonWrapper_QcIyb"
};
module.exports = exports;
