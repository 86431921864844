<template>
  <button
    :class="[$style.button, disabled && $style.disabled, active && $style.active]"
    v-on="!disabled && $listeners"
    v-bind="$attrs"
  >
    <loading-loader v-if="loading" :is-loading="loading" :size="7" :delay="0" />
    <slot v-else />
  </button>
</template>

<script>
import Vue from 'vue'
import LoadingLoader from '@loaders/list/LoadingLoader.vue'

export default Vue.extend({
  name: 'VHeaderButton',
  components: { LoadingLoader },
  props: {
    loading: Boolean,
    disabled: Boolean,
    active: Boolean,
  },
})
</script>
<style lang="scss" module>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background: transparent;
  color: $main-text-color;

  &.disabled {
    opacity: 0.3;
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
</style>
