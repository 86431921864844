import { Mutations } from './types'

const mutations: Mutations = {
  setPageBack(state, back) {
    state.back = { ...state.back, ...back }
  },
  setPageTitle(state, title) {
    if (!title.type) {
      title.type = ''
    }
    state.title = { ...state.title, ...title }
  },
  setPageIsHeader(state, isVisible) {
    state.isHeader = isVisible
  },
  setLastRouteFromFooter(state, route) {
    state.lastRouteFromFooter = route
  },
  setPageIsFooter(state, isVisible) {
    state.isFooter = isVisible
  },
  setPageIsMenu(state, isVisible) {
    state.isMenu = isVisible
  },
  setPageIsOffline(state, isOffline) {
    state.isOffline = isOffline
  },
  setPageLoading(state, loading) {
    state.loading = loading
  },
  setPageIsScroll(state, isScroll) {
    state.isScroll = isScroll
  },
  setIsFirstLaunch(state, isFirstLaunch) {
    state.isFirstLaunch = isFirstLaunch
  },
  setIsDesktop(state, isDesktop) {
    state.isDesktop = isDesktop
  },
}

export default mutations
