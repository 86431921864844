/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'browser-link': {
    width: 14,
    height: 12,
    viewBox: '0 0 14 12',
    data: '<path pid="0" d="M11.5 0h-9A2.502 2.502 0 000 2.5v7C0 10.879 1.121 12 2.5 12h9c1.379 0 2.5-1.121 2.5-2.5v-7C14 1.121 12.879 0 11.5 0zm-9 1h9c.827 0 1.5.673 1.5 1.5V4H1V2.5C1 1.673 1.673 1 2.5 1zm9 10h-9C1.673 11 1 10.327 1 9.5V5h12v4.5c0 .827-.673 1.5-1.5 1.5zM2 2.5a.5.5 0 01.5-.5h2a.5.5 0 110 1h-2a.5.5 0 01-.5-.5z"/>'
  }
})
