// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MaskInput_input_2niON{width:100%;padding:11px;font-weight:400;font-size:15px;line-height:20px;word-wrap:break-word;border:1px solid #e5e3e3;border-radius:6px;outline:none;transition:border .3s}.MaskInput_input_2niON::-moz-placeholder{color:rgba(0,0,0,.5);font-size:16px}.MaskInput_input_2niON::placeholder{color:rgba(0,0,0,.5);font-size:16px}.MaskInput_inputError_3F231{border:1px solid #fb4e4e}.MaskInput_input_2niON.MaskInput_inner-title_uiDSH{background:transparent;border:0}.MaskInput_inner-title_uiDSH,.MaskInput_input_2niON.MaskInput_shadow_1DHeT{background:#f8f8f8}.MaskInput_inner-title_uiDSH{display:flex;align-items:center;padding:8px 15px;border:1px solid #e5e3e3}.MaskInput_inner-title_uiDSH .MaskInput_inner-label_np1tr{color:rgba(0,0,0,.5);font-size:14px;white-space:nowrap}", ""]);
// Exports
exports.locals = {
	"input": "MaskInput_input_2niON",
	"inputError": "MaskInput_inputError_3F231",
	"inner-title": "MaskInput_inner-title_uiDSH",
	"shadow": "MaskInput_shadow_1DHeT",
	"inner-label": "MaskInput_inner-label_np1tr"
};
module.exports = exports;
