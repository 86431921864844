<template>
  <div :class="$style.form" color="primary" @submit="onSubmit">
    <auth-input
      placeholder="..."
      @input="error = ''"
      ref="password"
      v-model="formData.new"
      :class="$style.input"
      type="password"
      :title="$t('login.newPassword')"
      mode="inner-title"
      isNewPassword
      :rules="formData.password"
      :forseError="error"
    />
    <auth-input
      placeholder="..."
      @input="error = ''"
      ref="password"
      v-model="formData.repeat"
      :class="$style.input"
      type="password"
      :title="$t('login.repeatPassword')"
      mode="inner-title"
      isNewPassword
      :rules="formData.password"
      :forseError="error"
    />
    <div :class="$style.buttonContainer">
      <v-button
        size="xl"
        color="primary"
        :is-disabled="!isValid"
        :is-loading="isLoading"
        :class="$style.button"
        @click="onSubmit"
      >
        {{ $t('proceed') }}
      </v-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

import { mapGetters, mapMutations } from 'vuex'
import { vPassword, vRequired } from '@utils/validations'
import validate from '@utils/validate'
import VButton from '@elements/VButton.vue'
import AuthInput from '@components/elements/inputs/list/AuthInput.vue'

export default Vue.extend({
  name: 'NewPasswordForm',
  components: {
    VButton,
    AuthInput,
  },
  props: {
    isLoading: Boolean,
  },
  data() {
    return {
      formData: {
        new: '',
        repeat: '',
      },
      error: '',
    }
  },
  computed: {
    rules() {
      return {
        password: [vRequired(), vPassword()],
      }
    },

    isValid() {
      return !!this.formData.new || !!this.formData.repeat
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    closeAuthDrawer() {
      this.setDrawer({ name: 'login', isVisible: false })
    },
    async onSubmit() {
      this.error =
        (await validate(this.rules.password, this.formData.new)) ||
        (await validate(this.rules.password, this.formData.repeat))

      if (this.formData.new !== this.formData.repeat) {
        this.error = this.$t('errors.login.passwordsNotMatch')
        return
      }
      if (!this.error) {
        this.$emit('submit', this.formData.new)
      }
    },
  },
})
</script>

<style lang="scss" module>
.form {
  grid-auto-rows: min-content;
  grid-auto-flow: row;
}

.input {
  margin: 0;
  padding: 0;
}

.buttonContainer {
  padding: 15px;
}

.button {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 9px;
}
</style>
