import { Mutations } from './types'

const mutations: Mutations = {
  setVerification(state, verification) {
    state.verification = verification
  },
  setVerificationData(state, verificationData) {
    state.verificationData = verificationData
  },

  setVerificationId(state, verificationId) {
    state.verificationId = verificationId
  },
  setMode(state, mode) {
    state.mode = mode
  },
  setCode(state, code) {
    state.code = code
  },
  setStep(state, step) {
    state.step = step
  },
  resetVerification(state) {
    state.verification = null
  },
}

export default mutations
