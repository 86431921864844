import { Mutations } from './types'

const mutations: Mutations = {
  setClient(state, client) {
    if (state.client) {
      state.client = { ...state.client, ...client }
    } else {
      state.client = client
    }
  },
  setClientVerificationId(state, id) {
    if (state.client) {
      state.client.verification_id = id
    }
  },
  setClientLoginToken(state, token) {
    state.loginToken = token
  },
  setClientBot(state, clientBot) {
    state.clientBot = clientBot
  },
  setHasSubscriptions(state, hasSubscriptions) {
    state.hasSubscriptions = hasSubscriptions
  },
  setInitPromotionalId(state, id) {
    state.initPromotionalId = id
  },
  setGPTVersion(state, version) {
    state.gptVersion = version
  },
}

export default mutations
