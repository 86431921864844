/* eslint-disable no-template-curly-in-string */
import informs from '@translations/ru/informs'
import desktopPageLabels from './desktop-page-labels'
import countries from '../countries/ru'
import claims from './claims'

export default {
  hotPrice: 'Выгодно',
  renewSubscription: 'Возобновить',
  subscriptionExpiredText:
    'К сожалению, срок действия вашей подписки на контент истек. Пожалуйста, продлите подписку, чтобы восстановить доступ к контенту. Для этого вам необходимо оплатить новый период в разделе "Подписки" бокового меню.',
  renewFor: 'Продлить за',
  noVideo: 'Нет видео',
  noPhoto: 'Нет фото',
  recordingTip: 'Смахните для отмены',
  voiceMessage: 'Голосовое сообщение',
  mediaDevicesNotSupported: {
    label: 'Медиа устройства не поддерживаются',
    desc: 'Ваш браузер не поддерживает медиа устройства',
  },
  mediaDevicesPermissionNotification: {
    label: 'Разрешение на использование медиа устройств',
    desc: 'Пожалуйста, разрешите доступ к медиа устройствам, чтобы использовать голосовые сообщения',
  },
  mediaDevicesErrorLabels: {
    default: 'Ошибка',
    notAllowed: 'Ошибка доступа к медиа устройствам',
  },
  mediaDevicesErrorMessages: {
    denied: 'Доступ к медиа устройствам запрещен',
    dismissed: 'Доступ к медиа устройствам отклонен',
    default: 'Произошла ошибка',
  },
  support: 'Поддержка',
  locale: {
    ru: 'Русский',
    en: 'English',
  },
  gpt: {
    label: 'GPT',
    model: 'Модель: {version}',
    swearing:
      'Извините, но ваше сообщение содержит недопустимые слова. Мы стремимся поддерживать уважительное и положительное общение в нашем приложении. Пожалуйста, переформулируйте ваше сообщение.',
    requestError:
      'Извините, возникла проблема с отправкой вашего запроса. Пожалуйста, попробуйте отправить сообщение повторно',
    repeatRequest: 'Повторить запрос',
    subscribe: 'Оформить подписку',
    getUnlimited: 'Получить безлимит',
    limit3:
      'У вас закончились пробные попытки для отправки сообщений. Станьте пользователем платформы, чтобы продолжить без ограничений',
    limit4: 'Безлимитный ChatGPT-4, доступен только, пользователям платформы!',
    open: 'Открыть',
    waitingMinuteMore: 'Еще пару мгновений',
    waitingMinute: 'Подождите минутку',
    waiting: 'Минутку, готовлю ответ',
    somethingWrong: 'Что-то пошло не так, попробуйте еще раз',
    newChat: 'Новый GPT чат',
    access1: 'Неограниченное количество запросов',
    access2: 'Самые продвинутые модели ИИ в одном чате',
    access3: 'GPT 3.5 / GPT 4 / Midjourney',
    access4: 'Без VPN  и иностранных карт',
    welcome: {
      message:
        'Добро пожаловать в Supster GPT UI! Теперь вы сможете создать свой аналог ChatGPT за пару минут!',
      presentation1: {
        name: 'Примеры',
        item1: 'Объясните квантовые вычисления простыми словами',
        item2: 'Есть какие-нибудь креативные идеи для дня рождения 10-летнего ребенка?',
      },
      presentation2: {
        name: 'Возможности',
        item1: 'Создает картинки по вашему запросу',
        item1SubText:
          'Введите команду “/imagine” и после, что хотите нарисовать на английском языке',
        item2: 'Помнит сказанное ранее пользователем',
        item3: 'Преодолевает ограничения ChatGPT',
      },
      presentation3: {
        name: 'Скоро будет',
        item1: 'АИ агенты в общем чате',
        item2: 'Обучен и использует поиск Google',
        item3: 'Создание АИ арта',
        item4: 'Голосовой ввод и ответ',
      },
    },
    welcome2: {
      message: 'Интегрированный Chat GPT!',
      message2: 'Попробуй всего за рубль. И, кстати, \r\nЕСЛИ НЕ ПОНРАВИТСЯ, МЫ ВЕРНЕМ ДЕНЬГИ.',
      presentation1: {
        name: 'Узнай сейчас',
        item1: 'КАК ЗАРАБОТАТЬ ДЕНЬГИ В 2023 ГОДУ?',
        item2: 'КТО БУДЕТ ПРЕЗИДЕНТОМ В 2024?',
        item3: 'КАКАЯ ВЕРОЯТНОСТЬ ЯДЕРНОЙ ВОЙНЫ?',
        item4: 'КОГДА ИИ ЗАХВАТИТ МИР?',
      },
    },
    introduction: {
      headerText: 'Интегрированный ChatGPT 3.5/4',
      message1: 'Предложи идею для моего аккаунта Supster: @username',
      message2:
        'Конечно ваш аккаунт выглядит как профиль блогера. Вот идея для истории вашего аккаунта в Supster:',
      page1: {
        title: 'Получите мгновенные ответы и советы',
        text: 'Чат с искусственным интеллектом – это инструмент для создания увлекательного контента и ответов на вопросы.',
      },
      page2: {
        title: 'Будьте в курсе с оповещениями',
        text: 'Разрешите отправлять вам уведомления, чтобы вы ничего не пропустили',
      },
      page3: {
        title: 'GPT – ваш личный художник',
        text: 'Попросите ИИ создать оригинальное изображение на основе вашего описания и желаемого стиля, и ИИ нарисует его для вас в режиме реального времени.',
      },
      page4: {
        title: 'GPT – ваш личный гений',
        text: 'Всё в одном приложении ИИ, которое помогает создавать захватывающие посты, расширять свои знания и редактировать текст с легкостью',
      },
    },
  },
  client: {
    accessDenied: 'К сожалению пользователь ограничил возможность этого действия',
  },
  uploadMenuDrawer: {
    stories: 'Добавить историю',
    post: 'Добавить публикацию',
    chatGroup: 'Новый групповой чат',
    chatPersonal: 'Новый личный чат',
    chatChannel: 'Новый канал',
    chatGPT: 'Новый GPT чат',
  },
  supportChat: 'Чат поддержки',
  chat: {
    support: 'Чат поддержки Supster',
  },
  chatName: 'Название чата',
  channelName: 'Название канала',
  leave: 'Выйти',
  followersDeclination: '0 участников | {n} участник | {n} участника | {n} участников',
  linkCopied: 'Ссылка скопирована',
  link: 'Ссылка',
  profileDescPlaceholder:
    'Введите текст о вас. 2 строки будут видны сразу, а остальные будут скрыты под кнопкой «Далее».',
  multiImages: '3 отдельных изображения',
  singleImage: 'Одно изображение во всю ширину',
  editProfile: 'Редактировать профиль',
  access: 'Доступ',
  close: 'Закрыть',
  promoPage: 'Акционная страница',
  all: 'Все',
  vk: 'ВКонтакте',
  ok: 'Одноклассники',
  inProcess: 'В процессе',
  completed: 'Завершенный',
  send: 'Отправить',
  address: 'Адрес',
  name: 'Имя',
  surname: 'Фамилия',
  patronymic: 'Отчество',
  title: 'Название',
  description: 'Описание',
  details: 'Подробности',
  followers: 'Подписчики',
  follow: 'Подписки',
  donate: 'Задонатить {currency}{amount}',
  followAction: {
    follow: 'Подписаться',
    unfollow: 'Отписаться',
  },
  claimAcceptedDrawer: {
    accepted: 'Жалоба принята',
    desc: 'Спасибо, что дали нам знать',
  },
  blockingDrawer: {
    blocking: {
      content: 'Блокировка контента',
      desc: 'Контент этого пользователя будет заблокирован и не будет отображаться в вашей ленте. Продолжить?',
    },
    unblocking: {
      content: 'Разблокировка контента',
      desc: 'Контент этого пользователя будет разблокирован и будет отображаться в вашей ленте. Продолжить?',
    },
  },
  continue: 'Продолжить',
  continueChanges: 'Продолжить изменения',
  hiddenPublication: 'Скрытая публикация',
  replyTo: 'Ответить {0}',
  newVersionAvailable: 'Доступна новая версия',
  tariffs: 'Тарифы',
  posts: 'Посты',
  postsCount: 'Посты: {0}',
  more: {
    open: 'Свернуть',
    close: 'Далее',
  },
  next: 'Далее',
  getAccess: 'Получить доступ',
  photos: 'Фото',
  videos: 'Видео',
  photo: 'Фото',
  video: 'Видео',
  media: 'Медиа',
  gender: 'Пол',
  subscribe: 'Подписывайтесь',
  giveTryIt: 'Попробуйте всего за ${value}',
  share: 'Поделиться',
  subscribersOnly: 'Только для подписчиков',
  readOn: {
    open: 'Свернуть',
    close: 'Читать дальше',
  },
  actions: 'Действия',
  copyLink: 'Копировать ссылку',
  writeComment: 'Текст комментария',
  reply: 'Ответить',
  replyingTo: 'Ответ на',
  replies: '0 Ответов | {n} Ответ | {n} Ответа | {n} Ответов',
  closeReplies: 'Закрыть ответы',
  show: 'Показать',
  hide: 'Скрыть',
  anonymous: 'Аноним',
  cancel: 'Отмена',
  toCancel: 'Отменить',
  sendTip: 'Отправить чаевые',
  addCaption: 'Добавить подпись...',
  tagPeople: 'Отметить людей',
  hideSubscription: 'Скрыть под подпиской',
  enableComments: 'Включить комментарии',
  postOnSocialNetworks: 'Публикация в социальных сетях',
  postTariffs: 'Тарифы для данного поста',
  yourMessage: 'Ваше сообщение',
  yourName: 'Ваше имя',
  uploadImage: 'Загрузить изображение',
  underConstruction: 'Находится в разработке',
  availableSoon: 'Уже совсем скоро функционал будет доступен',
  shopOpenSoon: 'Магазин скоро откроется',
  mySubscriptions: 'Мои подписки',
  startedOn: 'Началась',
  payment: 'Оплата',
  phone: 'Телефон',
  changePhoneNumber: 'Изменить номер телефона',
  resend: 'Отправить повторно',
  afterTime: 'Через {0}',
  code: 'Код',
  lastPayment: 'Последний платеж',
  nextPayment: 'Следующий платеж',
  pay: 'Оплатить',
  expiration: 'Срок действия',
  securityCode: 'Код безопасности',
  withdrawal: 'Вывод средств',
  history: 'История',
  transferFee: 'Комиссия за перевод: {0}',
  fundsToBankingCard: 'Средства на банковскую карту',
  cardNumber: 'Номер карты',
  minMaxNumbers: '{0} цифр',
  characters: '0 символов | {n} символ | {n} символа | {n} символов',
  subscribers: '0 подписчиков | {n} подписчик | {n} подписчика | {n} подписчиков',
  subscribersTariffs: 'Подписчики',
  days: 'Дней',
  dynamicDays: '0 Дней | {n} День | {n} Дня | {n} Дней',
  nameAndSecondNameOnCard: 'Имя и Фамилия на карточке',
  proceed: 'Продолжить',
  getStarted: 'Приступить',
  withProtectedConnection: 'с защищенным соединением',
  dateBirth: 'Дата рождения',
  MMYY: 'ММ | ГГ',
  amount: 'Cумма',
  currency: 'Валюта',
  confirmPayment: 'Подтвердите оплату',
  subscribed: 'Подписан',
  shop: 'Магазин',
  choose: 'Выбрать',
  currentBalance: 'Текущий баланс',
  chooseSubscription: 'Выберите подписку',
  whatYouGetSubscription: 'Что вы получаете с подпиской',
  unlockTheFullPotentialOfAI: 'Раскройте Весь Потенциал Нейросетей',
  bankingCards: 'Банковские карты',
  accessToAll: 'Доступ ко всем <b>фото / видео</b> кроме платных.',
  accessToAllMeditation: 'Доступ ко всем медитациям',
  accessToAuthors: 'Доступ к <b>личным каналам</b> автора.',
  exclusiveContent: 'Эксклюзивный контент.',
  termsConditions: 'Условия и положения',
  preferences: 'Предпочтения',
  other: 'Остальные',
  userId: 'ID пользователя',
  confirmation: 'Подтверждение',
  videoProcessing: 'Видео обрабатывается',
  tags: 'Теги',
  top: 'Лучшее',
  recent: 'Недавний',
  browseSeveral: 'Просматривайте несколько новых сообщений каждую неделю!',
  seller: 'Продавец',
  delivery: 'Доставка',
  buyersPhotos: 'Фотографии покупателей',
  addToCart: 'Добавить в корзину',
  track: 'Трек №',
  item: '0 товаров | {n} товар | {n} товара | {n} товаров',
  sorting: 'Сортировать по',
  newestFirst: 'новизне',
  verification: 'Верификация',
  documentType: 'Тип документа',
  offerAgreement: 'Пользовательское соглашение',
  licenseAgreement: 'Лицензионное соглашение',
  privacyPolicy: 'Политика конфиденциальности',
  termsService: 'Договор для продавцов товаров',
  country: 'Страна',
  receiver: 'Получатель',
  method: 'Способ',
  offerForThePurchase: 'Оферта купли-продажи',
  toLeaveCommentNeedEnterName: 'Чтобы продолжить, вы должны ввести имя',
  editYourComment: 'Редактирование комментария',
  edit: 'Редактировать',
  editing: 'Редактирование',
  pinnedMessage: 'Прикреплённое сообщение #{0}',
  pin: 'Закрепить',
  unpin: 'Открепить',
  delete: 'Удалить',
  commentText: 'Текст комментария',
  saveChanges: 'Сохранить изменения',
  agreement: 'Продолжая, Вы соглашаетесь с {privacyPolicy} и {rulesPlatform}',
  withPrivacyPolicy: 'политикой безопасности',
  withRulesPlatform: 'правилами сервиса',
  blockUser: 'Заблокировать пользователя',
  userIsBlocked: 'Пользователь заблокирован',
  appNotFound: 'Приложение не найдено',
  deletedMessage: 'Сообщение удалено',
  block: {
    app: {
      post: 'Заблокировать пост',
      user: 'Заблокировать пользователя',
      profile: 'Приложение заблокировано',
    },
    comment: 'Заблокировать комментарий',
    unblock: 'Разблокировать',
  },
  myChannels: 'Мои каналы',
  privateChats: 'Приватные чаты',
  report: {
    user: 'Подать жалобу на пользователя',
    post: 'Подать жалобу на пост',
    text: 'Сообщить о нарушении',
    title: 'Жалоба отправлена',
    description: 'Спасибо что сообщили нам об этом',
    comment: 'Подать жалобу на комментарий',
    reason: 'Причина жалобы',
    confirmReason: 'Подтвердите причину',
  },
  post: {
    deleting: 'Удаление поста',
    deleteError: 'Ошибка удаления поста',
    confirmTitle: 'Удалить публикацию?',
    edit: 'Редактировать',
    delete: 'Удалить',
    modalNameDescription: 'Чтобы оставлять коментарии необходимо ввести имя',
  },
  no: {
    data: 'Нет данных',
    chat: 'Чат не существует',
    messages: 'Сообщений пока нет',
    orders: 'Список заказов пуст',
    products: 'Список продуктов пуст',
    mediaContent: 'Нет медиа-контента',
    countries: 'Список стран пуст',
  },
  createOrder: {
    yourOrder: 'Ваш Заказ',
    courier: 'Курьер',
    pickUp: 'Самовывоз',
    deliveryService: 'Служба доставки',
    wrapping: {
      title: 'Упаковка',
      eco: {
        title: 'Эко Упаковка',
        description:
          'Если вы предпочитаете более минимальный, сдержанный подход, выберите "Эко-упаковку" и получите свою покупку, все еще тщательно упакованную, в коричневой внешней коробке.',
      },
      signature: {
        title: 'Коробка для подписи',
        description:
          'Получите свою покупку, тщательно упакованную, в одной из наших фирменных фирменных коробок. Идеально подходит, если вы хотите побаловать себя или если ваш заказ - подарок кому-то особенному.',
      },
      gift: {
        title: 'Подарочная упаковка',
        description:
          'Упаковка для подарочной упаковки идеально подходит для случаев, когда ваш заказ является подарком кому-то особенному.',
      },
    },
    comment: 'Комментарий к заказу',
    activate: 'Активировать',
    promo: 'Промокод',
    delivery: 'Выберите Способ Доставки',
    phone: 'Телефон',
    total: 'Итого',
    proceed: '<b>Продолжить</b> с защищенным соединением',
    agreement: 'Продолжая, я соглашаюсь с {agreement}',
    userAgreement: 'Условиями обработки перс. данных',
    oferta: 'Условия соглашения',
  },
  cart: {
    title: 'Корзина',
    empty: 'Корзина пуста',
    view: 'Посмотреть корзину',
  },
  sortByDates: {
    0: 'Сегодня',
    1: 'Вчера',
    2: 'Последние 7 дней',
    3: 'Последние 30 дней',
    4: 'Текущий месяц',
    5: 'Прошлый месяц',
    6: 'Этот год',
    7: 'Все время',
  },
  files: {
    add: 'Добавить файл',
    noUploaded: 'Файл не загружен',
    format: 'Формат файла',
    document: 'PDF, JPG, PNG не больше {0}.',
  },
  documents: {
    passport: 'Паспорт',
    idCard: 'ID карточка',
    driversLicense: 'Водительское удостоверение',
  },
  genders: {
    male: 'Мужской',
    female: 'Женский',
  },
  verifications: {
    inProgress: {
      title: 'Прогресс верификации',
      topText: 'После успешной проверки данных вы сможете принимать онлайн платежи',
      bottomText: 'Ваша личная информация надежно защищена и зашифрована.',
      wait: 'Ожидайте',
      complete: 'Успешно завершено',
      inProcess: 'В процессе',
      rejected: 'Не пройдена',
      reason: 'Причина:',
      comment: 'Комментарий:',
      repeatModerate: 'Пройти модерацию заново',
      step1: {
        title: 'Заполнение данных для верификации',
      },
      step2: {
        title: 'Проверка данных',
      },
      step3: {
        title: 'Верификация',
      },
    },
    title: 'Ваш профиль не верифицирован',
    text: 'Верифицируйте профиль, чтобы открыть возможность принимать платежи от своих клиентов внутри приложения.',
    description:
      'После успешной верифицикации все созданные Вами модели подписок будут активны, а Ваши пользователи смогут оплатить доступ к контенту.',
    link: 'Перейти к верификации',
    notify: 'Ваша личная информация <br> надежно защищена и зашифрована.',
    onVerification: {
      title: 'В данный момент данные проходят верификацию',
      description: 'Это может занять некоторое время',
    },
    'step-1': {
      header: 'Данные физлица',
      title: 'Подтвердите свою личность',
      text: 'В соответствии с законом вам нужно подтвердить свою личность перед тем, как получить возможность принимать платежи.',
    },
    'step-2': {
      header: 'Код подтверждения',
      title: 'Загрузите скан или фото разворота документа с фотографией',
      text: 'Изображение должно быть цветным, сделанным с оригинала документа, четким и необрезанным.',
    },
    'step-3': {
      header: 'Вы и документ',
      title: 'Загрузите фото себя, держащего этот документ в руках',
      text: 'Изображение должно быть цветным, четким и необрезанным, документ должен быть хорошо читаем.',
    },
    'step-4': {
      header: 'Код подтверждения',
      title: 'Напишите на листке цифры, указанные ниже, и сделайте селфи',
      text: 'Изображение должно быть цветным, четким и необрезанным, цифры на листке хорошо различимы.',
    },
    'step-5': {
      header: 'Завершение',
      title: 'Все готово к отправке',
      text: 'Отправляя данные на проверку, вы подтверждаете, что ознакомились и согласны с Договором для продавцов товаров.',
    },
  },
  table: {
    date: 'Дата, время',
    sum: 'Сумма',
    status: 'Статус',
  },
  orders: {
    title: 'Заказы',
    order: 'Заказ {0}',
    details: 'Детали заказа',
    empty: 'Список заказов пуст',
    checkout: 'Оформление заказа',
    archived: 'В архиве',
    formed: 'Cформирован',
    changed: 'Изменен',
    type: {
      1: 'Разовая покупка',
      2: 'Подписка',
    },
    delivery: {
      1: 'Обрабатывается',
      2: 'Готов к отправке',
      4: 'Отправлен',
      5: 'Доставлен',
      types: {
        1: 'Доставка курьером',
        2: 'Самовывоз из отделения',
        3: 'Служба доставки',
      },
    },
    typeOrder: {
      1: 'Физический',
      2: 'Цифровой',
    },
    paid: {
      1: 'Не оплачен',
      2: 'Оплачен',
      3: 'В обработке',
    },
  },
  select: {
    value: 'Выберите значение',
    payment: 'Выберите способ оплаты',
    tariffs: 'Выберите тарифы',
    network: 'Выберите социальную сеть',
    images:
      'Выбрано 0 изображений | Выбрано {n} изображение | Выбрано {n} изображения | Выбрано {n} изображений',
  },
  chats: {
    general: 'Новый общий чат',
    createGeneral: 'Создать новый общий чат',
    personal: 'Новый личный чат',
    createPersonal: 'Создать новый личный чат',
    createGroup: 'Создать новый групповой чат',
    communicate: 'Чат для общения с автором.',
    with: 'Чат с пользователем {0}',
    edit: 'Редактировать',
    done: 'Готово',
  },
  channels: {
    channel: 'Новый канал',
    create: 'Создать новый канал',
    empty: 'Канал пуст',
    channels: 'Каналы',
    join: 'Присоединиться к каналу',
    private: 'Данный канал является приватным',
    privateMessage:
      'Чтобы увидеть сообщения и присоединиться к каналу - вам необходимо приобрести подписку по предложенным тарифам.',
  },
  settings: {
    security: {
      title: 'Безопасность',
      description: 'Изменить пароль, контроль сеансов',
    },
    account: {
      title: 'Информация об аккаунте',
      description: 'Имя пользователя, электронная почта, номер телефона',
    },
    privacyAndSafety: {
      title: 'Конфиденциальность и безопасность',
      description: 'Статус активности, Показать предложения',
    },
    notifications: {
      title: 'Уведомления',
      description: 'SMS, push-уведомления и т.д.',
      toggle: {
        push: 'Всплывающие уведомления',
        email: 'Уведомления по электронной почте',
        site: 'Уведомления на сайте',
        toast: 'Уведомления о тостах',
        sms: 'SMS-уведомленияs',
      },
    },
  },
  email: 'Email',
  enter: {
    description: 'Введите описание',
    name: 'Введите имя',
    surname: 'Введите фамилию',
    patronymic: 'Введите отчетсво',
    title: 'Введите название',
    securityCode: 'Введите код безопасности',
    amount: 'Введите сумму',
    userId: 'Введите ID пользователя',
    address: 'Введите адрес',
    numberOfDays: 'Введите количество дней',
    email: 'Введите email',
  },
  subscription: {
    freeTrial: 'Пробный период за %{amount}₽',
    freeTrialGpt: 'Пробный доступ',
    freeTrialGptFor: 'Пробный доступ на',
    daysFree: ' %{days} дня бесплатно',
    trial: '%{amount}₽ на %{period}',
    tearlyAccess: 'Прямой доступ',
    active: 'Активно',
    cancelled: 'Отменено',
    rejected: 'Отклонено',
    trialPeriods: {
      1: '1 день',
      2: '1 неделю',
      3: '1 месяц',
      4: '3 Месяца',
      5: '6 Месяцев',
      6: '1 год',
      7: '3 дня',
    },
    autoWithdrawal: 'Далее автосписание',
    periods: {
      1: 'в день',
      2: 'в неделю',
      3: 'в месяц',
      4: 'в 3 Месяца',
      5: 'в 6 Месяцев',
      6: 'в год',
      7: 'в 3 дня',
    },
    for: 'за',
    1: 'На День',
    2: 'На Неделю',
    3: 'На Месяц',
    4: 'На 3 Месяца',
    5: 'На 6 Месяцев',
    6: 'На Год',
    7: '3 дня',
  },
  subscriptionCancellation: {
    dontRush: 'Не торопитесь с решением!',
    areYouSure: 'Внимание! Вы собираетесь отменить подписку?',
    description1:
      'Мы заметили, что вы собираетесь отменить подписку. Но прежде чем принять окончательное решение, мы хотим предложить вам уникальную возможность:',
    announcement:
      'В качестве благодарности за то, что вы были с нами, мы предлагаем вам 7 дней {limit} нашего сервиса.',
    announcement2:
      'В этом случае 7 дней {limit} который мы предложили вам ранее, будет анулирован. Оставляем все, как есть?',
    announcementLimit: 'бесплатного использования',
    description2:
      'В течение 7 дней вы сможете в полной мере оценить все преимущества нашего продукта и, возможно, изменить свое мнение.',
    addition:
      'Будьте уверены, нет никаких скрытых условий - просто возможность еще раз оценить нас.',
    questions: {
      reason: 'Что вас привело к решению отменить подписку?',
      favorites: 'Что вам нравилось и не нравилось в нашем сервисе?',
    },
    cancelling: 'Ваша подписка будет отменена :(',
    goToEmail: 'Перейдите по ссылке в почте для подтверждения.',
    placeholder: 'Введите ваш ответ...',
  },
  notifications: {
    disable: 'Отключить уведомления',
    enable: 'Включить уведомления',
  },
  questions: {
    removeChannel: 'Вы действительно хотите удалить канал?',
  },
  confirm: {
    yes: 'Да',
    no: 'Нет',
    ok: 'Далее',
    accept: 'Принять',
    cancel: 'Отмена',
    discard: 'Сбросить',
    verify: 'Подтвердить',
    save: 'Сохранить',
    confirm: 'Подтвердить',
    delete: {
      stories: 'Удалить историю?',
    },
  },
  avatar: 'Аватар',
  pages: {
    comments: 'Комментарии',
    newPost: 'Новый пост',
    editPost: 'Редактировать пост',
  },
  install: {
    title: 'Установить',
    text: 'Нажмите “Установить”, чтобы увидеть все изображения!',
    safari: {
      title: 'Установите приложение, чтобы увидеть все изображения!',
      text: 'Установите это веб-приложение на свой iPhone: нажмите {icon} а затем «Добавить на рабочий стол».',
    },
    skip: 'Открыть в браузере',
    pleaseWait: 'Ожидайте',
    wait: 'Подождите, мы устанавливаем приложение...',
  },
  navigation: {
    feed: 'Лента',
    search: 'Поиск',
    chats: 'Чаты',
    profile: 'Профиль',
    profileEdit: 'Редактирование Профиля',
  },
  aside: {
    adminPanel: 'Панель администратора',
    auth: 'Вход / Регистрация',
    users: 'Пользователи',
    myProfile: 'Мой профиль',
    myOrders: 'Мои заказы',
    finances: 'Финансы',
    statistics: 'Статистика',
    subscriptions: 'Подписки',
    referrals: 'Рефералы',
    helpAndSupport: 'Помощь и поддержка',
    publicOffer: 'Публичное предложение',
    buySaleOffer: 'Оферта купли-продажи ',
    safeDeal: 'Условия соглашения',
    settings: 'Настройки',
  },
  success: {
    payments: {
      caption: 'Успешный платеж',
      description: 'Ваш платеж прошел',
    },
    joinChannel: {
      caption: 'Успех',
      description: 'Теперь вы присоединились в этот канал',
    },
    leaveChannel: {
      description: 'Вы покинули канал',
    },
    stories: {
      add: {
        title: 'Успешное добавление Истории',
        text: 'Вы добавили историю',
      },
    },
    profile: {
      edit: {
        title: 'Успешное изменение профиля',
        text: 'Вы изменили данные профиля',
      },
    },
  },
  permission: {
    title: 'Получать уведомления',
    text: 'Получайте уведомления о действиях соседей, опытных пользователях, новых историях, присоединяющихся друзьях и многом другом.',
    btnConfirm: 'Давай сделаем это',
    btnReject: 'Нет, в другой раз',
  },
  login: {
    enter: 'Войти',
    registration: 'Регистрация',
    register: 'Зарегистрироваться',
    entertoYourProfile: 'Войдите в ваш профиль',
    enterToProfile: 'Войти в профиль',
    congrats: 'Поздравляю!',
    congratsDesc: 'Ваш аккаунт зарегистрирован на платформе Supster',
    phoneCaption: 'Введите Ваш номер телефона. На него будет отправлен код для входа',
    emailCaption: 'Введите вашу почту. На нее будет отправлен код для восстановления пароля:',
    emailCaptionCode:
      'На ваш почтовый адрес отправлено сообщение с кодом подтверждения. Пожалуйста, введите его:',
    youPasswordRecovery: 'Ваш пароль восстановлен',
    loginTry: 'Теперь попробуйте еще раз войти в профиль',
    passwordRecovery: 'Востановление пароля',
    password: 'Пароль',
    email: 'Почта',
    newPassword: 'Новый пароль',
    repeatPassword: 'Повтор пароля',
    enterToAccount: 'Вход в аккаунт',
    loginOptions: {
      title: 'Варианты входа в профиль',
      email: 'По E-mail',
      phone: 'По номеру телефона',
    },
    forgotPassword: 'Забыли пароль?',
    afterPayment: 'Чтобы не потерять доступ к заказу,<br> необходимо зарегистрироваться',
  },
  warnings: {
    title: 'Предупреждение',
    message: 'Лучше не делайте так',
    size: 'Превышен лимит в 3000 символов',
  },
  errors: {
    title: 'Ошибка',
    message: 'Произошла неизвестная ошибка',
    login: {
      caption: 'Ошибка входа',
      descriptionLoad: 'Номер не зарегистрирован',
      loginEmail: 'Неверная почта или пароль',
      loginPhone: 'Неверный номер телефона или пароль',
      passwordsNotMatch: 'Пароли не совпадают',
      emailAlreadyInUse: 'Данный Email уже используется ',
      code: 'Неверный код',
    },
    profile: {
      edit: {
        title: 'Ошибка изменения профиля',
        text: 'Данные профиля не изменены',
      },
    },
    promo: {
      description: 'Промокод не найден',
    },
    files: {
      caption: 'Ошибка файлов',
      description: 'Неверный формат файла или привышен лимит в {0}',
      descriptionQuantity: 'Количество файлов должно быть не более {0}',
      descriptionLoad: 'Ошибка загрузки файлов',
      descriptionNotFound: 'Файл не найден',
    },
    countries: {
      caption: 'Ошибка стран',
      descriptionLoad: 'Ошибка загрузки стран',
    },
    posts: {
      caption: 'Ошибка постов',
      descriptionLoad: 'Ошибка загрузки постов',
    },
    products: {
      caption: 'Ошибка продуктов',
      descriptionLoad: 'Ошибка загрузки продуктов',
    },
    chats: {
      caption: 'Ошибка чатов',
      descriptionLoad: 'Ошибка загрузки чатов',
      descriptionCreateDialog: 'Ошибка создания диалога',
      notFounded: 'Чат не найден',
    },
    tags: {
      caption: 'Ошибка тегов',
      descriptionLoad: 'Ошибка загрузки тегов',
    },
    payments: {
      caption: 'Ошибка платежа',
      description: 'Произошла ошибка платежа. Проверте корректность введенных данных',
    },
    orders: {
      caption: 'Ошибка товаров',
      description: 'Произошла ошибка создания товара',
    },
    validations: {
      required: 'Обязательное поле',
      title: 'Название должно состоять только из цифр, букв или символов',
      name: 'Имя должно состоять только из букв, цифр и символов _-.',
      surname: 'Фамилия должна состоять только из букв',
      email: 'Некорректный формат почты. Пример: supster@info.com',
      creditCardName: 'Имя и фамилия должны содержать только латинские символы',
      numbers: 'Только числа',
      letters: 'Только буквы',
      lengthLessThanMin: 'Минимум {characters}',
      lengthGreaterThanMax: 'Максимум {characters}',
      password: {
        minChars: 'Минимум {characters} символов',
        spaces: 'Пароль не должен содержать пробелы',
        cyrillic: 'Пароль не должен содержать кириллицу',
      },
    },
    billing: {
      default: 'Произошла неизвестная ошибка. Проверте корректность введенных данных',
      noLink: 'Отсутствует ссылка для подтверждения платежа',
    },
    clients: {
      notFounded: 'Клиент не найден',
    },
    finances: {
      caption: 'Ошибка статистики платежей',
      description: 'Произошла ошибка при загрузке статистики платежей',
    },
    api: {
      400: {
        title: 'Неверный запрос',
        text: 'Может быть связано с разнообразными проблемами на стороне пользователя, такими как неверные JSON-данные в теле запроса, неправильные параметры действия, и т.д',
      },
      401: {
        title: 'Ошибка аутентификации',
        text: 'Аутентификация завершилась неудачно',
      },
      403: {
        title: 'Доступ не разрешен',
        text: 'Аутентифицированному пользователю не разрешен доступ к указанной точке входа API',
      },
      404: {
        title: 'Не найдено',
        text: 'Запрашиваемый ресурс не найден',
      },
      405: {
        title: 'Метод не поддерживается',
        text: 'Метод не поддерживается. Сверьтесь со списком поддерживаемых HTTP-методов в заголовке Allow',
      },
      500: {
        title: 'Произошла системная ошибка',
        text: 'Пожалуйста, обратитесь в службу поддержки',
      },
    },
    offline: 'No internet connection',
  },
  activation: {
    imageTitle: 'Загрузите свои фотографии',
    imageText:
      'Это лучший способ заявить о себе и завести новые контакты. Также вы можете присоединиться к <b>Supster Network</b> и зарабатывать деньги, продавая цифровой контент, товары и услуги по модели подписки. Для этого вам нужно будет активировать <b>Бизнес-аккаунт</b>.',
    videoTitle: 'Загрузите свои фотографии',
    videoText:
      'Это лучший способ заявить о себе и завести новые контакты. Также вы можете присоединиться к <b>Supster Network</b> и зарабатывать деньги, продавая цифровой контент, товары и услуги по модели подписки. Для этого вам нужно будет активировать <b>Бизнес-аккаунт</b>.',
    shopTitle: 'Создайте ваш собственный магазин',
    shopText:
      'Вы можете создать свой собственный интернет-магазин и зарабатывать деньги, продавая товары. Для этого вам нужно будет активировать <b>Бизнес-аккаунт</b>.',
    chatsTitle: 'Создайте ваш собственный магазин',
    chatsText:
      'Вы можете создать свой собственный интернет-магазин и зарабатывать деньги, продавая товары. Для этого вам нужно будет активировать <b>Бизнес-аккаунт</b>.',
  },
  createApp: {
    drawer: {
      phoneTitle: 'Активация Supster Business',
      phoneText:
        'Чтобы активировать <b>Supster Business</b>, вам необходимо подтвердить свою учетную запись. Пожалуйста, введите свой номер телефона в поле ниже. Позже он будет использован для входа в вашу панель управления.',
      phoneDescription: 'Ваш номер телефона',
      code: {
        title: 'Проверка номера телефона',
        text: 'На ваш номер телефона было отправлено SMS с кодом подтверждения. Пожалуйста, введите его в поле ниже:',
        description: 'Код подтверждения',
        changePhone: 'Изменить номер телефона',
        resendCode: 'Повторно отправить SMS',
      },
      greet: {
        title: 'Поздравляем!',
        text: 'Вам был предоставлен доступ к вашей личной панели управления Supster Business.',
        url: 'Ваш персональный URL:',
        copy: 'Копировать URL',
        admin: 'Перейдите в панель администратора',
      },
    },
  },
  documentsTitle: 'Документы',
  documentNotFound: 'Документ не найден',
  docs: {
    'user-agreement': 'Пользовательское соглашение',
    'credential-storage-agreement': 'Хранение учетных данных',
    privacy: 'Политика конфиденциальности',
    'suppliers-agreement': 'Соглашение с поставщиками',
    'combating-legalization': 'Борьба с легализацией',
    'sellers-contract': 'Контракт продавцов',
    'transactions-storage-agreement': 'Хранение транзакций',
  },
  profile: {
    trial: 'Попробуйте всего за 1',
    subscription: {
      1: '1 День',
      2: '1 Неделю',
      3: '1 Месяц',
      4: '3 Месяца',
      5: '6 Месяцев',
      6: '1 Год',
    },
  },
  promotional: {
    modal: {
      title: {
        posts: 'Фото и Видео',
        channels: 'Каналы',
      },
      button: 'Подпишись чтобы разблокировать',
    },
    plug: {
      trial: 'Бесплатно',
      hotOffer: 'Горячее предложение',
      bestOffer: 'Лучшее предложение',
      save85: 'Выгода 85%',
    },
  },
  modals: {
    adminNoVerified: {
      title: 'В данный момент покупка <br> невозможна :(',
      description:
        'Автор еще не верифицировал профиль и не может получать платежи. Поэтому данный контент временно недоступен для покупки.',
    },
  },
  install_instruction: {
    notify_text: 'Инструкция по установке приложения',
    install: 'Установить',
    inst_title: 'Установка приложения',
    inst_step1_title: '1. Откройте в Safari',
    inst_step1_subtitle: 'Откройте сайт в браузере Safari',
    inst_step2_title: '2. Нажмите на кнопку "Поделиться"',
    inst_step2_subtitle: 'Нажмите на кнопку "Поделиться" внизу браузера',
    inst_step3_title: '3. Выберите действие',
    inst_step3_subtitle: 'Выберите пункт в меню действий "на экран Домой"',
    inst_step4_title: '4. Добавьте иконку',
    inst_step4_subtitle: 'Нажмите "добавить" в правом верхнем углу экрана',
    androind: {
      inst_step1_title: '1. Скопируйте ссылку этой страницы',
      inst_step2_title: '2. Откройте ссылку в браузере Google Chrome',
      inst_step3_title: '3. На открытой странице откройте контекстное меню настроек',
      inst_step4_title: '4. Установите приложение за 1 минуту!',
    },
  },
  shareChatDrawer: {
    title: 'Поделиться ссылкой на чат',
    subTitle:
      'Сообщения, которые вы отправляете после создания ссылки, не будут опубликованы. Любой, у кого есть URL-адрес, сможет просматривать общий чат.',
    chatName: 'Название чата',
    chatCreated: 'Чат создан:',
    buttonText: 'Копировать ссылку',
  },
  ...countries,
  ...claims,
  ...informs,
  ...desktopPageLabels,
}
