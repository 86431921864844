<template>
  <v-drawer name="claim-accepted">
    <div :class="$style.container">
      <div
        :class="$style.background"
        :style="{ maskImage: `url(${require('@assets/images/svg/claim-bg.svg')} )` }"
      />

      <div :class="$style.content">
        <h3 :class="$style.title">{{ $t('claimAcceptedDrawer.accepted') }}</h3>

        <p :class="$style.text">
          {{ $t('claimAcceptedDrawer.desc') }}
        </p>

        <v-button color="black-primary" size="xxl" @click="onClose">
          {{ $t('continue') }}
        </v-button>
      </div>
    </div>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'

export default Vue.extend({
  name: 'ClaimAcceptedDrawer',
  components: { VDrawer, VButton },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    onClose() {
      this.setDrawer({ name: 'claim-accepted', isVisible: false })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 600px;
  background: $primary;
  color: $main-text-color;

  .background {
    position: absolute;
    top: 0;
    align-self: center;
    width: 100%;
    height: 490px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    --webkit-mask-size: contain;
    mask-size: contain;
    background-color: $main-text-color;

    @media screen and (max-height: 850px) {
      background-size: contain;
    }
  }

  .content {
    padding: 15px;

    .title {
      margin-bottom: 21px;
      text-align: center;
    }

    .text {
      max-width: 200px;
      margin: 0 auto 15px;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }
}
</style>
