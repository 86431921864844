<template>
  <ChatMessageReference
    :class="$style.content"
    :message="currentPinnedMessage"
    :alter-title="alterTitle"
    is-pinned
    :is-loading="isLoading"
    @click.native="next"
    @close="unpin"
  />
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { goToPinnedMessage } from '@modules/messages/services'
import ChatMessageReference from './ChatMessageReference.vue'

export default Vue.extend({
  name: 'ChatPinnedMessages',

  components: {
    ChatMessageReference,
  },

  data() {
    return {
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters('Chat', [
      'currentPinnedMessage',
      'pinnedMessagesPointer',
      'pinnedMessages',
      'chatMessages',
      'localId',
    ]),
    alterTitle() {
      return this.$t('pinnedMessage', [this.pinnedMessages.length - this.pinnedMessagesPointer])
    },
  },

  methods: {
    ...mapActions('Chat', ['putChatMessage']),
    ...mapMutations('Chat', [
      'deletePinnedMessage',
      'increasePinnedMessagesPointer',
      'decreasePinnedMessagesPointer',
    ]),
    async next() {
      if (!this.currentPinnedMessage) {
        return
      }
      this.isLoading = true
      await goToPinnedMessage({
        message: this.currentPinnedMessage,
        channel: this.currentPinnedMessage?.channel,
        store: this.$store,
      })
      this.$emit('click', this.currentPinnedMessage)
      this.increasePinnedMessagesPointer()
      this.isLoading = false
    },
    async unpin() {
      this.isLoading = true
      const message = await this.putChatMessage({
        id: this.currentPinnedMessage.id,
        local_id: this.localId,
        pinned_status: 0,
      })
      if (message) {
        this.deletePinnedMessage(this.currentPinnedMessage.id)
        this.decreasePinnedMessagesPointer()
      }
      this.isLoading = false
    },
  },
})
</script>

<style lang="scss" module>
.content {
  grid-template-columns: auto 1fr auto;
  padding-left: 9px;
  cursor: pointer;
}
</style>
