<template>
  <div :class="$style.section">
    <section :class="$style.header">
      <ProfileAvatar :is-editable="isMyProfilePage" @change="isAvatarChange = true" />
      <text-input
        v-if="isSimpleProfile"
        :value="name"
        :rules="rulesName"
        :title="$t('name')"
        :placeholder="$t('enter.name')"
        @error="errorName = $event"
        @blur="onBlurName"
        @input="onInputName"
      />
      <ProfileInfo v-else :is-editable="isMyProfilePage" />
      <VButton
        v-if="notMyCurrentApp && !isMyProfilePage"
        :class="$style.menu"
        color="transparent"
        @click="onMenu"
      >
        <icon name="dots-4" width="3" height="18" />
      </VButton>
    </section>
    <ProfileActivation
      v-if="isSimpleProfile"
      :title="$t(`activation.IMAGES.title`)"
      :text="$t(`activation.IMAGES.text`)"
      :class="$style.activation"
    />
    <PhoneVerification v-if="isSimpleProfile && hasPhoneVerification" />
    <ProfileBlocking v-else-if="isBlocked & !isMyProfilePage" />
    <template v-else-if="!isSplitTestApp6">
      <!-- <ProfileStories v-if="hasStories" /> -->
      <ProfileCover v-if="hasCover" :is-editable="isMyProfilePage" />
      <ProfileSubscribe v-if="hasSubscriptions" />
      <ProfileVerification v-if="hasVerification" />
      <ProfileTabs v-if="hasTabs" :is-editable="isMyProfilePage" />
    </template>
    <VReportMenu v-if="!isSimpleProfile" :is-post-page="false" />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import PhoneVerification from '@drawers/list/PhoneVerification.vue'
import { StateNames } from '@store/app/types'
import VReportMenu from '@layouts/post/list/VReportMenu.vue'
import VButton from '@elements/VButton.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import { vLength, vName } from '@utils/validations'
import ProfileActivation from '@modules/profile/components/ProfileActivation.vue'
import ProfileAvatar from './components/ProfileAvatar.vue'
import ProfileInfo from './components/ProfileInfo.vue'
import ProfileStories from './components/ProfileStories.vue'
import ProfileCover from './components/ProfileCover.vue'
import ProfileVerification from './components/ProfileVerification.vue'
import ProfileTabs from './components/ProfileTabs.vue'
import ProfileSubscribe from './components/ProfileSubscribe.vue'
import ProfileBlocking from './components/ProfileBlocking.vue'

export default Vue.extend({
  name: 'Profile',
  components: {
    ProfileActivation,
    TextInput,
    ProfileTabs,
    ProfileAvatar,
    ProfileBlocking,
    ProfileInfo,
    // ProfileStories,
    ProfileCover,
    ProfileVerification,
    PhoneVerification,
    ProfileSubscribe,
    VReportMenu,
    VButton,
  },
  data() {
    return {
      errorName: '',
      isAvatarChange: false,
      isNameChanged: false,
    }
  },
  computed: {
    ...mapGetters('App', [
      'app',
      'otherApp',
      'currentApp',
      'currentAppName',
      'currentAppId',
      'appId',
      'appName',
      'appClientId',
      'appHeaderImagesLength',
      'appBlockId',
      'appIsBlocked',
      'isMyAppGPT',
      'isSplitTestApp6',
    ]),
    ...mapGetters('Verification', ['verificationIsDraft']),
    ...mapGetters('Client', [
      'clientId',
      'isClientOwner',
      'isClientVerified',
      'clientName',
      'clientAvatar',
    ]),
    ...mapGetters('Drawers', ['drawer']),
    ...mapGetters('Stories', ['storiesIsOpened']),
    ...mapGetters('Profile', ['profileTariffsLength', 'profileClientApp']),
    ...mapGetters('Post', ['postMeta']),
    isDataChangedWithoutErrors() {
      return (this.isAvatarChange || this.isNameChanged) && !this.errorName
    },
    isSimpleProfile() {
      return this.isMyAppGPT || this.isSplitTestApp6
    },
    name: {
      get() {
        return this.profileClientApp?.name ?? ''
      },
      set(value) {
        this.setProfileClientApp({ name: value })
      },
    },
    rulesName() {
      return [vLength(3, 50), vName()]
    },
    routeAppId() {
      return this.$route?.params?.id ?? null
    },
    isProfilePage() {
      return this.$route?.name === 'Profile'
    },
    isMyProfilePage() {
      return this.$route?.name === 'MyProfile'
    },
    isOtherProfilePage() {
      return this.$route?.name === 'OtherProfile'
    },
    isBlocked() {
      return this.appBlockId(this.currentApp) || this.appIsBlocked
    },
    hasCover() {
      return this.appHeaderImagesLength(this.currentApp) || this.isMyProfilePage
    },
    hasStories() {
      return this.currentApp && !this.isMyProfilePage
    },
    hasSubscriptions() {
      return (
        (this.profileTariffsLength && !this.isClientOwner && !this.isMyProfilePage) ||
        (this.profileTariffsLength && this.isOtherProfilePage)
      )
    },
    hasVerification() {
      return (
        this.isProfilePage &&
        this.isClientOwner &&
        (!this.isClientVerified || this.verificationIsDraft)
      )
    },
    hasTabs() {
      return (this.currentApp && !this.isMyProfilePage) || this.isMyProfilePage
    },
    hasPhoneVerification() {
      return this.isMyProfilePage && !this.isClientOwner
    },
    notMyCurrentApp() {
      return !(this.isClientOwner && this.appClientId(this.currentApp) === this.clientId)
    },
  },
  methods: {
    ...mapActions('Client', ['putClient']),
    ...mapActions('App', ['getApp', 'replaceOtherApp']),
    ...mapMutations('App', ['setCurrentAppName']),
    ...mapMutations('Profile', [
      'resetProfileContent',
      'resetProfileTariffs',
      'resetProfileClientApp',
      'setProfileClientApp',
      'setIsClientEditingError',
    ]),
    ...mapActions('Profile', ['getProfileTariffs', 'updateProfile']),
    ...mapActions('Verification', ['getVerifications']),
    ...mapMutations('Stories', ['resetStories']),
    ...mapMutations('Page', [
      'setPageTitle',
      'setPageBack',
      'setPageIsFooter',
      'setPageIsHeader',
      'setLastRouteFromFooter',
    ]),
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Toasts', ['pushToast']),
    ...mapActions('Media', ['postMedia']),
    ...mapMutations('Files', ['resetFiles']),
    defineCurrentAppName() {
      const currentAppName = this.isOtherProfilePage
        ? StateNames.OTHER_APP
        : this.isMyProfilePage && !this.isClientOwner
        ? StateNames.CLIENT_APP
        : StateNames.MY_APP
      if (this.currentAppName !== currentAppName) {
        this.resetProfile()
        this.setCurrentAppName(currentAppName)
      }
    },
    onMenu() {
      this.setDrawer({
        name: 'post-menu',
        isVisible: true,
        params: {
          fromProfile: true,
          app: this.currentApp,
        },
      })
    },
    async init() {
      this.defineCurrentAppName()
      if (this.isOtherProfilePage && this.otherApp?.id !== this.routeAppId) {
        const app = await this.getApp(this.routeAppId)
        this.resetProfile()
        this.replaceOtherApp(app)
      }
      if (this.isBlocked) {
        this.pushToast({
          title: this.$t('inform.profile.title'),
          message: this.$t('inform.profile.text'),
          type: 'error',
        })
      }
    },
    resetProfile() {
      this.resetProfileContent()
      this.resetProfileTariffs()
      this.resetStories()
    },
    onInputName(name) {
      this.name = name
      this.isNameChanged = name && name !== (this.clientName ?? '')
    },
    onBlurName() {
      if (!this.name && this.clientName) {
        this.name = this.clientName
      }
    },
    async updateClient() {
      const isAvatarChanged = this.profileClientApp?.avatar?.data
      const client = {}
      if (this.isNameChanged) {
        client.name = this.name
      }
      if (isAvatarChanged) {
        const media = await this.postMedia(this.profileClientApp?.avatar?.data)
        client.avatar_id = media[0]?.id
      }
      if (this.isNameChanged || isAvatarChanged) {
        await this.putClient({
          id: this.clientId,
          client,
        })
        this.resetFiles()
        this.setProfileClientApp({
          avatar: null,
          name: '',
        })
      }
      this.setLastRouteFromFooter('')
      this.$router.push({ name: 'Chats' })
    },
  },

  created() {
    this.setProfileClientApp({
      name: this.clientName,
      avatar: {
        url: this.clientAvatar('content_url') ?? this.clientAvatar('preview_url'),
      },
    })
    this.setPageTitle({
      name: this.isMyProfilePage ? this.$t('aside.myProfile') : this.appName(this.currentApp),
      value: null,
      type: this.isMyProfilePage ? '' : 'appName',
    })
    this.init()
  },

  async mounted() {
    await this.getVerifications()
    if (this.isClientOwner) {
      this.$bus.$on('back', () => {
        if (this.isClientOwner) {
          this.resetProfileClientApp()
        }
      })
    }
    this.$bus.$on('edit-profile', async () => {
      if (this.isSimpleProfile) {
        this.updateClient()
        return
      }
      if (this.isClientOwner) {
        const result = await this.updateProfile()
        if (result) {
          this.$router.push({ name: 'Profile' })
        }
      } else {
        this.$router.push({ name: 'Profile' })
      }
    })

    this.setPageBack({
      isDisabled: this.isProfilePage,
    })
    this.setPageIsHeader(true)
    this.setPageIsFooter(true)
    if (!this.profileTariffsLength) {
      this.getProfileTariffs({ app_id: this.appId(this.currentApp) })
    }
  },
  watch: {
    isMyProfilePage(value) {
      this.setPageBack({
        isDisabled: !value,
      })
    },
    isBlocked(value) {
      this.setPageBack({
        route: value ? { name: 'Search' } : null,
      })
    },
    currentAppId: {
      handler() {
        this.setPageTitle({
          name: this.isMyProfilePage ? this.$t('aside.myProfile') : this.appName(this.currentApp),
          value: null,
          type: !this.isMyProfilePage && !this.isSimpleProfile ? 'appName' : '',
        })
        this.setPageBack({
          isDisabled: this.isProfilePage,
        })
      },
    },
    '$route.name': function () {
      this.init()
    },
    isDataChangedWithoutErrors(value) {
      this.setIsClientEditingError(!value)
    },
  },
  beforeDestroy() {
    this.$bus.$off('edit-profile')
    this.$bus.$off('back')
    if (this.isSimpleProfile) {
      this.resetProfileClientApp()
    }
    this.setPageBack({
      isDisabled: false,
    })
    this.setIsClientEditingError(true)
  },
  beforeRouteEnter(to, from, next) {
    const { frompayment, path } = to?.query ?? {}
    const fromName = from?.name
    if (frompayment && path && !fromName) {
      next(decodeURI(path))
      return
    }
    next((vm) => {
      if (frompayment && path) {
        if (fromName === 'Promotional') {
          vm.$router.go(0)
        } else {
          vm.$router.replace('profile')
        }
        return
      }
      const {
        frompayment: queryFrompayment,
        'refresh-token': refreshToken,
        section: querySection,
        ...newQuery
      } = vm.$route.query
      if (frompayment) {
        vm.$router.replace({
          ...vm.$route,
          query: newQuery,
        })
      }
    })
  },
})
</script>

<style lang="scss" module>
.section {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .header {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 21px;
    column-gap: 21px;
    background: white;
    @media screen and (max-width: 330px) {
      padding-right: 17px;
      padding-left: 17px;
      column-gap: 15px;
    }

    .menu {
      position: absolute;
      top: 21px;
      right: 0;
      width: 45px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .activation {
    margin: 10px;
  }
}
</style>
