import { Mutations } from './types'

const mutations: Mutations = {
  setPromotionalList(state, promotionalList) {
    state.promotionalList = [...(state.promotionalList || []), ...promotionalList]
    state.page++
  },
  setPromotional(state, promotional) {
    state.promotional = promotional
  },
  setIsDenied(state, isDenied) {
    state.isDenied = isDenied
  },
  setRedirectUrl(state, url) {
    state.redirectUrl = url
  },
  setPromotionalModalVisible(state, visible) {
    state.modal.visible = visible
  },
  setPromotionalMediaType(state, type) {
    state.modal.type = type
  },
  setPromotionalMedia(state, media) {
    state.modal[state.modal.type].data = [...(state.modal[state.modal.type].data || []), ...media]
    state.modal[state.modal.type].page++
  },
  resetPromotionalMedia(state) {
    state.modal.posts.data = null
    state.modal.posts.page = 1
    state.modal.channels.data = null
    state.modal.channels.page = 1
  },
  resetPromotional(state) {
    state.promotionalList = []
    state.promotional = null
    state.isDenied = false
    state.page = 1
  },
}

export default mutations
