// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VStarRating_list_2-6pC{grid-template-columns:repeat(5,min-content);-moz-column-gap:3px;column-gap:3px}.VStarRating_item_2tUJO,.VStarRating_list_2-6pC{display:grid}.VStarRating_item_2tUJO{align-self:center;cursor:pointer}.VStarRating_item_2tUJO.VStarRating_sm_rIOLH{width:10px;height:10px}.VStarRating_item_2tUJO.VStarRating_md_222yE{width:18px;height:18px}.VStarRating_item_2tUJO.VStarRating_lg_2hjBp{width:26px;height:26px}.VStarRating_item_2tUJO.VStarRating_xl_1nkdt{width:34px;height:34px}.VStarRating_icon_3g--g{width:100%;height:100%;transition:stroke .3s,fill .3s}.VStarRating_icon_3g--g,.VStarRating_icon_3g--g.VStarRating_default_1FDOq{stroke:#e5e3e3}.VStarRating_icon_3g--g.VStarRating_active_3MzhP,.VStarRating_icon_3g--g.VStarRating_primary_Hzs_s{stroke:var(--supster-primary)}.VStarRating_icon_3g--g.VStarRating_active_3MzhP{fill:var(--supster-primary)}", ""]);
// Exports
exports.locals = {
	"list": "VStarRating_list_2-6pC",
	"item": "VStarRating_item_2tUJO",
	"sm": "VStarRating_sm_rIOLH",
	"md": "VStarRating_md_222yE",
	"lg": "VStarRating_lg_2hjBp",
	"xl": "VStarRating_xl_1nkdt",
	"icon": "VStarRating_icon_3g--g",
	"default": "VStarRating_default_1FDOq",
	"active": "VStarRating_active_3MzhP",
	"primary": "VStarRating_primary_Hzs_s"
};
module.exports = exports;
