/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock-gradient': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" d="M12 8.05V5c0-2.757-2.243-5-5-5S2 2.243 2 5v3.05c-1.14.233-2 1.243-2 2.45v3C0 14.878 1.122 16 2.5 16h9c1.378 0 2.5-1.122 2.5-2.5v-3c0-1.207-.86-2.217-2-2.45zM8 11.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zM7 1c2.206 0 4 1.794 4 4v3H3V5c0-2.206 1.794-4 4-4z" _fill="url(#paint0_linear_701_93)"/><defs><linearGradient id="paint0_linear_701_93" x1="7" y1="16" x2="7" y2="0" gradientUnits="userSpaceOnUse"><stop offset=".117" stop-color="#DF9630"/><stop offset=".327" stop-color="#E09A30"/><stop offset=".659" stop-color="#EDD031"/><stop offset=".877" stop-color="#EDD031"/></linearGradient></defs>'
  }
})
