// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VFile_container_3y-b5{position:relative}.VFile_input_A1Rxd{position:absolute;z-index:-1;width:.1px;height:.1px;overflow:hidden;opacity:0}", ""]);
// Exports
exports.locals = {
	"container": "VFile_container_3y-b5",
	"input": "VFile_input_A1Rxd"
};
module.exports = exports;
