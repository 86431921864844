import 'lightgallery/css/lightgallery-bundle.css'
import 'video.js/dist/video-js.css'
import lightGallery from 'lightgallery'
import lgVideo from 'lightgallery/plugins/video'
import lgZoom from 'lightgallery/plugins/zoom'
import lgHash from 'lightgallery/plugins/hash'
import videojs from 'video.js'
import { checker } from '@helpers/index'
import { Chat, ChatId, ChatIds, Chats, ChatsObjectMap, ChatType } from '@common-types/chat'

export const isChat = (type?: ChatType | null): boolean =>
  [ChatType.COMMON_CHAT, ChatType.PERSONAL_CHAT].includes(type ?? -1)

export const isChannel = checker(ChatType.CHANNEL)

export const isChatPersonal = checker(ChatType.PERSONAL_CHAT)

export const isSupportChat = checker(ChatType.SUPPORT_CHAT)

export const isChatCommon = checker(ChatType.COMMON_CHAT)

declare global {
  interface Window {
    videojs: any
    myLightGallery: ReturnType<typeof lightGallery>
  }
}

export const startMediaViewer = (): void => {
  const el = document.getElementById('lightgallery')
  const container = document.querySelector('.lightgallery-container') as HTMLElement
  if (!el || !container) {
    return
  }
  window.videojs = videojs

  window.myLightGallery = lightGallery(el, {
    plugins: [lgVideo, lgZoom, lgHash],
    addClass: 'lg-custom-class',
    autoplayVideoOnSlide: true,
    actualSize: false,
    download: true,
    videojs: true,
    videojsOptions: {
      controlBar: {
        pictureInPictureToggle: false,
      },
    },
    flipHorizontal: false,
    flipVertical: false,
    mousewheel: true,
    rotateRight: false,
    selector: '.lightgallery-element',
    container,
  })
}

export const getChatIds = (chats: Chats): ChatIds => chats.map(({ id }) => id)

export const convertChatsArrayToObjectMap = (chats: Chats): ChatsObjectMap => {
  const entries: [ChatId, Chat][] = chats.map((chat) => [chat.id, chat])
  return Object.fromEntries(entries)
}

export default {
  isChat,
  isChannel,
  isChatCommon,
  isChatPersonal,
}
