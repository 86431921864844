// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPostPreviewSubscribe_container_zOlz8{position:relative;padding-bottom:100%;background:var(--supster-primary)}.VPostPreviewSubscribe_container_zOlz8.VPostPreviewSubscribe_convert_36oFj{background:transparent}.VPostPreviewSubscribe_icon-wrap_Tlo1c{display:flex;align-items:center;justify-content:center}.VPostPreviewSubscribe_preview_3klrI{position:absolute;top:0;right:0;left:0;bottom:0}.VPostPreviewSubscribe_content_3yXcv{position:absolute;top:50%;left:50%;display:grid;grid-auto-rows:1fr auto;width:100%;height:100%;row-gap:5px;padding:7px;transform:translate(-50%,-50%)}.VPostPreviewSubscribe_content_3yXcv.VPostPreviewSubscribe_lg_3jIvv{padding:15px}.VPostPreviewSubscribe_icon_34e3F{align-self:center;justify-self:center;width:40px;height:46px;color:var(--supster-main-text-color)}.VPostPreviewSubscribe_button_2EGGK{grid-auto-columns:auto}.VPostPreviewSubscribe_button_2EGGK.VPostPreviewSubscribe_round_1_ukP{border-radius:6px 6px 3px 3px!important}", ""]);
// Exports
exports.locals = {
	"container": "VPostPreviewSubscribe_container_zOlz8",
	"convert": "VPostPreviewSubscribe_convert_36oFj",
	"icon-wrap": "VPostPreviewSubscribe_icon-wrap_Tlo1c",
	"preview": "VPostPreviewSubscribe_preview_3klrI",
	"content": "VPostPreviewSubscribe_content_3yXcv",
	"lg": "VPostPreviewSubscribe_lg_3jIvv",
	"icon": "VPostPreviewSubscribe_icon_34e3F",
	"button": "VPostPreviewSubscribe_button_2EGGK",
	"round": "VPostPreviewSubscribe_round_1_ukP"
};
module.exports = exports;
