export const VirtualizationConfig = {
  VISIBLE_BLOCKS_COUNT: 12,
  SCROLL_STEP: 4,
  BLOCK_HEIGHT: 315,
}

export const GridBlockTypes = {
  COMMON: 'common',
  BIG_LEFT: 'big_left',
  BIG_RIGHT: 'big_right',
}

export default {
  PAGE_LIMIT: 36,
  REQUESTS_PARAMS: {
    direction: 'desc',
    'exists[products]': false,
    is_accessible: true,
    random: true,
  },
}
