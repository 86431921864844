// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MyApps_container_30eXp{position:absolute;top:0;right:0;transform:translateX(100%);visibility:hidden;opacity:0}.MyApps_container_30eXp,.MyApps_container_30eXp.MyApps_open_2xXcS{transition:transform .7s,visibility .7s,opacity .7s}.MyApps_container_30eXp.MyApps_open_2xXcS{visibility:visible;opacity:1}", ""]);
// Exports
exports.locals = {
	"container": "MyApps_container_30eXp",
	"open": "MyApps_open_2xXcS"
};
module.exports = exports;
