import OpusRecorder from 'opus-recorder'
import encoderPath from 'opus-recorder/dist/encoderWorker.min'
import {
  handleMediaServiceError,
  isPermissionGranted,
  requestPermission,
} from '@services/media-devices'

const recorder = new OpusRecorder({
  encoderPath,
})

export const startRecording = async () =>
  new Promise((resolve) => {
    recorder.ondataavailable = (typedArray) => {
      const blob = new Blob([typedArray], { type: 'audio/ogg' })
      resolve(blob)
    }
    isPermissionGranted().then((isGranted) => {
      if (isGranted) {
        recorder.start().catch((err) => {
          handleMediaServiceError(err)
          resolve(null)
        })
      } else {
        requestPermission()
        resolve(null)
      }
    })
  })

export const stopRecording = () => recorder.stop()

export default {
  startRecording,
}
