/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-pattern': {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<path pid="0" d="M13.471 7.2a.49.49 0 010-.41l.45-.99c.19-.43.06-.94-.33-1.22l-.89-.64a.456.456 0 01-.2-.35l-.11-1.08a1 1 0 00-.89-.89l-1.09-.12a.486.486 0 01-.35-.2l-.64-.89c-.19-.26-.5-.41-.81-.41-.14 0-.28.03-.41.09l-1 .44c-.06.03-.13.05-.2.05s-.14-.01-.2-.04l-1-.45a.978.978 0 00-.41-.09c-.31 0-.62.15-.81.41l-.64.89c-.08.11-.21.19-.35.2l-1.08.11c-.48.05-.85.42-.9.9l-.11 1.08c-.01.14-.09.27-.2.35l-.89.64c-.38.28-.52.79-.32 1.22l.44 1c.06.13.06.28 0 .41l-.44.99c-.19.43-.06.94.33 1.22l.88.64c.12.08.19.21.2.35l.11 1.08a1 1 0 00.89.89l1.08.11c.14.01.27.09.35.2l.64.88c.2.28.51.43.82.43.14 0 .28-.03.41-.09l.99-.45c.07-.03.13-.04.2-.04s.14.01.2.04l.99.45c.15.06.29.09.43.09.31 0 .62-.15.81-.41l.64-.88c.08-.12.21-.19.35-.2l1.08-.11a1 1 0 00.89-.89l.11-1.08c.01-.14.09-.27.2-.35l.88-.64c.38-.28.52-.79.33-1.22l-.43-1.02zm-1.35 2.05c-.35.25-.57.64-.61 1.06l-.11 1.08-1.08.11c-.42.04-.81.27-1.06.61l-.65.89-.99-.45c-.19-.09-.4-.13-.61-.13-.21 0-.42.04-.61.13l-1.01.45-.64-.88c-.25-.35-.64-.57-1.06-.61l-1.08-.11-.11-1.09c-.05-.43-.27-.81-.62-1.06l-.88-.64.45-.99c.17-.39.17-.84 0-1.23l-.45-1 .88-.64c.35-.25.57-.63.62-1.06l.11-1.08 1.08-.11c.43-.05.81-.27 1.06-.62l.64-.88.99.45c.2.08.41.13.62.13.21 0 .42-.04.61-.13l1-.45.64.88c.25.35.63.57 1.06.62l1.08.11.11 1.08c.04.43.27.81.61 1.06l.89.64-.45.99c-.17.39-.17.84 0 1.23l.45 1-.88.64zm-2.26-4.1c.19.2.19.51-.01.71l-3.11 3c-.1.09-.23.14-.35.14-.13 0-.25-.05-.35-.14l-1.89-1.8a.504.504 0 01-.02-.71c.19-.2.51-.21.71-.02l1.54 1.47 2.77-2.67c.2-.18.52-.18.71.02z"/>'
  }
})
