import { Module } from '../types'

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const module: Module = {
  state: () => ({
    postIds: [],
    visiblePosts: [],
    startIndex: 0,
    intersectedPostId: null,
    componentsHeights: {},
    page: 1,
    blockedPostCount: 0,
  }),
  getters,
  actions,
  mutations,
  namespaced: true,
}

export default module
