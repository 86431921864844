// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VLocaleLabel_container_1OCBo{display:flex;align-items:center;gap:9px}.VLocaleLabel_container_1OCBo .VLocaleLabel_flag_3K99K{width:21px;height:21px;border-radius:50%;border:1px solid #e5e3e3;overflow:hidden;display:flex;justify-content:center}.VLocaleLabel_container_1OCBo .VLocaleLabel_flag_3K99K .VLocaleLabel_iconWrapper_1p8vF .VLocaleLabel_icon_1l6m5{height:100%;display:block}.VLocaleLabel_container_1OCBo .VLocaleLabel_label_35hDE{font-size:15px;font-weight:400;line-height:14px}.VLocaleLabel_container--transparent_3HTGo .VLocaleLabel_label_35hDE{color:inherit}.VLocaleLabel_container--small_aL59J .VLocaleLabel_label_35hDE{font-size:12px;line-height:18px}", ""]);
// Exports
exports.locals = {
	"container": "VLocaleLabel_container_1OCBo",
	"flag": "VLocaleLabel_flag_3K99K",
	"iconWrapper": "VLocaleLabel_iconWrapper_1p8vF",
	"icon": "VLocaleLabel_icon_1l6m5",
	"label": "VLocaleLabel_label_35hDE",
	"container--transparent": "VLocaleLabel_container--transparent_3HTGo",
	"container--small": "VLocaleLabel_container--small_aL59J"
};
module.exports = exports;
