// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VLike_container_2zNe3{padding:0 9px 0 2px!important;border-radius:100px!important;-moz-column-gap:0;column-gap:0}.VLike_likes_2BBow{padding-top:1px}", ""]);
// Exports
exports.locals = {
	"container": "VLike_container_2zNe3",
	"likes": "VLike_likes_2BBow"
};
module.exports = exports;
