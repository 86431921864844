<template>
  <div :class="$style.container">
    <div :class="$style.playButton" @click="onClick">
      <icon :name="iconName" width="18" height="18" />
    </div>
    <div :class="$style.content">
      <div ref="spectrogram" :key="message.id" :class="$style.spectrogram" />
      <div :class="$style.duration">{{ duration }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import WaveSurfer from 'wavesurfer.js'
import { toTimeString } from '@utils/time'

const wavesurfers = {}

export default Vue.extend({
  name: 'ChatVoiceMessage',
  props: {
    message: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPlaying: false,
      duration: '00:00',
      durationInSec: 0,
    }
  },
  computed: {
    iconName() {
      return this.isPlaying ? 'pausesimple' : 'playsimple'
    },
    url() {
      return this.message?.media_objects?.[0]?.content_url ?? null
    },
    messageId() {
      return this.message.id ?? 'wavesurfer'
    },
  },
  mounted() {
    if (this.url) {
      this.$nextTick(() => {
        this.initWaveSurfer()
      })
    }
  },
  methods: {
    onClick() {
      this.isPlaying = !this.isPlaying
      const wavesurfer = wavesurfers[this.messageId]
      if (this.isPlaying && wavesurfer) {
        wavesurfer.play()
        this.setPlayingListener(wavesurfer)
      } else {
        wavesurfer.pause()
        this.removePlayingListener()
      }
    },
    setPlayingListener(ws) {
      this.$bus.$emit('start-voice-message', this.messageId)
      this.$bus.$on('start-voice-message', (id) => {
        if (id !== this.messageId) {
          ws.stop()
          this.reset(ws)
        }
      })
    },
    removePlayingListener() {
      this.$bus.$off('start-voice-message')
    },
    initWaveSurfer() {
      try {
        wavesurfers[this.messageId] = new WaveSurfer({
          container: this.$refs.spectrogram,
          waveColor: '#fff',
          height: 30,
          progressColor: '#6e6e6e',
          barWidth: 5,
          normalize: true,
          barRadius: 5,
          url: this.url,
          cursorWidth: 0,
        })
      } catch (e) {
        console.log(e)
      }
      const wavesurfer = wavesurfers[this.messageId]
      wavesurfer.on('ready', () => {
        this.resetDuration(wavesurfer)
      })
      wavesurfer.on('finish', () => {
        this.reset(wavesurfer)
        this.resetDuration(wavesurfer)
      })
      wavesurfer.on('timeupdate', (time) => {
        if (Math.floor(time) > this.durationInSec) {
          this.duration = toTimeString(Math.floor(time))
          this.durationInSec += 1
        }
      })
    },
    reset(wavesurfer) {
      this.isPlaying = false
      wavesurfer.setTime(0)
    },
    resetDuration(wavesurfer) {
      this.duration = toTimeString(Math.floor(wavesurfer.getDuration()))
    },
    beforeDestroy() {
      this.removePlayingListener()
    },
  },
})
</script>

<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  gap: 10px;

  .playButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    flex-shrink: 0;
    cursor: pointer;

    svg {
      fill: $secondary;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 5px;
    width: 100%;

    .spectrogram {
      height: 30px;
      width: 200px;
    }

    .duration {
      flex-shrink: 0;
      font-size: 12px;
      line-height: 15px;
    }
  }
}
</style>
