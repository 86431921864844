/* tslint:disable */
require('./add-2')
require('./add')
require('./admin')
require('./arrow-bottom')
require('./arrow-growth')
require('./arrow-left')
require('./arrow-link')
require('./arrow-right')
require('./attention-bold')
require('./attention')
require('./back')
require('./bell')
require('./browser-link')
require('./camera')
require('./card')
require('./channel')
require('./channels')
require('./chats')
require('./check-green-rounded')
require('./check-pattern-lg')
require('./check-pattern')
require('./check-sm')
require('./check')
require('./chevron')
require('./clip')
require('./clock')
require('./close')
require('./closeLock')
require('./comment')
require('./confirmationCode')
require('./copy')
require('./delete')
require('./dialog-bubble')
require('./dialog')
require('./donate')
require('./dots-3')
require('./dots-4')
require('./double-chevron')
require('./download')
require('./edit-outline')
require('./edit-round')
require('./edit')
require('./en')
require('./eye-hide')
require('./eye')
require('./facebook-icon')
require('./facebook')
require('./feed')
require('./file')
require('./fire')
require('./folder-arrow')
require('./gChat')
require('./github-icon')
require('./goblet')
require('./hashtag')
require('./info')
require('./instagram-colored')
require('./instagram')
require('./introduction-compilation')
require('./introduction-wiseman')
require('./lab')
require('./layout')
require('./leave')
require('./limitless')
require('./lock-gradient')
require('./lock-open')
require('./lock')
require('./logo')
require('./menu-chat-channel')
require('./menu-chat-group')
require('./menu-chat-personal')
require('./menu-post')
require('./menu-stories')
require('./microphone')
require('./multi-content-white')
require('./muted')
require('./nesting')
require('./no-avatar')
require('./nofollow')
require('./ok')
require('./openai-small')
require('./openai')
require('./openLock')
require('./pausesimple')
require('./paypal')
require('./pChat')
require('./permission-background')
require('./pin')
require('./play')
require('./playsimple')
require('./plus')
require('./profile')
require('./qiwi')
require('./question-colored')
require('./refresh')
require('./reply')
require('./rings-background')
require('./ru')
require('./search')
require('./send')
require('./settings-colored')
require('./settings')
require('./share-2')
require('./share')
require('./shield-round')
require('./shield')
require('./shop-2')
require('./shop')
require('./star-bubble')
require('./star')
require('./tags')
require('./telegram-colored')
require('./three-dots-loader')
require('./trash')
require('./tumblr')
require('./twitter-icon')
require('./twitter')
require('./unmuted')
require('./unpin')
require('./upload')
require('./video-white')
require('./vk')
require('./warn-triangle')
require('./warning')
require('./webmoney')
require('./youAndDocument')
require('./youtube')
require('./zero')
