/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 11,
    height: 10,
    viewBox: '0 0 11 10',
    data: '<path pid="0" d="M9.25.5H5.5A1.5 1.5 0 004 2v.75H1.75a1.5 1.5 0 00-1.5 1.5V8a1.5 1.5 0 001.5 1.5H5.5A1.5 1.5 0 007 8v-.75h2.25a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 009.25.5zm-3 7.5a.75.75 0 01-.75.75H1.75A.75.75 0 011 8V4.25a.75.75 0 01.75-.75H5.5a.75.75 0 01.75.75V8zM10 5.75a.75.75 0 01-.75.75H7V4.25a1.5 1.5 0 00-1.5-1.5h-.75V2a.75.75 0 01.75-.75h3.75A.75.75 0 0110 2v3.75z"/>'
  }
})
