/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 93.562,
    height: 93.562,
    viewBox: '0 0 93.562 93.562',
    data: '<path pid="0" d="M87.952 41.17l-36.386.11V5.61A5.598 5.598 0 0045.956 0a5.598 5.598 0 00-5.61 5.61l.11 35.561H5.61A5.598 5.598 0 000 46.781a5.597 5.597 0 005.61 5.609h34.791v35.562a5.599 5.599 0 005.61 5.61 5.599 5.599 0 005.61-5.61V52.391h36.331a5.599 5.599 0 005.61-5.61 5.616 5.616 0 00-5.61-5.611z"/>'
  }
})
