/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share-2': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<g filter="url(#filter0_d_1001_97)"><path pid="0" d="M16.022 0H16a2.015 2.015 0 00-.677.117L1.368 4.77a2 2 0 00.017 3.8l6.08 1.965 1.965 6.08A2 2 0 0011.324 18h.01a2 2 0 001.897-1.368l4.634-13.904A1.998 1.998 0 0016.022 0zM2 6.667l11.878-3.96-5.53 5.532a.977.977 0 00-.234.403L2 6.667zM11.333 16L9.358 9.887a.978.978 0 00.403-.234l5.532-5.533L11.333 16z" _fill="#fff"/></g><defs><filter id="filter0_d_1001_97" x="0" y="0" width="19.003" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="1" dy="1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1001_97"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1001_97" result="shape"/></filter></defs>'
  }
})
