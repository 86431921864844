/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shop': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M17.5 6H13V4c0-2.206-1.794-4-4-4S5 1.794 5 4v2H.5a.5.5 0 00-.5.5v8.007A3.498 3.498 0 003.493 18h11.014A3.498 3.498 0 0018 14.507V6.5a.5.5 0 00-.5-.5zM6 4c0-1.654 1.346-3 3-3s3 1.346 3 3v2H6V4zm11 10.507A2.496 2.496 0 0114.507 17H3.493A2.496 2.496 0 011 14.507V7h4v2.5a.5.5 0 101 0V7h6v2.5a.5.5 0 101 0V7h4v7.507zM8 13.5a.5.5 0 01-.5.5H6v1.5a.5.5 0 11-1 0V14H3.5a.5.5 0 110-1H5v-1.5a.5.5 0 111 0V13h1.5a.5.5 0 01.5.5z"/>'
  }
})
