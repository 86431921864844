import { FileParams } from '@services/media/types'

export const isVideo = (file: File): boolean => file.type.includes('video')
export const isImage = (file: File): boolean => file.type.includes('image')

export const onLoadImage = async (fileEvent: ProgressEvent<FileReader>): Promise<FileParams> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.src = fileEvent?.target?.result?.toString() ?? ''
    image.onload = ({ target }) => {
      const isMedia = typeof target === typeof HTMLImageElement.prototype
      if (isMedia) {
        const { width, height } = <typeof HTMLImageElement.prototype>target ?? {}
        resolve({ width, height })
      } else {
        reject()
      }
    }
  })

export const onLoadVideo = async (fileEvent: ProgressEvent<FileReader>): Promise<FileParams> =>
  new Promise((resolve) => {
    const video = document.createElement('video')

    // place a listener on it
    video.addEventListener(
      'loadedmetadata',
      (event) => {
        const target = (event.composedPath()?.[0] ?? {}) as HTMLVideoElement
        // retrieve dimensions
        const { videoWidth: width, videoHeight: height, duration } = target

        // send back result
        resolve({ height, width, duration: Math.floor(duration) })
      },
      false
    )
    video.src = fileEvent?.target?.result?.toString() ?? ''
  })

export const getFileParams = async (file: File): Promise<FileParams> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (fileEvent) => {
      const onLoadFile = isImage(file) ? onLoadImage : onLoadVideo
      onLoadFile(fileEvent).then(resolve).catch(reject)
    }
  })
}

export default {
  isVideo,
  isImage,
  onLoadImage,
  onLoadVideo,
  getFileParams,
}
