// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VHeader_header_1NPFj{position:relative;z-index:10;display:grid;min-height:47px;-moz-column-gap:5px;column-gap:5px;background-color:var(--supster-primary);border-bottom:3px solid var(--supster-secondary)}.VHeader_header_1NPFj,.VHeader_header_1NPFj.VHeader_search_11Vmo{grid-template-columns:1fr}.VHeader_header_1NPFj.VHeader_search_11Vmo{padding:4.6px 12px}.VHeader_title_uqrvc{align-self:center;justify-self:center;width:100%;max-width:80%;overflow:hidden;font-weight:500;font-size:18px;white-space:nowrap;text-align:center;text-overflow:ellipsis}.VHeader_appName_2Z2HM{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"header": "VHeader_header_1NPFj",
	"search": "VHeader_search_11Vmo",
	"title": "VHeader_title_uqrvc",
	"appName": "VHeader_appName_2Z2HM"
};
module.exports = exports;
