<template>
  <div @click="$refs.vFileAdd.onButton()" :class="$style.section">
    <v-image>
      <template #preview>
        <icon :class="$style.preview" name="camera" />
      </template>
      <template #content>
        <v-file
          ref="vFileAdd"
          :class="$style.button"
          :file-types="fileTypes"
          color="transparent"
          is-circle
          is-disabled-padding
          @files="onUpload"
        >
          <icon :class="$style.icon" name="add-2" />
        </v-file>
      </template>
    </v-image>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VImage from '@elements/VImage.vue'
import VFile from '@elements/VFile.vue'

export default Vue.extend({
  name: 'ProfileAdd',
  components: { VFile, VImage },
  props: {
    fileTypes: {
      type: Array,
      default: () => ['image'],
    },
  },
  computed: {
    ...mapGetters('Files', ['filesData', 'filesValid', 'files']),
  },
  methods: {
    async onUpload() {
      if (this.filesValid) {
        this.$emit('upload', this.files)
      }
    },
  },
})
</script>

<style lang="scss" module>
.section {
  position: relative;
  cursor: pointer;
}

.preview {
  width: 50px;
  color: $secondary-dark;
}

.button {
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: auto;

  .icon {
    width: 33px;
    height: 33px;
    fill: none;
  }
}
</style>
