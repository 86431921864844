<template>
  <v-button :class="$style.container" size="md" @click="checkClientName" :is-loading="isLoading">
    <icon name="comment" width="14" height="12" />
    <v-count :count="post.comment_count" :key="post.comment_count" />
  </v-button>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VCount from '@layouts/VCount.vue'

export default Vue.extend({
  name: 'VPostComment',
  components: { VButton, VCount },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('Client', ['clientName']),
    ...mapGetters('Locale', ['locale']),
  },
  methods: {
    ...mapMutations('Comments', ['resetComments']),
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Post', ['setPost']),
    ...mapMutations('Page', ['setPageBack']),
    checkClientName() {
      if (!this.clientName) {
        this.setDrawer({ name: 'noname', isVisible: true })
        this.$bus.$on('noname-confirm', this.onComment)
      } else {
        this.onComment()
      }
    },
    async onComment() {
      this.isLoading = true
      this.setPost(this.post)
      this.setPageBack({
        isDisabled: false,
      })
      await this.$router.push({
        name: 'PostComments',
        params: {
          id: this.post.id,
          locale: this.locale,
        },
      })
      this.isLoading = false
    },
  },
})
</script>

<style lang="scss" module>
.container {
  border-radius: 100px !important;
}
</style>
