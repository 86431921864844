/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning': {
    width: 14,
    height: 12,
    viewBox: '0 0 14 12',
    data: '<path pid="0" d="M13.32 9.683L8.324 1.108a1.478 1.478 0 00-1.29-.735 1.47 1.47 0 00-1.29.742L.753 9.69c-.576.98.144 2.212 1.29 2.212h9.987c1.146 0 1.867-1.232 1.29-2.22zM12.7 10.8a.757.757 0 01-.67.381H2.041a.756.756 0 01-.67-.381.739.739 0 010-.75l4.993-8.575a.758.758 0 01.67-.382c.137 0 .469.036.67.382L12.7 10.05a.74.74 0 010 .75zM7.755 9.74c0 .396-.324.72-.72.72a.723.723 0 01-.721-.72c0-.396.324-.72.72-.72.397 0 .721.324.721.72zm0-5.044v2.882c0 .397-.324.721-.72.721a.723.723 0 01-.721-.72V4.695c0-.396.324-.72.72-.72.397 0 .721.324.721.72z" _fill="#fff"/>'
  }
})
