import { RouteConfig } from 'vue-router'

const subPages: Array<RouteConfig> = [
  {
    path: 'following',
    name: 'Following',
    meta: { requiresAuth: true },
    component: () => import('@views/Following.vue'),
  },
  {
    path: 'finances',
    name: 'Finances',
    meta: { requiresAuth: true },
    component: () => import('@views/Finances.vue'),
  },
  {
    path: 'promotional/:id',
    name: 'Promotional',
    meta: { requiresAuth: true },
    component: () => import('@views/promotional/Promotional.vue'),
  },
  {
    path: 'verification',
    name: 'Verification',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('@views/verification/Verification.vue'),
  },
  {
    path: 'subscriptions',
    name: 'Subscriptions',
    meta: { requiresAuth: true },
    component: () => import('@views/subscriptions/Subscriptions.vue'),
  },
  {
    path: 'client-edit',
    name: 'ClientEdit',
    meta: { requiresAuth: true },
    component: () => import('@views/ClientEdit.vue'),
  },
  {
    path: 'post-setup',
    name: 'PostCreate',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@views/post_setup/PostSetup.vue'),
  },
  {
    path: 'post-setup/:id',
    name: 'PostEdit',
    props: true,
    meta: { requiresAuth: true },
    component: () => import('@views/post_setup/PostSetup.vue'),
  },
  {
    path: 'cart',
    name: 'Cart',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('@views/cart/Cart.vue'),
  },
  {
    path: 'orders',
    name: 'Orders',
    meta: { requiresAuth: true },
    component: () => import('@views/orders/Orders.vue'),
  },
  {
    path: 'orders/:id',
    name: 'Order',
    meta: { requiresAuth: true },
    component: () => import('@views/order/Order.vue'),
  },
]

export default subPages
