import { PostIds } from '@common-types/post'
import FeedCache from './services/cache'

export const getBlockedPostCount = (postIds: PostIds, blockedAppName: string): number =>
  postIds.reduce(
    (acc, postId) => (FeedCache.getItem(postId)?.app?.name === blockedAppName ? acc + 1 : acc),
    0
  )

export default {
  getBlockedPostCount,
}
