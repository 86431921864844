<template>
  <transition-group tag="div" name="slide-down-up" mode="in-out">
    <v-header key="header" />
  </transition-group>
</template>

<script>
import Vue from 'vue'
import VHeader from '@layouts/header/VHeader.vue'

export default Vue.extend({
  name: 'HeaderWrapper',
  components: {
    VHeader,
  },
})
</script>
