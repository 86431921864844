<template>
  <div :class="$style.section">
    <v-tabs
      :tabs="tabs"
      :active-index.sync="tabIndex"
      :class-name="$style.tabs"
      color="default"
      :is-tabs-visible="isTabsVisible"
      is-single-tab
      @tab="onChangeTabIndex"
    >
      <template #content>
        <component :is="componentName[tabName]" />
      </template>
    </v-tabs>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VTabs from '@layouts/VTabs.vue'
import VGrid from '@layouts/VGrid.vue'
import { Tab } from '@modules/search/types'
import SearchPosts from './modules/posts/SearchPosts.vue'
import SearchChats from './modules/chats/SearchChats.vue'

export default Vue.extend({
  name: 'Search',
  components: { VGrid, VTabs, SearchPosts, SearchChats },
  data() {
    return {
      tabIndex: 0,
      tabs: [
        { value: this.$t('posts'), name: Tab.POSTS },
        { value: this.$t('channels.channels'), name: Tab.CHATS },
        // { value: this.$t('tags'), name: Tab.TAGS, lastSearchQuery: '' },
      ],
      isTabsVisible: false,
      visibleItemIds: [],
    }
  },
  created() {
    const index = this.tabs.findIndex(({ name }) => name === this.$route?.query?.tab)
    if (index !== -1) {
      this.isTabsVisible = true
      this.tabIndex = index
    } else {
      this.tabIndex = 0
    }
    const tabName = this.tabs[this.tabIndex]?.name
    this.setTab(tabName)
    this.$bus.$on('search-input-focus', this.onFocus)
    if (!this.pageIsFooter) {
      this.setPageIsFooter(true)
    }
  },
  beforeDestroy() {
    this.$bus.$off('search-input-focus')
    this.$bus.$off('search-input-close')
  },
  computed: {
    ...mapGetters('Search', ['searchQuery']),
    ...mapGetters('Page', ['pageIsFooter']),
    tabName() {
      return this.tabs[this.tabIndex].name
    },
    componentName() {
      return {
        [Tab.POSTS]: 'SearchPosts',
        [Tab.CHATS]: 'SearchChats',
      }
    },
    placeholderName() {
      if (this.tabName === 'posts') {
        return 'p-post-preview'
      }
      return 'p-chat-preview'
    },
  },
  methods: {
    ...mapMutations('Search', ['setSearchQuery', 'setTab']),
    ...mapMutations('Page', ['setPageBack', 'setPageTitle', 'setPageIsFooter']),
    ...mapMutations('Profile', ['setProfileContentItem']),
    ...mapMutations('Chats', ['deleteChatFromChats']),
    async onChangeTabIndex(name) {
      this.setTab(name)
      this.setLastSearchQuery()
      if (this.$route.query.tab !== name) {
        await this.$router.push({ query: { tab: name } })
      }
    },
    setLastSearchQuery() {
      this.tabs = this.tabs.map((tab) =>
        tab.name === this.$route.query?.tab ? { ...tab, lastSearchQuery: this.searchQuery } : tab
      )
    },
    onFocus(isFocused) {
      this.isTabsVisible = isFocused
      if (!this.$route?.query?.tab) {
        this.onChangeTabIndex(this.tabs[0].name)
        this.tabIndex = 0
      }
      if (!isFocused) {
        this.setSearchQuery('')
      }
    },
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
}

.tabs {
  position: sticky;
  box-shadow: inset 0 -1px 0 $secondary-light;
}

.content {
  display: grid;
  height: 100%;
  row-gap: 9px;
}

.post {
  &:nth-child(24n + 1),
  &:nth-child(24n - 10) {
    grid-row: span 2;
    grid-column: span 2;
  }
}

.noData {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-top: 40px;
  transform: translate(-50%, -50%);
}
</style>
