<template>
  <menu :class="[$style.menu, desktop && $style.desktop, pageIsMenu && $style.open]" ref="aside">
    <icon v-if="!desktop" name="logo" :class="$style.logo" />
    <div :class="$style.content">
      <component
        v-for="({ to, href, name, icon, disabled, price, type, bolder }, i) in menuVisible"
        :key="`item-${i}`"
        :is="getComponentName({ to, href, disabled })"
        :class="[$style.item, disabled && $style.disabled, bolder && $style.bolder]"
        :to="Trans.i18nRoute(to)"
        :href="href"
        :target="href && '_blank'"
        @click.native="onMenu(type)"
        @click="onMenu(type)"
      >
        <icon v-if="icon" :name="icon" :class="$style.icon" />
        <span :class="$style.text">{{ name }}</span>
        <v-button
          v-if="price"
          :class="$style.price"
          size="xl"
          color="transparent"
          :is-disabled="disabled"
          is-div
        >
          {{ appCurrencySymbol() }}{{ price }}
        </v-button>
      </component>
    </div>
    <div v-if="!desktop" :class="$style['content-bottom']">
      <router-link :to="Trans.i18nRoute({ name: 'settings' })" :class="[$style.item, $style.small]">
        {{ $t('aside.settings') }}
      </router-link>
      <VLocaleLabel
        :locale="locale"
        :class="[$style.item, $style.small]"
        :key="locale"
        is-transparent
        is-small
        @click.native="showLocaleDrawer"
      />
    </div>
    <my-apps />
  </menu>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import SelectDropdown from '@dropdowns/list/SelectDropdown.vue'
import Trans from '@plugins/i18n/translation'
import VLocaleLabel from '@components/VLocaleLabel.vue'
import { drawerNames } from '@config/drawer-names'
import CommonConfig from '@config/common'
import MyApps from './list/MyApps.vue'

export default Vue.extend({
  name: 'VMenu',
  components: { VLocaleLabel, MyApps, VButton, SelectDropdown },
  props: {
    desktop: Boolean,
  },
  data() {
    return {
      isMyAppsVisible: false,
      Trans,
    }
  },
  computed: {
    ...mapGetters('App', ['appClientId', 'appCurrencySymbol', 'isMyAppGPT']),
    ...mapGetters('Client', ['clientId', 'clientName', 'isClientOwner', 'isClientVerified']),
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Page', ['pageIsMenu']),
    ...mapGetters('Countries', [
      'isCurrentCountryRu',
      'privacyDocId',
      'userAgreementDocId',
      'combatingLegalizationDocId',
    ]),

    localeList() {
      return Trans.supportedLocales.map((item) => {
        return {
          value: this.$t(`locale.${item}`),
          locale: item,
        }
      })
    },
    menu() {
      return [
        {
          href: 'https://my.supster.com',
          name: this.$t('aside.adminPanel'),
          icon: 'admin',
          hidden: !this.isClientOwner,
          bolder: true,
        },
        {
          name: this.$t('aside.users'),
          type: 'my-apps',
          hidden: !this.isClientOwner,
          disabled: this.isClientOwner,
        },
        { name: this.$t('aside.auth'), type: 'auth', hidden: this.isClientOwner, bolder: true },
        {
          to: { name: 'MyProfile' },
          name: this.$t('aside.myProfile'),
          hidden: this.isMyAppGPT || this.isClientOwner,
        },
        {
          to: { name: 'Verification' },
          name: this.$t('verification'),
          hidden: !this.isClientOwner || this.isMyAppGPT,
        },
        {
          to: { name: 'Finances' },
          name: this.$t('aside.finances'),
          hidden: this.isMyAppGPT,
          price: 0,
          disabled: true,
        },
        { to: '/', name: this.$t('aside.statistics'), disabled: true, hidden: this.isMyAppGPT },
        { to: { name: 'Subscriptions' }, name: this.$t('aside.subscriptions'), bolder: true },
        { name: this.$t('aside.referrals'), disabled: true, hidden: this.isMyAppGPT },
        { to: { name: 'Orders' }, name: this.$t('aside.myOrders'), bolder: true },
        { name: CommonConfig.SUPSTER_SUPPORT_EMAIL, type: 'support', bolder: true },
        {
          to: {
            name: 'Documents',
            params: {
              slug: this.userAgreementDocId,
            },
          },
          name: this.$t('aside.publicOffer'),
        },
        {
          to: {
            name: 'Documents',
            params: {
              slug: this.privacyDocId,
            },
          },
          name: this.$t('docs.privacy'),
        },
        {
          to: {
            name: 'Documents',
            params: {
              slug: this.combatingLegalizationDocId,
            },
          },
          name: this.$t('docs.combating-legalization'),
          hidden: this.isCurrentCountryRu,
        },
      ]
    },
    menuVisible() {
      return this.menu.filter(({ hidden }) => !hidden)
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Locale', ['setLocale']),

    ...mapMutations('Page', ['setPageIsMenu', 'setLastRouteFromFooter']),
    getComponentName({ to, href, disabled }) {
      if (disabled) {
        return 'div'
      }
      if (to) {
        return 'router-link'
      }
      if (href) {
        return 'a'
      }
      return 'div'
    },
    onClickOutside({ target }) {
      if (!this.$refs.aside?.contains(target)) {
        this.setPageIsMenu(false)
      }
    },
    onMenu(type) {
      if (type === 'support') {
        const link = document.createElement('a')
        link.href = `mailto:${CommonConfig.SUPSTER_SUPPORT_EMAIL}`
        link.click()
        // if (!this.clientName) {
        //   this.setPageIsMenu(false)
        //   this.setDrawer({ name: 'noname', isVisible: true })
        //   this.$bus.$on('noname-confirm', this.onGoToChatWithOwner)
        // } else {
        //   this.onGoToChatWithOwner()
        // }
      }
      if (type === 'auth') {
        this.setPageIsMenu(false)
        this.setDrawer({ name: 'auth', isVisible: true })
      }
      this.setLastRouteFromFooter('')
    },
    onGoToChatWithOwner() {
      this.$router.push({ name: 'SupportChat', params: { locale: this.locale } })
      this.$bus.$off('noname-confirm')
    },
    showLocaleDrawer() {
      this.setDrawer({ name: drawerNames.LOCALES, isVisible: true })
      this.setPageIsMenu(false)
    },
  },
  watch: {
    '$route.fullPath': {
      handler() {
        if (this.$route.name && this.pageIsMenu) {
          this.setPageIsMenu(false)
        }
      },
      immediate: true,
    },
    pageIsMenu(isOpen) {
      setTimeout(() => {
        if (isOpen) {
          window.addEventListener('click', this.onClickOutside)
        }
      }, 100)
      window.removeEventListener('click', this.onClickOutside)
    },
  },
})
</script>
<style lang="scss" module>
.menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 270px;
  height: 100%;
  padding: 13px 42px 42px 42px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $primary;
  border-left: 3px solid $secondary;
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.7s, visibility 0.7s, opacity 0.7s;
  row-gap: 15px;

  &.open {
    visibility: visible;
    opacity: 1;
    transition: transform 0.7s, visibility 0.7s, opacity 0.7s;
  }
}

.content {
  display: grid;
  grid-auto-rows: 30px;
  grid-auto-flow: row;
  row-gap: 20px;
}

.content-bottom {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 92px;
  height: 28px;
}

.item {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  align-self: center;
  justify-self: flex-start;
  color: $main-text-color;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  column-gap: 6px;
  cursor: pointer;
  transition: opacity $transition;

  &:hover:not(.disabled) {
    opacity: 0.6;
  }
  &.small {
    font-size: 12px;
  }
  &.bolder {
    font-weight: 500;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  & .icon {
    align-self: center;
    width: 17px;
    height: 13px;
    color: $main-text-color;
  }

  & .price {
    justify-self: flex-end;
    padding: 6px 9px !important;
    border: 1px solid $secondary !important;
  }
}

.language {
  span {
    font-weight: normal;
    font-size: 12px;
  }
}

.desktop {
  background-color: white;
  border: none;
  padding-top: 96px;

  .content {
    .item {
      color: black;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .bolder {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
  }
}
</style>
