import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@store/index'
import { ExtendedService } from '@services/types'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18n from '@plugins/i18n'
import router from '@router/index'
import PromotionalApi from '@services/promotional'
import SubscriptionApi from '@services/subscription'
import App from '../App.vue'

const Mapper = {
  $store: store,
  ...mapActions('Me', ['getMe']),
  ...mapGetters('Me', ['stream']),
  ...mapGetters('Client', ['client', 'hasClientBotLimit', 'hasSubscriptions']),
  ...mapActions('Client', ['postClient', 'getClientBot', 'getInstall']),
  ...mapMutations('Client', ['setHasSubscriptions', 'setInitPromotionalId']),
  ...mapGetters('App', [
    'appId',
    'appOriginalId',
    'isMyAppGPT',
    'isSplitTestApp',
    'isSplitTestApp2',
  ]),
  ...mapMutations('Me', [
    'setcId',
    'setStream',
    'setSubId',
    'setRefId',
    'setIsPassInstall',
    'setIsSkipInstall',
  ]),
  ...mapActions('Subscriptions', ['getSubscriptions']),
  ...mapActions('Install', ['getInstall']),
  ...mapMutations('Page', ['setIsFirstLaunch']),
  ...mapActions('Chat', ['postChatCopy']),
}

export const checkSubscriptions = async (): Promise<boolean> => {
  const subscriptions = await Mapper.getSubscriptions({
    params: {
      app_id: Mapper.appId()(),
    },
  })
  const hasSubscriptions = !!subscriptions?.length
  Mapper.setHasSubscriptions(hasSubscriptions)
  return hasSubscriptions
}

export const splitTestAppBoot = async (): Promise<void> => {
  if (!Mapper.hasSubscriptions()) {
    const promotionals = await PromotionalApi.getPromotionals({
      app_id: Mapper.appId()(),
      limit: 1,
      page: 1,
    })
    Mapper.setInitPromotionalId(promotionals?.[0]?.id ?? null)
  }
}
const handleAppBoot = async (): Promise<void> => {
  const uri = window.location.search.substring(1)
  const params = new URLSearchParams(uri)
  const stream = params.get('stream')
  const subId = params.get('subid')
  const refId = params.get('ref')
  const cId = params.get('cid')
  const isSkipInstall = 'true' // new URLSearchParams(uri).get('skip-install')
  const isPassInstall = 'true' // new URLSearchParams(uri).get('pass-install')

  if (subId || refId || stream || cId) {
    Mapper.setcId(cId)
    Mapper.setSubId(subId)
    Mapper.setRefId(refId)
    Mapper.setStream(stream)
    await Mapper.getInstall()
  }
  if (isSkipInstall) {
    Mapper.setIsSkipInstall(isSkipInstall)
  }
  if (isPassInstall) {
    Mapper.setIsPassInstall(isPassInstall)
  }
}

const handleRoutingParams = async (): Promise<void> => {
  const { location } = window
  const path = location.pathname
  const uri = location.search.substring(1)
  const params = new URLSearchParams(uri)
  const secretKey = params.get('secret_key')
  const isSharedChannelPage = /chats\/share/.test(path) && secretKey
  if (isSharedChannelPage) {
    await Mapper.postChatCopy(secretKey)
  }
}

export const bootApp: ExtendedService<'accessToken' | 'refreshToken', Promise<void>> = async ({
  accessToken,
  refreshToken,
}) => {
  await Mapper.getMe({ 'auth-token': accessToken, 'refresh-token': refreshToken })

  if (!Mapper.client()) {
    Mapper.setIsFirstLaunch(true)
    await handleAppBoot()
    await Mapper.postClient({
      app_id: Mapper.appId()(),
      name: '',
      stream: Mapper.stream(),
    })
  }
  if (Mapper.client()) {
    await checkSubscriptions()
    if (Mapper.isMyAppGPT()) {
      await Mapper.getClientBot()
      if (Mapper.isSplitTestApp()) {
        await splitTestAppBoot()
      }
      await handleRoutingParams()
    }
  }
}

export const hideLoader = (loader: Element | null): void => {
  if (loader) {
    loader.classList.add('loader-hide')
  }
}

export const makeErrorLoader = (loader: Element | null): void => {
  if (!loader) {
    return
  }
  const loaderText = loader.querySelector('.loader-text')
  const loaderLine = loader.querySelector('.loader-line')
  if (loaderText && loaderLine) {
    loaderText.textContent = 'ERROR 404'
    loaderLine.classList.add('loader-line--hide')
  }
}

export const makeSubscriptionCancellationPage = async (loader: Element | null): Promise<void> => {
  const subscriptionCancellationPage = document.querySelector('#subscription-cancellation')
  try {
    const uri = window.location.search.substring(1)
    const uriParams = new URLSearchParams(uri)
    const secretKey = uriParams.get('secret_key')
    if (secretKey) {
      await SubscriptionApi.postSubscriptionCancel(secretKey)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-undef
      ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'cancel-subscription-finish')
    }
  } catch (error) {
    const title = subscriptionCancellationPage?.querySelector('#subscription-cancellation-title')
    const text = subscriptionCancellationPage?.querySelector('#subscription-cancellation-text')
    if (title) {
      title.textContent = 'Error'
    }
    if (text) {
      text.textContent = 'Something went wrong'
    }
  }
  if (!subscriptionCancellationPage) {
    return
  }
  if (loader) {
    loader.classList.add('loader-hide')
  }
  subscriptionCancellationPage.classList.remove('subscription-cancellation--hide')
}

export const initVue = (): void => {
  const VUE = new Vue({
    name: '',
    i18n,
    store,
    router,
    render: (h) => h(App),
  })
  VUE.$mount('#app')
}
export const routingGuard = (): Record<string, boolean> => {
  const isSharedChannelPage = /channels\/share/.test(window.location.pathname)
  if (isSharedChannelPage) {
    window.location.replace(window.location.href.replace('channels', 'chats'))
  }
  const isSubscriptionCancellationPage = /subscriptions\?secret_key/.test(window.location.href)
  return {
    isSharedChannelPage,
    isSubscriptionCancellationPage,
  }
}

export default {
  bootApp,
  handleAppBoot,
  hideLoader,
  makeErrorLoader,
  initVue,
}
