<template>
  <VModal
    :is-visible="isEdit || isVisible"
    :is-loading="isLoading"
    :title="$tc('select.images', mediaLength)"
    is-confirm
    no-scrollbar
    no-validation
    @update:is-visible="$emit('update:is-visible', $event)"
    @cancel="onCancel"
    @accept="onSendMedia"
  >
    <template #accept>
      <transition name="slide-up" mode="out-in">
        <icon v-if="isError" name="refresh" width="15" height="15" />
        <span v-else>
          {{ $t('confirm.accept') }}
        </span>
      </transition>
    </template>
    <template #cancel>
      {{ $t('confirm.cancel') }}
    </template>
    <div :class="$style.container">
      <transition-group tag="div" name="fade" :class="$style.content">
        <VCover
          v-for="{ id, url, data } of mediaFiles"
          :key="id"
          :class="$style.element"
          :id="id"
          :src="url"
          :mime="data.type"
          :file-name="data.name"
          :progress="fileProgress(id)"
          :error="fileError(id)"
          color="secondary"
          @remove="onRemove"
          @refresh="onRefresh"
        />
      </transition-group>
      <VTextarea
        v-model="input"
        :value="input"
        :title="$t('description')"
        :placeholder="$t('enter.description')"
        :disabled="isLoading"
      />
    </div>
  </VModal>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VTextarea from '@elements/VTextarea.vue'
import VModal from '@modals/VModal.vue'

import VCover from '@layouts/VCover.vue'

export default Vue.extend({
  name: 'ChatMediaModal',
  components: {
    VModal,
    VCover,
    VTextarea,
  },
  props: {
    text: String,
    isVisible: Boolean,
  },
  data() {
    return {
      input: this.text,
      messageFiles: [],
      isLoading: false,
      isError: false,
    }
  },
  computed: {
    ...mapGetters('Files', [
      'files',
      'hasFiles',
      'everyFilesComplete',
      'filesLength',
      'fileProgress',
      'fileError',
    ]),
    ...mapGetters('Media', ['mediaIds']),
    ...mapGetters('Chat', ['referencedMessage', 'hasReferencedMessageMedia', 'chatMessageIsEdit']),
    isEdit() {
      return this.hasReferencedMessageMedia && this.chatMessageIsEdit
    },
    messageText() {
      return this.referencedMessage?.body ?? ''
    },
    mediaFiles() {
      return this.isEdit ? this.messageFiles : this.files
    },
    mediaLength() {
      return this.mediaFiles?.length ?? null
    },
    isShow() {
      return this.isEdit || this.isVisible
    },
  },
  watch: {
    text() {
      this.input = this.text
    },
    hasFiles: {
      handler() {
        if (!this.hasFiles) {
          this.$emit('update:is-visible', false)
        }
      },
    },
    isShow(isShow) {
      if (isShow) {
        this.input = this.isEdit ? this.messageText : this.text
        this.messageFiles = this.isEdit
          ? this.referencedMessage?.media_objects?.map(({ id, preview_url, content_url }) => ({
              id,
              url: preview_url || content_url,
              data: {
                name: 'image',
                type: 'image/jpeg',
              },
            }))
          : []
      } else {
        this.resetMedia()
        this.resetFiles()
        this.isLoading = false
        this.isError = false
      }
    },
  },
  methods: {
    ...mapMutations('Files', ['setFiles', 'removeFile', 'resetFiles', 'setFileProgress']),
    ...mapMutations('Media', ['resetMedia']),
    ...mapActions('Media', ['postMedia', 'postMediaItem']),
    ...mapMutations('Chat', ['resetReferencedMessageId', 'setIsReply', 'setIsEdit']),
    async onRefresh(id) {
      await this.postMediaItem({ fileId: id, callback: this.setFileProgress })
      if (this.everyFilesComplete) {
        this.submit()
      }
    },
    async onSendMedia() {
      this.isLoading = true
      if (!this.isEdit && this.hasFiles) {
        await this.postMedia(this.setFileProgress)
      }
      this.submit()
      this.isLoading = false
    },
    submit() {
      if (this.isEdit) {
        this.$emit('update', { media: this.mediaFiles?.map(({ id }) => id), text: this.input })
      } else {
        this.$emit('send', { media: this.mediaIds, text: this.input })
      }
      this.onCancel()
    },
    onCancel() {
      if (this.isEdit) {
        this.resetReferencedMessageId()
        this.setIsReply(false)
        this.setIsEdit(false)
      }
      this.$emit('update:is-visible', false)
    },
    onRemove(id) {
      if (this.isEdit) {
        this.messageFiles = this.messageFiles.filter((file) => file.id !== id)
        return
      }
      this.removeFile(id)
    },
  },
})
</script>
<style lang="scss" module>
.container {
  display: grid;
  row-gap: 10px;
}

.content {
  display: grid;
  width: 100%;
  height: 100%;
  column-gap: 6px;
  row-gap: 6px;
}

.element {
  height: 100%;
  max-height: 138px;

  &:nth-child(1) {
    grid-row: span 2;
    grid-column: span 2;
  }

  &:nth-child(2) {
    grid-row: span 2;
    grid-column: span 2;
  }
}
</style>
