<template>
  <div>
    <div v-if="!isFeedPage && isProduct && !isFooter" :class="[$style.badge, $style.price]">
      <span :class="$style.text">{{ currency }}{{ currentPrice }}</span>
    </div>
    <div
      v-if="isProduct && !isProductSmall"
      :class="[$style.badge, $style.shop, isSmall && $style.small]"
    >
      <icon name="shop" width="18" height="18" />
    </div>
    <div :class="[$style.actions, isSmall && $style.small]">
      <div
        v-if="discount"
        :class="[$style.badge, $style.discount, isProductPage && $style.primary]"
      >
        <span :class="[$style.text, isSmall && $style.small]">-{{ discount }}%</span>
      </div>
      <v-like
        v-if="isProductPage"
        :class="$style.button"
        :count="post.like_count"
        :is-liked="post.is_liked"
        @like="onLike"
      />
      <v-post-comment v-if="isProductPage" :class="$style.button" :post="post" />
      <div v-if="isProductPage && ratingStar" :class="[$style.badge, $style.rating]">
        <v-star-rating size="sm" :value="ratingStar" />
        <span :class="[$style.text, $style.small]">{{ ratingReplies }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import VLike from '@layouts/like/VLike.vue'
import VPostComment from '@layouts/post/list/VPostComment.vue'

export default Vue.extend({
  name: 'VPostPreviewBadges',
  components: { VLike, VPostComment },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    isProduct: Boolean,
    isProductSmall: Boolean,
    isFooter: Boolean,
  },
  computed: {
    ...mapGetters('App', ['appCurrencySymbol']),
    isFeedPage() {
      return this.$route.name === 'Feed'
    },
    isProductPage() {
      return this.$route.name === 'Product'
    },
    isSmall() {
      return !this.isFeedPage && !this.isProductPage
    },
    currency() {
      return this.product?.app?.currency?.symbol || this.appCurrencySymbol()
    },
    currentPrice() {
      return this.product?.price ?? 0
    },
    previousPrice() {
      return this.product?.old_price ?? 0
    },
    discount() {
      if (this.previousPrice) {
        const diff = Math.ceil(100 - (this.currentPrice / this.previousPrice) * 100)
        return diff > 0 ? diff : null
      }
      return null
    },
    ratingReplies() {
      return this.product?.review_count ?? 0
    },
    ratingStar() {
      return this.product?.rating_sum
        ? Math.round(this.product?.rating_sum / this.product?.review_count)
        : 0
    },
  },
  methods: {
    ...mapActions('Post', ['setPostLike']),
    async onLike() {
      await this.setPostLike({ isLike: !this.post.is_liked, id: this.post.id })
    },
  },
})
</script>

<style lang="scss" module>
.badges {
  position: relative;
}

.actions {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 9px;
  width: 100%;
  padding: 10px 15px;

  &.small {
    padding: 3px 3px;
  }
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;

  &.price {
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 2;
    color: $secondary;
    background: white;
    border-radius: 3px 9px 9px 9px;
  }

  &.shop {
    position: absolute;
    top: 9px;
    right: 9px;
    z-index: 2;
    padding: 6px;
    color: $secondary;
    background: white;
    border-radius: 9px 3px 9px 9px;

    &.small {
      top: 3px;
      right: 3px;
    }
  }

  &.discount {
    color: white;
    background: $secondary;
    border-radius: 9px 9px 9px 3px;

    &.primary {
      color: $secondary;
      background: $primary;
    }
  }

  &.rating {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-gap: 6px;
    justify-self: flex-end;
    color: white;
    background: $secondary;
  }

  & .text {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;

    &.small {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }

  & svg {
    display: block;
  }
}
</style>
