<template>
  <div :class="$style.container">
    <ChatMessageReference
      v-if="hasReference"
      :class="$style.reference"
      is-referenced
      @close="clearInput"
    />
    <div :class="$style.content">
      <v-file
        v-if="!isChatGPTBot && !isRecording"
        color="transparent-light"
        is-multiple
        :class="$style.file"
        @files="$emit('files')"
      >
        <icon name="clip" width="28" height="18" />
      </v-file>
      <message-input
        @blur="fixAfterSwipeEmptyPlaceOnIOS('blur')"
        @focus="fixAfterSwipeEmptyPlaceOnIOS('focus')"
        :focus="hasReference"
        :is-visible="!pageIsFooter"
        :value="text"
        :is-loading="isLoading"
        :placeholder="$t('yourMessage')"
        :class="$style.input"
        :only-text="isChatGPTBot"
        @input="$emit('input', $event)"
        @send="onSubmit"
        @record-end="onRecorded"
        @record-start="onRecordStart"
        @record-cancel="onRecordCancel"
        :max-length="maxLength"
      />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VFile from '@elements/VFile.vue'
import MessageInput from '@elements/inputs/list/MessageInput.vue'
import Config from '@config/index'
import MediaApi from '@services/media'
import { ToastTypes } from '@modules/toasts/constants'
import { MessageType } from '@common-types/chat/chat-message'
import ChatMessageReference from './ChatMessageReference.vue'

export default Vue.extend({
  name: 'ChatInput',
  components: { MessageInput, VFile, ChatMessageReference },
  props: {
    value: String,
    maxLength: Config.ChatConfig.INPUT_MAX_LENGTH,
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isRecording: false,
    }
  },
  computed: {
    ...mapGetters('Page', ['pageIsFooter', 'isIOS']),
    ...mapGetters('Chat', [
      'chatMessageIsReply',
      'chatMessageIsEdit',
      'referencedMessage',
      'referencedMessageId',
      'hasReferencedMessageMedia',
      'isChatGPTBot',
    ]),
    hasReference() {
      return this.chatMessageIsReply || (!this.hasReferencedMessageMedia && this.chatMessageIsEdit)
    },
    text() {
      return (this.chatMessageIsEdit && this.referencedMessage?.body) || this.value
    },
  },
  methods: {
    ...mapMutations('Chat', [
      'setReferencedMessageId',
      'resetReferencedMessageId',
      'setIsReply',
      'setIsEdit',
    ]),

    ...mapMutations('Toasts', ['pushToast']),

    fixAfterSwipeEmptyPlaceOnIOS(type) {
      if (this.isIOS) {
        if (type === 'focus') {
          const vh = window.innerHeight * 10000000
          document.documentElement.style.setProperty('--vh', `${vh}px`)
        } else {
          setTimeout(() => {
            const vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
          }, 100)
        }
      }
    },
    onSubmit(text) {
      this.$emit(this.chatMessageIsEdit ? 'update' : 'send', { text, media: null })
    },
    clearInput() {
      this.$emit('input', '')
    },
    async onRecorded(blob) {
      this.isRecording = false
      try {
        const media = await MediaApi.postVoiceMessage(blob)
        this.$emit('send', { media: [media.id], type: MessageType.VOICE })
      } catch (error) {
        if (error?.message === 'Network Error') {
          this.pushToast({
            type: ToastTypes.INFO,
            title: this.$t('errors.title'),
            message: this.$t('errors.offline'),
          })
        }
      }
    },
    onRecordStart() {
      this.isRecording = true
    },
    onRecordCancel() {
      this.isRecording = false
    },
  },
})
</script>
<style lang="scss" module>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  .reference {
    color: $secondary;
  }
}

.content {
  display: flex;
  align-items: center;
  padding: 12px;
  background: #ffffff;
  box-shadow: inset 0 1px 0 $secondary-light;

  .file {
    flex-basis: 42px;
  }

  .input {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
}

.button {
  align-self: flex-end;
  width: 42px;
  height: 42px;
}
</style>
