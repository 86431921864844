var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"dropdown",class:_vm.$style.content},[_c('v-popover',{class:_vm.$style.popover,attrs:{"offset":"3","trigger":"manual","container":_vm.$refs.dropdown,"boundaries-element":null,"popover-base-class":[_vm.$style.popoverBaseClass, 'tooltip', 'popover'],"open":_vm.isOpen,"is-disabled":_vm.isLoading},on:{"update:open":function($event){_vm.isOpen=$event}},scopedSlots:_vm._u([{key:"popover",fn:function(){return [(_vm.isSearch)?_c('div',{class:_vm.$style.search},[_c('text-input',{class:_vm.$style.input,attrs:{"placeholder":_vm.searchPlaceholder},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg',{class:_vm.$style.icon,attrs:{"viewBox":"0 0 14 14","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.8535 13.1464L9.74261 9.03558C9.73669 9.02966 9.7287 9.02777 9.72253 9.02222C10.5194 8.06793 11 6.84045 11 5.5C11 2.46246 8.53754 0 5.5 0C2.4624 0 0 2.46246 0 5.5C0 8.5376 2.4624 11 5.5 11C6.84045 11 8.06793 10.5193 9.02222 9.72247C9.02777 9.72864 9.02966 9.73669 9.03558 9.74261L13.1464 13.8535C13.2441 13.9511 13.372 13.9999 13.4999 13.9999C13.6279 13.9999 13.7558 13.9511 13.8535 13.8535C14.0488 13.6581 14.0488 13.3417 13.8535 13.1464ZM5.5 10C3.01868 10 1 7.98132 1 5.5C1 3.01868 3.01868 1 5.5 1C7.98126 1 10 3.01868 10 5.5C10 7.98132 7.98126 10 5.5 10Z"}})])]},proxy:true}],null,false,3564611973),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('ul',{class:_vm.$style.list},[(_vm.listItemsLength)?_vm._l((_vm.filteredList),function(item,index){return _c('li',{key:`dropdown-${_vm.name}-${index}`,class:[
              _vm.$style.item,
              _vm.currentValue === item.value && _vm.$style.itemActive,
              item.disabled && _vm.$style.itemDisabled,
            ],on:{"click":function($event){!item.disabled && _vm.onChange(item)}}},[(_vm.isLocale)?_c('VLocaleLabel',{attrs:{"locale":item.value}}):_c('p',[_vm._v(_vm._s(item.value))])],1)}):_c('div',{class:_vm.$style.noData},[(!_vm.noDataText)?_c('p',[_vm._v("No data")]):_vm._e(),_vm._t("no-data",null,{"else":""})],2)],2)]},proxy:true}])},[_c('v-input',{attrs:{"title":_vm.title,"disabled":_vm.isDisabled,"error":_vm.error,"error-to-show":_vm.errorToShow}},[_c('v-dropdown',{class:[_vm.$style.dropdown, _vm.className, _vm.$style[_vm.color]],attrs:{"icon-size":_vm.iconSize,"color":_vm.color,"class-name":_vm.className,"is-open":_vm.isOpen,"is-disabled":_vm.isLoading || _vm.isDisabled,"no-arrow":_vm.noArrow},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('transition',{attrs:{"name":"slide-up-kit","mode":"out-in"}},[(_vm.isLocale)?_vm._t("locale-label"):_c('span',{key:_vm.currentValue,class:{
                [_vm.$style.placeholder]: _vm.placeholder && !_vm.currentValue,
                [_vm.$style.smallText]: _vm.smallText,
              }},[_vm._v(" "+_vm._s(_vm.currentValue || _vm.placeholder)+" ")])],2),_c('overlay-loading-loader',{attrs:{"is-loading":_vm.isLoading,"delay":0,"size":5}})]},proxy:true}],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }