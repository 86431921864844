/* eslint-disable camelcase */
import { isChannel, isChat, isChatCommon, isChatPersonal } from '@helpers/chats'

import { Getters } from '@modules/chat/types'
import { ClientType } from '@services/client/types'

import { Message } from '@common-types/chat/chat-message'

const getters: Getters = {
  chat: (state) => state.chat,
  chatApp: (state, getters) => state.chat?.app ?? getters.chatPromptApp ?? null,
  chatId: (state) => state.chat?.id ?? null,
  chatName: (state) => state.chat?.name ?? null,
  chatType: (state) => state.chat?.type ?? null,
  chatIsSubscribed: (state) => state.chat?.is_subscribed ?? null,
  chatChannelSubscription: (state) => state.chat?.channel_subscription ?? null,
  chatMessages: (state) => [...state.messages, ...state.tempMessages],
  chatMessagesLength: (_, getters) => getters.chatMessages?.length ?? null,
  nextPage: (state) => state.nextPage,
  prevPage: (state) => state.prevPage,
  chatTempMessages: (state) => state.tempMessages,
  getTempMessage: (state) => (tempId: string) =>
    state.tempMessages.find((m) => m.tempId === tempId),
  chatUnreadMessageCount: (state) => state.chat?.channel_subscription?.new_message_count ?? 0,
  chatPartner: (state, getters, rootState, rootGetters) =>
    getters.isChatPersonal
      ? state.chat?.client?.id === rootGetters['Client/clientId']
        ? state.chat?.recipient
        : state.chat?.client
      : null,
  chatPartnerId: (s, getters) => getters.chatPartner?.id ?? null,
  chatPartnerName: (s, getters) => getters.chatPartner?.name ?? null,
  isChat: (state) => isChat(state.chat?.type),
  isChannel: (state) => state.chat?.type && isChannel(state.chat.type),
  isChatCommon: (state) => state.chat?.type && isChatCommon(state.chat?.type),
  isChatPersonal: (state) => state.chat?.type && isChatPersonal(state.chat?.type),
  isChatPartnerOwner: (state, g, rootState, rootGetters) =>
    g.chatPartner?.id === rootGetters['App/appClientId'](),
  isChatClientOwner: (state, g, rootState, rootGetters) =>
    state.chat?.client?.id === rootGetters['Client/clientId'],
  getIsMyMessage: (s, g, rootState, rootGetters) => (message: Message) =>
    message?.client?.id === rootGetters['Client/clientId'],
  getChatAvatar: (state, getters, rs, rootGetters) => (type: 'content_url' | 'preview_url') => {
    const image =
      state.tempAvatar ?? (getters.isChatPersonal && getters.isChatPartnerOwner)
        ? rootGetters['App/appAvatar']()
        : getters.isChatPersonal
        ? getters.chatPartner?.avatar
        : state.chat?.image
    return image?.[type] ?? null
  },
  tempAvatar: (state) => state.tempAvatar,
  selectedMessageId: (state) => state.selectedMessageId,
  selectedMessage: (state) =>
    state.messages?.find(({ id }) => id === state.selectedMessageId) ??
    state.tempMessages?.find(({ tempId }) => tempId === state.selectedMessageId),
  referencedMessageId: (state) => state.referencedMessageId,
  referencedMessage: (state) =>
    state.messages?.find(({ id }) => id === state.referencedMessageId) ??
    state.tempMessages?.find(({ tempId }) => tempId === state.referencedMessageId),
  hasReferencedMessageMedia: (state, getters) => !!getters.referencedMessage?.media_objects?.length,
  chatMessageIsReply: (state) => state.isReply,
  chatMessageIsEdit: (state) => state.isEdit,
  getChatMessage: (state) => (messageId: number | string) =>
    state.messages?.find(({ id }) => id === messageId) ??
    state.tempMessages?.find(({ tempId }) => tempId === messageId),
  pinnedMessages: (state) => state.pinnedMessages,
  pinnedMessagesPointer: (state) => state.pinnedMessagesPointer,
  currentPinnedMessage: (state, getters) =>
    getters.pinnedMessages[getters.pinnedMessagesPointer] ?? null,
  hasPinnedMessages: (state) => !!state.pinnedMessages?.length,
  hasPinnedMessage: (state) => (messageId: number) =>
    state.pinnedMessages?.some(({ id }) => id === messageId) ?? false,
  localId: (state) => state.localId,
  afterGoToPinnedMessage: (state) => state.afterGoToPinnedMessage,
  isChatGPTBot: (state, getters, rs, rootGetters) =>
    getters.chatPartner?.type === ClientType.BOT && rootGetters['App/isMyAppGPT'],
  isClientBotFromCurrentApp: (state, getters, rs, rootGetters) =>
    getters.isChatGPTBot && getters.chatPartner?.owner_app?.name === rootGetters['App/appName'](),
  chatIsLoading: (state) => state.isChatLoading,
  chatGptVersion: (state) => state.chat?.gpt_version ?? null,
  gptVersion: (state) => state.gptVersion,
  isChatTemp: (state) => state.chat?.temp ?? false,
  chatIsDemoMode: (state) => state.isDemoMode,
  chatSecretKey: (state) => state.chat?.secret_key ?? null,
  isPromptBung: (s, getters) => {
    const { temp, is_prompt } = getters.chat ?? {}
    return temp && is_prompt
  },
  chatPrompt: (s, getters) => getters.chat?.chat_gpt_system_prompt,
  isPromptChat: (s, getters) => !!getters.chatPrompt,
  isPrompt: (s, getters) => getters.isPromptBung || getters.isPromptChat,
  promptAvatar: (s, getters) => getters.chatPrompt?.image,
  promptName: (s, getters) => getters.chatPrompt?.name,
  promptMessage: (s, getters) => getters.chatPrompt?.empty_bot_chat_message,
  chatPrompts: (s, getters) => getters.chatPrompt?.prompts,
  chatPromptApp: (s, getters) => getters.chatPrompt?.app,
  chatPromptModel: (s, getters) => getters.chatPrompt?.model,
}

export default getters
