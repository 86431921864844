/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unpin': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.697 10.184C12.591 8.289 14.966 7.59 16 8.624L7.889.513c1.034 1.034.335 3.408-1.56 5.303-1.895 1.896-4.27 2.594-5.303 1.56l3.743 3.744-3.743 3.743.624.624 3.743-3.744 3.744 3.744c-1.034-1.034-.336-3.408 1.56-5.303zM3.482 8.586c1.133-.304 2.36-1.035 3.47-2.146 1.11-1.11 1.842-2.337 2.145-3.47l4.445 4.445c-1.133.303-2.36 1.034-3.47 2.145-1.11 1.11-1.843 2.337-2.146 3.47L3.483 8.586z" _fill="#000"/><path pid="1" d="M.889 2.513l.748-.749 12.728 12.728-.748.749L.889 2.513z" _fill="#000"/>'
  }
})
