import { Getters } from './types'

const getters: Getters = {
  verification: (state) => state.verification,
  verificationIsDraft: (state) => state.verification?.status === 4 ?? false,
  verificationData: (state) => state.verificationData,
  step: (state) => state.step,
  code: (state) => state.code,
  mode: (state) => state.mode,
  verificationId: (state) => state.verificationId,
}

export default getters
