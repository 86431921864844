// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AccessDeniedDrawer_container_3jYTU{width:100%;display:flex;flex-direction:column;align-items:center;gap:25px;padding:25px 15px 15px 15px}.AccessDeniedDrawer_container_3jYTU .AccessDeniedDrawer_text_3QnCv{text-align:center}", ""]);
// Exports
exports.locals = {
	"container": "AccessDeniedDrawer_container_3jYTU",
	"text": "AccessDeniedDrawer_text_3QnCv"
};
module.exports = exports;
