// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileVerification_section_h9fQ3{display:grid;grid-template-columns:auto 1fr;margin:6px;overflow:hidden;background:#262626;border-radius:6px}.ProfileVerification_aside_FsyoG{display:grid;grid-template-rows:auto 1fr;padding:15px 10px;background-color:#000;row-gap:15px}.ProfileVerification_content_1Pant{display:grid;grid-auto-flow:row;padding:12px 15px;row-gap:6px}.ProfileVerification_icon_1WuIL{justify-self:center;width:18px;height:16px;fill:var(--supster-primary)}.ProfileVerification_image_1E6v3{align-self:flex-end;width:28px;height:92px}.ProfileVerification_title_TO2dC{color:var(--supster-primary);font-weight:500;font-size:15px;line-height:22px}.ProfileVerification_text_1QuP9{color:#fff;font-weight:400;font-size:12px;line-height:18px}.ProfileVerification_text_1QuP9.ProfileVerification_dark_2ayDU{color:#a09f9f}.ProfileVerification_wrapper_2PJys{display:flex;margin-top:6px}.ProfileVerification_link_3WrHs{display:grid;grid-template-columns:repeat(2,max-content);color:var(--supster-primary);font-weight:500;font-size:12px;line-height:18px;-moz-column-gap:6px;column-gap:6px;transition:opacity .3s}.ProfileVerification_link_3WrHs:hover{opacity:.7}", ""]);
// Exports
exports.locals = {
	"section": "ProfileVerification_section_h9fQ3",
	"aside": "ProfileVerification_aside_FsyoG",
	"content": "ProfileVerification_content_1Pant",
	"icon": "ProfileVerification_icon_1WuIL",
	"image": "ProfileVerification_image_1E6v3",
	"title": "ProfileVerification_title_TO2dC",
	"text": "ProfileVerification_text_1QuP9",
	"dark": "ProfileVerification_dark_2ayDU",
	"wrapper": "ProfileVerification_wrapper_2PJys",
	"link": "ProfileVerification_link_3WrHs"
};
module.exports = exports;
