// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Notification_notification_3vkRh{padding:15px 15px 6px 15px;display:flex;flex-direction:column;gap:15px;align-items:center;background:#fff;border-radius:10px;border:1px solid #e5e3e3;box-shadow:0 2px 5px rgba(0,0,0,.2)}.Notification_notification_3vkRh.Notification_reply_DfnQq{padding-bottom:15px;background:#fff}.Notification_notification_3vkRh .Notification_container_6R5O6{width:100%;display:flex;align-items:center;gap:9px;overflow:hidden}.Notification_notification_3vkRh .Notification_container_6R5O6 .Notification_avatar_2tfWC{flex-grow:0;flex-shrink:0}.Notification_notification_3vkRh .Notification_container_6R5O6 .Notification_content_349aO{flex:1;display:flex;flex-direction:column;gap:3px;overflow:hidden}.Notification_notification_3vkRh .Notification_container_6R5O6 .Notification_content_349aO .Notification_text_1805J{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.Notification_notification_3vkRh .Notification_container_6R5O6 .Notification_content_349aO .Notification_title_2sbyn{font-weight:500;font-size:13px;line-height:13px}.Notification_notification_3vkRh .Notification_container_6R5O6 .Notification_content_349aO .Notification_body_2v62m{font-size:12px;line-height:12px}.Notification_notification_3vkRh .Notification_actionBar_32qET{width:42px;height:4px;border-radius:2px;background:#e5e3e3}", ""]);
// Exports
exports.locals = {
	"notification": "Notification_notification_3vkRh",
	"reply": "Notification_reply_DfnQq",
	"container": "Notification_container_6R5O6",
	"avatar": "Notification_avatar_2tfWC",
	"content": "Notification_content_349aO",
	"text": "Notification_text_1805J",
	"title": "Notification_title_2sbyn",
	"body": "Notification_body_2v62m",
	"actionBar": "Notification_actionBar_32qET"
};
module.exports = exports;
