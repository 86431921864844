var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.page,style:(_vm.cssVariables),attrs:{"id":"supster-app-container"}},[_c('AppLeftSection'),(_vm.isInstallPage)?_c('router-view'):_c('div',{class:[
      _vm.$style.container,
      !_vm.isMobile && _vm.$style.desktop,
      _vm.isMobile && _vm.$style.mobile,
      'lightgallery-container',
    ]},[(!_vm.isLoggedIn)?_c('transition',{attrs:{"name":"page","mode":"out-in","appear":""}},[_c('router-view')],1):_c('CentrifugeProvider',[_c('div',{class:[
          _vm.$style.content,
          _vm.pageIsMenu && _vm.$style.open,
          !_vm.pageIsHeader && _vm.$style.noHeader,
          !_vm.pageIsFooter && _vm.$style.noFooter,
          !_vm.pageIsHeader && !_vm.pageIsFooter && _vm.$style.fullHeight,
        ]},[(_vm.pageIsHeader)?_c('HeaderWrapper'):_vm._e(),_c('VMain'),(_vm.pageIsFooter)?_c('VFooter'):_vm._e(),(!_vm.isDesktop)?_c('VMenu'):_vm._e(),_c('CartDrawer'),_c('NoNameDrawer'),_c('CommentEditDrawer'),_c('CommentReportDrawer'),_c('AuthDrawer'),_c('AdminNoVerified'),_c('ClaimAcceptedDrawer'),_c('AccessDeniedDrawer'),_c('ClaimConfirmDrawer'),_c('BlockingDrawer'),_c('PermissionDrawer'),_c('Notifications'),_c('ChatNameEditDrawer'),_c('SubscriptionExpiredDrawer'),_c('LocalesDrawer')],1)]),_c('Toasts')],1),_c('AppRightSection')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }