<template>
  <v-button
    :class="$style.button"
    size="sm"
    color="white"
    :is-div="isDiv"
    :is-disabled="isDisabled"
  >
    <transition name="slide-up-kit" mode="out-in">
      <div v-if="isVideo" :key="isVideo">
        <transition name="slide-up-kit" mode="out-in">
          <icon
            v-if="isMuted"
            :key="isMuted"
            name="muted"
            width="12"
            height="12"
            :class="$style.icon"
          />
          <icon v-else :key="isMuted" name="unmuted" width="12" height="12" :class="$style.icon" />
        </transition>
      </div>
      <icon v-else name="playsimple" :class="$style.icon" width="12" height="12" />
    </transition>
  </v-button>
</template>

<script>
import VButton from '@elements/VButton.vue'

export default {
  name: 'VVideoButton',
  components: {
    VButton,
  },
  props: {
    isVideo: Boolean,
    isMuted: Boolean,
    isDisabled: Boolean,
    isDiv: Boolean,
  },
}
</script>

<style lang="scss" module>
.button {
  position: absolute !important;
  left: 3px;
  //z-index: 102 !important;
  width: auto !important;
}

.icon {
  display: block;
  fill: currentColor;
}
</style>
