// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VHeaderLeft_back_2d9Wz,.VHeaderLeft_dropdown_3qUQ-{position:absolute}.VHeaderLeft_dropdown_3qUQ-{top:0;left:0;min-width:200px}.VHeaderLeft_button_BKKoE{align-self:center;width:45px;height:45px}.VHeaderLeft_button_BKKoE svg{fill:var(--supster-main-text-color)}.VHeaderLeft_buttonEdit_3JgmG{width:auto;margin-left:5px}.VHeaderLeft_avatar_PmYTM{position:absolute!important;top:6px;right:6px}.VHeaderLeft_dots_3sEuP{align-self:center;justify-self:flex-start;width:4px;height:18px;color:var(--supster-main-text-color)}", ""]);
// Exports
exports.locals = {
	"back": "VHeaderLeft_back_2d9Wz",
	"dropdown": "VHeaderLeft_dropdown_3qUQ-",
	"button": "VHeaderLeft_button_BKKoE",
	"buttonEdit": "VHeaderLeft_buttonEdit_3JgmG",
	"avatar": "VHeaderLeft_avatar_PmYTM",
	"dots": "VHeaderLeft_dots_3sEuP"
};
module.exports = exports;
