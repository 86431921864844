/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-chat-channel': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M16 0H2a2 2 0 00-2 2v10a2 2 0 002 2h8l6 4v-4a2 2 0 002-2V2a2 2 0 00-2-2zm1 12c0 .551-.449 1-1 1h-1v3.131l-4.445-2.963-.252-.168H7V8c0-.551.449-1 1-1h9v5zm0-6H8a2 2 0 00-2 2v5H4V5c0-.551.449-1 1-1h12v2zM5 3a2 2 0 00-2 2v8H2c-.551 0-1-.449-1-1V2c0-.551.449-1 1-1h14c.551 0 1 .449 1 1v1H5z"/>'
  }
})
