// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginOptions_button_2H5kx,.LoginOptions_wrap_3Ny7r{width:100%}.LoginOptions_button_2H5kx{display:flex;align-items:center;justify-content:space-between;padding:16px 18px 16px 15px;font-size:15px;line-height:14px;background:#f8f8f8;border:1px solid #e5e3e3;cursor:pointer}.LoginOptions_button_2H5kx:first-child{margin-bottom:8px}.LoginOptions_button_2H5kx:last-child{margin-bottom:15px}.LoginOptions_icon_3ubUe{transform:rotate(180deg)}", ""]);
// Exports
exports.locals = {
	"button": "LoginOptions_button_2H5kx",
	"wrap": "LoginOptions_wrap_3Ny7r",
	"icon": "LoginOptions_icon_3ubUe"
};
module.exports = exports;
