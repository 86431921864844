<template>
  <header>
    <div :class="[$style.header, isSearchPage && $style.search]">
      <search-input
        v-if="isSearchPage"
        v-model="search"
        :placeholder="`${$t('navigation.search')}...`"
        @input="onSearchInput"
        @focus="$bus.$emit('search-input-focus', $event)"
        @close="onSearchClose"
      />
      <template v-else-if="isChatPage && (isChannel || isChatCommon)">
        <v-header-left />
        <v-header-channel />
      </template>
      <template v-else>
        <v-header-left />
        <h3 @click="copyLink" :class="[$style.title, isAppTitle && $style.appName]">
          {{ pageTitle }}
        </h3>
        <v-header-right />
      </template>
    </div>
    <Instruction
      :is-gpt="isMyAppGPT"
      v-if="isInstruction && !isChatPage && !isNewChatGpt && !isPromotionalPage && user"
    />
  </header>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { Clipboard } from 'v-clipboard'
import Instruction from '@/components/install/Instruction.vue'
import SearchInput from '@elements/inputs/list/SearchInput.vue'
import VHeaderLeft from '@layouts/header/list/VHeaderLeft.vue'
import VHeaderRight from '@layouts/header/list/VHeaderRight.vue'
import VHeaderChannel from '@layouts/header/list/VHeaderChannel.vue'

export default Vue.extend({
  name: 'VHeader',
  components: { VHeaderRight, VHeaderLeft, VHeaderChannel, SearchInput, Instruction },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    ...mapGetters('App', ['isMyAppGPT']),
    ...mapGetters('Page', ['pageTitle', 'pageTitleType', 'pageBack', 'isPWAInstalled', 'isMobile']),
    ...mapGetters('Chat', ['isChannel', 'isChatCommon']),
    ...mapGetters('User', ['user']),
    ...mapGetters('Client', ['clientId']),
    isInstruction() {
      return this.isMobile && !this.isPWAInstalled
    },
    isChatPage() {
      return this.$route.name === 'Chat'
    },
    isAppTitle() {
      return this.pageTitleType === 'appName'
    },
    isNewChatGpt() {
      return this.$route.name === 'NewGptChat'
    },
    isSearchPage() {
      return this.$route.name === 'Search'
    },
    isPromotionalPage() {
      return this.$route.name === 'Promotional'
    },
  },
  methods: {
    ...mapMutations('Toasts', ['pushToast']),
    ...mapMutations('Search', ['setSearchQuery']),
    copyLink() {
      if (this.isAppTitle) {
        Clipboard.copy(
          `https://${this.pageTitle}.${process.env.VUE_APP_BASE_DOMAIN}?ref=${this.clientId}`
        )
        this.pushToast({
          message: this.$t('linkCopied'),
          type: 'success',
        })
      }
    },
    onSearchInput(event) {
      this.setSearchQuery(event)
      this.$bus.$emit('search-input-input', event)
    },
    onSearchClose() {
      this.setSearchQuery('')
      this.$bus.$emit('search-input-close')
    },
  },
})
</script>
<style lang="scss" module>
.header {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  min-height: $header;
  column-gap: 5px;
  background-color: $primary;
  border-bottom: 3px solid $secondary;
  &.search {
    grid-template-columns: 1fr;
    padding: 4.6px 12px;
  }
}

.title {
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 80%;
  overflow: hidden;
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}

.appName {
  cursor: pointer;
}
</style>
