<template>
  <transition-group name="bottom">
    <v-button
      v-if="chatIsSubscribed"
      size="lg"
      key="notification-button"
      :class="$style.subscribingButton"
      @click="isNotifications = !isNotifications"
    >
      <transition name="slide-up" mode="out-in">
        <span :key="isNotifications">
          {{ isNotifications ? $t('notifications.disable') : $t('notifications.enable') }}</span
        >
      </transition>
    </v-button>
    <v-button
      v-else
      size="lg"
      color="primary"
      key="join-button"
      :is-loading="isSubscribeLoading"
      :class="$style.subscribingButton"
      @click="onJoinChannel"
    >
      {{ $t('channels.join') }}
    </v-button>
  </transition-group>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import { Tab } from '@modules/profile/constants'
import { addSubscribedChat } from '@modules/chats/services'

export default Vue.extend({
  name: 'ChatButtonNotification',

  components: {
    VButton,
  },
  data() {
    return {
      isSubscribeLoading: false,
      isNotifications: false,
    }
  },
  computed: {
    ...mapGetters('Chat', ['chatIsSubscribed', 'chatId']),
    ...mapGetters('Client', ['clientId']),
  },

  methods: {
    ...mapActions('Chat', ['postChatSubscription']),
    ...mapMutations('Chat', ['setChatIsSubscribed']),
    ...mapMutations('Profile', ['setProfileContentItem']),
    ...mapMutations('Chats', ['deleteChatFromChats']),
    ...mapMutations('Search', ['setSearchContentItem']),

    async onJoinChannel() {
      this.isSubscribeLoading = true
      const chat = await this.postChatSubscription({
        channel_id: this.chatId,
        client_id: this.clientId,
      })
      const isSubscribed = !!chat.is_subscribed
      this.setProfileContentItem({ contentItem: chat, tab: Tab.CHATS })
      this.setSearchContentItem({ contentItem: chat, tab: Tab.CHATS })
      if (isSubscribed) {
        addSubscribedChat({ chat, store: this.$store })
      } else {
        this.deleteChatFromChats(chat.id)
      }
      this.setChatIsSubscribed(true)
      this.isSubscribeLoading = false
    },
  },
})
</script>

<style lang="scss" module>
.subscribingButton {
  border-radius: 0 !important;

  &:hover {
    transform: translate(0);
  }

  &Ios {
    padding-bottom: 27px !important;
  }
}
</style>
