// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatShareDrawer_content_3kBw9{overflow-x:hidden;overflow-y:auto;border-top:1px solid #e5e3e3}.ChatShareDrawer_chatName_HlJUN,.ChatShareDrawer_subTitle_1rsau{padding:12px 15px;font-size:12px;line-height:18px;background:#f8f8f8;border-bottom:1px solid #e5e3e3}.ChatShareDrawer_chatName_HlJUN{display:flex;gap:15px;align-items:center;border-top:1px solid #e5e3e3}.ChatShareDrawer_chatName_HlJUN span:first-child{color:#a09f9f}.ChatShareDrawer_chatCreated_OxRAl{display:flex;gap:4px;align-items:center;padding:12px 15px;font-size:12px;line-height:18px;border-bottom:1px solid #e5e3e3}.ChatShareDrawer_footer_27_tM{padding:15px}", ""]);
// Exports
exports.locals = {
	"content": "ChatShareDrawer_content_3kBw9",
	"chatName": "ChatShareDrawer_chatName_HlJUN",
	"subTitle": "ChatShareDrawer_subTitle_1rsau",
	"chatCreated": "ChatShareDrawer_chatCreated_OxRAl",
	"footer": "ChatShareDrawer_footer_27_tM"
};
module.exports = exports;
