<template>
  <div :class="$style.container">
    <ChatGptWelcomeSocialNetworks v-if="isSocialNetworks" />
    <VAvatar
      v-if="isAvatar"
      :src="src"
      :srcset="srcset"
      image-icon="openai"
      size="md-3"
      progress-color="primary"
      color="primary"
    />
    <p v-if="isWelcomeMessage" :class="$style.message">{{ showedMessage }}</p>
    <ChatGptVersionToggle v-if="isGptVersionToggle" />
    <div v-for="({ name, type, items }, index) of presentation" :key="index" :class="$style.block">
      <p :class="$style.title">{{ name }}</p>
      <div :class="$style.items">
        <component
          v-for="({ text, subText, icon, hidden }, index) of items"
          :key="index"
          :is="itemComponents[type]"
          :text="text"
          :hidden="hidden"
          :sub-text="subText"
          :icon="icon || 'lab'"
          @click.native="onClick({ text, type })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import i18n from '@plugins/i18n'
import ChatGptWelcomeSocialNetworks from '@modules/chat/modules/chat-gpt-welcome/ChatGptWelcomeSocialNetworks.vue'
import ChatGptVersionToggle from './ChatGptVersionToggle.vue'
import ChatGptWelcomeRequest from './ChatGptWelcomeRequest.vue'
import ChatGptWelcomeOpportunity from './ChatGptWelcomeOpportunity.vue'

export default Vue.extend({
  name: 'ChatGptWelcome',
  components: {
    ChatGptWelcomeSocialNetworks,
    ChatGptVersionToggle,
    VAvatar,
    ChatGptWelcomeRequest,
    ChatGptWelcomeOpportunity,
  },
  computed: {
    ...mapGetters('Client', ['clientBotWelcomeLogo', 'clientBotWelcomeMessage']),
    ...mapGetters('App', [
      'isSplitTestApp2',
      'isSplitTestApp3',
      'isSplitTestApp4',
      'isSplitTestApp5',
      'appPrompts',
    ]),
    ...mapGetters('Chat', [
      'isPrompt',
      'promptAvatar',
      'promptMessage',
      'chatPrompts',
      'chatPromptModel',
      'isPromptChat',
      'gptVersion',
    ]),
    isSocialNetworks() {
      return this.isSplitTestApp5 && this.isPromptChat
    },
    isAvatar() {
      return !this.isSplitTestApp5 || !this.isPromptChat
    },
    isGptVersionToggle() {
      return !this.isSplitTestApp4 && !this.isSplitTestApp5 && !this.chatPromptModel
    },
    isWelcomeMessage() {
      return !this.isSplitTestApp5 || !this.isPromptChat
    },
    isSplitTest() {
      return this.isSplitTestApp2 || this.isSplitTestApp3
    },
    avatar() {
      return this.isPrompt ? this.promptAvatar : this.clientBotWelcomeLogo
    },
    src() {
      return this.avatar?.content_url ?? ''
    },
    srcset() {
      return this.avatar?.preview_url ?? ''
    },
    availableMessage() {
      return this.promptMessage ?? this.clientBotWelcomeMessage
    },
    message() {
      return this.availableMessage ?? this.$t('gpt.welcome.message')
    },
    showedMessage() {
      return this.isSplitTest ? this.$t('gpt.welcome2.message') : this.message
    },
    appPromptsList() {
      return this.appPrompts?.length ? this.appPrompts?.map((prompt) => ({ text: prompt })) : null
    },
    defaultPresentation() {
      return [
        {
          name: i18n.t('gpt.welcome.presentation1.name'),
          type: 'requests',
          items: this.appPromptsList ?? [
            {
              text: i18n.t('gpt.welcome.presentation1.item1'),
            },
            {
              text: i18n.t('gpt.welcome.presentation1.item2'),
            },
          ],
        },
        {
          name: i18n.t('gpt.welcome.presentation2.name'),
          type: 'opportunity',
          items: [
            {
              text: i18n.t('gpt.welcome.presentation2.item1'),
              icon: 'dialog-bubble',
              hidden: this.gptVersion !== 'midjourney',
            },
            {
              text: i18n.t('gpt.welcome.presentation2.item2'),
              icon: 'arrow-growth',
              hidden: this.gptVersion === 'midjourney',
            },
          ],
        },
      ]
    },
    splitTest5Presentation() {
      return [
        {
          name: i18n.t('gpt.welcome.presentation1.name'),
          type: 'requests',
          items: this.appPromptsList ?? [
            {
              text: i18n.t('gpt.welcome.presentation1.item1'),
            },
            {
              text: i18n.t('gpt.welcome.presentation1.item2'),
            },
          ],
        },
      ]
    },
    splitTest2Presentation() {
      return [
        {
          name: i18n.t('gpt.welcome2.presentation1.name'),
          type: 'requests',
          items: this.appPromptsList ?? [
            {
              text: i18n.t('gpt.welcome2.presentation1.item1'),
            },
            {
              text: i18n.t('gpt.welcome2.presentation1.item2'),
            },
            {
              text: i18n.t('gpt.welcome2.presentation1.item3'),
            },
            {
              text: i18n.t('gpt.welcome2.presentation1.item4'),
            },
          ],
        },
        {
          name: i18n.t('gpt.welcome.presentation2.name'),
          type: 'opportunity',
          items: [
            {
              text: i18n.t('gpt.welcome.presentation2.item2'),
              icon: 'dialog-bubble',
            },
            {
              text: i18n.t('gpt.welcome.presentation2.item3'),
              icon: 'arrow-growth',
            },
          ],
        },
      ]
    },
    presentation() {
      const presentation = this.isSplitTest
        ? this.splitTest2Presentation
        : this.isSplitTestApp5 && this.isPromptChat
        ? this.splitTest5Presentation
        : this.defaultPresentation
      if (this.isPrompt && this.chatPrompts?.length) {
        return presentation.map((section) => {
          if (section.type === 'requests') {
            section.items = this.chatPrompts?.map((text) => ({ text }))
          }
          return section
        })
      }
      return presentation
    },
    itemComponents() {
      return {
        requests: 'ChatGptWelcomeRequest',
        opportunity: 'ChatGptWelcomeOpportunity',
      }
    },
  },
  methods: {
    onClick({ type, text }) {
      if (type === 'requests') {
        this.$emit('click-suggestion', text)
      }
    },
  },
})
</script>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  gap: 21px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 27px 12px 12px 12px;
  color: $secondary;
  .message {
    font-size: 15px;
    line-height: 22px;
    white-space: break-spaces;
    text-align: center;
    word-break: break-word;
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    width: 100%;

    .title {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }

    .items {
      display: flex;
      flex-direction: column;
      gap: 3px;
      width: 100%;
    }
  }
}
</style>
