import Vue from 'vue'
import { DataType as OrderType } from '@services/order/types'
import { Mutations, TabType } from './types'

const mutations: Mutations = {
  setOrders(state, { orders, tab }) {
    Vue.set(state.orders, tab, { data: orders, page: 2 })
  },
  resetOrders(state) {
    const entries = Object.entries(state.orders).map(([key]) => [key, { data: null, page: 1 }])
    state.orders = Object.fromEntries(entries)
  },
  addOrders(state, { orders, tab }: { orders: OrderType[]; tab: TabType }) {
    state.orders[tab].data = [...((state.orders[tab].data as OrderType[]) || []), ...orders]
    state.orders[tab].page++
  },
}

export default mutations
