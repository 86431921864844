// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppRightSectionAction_container_ifuWs{display:flex;align-items:center;gap:6px;cursor:pointer;transition:background-color .2s ease-in-out;padding:5px 10px;border-radius:5px}.AppRightSectionAction_container_ifuWs.AppRightSectionAction_active_2zFqW,.AppRightSectionAction_container_ifuWs:hover{background-color:hsla(0,0%,100%,.3)}.AppRightSectionAction_container_ifuWs .AppRightSectionAction_label_1svsT{font-size:12px;font-weight:400;line-height:18px}.AppRightSectionAction_containerMenu_-l6B5{color:#000}.AppRightSectionAction_containerMenu_-l6B5.AppRightSectionAction_active_2zFqW,.AppRightSectionAction_containerMenu_-l6B5:hover{background-color:rgba(0,0,0,.1)}", ""]);
// Exports
exports.locals = {
	"container": "AppRightSectionAction_container_ifuWs",
	"active": "AppRightSectionAction_active_2zFqW",
	"label": "AppRightSectionAction_label_1svsT",
	"containerMenu": "AppRightSectionAction_containerMenu_-l6B5"
};
module.exports = exports;
