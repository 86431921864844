export default {
  desktopPageLabels: {
    promotional: { label: 'Выберите Подписку', desc: 'Раскройте все функции GPT' },
    chat: { label: '{appName}', desc: 'Model: {version}' },
    supportchat: { label: 'Чат поддержки Supster', desc: '' },
    chats: { label: 'Список чатов', desc: '' },
    profile: { label: 'Профиль', desc: '' },
    subscriptions: { label: 'Подписки', desc: '' },
    subscription: { label: 'Подписка', desc: '' },
    orders: { label: 'Заказы', desc: '' },
    settings: { label: 'Настройки', desc: '' },
    documents: { label: 'Документы', desc: '' },
    feed: { label: 'Лента', desc: '' },
    search: { label: 'Поиск', desc: '' },
    post: { label: 'Пост', desc: '' },
    verification: {
      label: 'Верификация',
      desc: '',
    },
    clientedit: { label: 'Редактирование клиента', desc: '' },
    notifications: { label: 'Уведомления', desc: '' },
    postcreate: {
      label: 'Создание поста',
      desc: '',
    },
  },
}
