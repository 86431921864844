import { Positions, ToastTypes } from './constants'
import { IconParam } from './types'

export default {
  DURATION: 3000,
  MAX_COUNT: 3,
  POSITION: Positions.BOTTOM,
}

export const IconParams: Record<ToastTypes, IconParam> = {
  [ToastTypes.ERROR]: {
    name: 'close',
    color: 'white',
    backgroundColor: 'black',
  },
  [ToastTypes.SUCCESS]: {
    name: 'check',
    color: 'black',
    backgroundColor: 'primary',
  },
  [ToastTypes.INFO]: {
    name: 'info',
    color: 'black',
    backgroundColor: 'primary',
  },
}
