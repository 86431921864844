/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'back': {
    width: 9,
    height: 17,
    viewBox: '0 0 9 17',
    data: '<path pid="0" d="M8.854 16.146a.5.5 0 11-.708.707l-8-8a.498.498 0 010-.707l8-8a.5.5 0 11.708.708L1.207 8.5l7.647 7.646z"/>'
  }
})
