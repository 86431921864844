import { Getters } from './types'

const getters: Getters = {
  filesData: (state) => state.files?.map(({ data }) => data),
  files: (state) => state.files,
  filesValid: (state) => state.filesValid,
  filesLoadParams: (state) => state.filesLoadParams,
  fileLoadParams: (state) => (fileId: string) => state.filesLoadParams?.[fileId] ?? null,
  filesResolution: (state) => state.filesResolution,
  hasFiles: (state) => !!state.files?.length,
  someFilesError: (state) =>
    state.filesLoadParams
      ? Object.values(state.filesLoadParams).some(({ error }) => !!error)
      : false,
  everyFilesComplete: (state) =>
    state.filesLoadParams
      ? Object.values(state.filesLoadParams).every(({ complete }) => !!complete)
      : false,
  filesNotComplete: (state) =>
    state.files?.filter(({ id }) => !state.filesLoadParams?.[id]?.complete) ?? null,
  filesLength: (state) => state.files?.length ?? 0,
  fileProgress: (state) => (fileId: string) => state.filesLoadParams?.[fileId]?.progress ?? 0,
  fileError: (state) => (fileId: string) => state.filesLoadParams?.[fileId]?.error ?? false,
  fileComplete: (state) => (fileId: string) => state.filesLoadParams?.[fileId]?.complete ?? false,
}

export default getters
