// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VModal_container_1sflX{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1001;height:100vh;height:calc(var(--vh, 1vh)*100);padding:15px;background-color:rgba(0,0,0,.6);-webkit-backdrop-filter:blur(3px);backdrop-filter:blur(3px)}.VModal_container_1sflX,.VModal_content_3vrXB{display:grid;width:100%}.VModal_content_3vrXB{position:relative;align-self:center;justify-self:center;max-width:400px;height:auto;max-height:calc(100vh - 30px);max-height:calc(var(--vh, 1vh)*100 - 30px);overflow:auto;color:var(--supster-secondary);border-radius:6px;background:#fff}.VModal_content_3vrXB.VModal_default_aito1{background-color:#fff}.VModal_content_3vrXB.VModal_primary_3VVYI{background-color:var(--supster-primary)}.VModal_title_1Iqoa{width:100%;overflow:hidden;font-weight:500;font-size:20px;line-height:20px;text-align:center}.VModal_description_25emx{font-weight:400;font-size:12px;line-height:18px;text-align:center}.VModal_closeButton_1qU2G{position:absolute!important;top:-30px;right:0;width:20px;height:20px}.VModal_inner_1F_TY{display:grid;padding:15px;row-gap:15px}.VModal_inner_1F_TY.VModal_noPadding_1yd9f{padding:0}.VModal_footer_16aEZ{display:grid;grid-template-columns:repeat(2,1fr);-moz-column-gap:10px;column-gap:10px}", ""]);
// Exports
exports.locals = {
	"container": "VModal_container_1sflX",
	"content": "VModal_content_3vrXB",
	"default": "VModal_default_aito1",
	"primary": "VModal_primary_3VVYI",
	"title": "VModal_title_1Iqoa",
	"description": "VModal_description_25emx",
	"closeButton": "VModal_closeButton_1qU2G",
	"inner": "VModal_inner_1F_TY",
	"noPadding": "VModal_noPadding_1yd9f",
	"footer": "VModal_footer_16aEZ"
};
module.exports = exports;
