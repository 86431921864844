import { VueConstructor } from 'vue'
import config from './config'

export default {
  install(Vue: VueConstructor): void {
    Vue.directive('longpress', {
      bind: (el, binding) => {
        let pressTimer: number | null = null
        let pressed = false
        const {
          value: { handler: longpressHandler, args, defaultHandler },
        } = binding

        if (typeof longpressHandler !== 'function') {
          return
        }

        const start = (event: MouseEvent | TouchEvent) => {
          pressed = false
          const isMouseEvent = event instanceof MouseEvent
          const notMainButton = isMouseEvent && event.button !== 0
          const isAlreadyPressed = pressTimer !== null
          const notCanBeStarted = isAlreadyPressed || notMainButton

          if (notCanBeStarted) {
            pressed = true
            return
          }

          pressTimer = setTimeout(() => {
            pressed = true
            longpressHandler(args)
          }, config.LONGPRESS_DELAY)
        }

        const cancel = () => {
          if (!pressed && typeof defaultHandler === 'function') {
            defaultHandler()
          }
          if (pressTimer === null) {
            return
          }

          clearTimeout(pressTimer)
          pressTimer = null
        }

        el.addEventListener('mousedown', start)
        el.addEventListener('touchstart', start)
        el.addEventListener('click', cancel)
        el.addEventListener('mouseleave', cancel)
        el.addEventListener('touchend', cancel)
        el.addEventListener('touchcancel', cancel)
      },
    })
  },
}
