import { Module } from './types'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const module: Module = {
  state: () => ({
    accessToken: localStorage.getItem('access-token'),
    cId: null,
    stream: null,
    subId: null,
    refId: null,
    isSkipInstall: null,
    isPassInstall: null,
  }),
  getters,
  actions,
  mutations,
  namespaced: true,
}

export default module
