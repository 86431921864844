import { Module, Tab } from '../types'
import getters from './getters'
import mutations from './mutations'
import Posts from '../modules/posts/store'
import Chats from '../modules/chats/store'

const module: Module = {
  state: () => ({
    query: '',
    tab: Tab.POSTS,
  }),
  getters,
  mutations,
  namespaced: true,
  modules: {
    Posts,
    Chats,
  },
}

export default module
