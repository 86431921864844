<template>
  <footer :class="$style.footer">
    <nav :class="[$style.section, isIOS && $style.ios]">
      <ul :class="$style.list">
        <li
          v-for="({ to, value, icon, name }, index) in menu"
          :key="`navigation-${index}`"
          :class="$style.item"
          @click="setLastRouteFromFooter(to)"
        >
          <router-link
            v-if="to"
            :to="Trans.i18nRoute(to)"
            :class="[
              $style.link,
              ($route.path === to || to === pageLastRouteFromFooter) && $style.active,
            ]"
          >
            <icon :class="$style.icon" :name="icon" />
            <span :class="$style.text">{{ value }}</span>
            <span v-if="chatsUnreadCount && name === 'chats'" :class="$style.counter">
              {{ chatsUnreadCount }}
            </span>
          </router-link>
          <v-button v-else color="transparent" :class="$style.button" @click="onMenu">
            <icon :class="[$style.icon, $style.plus]" :name="icon" />
          </v-button>
        </li>
      </ul>
    </nav>
    <upload-menu-drawer />
  </footer>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import UploadMenuDrawer from '@drawers/list/UploadMenuDrawer.vue'
import Config from '@config/index'

import Trans from '@plugins/i18n/translation'

export default Vue.extend({
  name: 'VFooter',
  components: { VButton, UploadMenuDrawer },
  data() {
    return {
      cacheHandlersMapId: null,
      Trans,
    }
  },
  computed: {
    ...mapGetters('App', ['isMyAppGPT', 'isSplitTestApp6']),
    ...mapGetters('Me', ['isLoggedIn']),
    ...mapGetters('Client', ['isClientOwner']),
    ...mapGetters('Chats', ['chatsUnreadCount']),
    ...mapGetters('Page', ['isIOS', 'pageLastRouteFromFooter']),
    menu() {
      return this.isMyAppGPT || this.isSplitTestApp6
        ? [
            {
              to: { name: 'Chats' },
              name: 'chats',
              value: this.$t('navigation.chats'),
              icon: 'chats',
            },
            { to: null, value: null, icon: 'plus' },
            { to: { name: 'MyProfile' }, value: this.$t('navigation.profile'), icon: 'profile' },
          ]
        : [
            { to: { name: 'Feed' }, value: this.$t('navigation.feed'), icon: 'feed' },
            { to: { name: 'Search' }, value: this.$t('navigation.search'), icon: 'search' },
            { to: null, value: null, icon: 'plus' },
            {
              to: { name: 'Chats' },
              name: 'chats',
              value: this.$t('navigation.chats'),
              icon: 'chats',
            },
            { to: { name: 'Profile' }, value: this.$t('navigation.profile'), icon: 'profile' },
          ]
    },
  },
  methods: {
    ...mapActions('Chats', ['getChatsUnreadCount']),
    ...mapMutations('Page', ['setLastRouteFromFooter']),
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Chats', ['setChatsUnreadCount']),
    onMenu() {
      this.setDrawer({ name: 'upload-menu', isVisible: true })
    },
  },
  watch: {
    '$route.name': {
      handler() {
        if (this.$route.name === 'Profile') {
          this.setLastRouteFromFooter('')
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    if (this.isLoggedIn) {
      await this.getChatsUnreadCount()
    }

    this.cacheHandlersMapId = this.$cacheManager.add({
      regExpURL: Config.URLRegExps.NEW_MESSAGE_COUNT,
      cacheHandlers: [
        async (data, url, params) => {
          const { total } = params ?? {}
          if (total > -1) {
            this.setChatsUnreadCount(total)
          }
        },
      ],
    })
  },
  beforeDestroy() {
    if (this.cacheHandlersMapId) {
      this.$cacheManager.remove([this.cacheHandlersMapId])
    }
  },
})
</script>

<style lang="scss" module>
.footer {
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
}

.section {
  position: relative;
  z-index: 500;
  width: 100%;
  background-color: $footer-background;

  &.ios {
    padding-bottom: 18px;
  }
}

.list {
  display: flex;
  justify-content: stretch;
  width: 100%;
}

.item {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.link {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  padding: 12px 0;
  color: $secondary-medium;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  transition: opacity $transition, background $transition;
  row-gap: 12px;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    color: $primary;
    background: $footer-gradient;
  }
}

.icon {
  justify-self: center;
  width: 18px;
  height: 18px;
  color: currentColor;
  transition: color $transition;

  &.plus {
    width: 14px;
    height: 14px;
  }
}

.text {
  justify-self: center;
  color: currentColor;
  font-weight: 400;
  transition: color $transition;
}

.button {
  align-self: center;
  justify-self: center;
  width: auto !important;
  padding: 10px !important;
  color: $secondary-medium !important;
  border: 1px solid $secondary-medium !important;
  border-radius: 100% !important;

  &:hover {
    transform: translate(0);
    opacity: 0.7;
  }
}

.counter {
  position: absolute;
  top: 4px;
  left: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  min-height: 16px;
  padding: 3px;
  color: $main-text-color;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  background-color: $primary;
  border-radius: 8px;
  transform: translateX(2px);
}
</style>
