<template>
  <span>{{ dateGap }}</span>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { formatDateToNow, formatDateToType } from '@utils/formatDate'

export default Vue.extend({
  name: 'VDateGap',
  props: { date: String, type: String },
  computed: {
    ...mapGetters('Locale', ['locale']),
    dateGap() {
      return this.type
        ? formatDateToType(this.date, this.type, this.locale)
        : formatDateToNow(this.date, this.locale)
    },
  },
})
</script>
