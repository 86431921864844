/* eslint-disable camelcase */
import { Getters } from './types'

const getters: Getters = {
  blocks: (state) => state.blocks,
  blockAppsNames: (state) => state.blockAppsNames,
  getIsAppBlocked: (state) => (appName: string) => !!state.blockAppsNames?.includes(appName),
  getBlockId: (state) => (appName: string) => state.appBlockIds?.[appName] ?? null,
}

export default getters
