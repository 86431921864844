<template>
  <transition name="fade-fast-kit">
    <div
      v-if="isLoadingDelayed"
      :class="$style.loader"
      :style="{ width: `${this.size * 5}px`, height: `${this.size * 5}px` }"
    >
      <slot name="icon" />
    </div>
  </transition>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VLoader',
  props: {
    delay: {
      type: Number,
      default: 800,
    },
    size: {
      type: Number,
      default: 32,
    },
    isLoading: Boolean,
  },
  data() {
    return {
      timerId: null,
      isLoadingDelayed: false,
    }
  },
  watch: {
    isLoading: {
      handler(isLoading) {
        if (this.timerId !== null) {
          clearTimeout(this.timerId)
          this.timerId = null
        }

        if (!isLoading) {
          this.isLoadingDelayed = false
          return
        }

        this.timerId = setTimeout(() => {
          this.isLoadingDelayed = true
        }, this.delay)
      },
      immediate: true,
    },
  },
})
</script>
<style lang="scss" module>
.loader {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
