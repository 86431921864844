import i18n from '@plugins/i18n'
import { CountryCodes, countryCodeValues } from '@services/countries/types/country-codes'
import { Getters } from './types'
import { licenseDocumentIds } from '../../constants/licenses'

const getters: Getters = {
  countries(state) {
    if (state.countries) {
      return state.countries.map(({ id, code }) => ({
        id,
        name: code,
        value: i18n.t(`countries.${code}`),
      }))
    }
    return null
  },
  currentCountry: (state) => state.currentCountry,
  currentCountryCode: (state) => {
    const countryCode = state.currentCountry?.code
    const isValidCode = countryCode && countryCodeValues.includes(countryCode)
    return isValidCode ? countryCode : CountryCodes.DEFAULT
  },
  isCurrentCountryRu: (state) => state.currentCountry?.code === 'RU' ?? false,
  isCurrentCountryIndia: (state) => state.currentCountry?.code === 'IN' ?? false,
  userAgreementDocId: (s, getters) =>
    licenseDocumentIds.userAgreements[getters.currentCountryCode as CountryCodes],
  privacyDocId: (s, getters) =>
    licenseDocumentIds.privacyPolicy[getters.currentCountryCode as CountryCodes],
  sellerDocId: (s, getters) =>
    licenseDocumentIds.sellerAgreements[getters.currentCountryCode as CountryCodes],
  combatingLegalizationDocId: (s, getters) =>
    licenseDocumentIds.legalization[getters.currentCountryCode as CountryCodes],
}

export default getters
