// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VMedia_media_1_cmn{width:100%;height:100%}.VMedia_image_3yNSo,.VMedia_media_1_cmn,.VMedia_video_4szYv{position:absolute}.VMedia_image_3yNSo{width:100%;height:100%}", ""]);
// Exports
exports.locals = {
	"media": "VMedia_media_1_cmn",
	"image": "VMedia_image_3yNSo",
	"video": "VMedia_video_4szYv"
};
module.exports = exports;
