<template>
  <VLabeledToggle :items="versions" :default-index="currentIndex" @click-item="onClickItem" />
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { GptVersionLabels } from '@modules/chat/config'
import { GptVersion } from '@common-types/chat'
import VLabeledToggle from '@elements/VLabeledToggle.vue'

export default Vue.extend({
  name: 'ChatGptVersionToggle',
  components: { VLabeledToggle },
  computed: {
    ...mapGetters('Chat', ['gptVersion']),
    ...mapGetters('Client', { clientGptVersion: 'gptVersion' }),
    versions() {
      return [
        { label: this.$t('gpt.label'), value: 'GPT' },
        { label: GptVersionLabels[GptVersion.MIDJOURNEY], value: GptVersion.MIDJOURNEY },
      ]
    },
    currentIndex() {
      return this.gptVersion === GptVersion.MIDJOURNEY ? 1 : 0
    },
  },
  methods: {
    ...mapMutations('Chat', ['setChatGptVersion']),
    onClickItem(version) {
      const v = version === 'GPT' ? this.clientGptVersion : version
      this.setChatGptVersion(v)
    },
  },
})
</script>
