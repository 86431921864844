<template>
  <div :class="$style.section">
    <v-grid :key="chatsName" :length="!!chatsLength" @infinite="onLoadChats">
      <transition-group name="list-complete" tag="div" :style="'display: grid'">
        <v-chat-preview
          v-for="chat of extendedChats"
          :key="`chat-${chat.id}`"
          class="list-complete-item"
          :chat="chat"
          is-channels-create-page
          @remove="onRemoveChannel"
        />
      </transition-group>
      <template #placeholder>
        <p-chat-preview v-for="i in 30" :key="i" />
      </template>
    </v-grid>
    <confirm-modal
      :is-visible.sync="isRemoveModalVisible"
      :title="$t('confirmation')"
      @confirm="onConfirmRemove"
      @close="chat = null"
    />
    <chats-filter-drawer />
    <ChatShareDrawer />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import i18n from '@plugins/i18n'

import { formatChatsDate } from '@utils/formatDate'
import VChatPreview from '@layouts/VChatPreview.vue'

import ConfirmModal from '@modals/list/ConfirmModal.vue'
import VGrid from '@layouts/VGrid.vue'
import ChatsFilterDrawer from '@drawers/list/ChatsFilterDrawer.vue'

import Config from '@config/index'
import ChatShareDrawer from '@modules/chat/modules/chat-sharing/ChatShareDrawer.vue'

export default Vue.extend({
  name: 'Chats',
  components: {
    ChatShareDrawer,
    VChatPreview,
    ConfirmModal,
    VGrid,
    PChatPreview: () =>
      import(/* webpackChunkName: 'skeletons-chunk' */ '@placeholders/PChatPreview.vue'),
    ChatsFilterDrawer,
  },
  data() {
    return {
      chat: null,
      clickedChat: null,
      action: '',
      tariffsList: [],
      infiniteId: +new Date(),
      isRemoveModalVisible: false,
      cacheHandlersMapId: null,
    }
  },
  computed: {
    ...mapGetters('Chats', ['chats', 'extendedChats', 'chatsLength', 'chatsName']),
    ...mapGetters('Chat', ['chatUnreadMessageCount']),
    ...mapGetters('Page', ['pageIsFooter']),
    ...mapGetters('App', ['isSplitTestApp6']),
  },
  async mounted() {
    if (!this.isSplitTestApp6) {
      this.cacheHandlersMapId = this.$cacheManager.add({
        regExpURL: Config.URLRegExps.CHATS,
        cacheHandlers: [
          (data) => {
            if (data?.length) {
              this.setChats({ chatsName: this.chatsName, chats: data })
            } else {
              this.resetChats(this.chatsName)
            }
          },
        ],
      })
    }
    this.setPageTitle({ name: 'navigation.chats' })
    this.setPageBack({
      isDisabled: true,
    })
    if (!this.pageIsFooter) {
      this.setPageIsFooter(true)
    }
  },
  beforeDestroy() {
    this.setIsEditChats(false)
    if (this.cacheHandlersMapId) {
      this.$cacheManager.remove([this.cacheHandlersMapId])
    }
  },
  methods: {
    ...mapMutations('Page', ['setPageTitle', 'setPageBack', 'setPageIsFooter']),
    ...mapMutations('Profile', ['deleteProfileChat']),
    ...mapMutations('Search', ['deleteSearchChat']),
    ...mapMutations('Chats', [
      'setIsEditChats',
      'decreaseChatsUnreadCount',
      'setChats',
      'resetChats',
    ]),
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapActions('Chats', ['getChatsInState']),
    ...mapActions('Chat', ['deleteChat']),
    async onLoadChats(scroll) {
      await this.getChatsInState({ chatsName: this.chatsName, scroll })
    },
    distanceDate(date) {
      return formatChatsDate(date, i18n.locale)
    },
    onRemoveChannel(chat) {
      this.isRemoveModalVisible = true
      this.chat = chat
    },

    onConfirmRemove(isConfirm) {
      if (isConfirm && this.chat) {
        this.deleteChat(this.chat.id)
        this.deleteProfileChat(this.chat.id)
        this.deleteSearchChat(this.chat.id)

        if (this.chat.channel_subscription?.new_message_count) {
          this.decreaseChatsUnreadCount()
        }
      }
      this.chat = null
    },
  },
})
</script>
<style lang="scss" module>
.section {
  width: 100%;
  height: 100%;
}
</style>
