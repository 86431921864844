/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" d="M12.854 12.146a.5.5 0 11-.707.707L6.5 7.208.854 12.854a.498.498 0 01-.708 0 .5.5 0 010-.707L5.793 6.5.146.854A.5.5 0 11.854.146L6.5 5.793 12.146.146a.5.5 0 11.707.708L7.208 6.5l5.647 5.646z"/>'
  }
})
