/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'attention': {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    data: '<path pid="0" d="M18.72 12.92l-6.93-11.9C11.39.34 10.7 0 10 0S8.61.34 8.21 1.03l-6.93 11.9c-.8 1.36.2 3.07 1.79 3.07h13.86c1.59 0 2.59-1.71 1.79-3.08zm-.86 1.55c-.09.16-.37.53-.93.53H3.07c-.56 0-.84-.37-.93-.53-.09-.16-.27-.57 0-1.04l6.93-11.9c.28-.48.74-.53.93-.53.19 0 .65.05.93.53l6.93 11.9c.27.47.09.88 0 1.04zM11 13c0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1 1 .45 1 1zm0-7v4c0 .55-.45 1-1 1s-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1z"/>'
  }
})
