<template>
  <VAvatar
    :src="avatarSrc"
    :srcset="avatarSrcSet"
    size="lg"
    :is-rect="isEditable"
    :progress-color="progressColor"
  >
    <!-- <VButton
      v-if="canChat"
      :class="[$style.button, $style.chat]"
      color="primary"
      is-circle
      @click="checkClientName"
    >
      <icon :class="$style.icon" name="dialog" />
    </VButton> -->
    <VFile
      v-if="isEditable"
      :class="[$style.button, $style.edit]"
      color="transparent"
      :is-disabled-padding="isEditable"
      @files="setChangedAvatar(files)"
      :fileTypes="['image']"
    >
      <icon :class="$style.icon" :name="editIconName" />
    </VFile>
  </VAvatar>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import VAvatar from '@layouts/avatar/VAvatar.vue'
import VButton from '@elements/VButton.vue'
import VFile from '@elements/VFile.vue'

export default Vue.extend({
  name: 'ProfileAvatar',
  components: { VAvatar, VFile },
  props: {
    isEditable: Boolean,
  },

  computed: {
    ...mapGetters('App', ['appClientId', 'appAvatarSrc', 'appAvatarSrcset', 'currentApp']),
    ...mapGetters('Client', ['clientName', 'isClientOwner']),
    ...mapGetters('Profile', ['profileClientApp']),
    ...mapGetters('Files', ['files']),
    componentName() {
      return this.isEditable ? 'v-file' : 'v-button'
    },
    editOwner() {
      return this.isEditable && this.isClientOwner
    },
    editClient() {
      return this.isEditable && !this.isClientOwner
    },
    avatarSrc() {
      let src = ''
      if (this.editClient) {
        src = this.profileClientApp?.avatar?.url || ''
      } else if (this.editOwner) {
        src = this.profileClientApp?.avatar
          ? this.profileClientApp?.avatar?.url
          : this.appAvatarSrc(this.currentApp)
      } else {
        src = this.appAvatarSrc(this.currentApp)
      }
      return src
    },
    avatarSrcSet() {
      let src = ''
      if (this.editClient) {
        src = this.profileClientApp?.avatar?.url || ''
      } else if (this.editOwner) {
        src = this.profileClientApp?.avatar
          ? this.profileClientApp?.avatar?.url
          : this.appAvatarSrcset(this.currentApp)
      } else {
        src = this.appAvatarSrcset(this.currentApp)
      }
      return src
    },
    progressColor() {
      return this.isEditable ? 'gradient' : 'primary'
    },
    // canChat() {
    //   return !this.isEditable && !this.isClientOwner
    // },
    editIconName() {
      return this.avatarSrc || this.avatarSrcSet ? 'edit-round' : 'add-2'
    },
  },
  methods: {
    ...mapMutations('Profile', ['setProfileClientApp']),
    ...mapActions('App', ['putApp']),
    ...mapMutations('Drawers', ['setDrawer']),

    setChangedAvatar(files) {
      this.$emit('change')
      this.setProfileClientApp({
        avatar: files[0],
      })
    },
    checkClientName() {
      if (!this.clientName) {
        this.setDrawer({ name: 'noname', isVisible: true })
        this.$bus.$on('noname-confirm', this.onGoToChatWithOwner)
      } else {
        this.onGoToChatWithOwner()
      }
    },
    onGoToChatWithOwner() {
      this.$router.push({
        path: `chats/${this.appClientId(this.currentApp)}`,
        query: { isClientId: true },
      })
    },
  },
})
</script>

<style lang="scss" module>
.button {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  &.chat {
    & .icon {
      width: 13px;
      height: 13px;
    }
  }

  &.edit {
    width: 33px;
    height: 33px;
    fill: none;

    & .icon {
      width: 33px;
      height: 33px;
      fill: none;
    }
  }
}
</style>
