/* eslint-disable camelcase */
import TagApi from '@services/tag'
import { Actions, TabType } from './types'

const actions: Actions = {
  async getTag({ commit, dispatch }, id) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const tag = await TagApi.getTag(id)
      commit('setTag', tag)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async getTagPosts(
    { commit, dispatch, state },
    { scroll, tab, tagName }: { scroll: InfiniteType; tab: TabType; tagName: string }
  ) {
    const posts = await dispatch(
      'Post/getPosts',
      {
        direction: 'desc',
        'exists[products]': false,
        is_accessible: true,
        limit: 12,
        page: state.tagPosts[tab].page,
        random: false,
        search: `#${tagName}`,
        sort_by: tab === 'top' ? 'popular' : 'created',
      },
      { root: true }
    )
    commit('setTagPosts', { posts, tab })
    if (scroll) {
      posts?.length ? scroll.loaded() : scroll.complete()
    }
  },
}

export default actions
