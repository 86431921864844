/* eslint-disable camelcase */
import ClientApi from '@services/client'
import { Actions } from './types'

const actions: Actions = {
  async getClientFromId({ commit, dispatch }, clientId) {
    try {
      const { data } = await ClientApi.getClient(clientId)
      commit('addClient', data)
      return data
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    }
  },
}

export default actions
