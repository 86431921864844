// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PPost_header_2HKCU,.PPost_section_1MSmC{display:grid}.PPost_header_2HKCU{grid-template-columns:54px 1fr;grid-gap:9px;align-items:center;padding:6px 8px}.PPost_avatar_3DqQa{background:#fff;width:54px;height:54px;border:2px solid #f2f2f2;border-radius:45px;padding:2px}.PPost_avatarContent_33nPv{background:#f2f2f2;width:46px;height:46px;border-radius:50%}.PPost_image_1umCu{background:#f2f2f2;width:100%;padding-top:100%}.PPost_content_xW0fh{display:grid;grid-gap:12px;padding:12px}.PPost_name_28iXd,.PPost_title_vvdcN{width:65%;height:12px}.PPost_description_1JqAm,.PPost_name_28iXd,.PPost_title_vvdcN{background:#f2f2f2;border-radius:6px}.PPost_description_1JqAm{width:45%;height:9px}", ""]);
// Exports
exports.locals = {
	"header": "PPost_header_2HKCU",
	"section": "PPost_section_1MSmC",
	"avatar": "PPost_avatar_3DqQa",
	"avatarContent": "PPost_avatarContent_33nPv",
	"image": "PPost_image_1umCu",
	"content": "PPost_content_xW0fh",
	"name": "PPost_name_28iXd",
	"title": "PPost_title_vvdcN",
	"description": "PPost_description_1JqAm"
};
module.exports = exports;
