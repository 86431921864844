<template>
  <div :class="[$style.container, isOpen && $style.open]">
    <v-grid />
  </div>
</template>

<script>
import Vue from 'vue'
import VGrid from '@layouts/VGrid.vue'

export default Vue.extend({
  name: 'MyApps',
  props: { isOpen: Boolean },
  components: { VGrid },
})
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
  transition: transform 0.7s, visibility 0.7s, opacity 0.7s;

  &.open {
    visibility: visible;
    opacity: 1;
    transition: transform 0.7s, visibility 0.7s, opacity 0.7s;
  }
}
</style>
