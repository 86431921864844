import * as Sentry from '@sentry/vue'
import Vue from 'vue'
import router from '@router/index'

export const initSentry = (): void => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_API_SENTRY,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['pwa', window.location.origin, /^\//],
        }),
        new Sentry.Replay(),
      ],
      tracingOptions: {
        trackComponents: true,
        timeout: 500,
        hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
      },

      tracesSampleRate: 1.0,
    })
  }
}

export default { initSentry }
