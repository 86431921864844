// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PPostPreview_item_3gFby{width:100%;padding-top:100%;background:#f2f2f2;box-shadow:inset -1px -1px 0 #fff}.PPostPreview_item_3gFby.PPostPreview_shop_2Q3uj{grid-column:span 2}.PPostPreview_item_3gFby>*{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
exports.locals = {
	"item": "PPostPreview_item_3gFby",
	"shop": "PPostPreview_shop_2Q3uj"
};
module.exports = exports;
