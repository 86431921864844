/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-post': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M16 0H2a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V2a2 2 0 00-2-2zM2 17c-.551 0-1-.449-1-1V2c0-.551.449-1 1-1h8c.551 0 1 .449 1 1v14c0 .551-.449 1-1 1H2zm9.723 0A1.98 1.98 0 0012 16V2a1.98 1.98 0 00-.277-1H13c.551 0 1 .449 1 1v14c0 .551-.449 1-1 1h-1.277zM17 16c0 .551-.449 1-1 1h-1.277A1.98 1.98 0 0015 16V2a1.98 1.98 0 00-.277-1H16c.551 0 1 .449 1 1v14zM4 5l5 4-5 4V5z"/>'
  }
})
