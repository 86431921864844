/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clip': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" d="M13.856 8.145L7.398 14.71A4.275 4.275 0 014.33 16a4.275 4.275 0 01-3.067-1.29c-1.685-1.713-1.685-4.5 0-6.214l2.655-2.698 4.9-4.98c1.074-1.09 2.95-1.09 4.023 0a2.914 2.914 0 01.001 4.07l-7.116 7.231a.498.498 0 01-.707.005.501.501 0 01-.005-.708l7.115-7.231a1.908 1.908 0 000-2.663c-.695-.706-1.904-.706-2.6 0l-4.9 4.98L1.977 9.2a3.443 3.443 0 000 4.806c1.258 1.278 3.45 1.278 4.71 0l6.458-6.564a.501.501 0 01.712.703z"/>'
  }
})
