import { Mutations, ToastOptions } from '../types'
import Config from '../config'
import { createToast } from '../services'

const mutations: Mutations = {
  pushToast(state, options: ToastOptions) {
    const toast = createToast(options)
    const timerId = setTimeout(() => {
      state.toasts = state.toasts.filter((t) => t.id !== toast.id)
      clearTimeout(timerId)
    }, Config.DURATION)
    if (state.toasts.length > 2) {
      const shiftedToast = state.toasts.shift()
      if (shiftedToast?.timerId) {
        clearTimeout(shiftedToast.timerId)
      }
    }
    state.toasts.push(toast)
  },
}

export default mutations
