import CountriesApi from '@services/countries'
import i18n from '@plugins/i18n'
import { Actions } from './types'

const actions: Actions = {
  async getCountries({ commit, dispatch }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const countries = await CountriesApi.getCountries({})
      commit('setCountries', countries)
    } catch (error) {
      dispatch(
        'Error/notify',
        {
          type: 'error',
          title: `${i18n.t('errors.countries.caption')}`,
          text: `${i18n.t('errors.countries.description')}`,
        },
        { root: true }
      )
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async getCurrentCountry({ commit, dispatch }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const country = await CountriesApi.getCurrentCountry()
      commit('setCurrentCountry', country)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      commit('setCurrentCountry', {
        id: 185,
        name: 'Россия',
        code: 'RU',
      })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
