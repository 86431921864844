import { Prompt } from '@modules/prompts/types'
import { DataType as AppType } from '@services/app/types'
import { Client, ClientType } from '@services/client/types'
import { MediaObject } from '@services/media/types'
import { Subscription } from '@common-types/chat/chat-subscription'

export enum ChatType {
  CHANNEL = 1,
  COMMON_CHAT = 2,
  PERSONAL_CHAT = 3,
  PROMOTIONAL_PAGE_CHAT = 4,
  SUPPORT_CHAT = 5,
  APP_SUPPORT_CHAT = 6,
}

export enum GptVersion {
  GPT_3 = '3.5-turbo',
  GPT_4 = '4',
  MIDJOURNEY = 'midjourney',
}

export interface Chat {
  app: AppType | null
  channel_subscription: Subscription
  client: Client
  created: DateType
  description: string | null
  follower_count: number | null
  id: number
  image: MediaObject | null
  is_accessible: boolean
  is_subscribed: boolean
  last_event_time: DateType
  message_count: number
  name: string
  promotional_page_id: number | null
  recipient: {
    avatar: MediaObject | null
    id: number
    name: string
    verified_status?: number
    type: ClientType
  }
  status: number
  type: ChatType
  updated: DateType
  temp?: boolean
  secret_key: string | null
  gpt_version: GptVersion
  chat_gpt_system_prompt: Prompt | null
}

export type Chats = Chat[]
export type ChatId = Chat['id']
export type ChatIds = ChatId[]
export type ChatsObjectMap = Record<ChatId, Chat>

export type TemplateChat = Required<Pick<Chat, 'type'>> &
  Omit<Partial<Chat>, 'id'> & {
    temp: boolean
    is_prompt?: boolean
    prompt?: Prompt
    id: string
  }
