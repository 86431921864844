<template>
  <div :class="$style.section">
    <div :class="$style.header">
      <div :class="$style.avatar">
        <div :class="$style.content"></div>
      </div>
      <div :class="$style.name"></div>
    </div>
    <div :class="$style.image"></div>
    <div :class="$style.content">
      <div :class="$style.title"></div>
      <div :class="$style.description"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'PPost',
  components: {},
})
</script>

<style lang="scss" module>
.section {
  display: grid;
}

.header {
  display: grid;
  grid-template-columns: 54px 1fr;
  grid-gap: 9px;
  align-items: center;
  padding: 6px 8px;
}

.avatar {
  background: white;
  width: 54px;
  height: 54px;
  border: 2px solid $grey;
  border-radius: 45px;
  padding: 2px;

  &Content {
    background: $grey;
    width: 46px;
    height: 46px;
    border-radius: 50%;
  }
}

.image {
  background: $grey;
  width: 100%;
  padding-top: 100%;
}

.content {
  display: grid;
  grid-gap: 12px;
  padding: 12px;
}

.name,
.title {
  background: $grey;
  width: 65%;
  height: 12px;
  border-radius: 6px;
}

.description {
  background: $grey;
  width: 45%;
  height: 9px;
  border-radius: 6px;
}
</style>
