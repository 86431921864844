/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 152,
    height: 120,
    viewBox: '0 0 152 120',
    data: '<path pid="0" class="st0" d="M129.297 24.002h-17.298v-3.938C112 9.001 103 0 91.936 0H60.064C49.001 0 40.001 9 40.001 20.064v3.938H22.703C10.185 24.002 0 35.05 0 48.629v46.744C0 108.953 10.185 120 22.703 120h106.594C141.815 120 152 108.952 152 95.373V48.63c0-13.58-10.185-24.627-22.703-24.627zM144 95.373c0 9.168-6.596 16.627-14.703 16.627H22.703C14.596 112 8 104.541 8 95.373V48.63c0-9.168 6.596-16.627 14.703-16.627h21.298a4 4 0 004-4v-7.938C48 13.412 53.413 8 60.064 8h31.872c6.651 0 12.063 5.412 12.063 12.064v7.938a4 4 0 004 4h21.298c8.107 0 14.703 7.459 14.703 16.627v46.744zM76 40c-15.44 0-28 12.56-28 28s12.56 28 28 28 28-12.561 28-28-12.56-28-28-28zm0 48c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.971 20 20-8.972 20-20 20z"/>'
  }
})
