import { Mutations } from './types'

const mutations: Mutations = {
  setSubscriptions(state, subscriptions) {
    state.subscriptions = subscriptions
    state.page = 2
  },
  resetSubscriptions(state) {
    state.subscriptions = null
    state.page = 1
  },
  addSubscriptions(state, subscriptions) {
    state.subscriptions = [...(state.subscriptions || []), ...subscriptions]
    state.page++
  },
  changeSubscription(state, subscription) {
    state.subscriptions =
      state.subscriptions?.map((item) => (item.id === subscription.id ? subscription : item)) ??
      null
  },
  setCancelingSubscriptionId(state, subscriptionId) {
    state.cancelingSubscriptionId = subscriptionId
  },
}

export default mutations
