import { Getters } from './types'

const getters: Getters = {
  orders: (state) => state.finances,
  noData: (state) => {
    if (state.finances) {
      return !!state.finances.length
    }
    return false
  },
}

export default getters
