/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'limitless': {
    width: 20,
    height: 10,
    viewBox: '0 0 20 10',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.387 5.345a.87.87 0 00-.04.83c.22.45.77.65 1.24.44l1.68-.73c.26-.11.45-.34.52-.6.07-.27 0-.55-.17-.76l-1.13-1.4a.977.977 0 00-1.32-.16c-.2.15-.32.35-.35.57-.38-.26-.71-.6-.96-1.01a5.292 5.292 0 00-3.27-2.35 5.43 5.43 0 00-4 .53c-1.25.71-2.09 1.83-2.42 3.08-.34 1.24-.18 2.6.55 3.78a5.112 5.112 0 003.17 2.28c1.28.31 2.68.17 3.9-.53a.62.62 0 00.24-.87.681.681 0 00-.91-.23c-.91.49-1.94.58-2.87.33-.92-.25-1.74-.83-2.25-1.69-.5-.85-.59-1.82-.33-2.69.25-.86.85-1.63 1.73-2.11.87-.47 1.86-.55 2.75-.31.88.24 1.67.8 2.15 1.62.37.63.86 1.15 1.43 1.57.21.15.42.28.65.4l.01.01zm-2.79-.68a.87.87 0 00.04-.83.95.95 0 00-1.24-.44l-1.68.73c-.26.11-.45.34-.52.6-.07.27 0 .55.17.76l1.13 1.4c.32.39.91.46 1.32.16.2-.15.32-.35.35-.57.38.26.71.6.96 1.01a5.292 5.292 0 003.27 2.35c1.31.32 2.76.17 4.02-.54 1.25-.71 2.09-1.83 2.42-3.08.33-1.24.18-2.6-.56-3.78a5.112 5.112 0 00-3.17-2.28c-1.28-.31-2.68-.17-3.9.53a.62.62 0 00-.24.87c.18.3.59.41.91.23a3.91 3.91 0 012.87-.33c.92.25 1.74.83 2.25 1.69.5.85.59 1.82.33 2.69-.25.86-.85 1.63-1.73 2.11-.87.47-1.86.55-2.75.31-.88-.24-1.67-.8-2.15-1.62a5.25 5.25 0 00-1.43-1.57c-.21-.15-.42-.28-.65-.4h-.02z" _fill="#000"/>'
  }
})
