/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { DataType, GetParams, PostParams } from './types'
import OrderHistoriesApi from './histories'

export default {
  getOrders: async (params: GetParams): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosJWT.get('orders', { params })
    return data === null ? [] : data
  },
  getOrder: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.get(`orders/${id}`)
    return data
  },
  postOrder: async (order: PostParams): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.post('orders', order)
    return data
  },
  putOrder: async (id: number, order: DataType): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.put(`orders/${id}`, order)
    return data
  },
  deleteOrder: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.delete(`orders/${id}`)
    return data
  },
  ...OrderHistoriesApi,
}
