import { Module } from './types'
import actions from './actions'

const module: Module = {
  state: () => ({
    errors: [400, 401, 403, 405, 415, 422, 429, 500],
  }),
  actions,
  namespaced: true,
}

export default module
