import Config from '@config/index'
import { SubCommentsMap } from '../types'
import { ConfiguredParams, Keys } from './types'

type Params<T extends Keys> = ConfiguredParams<'subCommentsMap' | T>

export const pushSubComments = ({
  subCommentsMap,
  subComments,
  parentId,
}: Params<'parentId' | 'subComments'>): {
  updatedSubCommentsMap: SubCommentsMap
  hasMore: boolean
} => {
  const currentSubComments = subCommentsMap[parentId] ?? []
  const updatedSubComments = subComments.filter(
    (subComment) =>
      !currentSubComments.some((currentSubComment) => currentSubComment.id === subComment.id)
  )
  const hasMore =
    !!updatedSubComments?.length && subComments.length >= Config.Comments.SUB_COMMENTS_PAGE_LIMIT
  const updatedSubCommentsMap = {
    ...subCommentsMap,
    [parentId]: [...currentSubComments, ...updatedSubComments],
  }
  return {
    updatedSubCommentsMap,
    hasMore,
  }
}

export const addSubComment = ({
  subCommentsMap,
  subComment,
}: Params<'subComment'>): SubCommentsMap => {
  const parentId = subComment.parent.id
  const currentSubComments = subCommentsMap[parentId] ?? []
  return {
    ...subCommentsMap,
    [parentId]: [subComment, ...currentSubComments],
  }
}

export const replaceSubComment = ({
  subCommentsMap,
  subComment,
}: Params<'subComment'>): SubCommentsMap => {
  const parentId = subComment.parent.id
  const currentSubComments = subCommentsMap[parentId] ?? []
  const updatedSubComments = currentSubComments.map((currentSubComment) =>
    currentSubComment.id === subComment.id ? subComment : currentSubComment
  )
  return {
    ...subCommentsMap,
    [parentId]: updatedSubComments,
  }
}

export const removeSubComment = ({
  subCommentsMap,
  subCommentId,
  parentId,
}: Params<'subCommentId' | 'parentId'>): SubCommentsMap => {
  const currentSubComments = subCommentsMap[parentId]
  if (!currentSubComments) {
    return subCommentsMap
  }
  const updatedSubComments = currentSubComments.filter(
    (subComment) => subComment.id !== subCommentId
  )
  return {
    ...subCommentsMap,
    [parentId]: updatedSubComments,
  }
}

export const removeSubCommentsMapItems = ({
  subCommentsMap,
  parentIds,
}: Params<'parentIds'>): SubCommentsMap => {
  const entries = [...Object.entries(subCommentsMap)]
  const updatedEntries = entries.filter(([id]) => !parentIds.includes(Number(id)))
  return Object.fromEntries(updatedEntries)
}

export default {
  pushSubComments,
}
