// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatLoadingPlaceholder_container_1WDAd{display:flex;flex-direction:column;justify-content:flex-end;height:100%}", ""]);
// Exports
exports.locals = {
	"container": "ChatLoadingPlaceholder_container_1WDAd"
};
module.exports = exports;
