import { Mutations, TabType } from './types'
import { Post } from '../../common-types/post'

const mutations: Mutations = {
  setTag(state, tag) {
    state.tag = tag
  },
  setTagPosts(state, { posts, tab }: { posts: Post[]; tab: TabType }) {
    state.tagPosts[tab].data = [...(state.tagPosts[tab].data || []), ...posts]
    state.tagPosts[tab].page++
  },
  resetTagPosts(state) {
    state.tagPosts.top.data = null
    state.tagPosts.top.page = 1
    state.tagPosts.recent.data = null
    state.tagPosts.recent.page = 1
  },
}

export default mutations
