/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { Tag, GetParams } from './types'

export default {
  getTags: async (params: GetParams): Promise<Tag[]> => {
    const { data }: ResType<Tag[]> = await axiosJWT.get('tags', { params })
    return data === null ? [] : data
  },
  getTag: async (id: number): Promise<Tag> => {
    const { data }: ResType<Tag> = await axiosJWT.get(`tags/${id}`)
    return data
  },
}
