<template>
  <div :class="[$style.container, pageIsMenu && $style.open]">
    <select-dropdown
      name="languages"
      :color="dropdownColor"
      icon-size="lg"
      :class="$style.language"
      :select-value="locale"
      :list="localeList"
      small-text
      is-locale
      @change="onChangeLang"
    >
      <template #locale-label>
        <VLocaleLabel :class="$style.locale" :locale="locale" is-small />
      </template>
    </select-dropdown>
    <AppRightSectionAction
      v-for="({ icon, label, action }, index) of actions"
      :key="index"
      :icon="icon"
      :label="label"
      @click.native="action"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import CommonConfig from '@config/common'
import SelectDropdown from '@dropdowns/list/SelectDropdown.vue'
import Trans from '@plugins/i18n/translation'
import AppRightSectionAction from '@modules/app-right-section/AppRightSectionAction.vue'
import VLocaleLabel from '@components/VLocaleLabel.vue'

export default Vue.extend({
  name: 'AppRightSectionFooter',
  components: { VLocaleLabel, AppRightSectionAction, SelectDropdown },
  computed: {
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Page', ['pageIsMenu']),
    ...mapGetters('Client', ['clientName']),
    localeList() {
      return Trans.supportedLocales.map((item) => {
        return {
          value: item,
          label: this.$t(`locale.${item}`),
          icon: item,
        }
      })
    },
    actions() {
      return [
        {
          icon: 'settings-colored',
          label: this.$t('aside.settings'),
          action: this.onSettings,
        },
        {
          icon: 'question-colored',
          label: CommonConfig.SUPSTER_SUPPORT_EMAIL,
          action: this.onSupport,
        },
      ]
    },
    dropdownColor() {
      return this.pageIsMenu ? 'transparent-light-background' : 'transparent'
    },
  },
  methods: {
    ...mapMutations('Locale', ['setLocale']),
    ...mapMutations('Drawers', ['setDrawer']),
    async onChangeLang(item) {
      const newLocale = item.value
      await Trans.switchLanguage(newLocale)
      try {
        await this.$router.replace({ params: { locale: newLocale }, query: this.$route.query })
      } catch (e) {
        this.$router.push('/')
      }
      this.setLocale(newLocale)
    },
    onSettings() {
      this.$router.push({ name: 'settings', params: { locale: this.locale } })
    },
    onSupport() {
      const link = document.createElement('a')
      link.href = `mailto:${CommonConfig.SUPSTER_SUPPORT_EMAIL}`
      link.click()
      // if (!this.clientName) {
      //   this.setDrawer({ name: 'noname', isVisible: true })
      //   this.$bus.$on('noname-confirm', this.onGoToChatWithOwner)
      // } else {
      //   this.onGoToChatWithOwner()
      // }
    },
    onGoToChatWithOwner() {
      this.$router.push({ name: 'SupportChat', params: { locale: this.locale } })
      this.$bus.$off('noname-confirm')
    },
  },
})
</script>

<style module lang="scss">
.container {
  position: relative;
  z-index: 3;
  display: flex;
  gap: 22px;
  align-items: center;

  @media (max-width: $width) {
    display: none;
  }

  .language {
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    .locale {
      color: $main-text-color;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &.active {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &Label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.open {
  z-index: 6;

  .language {
    .locale {
      color: black;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
