<template>
  <VDrawer name="blocking">
    <div :class="$style.container">
      <div
        :class="$style.background"
        :style="{ backgroundImage: `url(${require('@assets/images/svg/blocking-bg.svg')} )` }"
      />

      <div :class="$style.content">
        <h3 :class="$style.title">{{ $t(`blockingDrawer.${blocking}.content`) }}</h3>

        <p :class="$style.text">
          {{ $t(`blockingDrawer.${blocking}.desc`) }}
        </p>

        <VButton :is-loading="isLoading" color="primary" size="xxl" @click="onClick">
          {{ $t('continue') }}
        </VButton>
      </div>
    </div>
  </VDrawer>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'

export default Vue.extend({
  name: 'BlockingDrawer',
  components: { VDrawer, VButton },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('Drawers', ['drawerParams']),
    isUnblocking() {
      return !!this.drawerParams?.isUnblocking
    },
    blocking() {
      return this.isUnblocking ? 'unblocking' : 'blocking'
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapActions('Blocks', ['postAppBlock', 'deleteAppBlock']),
    onClick() {
      return this.isUnblocking ? this.onUnblock() : this.onBlock()
    },
    async onBlock() {
      this.isLoading = true
      await this.postAppBlock({
        appId: this.drawerParams?.appId,
        appName: this.drawerParams?.appName,
      })
      this.isLoading = false
      if (this.drawerParams?.isPostPage) {
        this.setDrawer({ name: 'blocking', isVisible: false })
        this.$router.push({ name: 'Search' })
      }
      this.setDrawer({ name: 'blocking', isVisible: false })
    },
    async onUnblock() {
      this.isLoading = true
      await this.deleteAppBlock({
        blockId: this.drawerParams?.blockId,
        appName: this.drawerParams?.appName,
      })
      this.isLoading = false
      this.setDrawer({ name: 'blocking', isVisible: false })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 460px;
  background: white;

  .background {
    position: absolute;
    top: 0;
    align-self: center;
    width: 100%;
    height: 445px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (max-height: 850px) {
      background-size: contain;
    }
  }

  .content {
    padding: 15px;

    .title {
      margin-bottom: 21px;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
    }

    .text {
      max-width: 200px;
      margin: 0 auto 15px;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }
}
</style>
