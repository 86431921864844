// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PSearchPostsBlock_container_2Z_br{height:315px;overflow:hidden;grid-template-rows:repeat(2,auto);display:grid;gap:5px;grid-template-columns:1fr 1fr 1fr;background:#fff;border:5px solid #fff;border-bottom:none}.PSearchPostsBlock_container_2Z_br.PSearchPostsBlock_bigLeft_uR_gS{grid-template-columns:1fr 2fr;grid-auto-flow:column}.PSearchPostsBlock_container_2Z_br.PSearchPostsBlock_bigLeft_uR_gS .PSearchPostsBlock_post_30-t2:last-child{grid-row:1/3}.PSearchPostsBlock_container_2Z_br.PSearchPostsBlock_bigRight_2LPQM{grid-template-columns:2fr 1fr}.PSearchPostsBlock_container_2Z_br.PSearchPostsBlock_bigRight_2LPQM .PSearchPostsBlock_post_30-t2:first-child{grid-row:1/3}.PSearchPostsBlock_container_2Z_br .PSearchPostsBlock_post_30-t2{background:#f2f2f2;height:100%;border-radius:5px}", ""]);
// Exports
exports.locals = {
	"container": "PSearchPostsBlock_container_2Z_br",
	"bigLeft": "PSearchPostsBlock_bigLeft_uR_gS",
	"post": "PSearchPostsBlock_post_30-t2",
	"bigRight": "PSearchPostsBlock_bigRight_2LPQM"
};
module.exports = exports;
