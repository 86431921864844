// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PermissionDrawer_container_jrq6B{position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;background:var(--supster-primary)}.PermissionDrawer_container_jrq6B,.PermissionDrawer_logo_3jDzu{color:var(--supster-main-text-color)}.PermissionDrawer_logo_3jDzu{align-self:flex-end;width:92px;height:24px;fill:var(--supster-main-text-color)}.PermissionDrawer_footer_kqrDV{align-items:center;width:100%;padding:0 21px 15px 21px}.PermissionDrawer_title_1HZPL{margin-bottom:21px}.PermissionDrawer_text_2nDjH,.PermissionDrawer_title_1HZPL{text-align:center}.PermissionDrawer_text_2nDjH{max-width:200px;margin:0 auto 45px;font-size:12px;line-height:18px}.PermissionDrawer_background_z1jPd{align-self:center;width:100%;height:100%;background-repeat:no-repeat;background-position:50%;background-size:cover;overflow:hidden;display:flex;justify-content:center;align-items:flex-start}.PermissionDrawer_background_z1jPd svg{flex-shrink:0;color:var(--supster-main-text-color);fill:none;stroke:none;width:550px;height:110%}@media screen and (max-height:850px){.PermissionDrawer_background_z1jPd{background-size:contain}}.PermissionDrawer_content_u-RcZ{display:grid;grid-template-rows:75px 1fr auto;grid-template-columns:1fr;gap:7px;justify-items:center;height:100%}", ""]);
// Exports
exports.locals = {
	"container": "PermissionDrawer_container_jrq6B",
	"logo": "PermissionDrawer_logo_3jDzu",
	"footer": "PermissionDrawer_footer_kqrDV",
	"title": "PermissionDrawer_title_1HZPL",
	"text": "PermissionDrawer_text_2nDjH",
	"background": "PermissionDrawer_background_z1jPd",
	"content": "PermissionDrawer_content_u-RcZ"
};
module.exports = exports;
