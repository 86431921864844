// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VAvatarCount_section_21XR_{position:absolute;top:0;right:0;z-index:2;display:flex;flex-direction:column;align-items:center;justify-content:center;color:var(--supster-primary-light-text-color);font-weight:500;background-color:var(--supster-primary-light-background);border-radius:100%}.VAvatarCount_section_21XR_.VAvatarCount_xs_2X425{min-width:12px;min-height:12px}.VAvatarCount_section_21XR_.VAvatarCount_sm_2UZJd,.VAvatarCount_section_21XR_.VAvatarCount_xs_2X425{padding:2px;font-size:8px}.VAvatarCount_section_21XR_.VAvatarCount_sm_2UZJd{min-width:14px;min-height:14px}.VAvatarCount_section_21XR_.VAvatarCount_md_2tp-z,.VAvatarCount_section_21XR_.VAvatarCount_md-2_2pHRb,.VAvatarCount_section_21XR_.VAvatarCount_md-3_2WZmK{min-width:16px;min-height:16px;padding:3px;font-size:10px}.VAvatarCount_section_21XR_.VAvatarCount_lg_3-HMn{min-width:26px;min-height:26px;padding:4px;font-size:15px}.VAvatarCount_section_21XR_.VAvatarCount_xl_3B5qM{min-width:32px;min-height:32px;padding:6px;font-size:18px}", ""]);
// Exports
exports.locals = {
	"section": "VAvatarCount_section_21XR_",
	"xs": "VAvatarCount_xs_2X425",
	"sm": "VAvatarCount_sm_2UZJd",
	"md": "VAvatarCount_md_2tp-z",
	"md-2": "VAvatarCount_md-2_2pHRb",
	"md-3": "VAvatarCount_md-3_2WZmK",
	"lg": "VAvatarCount_lg_3-HMn",
	"xl": "VAvatarCount_xl_3B5qM"
};
module.exports = exports;
