var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.form,on:{"submit":_vm.onSubmit}},[(_vm.isPhoneType)?_c('mask-input',{class:_vm.$style.input,attrs:{"type":"tel","title":_vm.$t('phone'),"mode":"inner-title","mask":_vm.mask,"masked":"","tokens":_vm.symbolsToken,"isAuthInput":"","placeholder":"..."},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}):_vm._e(),(_vm.isEmailType)?_c('auth-input',{class:_vm.$style.input,attrs:{"type":"email","title":_vm.$t('login.email'),"mode":"inner-title","placeholder":"..."},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}):_vm._e(),_c('auth-input',{class:_vm.$style.input,attrs:{"type":"password","title":_vm.$t('login.password'),"mode":"inner-title","placeholder":"...","forseError":_vm.serverError},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('div',{class:_vm.$style.recovery,on:{"click":function($event){return _vm.$emit('recovery')}}},[_vm._v(" "+_vm._s(_vm.$t('login.forgotPassword'))+" ")]),_c('div',{class:_vm.$style.buttonContainer},[_c('v-button',{class:_vm.$style.button,attrs:{"size":"xl-1","color":"primary","is-disabled":!_vm.isValid || !!_vm.serverError,"is-loading":_vm.isLoading},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('login.enter'))+" ")])],1),_c('i18n',{key:"agreement",class:[_vm.$style.text, _vm.$style.agreement],attrs:{"path":"agreement","tag":"p"},scopedSlots:_vm._u([{key:"privacyPolicy",fn:function(){return [_c('router-link',{class:[_vm.$style.link],attrs:{"to":_vm.Trans.i18nRoute({
            name: 'Documents',
            params: {
              slug: _vm.privacyDocId,
            },
          })},nativeOn:{"click":function($event){return _vm.closeProfileDrawer.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('withPrivacyPolicy'))+" ")])]},proxy:true},{key:"rulesPlatform",fn:function(){return [_c('router-link',{class:[_vm.$style.link],attrs:{"to":_vm.Trans.i18nRoute({
            name: 'Documents',
            params: {
              slug: _vm.userAgreementDocId,
            },
          })},nativeOn:{"click":function($event){return _vm.closeProfileDrawer.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('withRulesPlatform'))+" ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }