var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$style.form,on:{"submit":_vm.onSubmit}},[_c('auth-input',{ref:"email",class:_vm.$style.input,attrs:{"rules":_vm.rules.email,"type":"email","title":_vm.$t('login.email'),"mode":"inner-title","placeholder":"...","isNewPassword":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('auth-input',{ref:"password",class:_vm.$style.input,attrs:{"rules":_vm.rules.password,"type":"password","title":_vm.$t('login.password'),"mode":"inner-title","placeholder":"...","isNewPassword":""},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('auth-input',{class:_vm.$style.input,attrs:{"type":"password","title":_vm.$t('login.repeatPassword'),"mode":"inner-title","placeholder":"...","isNewPassword":"","forseError":_vm.isPasswordMatch || _vm.serverError},model:{value:(_vm.formData.repeatPassword),callback:function ($$v) {_vm.$set(_vm.formData, "repeatPassword", $$v)},expression:"formData.repeatPassword"}}),_c('div',{class:_vm.$style.buttonContainer},[_c('v-button',{class:_vm.$style.button,attrs:{"size":"xl-1","color":"primary","is-disabled":!_vm.isEmptyValid || _vm.isInputsValid || !!_vm.isPasswordMatch || !!_vm.serverError,"is-loading":_vm.isLoading},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('proceed'))+" ")])],1),_c('i18n',{key:"agreement",class:[_vm.$style.text, _vm.$style.agreement],attrs:{"path":"agreement","tag":"p"},scopedSlots:_vm._u([{key:"privacyPolicy",fn:function(){return [_c('router-link',{class:[_vm.$style.link],attrs:{"to":_vm.Trans.i18nRoute({
            name: 'Documents',
            params: {
              slug: _vm.privacyDocId,
            },
          })},nativeOn:{"click":function($event){return _vm.closeProfileDrawer.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('withPrivacyPolicy'))+" ")])]},proxy:true},{key:"rulesPlatform",fn:function(){return [_c('router-link',{class:[_vm.$style.link],attrs:{"to":_vm.Trans.i18nRoute({
            name: 'Documents',
            params: {
              slug: _vm.userAgreementDocId,
            },
          })},nativeOn:{"click":function($event){return _vm.closeProfileDrawer.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('withRulesPlatform'))+" ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }