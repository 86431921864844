/* eslint-disable camelcase */
import { Getters } from './types'

const getters: Getters = {
  subscriptions: (state) => state.subscriptions,
  subscriptionsPage: (state) => state.page,
  subscriptionsLength: (state) => state.subscriptions?.length ?? null,
  cancelingSubscriptionId: (state) => state.cancelingSubscriptionId,
  cancelingSubscription: (state) =>
    state.subscriptions?.find((item) => item.id === state.cancelingSubscriptionId) ?? null,
}

export default getters
