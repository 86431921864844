// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClaimConfirmDrawer_drawer_1xQUe>*{padding:0 15px 15px 15px}.ClaimConfirmDrawer_input_1f_cn{margin:0;padding:0}.ClaimConfirmDrawer_text_1EP0P{font-weight:400;font-size:12px;line-height:18px}.ClaimConfirmDrawer_buttonWrapper_2y2In{padding:15px}", ""]);
// Exports
exports.locals = {
	"drawer": "ClaimConfirmDrawer_drawer_1xQUe",
	"input": "ClaimConfirmDrawer_input_1f_cn",
	"text": "ClaimConfirmDrawer_text_1EP0P",
	"buttonWrapper": "ClaimConfirmDrawer_buttonWrapper_2y2In"
};
module.exports = exports;
