import { Module } from './types'

import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const module: Module = {
  state: () => ({
    tag: null,
    tagPosts: {
      top: {
        data: null,
        page: 1,
      },
      recent: {
        data: null,
        page: 1,
      },
    },
  }),
  getters,
  actions,
  mutations,
  namespaced: true,
}

export default module
