<template>
  <div :class="$style.form" color="primary" @submit="onSubmit">
    <div :class="$style.caption">
      {{ $t('login.emailCaptionCode') }}
    </div>
    <MaskInput
      v-model="code"
      :class="$style.input"
      type="tel"
      :title="$t('code')"
      :mask="['# # # #']"
      :tokens="symbolsToken"
      mode="inner-title"
      placeholder="..."
      :forseError="error || serverError"
      isAuthInput
      @input="$emit('clearError')"
    />
    <div :class="$style.actions">
      <!-- <div :class="[$style.action, $style.changePhone]">
        <VButton color="transparent" @click="$emit('back')">
          <icon name="back" width="9" height="10" />
          {{ email ? 'Заменить емаил' : $t('changePhoneNumber') }}
        </VButton>
        <div :class="$style.desc">{{ email || phone }}</div>
      </div> -->
      <div :class="[$style.action, $style.resend]">
        <VButton color="transparent" :is-disabled="!isValidResend" @click="onResend">
          <icon name="back" width="9" height="10" />
          {{ $t('resend') }}
        </VButton>
        <div v-show="!!time" :class="$style.desc">
          {{ $t('afterTime', [timeString]) }}
        </div>
      </div>
    </div>
    <div :class="$style.buttonContainer">
      <VButton
        size="xl"
        color="primary"
        :is-disabled="!isValid"
        :is-loading="isLoading || isInnerLoading"
        :class="$style.button"
        @click="onSubmit"
      >
        {{ $t('proceed') }}
      </VButton>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MaskInput from '@elements/inputs/list/MaskInput.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'CodeForm',
  components: {
    MaskInput,
    VButton,
  },
  props: {
    phone: String,
    email: String,
    data: Object,
    isLoading: Boolean,
    error: String,
    emailToken: String,
    password: String,
    mode: String,
  },
  data() {
    return {
      code: '',
      symbolsToken: {
        '#': {
          pattern: /[0-9]/,
        },
      },
      isInnerLoading: false,
      time: 0,
      timer: null,
      serverError: '',
    }
  },
  computed: {
    ...mapGetters('Client', ['clientLoginToken', 'clientId']),
    ...mapGetters('Drawers', ['drawerParams']),
    timeString() {
      const minutes = Math.floor(this.time / 60)
      const seconds = Math.floor(this.time % 60)
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    },
    isValid() {
      return !!this.code && this.code.length === 4
    },
    isValidResend() {
      return !this.time
    },
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    ...mapActions('Client', ['postClientCodeAuth', 'putClientEmail']),
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapActions('Order', ['postOrder']),
    async onSubmit() {
      if (this.mode === 'recovery') {
        this.$emit('submit', this.code)
        return
      }
      this.isInnerLoading = true
      this.serverError = ''
      const { error, data } = await this.putClientEmail({
        id: this.clientId,
        params: {
          email: this.email,
          password: this.password,
          email_code: this.code,
          email_token: this.emailToken,
        },
      })
      if (error) {
        this.serverError = error.data.error.includes('code is not correct')
          ? this.$t('errors.login.code')
          : error.data.error
      } else {
        const { app_id, tariff_id, type } = this.drawerParams ?? {}
        this.setDrawer({ name: 'auth', isVisible: false })
        await this.postOrder({
          app_id,
          tariff_id,
          type,
        })
        // eslint-disable-next-line no-undef
        // ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'payment-form-open')
        this.setDrawer({ name: 'payment', isVisible: true })
      }
      this.isInnerLoading = false
    },
    onResend() {
      this.startTimer()
      this.code = ''
      if (this.mode === 'recovery') {
        this.$emit('resend', this.code)
        return
      }
      this.$bus.$emit('resend-code')
    },
    startTimer() {
      this.time = 30
      this.timer = setInterval(() => {
        this.time -= 1
        if (this.time === 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
  },
})
</script>
<style lang="scss" module>
.form {
  grid-auto-rows: min-content;
  grid-auto-flow: row;
}

.inner {
  display: grid;
  row-gap: 9px;
}

.input {
  margin: 0;
  padding: 0;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  * {
    color: #737373 !important;
  }

  .action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    button {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .changePhone {
    align-items: flex-start;
    button {
      justify-content: start;
    }
  }
}

.buttonContainer {
  padding: 15px;
}

.button {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 9px;
}

.caption {
  padding: 15px;
  color: #737373;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  border-top: 1px solid #e5e3e3;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
