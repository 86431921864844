// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatMessageMidjourneyActions_container_3sLjG{display:flex;justify-content:stretch;align-items:center;padding:5px;gap:5px}", ""]);
// Exports
exports.locals = {
	"container": "ChatMessageMidjourneyActions_container_3sLjG"
};
module.exports = exports;
