import { Tab } from './constants'
import { ChatTab, MediaTypes, Pages, PostTab } from './types'

export const postTabs: PostTab[] = [Tab.IMAGES, Tab.VIDEOS, Tab.PRODUCTS]
export const chatTabs: ChatTab[] = [Tab.CHATS]
export const mediaTypes: MediaTypes = {
  [Tab.IMAGES]: 'image',
  [Tab.VIDEOS]: 'video',
  [Tab.PRODUCTS]: 'shop',
  [Tab.CHATS]: 'chat',
}
export const tabs: Tab[] = [...postTabs, ...chatTabs]
export const increasePage = ({ pages, tab }: { pages: Pages; tab: Tab }): Pages => ({
  ...pages,
  [tab]: pages[tab] + 1,
})
export const resetPages = (): Pages => ({
  [Tab.IMAGES]: 1,
  [Tab.VIDEOS]: 1,
  [Tab.PRODUCTS]: 1,
  [Tab.CHATS]: 1,
})
export default {
  postTabs,
  chatTabs,
  mediaTypes,
  tabs,
  increasePage,
  resetPages,
}
