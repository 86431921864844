/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'admin': {
    width: 17,
    height: 13,
    viewBox: '0 0 17 13',
    data: '<path pid="0" d="M15 0H2v10h13V0zm-2 8H4V2h9v6zm4 3v2H0v-2h17z"/>'
  }
})
