import i18n from '@plugins/i18n'

import { Module } from './types'
import mutations from './mutations'
import getters from './getters'

const module: Module = {
  state: () => ({
    locale: i18n.locale,
  }),
  mutations,
  getters,
  namespaced: true,
}

export default module
