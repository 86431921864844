<template>
  <div v-if="!chat.id" :class="$style.blank"></div>
  <div v-else :class="$style.sectionWrapper">
    <div
      :class="[
        $style.section,
        isChannelsCreatePage && $style.sectionLarge,
        newMessageCount && $style.sectionNewMessage,
        isEditChats && (canBeDeleted || canBeShare) && $style.sectionIsEdit,
        canBeShare && canBeDeleted && $style.full,
        canBeShare !== canBeDeleted && $style.half,
      ]"
      @click="onClick"
    >
      <VAvatar
        :src="imageUrl"
        :srcset="imagePreviewUrl"
        size="md-3"
        progress-color="primary"
        :color="avatarColor"
        :count="newMessageCount"
        :image-icon="avatarIcon"
      />
      <div :class="$style.content">
        <div :class="$style.wrapper">
          <h2 :class="$style.name">
            <template v-if="isTags">#</template>
            {{ partnerName }}
          </h2>
          <template>
            <transition name="slide-up" mode="out-in">
              <p :key="chat.follower_count" :class="$style.description">
                <template v-if="isTags"
                  >{{ $t('postsCount', [countToShow(chat.post_count)]) }}
                </template>
                <template v-else
                  >{{
                    isChannelsCreatePage || isBung
                      ? chatDescription
                      : $tc('subscribers', chat.follower_count)
                  }}
                </template>
              </p>
            </transition>
          </template>
        </div>
        <transition name="fade" mode="out-in">
          <template v-if="!isTags && !(isEditChats && canBeDeleted) && !isPromptBung">
            <div v-if="isBung">
              <div v-if="isChatOwner" :class="[$style.wrapper, $style.edit]">
                {{ $t('edit') }}
              </div>
              <div v-else-if="!chat.is_accessible" :class="$style.wrapper">
                <icon :name="iconLock" width="14" height="16" />
              </div>
            </div>
            <div v-else-if="isChannelsCreatePage" :class="$style.wrapper">
              <div :class="$style.date">
                <v-date-gap :date="chat.last_event_time || chat.created" />
              </div>
              <div v-if="isPromotional" :class="$style.privacy">
                <icon :name="iconLock" width="14" height="16" />
              </div>
            </div>
            <div v-else-if="chat.is_accessible" :class="$style.wrapper">
              <transition name="slide-up" mode="out-in">
                <div v-if="chat.is_subscribed">
                  <div :class="$style.subscribed">
                    <icon :class="$style.subscribedIcon" name="check" />
                    <span :class="$style.subscribedText"> {{ $t('subscribed') }}</span>
                  </div>
                </div>
                <VButton
                  v-else
                  size="md"
                  color="primary"
                  :is-loading="isSubscriptionLoading"
                  @click.prevent.stop="onJoinChannel"
                >
                  {{ $t('channels.join') }}
                </VButton>
              </transition>
            </div>
            <div v-else :class="$style.wrapper">
              <icon :name="iconLock" width="14" height="16" />
            </div>
          </template>
        </transition>
      </div>
    </div>
    <VButton
      v-if="canBeShare"
      :class="[$style.button, $style.shareButton]"
      :is-loading="isSubscriptionLoading"
      @click.prevent.stop="onShare"
    >
      <icon name="copy" width="18" height="16" />
    </VButton>
    <VButton
      v-if="canBeDeleted"
      color="black-primary-light"
      :class="[$style.button, $style.removeButton]"
      :is-loading="isSubscriptionLoading"
      @click.prevent.stop="$emit('remove', chat)"
    >
      <icon name="delete" width="16" height="18" />
    </VButton>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { Clipboard } from 'v-clipboard'
import { abbreviateNumber } from '@utils/numbers'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VButton from '@elements/VButton.vue'
import VDateGap from '@layouts/VDateGap.vue'
import { isChatPersonal, isSupportChat } from '@helpers/chats'
import AppApi from '@services/app'
import { getAvatarUrl, getTitle, initProfileAppSupportChat } from '@modules/chat/services/chat'
import supportChatAvatar from '@assets/images/support-avatar.png'
import { ClientType } from '@services/client/types'
import {
  isAppSupportBung,
  isAppSupportChat,
  needInitAppSupportChat,
} from '@modules/chat/helpers/chat'
import { initAppSupportChat } from '@modules/chats/services'
import { alreadyHadSubscribed } from '@views/promotional/list/services/promotional'

export default Vue.extend({
  name: 'VChatPreview',
  components: { VButton, VAvatar, VDateGap },
  props: {
    chat: {
      type: Object,
      default: () => ({}),
    },
    isChannelsCreatePage: Boolean,
    isTags: Boolean,
  },
  data() {
    return {
      isSubscriptionLoading: false,
    }
  },
  computed: {
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Client', ['client', 'clientName', 'clientId', 'isClientOwner']),
    ...mapGetters('App', [
      'app',
      'appId',
      'appClientId',
      'appAvatar',
      'appName',
      'otherApp',
      'appClientIsVerified',
      'isMyAppGPT',
      'appColor',
    ]),
    ...mapGetters('Chats', ['isEditChats']),
    ...mapGetters('Page', ['pageTitle']),
    chatDescription() {
      return this.isPrompt ? this.promptMessage : this.chat?.description
    },
    isAppSupportChat() {
      return isAppSupportChat(this.chat.type)
    },
    avatarColor() {
      return this.isTags ? 'primary' : ''
    },
    isChatGPTBot() {
      return this.chat?.recipient?.type === ClientType.BOT && this.isMyAppGPT
    },
    avatarIcon() {
      return this.isChatGPTBot ? 'openai' : this.isTags ? 'hashtag' : ''
    },
    imageUrl() {
      if (this.isPromptBung) {
        return this.chat?.image?.preview_url
      }
      if (this.isPromptChat) {
        return this.chatPrompt?.image?.preview_url
      }
      if (this.isChatGPTBot) {
        return ''
      }
      if (isSupportChat(this.chat?.type)) {
        return supportChatAvatar
      }
      return getAvatarUrl(this.chat)
    },
    imagePreviewUrl() {
      if (this.isChatGPTBot) {
        return ''
      }
      if (isSupportChat(this.chat?.type)) {
        return supportChatAvatar
      }
      return getAvatarUrl(this.chat)
    },
    isChatPersonal() {
      return isChatPersonal(this.chat?.type)
    },
    iconName() {
      return this.chat.is_accessible ? 'closeLock' : 'openLock'
    },
    partner() {
      if (
        this.chat?.recipient?.id === this.clientId &&
        this.chat?.recipient?.id !== this.chat?.client?.id
      ) {
        return this.chat?.client
      }
      if (
        this.chat?.client?.id === this.clientId &&
        this.chat?.client?.id !== this.chat.recipient?.id
      ) {
        return this.chat?.recipient
      }
      return undefined
    },
    partnerName() {
      if (this.isChatGPTBot && this.chat?.message_count === 0) {
        return this.$t('gpt.newChat')
      }
      if (isSupportChat(this.chat?.type)) {
        return this.$t('chat.support')
      }
      if (this.isPromptChat) {
        return this.chat?.name
      }
      return getTitle(this.chat)
    },
    isPromotionalPage() {
      return this.$route.name === 'Promotional'
    },
    routerLink() {
      if (this.isPromotionalPage) {
        return null
      }
      if (this.isTags) {
        return { name: 'Tag', params: { id: this.chat.id, locale: this.locale } }
      }
      if (this.chat.is_accessible) {
        return isSupportChat(this.chat.type)
          ? { name: 'SupportChat' }
          : { name: 'Chat', params: { id: this.chat?.id, locale: this.locale } }
      }
      return {
        name: 'Promotional',
        params: { id: this.chat.promotional_page_id, locale: this.locale },
        query: { chat_id: this.chat.id },
      }
    },
    newMessageCount() {
      return this.chat?.channel_subscription?.new_message_count ?? 0
    },
    isAnotherApp() {
      return this.chat?.app && this.chat?.app?.name !== this.appName()
    },
    canBeDeleted() {
      return ((this.isClientOwner && !this.isAnotherApp) || this.canBeShare) && !this.isPromptBung
    },
    canBeShare() {
      return this.isChatGPTBot && this.isMyAppGPT
    },
    isPromotional() {
      return !((this.chat?.promotional_page_id ?? -1) < 0)
    },
    iconLock() {
      return this.chat?.is_accessible && (this.isAnotherApp || !this.isClientOwner)
        ? 'lock-open'
        : 'lock-gradient'
    },
    isBungForOwner() {
      return this.isBung && this.isChatOwner
    },
    isChatOwner() {
      return this.isClientOwner && this.appClientId(this.chat.app) === this.appClientId()
    },
    isBung() {
      return isAppSupportBung(this.chat)
    },
    isChatsPage() {
      return this.$route.name === 'Chats'
    },
    isPromptBung() {
      const { temp, is_prompt } = this.chat
      return temp && is_prompt
    },
    chatPrompt() {
      return this.chat?.chat_gpt_system_prompt
    },
    isPromptChat() {
      return !!this.chatPrompt
    },
    promptMessage() {
      return this.chatPrompt?.empty_bot_chat_message
    },
    isPrompt() {
      return this.isPromptBung || this.isPromptChat
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Chat', ['setChat', 'setIsDemoMode']),
    ...mapMutations('Tag', ['setTag']),
    ...mapMutations('Modals', ['setModal']),
    ...mapActions('Chat', ['postChatSubscription']),
    ...mapActions('Chats', ['unshiftChat']),
    ...mapMutations('Chats', ['setIsEditChats']),
    ...mapMutations('Toasts', ['pushToast']),
    ...mapActions('Promotional', ['getPromotional']),
    countToShow(count) {
      return abbreviateNumber(count, this.locale)
    },
    async onJoinChannel() {
      if (this.chat) {
        this.isSubscriptionLoading = true
        const subscription = await this.postChatSubscription({
          channel_id: this.chat.id,
          client_id: this.client.id,
        })
        const chat = { ...this.chat, channel_subscription: subscription, is_subscribed: true }
        this.isSubscriptionLoading = false
        this.$emit('joined', chat)
      }
    },
    onClick() {
      if (this.isBungForOwner) {
        return
      }
      if (!this.clientName) {
        this.setDrawer({ name: 'noname', isVisible: true, params: this.chat?.id ?? null })
        this.$bus.$on('noname-confirm', this.goToChat)
      } else {
        this.goToChat(this.chat?.id)
      }
    },
    async goToChat(chatId) {
      this.$bus.$off('noname-confirm')
      if (chatId !== this.chat?.id) {
        return
      }
      if (this.routerLink) {
        if (this.chat.is_accessible) {
          if (this.isTags) {
            this.setTag(this.chat)
            this.$router.push(this.routerLink).catch()
            return
          }
          if (this.chat.temp) {
            const appSupportChat = await AppApi.getAppSupportChatExistence(
              this.appId(this.chat?.app)
            )
            if (appSupportChat) {
              this.setChat(appSupportChat)
              await this.unshiftChat(appSupportChat)
              this.$router.push({ name: 'Chat', params: { id: appSupportChat.id } }).catch()
              return
            }
          }
          this.setChat(this.chat)
          this.$router.push(this.routerLink).catch()
          return
        }
        if (this.isPromotional) {
          if (this.appClientIsVerified(this.chat?.app)) {
            const promotional = await this.getPromotional(this.chat?.promotional_page_id)
            if (promotional && alreadyHadSubscribed(promotional)) {
              this.setDrawer({
                name: 'subscription-expired',
                isVisible: true,
                params: { promoLink: this.routerLink },
              })
            } else {
              this.$router.push(this.routerLink).catch()
            }
            return
          }
          this.setModal({ name: 'admin-no-verified', isVisible: true })
        } else {
          this.setDrawer({ name: 'access-denied', isVisible: true })
        }
      }
    },
    init() {
      if (needInitAppSupportChat(this.chat)) {
        this.isChatsPage
          ? initAppSupportChat({ chat: this.chat, store: this.$store })
          : initProfileAppSupportChat(this.chat)
      }
    },
    onShare() {
      if (this.isPromptBung) {
        Clipboard.copy(
          `${window.location.origin}/${this.locale}/new-gpt-chat?promptId=${this.chatPrompt?.id}`
        )
        this.pushToast({
          message: this.$t('linkCopied'),
          type: 'success',
        })
        return
      }
      this.setDrawer({ name: 'chat-share', isVisible: true })
      this.setChat(this.chat)
      this.setIsDemoMode(true)
      this.setIsEditChats(false)
    },
  },
  created() {
    this.init()
  },
})
</script>
<style lang="scss" module>
.blank {
  height: 75px;
  background-color: white;
  box-shadow: inset 0 -1px 0 $secondary-light;
}
.sectionWrapper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: inset 0 -1px 0 $secondary-light;
  height: 75px;

  &:last-child {
    box-shadow: none;
  }

  .button {
    display: inline-block;
    width: 48px;
    height: calc(100% - 1px);
    border-radius: 0;
    border: transparent;
  }

  .removeButton {
    svg {
      fill: $primary-dark-background;
    }
  }
  .shareButton {
    background: $primary-dark-background;
  }
}

.section {
  position: relative;
  display: inline-grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  height: 100%;
  padding: 9px 15px 9px 9px;
  text-decoration: none;
  vertical-align: top;
  column-gap: 9px;
  cursor: pointer;
  transition: background $transition, width $transition;
  align-items: center;

  &Large {
    padding: 9px 21px 9px 9px;
  }

  &NewMessage {
    background: rgba($secondary-dark, 0.1);
  }

  &IsEdit {
    &.full {
      width: calc(100% - 96px);
    }
    &.half {
      width: calc(100% - 48px);
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-self: center;
  height: 100%;
  padding: 9px 0;
  column-gap: 9px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 9px;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.edit {
  font-size: 12px;
}

.name {
  width: 100%;
  overflow: hidden;
  color: $secondary;
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.description {
  overflow: hidden;
  color: $secondary;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.5;
}

.inner {
  display: grid;
  justify-self: flex-end;
  margin-right: -10px;
  column-gap: 5px;
}

.date {
  color: $secondary;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  text-align: right;
}

.privacy {
  display: flex;
  justify-content: flex-end;
}

.subscribed {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 6px;

  &Icon {
    align-self: center;
    width: 8px;
    height: 6px;
    color: $secondary;
  }

  &Text {
    color: $secondary;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  }
}

.icon {
  color: $primary;
  fill: $primary;
}

.lock {
  width: 30px;
  height: 30px;
  color: $primary;
  fill: $primary;
}
</style>
