import { ChatsFilterNames } from '@config/index'
import { createChat } from '@modules/chat/helpers/chat'
import { Module } from '../types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const module: Module = {
  state: () => ({
    chatsTabs: [],
    appSupportChat: createChat(),
    isLoadingCompleted: false,
    chatsUnreadCount: 0,
    isEditChats: false,
    chatsName: ChatsFilterNames.ALL,
  }),
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default module
