import { Module } from '../types/store'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const module: Module = {
  state: () => ({
    chatIds: [],
    page: 1,
    visibleChats: {},
  }),
  getters,
  actions,
  mutations,
  namespaced: true,
}

export default module
