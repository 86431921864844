<template>
  <div :class="[$style.burger, pageIsMenu && $style.close]" @click.stop="onClick">
    <div v-for="i in 2" :key="i" :class="[$style.line, $style[`line${i}`]]" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'AppRightSectionBurger',
  computed: {
    ...mapGetters('Page', ['pageIsMenu']),
  },
  methods: {
    ...mapMutations('Page', ['setPageIsMenu']),
    onClick() {
      this.setPageIsMenu(!this.pageIsMenu)
    },
  },
})
</script>

<style module lang="scss">
.burger {
  position: relative;
  width: 32px;
  height: 32px;
  border: 10px solid transparent;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  box-sizing: content-box;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  z-index: 3;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: $main-text-color;
    transition: transform 0.2s ease-in-out;
  }
}
.close {
  .line {
    background-color: black;
  }
  .line1 {
    transform: rotate(45deg) translate(3px, 3px);
  }
  .line2 {
    transform: rotate(-45deg) translate(3px, -3px);
  }
}
</style>
