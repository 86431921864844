// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VLabeledToggle_container_1inm6{position:relative;display:flex;min-width:300px;width:100%;height:36px;justify-content:stretch;align-items:stretch;padding:3px;border-radius:6px;background:#fff;box-shadow:0 8px 20px 0 rgba(39,20,96,.07)}.VLabeledToggle_container_1inm6 .VLabeledToggle_indicator_2SUNj{position:absolute;left:3px;right:50%;top:3px;bottom:3px;border-radius:3px;transition:all .3s ease;background:var(--supster-primary-light-background)}.VLabeledToggle_container_1inm6 .VLabeledToggle_indicatorRight_2ibI0{right:3px;left:50%}.VLabeledToggle_container_1inm6 .VLabeledToggle_item_MZ616{color:var(--supster-secondary);transition:color,background-color .3s ease;z-index:1;display:flex;justify-content:center;align-items:center;width:50%;font-size:12px;line-height:18px;border-radius:3px;cursor:pointer}.VLabeledToggle_container_1inm6 .VLabeledToggle_item_MZ616:hover{background:#f2f2f2}.VLabeledToggle_container_1inm6 .VLabeledToggle_itemActive_3o2S9{color:var(--supster-primary-light-text-color);transition:background-color 0s ease}.VLabeledToggle_container_1inm6 .VLabeledToggle_itemActive_3o2S9:hover{background:transparent}", ""]);
// Exports
exports.locals = {
	"container": "VLabeledToggle_container_1inm6",
	"indicator": "VLabeledToggle_indicator_2SUNj",
	"indicatorRight": "VLabeledToggle_indicatorRight_2ibI0",
	"item": "VLabeledToggle_item_MZ616",
	"itemActive": "VLabeledToggle_itemActive_3o2S9"
};
module.exports = exports;
