/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dialog-bubble': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M10.992 0H2.998A3.008 3.008 0 000 3v5c0 1.65 1.349 3 2.998 3v2c0 .4.24.77.62.92a.994.994 0 001.089-.21L7.415 11h3.587A3.008 3.008 0 0014 8V3c0-1.65-1.35-3-2.998-3h-.01zM8.993 6H2.998V5h5.995v1zm1-2H2.998V3h6.995v1z"/>'
  }
})
