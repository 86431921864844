/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'reply': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M18 11.5c0 3.584-2.916 6.5-6.5 6.5a.5.5 0 110-1c3.032 0 5.5-2.468 5.5-5.5S14.532 6 11.5 6H1.707l4.147 4.146a.5.5 0 11-.707.707l-5-4.999a.5.5 0 010-.708l5-5a.5.5 0 11.707.708L1.707 5H11.5c3.584 0 6.5 2.916 6.5 6.5z"/>'
  }
})
