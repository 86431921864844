import { Module } from '@modules/chat/types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const module: Module = {
  state: () => ({
    chat: null,
    nextPage: 1,
    prevPage: 0,
    messages: [],
    tempMessages: [],
    selectedMessageId: null,
    referencedMessageId: null,
    tempAvatar: null,
    pinnedMessages: [],
    pinnedMessagesPointer: 0,
    isReply: false,
    isEdit: false,
    localId: null,
    afterGoToPinnedMessage: false,
    isChatLoading: false,
    gptVersion: null,
    isDemoMode: false,
  }),
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default module
