/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" d="M13 2.843L5.214 10.63 2.371 7.786 10.157 0 13 2.843zM0 13l3.949-1.105L1.106 9.05 0 13z"/>'
  }
})
