<template>
  <div :class="$style.back">
    <v-button
      v-if="isEditButton"
      :class="[$style.button, $style.buttonEdit]"
      color="transparent"
      @click="setIsEditChats(!isEditChats)"
    >
      {{ chatsEditButtonLabel }}
    </v-button>
    <v-button
      v-if="!pageBack.isDisabled"
      :class="$style.button"
      color="transparent"
      is-disabled-padding
      @click="onBack"
    >
      <icon name="back" width="9" height="17" />
    </v-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VHeaderLeft',
  components: { VButton },
  computed: {
    ...mapGetters('Client', ['isClientOwner']),
    ...mapGetters('Page', ['pageBack']),
    ...mapGetters('Chats', ['isEditChats']),
    ...mapGetters('App', ['isMyAppGPT']),
    isChatsPage() {
      return this.$route.name === 'Chats'
    },
    isMyProfilePage() {
      return this.$route.name === 'MyProfile'
    },
    chatMenu() {
      return [
        { icon: 'pChat', item: 3, value: this.$t('chats.personal') },
        {
          icon: 'channel',
          item: 1,
          value: this.$t('channels.channel'),
          disabled: !this.isClientOwner,
        },
      ]
    },
    chatsEditButtonLabel() {
      return this.$t(`chats${this.isEditChats ? '.done' : '.edit'}`)
    },
    isEditButton() {
      return this.isChatsPage && (this.isClientOwner || this.isMyAppGPT)
    },
  },
  methods: {
    ...mapMutations('Chats', ['setIsEditChats']),
    ...mapMutations('Page', ['setPageBack', 'setLastRouteFromFooter']),
    onBack() {
      if (this.pageBack.route === 'emit-back') {
        this.$bus.$emit('emit-back')
        return
      }
      if (this.pageBack.route) {
        this.$router.push(this.pageBack.route)
        this.setPageBack({ route: null })
        return
      }
      this.$bus.$emit('back')
      this.setLastRouteFromFooter('')
      this.$router.go(-1)
    },
  },
  created() {
    this.$bus.$on('back-button', this.onBack)
  },
})
</script>

<style lang="scss" module>
.back {
  position: absolute;
}

.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 200px;
}

.button {
  align-self: center;
  width: 45px;
  height: 45px;

  svg {
    fill: $main-text-color;
  }
}

.buttonEdit {
  width: auto;
  margin-left: 5px;
}

.avatar {
  position: absolute !important;
  top: 6px;
  right: 6px;
}

.dots {
  align-self: center;
  justify-self: flex-start;
  width: 4px;
  height: 18px;
  color: $main-text-color;
}
</style>
