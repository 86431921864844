// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPostPreview_blank_1QzQD{padding-bottom:100%;background-color:#e5e3e3}.VPostPreview_container_2AkoF{display:grid}.VPostPreview_container_2AkoF.VPostPreview_isFeedProduct__U3kB{padding:6px;background-color:#f8f8f8;box-shadow:inset 0 -1px 0 #e5e3e3,inset 0 1px 0 #e5e3e3}.VPostPreview_pointer_3_hUo{cursor:pointer}.VPostPreview_content_eiVvt{position:relative;display:grid}.VPostPreview_content_eiVvt.VPostPreview_isProduct_U1fqh{overflow:hidden;border-radius:6px}.VPostPreview_content_eiVvt.VPostPreview_isShadow_qYotr{box-shadow:0 2px 5px rgba(0,0,0,.08)}.VPostPreview_content_eiVvt.VPostPreview_isFooter_8dNQY{grid-template-rows:min-content 1fr}", ""]);
// Exports
exports.locals = {
	"blank": "VPostPreview_blank_1QzQD",
	"container": "VPostPreview_container_2AkoF",
	"isFeedProduct": "VPostPreview_isFeedProduct__U3kB",
	"pointer": "VPostPreview_pointer_3_hUo",
	"content": "VPostPreview_content_eiVvt",
	"isProduct": "VPostPreview_isProduct_U1fqh",
	"isShadow": "VPostPreview_isShadow_qYotr",
	"isFooter": "VPostPreview_isFooter_8dNQY"
};
module.exports = exports;
