// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/images/svg/progress-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".ChatProgressLoader_container_3u8S5{width:100%;padding-top:40px}.ChatProgressLoader_bar_aeUCL{position:relative;width:90%;height:14px;margin:0 auto;margin-bottom:5px}.ChatProgressLoader_lineWrapper_249x9{width:100%;position:relative;background:linear-gradient(0deg,#dee1e2 16%,#d2d2d2 88%)}.ChatProgressLoader_line_3u2lu,.ChatProgressLoader_lineWrapper_249x9{height:100%;border-radius:20px}.ChatProgressLoader_line_3u2lu{max-width:100%;top:0;left:0;display:block;width:0;background:url('data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12\" height=\"15\" fill=\"none\"><path opacity=\".22\" d=\"M5.84 14.83H.17l5.67-14h5.68l-5.68 14z\" fill=\"%23fff\"/></svg>'),linear-gradient(90deg,#0081ea,#7fffe0);transition:width 1s linear;content:\"\"}.ChatProgressLoader_icon_3KKEg,.ChatProgressLoader_line_3u2lu{position:absolute}.ChatProgressLoader_icon_3KKEg{right:-15px;top:-40px;display:flex;justify-content:center;justify-self:start;width:30px;height:36px;margin-bottom:5px;padding-top:10px;color:#fff;font-size:10px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");transition:left 1s linear}.ChatProgressLoader_iconWrap_2CuWs{width:90%;margin:0 auto}.ChatProgressLoader_waitingText_3ssw4{font-size:10px;line-height:18px;text-align:center}.ChatProgressLoader_waitingDots_3vgrL{display:inline-block;width:20px}", ""]);
// Exports
exports.locals = {
	"container": "ChatProgressLoader_container_3u8S5",
	"bar": "ChatProgressLoader_bar_aeUCL",
	"lineWrapper": "ChatProgressLoader_lineWrapper_249x9",
	"line": "ChatProgressLoader_line_3u2lu",
	"icon": "ChatProgressLoader_icon_3KKEg",
	"iconWrap": "ChatProgressLoader_iconWrap_2CuWs",
	"waitingText": "ChatProgressLoader_waitingText_3ssw4",
	"waitingDots": "ChatProgressLoader_waitingDots_3vgrL"
};
module.exports = exports;
