// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AuthInput_input_12ZfI{width:100%;padding:11px;font-weight:400;font-size:15px;line-height:20px;word-wrap:break-word;border:1px solid #e5e3e3;border-radius:6px;outline:none;transition:border .3s}.AuthInput_input_12ZfI::-moz-placeholder{color:rgba(0,0,0,.5);font-size:16px}.AuthInput_input_12ZfI::placeholder{color:rgba(0,0,0,.5);font-size:16px}.AuthInput_inputError_2IJIO{border:1px solid #fb4e4e}.AuthInput_input_12ZfI.AuthInput_inner-title_u9hXK{background:transparent;border:0}.AuthInput_inner-title_u9hXK,.AuthInput_input_12ZfI.AuthInput_shadow_1YovU{background:#f8f8f8}.AuthInput_inner-title_u9hXK{display:flex;align-items:center;padding:8px 15px;border:1px solid #e5e3e3}.AuthInput_inner-title_u9hXK .AuthInput_inner-label_3-DXm{min-width:70px;color:rgba(0,0,0,.5);font-size:14px;white-space:nowrap}.AuthInput_inner-title_u9hXK .AuthInput_inner-label_3-DXm.AuthInput_isNewPassword_10rLl{min-width:110px}", ""]);
// Exports
exports.locals = {
	"input": "AuthInput_input_12ZfI",
	"inputError": "AuthInput_inputError_2IJIO",
	"inner-title": "AuthInput_inner-title_u9hXK",
	"shadow": "AuthInput_shadow_1YovU",
	"inner-label": "AuthInput_inner-label_3-DXm",
	"isNewPassword": "AuthInput_isNewPassword_10rLl"
};
module.exports = exports;
