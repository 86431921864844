/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { Chat } from '@common-types/chat'
import { GetParams, PostParams } from '../types'
import ChatMessagesApi from './messages'
import ChatSubscriptionsApi from './subscriptions'

export default {
  getChats: async (params: GetParams): Promise<ResType<Chat[]>> => {
    const { total, data }: ResType<Chat[]> = await axiosJWT.get('channels', { params })
    const dataValue = data === null ? [] : data
    return { total, data: dataValue }
  },
  getChat: async (id: number): Promise<Chat> => {
    const { data }: ResType<Chat> = await axiosJWT.get(`channels/${id}`)
    return data
  },
  postChat: async (chat: PostParams): Promise<Chat> => {
    const { data }: ResType<Chat> = await axiosJWT.post('channels', chat)
    return data
  },
  putChat: async (id: number, chat: Chat): Promise<Chat> => {
    const { data }: ResType<Chat> = await axiosJWT.put(`channels/${id}`, chat)
    return data
  },
  deleteChat: async (id: number): Promise<Chat> => {
    const { data }: ResType<Chat> = await axiosJWT.delete(`channels/${id}`)
    return data
  },
  getChatByClientId: async (id: number): Promise<Chat> => {
    const { data }: ResType<Chat> = await axiosJWT.get(`channels/by_client/${id}`)
    return data
  },
  postChatCopy: async (params: any): Promise<Chat> => {
    const { data }: ResType<Chat> = await axiosJWT.post(`channels/copy`, params)
    return data
  },
  ...ChatMessagesApi,
  ...ChatSubscriptionsApi,
}
