<template>
  <div :class="$style.section">
    <!--<h4 :class="$style.title">{{ $t('subscribe') }}:</h4>
    <div :class="$style.tariffs">
      <v-button
        v-for="{ id, amount, period } in tariffs"
        :key="`tariff-${id}`"
        :class="$style.price"
        size="lg"
      >
        {{ amount }} {{ appCurrencySymbol(currentApp) }}
        <span :class="$style.period">{{ $t(`profile.subscription.${period}`) }}</span>
      </v-button>
    </div>-->
    <div :class="$style.button">
      <v-button color="primary" size="lg" @click="onTryTrial"
        >{{ $t('profile.trial') }} {{ appCurrencySymbol(currentApp) }}
      </v-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'ProfileSubscribe',
  components: {
    VButton,
  },
  computed: {
    ...mapGetters('App', [
      'appCurrencySymbol',
      'otherApp',
      'appClientIsVerified',
      'app',
      'currentApp',
    ]),
    ...mapGetters('Profile', ['profileTariffs']),
    ...mapGetters('Locale', ['locale']),

    promotionalId() {
      return this.profileTariffs[0]?.id ?? null
    },
    isProfilePage() {
      return this.$route?.name === 'Profile'
    },

    tariffs() {
      return this.profileTariffs[0]?.tariffs ?? null
    },
  },
  methods: {
    ...mapMutations('Modals', ['setModal']),
    async onTryTrial() {
      if (!this.appClientIsVerified(this.isProfilePage ? this.app : this.otherApp)) {
        this.setModal({ name: 'admin-no-verified', isVisible: true })
      } else {
        await this.$router.push({
          name: 'Promotional',
          params: { id: this.promotionalId, locale: this.locale },
        })
      }
    },
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-gap: 15px;
  padding-top: 15px;
}

.title {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
}

/*.tariffs {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  padding: 0 15px 15px;
  column-gap: 15px;
  box-shadow: inset 0px -1px 0px $secondary-light;

  .price {
    position: relative;
    justify-content: center;
    overflow: visible;
    color: $primary;
    font-size: 18px;
    line-height: 18px;

    & .period {
      position: absolute;
      top: 100%;
      left: 50%;
      padding: 0 6px;
      overflow: hidden;
      color: white;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: $secondary;
      border: 1px solid white;
      border-bottom: 0;
      border-radius: 6px 6px 3px 3px;
      transform: translate(-50%, -50%);
    }
  }
}*/

.button {
  padding: 0 15px 15px;
}
</style>
