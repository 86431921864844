import { Actions } from '../types/store'
import Config from '../config'

const actions: Actions = {
  async getChats({ commit, dispatch, state, rootGetters }, scroll: InfiniteType) {
    const params = {
      ...Config.REQUESTS_PARAMS,
      limit: Config.PAGE_LIMIT,
      page: state.page,
      search: rootGetters['Search/searchQuery'],
    }
    const chats = await dispatch('Chats/getChats', params, { root: true })
    commit('pushChats', chats)
    if (scroll) {
      chats?.length ? scroll.loaded() : scroll.complete()
    }
    return chats
  },
}

export default actions
