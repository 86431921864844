import { Module } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const module: Module = {
  state: () => ({
    files: null,
    filesLoadParams: null,
    filesValid: false,
    filesResolution: null,
  }),
  getters,
  actions,
  mutations,
  namespaced: true,
}

export default module
