export enum SubscriptionStatus {
  ACCEPTED = 1,
  DECLINED,
  STOPPED,
}

export enum SubscriptionStatusRecurrent {
  PAID = 1,
  NOT_PAID = 2,
}
