import {
  getAvatarUrl as getChatAvatarUrl,
  getTitle as getChatTitle,
} from '@modules/chat/services/chat'
import Config from '@modules/chat/config'
import { checker } from '@helpers/index'
import { isTetATetChat } from '@modules/messages/helpers'
import { Message, MessageErrors, MessageMode } from '@common-types/chat/chat-message'
import { ExtendedService, Service } from './types'

export const isSenderApp = checker(MessageMode.APP)

export const getAvatarUrl = (message: Message): string => getChatAvatarUrl(message.channel)

export const getTitle = (message: Message): string => getChatTitle(message.channel)

export const isMyMessage: Service<boolean> = ({ message, store }) => {
  const clientId = store.getters['Client/clientId']
  const client = message.client ?? message.mode_app?.user?.client
  return client?.id === clientId
}

export const fromCurrentDevice: Service<boolean> = ({ message, store }) => {
  const localId = store.getters['Chat/localId']
  return !!localId && !!message.local_id && message.local_id === localId
}

export const isFirstMessageInTetATetChat = (message: Message): boolean =>
  isTetATetChat(message.channel.type) && message.channel?.message_count === 1

export const goToPinnedMessage: ExtendedService<'message' | 'channel' | 'store', void> = async ({
  message,
  channel,
  store,
}) => {
  store.commit('Chat/setAfterGoToPinnedMessage', true)
  const { sort_index } = message
  const page = sort_index ? Math.ceil((sort_index + 1) / Config.PAGE_LIMIT) : 0
  const hasMessage = store.getters['Chat/chatMessages']?.some((m: Message) => m.id === message.id)
  if (hasMessage) {
    return
  }
  store.commit('Chat/setNextPage', page)
  await store.dispatch('Chat/getChatMessages', { id: channel?.id, isRefresh: true })
  store.commit('Chat/setPrevPage', page > 0 ? page - 1 : 0)
}

export const isLimitMessage = (message: Message): boolean =>
  message && message.error_code === MessageErrors.NEED_SUBSCRIPTION

export const isUnknownError = (message: Message): boolean =>
  message && message.error_code === MessageErrors.UNKNOWN

export const isSwearingError = (message: Message): boolean =>
  message && message.error_code === MessageErrors.SWEARING

export const hasMedia = (message: Message): boolean => !!message.media_objects?.length

export default {
  getAvatarUrl,
}
