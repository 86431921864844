import AppApi from '@services/app'
import { Actions } from './types'

const actions: Actions = {
  async getApps({ dispatch, commit }) {
    try {
      const apps = await AppApi.getApps({})
      commit('setMyApps', apps)
      return apps
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return error
    }
  },
  async getApp({ dispatch, commit }, id) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      return await AppApi.getApp(id)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async putApp({ dispatch, commit, getters }, { id = getters.appId(), app }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const newApp = await AppApi.putApp(id, app)
      commit('setApp', newApp)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async setAppSubscription({ dispatch, commit }, { id, isFollowed }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { data, access_token, success } = isFollowed
        ? await AppApi.deleteAppSubscription(id)
        : await AppApi.postAppSubscription(id)
      commit('Me/setAccessToken', access_token, { root: true })
      if (data) {
        commit('Follow/setFollowingItemSubscribe', { id, isFollowed: data.id }, { root: true })
        commit('updateOtherApp', { my_app_subscription: { id: data.id } })
      }
      if (success) {
        commit('Follow/setFollowingItemSubscribe', { id, isFollowed }, { root: true })
        commit('updateOtherApp', { my_app_subscription: null })
      }
      // commit('Follow/setFollowing', data.id, { root: true })
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  replaceOtherApp({ commit }, app) {
    commit('Profile/resetProfileContent', null, { root: true })
    commit('setOtherApp', app)
  },
}

export default actions
