<template>
  <VPostPreviewBlocked
    v-if="isHidden"
    :post="post"
    :class="[$style.container, isFeedPage && isProduct && $style.isFeedProduct]"
  />
  <div
    v-else
    :class="[$style.container, isFeedPage && isProduct && $style.isFeedProduct, $style.pointer]"
    @click="onClick"
  >
    <div
      :class="[
        $style.content,
        isFooter && isProduct && $style.isFooter,
        isProduct && $style.isProduct,
        (isFeedPage || isProfilePage) && isProduct && $style.isShadow,
      ]"
    >
      <VPostPreviewMedia
        v-if="(isAccessible && !isProduct) || isProduct || (!isAccessible && isPromotionalPage)"
        :post="post"
        :is-footer="isFooter"
        :is-product-small="isProductSmall"
        :is-blur="!isAccessible && isPromotionalPage"
      />
      <VPostPreviewSubscribe @click="onPost" v-else :post="post" />
      <VPostPreviewFooter v-if="isFooter && isProduct" :product="product" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { alreadyHadSubscribed } from '@views/promotional/list/services/promotional'
import { PostSource } from '@common-types/post'
import VPostPreviewBlocked from './list/VPostPreviewBlocked.vue'
import VPostPreviewFooter from './list/VPostPreviewFooter.vue'
import VPostPreviewMedia from './list/VPostPreviewMedia.vue'
import VPostPreviewSubscribe from './list/VPostPreviewSubscribe.vue'

export default Vue.extend({
  name: 'VPostPreview',
  components: {
    VPostPreviewBlocked,
    VPostPreviewSubscribe,
    VPostPreviewMedia,
    VPostPreviewFooter,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    tabName: {
      type: String,
      default: '',
    },
    observers: {
      type: Array,
      default: () => [],
    },
    isFooter: Boolean,
    isProductSmall: Boolean,
  },
  computed: {
    ...mapGetters('App', ['currentApp', 'appClientIsVerified']),
    ...mapGetters('Blocks', ['getIsAppBlocked']),
    ...mapGetters('Locale', ['locale']),
    isFeedPage() {
      return this.$route.name === 'Feed' || this.$route.name === 'Post'
    },
    isProfilePage() {
      return this.$route.name === 'Profile'
    },
    isSearchPage() {
      return this.$route.name === 'Search'
    },
    isProductPage() {
      return this.$route.name === 'Product'
    },
    isPromotionalPage() {
      return this.$route.name === 'Promotional'
    },
    needGoToPromo() {
      return !this.isAccessible && !this.isPromotionalPage
    },
    routerLink() {
      if (this.isProduct && !this.isProductPage) {
        return { name: 'Product', params: { locale: this.locale, id: this.post?.id } }
      }
      if (this.needGoToPromo) {
        return {
          name: 'Promotional',
          params: { locale: this.locale, id: this.post?.promotional_page_id },
          query: { post_id: this.post?.id },
        }
      }
      if (this.isSearchPage) {
        return { name: 'SearchFeed', params: { locale: this.locale, id: this.post?.id } }
      }
      if (!this.isFeedPage && !this.isProductPage && !this.isPromotionalPage) {
        return { name: 'Post', params: { locale: this.locale, id: this.post?.id } }
      }
      return null
    },
    isAccessible() {
      return this.post?.is_accessible ?? false
    },
    postApp() {
      return this.post?.app ?? null
    },
    product() {
      if (this.post?.products) {
        return this.post?.products[0]
      }
      return null
    },
    isProduct() {
      return !!this.product
    },
    isHidden() {
      return this.getIsAppBlocked(this.postApp?.name)
    },
    hasObservers() {
      return !!this.observers.length
    },
  },
  methods: {
    ...mapMutations('Post', ['setPost', 'setPostMeta']),
    ...mapMutations('Modals', ['setModal']),
    ...mapActions('Promotional', ['getPromotional']),
    ...mapMutations('Drawers', ['setDrawer']),
    observe() {
      if (this.hasObservers) {
        this.observers.forEach((observer) => {
          observer.observe(this.$refs['post-preview'])
        })
      }
    },
    unobserve() {
      if (this.hasObservers) {
        this.observers.forEach((observer) => {
          observer.unobserve(this.$refs['post-preview'])
        })
      }
    },
    onClick() {
      this.isLoading = true
      this.onPost()
    },
    async onPost() {
      if (this.routerLink) {
        this.setPost(this.post)
        this.setPostMeta({
          postId: this.post?.id,
          source: this.isSearchPage ? PostSource.SEARCH : PostSource.PROFILE,
          tabName: this.tabName,
          appId: this.currentApp?.id,
        })
        if (!this.isAccessible && !this.appClientIsVerified(this.postApp)) {
          this.setModal({ name: 'admin-no-verified', isVisible: true })
        } else {
          if (this.needGoToPromo) {
            const promotional = await this.getPromotional(this.post?.promotional_page_id)
            if (promotional || alreadyHadSubscribed(promotional)) {
              this.setDrawer({
                name: 'subscription-expired',
                isVisible: true,
                params: { promoLink: this.routerLink },
              })
            } else {
              this.$router.push(this.routerLink).catch()
            }
            return
          }
          this.$router.push(this.routerLink)
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.observe()
    })
  },
  beforeDestroy() {
    this.unobserve()
  },
})
</script>

<style lang="scss" module>
.blank {
  padding-bottom: 100%;
  background-color: $secondary-light;
}
.container {
  display: grid;

  &.isFeedProduct {
    padding: 6px;
    background-color: $shadow;
    box-shadow: inset 0 -1px 0 $secondary-light, inset 0 1px 0 $secondary-light;
  }
}

.pointer {
  cursor: pointer;
}

.content {
  position: relative;
  display: grid;

  &.isProduct {
    overflow: hidden;
    border-radius: 6px;
  }

  &.isShadow {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }

  &.isFooter {
    grid-template-rows: min-content 1fr;
  }
}
</style>
