export enum Claims {
  DONT_LIKE = 'DONT_LIKE',
  SPAM = 'SPAM',
  SEXUAL = 'SEXUAL',
  HATE = 'HATE',
  FALSE_INFORMATION = 'FALSE_INFORMATION',
  BULLYING = 'BULLYING',
  VIOLENCE = 'VIOLENCE',
  SCAM = 'SCAM',
  INTELLECTUAL = 'INTELLECTUAL',
  SALE_ILLEGAL = 'SALE_ILLEGAL',
  SUICIDE = 'SUICIDE',
  EATING_DISORDERS = 'EATING_DISORDERS',
  SUICIDE_OR_EATING_DISORDERS = 'SUICIDE_OR_EATING_DISORDERS',
  SOMETHING_ELSE = 'SOMETHING_ELSE',
}

export default {
  Claims,
}
