import { Mutations, StateNames } from './types'

const mutations: Mutations = {
  setApp(state, app) {
    state[StateNames.MY_APP] = { ...state[StateNames.MY_APP], ...app }
  },
  setOtherApp(state, app) {
    state[StateNames.OTHER_APP] = app
  },
  updateOtherApp(state, app) {
    state[StateNames.OTHER_APP] = { ...state[StateNames.OTHER_APP], ...app }
  },
  setClientApp(state, app) {
    state[StateNames.CLIENT_APP] = { ...state[StateNames.CLIENT_APP], ...app }
  },
  resetClientApp(state) {
    state[StateNames.CLIENT_APP] = null
  },
  setMyApps(state, apps) {
    state.myApps = apps
  },
  setAppPostCount(state, action: 'increment' | 'decrement') {
    const myApp = state[StateNames.MY_APP]
    const increase = action === 'increment' ? 1 : -1
    state[StateNames.MY_APP] = myApp && { ...myApp, post_count: (myApp.post_count += increase) }
  },
  setCurrentAppName(state, name: StateNames) {
    state.currentAppName = name
  },
  unblockCurrentApp(state) {
    const currentApp = state[state.currentAppName]
    state[state.currentAppName] = currentApp && { ...currentApp, i_blocked_app: null }
  },
}

export default mutations
