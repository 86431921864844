/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ru': {
    width: 900,
    height: 600,
    viewBox: '0 0 9 6',
    data: '<path pid="0" _fill="#fff" d="M0 0h9v3H0z"/><path pid="1" _fill="#d52b1e" d="M0 3h9v3H0z"/><path pid="2" _fill="#0039a6" d="M0 2h9v2H0z"/>'
  }
})
