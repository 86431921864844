import Vue from 'vue'
import { PostIds } from '@common-types/post'
import ProfileImagesCache from '@modules/profile/modules/profile-images/services/cache'
import { Mutations } from '../types'

const mutations: Mutations = {
  addComponentHeight(state, { postId, height }) {
    Vue.set(state.componentsHeights, postId, height)
  },
  setStartIndex(state, startIndex) {
    state.startIndex = startIndex
  },
  setVisiblePosts(state, postIds: PostIds) {
    state.visiblePosts = ProfileImagesCache.getItems(postIds)
  },
  setPost(state, post) {
    const oldPost = ProfileImagesCache.getItem(post.id)
    const oldPostMediaObjects = oldPost?.media_objects
    const newPostMediaObjects = post.media_objects ?? oldPostMediaObjects ?? []
    const newPost = { ...post, media_objects: newPostMediaObjects }
    ProfileImagesCache.setItem(newPost)
    state.visiblePosts = state.visiblePosts.map((item) => (item.id === post.id ? newPost : item))
  },
}

export default mutations
