/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 5,
    height: 8,
    viewBox: '0 0 5 8',
    data: '<path pid="0" d="M0 4c0 .145.062.281.17.376l4 3.5a.498.498 0 00.706-.047.499.499 0 00-.047-.705L1.259 4 4.83.876a.499.499 0 10-.658-.752l-4 3.5A.499.499 0 000 4z" _fill="#000"/>'
  }
})
