import { Mutations, Tab } from '../types'

const mutations: Mutations = {
  setSearchQuery(state, query: string) {
    state.query = query
  },
  setTab(state, tab: Tab) {
    state.tab = tab
  },
}

export default mutations
