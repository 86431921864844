/* eslint-disable camelcase */
import { getBlockedPostCount } from '../helpers'
import { Actions } from '../types'
import Config from '../config'

const actions: Actions = {
  async getFeed({ commit, dispatch, state }, { scroll }: { scroll: InfiniteType }) {
    dispatch('calcPage')
    const posts = await dispatch(
      'Post/getPosts',
      {
        ...Config.REQUESTS_PARAMS,
        page: state.page,
      },
      { root: true }
    )
    commit('pushFeed', posts)
    if (scroll) {
      posts?.length ? scroll.loaded() : scroll.complete()
    }
  },
  calcBlockedPostsCount({ commit, state }, blockedAppName: string) {
    commit('setBlockedPostCount', getBlockedPostCount(state.postIds, blockedAppName))
  },
  calcPage({ commit, state }) {
    const pageCount = Math.ceil(state.blockedPostCount / Config.REQUESTS_PARAMS.limit)
    const page = state.page - pageCount
    commit('setFeedPage', page > 1 ? page : 1)
  },
}

export default actions
