/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-chat-personal': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M16 0H2a2 2 0 00-2 2v10a2 2 0 002 2h8l6 4v-4a2 2 0 002-2V2a2 2 0 00-2-2zm1 12c0 .551-.449 1-1 1h-1v3.131l-4.445-2.963-.252-.168H2c-.551 0-1-.449-1-1V2c0-.551.449-1 1-1h14c.551 0 1 .449 1 1v10zm-6.455-6.708L9 2 7.455 5.292 4 5.82l2.5 2.562L5.91 12 9 10.292 12.09 12l-.59-3.618L14 5.82l-3.455-.528zm.24 2.392l-.353.36.081.499.259 1.586-1.288-.712L9 9.149l-.484.268-1.288.712.259-1.586.081-.498-.352-.361-1.141-1.17 1.531-.234.527-.08.227-.483L9 4.353l.64 1.364.227.483.527.08 1.531.234-1.14 1.17z"/>'
  }
})
