<template>
  <v-drawer name="subscription-expired">
    <div :class="$style.content">
      <div :class="$style.iconWrapper">
        <icon name="clock" width="120" height="120" />
      </div>
      <p :class="$style.text">{{ $t('subscriptionExpiredText') }}</p>
      <v-button :class="$style.button" color="primary" size="xl" @click="onCLick">{{
        $t('renewSubscription')
      }}</v-button>
    </div>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'SubscriptionExpiredDrawer',
  components: { VButton, VDrawer },
  computed: {
    ...mapGetters('Drawers', ['drawerParams']),
    promoLink() {
      return this.drawerParams?.promoLink
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    onCLick() {
      if (this.promoLink) {
        this.$router.push(this.promoLink)
        this.setDrawer({ name: 'subscription-expired', isVisible: false })
      }
    },
  },
})
</script>

<style lang="scss" module>
.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .iconWrapper {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    text-align: center;
  }
  .button {
    height: 50px;
  }
}
</style>
