export default {
  INSTALL: 'Install',
  FEED: 'Feed',
  POST: 'Post',
  POST_COMMENTS: 'PostComments',
  PRODUCT: 'Product',
  BROADCAST: 'Broadcast',
  SEARCH: 'Search',
  SEARCH_FEED: 'SearchFeed',
  TAG: 'Tag',
  CHATS: 'Chats',
  CHAT_SHARED: 'ChatShared',
  CHAT: 'Chat',
  NEW_GPT_CHAT: 'NewGptChat',
  SUPPORT_CHAT: 'SupportChat',
  PROFILE: 'Profile',
  OTHER_PROFILE: 'OtherProfile',
  PROFILE_IMAGES_FEED: 'ProfileImagesFeed',
  PROFILE_VIDEOS_FEED: 'ProfileVideosFeed',
  MY_PROFILE: 'MyProfile',
  STORIES: 'Stories',
  DOCUMENTS: 'Documents',
  INTRODUCTION: 'Introduction',
  PAYMENT_SUCCESS: 'PaymentSuccess',
}
