<template>
  <div
    :class="[
      $style.container,
      isTransparent && $style['container--transparent'],
      isSmall && $style['container--small'],
    ]"
  >
    <div :class="$style.flag">
      <div :class="$style.iconWrapper">
        <icon :name="locale" :class="$style.icon" />
      </div>
    </div>
    <div :class="$style.label">
      {{ label }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VLocaleLabel',
  props: {
    locale: {
      type: String,
      default: 'ru',
    },
    isTransparent: Boolean,
    isSmall: Boolean,
  },
  computed: {
    label() {
      return this.$t(`locale.${this.locale}`)
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  gap: 9px;

  .flag {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 1px solid $secondary-light;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .iconWrapper {
      .icon {
        height: 100%;
        display: block;
      }
    }
  }

  .label {
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
  }

  &--transparent {
    .label {
      color: inherit;
    }
  }

  &--small {
    .label {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
