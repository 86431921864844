// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatMediaModal_container_xRfQv{display:grid;row-gap:10px}.ChatMediaModal_content_1nlEm{display:grid;width:100%;height:100%;-moz-column-gap:6px;column-gap:6px;row-gap:6px}.ChatMediaModal_element_lWfSd{height:100%;max-height:138px}.ChatMediaModal_element_lWfSd:first-child,.ChatMediaModal_element_lWfSd:nth-child(2){grid-row:span 2;grid-column:span 2}", ""]);
// Exports
exports.locals = {
	"container": "ChatMediaModal_container_xRfQv",
	"content": "ChatMediaModal_content_1nlEm",
	"element": "ChatMediaModal_element_lWfSd"
};
module.exports = exports;
