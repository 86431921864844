<template>
  <div :class="$style.container">
    <v-button
      v-if="cartProductsLength"
      :class="[$style.shop, $style.button]"
      color="transparent"
      is-disabled-padding
      @click="setDrawer({ name: 'cart', isVisible: true })"
    >
      <icon name="shop" :class="$style.icon" />
      <span :class="$style.value">{{ cartProductsLength }}</span>
    </v-button>

    <v-header-button
      v-if="isChatsPage & !isMyAppGPT"
      :class="$style.button"
      :active="drawer('chats-filter')"
      @click="setDrawer({ name: 'chats-filter', isVisible: true })"
    >
      <icon name="settings" width="19" height="13" />
    </v-header-button>

    <v-header-button
      v-if="isPostSetupPage"
      :class="$style.button"
      :disabled="pageLoading"
      :loading="isPostSent"
      @click="onSendPost"
    >
      <icon :class="$style.check" v-if="!isPostSent" name="check" width="19" height="13" />
    </v-header-button>

    <v-header-button
      v-if="isMyProfilePage"
      :class="$style.button"
      :disabled="isMyProfileButtonDisabled"
      :loading="isProfileEdit"
      @click="onEditProfile"
    >
      <icon :class="$style.check" v-if="!isProfileEdit" name="check" width="19" height="13" />
    </v-header-button>

    <v-avatar
      v-else-if="isChatPage"
      :class="$style.avatar"
      :src="avatarSrc"
      :srcset="avatarSrc"
      size="xs"
      :image-icon="avatarIcon"
      progress-color="white"
      :progress-count="100"
      :progress-width="2"
      :to-app="chatApp"
      @click.native="onCopyLink"
    />

    <v-burger v-if="!isChatPage" :class="[$style.burger, $style.button]" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import { Clipboard } from 'v-clipboard'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VBurger from '@layouts/VBurger.vue'
import VButton from '@elements/VButton.vue'
import VHeaderButton from '@elements/VHeaderButton.vue'
import { getAvatarUrl } from '@modules/chat/services/chat'
import { isSupportChat } from '@helpers/chats'
import { ClientType } from '@services/client/types'
import SupportAvatar from '@assets/images/support-avatar.png'
import { createSharedLink } from '@modules/chat/services/chat-sharing'

export default Vue.extend({
  name: 'VHeaderRight',
  components: { VAvatar, VBurger, VButton, VHeaderButton },
  data() {
    return {
      isPostSent: false,
      isProfileEdit: false,
    }
  },
  computed: {
    ...mapGetters('Cart', ['cartProductsLength']),
    ...mapGetters('Chat', [
      'chat',
      'getChatAvatar',
      'chatApp',
      'isChatPartnerOwner',
      'tempAvatar',
      'isPrompt',
      'chatSecretKey',
      'isChatGPTBot',
    ]),
    ...mapGetters('Page', ['pageLoading']),
    ...mapGetters('App', ['appAvatar', 'isMyAppGPT', 'appColor']),
    ...mapGetters('Drawers', ['drawer']),
    ...mapGetters('Profile', ['isClientEditingError']),
    ...mapGetters('Client', ['clientId']),
    isChatGPTBot() {
      return this.chat?.recipient?.type === ClientType.BOT && this.isMyAppGPT
    },
    isChatPage() {
      return this.$route.name === 'Chat' || this.$route.name === 'SupportChat'
    },
    isChatsPage() {
      return this.$route.name === 'Chats'
    },
    isMyProfilePage() {
      return this.$route?.name === 'MyProfile'
    },
    isDocumentsPage() {
      return this.$route?.name === 'Documents'
    },
    isPostSetupPage() {
      return this.$route?.name === 'PostCreate' || this.$route?.name === 'PostEdit'
    },
    avatarSrc() {
      if (this.isChatGPTBot && !this.isPrompt) {
        return ''
      }
      if (isSupportChat(this.chat?.type)) {
        return SupportAvatar
      }
      return this.chat && getAvatarUrl(this.chat)
    },
    avatarIcon() {
      return this.isChatGPTBot ? 'openai' : ''
    },
    isMyProfileButtonDisabled() {
      return this.pageLoading || (this.isMyAppGPT && this.isClientEditingError)
    },
  },
  watch: {
    isPostSetupPage(val) {
      if (!val) {
        this.isPostSent = val
      }
    },
    isMyProfilePage(val) {
      if (!val) {
        this.isProfileEdit = val
      }
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Toasts', ['pushToast']),
    onSendPost() {
      if (this.isPostSent) {
        return
      }
      this.$bus.$emit('header-send')
      this.isPostSent = true
    },
    onEditProfile() {
      if (this.isProfileEdit) {
        return
      }
      this.$bus.$emit('edit-profile')
      this.isProfileEdit = true
    },
    onCopyLink() {
      if (!this.isChatGPTBot || !this.chatSecretKey) {
        return
      }
      Clipboard.copy(createSharedLink(this.chatSecretKey, this.clientId))
      this.pushToast({
        message: this.$t('linkCopied'),
        type: 'success',
      })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: absolute;
  right: 0;
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  align-items: center;
  height: 100%;
}

.check {
  path {
    fill: $main-text-color;
  }
  fill: $main-text-color;
}

.avatar {
  margin-right: 8px;
}

.button {
  align-self: center;
  width: 45px;
  height: 45px;
  svg {
    fill: $main-text-color;
  }
}

.shop {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: center;
  column-gap: 6px;
}

.icon {
  align-self: center;
  width: 13px;
  height: 13px;
}

.value {
  align-self: center;
  color: $secondary;
}

.burger {
  width: 45px;

  @media (min-width: $width) {
    display: none;
  }
}
</style>
