import Vue from 'vue'
import { Chat, ChatIds } from '@common-types/chat'
import { convertChatsArrayToObjectMap, getChatIds } from '@helpers/chats'
import ChatsCache from '../services/cache'
import { Mutations } from '../types/store'

const mutations: Mutations = {
  setChats(state, chats) {
    ChatsCache.setItems(chats)
    state.chatIds = getChatIds(chats)
    state.page = 2
  },
  pushChats(state, chats) {
    ChatsCache.addItems(chats)
    state.chatIds.push(...getChatIds(chats))
    state.page += 1
  },
  pushChat(state, chat: Chat) {
    ChatsCache.addItem(chat)
    state.chatIds.push(chat.id)
  },
  resetChats(state) {
    ChatsCache.resetCache()
    state.chatIds = []
    state.visibleChats = {}
    state.page = 1
  },
  setChat(state, chat: Chat) {
    ChatsCache.setItem(chat)
    state.visibleChats = { ...state.visibleChats, [chat.id]: chat }
  },
  deleteChat(state, chatId) {
    ChatsCache.deleteItem(chatId)
    state.chatIds = state.chatIds.filter((id) => id !== chatId)
    Vue.delete(state.visibleChats, chatId)
  },
  setVisibleChats(state, chatIds: ChatIds) {
    const chats = ChatsCache.getItems(chatIds)
    state.visibleChats = convertChatsArrayToObjectMap(chats)
  },
  addVisibleChats(state, chatIds: ChatIds) {
    const chats = ChatsCache.getItems(chatIds)
    const visibleChats = convertChatsArrayToObjectMap(chats)
    state.visibleChats = { ...state.visibleChats, ...visibleChats }
  },
  deleteVisibleChats(state, chatIds: ChatIds) {
    chatIds.forEach((chatId) => Vue.delete(state.visibleChats, chatId))
  },
  resetVisibleChats(state) {
    state.visibleChats = {}
  },
}

export default mutations
