/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'openai': {
    width: 671.194,
    height: 680.249,
    viewBox: '0 0 671.194 680.249',
    data: '<path pid="0" d="M626.946 278.404a169.45 169.45 0 00-14.564-139.187 171.383 171.383 0 00-184.594-82.233A169.45 169.45 0 00299.975.004a171.399 171.399 0 00-163.5 118.668 169.508 169.508 0 00-113.318 82.206A171.41 171.41 0 0044.24 401.845a169.456 169.456 0 0014.563 139.188 171.4 171.4 0 00184.595 82.231 169.447 169.447 0 00127.813 56.981A171.4 171.4 0 00534.764 561.51a169.504 169.504 0 00113.318-82.206 171.412 171.412 0 00-21.136-200.9zM371.265 635.776a127.108 127.108 0 01-81.603-29.503c1.032-.562 2.844-1.556 4.024-2.278l135.444-78.237a22.023 22.023 0 0011.13-19.27V315.537l57.25 33.057a2.033 2.033 0 011.113 1.568v158.135a127.64 127.64 0 01-127.358 127.479zM97.37 518.799a127.054 127.054 0 01-15.208-85.426c1.006.604 2.762 1.677 4.023 2.401L221.63 514.01a22.04 22.04 0 0022.25 0l165.363-95.482v66.113a2.053 2.053 0 01-.818 1.757l-136.92 79.054a127.615 127.615 0 01-174.134-46.654zM61.738 223.11a127.015 127.015 0 0166.355-55.894c0 1.167-.067 3.233-.067 4.667v156.472a22.004 22.004 0 0011.117 19.258l165.363 95.47-57.248 33.054a2.055 2.055 0 01-1.932.176l-136.933-79.122a127.614 127.614 0 01-46.655-174.08zm470.357 109.456L366.73 237.085l57.25-33.043a2.046 2.046 0 011.93-.173l136.934 79.053a127.505 127.505 0 01-19.7 230.055V351.825a21.996 21.996 0 00-11.05-19.258zm56.98-85.76a182.901 182.901 0 00-4.023-2.4l-135.446-78.236a22.07 22.07 0 00-22.247 0l-165.364 95.483v-66.113a2.053 2.053 0 01.818-1.757l136.92-78.988a127.492 127.492 0 01189.34 132.012zM230.871 364.647l-57.264-33.057a2.032 2.032 0 01-1.112-1.57V171.883a127.493 127.493 0 01209.068-97.895c-1.033.564-2.83 1.556-4.024 2.28l-135.444 78.236a22.003 22.003 0 00-11.13 19.257zm31.097-67.053l73.651-42.537 73.649 42.51v85.048l-73.649 42.513-73.65-42.513z"/>'
  }
})
