/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.851 0H3.15A3.149 3.149 0 000 3.149V20.85A3.149 3.149 0 003.149 24H20.85A3.149 3.149 0 0024 20.851V3.15C24 1.409 22.59 0 20.851 0zM18 13h-5v5a1 1 0 01-2 0v-5H6a1 1 0 010-2h5V6a1 1 0 112 0v5h5a1 1 0 110 2z"/>'
  }
})
