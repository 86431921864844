/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shield-round': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<path pid="0" d="M20.23 14l1.72 1.77c-.16 1.69-.74 3.65-2.37 5.05-.58.51-1.16.83-1.58 1.02-1.22-.54-3.58-2.1-3.95-6.07L15.77 14h4.46zm4.77.66c0 2.49-.6 5.97-3.46 8.43-1.72 1.48-3.42 1.88-3.49 1.9-.02.01-.03.01-.05.01-.02 0-.03 0-.05-.01-.07-.01-6.95-1.68-6.95-10.33 0-.04.02-.08.05-.11l3.4-3.49c.03-.04.09-.06.15-.06h6.8c.06 0 .12.02.16.06l3.4 3.49c.02.03.04.07.04.11zM24 15l-2.92-3h-6.15L12 15c.16 6.82 4.98 8.66 6 8.97.44-.13 1.66-.58 2.89-1.63 2.45-2.11 3.05-5.09 3.11-7.34z" _fill="#EDD031"/><rect pid="1" x=".5" y=".5" width="35" height="35" rx="17.5" _stroke="#EDD031"/>'
  }
})
