import { Module } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const module: Module = {
  state: () => ({
    verification: null,
    verificationData: null,
    step: 0,
    code: null,
    mode: 'created',
    verificationId: null,
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default module
