import { Prompt } from '@modules/prompts/types'
import { axiosJWT } from '@plugins/axios'
// import mock from './mock'

export default {
  getPrompts: async (params: any): Promise<Prompt[]> => {
    const { data }: ResType<Prompt[]> = await axiosJWT.get('chat_gpt_system_prompts', { params })
    return data
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // return mock as Prompt[]
  },
  getPrompt: async (promptId: Prompt['id']): Promise<Prompt> => {
    const { data }: ResType<Prompt> = await axiosJWT.get(`chat_gpt_system_prompts/${promptId}`)
    return data
  },
}
