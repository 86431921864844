<template>
  <VDrawer :name="drawerName">
    <div v-for="code of locales" :key="code" :class="$style.item" @click="onClick(code)">
      <VLocaleLabel :locale="code" :key="code" />
    </div>
    <div :class="[$style.item, $style.cancel]" @click="cancel">{{ $t('cancel') }}</div>
  </VDrawer>
</template>

<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import Trans from '@plugins/i18n/translation'
import VLocaleLabel from '@components/VLocaleLabel.vue'
import { drawerNames } from '@config/drawer-names'

export default Vue.extend({
  name: 'LocalesDrawer',
  components: { VLocaleLabel, VDrawer },
  computed: {
    locales() {
      return Trans.supportedLocales
    },
    drawerName() {
      return drawerNames.LOCALES
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    async onClick(code) {
      await this.changeLocale(code)
      this.cancel()
    },
    async changeLocale(code) {
      if (!code) {
        return
      }
      await Trans.switchLanguage(code)
      try {
        await this.$router.replace({ params: { locale: code }, query: this.$route.query })
      } catch (e) {
        this.$router.push('/')
      }
    },
    cancel() {
      this.setDrawer({ name: drawerNames.LOCALES, isVisible: false })
    },
  },
})
</script>

<style lang="scss" module>
.item {
  width: 100%;
  padding: 13.5px 15px;
  overflow: hidden;
  box-shadow: 0px -1px 0px 0px $secondary-light inset;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.cancel {
  color: white;
  background: $grey-dark;
  box-shadow: none;
}
</style>
