<template>
  <div :class="[$style.container, even && $style.even]">
    <div :class="$style.section">
      <div v-if="!even" :class="$style.avatar">
        <div :class="$style.avatarContent"></div>
      </div>
      <div :class="$style.content">
        <div :class="$style.title"></div>
        <div :class="$style.description"></div>
      </div>
      <div v-if="even" :class="$style.avatar">
        <div :class="$style.avatarContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'PChatMessage',
  props: {
    even: Boolean,
  },
})
</script>

<style lang="scss" module>
.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  .section {
    width: 80%;
    display: grid;
    grid-gap: 9px;
    grid-template-columns: 54px 1fr;
    align-items: center;
    padding: 9px;
    background: white;
    box-shadow: inset 0 -1px 0 $secondary-light;
    border-radius: 10px;
    border-top-left-radius: 0;
  }
}

.even {
  justify-content: end;

  .section {
    grid-template-columns: 1fr 54px;
    border-top-right-radius: 0;
    border-top-left-radius: 10px;
  }
}

.avatar {
  width: 54px;
  height: 54px;
  padding: 2px;
  background: white;
  border: 2px solid $grey;
  border-radius: 45px;

  &Content {
    width: 46px;
    height: 46px;
    background: $grey;
    border-radius: 50%;
  }
}

.content {
  display: grid;
  grid-gap: 12px;
}

.title {
  width: 65%;
  height: 12px;
  background: $grey;
  border-radius: 6px;
}

.description {
  width: 45%;
  height: 9px;
  background: $grey;
  border-radius: 6px;
}
</style>
