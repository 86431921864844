export enum MediaDevicesAccessError {
  ABORT = 'AbortError ',
  NOT_ALLOWED = 'NotAllowedError',
  NOT_FOUND = 'NotFoundError',
  NOT_READABLE = 'NotReadableError',
  OVERCONSTRAINED = 'OverconstrainedError',
  SECURITY = 'SecurityError',
  TYPE = 'TypeError',
}
export enum MediaDevicesNotAllowedErrorType {
  DENIED = 'Permission denied',
  DISMISSED = 'Permission dismissed',
}

export enum PermissionState {
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt',
}

export type MediaDevicesErrorToastLabels = Partial<
  Record<MediaDevicesAccessError | 'default', string>
>
export type MediaDevicesNotAllowedErrorToastMessages = Record<
  MediaDevicesNotAllowedErrorType,
  string
>

export default {
  MediaDevicesAccessError,
  MediaDevicesNotAllowedErrorType,
}
