<template>
  <div :class="$style.request">
    <icon :class="$style.icon" name="double-chevron" width="12" height="12" />
    <p>{{ text }}</p>
    <icon :class="$style.icon" name="chevron" width="6" height="12" />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'ChatGptWelcomeRequest',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
})
</script>

<style module lang="scss">
.request {
  display: grid;
  grid-template-columns: 12px 1fr 6px;
  align-items: center;
  padding: 9px 18px 9px 12px;
  gap: 10px;
  background: white;
  border: 1px solid $primary-light-background;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: $shadow;
  }
  svg {
    fill: $primary-light-background;
  }
  p {
    line-height: 22px;
    font-size: 14px;
  }
}
</style>
