const customViewportCorrectionVariable = 'vh'
function isIOS() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}
/* 
  
  Хак по задаче SUP-1781. Устраняет баг когда сафари неправильно определяет 
  windowHeight после редиректа из сторонних сайтов.
  Когда при оплате подписки осуществляется редирект для подтверждения оплаты,
  браузер открывает окно сафари, и добавлает элементы управления, после возврата в PWA,
  элементы управления браузера снова удаляются, но при этом браузер при рендеринге ошибочно
  определяет что они есть, по этой причине окно браузера PWA, становится меньше примерно на 110px.

  Решение:
  saveWindowHeightPwa()
  Для IOS При первом открытии PWA сохраняем в localStorage высоту.
  Tсли разница между высоты окна браузера и высоты всего экрана меньше чем 60px, 
  то сохраняем высоту в localStorage. Такая разница может быть только в PWA, 
  т.к. отсутствуют элементы управления браузера. Если мы откроем в самом браузере сафари, 
  то разница между screeHeight и windowheight будет составлять больше 100 пикселей, 
  тогда нам записывать не нужно.

  getWindowHeight()
  Вызывается только в случае если мы определили, что PWA открыто в браузере Safari IOS.
  Так, мы всегда будем брать высоту которую записали изначально (правильную).
*/
function saveWindowHeightPwa() {
  const difference = window.screen.height - window.innerHeight
  if (difference < 60) {
    localStorage.setItem('correctWindowHeight', window.innerHeight.toString())
  }
}
function getWindowHeight() {
  return Number(localStorage.getItem('correctWindowHeight') ?? window.innerHeight)
}
function setViewportProperty(doc: HTMLElement) {
  let prevClientHeight: number
  const customVar = `--${customViewportCorrectionVariable || 'vh'}`
  function handleResize() {
    const { clientHeight } = doc
    const newHeight = isIOS() ? getWindowHeight() : clientHeight
    if (clientHeight === prevClientHeight) {
      return
    }
    requestAnimationFrame(function updateViewportHeight() {
      doc.style.setProperty(customVar, `${newHeight * 0.01}px`)
      prevClientHeight = newHeight
    })
  }
  handleResize()
  return handleResize
}
saveWindowHeightPwa()
window.addEventListener('resize', setViewportProperty(document.documentElement))
