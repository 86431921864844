/* eslint-disable camelcase */
import { MediaObject } from '@services/media/types'

export enum ClientType {
  BOT = 'bot',
  DEFAULT = 'default',
}

export interface Client {
  app_subscriptions_count: number
  avatar: MediaObject | null
  email: string
  id: number
  name: string
  verification_id?: number
  verified_status?: number
  type: ClientType
}

export type ClientBot = Omit<Client, 'email' | 'verification_id'>

export interface PostParams {
  app_id: string
  name: string
  stream?: string
}

export interface PutEmailParams {
  email: string
  email_code: string
  email_token: string
}
