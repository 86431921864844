// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OverlayLoadingLoader_container_1yLFv{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1000;display:flex;align-items:center;justify-content:center;background-color:hsla(0,0%,100%,.8)}", ""]);
// Exports
exports.locals = {
	"container": "OverlayLoadingLoader_container_1yLFv"
};
module.exports = exports;
