<template>
  <v-input
    :disabled="disabled"
    :error="error"
    :error-to-show="errorToShow"
    :title="computedTitle"
    :type="isAuthInput ? 'auth' : ''"
  >
    <div :class="mode && $style[mode]">
      <label
        v-if="isInnerTitle"
        :class="[$style['inner-label'], isAuthInput && $style.isAuthInput]"
        >{{ title }}</label
      >
      <the-mask
        v-model="input"
        :class="[
          $style.input,
          errorToShow && $style.inputError,
          mode && $style[mode],
          shadow && $style.shadow,
        ]"
        :placeholder="placeholder"
        :mask="mask"
        :masked="masked"
        :tokens="tokens"
        :type="type"
      />
    </div>
  </v-input>
</template>
<script>
import Vue from 'vue'
import validate from '@utils/validate'
import VInput from '@elements/inputs/VInput.vue'

export default Vue.extend({
  name: 'MaskInput',
  components: {
    VInput,
  },
  props: {
    title: String,
    placeholder: String,
    mask: {
      type: Array,
      default: () => [],
    },
    masked: Boolean,
    value: String,
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    tokens: {
      type: Object,
      default: () => ({}),
    },
    disabled: Boolean,
    mode: {
      type: String,
      default: '',
    },
    shadow: Boolean,
    isAuthInput: Boolean,
    forseError: String,
  },
  data() {
    return {
      error: '',
      errorToShow: '',
    }
  },
  watch: {
    input() {
      this.validate()
    },
    forseError() {
      if (this.forseError) {
        this.error = this.forseError
        this.errorToShow = this.forseError
      } else {
        this.error = ''
      }
    },
  },
  computed: {
    computedTitle() {
      return this.isInnerTitle ? '' : this.title
    },
    isInnerTitle() {
      return this.mode === 'inner-title'
    },
    input: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  async mounted() {
    this.input = this.value
    this.error = await validate(this.rules, this.value)
  },
  methods: {
    async validate() {
      this.error = await validate(this.rules, this.value)
      this.errorToShow = this.error
      return this.error
    },
  },
})
</script>
<style lang="scss" module>
.input {
  width: 100%;
  padding: 11px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  word-wrap: break-word;
  border: 1px solid $secondary-light;
  border-radius: 6px;
  outline: none;
  transition: border $transition;

  &::placeholder {
    color: rgba(black, 0.5);
    font-size: 16px;
  }

  &Error {
    border: 1px solid $error;
  }

  &.inner-title {
    background: transparent;
    border: 0;
  }

  &.shadow {
    background: $shadow;
  }
}

.inner-title {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  background: $shadow;
  border: 1px solid $secondary-light;

  .inner-label {
    color: rgba(black, 0.5);
    font-size: 14px;
    white-space: nowrap;
  }
}
</style>
