// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PChatPreview_section_2NM4L{display:grid;grid-gap:9px;grid-template-columns:54px 1fr;align-items:center;padding:9px;background:#fff;box-shadow:inset 0 -1px 0 #e5e3e3}.PChatPreview_avatar_2-RvY{width:54px;height:54px;padding:2px;background:#fff;border:2px solid #f2f2f2;border-radius:45px}.PChatPreview_avatarContent_wsPUH{width:46px;height:46px;background:#f2f2f2;border-radius:50%}.PChatPreview_content_1qtC0{display:grid;grid-gap:12px}.PChatPreview_title_3EpLf{width:65%;height:12px}.PChatPreview_description_er-eZ,.PChatPreview_title_3EpLf{background:#f2f2f2;border-radius:6px}.PChatPreview_description_er-eZ{width:45%;height:9px}", ""]);
// Exports
exports.locals = {
	"section": "PChatPreview_section_2NM4L",
	"avatar": "PChatPreview_avatar_2-RvY",
	"avatarContent": "PChatPreview_avatarContent_wsPUH",
	"content": "PChatPreview_content_1qtC0",
	"title": "PChatPreview_title_3EpLf",
	"description": "PChatPreview_description_er-eZ"
};
module.exports = exports;
