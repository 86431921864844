<template>
  <v-button
    :class="[$style.button, $style[size]]"
    :href="href"
    :to="to"
    :size="size"
    :round="round"
    :is-div="isDiv"
    :is-disabled="isDisabled"
    :is-disabled-padding="true"
    color="primary"
    @click="$emit('click', $event)"
  >
    <div :class="[$style.circle, $style[size]]">
      <svg
        :class="[$style.icon, $style[size]]"
        viewBox="0 0 8 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable -->
        <path
          d="M5.73584 10.2168C5.73584 9.80176 5.604 9.45996 5.34033 9.19141C5.07666 8.91797 4.63721 8.67383 4.02197 8.45898C3.40674 8.23926 2.92822 8.03662 2.58643 7.85107C1.44873 7.24072 0.879883 6.3374 0.879883 5.14111C0.879883 4.33057 1.12646 3.66406 1.61963 3.1416C2.11279 2.61914 2.78174 2.30908 3.62646 2.21143V0.614746H4.79834V2.22607C5.64795 2.34814 6.30469 2.70947 6.76855 3.31006C7.23242 3.90576 7.46436 4.68213 7.46436 5.63916H5.69189C5.69189 5.02393 5.55273 4.54053 5.27441 4.18896C5.00098 3.83252 4.62744 3.6543 4.15381 3.6543C3.68506 3.6543 3.31885 3.78125 3.05518 4.03516C2.7915 4.28906 2.65967 4.65283 2.65967 5.12646C2.65967 5.55127 2.78906 5.89307 3.04785 6.15186C3.31152 6.40576 3.75586 6.64746 4.38086 6.87695C5.00586 7.10645 5.49658 7.31885 5.85303 7.51416C6.20947 7.70947 6.50977 7.93408 6.75391 8.18799C6.99805 8.43701 7.18604 8.7251 7.31787 9.05225C7.44971 9.37939 7.51562 9.7627 7.51562 10.2021C7.51562 11.0273 7.26172 11.6963 6.75391 12.209C6.25098 12.7217 5.54785 13.0244 4.64453 13.1172V14.5454H3.47998V13.1245C2.51318 13.0171 1.76855 12.6704 1.24609 12.0845C0.728516 11.4985 0.469727 10.7222 0.469727 9.75537H2.24951C2.24951 10.3706 2.40332 10.8467 2.71094 11.1836C3.02344 11.5205 3.46289 11.689 4.0293 11.689C4.58594 11.689 5.0083 11.5547 5.29639 11.2861C5.58936 11.0176 5.73584 10.6611 5.73584 10.2168Z"
        />
        <!-- eslint-enable -->
      </svg>
    </div>
    <span :class="$style.content">{{ $t('sendTip') }}</span>
  </v-button>
</template>
<script>
import Vue from 'vue'

import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'SendTip',
  components: { VButton },
  props: {
    href: String,
    to: String,
    size: {
      default: 'md',
      type: String,
    },
    round: {
      default: 'all',
      type: String,
    },
    isDiv: Boolean,
    isDisabled: Boolean,
    isDisabledPadding: Boolean,
  },
})
</script>

<style lang="scss" module>
.button {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: center;
  column-gap: 6px;
  border: 0;
}

.content {
  align-self: center;
  justify-self: center;
  width: 100%;
  padding: 6px 9px 6px 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}

.circle {
  display: grid;

  height: 100%;
  background-color: $secondary;
  border-radius: 100%;

  &.sm {
    width: 22px;
  }

  &.md {
    width: 23px;
  }

  &.lg {
    width: 25.5px;
  }

  &.xl {
    width: 25.5px;
  }
}

.icon {
  align-self: center;
  justify-self: center;
  width: 14px;
  height: 12px;
  fill: $primary;

  &.sm {
    width: 6px;
    height: 13px;
  }

  &.md {
    width: 8px;
    height: 14px;
  }

  &.lg {
    width: 10px;
    height: 17px;
  }

  &.xl {
    width: 11px;
    height: 19px;
  }
}
</style>
