// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VReportMenu_content_3SuGo{position:relative;overflow:hidden;background:#f8f8f8;box-shadow:inset 0 1px 0 #e5e3e3}", ""]);
// Exports
exports.locals = {
	"content": "VReportMenu_content_3SuGo"
};
module.exports = exports;
