/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pin': {
    width: 13,
    height: 17,
    viewBox: '0 0 13 17',
    data: '<path pid="0" d="M10 5.5C10 2.462 11.343 0 13 0H0c1.657 0 3 2.462 3 5.5S1.657 11 0 11h6v6h1v-6h6c-1.657 0-3-2.462-3-5.5zM7 10H2.938C3.603 8.85 4 7.28 4 5.5 4 3.72 3.603 2.15 2.938 1H10.062C9.397 2.15 9 3.72 9 5.5c0 1.78.397 3.35 1.062 4.5H7z" _fill="#fff"/>'
  }
})
