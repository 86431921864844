/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 64',
    data: '<g data-name="6-twitter"><rect pid="0" height="64" rx="11.2" ry="11.2" transform="rotate(180 32 32)" width="64" _fill="#00aade"/><path pid="1" d="M47.2 20.8a8 8 0 004-4.8c-1.6 1.6-5 2.13-5 2.13a8 8 0 00-13.83 7.47H32a23.45 23.45 0 01-16.8-8.8s-4.8 6.4 2.4 11.2c0 0-3.2 0-4-.8 0 0-.8 4.8 6.4 8a3.86 3.86 0 01-3.2 0c0 3.09 3.22 5.6 7.2 5.6 0 2.21-5.37 4-12 4 10.4 7.2 35.2 5.6 36.8-21.6l4-4z" _fill="#fff"/></g>'
  }
})
