// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileImages_wrapper_24Ig5{display:flex;flex-direction:column;gap:1px}", ""]);
// Exports
exports.locals = {
	"wrapper": "ProfileImages_wrapper_24Ig5"
};
module.exports = exports;
