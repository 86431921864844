/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'zero': {
    width: 10,
    height: 16,
    viewBox: '0 0 10 16',
    data: '<path pid="0" d="M2.253 0h5.494l1.035.96-1.035.96H2.253L1.218.96 2.253 0zm0 14.08h5.494l1.035.96-1.035.96H2.23l-1.012-.96 1.035-.96zM7.93 8.96L8.966 8 7.93 7.083V2.09l1.035-.96L10 2.09V7.04L8.966 8 10 8.96v4.95l-1.034.96-1.035-.96V8.96zM0 8.96L1.034 8 0 7.04V2.09l1.034-.96 1.035.96v4.95L1.034 8l1.035.96v4.95l-1.035.96L0 13.91V8.96zm6.207-6.784h1.448v1.536L3.793 13.824H2.345v-1.536L6.207 2.176z"/>'
  }
})
