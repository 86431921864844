/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'unmuted': {
    width: 12,
    height: 12,
    viewBox: '0 0 14 12',
    data: '<path pid="0" d="M3.998 4l4-4v12l-4-4h-3V4h3zm7.072-2.664l-.707.707A5.56 5.56 0 0112.003 6c0 1.495-.583 2.9-1.64 3.956l.707.708A6.548 6.548 0 0013.002 6a6.552 6.552 0 00-1.932-4.664zM9.696 2.71l-.707.707a3.657 3.657 0 010 5.165l.707.707a4.658 4.658 0 000-6.579z"/>'
  }
})
