import { Module } from '../types'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'

const module: Module = {
  state: () => ({
    prompts: [],
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default module
