<template>
  <div
    :class="[
      $style.message,
      isMyMessage && $style.myMessage,
      isChatGPTBot && $style.canCopy,
      'markdown-message',
    ]"
  >
    <VAvatar v-if="isSomeonesMessage" :src="imageUrl" :srcset="imagePreviewUrl" />
    <div :class="[$style.content, isChatGPTBot && $style.wideContent]">
      <!-- <chat-invite /> -->
      <ChatVoiceMessage v-if="message.type === 3" :message="message" />
      <template v-else>
        <ChatMessageReference
          ref="reference"
          v-if="hasParent"
          :message="parentMessage"
          :is-my-message="isMyMessage"
          is-replied
          :is-loading="isLoading"
          @click.native="goToMessage"
        />
        <h2 v-if="hasSenderName" :class="$style.name">
          {{ senderName }}
        </h2>
        <template v-if="hasMedia">
          <LoadingLoader v-if="isWaiting" is-loading :size="10" :delay="0" />
          <ChatMedia v-else :media="messageMedia" :is-openable="!isSelected" />
        </template>
        <div :class="$style.textWrapper">
          <div v-if="isErrorMessage" :class="$style.text">
            {{ errorMessageText }}
          </div>
          <div
            ref="message-content"
            v-else-if="markdownBody"
            :class="$style.text"
            v-html="markdownBody"
          />

          <!-- <chat-donate-button :amount="3" target="for new IPhone" /> -->
          <div :class="$style.wrapper">
            <icon v-if="isChannel" name="eye" :class="$style.icon" />
            <span :class="$style.textSub">{{ messageCreatedDate }}</span>
            <div v-if="isMyMessage" :class="$style.status">
              <icon v-if="isWaiting" name="clock" :class="[$style.statusIcon, $style.waiting]" />
              <icon v-else name="check-sm" :class="[$style.statusIcon, $style.send]" />
              <icon
                v-if="isReadMessage"
                name="check-sm"
                :class="[$style.statusIcon, isReadMessage && $style.read]"
              />
            </div>
          </div>
        </div>
        <div :class="$style.triangle" />
        <ChatMessageActions
          v-if="isActions"
          :message="message"
          :is-limit="isLimit"
          :is-unknown-error="isUnknownError"
          @resend-last-message="$emit('resend-last-message')"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import CopyToClipboard from 'copy-to-clipboard'
import { formatDateToType } from '@utils/formatDate'
import i18n from '@plugins/i18n'

import VAvatar from '@layouts/avatar/VAvatar.vue'
import {
  goToPinnedMessage,
  isLimitMessage,
  isSenderApp,
  isSwearingError,
  isUnknownError,
} from '@modules/messages/services'
import LoadingLoader from '@loaders/list/LoadingLoader.vue'
import ChatVoiceMessage from '@modules/chat/modules/chat-voice-message/ChatVoiceMessage.vue'
import { GptVersion } from '@common-types/chat'
import ChatMessageActions from './ChatMessageActions.vue'
import ChatMedia from './ChatMedia.vue'
import ChatMessageReference from './ChatMessageReference.vue'
import ChatDonateButton from './ChatDonateButton.vue'
import ChatInvite from './ChatInvite.vue'

export default Vue.extend({
  name: 'ChatMessage',
  components: {
    ChatVoiceMessage,
    ChatMessageActions,
    LoadingLoader,
    ChatMessageReference,
    VAvatar,
    ChatMedia,
  },
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    markdownBody: {
      type: String,
      default: '',
    },
    isMyMessage: Boolean,
    isSelected: Boolean,
    isDemo: Boolean,
  },
  data() {
    return {
      isLoading: false,
      isLinkLoading: false,
    }
  },
  computed: {
    ...mapGetters('Chat', [
      'chatId',
      'chatName',
      'getChatMessage',
      'isChatPersonal',
      'isChatCommon',
      'isChannel',
      'chatIsDemoMode',
      'chatGptVersion',
    ]),
    ...mapGetters('Chat', ['isChatGPTBot']),
    ...mapGetters('Locale', ['locale']),
    isActions() {
      return !this.chatIsDemoMode
    },
    isLimit() {
      return isLimitMessage(this.message)
    },
    isUnknownError() {
      return isUnknownError(this.message)
    },
    isSwearingError() {
      return isSwearingError(this.message)
    },
    isErrorMessage() {
      return this.isLimit || this.isUnknownError || this.isSwearingError
    },
    gptVersionLimitMessageI18nCaption() {
      return this.chatGptVersion === GptVersion.GPT_4 ? 'limit4' : 'limit3'
    },
    errorMessageText() {
      const caption = this.isLimit
        ? this.gptVersionLimitMessageI18nCaption
        : this.isSwearingError
        ? 'swearing'
        : 'requestError'
      return this.$t(`gpt.${caption}`)
    },
    hasSenderName() {
      return this.senderName && (this.isChannel || this.isSomeonesMessage) && !this.isDemo
    },
    hasMedia() {
      const hasTempMedia = !!this.message?.mediaObjectsIds?.length
      const hasMedia = !!(this.messageMedia && this.messageMedia.length)
      return this.isWaiting ? hasTempMedia : hasMedia
    },
    modeApp() {
      return this.message?.mode_app ?? null
    },
    avatar() {
      return (
        (isSenderApp(this.message.mode) ? this.modeApp?.image : this.messageClient?.avatar) ?? null
      )
    },
    messageCreatedDate() {
      const { created } = this.message ?? {}
      return created ? this.distanceDate(created) : ''
    },
    messageClient() {
      return this.message?.client ?? null
    },
    messageMedia() {
      return this.message?.media_objects ?? null
    },
    isReadMessage() {
      return this.message?.read ?? null
    },
    senderName() {
      return isSenderApp(this.message?.mode)
        ? this.modeApp?.name
        : this.isChannel
        ? this.chatName
        : this.messageClient?.name || `#${this.messageClient?.id ?? ''}`
    },
    imageUrl() {
      return this.avatar?.content_url ?? null
    },
    imagePreviewUrl() {
      return this.avatar?.preview_url ?? ''
    },
    isWaiting() {
      return !!this.message?.temp
    },
    hasParent() {
      return this.message?.has_parent
    },
    parentMessage() {
      return (
        (this.message?.parent?.client
          ? this.message?.parent
          : this.getChatMessage(this.message?.parent?.id)) ?? this.message?.parent
      )
    },
    isSomeonesMessage() {
      return (this.isChatCommon && !this.isMyMessage) || this.isDemo
    },
  },
  mounted() {
    this.bindCopyCodeToButtons()
  },
  methods: {
    bindCopyCodeToButtons() {
      const content = this.$refs['message-content']
      if (!content) {
        return
      }
      content.querySelectorAll('.hljs-code-container').forEach((codeContainer) => {
        const copyButton = codeContainer.querySelector('.hljs-copy-button')
        const copyButtonText = copyButton.querySelector('.hljs-copy-button-text')
        const codeBody = codeContainer.querySelector('code')
        copyButton.onclick = () => {
          CopyToClipboard(codeBody.textContent ?? '')

          copyButtonText.innerHTML = 'Copied!'
          copyButton.dataset.copied = 'true'

          setTimeout(() => {
            copyButtonText.innerHTML = 'Copy code'
            copyButton.dataset.copied = 'false'
          }, 2000)
        }
      })
    },
    distanceDate(date) {
      return formatDateToType(date, 'HH:mm', i18n.locale)
    },
    async goToMessage() {
      if (!this.parentMessage || this.chatIsDemoMode) {
        return
      }
      this.isLoading = true
      await goToPinnedMessage({
        message: this.parentMessage,
        channel: this.message?.channel,
        store: this.$store,
      })
      this.scrollToMessage(this.parentMessage)
      this.isLoading = false
    },
    scrollToMessage(message) {
      this.$nextTick(() => {
        const element = document.getElementById(`message-${message.id}`)
        if (element) {
          element.scrollIntoView({
            behavior: 'instant',
            block: 'center',
          })
          this.highlightMessage(element)
        }
      })
    },
    highlightMessage(element) {
      element.classList.add('message--highlighted')
      setTimeout(() => {
        element.classList.remove('message--highlighted')
      }, 1000)
    },
  },
})
</script>
<style lang="scss" module>
.message {
  position: relative;
  display: flex;
  color: black;
  column-gap: 6px;
  user-select: none;
  max-width: 100%;
  margin-bottom: 6px;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 9px;
    max-width: 300px;
    padding: 9px;
    background: #ffffff;
    border: 1px solid $secondary-light;
    border-radius: 0 6px 6px 6px;

    &::after {
      position: absolute;
      top: -0.5px;
      left: -6px;
      display: block;
      box-sizing: border-box;
      width: 7px;
      height: 7px;
      background: $secondary-light;
      transform: rotate(90deg);
      content: '';
      clip-path: polygon(0 0, 0 100%, 100% 0, calc(100% - 2px) 0, 1px calc(100% - 2px), 1px 0);
    }

    .name {
      color: $grey-dark;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
    }

    .textWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 9px;
      align-items: flex-end;

      .text {
        max-width: 100%;
        font-size: 14px;

        p,
        ul,
        ol {
          line-height: 20px;
          word-break: break-word;
          list-style: decimal inside;

          li {
            p {
              display: inline;
            }
          }
        }

        &Sub {
          color: $secondary-medium;
          font-size: 9px;
          line-height: 9px;

          &.chat {
            color: rgba(black, 0.5);
          }
        }

        code {
          display: inline-block;
          max-width: 100%;
          font-weight: 600;
        }
      }

      .wrapper {
        display: flex;
        flex-flow: row nowrap;
        gap: 5px;
        align-items: center;
        justify-content: space-between;
      }
    }

    .triangle {
      position: absolute;
      top: -0.5px;
      left: -6px;
      width: 0;
      height: 0;
      border-color: transparent #fff transparent transparent;
      border-style: solid;
      border-width: 0 7px 7px 0;
    }
  }
  .wideContent {
    max-width: 100%;
  }
}

.myMessage {
  color: $main-text-color;
  justify-self: flex-end;

  .content {
    grid-row-start: 1;
    grid-column-start: 1;
    background-color: $primary-light-background-adaptive;
    border: 1px solid $primary;
    border-radius: 6px 0 6px 6px;

    &::after {
      top: 0;
      display: none;
    }

    .name {
      text-align: right;
    }

    .wrapper {
      gap: 10px;
      justify-content: flex-start;

      .textSub {
        color: $main-text-color;
      }

      svg {
        fill: $main-text-color;
      }
    }

    .triangle {
      top: 0;
      right: -6px;
      left: auto;
      border-color: transparent $primary-light-background-adaptive transparent transparent;
      transform: scale(-1, 1.2);
    }
  }
}

.canCopy {
  user-select: auto;
}

.icon {
  width: 13px;
  height: 9px;
  color: $secondary-medium;
}

.status {
  font-size: 0;

  &Icon {
    position: relative;
    width: 10px;
    height: 10px;
    color: $secondary;

    &.send {
      position: relative;
      z-index: 2;
    }

    &.waiting {
      width: 15px;
      height: 15px;
    }

    &.read {
      position: relative;
      left: -7px;
      z-index: 1;
    }
  }
}
</style>
