/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lab': {
    width: 14,
    height: 18,
    viewBox: '0 0 14 18',
    data: '<path pid="0" d="M5.007 3c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zm3.5-2c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-1 4c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm4.71 13H1.797c-1.59 0-2.39-1.93-1.27-3.06l4.47-4.48V6h4v4.46l4.47 4.48c1.13 1.13.33 3.06-1.27 3.06h.02zm-7.21-1.49c0-.28-.22-.5-.5-.5s-.5.22-.5.5.22.5.5.5.5-.22.5-.5zm2-6c0-.28-.22-.5-.5-.5s-.5.22-.5.5.22.5.5.5.5-.22.5-.5zm2 2.5c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1z"/>'
  }
})
