// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/images/valor_background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Chat_section_1n8B2{display:flex;flex-direction:column;width:100%;height:100%;background:#f8f8f8}.Chat_sectionIos_MhsMq{height:calc(100% - 65px)}.Chat_section_1n8B2 .Chat_overlay_1_nsf{position:absolute;z-index:1;width:100%;height:100%;background:#000;opacity:.3;transition:opacity .3s}.Chat_section_1n8B2 .Chat_content_3GiTP{position:relative;display:flex;flex:1;flex-direction:column;width:100%;overflow-y:hidden}.Chat_section_1n8B2 .Chat_content_3GiTP .Chat_scrollWrapper_1CraW{position:relative;width:100%;height:100%;padding:9px;overflow-x:hidden;overflow-y:scroll}.Chat_section_1n8B2 .Chat_content_3GiTP .Chat_scrollWrapper_1CraW .Chat_waiting_1wPFU{display:flex;align-items:center;justify-content:center;padding:15px 0;color:var(--supster-secondary)}.Chat_section_1n8B2 .Chat_content_3GiTP .Chat_scrollWrapper_1CraW .Chat_waiting_1wPFU .Chat_waitingDots_3FQuH{width:20px}.Chat_section_1n8B2 .Chat_content_3GiTP .Chat_scrollWrapper_1CraW .Chat_waitingError_3HFpL{display:flex;align-items:center;justify-content:center;padding:15px 0;color:#e54d42}.Chat_section_1n8B2 .Chat_content_3GiTP .Chat_noScroll_2baxq{overflow:hidden}.Chat_section_1n8B2 .Chat_background_3rTes{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat;background-size:25%}.Chat_section_1n8B2 .Chat_input_1RwjA{flex:0}", ""]);
// Exports
exports.locals = {
	"section": "Chat_section_1n8B2",
	"sectionIos": "Chat_sectionIos_MhsMq",
	"overlay": "Chat_overlay_1_nsf",
	"content": "Chat_content_3GiTP",
	"scrollWrapper": "Chat_scrollWrapper_1CraW",
	"waiting": "Chat_waiting_1wPFU",
	"waitingDots": "Chat_waitingDots_3FQuH",
	"waitingError": "Chat_waitingError_3HFpL",
	"noScroll": "Chat_noScroll_2baxq",
	"background": "Chat_background_3rTes",
	"input": "Chat_input_1RwjA"
};
module.exports = exports;
