<template>
  <v-drawer name="access-denied">
    <div :class="$style.container">
      <p :class="$style.text">
        {{ $t('client.accessDenied') }}
      </p>
      <v-button color="black-primary-light" size="xxl" @click="onClose">
        {{ $t('continue') }}
      </v-button>
    </div>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'

export default Vue.extend({
  name: 'AccessDeniedDrawer',
  components: { VDrawer, VButton },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    onClose() {
      this.setDrawer({ name: 'access-denied', isVisible: false })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 25px 15px 15px 15px;

  .text {
    text-align: center;
  }
}
</style>
