// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RegistrationForm_form_GPuKa{grid-auto-rows:min-content;grid-auto-flow:row}.RegistrationForm_caption_2w75w{padding:15px;color:rgba(0,0,0,.5);font-size:14px;text-align:left}.RegistrationForm_inner_1naGr{display:grid;row-gap:9px}.RegistrationForm_input_2x0tn{margin:0;padding:0}.RegistrationForm_wrapper_3ydn2{display:grid;grid-template-columns:repeat(2,1fr);-moz-column-gap:20px;column-gap:20px}.RegistrationForm_buttonContainer_3sptP{padding:15px}.RegistrationForm_button_3pwIs{display:grid;grid-auto-flow:column;justify-content:center;-moz-column-gap:9px;column-gap:9px}.RegistrationForm_title_22O6b{font-weight:500;line-height:12px;text-transform:uppercase}.RegistrationForm_text_2unLE,.RegistrationForm_title_22O6b{font-size:12px}.RegistrationForm_text_2unLE{font-weight:400;line-height:18px}.RegistrationForm_agreement_1uXf1{padding:15px 45px;text-align:center;border-top:1px solid #e5e3e3;box-shadow:inset 0 -1px 0 #e5e3e3}.RegistrationForm_link_2tK73{color:#000;font-weight:600;font-size:12px;text-decoration:underline;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"form": "RegistrationForm_form_GPuKa",
	"caption": "RegistrationForm_caption_2w75w",
	"inner": "RegistrationForm_inner_1naGr",
	"input": "RegistrationForm_input_2x0tn",
	"wrapper": "RegistrationForm_wrapper_3ydn2",
	"buttonContainer": "RegistrationForm_buttonContainer_3sptP",
	"button": "RegistrationForm_button_3pwIs",
	"title": "RegistrationForm_title_22O6b",
	"text": "RegistrationForm_text_2unLE",
	"agreement": "RegistrationForm_agreement_1uXf1",
	"link": "RegistrationForm_link_2tK73"
};
module.exports = exports;
