<template>
  <div :class="$style.container">
    <VReadMore v-if="body" :class="$style.body" size="md" :content="post.body" name="readOn" />
    <div :class="$style.buttons">
      <VLike
        :class="$style.button"
        :count="likeCount"
        :is-liked="isLiked"
        :is-loading="isLikeLoading"
        @like="onLike"
      />
      <VPostComment v-if="!!isComments && isAccessible" :class="$style.button" :post="post" />
      <VPostShare :class="$style.button" :post="post" />
    </div>
    <SendTip v-if="false" :class="$style.sendTip" size="md" />
    <VDateGap :class="$style.date" :date="created" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'

import VReadMore from '@layouts/VReadMore.vue'
import SendTip from '@layouts/SendTip.vue'
import VDateGap from '@layouts/VDateGap.vue'
import VLike from '@layouts/like/VLike.vue'
import VPostComment from './VPostComment.vue'
import VPostShare from './VPostShare.vue'

export default Vue.extend({
  name: 'VPostFooter',
  components: { VPostShare, VPostComment, VLike, VReadMore, VDateGap, SendTip },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLikeLoading: false,
    }
  },
  computed: {
    created() {
      return this.post?.created ?? ''
    },
    body() {
      return this.post?.body ?? ''
    },
    isAccessible() {
      return this.post?.is_accessible ?? false
    },
    isComments() {
      return this.post?.comments_enabled ?? false
    },
    isLiked() {
      return this.post?.is_liked ?? false
    },
    likeCount() {
      return this.post?.like_count ?? 0
    },
  },
  methods: {
    ...mapActions('Post', ['setPostLike']),
    async onLike() {
      this.isLikeLoading = true
      await this.setPostLike({ isLike: !this.post.is_liked, id: this.post.id })
      this.isLikeLoading = false
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-gap: 12px;
  padding: 12px;
}

.body {
  white-space: pre;
}

.buttons {
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  column-gap: 9px;
}

.button {
  justify-self: flex-start;
  width: auto;
}

.sendTip {
  align-self: center;
}

.date {
  font-size: 12px;
  line-height: 18px;
  opacity: 0.4;
}
</style>
