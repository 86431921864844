/* eslint-disable camelcase */
import Config from '@config/index'
import CommentsApi from '@services/comments'
import { Comment, Comments, GetParams, isSubComment } from '@services/comments/types'
import { Actions } from './types'

const actions: Actions = {
  async getComments(
    { dispatch, commit },
    { scroll, isSub, params }: { scroll: InfiniteType; isSub: boolean; params: GetParams }
  ) {
    try {
      if (isSub) {
        dispatch('loadSubComments', { parentId: params?.parent_id, params })
      } else {
        dispatch('loadComments', { scroll, params })
      }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },

  async loadComments({ dispatch, commit, getters }, { scroll, params }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const pageCorrection = getters.getPageCorrectionRatio()
      commit('resetExtraCommentsCount')
      const limit = Config.Comments.COMMENTS_PAGE_LIMIT
      const updatedParams = {
        ...params,
        page: getters.commentsPage,
        limit,
      }
      if (pageCorrection) {
        const page = getters.commentsPage + pageCorrection
        commit('setPage', page)
        dispatch('loadComments', { scroll, params: { ...updatedParams, page } })
        return
      }
      const comments = await CommentsApi.getComments({ ...updatedParams })
      dispatch('pushComments', { comments, scroll })
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },

  async loadSubComments({ dispatch, commit, getters }, { parentId, params }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const page = getters.getThreadPage(parentId)
      const pageCorrection = getters.getPageCorrectionRatio(parentId)
      const limit = Config.Comments.SUB_COMMENTS_PAGE_LIMIT
      const updatedParams = { ...params, page, limit }
      commit('resetExtraSubCommentsCount', { parentId })
      if (pageCorrection) {
        const updatedPage = page + pageCorrection
        commit('setThreadPage', { parentId, page: updatedPage })
        dispatch('loadSubComments', { parentId, params: { ...updatedParams, page: updatedPage } })
        return
      }
      const subComments = await CommentsApi.getComments({ ...updatedParams })
      dispatch('pushSubComments', { subComments, parentId })
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },

  async getComment({ dispatch, commit }, id) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      await CommentsApi.getComment(id)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postComment({ dispatch, commit }, { params }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const comment = await CommentsApi.postComment(params)
      if (isSubComment(comment)) {
        commit('addSubComment', comment)
        commit('replaceComment', comment.parent)
      } else {
        commit('addComment', comment)
      }
      const { products, ...postDefinition } = comment.post
      commit('Post/changePost', postDefinition, { root: true })
      commit('Feed/setFeedPost', comment.post, { root: true })
      commit('Profile/Images/setPost', comment.post, { root: true })
      commit('Profile/Videos/setPost', comment.post, { root: true })
      commit('Profile/Videos/Feed/setPost', comment.post, { root: true })
      commit('Profile/Images/Feed/setPost', comment.post, { root: true })
      commit('Search/Posts/setPost', comment.post, { root: true })
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async putComment({ dispatch, commit }, { id, text, isSub }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const comment = await CommentsApi.putComment(id, { text })
      if (isSub) {
        commit('replaceSubComment', comment)
      } else {
        commit('replaceComment', comment)
      }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async deleteComment({ dispatch, commit, getters }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { commentId, parentId } = getters.selectedCommentParams ?? {}
      const success = await CommentsApi.deleteComment(commentId)
      if (success) {
        commit('setSelectedCommentParams', null)
        if (parentId) {
          dispatch('deleteSubComment', { commentId, parentId })
        } else {
          dispatch('removeComment', commentId)
        }
      }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  removeComment({ state, commit }, commentId) {
    commit('removeComment', commentId)
    const needMarkAsDeleted = !state.addedCommentIds.includes(commentId)
    if (needMarkAsDeleted) {
      commit('increaseDeletedCommentsCount')
    }
    const addedCommentIds = state.addedCommentIds.filter((id) => id !== commentId)
    commit('setAddedCommentIds', addedCommentIds)
    commit('setAddedCommentsCount', addedCommentIds.length)
  },
  deleteCommentsByClientId({ state, commit }, clientId) {
    const deletingCommentIds = state.comments
      .filter(({ client }) => client.id === clientId)
      .map(({ id }) => id)
    commit('removeThreads', deletingCommentIds)
    commit('removeSubCommentsMapItems', deletingCommentIds)
    commit('deleteComments', deletingCommentIds)
    commit('deleteSubCommentsByClientId', clientId)
  },
  async setCommentLike({ dispatch, commit }, { isLike, id, isSub }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const comment = isLike
        ? await CommentsApi.postCommentLike(id)
        : await CommentsApi.postCommentUnlike(id)
      isSub ? commit('replaceSubComment', comment) : commit('replaceComment', comment)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },

  pushComments(
    { state, commit },
    {
      comments,
      scroll,
    }: { comments: Comments; scroll?: { loaded: () => void; complete: () => void } }
  ) {
    const updatedComments = comments.filter(
      (comment: Comment) =>
        !state.comments.some(({ id }) => {
          return id === comment.id
        })
    )
    const hasMore =
      !!updatedComments?.length && comments?.length >= Config.Comments.COMMENTS_PAGE_LIMIT
    if (scroll) {
      hasMore ? scroll.loaded() : scroll.complete()
    }
    commit('pushComments', updatedComments)
    if (updatedComments?.length) {
      commit('increasePage')
    }
  },

  pushSubComments({ commit }, { subComments, parentId }) {
    commit('pushSubComments', { subComments, parentId })
    if (subComments?.length) {
      commit('increaseThreadPage', parentId)
    }
  },

  deleteSubComment({ commit }, { commentId, parentId }) {
    commit('deleteSubComment', { commentId, parentId })
    commit('decreaseSubCommentsCount', parentId)
  },
}

export default actions
