// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartProduct_container_2PsJC{position:relative;display:grid;grid-template-columns:90px 1fr;padding:15px;-moz-column-gap:15px;column-gap:15px;background-color:#fff}.CartProduct_delete_3XFKt{position:absolute!important;top:0;right:0;width:auto!important}.CartProduct_image_3Hhaf{width:90px!important;height:90px!important;overflow:hidden;border-radius:6px}.CartProduct_content_2e2-T{grid-template-rows:1fr auto;padding:9px 0;color:var(--supster-secondary);font-weight:400;font-size:12px;line-height:12px}.CartProduct_content_2e2-T,.CartProduct_wrapper_rhd2H{display:grid;row-gap:9px}.CartProduct_wrapper_rhd2H{position:relative;grid-auto-rows:min-content;grid-auto-flow:row}.CartProduct_name_5qKrL{width:calc(100% - 15px);overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.CartProduct_properties_16l1I{color:#a09f9f}.CartProduct_footer_2iJLT{display:grid;grid-template-columns:repeat(2,min-content);justify-content:space-between;-moz-column-gap:10px;column-gap:10px}.CartProduct_price_narHc{align-self:center;font-weight:500}", ""]);
// Exports
exports.locals = {
	"container": "CartProduct_container_2PsJC",
	"delete": "CartProduct_delete_3XFKt",
	"image": "CartProduct_image_3Hhaf",
	"content": "CartProduct_content_2e2-T",
	"wrapper": "CartProduct_wrapper_rhd2H",
	"name": "CartProduct_name_5qKrL",
	"properties": "CartProduct_properties_16l1I",
	"footer": "CartProduct_footer_2iJLT",
	"price": "CartProduct_price_narHc"
};
module.exports = exports;
