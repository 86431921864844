// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppRightSectionBurger_burger_3LnCL{position:relative;width:32px;height:32px;border:10px solid transparent;border-radius:50%;display:flex;flex-direction:column;justify-content:center;gap:6px;box-sizing:content-box;transition:background-color .2s ease-in-out;cursor:pointer;z-index:3}.AppRightSectionBurger_burger_3LnCL:hover{background-color:hsla(0,0%,100%,.3)}.AppRightSectionBurger_burger_3LnCL .AppRightSectionBurger_line_22D1Q{width:100%;height:2px;background-color:var(--supster-main-text-color);transition:transform .2s ease-in-out}.AppRightSectionBurger_close_3TqbJ .AppRightSectionBurger_line_22D1Q{background-color:#000}.AppRightSectionBurger_close_3TqbJ .AppRightSectionBurger_line1_3J0Vz{transform:rotate(45deg) translate(3px,3px)}.AppRightSectionBurger_close_3TqbJ .AppRightSectionBurger_line2_1Uc_X{transform:rotate(-45deg) translate(3px,-3px)}", ""]);
// Exports
exports.locals = {
	"burger": "AppRightSectionBurger_burger_3LnCL",
	"line": "AppRightSectionBurger_line_22D1Q",
	"close": "AppRightSectionBurger_close_3TqbJ",
	"line1": "AppRightSectionBurger_line1_3J0Vz",
	"line2": "AppRightSectionBurger_line2_1Uc_X"
};
module.exports = exports;
