import InstallApi from '@services/install'
import { Actions } from './types'

const actions: Actions = {
  async getInstall({ commit, dispatch, rootGetters }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { app, posts } = await InstallApi.getInstall({
        cid: rootGetters['Me/cId'],
        subid: rootGetters['Me/subId'],
        stream: rootGetters['Me/stream'],
        ref: rootGetters['Me/refId'],
      })
      commit('App/setApp', app, { root: true })
      commit('setPosts', posts)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
