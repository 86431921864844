// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VImage_container_3MnMu{display:flex;align-items:center;justify-content:center;width:100%;height:100%;background:#dcd6e7}.VImage_container_3MnMu.VImage_noImage_DHS_W{background-color:#f2f2f2}.VImage_image_22rEl{width:100%;height:100%;-o-object-fit:cover;object-fit:cover}.VImage_icon_1QlMI{width:40px;color:#a09f9f}", ""]);
// Exports
exports.locals = {
	"container": "VImage_container_3MnMu",
	"noImage": "VImage_noImage_DHS_W",
	"image": "VImage_image_22rEl",
	"icon": "VImage_icon_1QlMI"
};
module.exports = exports;
