<template>
  <div :class="$style.container" @click.stop.prevent="onClick">
    <img :class="$style.image" :src="src" alt="Image" />
    <div :class="$style.content">
      <icon :class="$style.icon" name="eye-hide" width="33" height="33" />
    </div>
    <div :class="$style.labelContainer">
      <span :class="$style.label">{{ $t('hiddenPublication') }}</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'VPostPreviewBlocked',
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('Blocks', ['getBlockId']),
    src() {
      return this.post.media_objects?.[0]?.preview_url ?? ''
    },
    isBig() {
      return (
        this.$route.name === 'Post' || this.$route.name === 'Feed' || this.$route.name === 'Product'
      )
    },
    postAppName() {
      return this.post?.app?.name
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    onClick() {
      this.setDrawer({
        name: 'blocking',
        isVisible: true,
        params: {
          isUnblocking: true,
          blockId: this.getBlockId(this.postAppName),
          appName: this.postAppName,
        },
      })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100%;
  overflow: hidden;

  .image {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: blur(10px);
  }

  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .labelContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 6px;

    .label {
      display: block;
      width: 100%;
      padding: 6px;
      color: white;
      font-weight: 500;
      font-size: 12px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      line-height: 12px;
      text-align: center;
      background-color: black;
      border-radius: 6px 6px 3px 3px;
      cursor: pointer;
    }
  }
}
</style>
