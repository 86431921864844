<template>
  <transition name="fade-kit" appear>
    <div v-if="isVisible" :class="$style.container" @click.self="onClose">
      <div :class="[$style.content, $style[color]]">
        <v-form
          :class="[$style.inner, className, noPadding && $style.noPadding]"
          @input="$emit('update:is-valid', $event)"
          @submit="$emit('submit')"
        >
          <h2 v-if="title" :class="$style.title">{{ title }}</h2>
          <h3 v-if="description" :class="$style.description">{{ description }}</h3>
          <slot />
          <div v-if="isConfirm" :class="$style.footer">
            <v-button size="md" @click="$emit('cancel')">
              <slot v-if="isCancel" name="cancel" />
              <template v-else> {{ $t('confirm.cancel') }} </template>
            </v-button>
            <v-button
              size="md"
              type="submit"
              :is-loading="isLoading"
              :is-disabled="!isValidFormContent"
              @click="$emit('accept')"
            >
              <slot v-if="isAccept" name="accept" />
              <template v-else> {{ $t('confirm.accept') }} </template>
            </v-button>
          </div>
        </v-form>
      </div>
    </div>
  </transition>
</template>
<script>
import Vue from 'vue'

import VForm from '@elements/VForm.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VModal',
  components: { VForm, VButton },
  props: {
    title: String,
    description: String,
    className: String,
    color: {
      default: 'default',
      type: String,
    },
    noValidation: Boolean,
    noScrollbar: Boolean,
    noPadding: Boolean,
    isConfirm: Boolean,
    isVisible: Boolean,
    isLoading: Boolean,
    isValid: Boolean,
  },
  computed: {
    isValidFormContent() {
      if (this.noValidation) {
        return true
      }
      return this.isValid
    },
    isAccept() {
      return !!this.$slots.accept
    },
    isCancel() {
      return !!this.$slots.cancel
    },
  },
  watch: {
    isVisible: {
      handler(isVisible) {
        if (isVisible) {
          window.addEventListener('keydown', this.onKeyDown)
          this.$bus.$emit('scrollbar', { isVisible: false })
        } else {
          window.removeEventListener('keydown', this.onKeyDown)
          if (!this.noScrollbar) {
            this.$bus.$emit('scrollbar', { isVisible: true })
          }
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    onKeyDown({ code }) {
      if (code === 'Escape') {
        this.onClose()
      }
    },
    onClose() {
      this.$emit('update:is-visible', false)
      this.$emit('close')
    },
  },
})
</script>
<style lang="scss" module>
.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  display: grid;
  width: 100%;
  height: 100vh;
  padding: 15px;
  background-color: rgba(#000000, 0.6);
  backdrop-filter: blur(3px);
}

.content {
  position: relative;
  display: grid;
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 400px;
  height: auto;
  max-height: calc(100vh - 30px);
  overflow: auto;
  color: $secondary;
  border-radius: 6px;
  background: white;

  &.default {
    background-color: white;
  }

  &.primary {
    background-color: $primary;
  }
}

.title {
  width: 100%;
  overflow: hidden;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.closeButton {
  position: absolute !important;
  top: -30px;
  right: 0;
  width: 20px;
  height: 20px;
}

.inner {
  display: grid;
  padding: 15px;
  row-gap: 15px;

  &.noPadding {
    padding: 0;
  }
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}
</style>
