/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-green-rounded': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15 8a7 7 0 100 14 7 7 0 000-14zm-1.375 7.734l4.057-4.058 1.3 1.3-4.058 4.057-1.29 1.291-1.3-1.3-1.316-1.316 1.291-1.29 1.316 1.316z" _fill="#11AC86"/>'
  }
})
