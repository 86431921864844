/* eslint-disable camelcase */
import BlocksApi from '@services/blocks'
import AppBlocksApi from '@services/app-blocks'
import { AppBlock } from '@services/app-blocks/types'
import { Block } from '@services/blocks/types'
import i18n from '@plugins/i18n'
import { Actions } from './types'

const actions: Actions = {
  async getBlocks({ dispatch, commit }): Promise<Block[] | null> {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const blocks = await BlocksApi.getBlocks()
      return blocks.data
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postBlock({ dispatch, rootGetters, commit }, client_id: number) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const app_id: string = rootGetters['App/appId']
      await BlocksApi.postBlock({ app_id, client_id })
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async deleteBlock({ commit, dispatch }, blockId: number) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      await BlocksApi.deleteBlock(blockId)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async deleteBlocks({ commit, dispatch, getters }) {
    try {
      const { blocks }: { blocks: Block[] } = getters
      if (!blocks) {
        return
      }
      const blocksPromises = blocks.map((block) => BlocksApi.deleteBlock(block.id))
      await Promise.all(blocksPromises)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postAppBlock({ commit, dispatch }, { appId, appName }: { [key: string]: string }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const appBlock = await AppBlocksApi.postAppBlock(appId)
      commit('addBlockAppsNames', appName)
      dispatch('Feed/calcBlockedPostsCount', appName, { root: true })
      commit('addBlockIds', { appName, blockId: appBlock?.data?.id })
      commit(
        'Toasts/pushToast',
        {
          type: 'error',
          title: `${i18n.t('inform.profile.title')}`,
          message: `${i18n.t('inform.profile.text')}`,
        },
        { root: true }
      )
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async getAppBlocks({ commit, dispatch }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const response = await AppBlocksApi.getAppBlocks()
      commit('setBlocks', response.data)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async deleteAppBlock(
    { commit, dispatch, rootGetters },
    { appName, blockId }: { appName: string; blockId: number }
  ) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const response = await AppBlocksApi.deleteAppBlock(blockId)
      commit('App/unblockCurrentApp', null, { root: true })
      const currentAppName = appName ?? rootGetters['App/currentApp']?.name
      commit('removeBlockAppsNames', currentAppName)
      commit('removeAppBlockId', currentAppName)
      return response
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return null
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async deleteAppBlocks({ commit, dispatch, getters }) {
    try {
      const { blocks }: { blocks: AppBlock[] } = getters
      if (!blocks) {
        return
      }
      const blocksPromises = blocks.map((block) => AppBlocksApi.deleteAppBlock(block.id))
      await Promise.all(blocksPromises)
      commit('setBlocks', null)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
