import { Module } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const module: Module = {
  state: () => ({
    comments: [],
    threadsMap: {},
    subCommentsMap: {},
    addedCommentsCount: 0,
    addedCommentIds: [],
    deletedCommentsCount: 0,
    page: 1,
    sourceId: null,
    isVisible: false,
    selectedCommentParams: null,
    isEdit: false,
    isReply: false,
  }),
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default module
