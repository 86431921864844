// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileVideosFeed_page_2NL-9{position:relative;display:grid;grid-auto-rows:max-content;grid-auto-flow:row;color:var(--supster-secondary)}", ""]);
// Exports
exports.locals = {
	"page": "ProfileVideosFeed_page_2NL-9"
};
module.exports = exports;
