/* eslint-disable @typescript-eslint/ban-ts-comment */
import moment, { Moment } from 'moment-timezone'

const REFERENCE = moment(new Date())
const TODAY = REFERENCE.clone().startOf('day')
const A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day')
const A_YEAR_OLD = REFERENCE.clone().subtract(12, 'M').startOf('day')

const LOCAL_TZ = moment.tz.guess()

export function formatDateToType(
  date: Moment | Date | string,
  type = 'MMM, dd, yyyy',
  locale: string
): string {
  const newDate = moment.utc(date).tz(LOCAL_TZ)
  return moment(newDate).locale(locale).format(type)
}

export function formatDateToNow(date: Moment | Date | string, locale: string): string {
  const newDate = moment.utc(date).tz(LOCAL_TZ)
  if (moment(newDate).isValid()) {
    if (moment(newDate).isSame(TODAY, 'd') || moment(newDate).isAfter(A_WEEK_OLD)) {
      return moment(newDate).locale(locale).fromNow()
    }

    if (moment(newDate).isAfter(A_YEAR_OLD)) {
      return formatDateToType(date, 'D MMMM', locale)
    }

    return formatDateToType(date, 'D MMMM YYYY', locale)
  }
  return ''
}

export function formatChatsDate(date: Moment | Date | string, locale: string): string {
  const newDate = moment.utc(date).tz(LOCAL_TZ)
  if (moment(newDate).isValid()) {
    if (moment(newDate).isSame(TODAY, 'd') || moment(newDate).isAfter(A_WEEK_OLD)) {
      return formatDateToType(date, 'HH:mm', locale)
    }

    if (moment(newDate).isAfter(A_WEEK_OLD)) {
      return formatDateToType(date, 'ddd', locale).toUpperCase()
    }

    if (moment(newDate).isAfter(A_YEAR_OLD)) {
      return formatDateToType(date, 'D MMMM', locale)
    }

    return formatDateToType(date, 'D MMMM YYYY', locale)
  }
  return ''
}

export function formatDateRanges(
  range: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 = 7,
  locale: string,
  type = 'yyyy-MM-DD H:mm'
): string[] | null {
  const ranges: {
    [key: number]: [Moment, Moment] | null
  } = {
    0: [moment().subtract(0, 'day').startOf('day'), moment().subtract(0, 'day').endOf('day')], // Today
    1: [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')], // Yesterday
    2: [moment().subtract(6, 'days').startOf('days'), moment().subtract(0, 'day').endOf('day')], // Last 7 Days
    3: [moment().subtract(29, 'days').startOf('days'), moment().subtract(0, 'day').endOf('day')], // Last 30 Days
    4: [
      moment().subtract(0, 'month').startOf('month'),
      moment().subtract(0, 'month').endOf('month'),
    ], // This Month
    5: [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ], // Last Month
    6: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')], // This Year
    7: null, // All the time
  }
  if (ranges[range]?.length) {
    return [
      // @ts-ignore
      formatDateToType(ranges[range][0], type, locale),
      // @ts-ignore
      formatDateToType(ranges[range][1], type, locale),
    ]
  }
  return null
}
