import { CountryCodes } from '@services/countries/types/country-codes'

export const licenseDocumentIds = {
  userAgreements: {
    [CountryCodes.RU]: 'russia-polzovatelskoe-soglashenie-ru',
    [CountryCodes.IN]: 'india-user-agreement-en',
    [CountryCodes.EN]: 'world-user-agreement-en',
    [CountryCodes.DEFAULT]: 'russia-polzovatelskoe-soglashenie-ru',
  },
  privacyPolicy: {
    [CountryCodes.RU]: 'russia-politica-confidencialnosty-ru',
    [CountryCodes.IN]: 'india-privacy-policy-en',
    [CountryCodes.EN]: 'world-privacy-policy-en',
    [CountryCodes.DEFAULT]: 'russia-politica-confidencialnosty-ru',
  },
  sellerAgreements: {
    [CountryCodes.RU]: 'russia-dogovor-dlya-prodavcov-ru',
    [CountryCodes.IN]: 'india-agreement-for-sellers-en',
    [CountryCodes.EN]: 'world-agreement-for-suppliers-en',
    [CountryCodes.DEFAULT]: 'russia-dogovor-dlya-prodavcov-ru',
  },
  legalization: {
    [CountryCodes.RU]: 'world-supster-policy-en',
    [CountryCodes.IN]: 'india-supster-policy-en',
    [CountryCodes.EN]: 'world-supster-policy-en',
    [CountryCodes.DEFAULT]: 'world-supster-policy-en',
  },
}
export default {}
