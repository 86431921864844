<template>
  <div></div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'PaymentResult',

  //   components: {}
  computed: {
    ...mapGetters('Locale', ['locale']),
  },
  async created() {
    if (this.$route.name === 'PaymentSuccess') {
      await this.ymPushEvent()
      setTimeout(() => {
        this.$router.push({ name: 'NewGptChat', params: { locale: this.locale } })
      }, 1000)
    }
  },
  methods: {
    async ymPushEvent() {
      // eslint-disable-next-line no-undef
      ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'promotional-payed')
    },
  },
})
</script>
