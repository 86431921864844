// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginForm_form_2oMrE{grid-auto-rows:min-content;grid-auto-flow:row}.LoginForm_caption_2uioQ{padding:15px;color:rgba(0,0,0,.5);font-size:14px;text-align:left}.LoginForm_inner_1UoLf{display:grid;row-gap:9px}.LoginForm_input_1g_v8{margin:0;padding:0}.LoginForm_wrapper_siQ5E{display:grid;grid-template-columns:repeat(2,1fr);-moz-column-gap:20px;column-gap:20px}.LoginForm_buttonContainer_2B6SH{padding:15px}.LoginForm_button_Lc4EV{display:grid;grid-auto-flow:column;justify-content:center;-moz-column-gap:9px;column-gap:9px}.LoginForm_title_2jNnS{font-weight:500;line-height:12px;text-transform:uppercase}.LoginForm_text_D-Xs9,.LoginForm_title_2jNnS{font-size:12px}.LoginForm_text_D-Xs9{font-weight:400;line-height:18px}.LoginForm_agreement_WZbTI{padding:15px 45px;text-align:center;border-top:1px solid #e5e3e3;box-shadow:inset 0 -1px 0 #e5e3e3}.LoginForm_link_3pR69{color:#000;font-weight:600;text-decoration:underline}.LoginForm_link_3pR69,.LoginForm_recovery_AWaCZ{font-size:12px;cursor:pointer}.LoginForm_recovery_AWaCZ{display:inline-block;margin-top:15px;padding:0 15px;line-height:18px;-webkit-text-decoration-line:underline;text-decoration-line:underline}", ""]);
// Exports
exports.locals = {
	"form": "LoginForm_form_2oMrE",
	"caption": "LoginForm_caption_2uioQ",
	"inner": "LoginForm_inner_1UoLf",
	"input": "LoginForm_input_1g_v8",
	"wrapper": "LoginForm_wrapper_siQ5E",
	"buttonContainer": "LoginForm_buttonContainer_2B6SH",
	"button": "LoginForm_button_Lc4EV",
	"title": "LoginForm_title_2jNnS",
	"text": "LoginForm_text_D-Xs9",
	"agreement": "LoginForm_agreement_WZbTI",
	"link": "LoginForm_link_3pR69",
	"recovery": "LoginForm_recovery_AWaCZ"
};
module.exports = exports;
