import { Mutations } from './types'

const mutations: Mutations = {
  setBlocks: (state, blocks) => {
    state.blocks = blocks
  },
  setBlockAppsNames: (state, blockAppsNames) => {
    state.blockAppsNames = blockAppsNames
  },
  addBlockAppsNames: (state, blockAppsName) => {
    state.blockAppsNames = [...(state.blockAppsNames ?? []), blockAppsName]
  },
  removeBlockAppsNames: (state, blockAppsName) => {
    state.blockAppsNames = state.blockAppsNames?.filter((name) => blockAppsName !== name) ?? null
  },
  removeAppBlockId: (state, appName) => {
    state.appBlockIds = Object.fromEntries(
      Object.entries(state.appBlockIds ?? {}).filter(([name]) => name !== appName)
    )
  },
  addBlockIds: (state, { appName, blockId }) => {
    state.appBlockIds = {
      ...state.appBlockIds,
      [appName]: blockId,
    }
  },
}

export default mutations
