// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VTabs_section_35lkd{position:relative;display:flex;flex-direction:column;flex-grow:1;width:100%}.VTabs_container_1OVSs,.VTabs_content_3m3fG{position:relative;display:flex;flex-direction:column;flex-grow:1;width:100%;height:100%}.VTabs_tabs_1rwwo{grid-template-rows:1fr}.VTabs_tab_3VHnp,.VTabs_tabs_1rwwo{display:grid;grid-auto-flow:column}.VTabs_tab_3VHnp{grid-auto-columns:auto;align-self:center;justify-content:center;width:100%;padding:15px 0!important;font-weight:400;-moz-column-gap:9px;column-gap:9px;border-radius:0!important}.VTabs_tab_3VHnp.VTabs_cover_3Ph-U{height:100%;padding:7px 15px!important}.VTabs_tab_3VHnp.VTabs_cover_3Ph-U.VTabs_default_23ak8{color:#737373;background:#e5e3e3;opacity:1}.VTabs_tab_3VHnp.VTabs_cover_3Ph-U.VTabs_active_2uuOK{color:var(--supster-secondary);background:transparent}.VTabs_tab_3VHnp.VTabs_sm_1Vai2{padding:5px 0!important}.VTabs_tab_3VHnp.VTabs_sm_1Vai2:first-child{border-radius:6px 0 0 6px!important}.VTabs_tab_3VHnp.VTabs_sm_1Vai2:last-child{border-radius:0 6px 6px 0!important}.VTabs_tab_3VHnp:hover{transform:translate(0)}.VTabs_tab_3VHnp.VTabs_default_23ak8{opacity:.3}.VTabs_tab_3VHnp.VTabs_default_23ak8:hover{opacity:.7}.VTabs_tab_3VHnp.VTabs_active_2uuOK{opacity:1}.VTabs_wrapper_3BeUK{display:grid;grid-auto-columns:auto;grid-auto-flow:column;align-self:center;padding:15px 0;-moz-column-gap:9px;column-gap:9px}.VTabs_icon_2QQvX{width:18px;height:18px}.VTabs_icon_2QQvX,.VTabs_text_3FRfn{justify-self:center}.VTabs_text_3FRfn{align-self:center;font-size:12px}.VTabs_text_3FRfn.VTabs_cover_3Ph-U{font-size:15px;line-height:22px}", ""]);
// Exports
exports.locals = {
	"section": "VTabs_section_35lkd",
	"container": "VTabs_container_1OVSs",
	"content": "VTabs_content_3m3fG",
	"tabs": "VTabs_tabs_1rwwo",
	"tab": "VTabs_tab_3VHnp",
	"cover": "VTabs_cover_3Ph-U",
	"default": "VTabs_default_23ak8",
	"active": "VTabs_active_2uuOK",
	"sm": "VTabs_sm_1Vai2",
	"wrapper": "VTabs_wrapper_3BeUK",
	"icon": "VTabs_icon_2QQvX",
	"text": "VTabs_text_3FRfn"
};
module.exports = exports;
