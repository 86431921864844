/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'double-chevron': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M6.663 0l-3.7 6.06L0 6.045V5.85L4.23 0h2.433zm-3.7 5.925L6.663 12H4.231L0 6.135V5.94l2.963-.015zM12 0L8.3 6.06l-2.963-.015V5.85L9.567 0H12zM8.3 5.925L12 12H9.567l-4.23-5.865V5.94L8.3 5.925z"/>'
  }
})
