<template>
  <VDrawer :overlayAnimate="false" name="permission">
    <div :class="$style.container">
      <div :class="$style.content">
        <icon name="logo" :class="$style.logo" />
        <div :class="$style.background">
          <icon name="permission-background" />
        </div>
        <div :class="$style.footer">
          <h3 :class="$style.title">{{ $t('permission.title') }}</h3>
          <p :class="$style.text">
            {{ $t('permission.text') }}
          </p>
          <v-button :isLoading="isLoading" @click="sendRequest" color="black-primary" size="xxl"
            >{{ $t('permission.btnConfirm') }}
          </v-button>
          <v-button @click="closeDrawer" size="xxl" color="transparent"
            >{{ $t('permission.btnReject') }}
          </v-button>
        </div>
      </div>
    </div>
  </VDrawer>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'

export default Vue.extend({
  name: 'PermissionDrawer',
  components: { VDrawer, VButton },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('Drawers', ['drawerParams']),
    ...mapGetters('Me', ['isLoggedIn']),
    ...mapGetters('Notifications', ['notificationsToken']),
  },
  methods: {
    ...mapMutations('Page', ['setPageIsHeader', 'setPageIsFooter']),
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapActions('Notifications', ['postNotificationsToken']),
    async sendRequest() {
      if (this.isLoggedIn && !this.notificationsToken && 'Notification' in window) {
        if (Notification.permission === 'granted') {
          await this.postNotificationsToken()
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then(async (permission) => {
            if (permission === 'granted') {
              this.isLoading = true
              await this.postNotificationsToken()
              this.closeDrawer()
            }
          })
        }
      }
    },
    closeDrawer() {
      this.setDrawer({ name: 'permission', isVisible: false })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $primary;
  color: $main-text-color;
}

.logo {
  align-self: flex-end;
  width: 92px;
  height: 24px;
  color: $main-text-color;
  fill: $main-text-color;
}

.footer {
  align-items: center;
  width: 100%;
  padding: 0 21px 15px 21px;
}

.title {
  margin-bottom: 21px;
  text-align: center;
}

.text {
  max-width: 200px;
  margin: 0 auto 45px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.background {
  align-self: center;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  svg {
    flex-shrink: 0;
    color: $main-text-color;
    fill: none;
    stroke: none;
    width: 550px;
    height: 110%;
  }

  @media screen and (max-height: 850px) {
    background-size: contain;
  }
}

.content {
  display: grid;
  grid-template-rows: 75px 1fr auto;
  grid-template-columns: 1fr;
  gap: 7px;
  justify-items: center;
  height: 100%;
}
</style>
