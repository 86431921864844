<template>
  <div v-if="isIndicator" :class="$style.container">
    <div :class="$style.label">{{ label }}</div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { GptVersionLabels } from '@modules/chat/config'

export default Vue.extend({
  name: 'ChatGptVersionIndicator',
  computed: {
    ...mapGetters('Chat', [
      'chatGptVersion',
      'chatId',
      'isChatTemp',
      'isChatGPTBot',
      'isPromptChat',
    ]),
    ...mapGetters('App', ['isSplitTestApp4', 'isSplitTestApp5']),
    label() {
      return this.$t('gpt.model', { version: GptVersionLabels[this.chatGptVersion] })
    },
    isIndicator() {
      return (
        this.chatId &&
        !this.isChatTemp &&
        this.isChatGPTBot &&
        this.chatGptVersion &&
        !this.isSplitTestApp4 &&
        !this.isSplitTestApp5
      )
    },
  },
})
</script>

<style module lang="scss">
.container {
  height: 0;
  display: flex;
  justify-content: center;

  .label {
    position: fixed;
    width: fit-content;
    color: $primary-light-background;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 9px;
    border: 1px solid $primary-light-background;
    padding: 0px 9px;
    transform: translateY(-50%);
    background-color: white;
    z-index: 11;
  }
}
</style>
