/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'instagram': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<g fill-rule="evenodd" clip-rule="evenodd" _fill="currentColor"><path pid="0" d="M13 9a4 4 0 11-8 0 4 4 0 018 0zm-1 0a3 3 0 11-6 0 3 3 0 016 0z"/><path pid="1" d="M0 4a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H4a4 4 0 01-4-4V4zm4-3h10a3 3 0 013 3v10a3 3 0 01-3 3H4a3 3 0 01-3-3V4a3 3 0 013-3z"/></g>'
  }
})
