/* eslint-disable camelcase */
import { Actions } from '@store/stories/types'
import StoriesApi from '@services/story'
import i18n from '@plugins/i18n'

const actions: Actions = {
  async getStories({ commit, dispatch, rootGetters, state }, app_id = rootGetters['App/appId']()) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const stories = await StoriesApi.getStories({
        app_id,
        page: state.stories.page,
        limit: 20,
      })
      commit('setStories', stories)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async deleteStory({ dispatch, commit }, id) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      await StoriesApi.deleteStory(id)
      commit('deleteStory', id)
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postStory({ dispatch, commit }, data) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const story = await StoriesApi.postStory(data)
      commit('unshiftStory', story)
      dispatch(
        'Error/notify',
        {
          type: 'success',
          title: `${i18n.t('success.stories.add.title')}`,
          text: `${i18n.t('success.stories.add.text')}`,
        },
        { root: true }
      )
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
