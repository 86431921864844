// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SendTip_button_37dyF{display:grid;grid-template-columns:auto 1fr;justify-content:center;-moz-column-gap:6px;column-gap:6px;border:0}.SendTip_content_Pnmib{align-self:center;justify-self:center;width:100%;padding:6px 9px 6px 0;overflow:hidden;white-space:nowrap;text-align:center;text-overflow:ellipsis}.SendTip_circle_1ob-z{display:grid;height:100%;background-color:var(--supster-secondary);border-radius:100%}.SendTip_circle_1ob-z.SendTip_sm_GcZ1A{width:22px}.SendTip_circle_1ob-z.SendTip_md_4qvob{width:23px}.SendTip_circle_1ob-z.SendTip_lg_8tmRj,.SendTip_circle_1ob-z.SendTip_xl_3UrSF{width:25.5px}.SendTip_icon_3n7_C{align-self:center;justify-self:center;width:14px;height:12px;fill:var(--supster-primary)}.SendTip_icon_3n7_C.SendTip_sm_GcZ1A{width:6px;height:13px}.SendTip_icon_3n7_C.SendTip_md_4qvob{width:8px;height:14px}.SendTip_icon_3n7_C.SendTip_lg_8tmRj{width:10px;height:17px}.SendTip_icon_3n7_C.SendTip_xl_3UrSF{width:11px;height:19px}", ""]);
// Exports
exports.locals = {
	"button": "SendTip_button_37dyF",
	"content": "SendTip_content_Pnmib",
	"circle": "SendTip_circle_1ob-z",
	"sm": "SendTip_sm_GcZ1A",
	"md": "SendTip_md_4qvob",
	"lg": "SendTip_lg_8tmRj",
	"xl": "SendTip_xl_3UrSF",
	"icon": "SendTip_icon_3n7_C"
};
module.exports = exports;
