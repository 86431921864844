/* eslint-disable no-template-curly-in-string */
import informs from '@translations/en/informs'
import countries from '../countries/en'
import claims from './claims'
import desktopPageLabels from './desktop-page-labels'

export default {
  hotPrice: 'hot price',
  renewSubscription: 'Renew subscription',
  subscriptionExpiredText:
    'Unfortunately, your subscription to the content has expired. Please renew your subscription to regain access to the content. To do so, you need to pay for a new period in the "Subscriptions" section of the sidebar menu.',
  renewFor: 'Renew for',
  noVideo: 'No video',
  noPhoto: 'No photo',
  recordingTip: 'Swipe to cancel',
  voiceMessage: 'Voice message',
  mediaDevicesNotSupported: {
    label: 'Media devices not supported',
    desc: 'Your browser does not support media devices',
  },
  mediaDevicesPermissionNotification: {
    label: 'Media devices permission',
    desc: 'Please allow access to your media devices to use voice messages',
  },
  mediaDevicesErrorLabels: {
    default: 'Media devices error',
    notAllowed: 'Media devices not allowed error',
  },
  mediaDevicesErrorMessages: {
    denied: 'Access to media devices denied',
    dismissed: 'Access to media devices dismissed',
    default: 'Error with media devices',
  },
  support: 'Support',
  locale: {
    ru: 'Русский',
    en: 'English',
  },
  gpt: {
    label: 'GPT',
    swearing:
      'Sorry, but your message contains prohibited words. We strive to maintain respectful and positive communication in our application. Please rephrase your message.',
    model: 'Model: {version}',
    requestError:
      'Sorry, there was a problem with sending your request. Please try sending the message again',
    repeatRequest: 'Repeat request',
    subscribe: 'Subscribe',
    getUnlimited: 'Get unlimited',
    limit3:
      'We have run out of trial attempts for sending messages. Become a platform user to continue without limitations',
    limit4: 'Unlimited ChatGPT-4 is available exclusively to platform users!',
    open: 'Open',
    waitingMinuteMore: 'A couple more moments',
    waitingMinute: 'Wait a minute',
    waiting: "Wait a minute, I'm preparing an answer",
    somethingWrong: 'Something went wrong',
    newChat: 'New GPT chat',
    access1: 'Unlimited Number of Queries',
    access2: 'The Most Advanced AI Models in One Chat',
    access3: 'GPT 3.5 / GPT 4 / Midjourney',
    access4: 'Without VPN and foreign cards',
    welcome: {
      message:
        'Welcome to the Supster GPT UI! Now you can create your own ChatGPT analogue in a couple of minutes!',
      presentation1: {
        name: 'Examples',
        item1: 'Explain quantum computing in simple words',
        item2: "Do you have any creative ideas for a 10-year-old's birthday?",
      },
      presentation2: {
        name: 'Opportunities',
        item1: 'Creates pictures according to your request',
        item1SubText: 'Enter the command “/imagine” and then what you want to draw',
        item2: 'Remembers what the user said earlier',
        item3: 'Overcomes the limitations of ChatGPT',
      },
      presentation3: {
        name: 'Coming soon',
        item1: 'AI agents in the general chat',
        item2: 'Trained and uses Google search',
        item3: 'Creating AI Art',
        item4: 'Voice input and response',
      },
    },
    welcome2: {
      message: 'Integrated Chat GPT!',
      message2:
        "Try it for just one ruble. And, by the way, \r\nIF YOU DON'T LIKE IT, WE WILL REFUND YOUR MONEY.",
      presentation1: {
        name: 'Find out now',
        item1: 'HOW TO MAKE MONEY IN 2023?',
        item2: 'WHO WILL BE THE PRESIDENT IN 2024?',
        item3: 'WHAT IS THE PROBABILITY OF NUCLEAR WAR?',
        item4: 'WHEN WILL AI TAKE OVER THE WORLD?',
      },
    },
  },
  client: {
    accessDenied: 'Sorry, the user has limited the possibility of this action',
  },
  uploadMenuDrawer: {
    stories: 'Add Stories',
    post: 'Add Photo or Video',
    chatGroup: 'New General Chat',
    chatPersonal: 'New Personal Chat',
    chatChannel: 'New Channel',
    chatGPT: 'New GPT chat',
  },
  supportChat: 'Support Chat',
  chat: {
    support: 'Supster support chat',
  },
  chatName: 'Chat name',
  channelName: 'Channel name',
  leave: 'Leave',
  followersDeclination: '0 followers | {n} follower | {n} followers',
  linkCopied: 'Link copied',
  link: 'Link',
  profileDescPlaceholder:
    'Enter text about you. 2 lines will be visible at once, and the rest will be hidden under “More” button.',
  multiImages: '3 separate images',
  singleImage: 'Single full-width image',
  editProfile: 'Edit profile',
  access: 'Access',
  getAccess: 'Get access',
  close: 'Close',
  promoPage: 'Promotional page',
  all: 'All',
  vk: 'VKontakte',
  ok: 'Odnoklassniki',
  inProcess: 'In Process',
  completed: 'Completed',
  send: 'Send',
  address: 'Address',
  name: 'Name',
  surname: 'Surname',
  patronymic: 'Patronymic',
  title: 'Title',
  description: 'Description',
  details: 'Details',
  followers: 'Followers',
  follow: 'Follow',
  donate: 'Donate {currency}{amount}',
  followAction: {
    follow: 'Follow',
    unfollow: 'Unfollow',
  },
  claimAcceptedDrawer: {
    accepted: 'Claim accepted',
    desc: 'Thanks for letting us know',
  },
  blockingDrawer: {
    blocking: {
      content: 'Content blocking',
      desc: "This user's content will be blocked and will not be in your feed. Continue?",
    },
    unblocking: {
      content: 'Content unblocking',
      desc: "This user's content will be unblocked and will be in your feed. Continue?",
    },
  },
  continue: 'Continue',
  continueChanges: 'Proceed with changes',
  hiddenPublication: 'Hidden publication',
  replyTo: 'Reply to {0}',
  newVersionAvailable: 'A new version is vailable',
  tariffs: 'Tariffs',
  posts: 'Posts',
  postsCount: 'Posts: {0}',
  more: {
    open: 'Hide',
    close: 'More',
  },
  next: 'Next',
  photos: 'Photos',
  videos: 'Videos',
  photo: 'Photo',
  video: 'Video',
  media: 'Media',
  gender: 'Gender',
  subscribe: 'Subscribe',
  giveTryIt: 'Give it a try for only ${value}',
  share: 'Share',
  subscribersOnly: 'Subscribers Only',
  readOn: {
    open: 'Hide',
    close: 'Read on',
  },
  actions: 'Actions',
  copyLink: 'Copy Link',
  writeComment: 'Write a comment',
  replies: '0 Replies | {n} Reply | {n} Replies | {n} Replies',
  closeReplies: 'Close the replies',
  reply: 'Reply',
  replyingTo: 'Replying to',
  show: 'Show',
  hide: 'Hide',
  anonymous: 'Anonymous',
  cancel: 'Cancel',
  toCancel: 'Cancel',
  sendTip: 'Send Tip',
  addCaption: 'Add Caption...',
  tagPeople: 'Tag People',
  hideSubscription: 'Hide under Subscription',
  enableComments: 'Enable Comments',
  postOnSocialNetworks: 'Post on Social Networks',
  postTariffs: 'Tariffs for this post',
  yourMessage: 'Your message',
  yourName: 'Your name',
  uploadImage: 'Upload image',
  underConstruction: 'Under construction',
  availableSoon: 'The functionality will be available very soon',
  shopOpenSoon: 'The shop will open soon',
  mySubscriptions: 'My subscriptions',
  startedOn: 'Started on',
  payment: 'Payment',
  phone: 'Phone',
  changePhoneNumber: 'Change phone number',
  resend: 'Resend',
  afterTime: 'in {0}',
  code: 'Code',
  lastPayment: 'Last Payment',
  nextPayment: 'Next Payment',
  pay: 'Pay',
  expiration: 'Expiration',
  securityCode: 'Security Code',
  withdrawal: 'Withdrawal',
  history: 'History',
  transferFee: 'Transfer Fee: {0}',
  fundsToBankingCard: 'Funds to Banking Card',
  cardNumber: 'Card Number',
  minMaxNumbers: '{0} numbers',
  characters: '0 characters | {n} character | {n} characters',
  subscribers: '0 subscribers | {n} subscriber | {n} subscribers',
  subscribersTariffs: 'Subscriber',
  days: 'Days',
  dynamicDays: '0 Days | {n} Day | {n} Days',
  nameAndSecondNameOnCard: 'Name and Second Name on the Card',
  proceed: 'Proceed',
  getStarted: 'Get started',
  withProtectedConnection: 'with Protected Connection',
  dateBirth: 'Date of Birth',
  MMYY: 'MM | YY',
  amount: 'Amount',
  currency: 'Currency',
  confirmPayment: 'Confirm payment',
  subscribed: 'Subscribed',
  shop: 'Shop',
  choose: 'Choose',
  currentBalance: 'Current Balance',
  chooseSubscription: 'Choose Subscription',
  whatYouGetSubscription: 'What You Get with Subscription',
  unlockTheFullPotentialOfAI: 'Unlock the Full Potential of Neural Networks',
  bankingCards: 'Banking Cards',
  accessToAll: 'Access to all <b>photos / videos</b> except paid ones.',
  accessToAllMeditation: 'Access to all meditations',
  accessToAuthors: 'Access to author’s <b>private channels</b>.',
  exclusiveContent: 'Exclusive content.',
  termsConditions: 'Terms & Conditions',
  preferences: 'Preferences',
  other: 'Other',
  userId: 'User ID',
  confirmation: 'Confirmation',
  videoProcessing: 'Video is being processed',
  tags: 'Tags',
  top: 'Top',
  recent: 'Recent',
  browseSeveral: 'Browse several new posts every week!',
  seller: 'Seller',
  delivery: 'Delivery',
  buyersPhotos: 'Buyers’ Photos',
  addToCart: 'Add to Cart',
  track: 'Track No.',
  item: '0 items | {n} item | {n} items',
  sorting: 'Sorting',
  newestFirst: 'Newest First',
  verification: 'Verification',
  documentType: 'Document type',
  offerAgreement: 'Offer Agreement',
  toLeaveCommentNeedEnterName: 'To continue, you must enter a name',
  editYourComment: 'Edit Your Comment',
  edit: 'Edit',
  editing: 'Editing',
  pinnedMessage: 'Pinned message #{0}',
  pin: 'Pin',
  unpin: 'Unpin',
  delete: 'Delete',
  commentText: 'Comment Text',
  saveChanges: 'Save Changes',
  agreement: 'By continuing, you agree to the {privacyPolicy} and {rulesPlatform}',
  withPrivacyPolicy: 'privacy policy',
  withRulesPlatform: 'rules of the platform',
  licenseAgreement: 'License Agreement',
  privacyPolicy: 'Privacy Policy',
  termsService: 'Terms of Service',
  country: 'Country',
  receiver: 'Receiver',
  method: 'Method',
  offerForThePurchase: 'Purchase and sale offer',
  blockUser: 'Block user',
  userIsBlocked: 'The user is blocked',
  appNotFound: 'App not found',
  deletedMessage: 'Deleted message',
  block: {
    app: {
      post: 'Block post',
      user: 'Block user',
      profile: 'Application blocked',
    },
    comment: 'Block comment',
    unblock: 'Unblock',
  },
  myChannels: 'My channels',
  privateChats: 'Private chats',
  report: {
    user: 'Report user',
    post: 'Report post',
    text: 'Report abuse',
    title: 'Report has been sent',
    description: 'Thank you for informing us about this',
    comment: 'Report comment',
    reason: 'Reason for claim',
    confirmReason: 'Confirm reason',
  },
  post: {
    deleting: 'Deleting post',
    deleteError: 'Error deleting post',
    confirmTitle: 'Delete post?',
    edit: 'Edit',
    delete: 'Delete',
    modalNameDescription: 'To leave comments, you must enter a name',
  },
  no: {
    data: 'No data',
    chat: 'No chat',
    messages: 'No messages yet',
    orders: 'Order list is empty',
    products: 'Product list is empty',
    mediaContent: 'No media content',
    countries: 'No countries',
  },
  createOrder: {
    yourOrder: 'Your Order',
    courier: 'Courier',
    pickUp: 'Pick-up',
    deliveryService: 'Delivery service',
    wrapping: {
      title: 'Wrapping',
      eco: {
        title: 'Eco Wrapping',
        description:
          'If you prefer a more minimal, discreet approach, choose the "Eco wrapping" and receive your purchase, still carefully packed, in a brown outer box.',
      },
      signature: {
        title: 'Signature Box',
        description:
          'Get your purchase, carefully packed, in one of our our branded signature boxes. Perfect if you want to treat yourself or if your order is a gift to someone special.',
      },
      gift: {
        title: 'Gift Wrapping',
        description:
          'The gift wrapping packaging is perfect for when your order is a gift to someone special.',
      },
    },
    comment: 'Order Comment',
    activate: 'Activate',
    promo: 'Promo-Code',
    delivery: 'Choose Delivery Method',
    phone: 'Phone Number',
    total: 'Total',
    proceed: '<b>Proceed</b> with Protected Connection',
    agreement: 'By continuing, I agree to {agreement}',
    userAgreement: 'User Agreement',
    oferta: 'Terms of the agreement',
  },
  cart: {
    title: 'Cart',
    empty: 'Shopping cart empty',
    view: 'View Cart',
  },
  sortByDates: {
    0: 'Today',
    1: 'Yesterday',
    2: 'Last 7 Days',
    3: 'Last 30 Days',
    4: 'This Month',
    5: 'Last Month',
    6: 'This Year',
    7: 'All the time',
  },
  files: {
    add: 'Add file',
    noUploaded: 'File not uploaded',
    format: 'File Format',
    document: 'PDF, JPG, PNG no more than {0}.',
  },
  documents: {
    passport: 'Passport',
    idCard: 'ID card',
    driversLicense: 'Driver`s license',
  },
  genders: {
    male: 'Male',
    female: 'Female',
  },
  verifications: {
    inProgress: {
      title: 'Verification progress',
      topText: 'After successful data verification, you will be able to accept online payments',
      bottomText: 'Your personal information is securely protected and encrypted.',
      wait: 'Expect',
      complete: 'Successfully completed',
      inProcess: 'In the process',
      rejected: 'Failed',
      reason: 'Reason:',
      comment: 'A comment:',
      repeatModerate: 'Go through moderation again',
      step1: {
        title: 'Filling in data for verification',
      },
      step2: {
        title: 'Data validation',
      },
      step3: {
        title: 'Verification',
      },
    },
    title: 'Your profile is not verified',
    text: 'Verify your profile to unlock the ability to accept payments from your customers inside the app.',
    description:
      'After successful verification, all subscription models you have created will be active, and your users will be able to pay for access to the content.',
    link: 'Go to verification',
    notify: 'Your personal information <br> is secure and encrypted.',
    onVerification: {
      title: 'The data is currently being verified',
      description: 'It may take some time',
    },
    'step-1': {
      header: 'Individual data',
      title: 'Confirm your identity',
      text: 'According to the law, you have to prove your identity before you can accept payments.',
    },
    'step-2': {
      header: 'Document',
      title: 'Upload a scanned or photographed photo document',
      text: 'The image must be in color, made from the original document, clear and uncut.',
    },
    'step-3': {
      header: 'You and the document',
      title: 'Upload a photo of yourself holding this document',
      text: 'The image must be in color, clear and uncut, and the document must be clearly legible.',
    },
    'step-4': {
      header: 'Confirmation code',
      title: 'Write the numbers below on a piece of paper and take selfies',
      text: 'The image should be in color, clear and uncut, and the numbers on the sheet should be clearly legible.',
    },
    'step-5': {
      header: 'Completion',
      title: 'All ready to ship',
      text: 'By submitting data for verification, you confirm that you have read and agree with the Offer Agreement, Privacy Policy and Service Rules.',
    },
  },
  table: {
    date: 'Date, Time',
    sum: 'Sum',
    status: 'Status',
  },
  orders: {
    title: 'Orders',
    order: 'Order {0}',
    details: 'Order Details',
    empty: 'The order list is empty',
    formed: 'Formed',
    archived: 'Archived',
    checkout: 'Checkout',
    changed: 'Сhanged',
    type: {
      1: 'Single Purchase',
      2: 'Subscription',
    },
    delivery: {
      1: 'Processed',
      2: 'Ready to ship',
      3: 'Submitted',
      4: 'Delivered',
      types: {
        1: 'Delivery with currier',
        2: 'Pickup from branch',
        3: 'Delivery service',
      },
    },
    typeOrder: {
      1: 'Physical',
      2: 'Digital',
    },
    paid: {
      1: 'Not paid',
      2: 'Paid',
      3: 'Processed',
    },
  },
  select: {
    value: 'Select value',
    payment: 'Select Payment Method',
    tariffs: 'Select tariffs',
    network: 'Select social network',
    images: 'Selected 0 images | Selected {n} images | Selected {n} images | Selected {n} images',
  },
  chats: {
    general: 'New General Chat',
    createGeneral: 'Create new general chat',
    personal: 'New Personal Chat',
    createPersonal: 'Create new personal chat',
    createGroup: 'Сreate  new group chat',
    communicate: 'Chat to communicate with the author.',
    with: 'Chat with user {0}',
    edit: 'Edit',
    done: 'Done',
  },
  channels: {
    channel: 'New Channel',
    create: 'Create new channel',
    empty: 'Channel is empty',
    channels: 'Channels',
    join: 'Join Channel',
    private: 'This channel is private',
    privateMessage:
      'To see messages and join the channel, you need to purchase a subscription at the offered tariffs.',
  },
  settings: {
    security: {
      title: 'Security',
      description: 'Change Password, Sessions Control',
    },
    account: {
      title: 'Account info',
      description: 'Username, E-mail, Phone Number',
    },
    privacyAndSafety: {
      title: 'Privacy and Safety',
      description: 'Activity Status, Show Offers',
    },
    notifications: {
      title: 'Notifications',
      description: 'SMS, Push Notifications etc.',
      toggle: {
        push: 'Push Notifications',
        email: 'E-mail Notifications',
        site: 'Site Notifications',
        toast: 'Toast Notifications',
        sms: 'SMS Notifications',
      },
    },
  },
  email: 'Email',
  enter: {
    description: 'Enter a description',
    name: 'Enter a name',
    surname: 'Enter a surname',
    patronymic: 'Enter a patronymic',
    title: 'Enter a title',
    securityCode: 'Enter security code',
    amount: 'Enter amount',
    userId: 'Enter user ID',
    address: 'Enter a Address',
    numberOfDays: 'Enter the number of days',
    email: 'Enter email',
  },
  subscription: {
    freeTrial: 'Trial period for %{amount}₽',
    freeTrialGpt: 'Trial access',
    freeTrialGptFor: 'Trial access for',
    daysFree: ' %{days} days free',
    trial: '%{period} trial period for %{amount}₽',
    tearlyAccess: 'Tearly Access',
    active: 'Active',
    cancelled: 'Cancelled',
    rejected: 'Rejected',
    trialPeriods: {
      1: '1 Day',
      2: '1 Week',
      3: '1 Month',
      4: '3 Month',
      5: '6 Month',
      6: '1 Year',
      7: '3-day',
    },
    for: 'for',
    1: 'Per Day',
    2: 'Per Week',
    3: 'Per Month',
    4: 'Per 3 Month',
    5: 'Per 6 Month',
    6: 'Per Year',
    7: '3 days',
  },
  subscriptionCancellation: {
    dontRush: "Don't rush with your decision!",
    areYouSure: 'Attention! Are you going to cancel your subscription?',
    description1:
      'We noticed that you are about to cancel your subscription. But before you make a final decision, we want to offer you a unique opportunity:',
    announcement:
      'As a token of appreciation for you being with us, we offer you a {limit} our service.',
    announcement2:
      'In this case, {limit} that we offered you earlier will be canceled. Are we leaving everything as it is?',
    announcementLimit: 'free 7 days of using',
    description2:
      'Within 7 days you will be able to fully appreciate all the benefits of our product and, perhaps, change your mind.',
    addition:
      'Rest assured, there are no hidden conditions - just an opportunity to evaluate us once more.',
    questions: {
      reason: 'What led you to the decision to cancel your subscription?',
      favorites: 'What did you like and dislike about our service?',
    },
    cancelling: 'Your subscription will be cancelled :(',
    goToEmail: 'Please follow the link in the email for confirmation.',
    placeholder: 'Enter your response...',
  },
  notifications: {
    disable: 'Disable notifications',
    enable: 'Enable notifications',
  },
  questions: {
    removeChannel: 'Do you really want to remove channel?',
  },
  confirm: {
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    accept: 'Accept',
    cancel: 'Cancel',
    discard: 'Discard',
    verify: 'Verify',
    save: 'Save',
    confirm: 'Confirm',
    delete: {
      stories: 'Delete story?',
    },
  },
  avatar: 'Avatar',
  pages: {
    comments: 'Comments',
    newPost: 'New Post',
    editPost: 'Edit Post',
  },
  install: {
    title: 'Install',
    text: 'Press “Install” to see all the images!',
    safari: {
      title: 'Install the App to see all the images!',
      text: 'Install this webapp on your iPhone: tap {icon} and then Add to homescreen.',
    },
    skip: 'Open in browser',
    pleaseWait: 'Please wait',
    wait: 'Wait a minute, we are installing the application...',
  },
  navigation: {
    feed: 'Feed',
    search: 'Search',
    chats: 'Chats',
    profile: 'Profile',
    profileEdit: 'Profile Edit',
  },
  aside: {
    adminPanel: 'Admin Panel',
    auth: 'Login / Registration',
    users: 'Users',
    myProfile: 'My Profile',
    myOrders: 'My Orders',
    finances: 'Finances',
    statistics: 'Statistics',
    subscriptions: 'Subscriptions',
    referrals: 'Referrals',
    helpAndSupport: 'Help & Support',
    publicOffer: 'Public Offer',
    buySaleOffer: 'Purchase and Sale Offer',
    safeDeal: 'Terms of the agreement',
    settings: 'Settings',
  },
  success: {
    payments: {
      caption: 'Successful payment',
      description: 'Your payment went through',
    },
    joinChannel: {
      caption: 'Success',
      description: 'You have now joined this channel',
    },
    leaveChannel: {
      description: 'You left the channel',
    },
    stories: {
      add: {
        title: 'Successful Stories',
        text: 'You have add story',
      },
    },
    profile: {
      edit: {
        title: 'Successful Profile',
        text: 'You change profile data',
      },
    },
  },
  permission: {
    title: 'Get Notified',
    text: 'Get notified about neighbourhood activies, skilled users, new stories, friends joining and more.',
    btnConfirm: 'Let’s do it',
    btnReject: 'No, another time',
  },
  login: {
    enter: 'Enter',
    registration: 'Registration',
    register: 'Register',
    entertoYourProfile: 'Log in to your profile',
    enterToProfile: 'Log in profile',
    congrats: 'Congratulations!',
    congratsDesc: 'Your account is registered on the Supster platform',
    phoneCaption: 'Enter your phone number. A login code will be sent to it',
    emailCaption: 'Enter your email. A password recovery code will be sent to it:',
    emailCaptionCode:
      'A message with a confirmation code has been sent to your email address. Please enter it:',
    youPasswordRecovery: 'Your password has been recovered',
    loginTry: 'Now try logging in again',
    passwordRecovery: 'Password recovery',
    password: 'Password',
    email: 'Email',
    newPassword: 'New password',
    repeatPassword: 'Password repeat',
    enterToAccount: 'Login',
    loginOptions: {
      title: 'Profile login options',
      email: 'By E-mail',
      phone: 'By phone',
    },
    forgotPassword: 'Forgot your password?',
    afterPayment: 'In order not to lose access to the order,<br> you must register',
  },
  warnings: {
    title: 'Warning',
    message: 'Better not to do this',
    size: 'Limit of 3000 characters exceeded',
  },
  errors: {
    title: 'Error',
    message: 'An unknown error has occurred',
    login: {
      caption: 'Login error',
      descriptionLoad: 'the phone number is not registered',
      loginEmail: 'Invalid email or password',
      loginPhone: 'Invalid phone number or password',
      passwordsNotMatch: 'Password mismatch',
      emailAlreadyInUse: 'This email is already in use',
      code: 'The code is not correct',
    },
    profile: {
      edit: {
        title: 'Unsuccessful Profile',
        text: 'Profile data has not been changed',
      },
    },
    promo: {
      description: 'Promo code not found',
    },
    files: {
      caption: 'Files error',
      description: 'Incorrect file format or {0} limit exceeded',
      descriptionQuantity: 'The number of files must be no more than {0}',
      descriptionLoad: 'File upload error',
      descriptionNotFound: 'File not found',
    },
    countries: {
      caption: 'Countries error',
      descriptionLoad: 'Countries upload error',
    },
    posts: {
      caption: 'Posts error',
      descriptionLoad: 'Posts upload error',
    },
    products: {
      caption: 'Products error',
      descriptionLoad: 'Products upload error',
    },
    chats: {
      caption: 'Chats error',
      descriptionLoad: 'Chats upload error',
      descriptionCreateDialog: 'Dialog creation error',
      notFounded: 'Chat not found',
    },
    tags: {
      caption: 'Tags error',
      descriptionLoad: 'Tags upload error',
    },
    payments: {
      caption: 'Payment error',
      description: 'A payment error has occurred. Check the correctness of the entered data',
    },
    orders: {
      caption: 'Product error',
      description: 'A product creation error occurred',
    },
    validations: {
      required: 'Required',
      title: 'The name must be only numbers, letters or symbols',
      name: 'Name must contain only letters, numbers or symbols _-.',
      surname: 'Surname must consist of only letters',
      email: 'Invalid email',
      creditCardName: 'Name and surname must contain only latin letters and one space',
      numbers: 'Only numbers',
      letters: 'Only letters',
      lengthLessThanMin: 'Must be at least {characters} long',
      lengthGreaterThanMax: 'Must be at most {characters} long',
      password: {
        minChars: 'Minimum {characters} characters',
        spaces: 'Password must not contain spaces',
        cyrillic: 'Password must not contain Cyrillic',
      },
    },
    billing: {
      default: 'An unknown error has occurred. Check the correctness of the entered data',
      noLink: 'There is no link to confirm the payment',
    },
    clients: {
      notFounded: 'Client not found',
    },
    api: {
      400: {
        title: 'Invalid request',
        text: 'It can be caused by various problems on the user side, such as incorrect JSON data in the request body, incorrect action parameters, etc',
      },
      401: {
        title: 'Authentication error',
        text: 'Authentication failed',
      },
      403: {
        title: ' Access not allowed',
        text: ' An authenticated user is not allowed access to the specified API entry point',
      },
      404: {
        title: 'Not found',
        text: 'The requested resource could not be found',
      },
      405: {
        title: 'Method not supported',
        text: 'The method is not supported. Check the list of supported HTTP methods in the Allow header',
      },
      500: {
        title: 'System error occurred',
        text: 'Please contact our Support Team',
      },
    },
    offline: 'Нет подключения к сети',
  },
  activation: {
    IMAGES: {
      title: 'Upload your photos below',
      text: 'It’s the best way to present yourself and gain new contacts. Also you may join <b>Supster Network</b> and earn money selling digital content, goods and services by subscription model. You will need to activate <b>Business Account</b> to do this.',
    },
    VIDEOS: {
      title: 'Upload your videos below',
      text: 'It’s the best way to present yourself and gain new contacts. Also you may join <b>Supster Network</b> and earn money selling digital content, goods and services by subscription model. You will need to activate <b>Business Account</b> to do this.',
    },
    PRODUCTS: {
      title: 'Creating your own shop',
      text: 'You may create your own internet-shop and earn money selling goods. You will need to activate <b>Business Account</b> to do this.',
    },
    CHATS: {
      title: 'Creating your own channel',
      text: 'You may create your own internet-shop and earn money selling goods. You will need to activate <b>Business Account</b> to do this.',
    },
  },
  createApp: {
    drawer: {
      phoneTitle: 'Activating Supster Business',
      phoneText:
        'To activate <b>Supster Business</b> you need to verify your account. Please enter your phone number in the field below. Later, it will be used to log into your control panel.',
      phoneDescription: 'Your Phone Number',
      code: {
        title: 'Phone Number Verification',
        text: 'SMS with verification code was sent to your phone number. Please enter it in the field below:',
        description: 'Verification Code',
        changePhone: 'Change Phone Number',
        resendCode: 'Resend SMS',
      },
      greet: {
        title: 'Congratulations!',
        text: 'You have been granted access to your personal Supster Business Control Panel.',
        url: 'Your personal URL:',
        copy: 'Copy URL',
        admin: 'Go to Admin Panel',
      },
    },
  },
  documentsTitle: 'Documents',
  documentNotFound: 'Document not found',
  docs: {
    'user-agreement': 'User agreement',
    'credential-storage-agreement': 'Credential storage agreement',
    privacy: 'Privacy policy',
    'suppliers-agreement': 'Suppliers agreement',
    'combating-legalization': 'Policy of legalization',
    'sellers-contract': 'Sellers contract',
    'transactions-storage-agreement': 'Transactions storage agreement',
  },
  profile: {
    trial: 'Give it a try for only 1',
    subscription: {
      1: '1 Day',
      2: '1 Week',
      3: '1 Month',
      4: '3 Month',
      5: '6 Month',
      6: '1 Year',
    },
  },
  promotional: {
    modal: {
      title: {
        posts: 'Photos and Videos',
        channels: 'Channels',
      },
      button: 'Subscribe to Unlock!',
    },
    plug: {
      trial: 'Free',
      hotOffer: 'Hot Offer',
      bestOffer: 'The Best Offer',
      save85: 'Save 85%',
    },
  },
  modals: {
    adminNoVerified: {
      title: 'At the moment, the purchase <br> is not possible :(',
      description:
        'The author has not verified the profile yet and cannot receive payments. Therefore, this content is temporarily unavailable for purchase.',
    },
  },
  install_instruction: {
    notify_text: 'Install the App to see all the images!',
    install: 'Install',
    inst_title: 'Install App',
    inst_step1_title: '1. Open Safari',
    inst_step1_subtitle: 'Open the site in the Safari browser',
    inst_step2_title: '2. Click on the «Share» button',
    inst_step2_subtitle: 'Click on the "Share" button at the bottom of the browser',
    inst_step3_title: '3. Choose the action',
    inst_step3_subtitle: 'Select the item in the action menu "To the Home screen"',
    inst_step4_title: '4. Add the Icon',
    inst_step4_subtitle: 'Click "Add" at the top right of the screen"',
    androind: {
      inst_step1_title: '1. Copy the link of this page',
      inst_step2_title: '2. Open the link in Google Chrome browser.',
      inst_step3_title: '3. On the open page, open the context menu of settings',
      inst_step4_title: '4. Install the app in 1 minute!',
    },
  },
  shareChatDrawer: {
    title: 'Share chat link',
    subTitle:
      'Messages you send after creating the link will not be published. Anyone with the URL will be able to view the general chat.',
    chatName: 'Chat name',
    chatCreated: 'Chat created:',
    buttonText: 'Copy link',
  },
  ...countries,
  ...claims,
  ...informs,
  ...desktopPageLabels,
}
