/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M13.854 13.146l-4.111-4.11c-.006-.006-.014-.008-.02-.014a5.5 5.5 0 10-.7.7c.005.007.007.015.013.02l4.11 4.111a.499.499 0 10.707-.707zM5.5 10A4.505 4.505 0 011 5.5C1 3.019 3.019 1 5.5 1S10 3.019 10 5.5 7.981 10 5.5 10z"/>'
  }
})
