// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatGptWelcomeSocialNetworks_container_1McNo{display:flex;flex-direction:column;align-items:center;gap:20px}.ChatGptWelcomeSocialNetworks_container_1McNo .ChatGptWelcomeSocialNetworks_image_xECEp{height:300px;-o-object-fit:cover;object-fit:cover}.ChatGptWelcomeSocialNetworks_container_1McNo .ChatGptWelcomeSocialNetworks_socials_XJap5{display:flex;flex-direction:column;gap:15px}.ChatGptWelcomeSocialNetworks_container_1McNo .ChatGptWelcomeSocialNetworks_socials_XJap5 .ChatGptWelcomeSocialNetworks_social_1S1tp{display:flex;gap:10px;align-items:center}", ""]);
// Exports
exports.locals = {
	"container": "ChatGptWelcomeSocialNetworks_container_1McNo",
	"image": "ChatGptWelcomeSocialNetworks_image_xECEp",
	"socials": "ChatGptWelcomeSocialNetworks_socials_XJap5",
	"social": "ChatGptWelcomeSocialNetworks_social_1S1tp"
};
module.exports = exports;
