import { capitalize } from 'lodash'
import currencies from '@utils/currencies'
import { AppMode } from '@services/app/types'
import { isDarkColor, isTooLight } from '@helpers/colors'
import {
  isLandingMode,
  isSplitTestApp,
  isSplitTestApp2,
  isSplitTestApp3,
  isSplitTestApp4,
  isSplitTestApp5,
  isSplitTestApp6,
} from '@helpers/apps'
import { Getters, StateNames } from './types'

const getters: Getters = {
  app: (state) => state[StateNames.MY_APP],
  isDarkMode: (state, getters) => isDarkColor(getters.appColor()),
  isTooDarkColor: (state, getters) => isDarkColor(getters.appColor(), true),
  isTooLightColor: (state, getters) => isTooLight(getters.appColor()),
  myApps: (state) => state.myApps,
  isMyAppGPT: (state) => state[StateNames.MY_APP]?.type === AppMode.CHAT_GPT,
  otherApp: (state) => state[StateNames.OTHER_APP],
  clientApp: (state) => state[StateNames.CLIENT_APP],
  currentAppName: (state) => state.currentAppName,
  currentApp: (state) => (state.currentAppName ? state[state.currentAppName] : null),
  currentAppId: (s, getters) => getters.currentApp?.id ?? null,
  appAvatar:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.image ?? null,
  appAvatarSrc:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.image?.content_url ?? '',
  appAvatarSrcset:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.image?.preview_url ?? '',
  appId:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.id,
  appOriginalId:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.original_id,
  appColor:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.primary_color || '#EDD031',
  appClient:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.user?.client,
  appClientIsVerified:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.user?.client?.verified_status,
  appClientId:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.user?.client?.id,
  appCurrencyCode:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.currency?.code,
  appCurrencySymbol:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.currency?.symbol || currencies.RUB?.symbol,
  appDescription:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.description ?? '',
  appFollowers:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.follower_count ?? 0,
  appMySubId:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.my_app_subscription?.id ?? null,
  isAppFollowed:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      !!app?.my_app_subscription ?? false,
  appHeaderImages:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.header_images ?? null,
  appHeaderImagesLength:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.header_images?.length ?? 0,
  appName:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.name ?? '',
  appNameCapitalized:
    (state, getters) =>
    (app = state[StateNames.MY_APP]) =>
      getters.appName(app) && capitalize(getters.appName(app)),
  appPostsCount:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.post_count ?? 0,
  appUser:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.user,
  appUserId:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.user?.id,
  appUserName:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.user?.name ?? '',
  appBlockId:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.i_blocked_app?.id ?? null,
  appIsBlocked: (state, getters, rs, rootGetters) => {
    const getIsAppBlocked = rootGetters['Blocks/getIsAppBlocked']
    return getIsAppBlocked(getters.currentApp?.name)
  },
  appSupportChatName:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.personal_chat_name,
  appSupportChatAvatar:
    (state) =>
    (app = state[StateNames.MY_APP]) =>
      app?.personal_chat_avatar,
  isSplitTestApp: (s, getters) => isSplitTestApp(getters.app?.test_code),
  isSplitTestApp2: (s, getters) => isSplitTestApp2(getters.app?.test_code),
  isSplitTestApp3: (s, getters) => isSplitTestApp3(getters.app?.test_code),
  isSplitTestApp4: (s, getters) => isSplitTestApp4(getters.app?.test_code),
  isSplitTestApp5: (s, getters) => isSplitTestApp5(getters.app?.test_code),
  isSplitTestApp6: (s, getters) => isSplitTestApp6(getters.app?.test_code),
  isLandingMode: (s, getters) => isLandingMode(getters.app?.landing_type),
  appPrompts: (state) => state[StateNames.MY_APP]?.prompts ?? null,
}

export default getters
