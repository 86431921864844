import { Module } from '../types'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const module: Module = {
  state: () => ({
    notificationsToken: null,
    notification: null,
    message: null,
  }),
  getters,
  actions,
  mutations,
  namespaced: true,
}

export default module
