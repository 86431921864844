export const getCanvasVideoPreviewURL = (file: File): Promise<string> => {
  const fileReader = new FileReader()
  return new Promise((resolve, reject) => {
    fileReader.onload = () => {
      if (!fileReader.result) {
        reject()
        return
      }
      const blob = new Blob([fileReader.result], { type: file.type })
      const url = URL.createObjectURL(blob)
      const video = document.createElement('video')
      const snapImage = () => {
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const context = canvas.getContext('2d')
        if (!context) {
          return false
        }
        context.drawImage(video, 0, 0, canvas.width, canvas.height)
        const imageURL = canvas.toDataURL()
        const success = imageURL.length > 100000
        if (success) {
          URL.revokeObjectURL(url)
          resolve(imageURL)
        }
        return success
      }
      const timeupdate = () => {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate)
          video.pause()
        }
      }
      video.addEventListener('loadeddata', () => {
        if (snapImage()) {
          video.removeEventListener('timeupdate', timeupdate)
        }
      })
      video.addEventListener('timeupdate', timeupdate)
      video.preload = 'metadata'
      video.src = url
      // Load video in Safari / IE11
      video.muted = true
      video.playsInline = true
      video.play()
    }
    fileReader.readAsArrayBuffer(file)
  })
}

export default { getCanvasVideoPreviewURL }
