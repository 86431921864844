import { Module } from '@store/stories/types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const module: Module = {
  state: () => ({
    stories: {
      data: null,
      page: 1,
    },
    story: null,
    isOpened: false,
  }),
  getters,
  actions,
  mutations,
  namespaced: true,
}

export default module
