/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M408.973 142.689c-40.86-40.86-95.188-63.363-152.973-63.363h-31.717l50.907-51.032L246.826 0 147.68 99.389l97.852 99.488 28.563-28.093-50.551-51.396H256c97.198 0 176.275 79.076 176.275 176.275S353.198 471.938 256 471.938 79.725 392.861 79.725 295.659v-20.031l-40.062.004v20.031c0 57.786 22.503 112.113 63.364 152.973C143.887 489.497 198.215 512 256 512c57.785 0 112.113-22.503 152.973-63.364 40.861-40.861 63.364-95.188 63.364-152.973s-22.503-112.113-63.364-152.974z"/>'
  }
})
