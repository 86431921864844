<template>
  <dropdown :shown="isShown" v-bind="$attrs" v-on="$listeners">
    <slot />
    <template #popper>
      <slot name="content" />
    </template>
  </dropdown>
</template>

<script>
import Vue from 'vue'
import { Dropdown } from 'floating-vue'
import 'floating-vue/dist/style.css'

export default Vue.extend({
  name: 'VPopover',

  components: {
    Dropdown,
  },

  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
