// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatMessages_grid_BvB9p{justify-content:flex-end;height:auto;min-height:100%}.ChatMessages_grid_BvB9p,.ChatMessages_grid_BvB9p .ChatMessages_gridContainer_3ZquZ{display:flex;flex-direction:column}.ChatMessages_grid_BvB9p .ChatMessages_messageWrapper_HgxsW{display:flex;transition:background-color 1s ease-in-out}.ChatMessages_grid_BvB9p .ChatMessages_myMessageWrapper_1Yhk5{justify-content:flex-end}.ChatMessages_grid_BvB9p .ChatMessages_waiting_3WdP0{display:flex;align-items:center;justify-content:center;padding:15px 0;color:var(--supster-secondary)}.ChatMessages_grid_BvB9p .ChatMessages_waiting_3WdP0 .ChatMessages_waitingDots_120r_{width:20px}.ChatMessages_grid_BvB9p .ChatMessages_waitingError_2We1C{display:flex;align-items:center;justify-content:center;padding:15px 0;color:#e54d42}", ""]);
// Exports
exports.locals = {
	"grid": "ChatMessages_grid_BvB9p",
	"gridContainer": "ChatMessages_gridContainer_3ZquZ",
	"messageWrapper": "ChatMessages_messageWrapper_HgxsW",
	"myMessageWrapper": "ChatMessages_myMessageWrapper_1Yhk5",
	"waiting": "ChatMessages_waiting_3WdP0",
	"waitingDots": "ChatMessages_waitingDots_120r_",
	"waitingError": "ChatMessages_waitingError_2We1C"
};
module.exports = exports;
