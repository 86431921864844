// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VAvatar_section_3zB-g{position:relative;display:grid;flex-shrink:0;border-radius:100%}.VAvatar_section_3zB-g.VAvatar_rect_-J8xa{border-radius:47px}.VAvatar_section_3zB-g.VAvatar_xs_3N2T0{width:33px;height:33px}.VAvatar_section_3zB-g.VAvatar_sm_1OgMV{width:36px;height:36px}.VAvatar_section_3zB-g.VAvatar_md_3AETd{width:42px;height:42px}.VAvatar_section_3zB-g.VAvatar_md-2_1HlrI{width:46px;height:46px}.VAvatar_section_3zB-g.VAvatar_md-3_ucO2d{width:54px;height:54px}.VAvatar_section_3zB-g.VAvatar_md-4_2r2ps{width:68px;height:68px}.VAvatar_section_3zB-g.VAvatar_md-5_1-tMB{width:80px;height:80px}.VAvatar_section_3zB-g.VAvatar_lg_1FNAg{width:90px;height:90px}.VAvatar_section_3zB-g.VAvatar_xl_1seZA{width:122px;height:122px}.VAvatar_wrap_9ydMB{display:flex;cursor:pointer}.VAvatar_wrap_9ydMB.VAvatar_default_3NoSs{cursor:default}.VAvatar_content_3S8DD{display:grid;width:100%!important;height:100%!important;overflow:hidden}.VAvatar_content_3S8DD .VAvatar_image_3kBIc{z-index:1;width:100%;height:100%}", ""]);
// Exports
exports.locals = {
	"section": "VAvatar_section_3zB-g",
	"rect": "VAvatar_rect_-J8xa",
	"xs": "VAvatar_xs_3N2T0",
	"sm": "VAvatar_sm_1OgMV",
	"md": "VAvatar_md_3AETd",
	"md-2": "VAvatar_md-2_1HlrI",
	"md-3": "VAvatar_md-3_ucO2d",
	"md-4": "VAvatar_md-4_2r2ps",
	"md-5": "VAvatar_md-5_1-tMB",
	"lg": "VAvatar_lg_1FNAg",
	"xl": "VAvatar_xl_1seZA",
	"wrap": "VAvatar_wrap_9ydMB",
	"default": "VAvatar_default_3NoSs",
	"content": "VAvatar_content_3S8DD",
	"image": "VAvatar_image_3kBIc"
};
module.exports = exports;
