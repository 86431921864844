<template>
  <v-button :class="$style.button">
    <icon :class="$style.icon" name="donate" width="16" height="16" fill="primary" />
    <span :class="$style.text">{{
      `${$t('donate', { amount: amount, currency: currency })} ${target}`
    }}</span>
  </v-button>
</template>

<script>
import Vue from 'vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'ChatDonateButton',

  props: {
    amount: {
      type: [Number, String],
      default: 0,
    },
    currency: {
      type: String,
      default: '$',
    },
    target: String,
  },

  components: {
    VButton,
  },
})
</script>

<style lang="scss" module>
.button {
  .icon {
    margin-right: 9px;
  }

  .text {
    color: $primary;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  }
}
</style>
