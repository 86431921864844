import { axiosJWT } from '@plugins/axios'
import { DataType, GetParams } from './types'

export default {
  getStories: async (params: GetParams): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosJWT.get('stories', { params })
    return data === null ? [] : data
  },
  getStory: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.get(`stories/${id}`)
    return data
  },
  postStory: async (story: DataType): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.post(`stories`, story)
    return data
  },
  puStory: async (id: number, story: DataType): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.put(`stories/${id}`, story)
    return data
  },
  deleteStory: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.delete(`stories/${id}`)
    return data
  },
}
