// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileMedia_grid_WqzbM{flex:1}.ProfileMedia_product_3ZdeY{grid-column:span 2}.ProfileMedia_product_3ZdeY.ProfileMedia_big_1CkJ6{grid-column:span 3}.ProfileMedia_add_1FntT{display:grid;grid-template-columns:repeat(3,1fr);padding:6px;-moz-column-gap:1.5px;column-gap:1.5px;row-gap:1.5px;border-top:1px solid #e5e3e3}.ProfileMedia_add_1FntT .ProfileMedia_container_2WWfd{position:relative;padding-bottom:100%;overflow:hidden}.ProfileMedia_add_1FntT .ProfileMedia_container_2WWfd:last-child{border-radius:0 6px 6px 0}.ProfileMedia_add_1FntT .ProfileMedia_container_2WWfd:first-child{border-radius:6px 0 0 6px}.ProfileMedia_add_1FntT .ProfileMedia_image_1jKK1{position:absolute;top:50%;left:50%;width:100%;height:100%;transform:translate(-50%,-50%)}", ""]);
// Exports
exports.locals = {
	"grid": "ProfileMedia_grid_WqzbM",
	"product": "ProfileMedia_product_3ZdeY",
	"big": "ProfileMedia_big_1CkJ6",
	"add": "ProfileMedia_add_1FntT",
	"container": "ProfileMedia_container_2WWfd",
	"image": "ProfileMedia_image_1jKK1"
};
module.exports = exports;
