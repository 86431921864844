import AppApi from '@services/app'
import { Actions } from './types'

const actions: Actions = {
  async getFollowing({ dispatch, commit, state }, { scroll }: { scroll: InfiniteType }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const apps = await AppApi.getApps({
        search: state.query,
        subscribed: true,
        limit: 20,
        page: state.following.page,
      })
      commit('setFollowing', apps)
      if (scroll) {
        apps?.length ? scroll.loaded() : scroll.complete()
      }
      return apps
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
