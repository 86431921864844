// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VLoader_loader_OTsXx{position:relative;width:100%;height:100%}", ""]);
// Exports
exports.locals = {
	"loader": "VLoader_loader_OTsXx"
};
module.exports = exports;
