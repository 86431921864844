/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dots-4': {
    width: 3,
    height: 18,
    viewBox: '0 0 3 18',
    data: '<path pid="0" d="M1.5 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM1.5 5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM0 11.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM1.5 15a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"/>'
  }
})
