<template>
  <VDrawer is-close name="chat-share" :title="$t('shareChatDrawer.title')" @close="onClose">
    <div :class="$style.content">
      <div :class="$style.subTitle">
        {{ $t('shareChatDrawer.subTitle') }}
      </div>
      <ChatShareMessages />
      <div :class="$style.chatName">
        <span>{{ $t('shareChatDrawer.chatName') }}</span>
        <span>{{ chatName }}</span>
      </div>
      <div :class="$style.chatCreated">
        <span>{{ $t('shareChatDrawer.chatCreated') }}</span>
        <VDateGap :date="chatEventDate" />
      </div>
    </div>
    <div :class="$style.footer">
      <VButton size="xl-1" color="primary" @click="copyLink">
        {{ $t('shareChatDrawer.buttonText') }}
      </VButton>
    </div>
  </VDrawer>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import { Clipboard } from 'v-clipboard'
import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'
import VDateGap from '@layouts/VDateGap.vue'
import { createSharedLink } from '@modules/chat/services/chat-sharing'
import ChatShareMessages from './ChatShareMessages.vue'

export default Vue.extend({
  name: 'ChatShareDrawer',
  components: { ChatShareMessages, VDrawer, VButton, VDateGap },
  props: {
    isVisible: Boolean,
  },
  computed: {
    ...mapGetters('Chat', ['chat', 'chatSecretKey']),
    ...mapGetters('Client', ['clientId']),
    chatEventDate() {
      return this.chat?.last_event_time || this.chat?.created || ''
    },
    chatName() {
      return this.chat?.name ?? ''
    },
  },

  methods: {
    ...mapMutations('Toasts', ['pushToast']),
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Chat', ['resetChat', 'resetMessages', 'setIsDemoMode']),
    copyLink() {
      this.setDrawer({ name: 'chat-share', isVisible: false })
      Clipboard.copy(createSharedLink(this.chatSecretKey, this.clientId))
      this.pushToast({
        message: this.$t('linkCopied'),
        type: 'success',
      })
    },
    onClose() {
      this.resetMessages()
      this.resetChat()
      this.setIsDemoMode(false)
    },
  },
})
</script>
<style lang="scss" module>
.content {
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid $secondary-light;
}

.subTitle {
  padding: 12px 15px;
  font-size: 12px;
  line-height: 18px;
  background: $shadow;
  border-bottom: 1px solid $secondary-light;
}

.chatName {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 12px 15px;
  font-size: 12px;
  line-height: 18px;
  background: $shadow;
  border-top: 1px solid $secondary-light;
  border-bottom: 1px solid $secondary-light;
  span:first-child {
    color: $secondary-medium;
  }
}

.chatCreated {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 12px 15px;
  font-size: 12px;
  line-height: 18px;
  border-bottom: 1px solid $secondary-light;
}

.footer {
  padding: 15px;
}
</style>
