import { getTime } from '@helpers/dates'
import { isAppSupportChat } from '@modules/chat/helpers/chat'
import ClientApi from '@services/client'
import PromotionalApi from '@services/promotional'
import { Chat, ChatType } from '@common-types/chat'
import { ChatsTab, Service } from './types'

export const addChats = (chatsTab: ChatsTab, chats: Chat[]): ChatsTab => ({
  ...chatsTab,
  chats: [...(chatsTab.chats ?? []), ...chats],
  page: chatsTab.page + 1,
})

export const addSubscribedChat: Service<'chat' | 'store', void> = ({ chat, store }) => {
  const chats: Chat[] = store.getters['Chats/chats']
  const isLoadingCompleted = store.getters['Chats/isLoadingCompleted']
  const isClientOwner = store.getters['Client/isClientOwner']
  const chatsName = store.getters['Chats/chatsName']
  const insertedIndex =
    chats?.findIndex(({ last_event_time, type }) => {
      const isLater = getTime(last_event_time) < getTime(chat.last_event_time)
      return isLater && (isClientOwner || !isAppSupportChat(type))
    }) ?? -1

  const modifiedChats = chats ? [...chats] : []
  if (insertedIndex > -1) {
    modifiedChats.splice(insertedIndex, 0, chat)
  } else if (isLoadingCompleted) {
    modifiedChats.push(chat)
  }
  store.commit('Chats/replaceChats', { chatsName, chats: modifiedChats })
}

export const updateChatsItem: Service<'chats' | 'chatFields', Chat[]> = ({ chats, chatFields }) =>
  chats.map((chat) => (chat.id === chatFields.id ? { ...chat, ...chatFields } : chat))

export const incrementChatsUnreadCount: Service<'chats' | 'chatId', Chat[]> = ({ chats, chatId }) =>
  chats.map((chat) => {
    const { channel_subscription } = chat
    const { new_message_count } = channel_subscription
    const subscription = {
      ...channel_subscription,
      new_message_count: new_message_count + 1,
    }
    return chat.id === chatId ? { ...chat, channel_subscription: subscription } : chat
  })
export const hasAppSupportChat = (chats: Chat[], appName: string): boolean =>
  chats[0]?.type === ChatType.APP_SUPPORT_CHAT && chats[0]?.app?.name === appName

export const unshiftChat = ({
  chatsTab,
  chat,
  store,
}: {
  chatsTab: ChatsTab
  chat: Chat
  store: any
}): ChatsTab => {
  const chats = chatsTab.chats ?? []
  const appName = store.rootGetters['App/appName']()
  const isClientOwner = store.rootGetters['Client/isClientOwner']
  hasAppSupportChat(chatsTab.chats, appName) && !isClientOwner
    ? chats.splice(1, 0, chat)
    : chats.unshift(chat)
  return {
    ...chatsTab,
    chats,
  }
}

export const resetUnreadMessageCount = (chats: Chat[], chatId: number): Chat[] =>
  chats.map((chat) =>
    chat.id === chatId
      ? { ...chat, channel_subscription: { ...chat.channel_subscription, new_message_count: 0 } }
      : chat
  )

export const initAppSupportChat: Service<'chat' | 'store', Promise<void>> = async ({
  chat,
  store,
}) => {
  const clientId = store.getters['App/appClientId']()
  const name = store.getters['App/appSupportChatName']()
  const image = store.getters['App/appSupportChatAvatar']()
  const app_id = store.getters['App/appId']()
  const is_accessible = await ClientApi.getAppSupportChatIsAccessible(clientId)
  const promotional = is_accessible
    ? null
    : await PromotionalApi.getPromotionals({
        app_id,
        trial: true,
      })
  const promotional_page_id = promotional?.[0]?.id ?? null
  store.commit('Chats/setAppSupportChat', {
    ...chat,
    is_accessible,
    promotional_page_id,
    name,
    image,
  })
}
export default {
  addChats,
}
