// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LocalesDrawer_item_2cEuM{width:100%;padding:13.5px 15px;overflow:hidden;box-shadow:inset 0 -1px 0 0 #e5e3e3;cursor:pointer}.LocalesDrawer_item_2cEuM:hover{opacity:.8}.LocalesDrawer_cancel_2G88S{color:#fff;background:#737373;box-shadow:none}", ""]);
// Exports
exports.locals = {
	"item": "LocalesDrawer_item_2cEuM",
	"cancel": "LocalesDrawer_cancel_2G88S"
};
module.exports = exports;
