import { axiosJWT } from '@plugins/axios'
import { DataType, GetParams } from './types'

export default {
  getPromotionals: async (params: GetParams): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosJWT.get('promotional_pages', { params })
    return data === null ? [] : data
  },
  getPromotional: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.get(`promotional_pages/${id}`)
    return data
  },
  postPromotional: async (promotional: DataType): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.post('promotional_pages', promotional)
    return data
  },
}
