import Vue from 'vue'
import { Mutations } from './types'

const mutations: Mutations = {
  setDrawer(
    state,
    { name, isVisible, params }: { name: string; isVisible: boolean; params?: any }
  ) {
    Vue.set(state.drawers, name, isVisible)
    state.params = isVisible ? params ?? null : null
  },
}

export default mutations
