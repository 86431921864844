import { Mutations } from './types'

const mutations: Mutations = {
  setAccessToken(state, token) {
    localStorage.setItem('access-token', token)
    state.accessToken = token
  },
  setcId(state, cId) {
    state.cId = cId
  },
  setStream(state, stream) {
    state.stream = stream
  },
  setSubId(state, subId) {
    state.subId = subId
  },
  setRefId(state, refId) {
    state.refId = refId
  },
  setIsSkipInstall(state, isSkipInstall) {
    state.isSkipInstall = isSkipInstall
  },
  setIsPassInstall(state, isPassInstall) {
    state.isPassInstall = isPassInstall
  },
}

export default mutations
