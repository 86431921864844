// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VHeaderRight_container_3j8HG{position:absolute;right:0;display:grid;grid-auto-columns:auto;grid-auto-flow:column;align-items:center;height:100%}.VHeaderRight_check_3a_L4,.VHeaderRight_check_3a_L4 path{fill:var(--supster-main-text-color)}.VHeaderRight_avatar_2Gry0{margin-right:8px}.VHeaderRight_button_23k4H{align-self:center;width:45px;height:45px}.VHeaderRight_button_23k4H svg{fill:var(--supster-main-text-color)}.VHeaderRight_shop_1H88E{display:grid;grid-template-columns:repeat(2,min-content);justify-content:center;-moz-column-gap:6px;column-gap:6px}.VHeaderRight_icon_1atYk{width:13px;height:13px}.VHeaderRight_icon_1atYk,.VHeaderRight_value_1-Us5{align-self:center}.VHeaderRight_value_1-Us5{color:var(--supster-secondary)}.VHeaderRight_burger_39KKK{width:45px}@media(min-width:768px){.VHeaderRight_burger_39KKK{display:none}}", ""]);
// Exports
exports.locals = {
	"container": "VHeaderRight_container_3j8HG",
	"check": "VHeaderRight_check_3a_L4",
	"avatar": "VHeaderRight_avatar_2Gry0",
	"button": "VHeaderRight_button_23k4H",
	"shop": "VHeaderRight_shop_1H88E",
	"icon": "VHeaderRight_icon_1atYk",
	"value": "VHeaderRight_value_1-Us5",
	"burger": "VHeaderRight_burger_39KKK"
};
module.exports = exports;
