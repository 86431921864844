import { Mutations } from './types'

const mutations: Mutations = {
  setCartProduct(state, product) {
    state.products =
      state.products && state.products.some(({ id }) => id === product.id)
        ? state.products.map((cartProduct) =>
            cartProduct.id === product.id
              ? { ...cartProduct, count: cartProduct.count + 1 }
              : cartProduct
          )
        : [...(state.products || []), { count: 1, ...product }]
    localStorage.setItem('cart', JSON.stringify(state.products))
  },
  setCartProductCount(state, { id, type }: { id: number; type: 'increment' | 'decrement' }) {
    const step = type === 'increment' ? 1 : -1
    state.products =
      state.products
        ?.map((product) => {
          return product.id === id ? { ...product, count: product.count + step } : product
        })
        ?.filter(({ count }) => count) || null
    localStorage.setItem('cart', JSON.stringify(state.products))
  },
  removeCartProduct(state, id) {
    state.products = state.products?.filter(({ id: productId }) => productId !== id) || null
    localStorage.setItem('cart', JSON.stringify(state.products))
  },
  resetCartProducts(state) {
    state.products = null
    localStorage.setItem('cart', JSON.stringify(state.products))
  },
}

export default mutations
