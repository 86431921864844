/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M12 1H2a2 2 0 00-2 2v8a2 2 0 002 2h10a2 2 0 002-2V3a2 2 0 00-2-2zm1 10c0 .551-.449 1-1 1H2c-.551 0-1-.449-1-1V3c0-.551.449-1 1-1h10c.551 0 1 .449 1 1v8zM5 4l5 3-5 3V4z"/>'
  }
})
