export const VirtualizationConfig = {
  VISIBLE_BLOCKS_COUNT: 7,
  SCROLL_STEP: 1,
  BLOCK_HEIGHT: 315,
}

export const GridBlockTypes = {
  COMMON: 'common',
  BIG_LEFT: 'big_left',
  BIG_RIGHT: 'big_right',
}

export default {
  PAGE_LIMIT: 36,
  REQUESTS_PARAMS: {
    direction: 'desc',
    limit: 30,
    random: false,
    media_type: 'image',
  },
}
