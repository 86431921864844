// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VCover_element_2iL2L{position:relative;display:flex;width:100%;height:100%;overflow:hidden}.VCover_notSupported_2YlEs{height:138px}.VCover_filePreview_X8MAS{display:flex;align-items:center;align-self:center;justify-self:center}.VCover_filePreviewName_1jwdI{max-width:300px;overflow:hidden;text-overflow:ellipsis}.VCover_content_2YSyM{-o-object-fit:cover;object-fit:cover}.VCover_content_2YSyM,.VCover_cover_3QG4v{width:100%;height:100%}.VCover_cover_3QG4v{position:absolute;top:0;left:0;display:flex;flex-direction:column;align-items:center;justify-content:center;transition:background .3s ease-in}.VCover_coverBackground_Jd_Po{background-color:rgba(229,227,227,.7)}.VCover_coverError_2mZDc{background-color:rgba(251,78,78,.7)}.VCover_button_1GKZa{position:relative}.VCover_button_1GKZa,.VCover_ring_1Hqu6{align-self:center;justify-self:center}.VCover_ring_1Hqu6{width:60px;height:60px}.VCover_convertation_1m5oz,.VCover_icon_3TekR{width:100%;height:100%}.VCover_convertation_1m5oz{display:flex;align-items:center;justify-content:center;background:var(--supster-primary)}.VCover_convertationIcon_1Ek0s{width:20px;height:20px}", ""]);
// Exports
exports.locals = {
	"element": "VCover_element_2iL2L",
	"notSupported": "VCover_notSupported_2YlEs",
	"filePreview": "VCover_filePreview_X8MAS",
	"filePreviewName": "VCover_filePreviewName_1jwdI",
	"content": "VCover_content_2YSyM",
	"cover": "VCover_cover_3QG4v",
	"coverBackground": "VCover_coverBackground_Jd_Po",
	"coverError": "VCover_coverError_2mZDc",
	"button": "VCover_button_1GKZa",
	"ring": "VCover_ring_1Hqu6",
	"convertation": "VCover_convertation_1m5oz",
	"icon": "VCover_icon_3TekR",
	"convertationIcon": "VCover_convertationIcon_1Ek0s"
};
module.exports = exports;
