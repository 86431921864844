<template>
  <div>
    <v-tabs v-if="isEditable" :tabs="tabs" :active-index.sync="tabIndex" :class-name="$style.cover">
      <template #tab-multi>
        <section :class="[$style.section, oneHeaderImage && $style['single-section']]">
          <div
            v-for="i in [0, 1, 2]"
            :key="`cover-${i}`"
            @click="onClickItemOfMultiCover(i)"
            :class="[$style.container, oneHeaderImage && $style['single-container']]"
          >
            <v-image
              v-if="
                profileClientApp &&
                profileClientApp.header_images &&
                profileClientApp.header_images[i]
              "
              :class="$style.image"
              :src="getHeaderImageContentUrl(i)"
              :srcset="getHeaderImagePreviewUrl(i)"
            >
              <template #content>
                <v-file
                  ref="vFileCover"
                  :class="$style.edit"
                  color="primary"
                  is-circle
                  :fileTypes="['image']"
                  @files="onEdit(files, i)"
                >
                  <icon name="edit" width="13" height="13" />
                </v-file>
              </template>
            </v-image>
            <profile-add v-else :class="$style.image" @upload="onUpload" />
          </div>
        </section>
      </template>
      <template #tab-single>
        <section :class="[$style.section, $style['single-section']]">
          <div
            v-if="profileClientApp.header_images[0]"
            @click="$refs.vFileCover.onButton()"
            :class="[$style.container, $style['single-container']]"
          >
            <v-image
              :class="$style.image"
              :src="getHeaderImageContentUrl(0)"
              :srcset="getHeaderImagePreviewUrl(0)"
            >
              <template #content>
                <v-file
                  ref="vFileCover"
                  :class="$style.edit"
                  color="primary"
                  is-circle
                  :fileTypes="['image']"
                  @files="onEdit(files, 0)"
                >
                  <icon name="edit" width="13" height="13" />
                </v-file>
              </template>
            </v-image>
          </div>
          <div
            :class="[$style.container, $style['single-container']]"
            v-if="profileHeaderImagesLength < 1"
          >
            <profile-add :class="$style.image" @upload="onUpload" />
          </div>
        </section>
      </template>
    </v-tabs>
    <section v-else :class="[$style.section, oneHeaderImage && $style['single-section']]">
      <div
        :class="[$style.container, oneHeaderImage && $style['single-container']]"
        v-for="(image, i) in appHeaderImages(currentApp)"
        :key="`cover-${i}`"
      >
        <v-image :class="$style.image" :src="image.content_url" :srcset="image.preview_url">
          <template #content>
            <v-file
              :class="$style.edit"
              v-if="isEditable"
              color="primary"
              is-circle
              @files="onEdit()"
            >
              <icon name="edit" width="13" height="13" />
            </v-file>
          </template>
        </v-image>
      </div>
      <div :class="$style.container" v-if="isEditable && headerImagesLength < 3">
        <profile-add :class="$style.image" @upload="onUpload" />
      </div>
    </section>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VImage from '@elements/VImage.vue'
import VFile from '@elements/VFile.vue'
import VTabs from '@layouts/VTabs.vue'
import ProfileAdd from './ProfileAdd.vue'

export default Vue.extend({
  name: 'ProfileCover',
  components: { VImage, VFile, ProfileAdd, VTabs },
  props: {
    isEditable: Boolean,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  computed: {
    ...mapGetters('App', ['appHeaderImages', 'currentApp']),
    ...mapGetters('Client', ['isClientOwner']),
    ...mapGetters('Files', ['files']),
    ...mapGetters('Profile', ['profileClientApp']),
    headerImagesLength() {
      return this.appHeaderImages(this.currentApp)?.length ?? 0
    },
    profileHeaderImagesLength() {
      return this.profileClientApp?.header_images?.length ?? 0
    },
    oneHeaderImage() {
      return this.headerImagesLength < 2 && !this.isEditable
    },
    editOwner() {
      return this.isEditable && this.isClientOwner
    },
    editClient() {
      return this.isEditable && !this.isClientOwner
    },

    tabs() {
      return [
        { value: this.$t('multiImages'), name: 'multi' },
        { value: this.$t('singleImage'), name: 'single' },
      ]
    },
  },
  methods: {
    ...mapMutations('Profile', ['setProfileClientApp']),
    onClickItemOfMultiCover(index) {
      const currentFileCover = this.$refs.vFileCover?.[index]
      if (currentFileCover) {
        currentFileCover.onButton()
      }
    },
    getHeaderImageContentUrl(i) {
      return (
        this.profileClientApp.header_images[i].content_url ||
        this.profileClientApp.header_images[i].url
      )
    },
    getHeaderImagePreviewUrl(i) {
      return (
        this.profileClientApp.header_images[i].preview_url ||
        this.profileClientApp.header_images[i].url
      )
    },
    async onUpload(files) {
      this.setProfileClientApp({
        header_images: [...this.profileClientApp?.header_images, files[0]],
      })
    },
    async onEdit(files, index) {
      this.setProfileClientApp({
        header_images: this.profileClientApp?.header_images.map((item, i) => {
          return i === index ? files[0] : item
        }),
      })
    },
  },
  watch: {
    tabIndex() {
      this.setProfileClientApp({ coverMode: this.tabIndex })
    },
    isEditable() {
      if (this.isEditable) {
        if (this.editOwner) {
          this.setProfileClientApp({
            header_images: this.appHeaderImages(this.currentApp),
          })
        } else if (!this.profileHeaderImagesLength) {
          this.setProfileClientApp({ header_images: [] })
        }
      }
    },
  },
  mounted() {
    if (this.isEditable) {
      if (this.editOwner) {
        this.setProfileClientApp({
          header_images: this.appHeaderImages(this.currentApp),
          coverMode: this.tabIndex,
        })
      } else if (!this.profileHeaderImagesLength) {
        this.setProfileClientApp({ header_images: [], coverMode: this.tabIndex })
      }
    }
  },
})
</script>
<style lang="scss" module>
.section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 6px;
  column-gap: 1.5px;
  row-gap: 1.5px;
}
.cover {
  background-color: white;
  border-top: 1px solid $secondary-light;
}
.single-section {
  display: block;
}
.container {
  position: relative;
  padding-bottom: 100%;
  overflow: hidden;
  cursor: pointer;
  &:last-child {
    border-radius: 0 6px 6px 0;
  }

  &:first-child {
    border-radius: 6px 0 0 6px;
  }
}
.single-container {
  padding-bottom: 146px;
  border-radius: 6px !important;
}
.image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  & .edit {
    position: absolute;
    right: 4px;
    bottom: 4px;
  }
}
</style>
