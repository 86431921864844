import { splitPosts } from '@helpers/posts'
import { createChat } from '@modules/chat/helpers/chat'
import { Tab } from '../constants'
import { Getters } from '../types'
import { Post, PostMeta } from '../../../common-types/post'

const getters: Getters = {
  profileContent: (state, getters) => (tab: Tab) => {
    const chats = state[tab]
    return tab === Tab.CHATS ? [getters.profileAppSupportChat, ...chats] : chats
  },
  profileContentLength: (state) => (tab: Tab) => state[tab].length ?? null,
  profileTariffs: (state) => state.tariffs,
  profileClientApp: (state) => state.clientApp,
  profileTariffsLength: (state) => state.tariffs?.length ?? null,
  profileContentFeed: (s, getters, rs, rootGetters) => (tab: Tab) => {
    const postMeta: PostMeta = rootGetters['Post/postMeta']
    const profileContent: Post['id'][] = getters.profileContent(tab)
    return splitPosts({ postIds: profileContent, postId: postMeta?.postId })
  },
  profileAppSupportChat: (state, g, rs, rootGetters) => {
    const app = rootGetters['App/currentApp']
    const appSupportChat = state.appSupportChats[app.id] ?? createChat()
    return { ...appSupportChat, app }
  },
  isClientEditingError: (state) => state.isClientEditingError,
}

export default getters
