<template>
  <div :class="$style.page">
    <VGrid
      :length="dataLength"
      :is-infinite="isInfinite"
      :no-data="!dataLength"
      :is-first-load="isPostPage"
      @infinite="onGetFeed"
    >
      <div :style="wrapperStyles">
        <VPost
          v-for="post in data"
          :key="`post-${post.id}`"
          :post="post"
          :observers="observers"
          @mounted="onPostComponentMounted"
        />
      </div>
      <template #placeholder>
        <PPost v-for="i in placeholderCount" :key="i" />
      </template>
    </VGrid>
    <VReportMenu :is-post-page="isPostPage" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VGrid from '@layouts/VGrid.vue'
import VPost from '@layouts/post/VPost.vue'
import PPost from '@placeholders/PPost.vue'
import VReportMenu from '@components/layouts/post/list/VReportMenu.vue'

import Config from '@config/index'

import { PostSource } from '@common-types/post'
import { VirtualizationConfig } from './config'

export default Vue.extend({
  name: 'Feed',
  components: {
    VGrid,
    VPost,
    PPost,
    VReportMenu,
    // VPostShare,
  },
  data() {
    return {
      cacheHandlersMapId: null,
    }
  },
  computed: {
    ...mapGetters('App', ['appName']),
    ...mapGetters('Client', ['isClientOwner']),
    ...mapGetters('Feed', [
      'postIds',
      'visiblePosts',
      'visiblePostsIds',
      'wrapperPaddingTop',
      'wrapperPaddingBottom',
      'averageComponentHeight',
      'startIndex',
    ]),
    ...mapGetters('Post', ['post', 'postId', 'postName', 'postBody', 'postMeta']),
    ...mapGetters('Page', ['pageIsFooter']),
    wrapperStyles() {
      return {
        paddingTop: `${this.wrapperPaddingTop}px`,
        paddingBottom: `${this.wrapperPaddingBottom}px`,
      }
    },
    observers() {
      const autoplayObserver = new IntersectionObserver(this.autoplayObserverCallback.bind(this), {
        root: document.getElementById('main'),
        threshold: 1.0,
      })
      return [autoplayObserver]
    },
    postRouteId() {
      return Number(this.$route?.params?.id) ?? null
    },
    isPostPage() {
      return !!this.postRouteId
    },
    hasPostMeta() {
      return !!this.postMeta?.source
    },
    isSearchSource() {
      return this.postMeta?.source === PostSource.SEARCH
    },
    isInfinite() {
      return !this.isPostPage || (this.isPostPage && this.isSearchSource)
    },
    data() {
      return !this.isPostPage ? this.visiblePosts : this.post ? [this.post] : []
    },
    dataLength() {
      return this.data.length
    },
    placeholderCount() {
      return this.isPostPage ? 1 : 10
    },
  },
  watch: {
    visiblePostsIds: {
      handler(postIds) {
        this.setVisiblePosts(postIds)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('Feed', ['getFeed']),
    ...mapActions('Post', ['getPost']),
    ...mapActions('Profile', ['getProfileContent']),
    ...mapMutations('Page', ['setPageTitle', 'setPageBack', 'setPageIsFooter']),
    ...mapMutations('Feed', [
      'setIntersectedPostId',
      'setFeed',
      'setVisiblePosts',
      'addComponentHeight',
      'setVisiblePosts',
      'setStartIndex',
    ]),
    ...mapMutations('Post', ['setPost']),
    onPostComponentMounted({ height, postId }) {
      if (this.isPostPage) {
        return
      }
      this.addComponentHeight({ postId, height })
    },
    async onGetFeed(scroll) {
      if (!this.isPostPage) {
        await this.getFeed({ scroll })
        this.setCacheHandlerFeed()
        return
      }
      if (!this.hasPostMeta) {
        await this.getPost(this.postRouteId)
        this.setCacheHandlerPost()
      }
    },
    autoplayObserverCallback(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (isIntersecting) {
          this.setIntersectedPostId(Number(target.dataset.postId))
        }
      })
    },
    setCacheHandlerFeed() {
      if (this.cacheHandlersMapId) {
        return
      }
      this.cacheHandlersMapId = this.$cacheManager.add({
        regExpURL: Config.URLRegExps.FEED,
        cacheHandlers: [
          (data) => {
            this.setFeed(data)
          },
        ],
      })
    },
    setCacheHandlerPost() {
      if (this.cacheHandlersMapId) {
        return
      }
      this.cacheHandlersMapId = this.$cacheManager.add({
        regExpURL: Config.URLRegExps.POST,
        cacheHandlers: [
          (data) => {
            this.setPost(data)
          },
        ],
      })
    },
    scrollListener(event) {
      const scrollTopPadding = event.target.scrollTop - this.averageComponentHeight
      const scrollTop = scrollTopPadding > 0 ? scrollTopPadding : 0
      const startIndex =
        Math.floor(scrollTop / this.averageComponentHeight) * VirtualizationConfig.SCROLL_STEP
      this.checkStartIndex(startIndex)
    },
    checkStartIndex(index) {
      if (this.startIndex !== index) {
        this.setStartIndex(index)
      }
    },
    connectScrollWatcher() {
      const mainWrapper = document.getElementById('main')
      mainWrapper.addEventListener('scroll', this.scrollListener)
    },
    deleteScrollWatcher() {
      const mainWrapper = document.getElementById('main')
      mainWrapper.removeEventListener('scroll', this.scrollListener)
    },
  },
  mounted() {
    this.connectScrollWatcher()
  },
  beforeMount() {
    if (this.isPostPage) {
      if (!this.hasPostMeta) {
        this.setPageBack({
          isDisabled: true,
        })
      }
    } else {
      this.setPageBack({
        isDisabled: true,
      })
    }
    this.setPageTitle({ name: this.appName(), type: 'appName' })
    if (!this.pageIsFooter) {
      this.setPageIsFooter(true)
    }
  },
  beforeDestroy() {
    this.deleteScrollWatcher()
    if (this.cacheHandlersMapId) {
      this.$cacheManager.remove([this.cacheHandlersMapId])
    }
  },
  destroyed() {
    this.setIntersectedPostId(null)
    this.setPageBack({
      isDisabled: false,
    })
  },
})
</script>

<style lang="scss" module>
.page {
  position: relative;
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  color: $secondary;
}
</style>
