<template>
  <v-grid
    :class="$style.grid"
    :length="chatMessagesLength"
    :container-class="$style.gridContainer"
    :is-infinite="isInfinite"
    is-reversed
    not-length-watch
    :is-both-direction="prevPage > 0"
    v-on="$listeners"
  >
    <div
      v-for="message of chatMessages"
      :key="getMessageKey(message)"
      :id="`message-${message.id}`"
      :class="[
        $style.messageWrapper,
        !isChannel && getIsMyMessage(message) && $style.myMessageWrapper,
      ]"
    >
      <ChatMessage
        v-if="isChatGPTBot"
        :key="getMessageKey(message)"
        :id="getMessageKey(message)"
        :markdown-body="getMarkdownBody(message)"
        :message="message"
        :is-my-message="!isChannel && getIsMyMessage(message)"
        @resend-last-message="$emit('resend-last-message')"
      />
      <ChatMessagePopover
        v-else
        :key="getMessageKey(message)"
        :id="getMessageKey(message)"
        :markdown-body="getMarkdownBody(message)"
        :message="message"
        :is-my-message="!isChannel && getIsMyMessage(message)"
        :boundary="boundary"
        @select="selectMessage"
        @close-menu="deselectMessage"
      />
    </div>
    <div v-if="waiting && !waitingMidjourney" :class="$style.waiting">
      <span>{{ $t('gpt.waiting') }} </span>
      <span :class="$style.waitingDots">{{ waitingDots }} </span>
    </div>
    <div v-else-if="waitingMidjourney" :class="$style.waiting">
      <ChatProgressLoader />
    </div>
    <div v-else-if="isResponseError" :class="$style.waitingError">
      {{ $t('gpt.somethingWrong') }}
    </div>
    <template #placeholder>
      <PChatMessage v-for="i in 8" :key="i" :even="i % 2 === 0" />
    </template>
  </v-grid>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VGrid from '@layouts/VGrid.vue'
import { getHashFromDate } from '@helpers/hash'
import PChatMessage from '@placeholders/PChatMessage.vue'
import { isMyMessage } from '@modules/messages/services'
import Config from '@config/index'
import ChatProgressLoader from '@modules/chat/modules/ChatProgressLoader.vue'
import ChatMessagePopover from './ChatMessagePopover.vue'
import 'highlight.js/styles/github-dark.css'
import '@assets/style/markdown/tables.scss'
import '@assets/style/markdown/code.scss'
import ChatMessage from './ChatMessage.vue'
import markDown from '../services/markdown'

export default Vue.extend({
  name: 'ChatMessages',
  components: {
    ChatProgressLoader,
    PChatMessage,
    ChatMessage,
    VGrid,
    ChatMessagePopover,
  },
  props: {
    boundary: {
      type: Element,
      default: null,
    },
    waitingDots: {
      type: String,
      default: '...',
    },
    waiting: Boolean,
    waitingMidjourney: Boolean,
    isResponseError: Boolean,
  },
  data() {
    return {
      cacheHandlersMapIds: [],
    }
  },
  computed: {
    ...mapGetters('Chat', [
      'chatMessages',
      'chatMessagesLength',
      'selectedMessageId',
      'isChannel',
      'prevPage',
      'chatId',
      'chat',
      'isChatGPTBot',
    ]),
    isInfinite() {
      return this.chatId && !this.chat?.temp
    },
  },
  created() {
    this.createCacheHandler()
    this.watchWSReconnection()
  },
  beforeDestroy() {
    if (this.cacheHandlersMapIds) {
      this.$cacheManager.remove(this.cacheHandlersMapIds)
    }
    this.closeWatchingWSReconnection()
  },
  methods: {
    ...mapMutations('Chat', [
      'resetPinnedMessages',
      'pushPinnedMessages',
      'refreshMessages',
      'setSelectedMessageId',
      'resetSelectedMessageId',
      'resetMessages',
    ]),
    watchWSReconnection() {
      this.$bus.$on('centrifuge-reconnect', () => {
        this.resetMessages()
        this.$emit('force-refresh')
      })
    },
    closeWatchingWSReconnection() {
      this.$bus.$off('centrifuge-reconnect')
    },
    createCacheHandler() {
      const messagesCacheHandlersMapId = this.$cacheManager.add({
        regExpURL: Config.URLRegExps.MESSAGES(this.chatId),
        cacheHandlers: [
          (data, updatedURL, params) => {
            this.resetPinnedMessages()
            const pinnedMessage = params?.pinned_message
            const pinnedMessages = data?.filter((message) => message.pinned_status === 1)

            if (pinnedMessages && pinnedMessages.length) {
              this.pushPinnedMessages(pinnedMessages)
            }
            if (pinnedMessage) {
              this.pushPinnedMessages([pinnedMessage])
            }
            this.refreshMessages(data?.reverse() ?? [])
            this.$emit('refresh-messages', data)
          },
        ],
      })
      this.cacheHandlersMapIds.push(messagesCacheHandlersMapId)
    },
    getIsMyMessage(message) {
      return !this.isChannel && isMyMessage({ message, store: this.$store })
    },
    getMarkdownBody(message) {
      return message.body ? markDown.render(message.body) : ''
    },
    getMessageKey(message) {
      const id = (message && (message.id || message.tempId)) || null
      return `message-${id ?? getHashFromDate()}`
    },
    selectMessage(message) {
      this.setSelectedMessageId(message.id ?? message.tempId)
    },
    deselectMessage() {
      if (this.selectedMessageId) {
        this.resetSelectedMessageId()
      }
    },
  },
})
</script>

<style lang="scss" module>
.grid {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: auto;
  min-height: 100%;

  .gridContainer {
    display: flex;
    flex-direction: column;
  }

  .messageWrapper {
    display: flex;
    transition: background-color 1s ease-in-out;
  }

  .myMessageWrapper {
    justify-content: flex-end;
  }

  .waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    color: $secondary;

    .waitingDots {
      width: 20px;
    }
  }
  .waitingError {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    color: #e54d42;
  }
}
</style>
