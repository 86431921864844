type Seconds = number

export const toTimeString = (time: Seconds, isFull?: boolean): string => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time - minutes * 60)
  return isFull
    ? `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
    : `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}

export default {
  toTimeString,
}
