<template>
  <section v-if="storiesLength && isClientOwner && isProfilePage" :class="$style.section">
    <router-link
      v-for="story in stories"
      :key="story.id"
      :to="
        Trans.i18nRoute({
          name: 'Stories',
          params: {
            id: story.id,
          },
        })
      "
    >
      <v-avatar size="md-2" :src="appAvatarSrc(story.app)" :srcset="appAvatarSrcset(story.app)" />
    </router-link>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import Trans from '@plugins/i18n/translation'

export default Vue.extend({
  name: 'ProfileStories',
  components: { VAvatar },
  data() {
    return { Trans }
  },
  computed: {
    ...mapGetters('App', [
      'appId',
      'appAvatarSrc',
      'appAvatarSrcset',
      'currentApp',
      'currentAppId',
    ]),
    ...mapGetters('Client', ['isClientOwner']),
    ...mapGetters('Media', ['mediaIds']),
    ...mapGetters('Page', ['pageLoading']),
    ...mapGetters('Stories', ['stories', 'storiesLength']),
    isProfilePage() {
      return this.$route?.name === 'Profile'
    },
  },
  watch: {
    currentAppId: {
      handler() {
        this.resetStories()
        this.getStories(this.appId(this.currentApp))
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('Media', ['resetMedia']),
    ...mapMutations('Files', ['resetFiles']),
    ...mapActions('Media', ['postMedia']),
    ...mapActions('Stories', ['getStories', 'postStory']),
    ...mapMutations('Stories', ['resetStories']),
    async onCreateStory() {
      await this.postMedia()
      await this.postStory({
        app_id: this.appId(),
        media_objects: this.mediaIds,
      })
      this.resetFiles()
      this.resetMedia()
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 3px;
  padding: 6px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.add {
  width: 46px;
  height: 46px;
}
</style>
