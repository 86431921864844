// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VTextarea_textarea_2UMS-{height:100px;padding:11px;resize:none;width:100%;color:var(--supster-secondary);font-weight:400;font-size:15px;line-height:20px;word-wrap:break-word;background-color:#fff;border:1px solid #e5e3e3;border-radius:6px;outline:none;transition:border .3s}.VTextarea_textarea_2UMS-::-moz-placeholder{overflow:hidden;color:var(--supster-secondary);white-space:nowrap;text-overflow:ellipsis;opacity:.5}.VTextarea_textarea_2UMS-::placeholder{overflow:hidden;color:var(--supster-secondary);white-space:nowrap;text-overflow:ellipsis;opacity:.5}.VTextarea_textareaError_1Rsh-{border:1px solid #fb4e4e}.VTextarea_textarea_2UMS-::-webkit-scrollbar{width:5px;background:transparent}.VTextarea_textarea_2UMS-::-webkit-scrollbar-thumb{background:#181a22;border-radius:8px;cursor:pointer}.VTextarea_textarea_2UMS-::-webkit-scrollbar-thumb:hover{background:#24272f}.VTextarea_textarea_2UMS-::-webkit-scrollbar-track{background:hsla(0,0%,100%,.1)}.VTextarea_textarea_2UMS-::-webkit-scrollbar-corner{display:none}.VTextarea_textarea_2UMS-:disabled{cursor:not-allowed}", ""]);
// Exports
exports.locals = {
	"textarea": "VTextarea_textarea_2UMS-",
	"textareaError": "VTextarea_textareaError_1Rsh-"
};
module.exports = exports;
