import { Comments } from '@services/comments/types'
import { Mutations } from '../types'
import { decreaseSubCommentsCount } from '../services/comments'
import SubCommentsMutations from './sub-comments'
import ThreadsMutations from './threads'

const mutations: Mutations = {
  setComments(state, comments) {
    state.comments = comments
    state.page = 2
  },
  pushComments(state, comments: Comments) {
    state.comments = [...state.comments, ...comments]
  },
  addComment(state, comment) {
    state.comments = [comment, ...state.comments]
    state.addedCommentsCount += 1
    state.addedCommentIds.push(comment.id)
  },
  replaceComment(state, comment) {
    state.comments = state.comments.map((currentComment) =>
      currentComment.id === comment.id ? comment : currentComment
    )
  },
  increasePage(state) {
    state.page += 1
  },
  setPage(state, page) {
    state.page = page
  },
  setSourceId(state, sourceId) {
    state.sourceId = sourceId
  },
  setAddedCommentsCount(state, addedCommentsCount) {
    state.addedCommentsCount = addedCommentsCount
  },
  setAddedCommentIds(state, addedCommentIds) {
    state.addedCommentIds = addedCommentIds
  },
  setDeletedCommentsCount(state, deletedCommentsCount) {
    state.deletedCommentsCount = deletedCommentsCount
  },
  increaseDeletedCommentsCount(state) {
    state.deletedCommentsCount += 1
  },
  resetComments(state) {
    state.comments = []
    state.page = 1
    state.sourceId = null
    state.deletedCommentsCount = 0
    state.addedCommentsCount = 0
    state.addedCommentIds = []
  },
  setSelectedCommentParams(state, commentParams) {
    state.selectedCommentParams = commentParams
  },
  deleteComment(state, commentId) {
    this.removeComment(state, commentId)
  },
  removeComment(state, commentId) {
    state.comments = state.comments?.filter(({ id }) => id !== commentId) ?? null
    const { [commentId]: deletedThread, ...updatedThreadsMap } = state.threadsMap
    state.threadsMap = updatedThreadsMap
    const { [commentId]: deletedSubComment, ...updatedSubCommentsMap } = state.subCommentsMap
    state.subCommentsMap = updatedSubCommentsMap
  },
  deleteComments(state, commentIds: number[]) {
    state.comments = state.comments.filter(({ id }) => !commentIds.includes(id))
    const commentIdsMarkerAsDeleted = commentIds.filter((id) => !state.addedCommentIds.includes(id))
    const deletedCommentsCount = commentIdsMarkerAsDeleted.length
    const addedCommentIds = state.addedCommentIds.filter((id) => !commentIds.includes(id))
    const addedCommentsCount = addedCommentIds.length
    state.deletedCommentsCount += deletedCommentsCount
    state.addedCommentIds = addedCommentIds
    state.addedCommentsCount = addedCommentsCount
  },
  decreaseSubCommentsCount(state, parentId) {
    state.comments = decreaseSubCommentsCount({
      comments: state.comments,
      parentId,
    })
  },
  resetExtraCommentsCount(state) {
    state.addedCommentsCount = 0
    state.addedCommentIds = []
    state.deletedCommentsCount = 0
  },
  setIsEditComment(state, value: boolean) {
    state.isEdit = value
  },
  setIsReplyComment(state, value: boolean) {
    state.isReply = value
  },
  ...SubCommentsMutations,
  ...ThreadsMutations,
}
export default mutations
