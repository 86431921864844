/* eslint-disable camelcase */
import { Client } from '@services/client/types'

import { Post } from '../../common-types/post'

export interface BaseComment {
  client: Client
  comment_count: number
  created: DateType
  id: number
  is_liked: boolean
  like_count: number
  post: Post
  status: number
  text: string
  updated: DateType
}

export interface Comment extends BaseComment {
  parent: null
}

export interface SubComment extends BaseComment {
  parent: Comment
}

export const isSubComment = (comment: Comment | SubComment): comment is SubComment =>
  !!(comment as SubComment).parent

interface GetParamsType {
  'order[created]'?: 'desc' | 'asc'
  'not_exists[parent]'?: boolean
  post_id?: number
  parent_id?: number
}

export type GetParams = (GetParamsType & ReqParamsType) | null

export type Comments = Comment[]
export type SubComments = SubComment[]

export interface PostParams {
  parent_id?: number
  post_id: number
  text: string
}
