/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 256,
    height: 256,
    viewBox: '0 0 256 256',
    data: '<g transform="matrix(2.81 0 0 2.81 1.407 1.407)" stroke-width="0" stroke-miterlimit="10" _fill="#000"><path pid="0" d="M49.083 71.489l5.776-21.96 4.186-15.247c3.497-16.18-32.704-2.439-38.002 1.695l.425 4.853c4.824-3.395 23.091-7.744 19.449 4.275l-1.634 6.135-8.329 31.071c-3.497 16.18 32.704 2.439 38.002-1.695l-.425-4.853c-4.823 3.396-23.09 7.745-19.448-4.274z"/><circle pid="1" cx="53.871" cy="11.201" r="11.201"/></g>'
  }
})
