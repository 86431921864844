import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import i18n from '@plugins/i18n'
import { getCanvasVideoPreviewURL } from '@utils/media'
import { isFileValidate } from '@utils/files'
import { Actions } from './types'

const actions: Actions = {
  async validateFiles({ commit, rootGetters }, { files, fileTypes, isMultiple }) {
    if (isMultiple && files.length > 10) {
      Vue.notify({
        type: 'error',
        title: `${i18n.t('errors.files.caption')}`,
        text: `${i18n.t('errors.files.descriptionQuantity', [10])}`,
      })
      commit('setFilesValid', false)
      return
    }
    const isValid = files.every((file: File) => isFileValidate(file, fileTypes))
    if (isValid) {
      const filesConvertedPromises = files?.map(async (file: File) => ({
        id: uuid(),
        url:
          rootGetters['Page/isIOS'] && file.type.includes('video')
            ? await getCanvasVideoPreviewURL(file)
            : URL.createObjectURL(file),
        data: file,
      }))
      const filesConverted = await Promise.all(filesConvertedPromises)
      commit('setFiles', filesConverted)
    } else {
      Vue.notify({
        type: 'error',
        title: `${i18n.t('errors.files.caption')}`,
        text: `${i18n.t('errors.files.description', ['5Gb'])}`,
      })
    }
    commit('setFilesValid', isValid)
  },
  setFileResolution({ commit }, { file, mediaId }) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (fileEvent) => {
      const image = new Image()
      image.src = fileEvent?.target?.result?.toString() ?? ''
      image.onload = ({ target }) => {
        if (typeof target === typeof HTMLImageElement.prototype) {
          const { width, height } = <typeof HTMLImageElement.prototype>target ?? {}
          commit('setFileResolution', {
            mediaId,
            resolution: { width, height },
          })
        }
      }
    }
  },
}

export default actions
