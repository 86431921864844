import { Mutations } from './types'

const mutations: Mutations = {
  setMedia(state, media) {
    state.media = media
  },
  resetMedia(state) {
    state.media = null
  },
  pushMediaItem(state, mediaItem) {
    state.media = [...(state.media ?? []), mediaItem]
  },
}

export default mutations
