import Vue from 'vue'
import VueLazyload, { VueReactiveListener } from 'vue-lazyload'

interface Loaded extends VueReactiveListener {
  el: HTMLImageElement | HTMLVideoElement
}

// or with options
Vue.use(VueLazyload, {
  preLoad: 5,
  loading: 'img/image-loader.gif',
  adapter: {
    loaded({ el, naturalHeight, naturalWidth }: Loaded) {
      if (el) {
        el.width = naturalWidth
        el.height = naturalHeight
      }
    },
  },
})
