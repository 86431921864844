import { Module } from '../types'
import getters from './getters'
import mutations from './mutations'

const module: Module = {
  state: () => ({
    visiblePosts: [],
    startIndex: 0,
    componentsHeights: {},
  }),
  getters,
  mutations,
  namespaced: true,
}

export default module
