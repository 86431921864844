import Vue from 'vue'
import { mapGetters } from 'vuex'
import { isCreated, isRemoved, isRead, isConverted } from '@providers/helpers'

export default Vue.extend({
  name: 'ChatObserverCache',

  inject: {
    centrifugeProvider: {
      default: () => ({
        message: null,
      }),
    },
  },

  render() {
    return this.$scopedSlots.default()
  },

  computed: {
    ...mapGetters('Chat', ['chatId']),
  },

  watch: {
    'centrifugeProvider.message': {
      handler(message) {
        if (message && message.data?.channel?.id !== this.chatId) {
          return
        }
        const { eventType, data } = message
        if (isCreated(eventType)) {
          this.$emit('new-message', data)
        }
        if (isRemoved(eventType)) {
          this.$emit('remove-message', data)
        }
        if (isRead(eventType)) {
          this.$emit('read-message', data)
        }
      },
      deep: true,
      immediate: true,
    },
    'centrifugeProvider.mediaObject': {
      handler(mediaObject) {
        if (isConverted(mediaObject?.eventType)) {
          this.$emit('new-media-object', mediaObject?.data)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    onLoadMessages() {
      this.$emit('load-messages')
    },
  },
})
