// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatMessageMenu_menu_WVVSY{display:flex;flex-direction:column;min-width:150px;border-radius:6px 0 6px 6px}.ChatMessageMenu_menu_WVVSY .ChatMessageMenu_item_v5FxV{display:flex;gap:20px;align-items:center;justify-content:space-between;width:100%;padding:14px 15px;border-bottom:1px solid #000;cursor:pointer;transition:background-color .3s ease-in-out}.ChatMessageMenu_menu_WVVSY .ChatMessageMenu_item_v5FxV:hover{background-color:#f8f8f8}.ChatMessageMenu_menu_WVVSY .ChatMessageMenu_item_v5FxV:last-child{border-bottom:0}.ChatMessageMenu_menu_WVVSY .ChatMessageMenu_itemLabel_R379Z{font-weight:400;font-size:15px;line-height:14px}.ChatMessageMenu_menu_WVVSY .ChatMessageMenu_itemIcon_1weNS .ChatMessageMenu_icon_w2rUz{display:block}", ""]);
// Exports
exports.locals = {
	"menu": "ChatMessageMenu_menu_WVVSY",
	"item": "ChatMessageMenu_item_v5FxV",
	"itemLabel": "ChatMessageMenu_itemLabel_R379Z",
	"itemIcon": "ChatMessageMenu_itemIcon_1weNS",
	"icon": "ChatMessageMenu_icon_w2rUz"
};
module.exports = exports;
