// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VReadMore_section_24Zu-{width:100%}.VReadMore_wrap_J3pAP{display:grid;grid-template-rows:repeat(2,auto);row-gap:16px}.VReadMore_btns-wrap_HrekT{display:grid;grid-column-gap:21px;grid-template-columns:1fr auto;align-items:center}.VReadMore_content_2bJnZ{height:100%;max-height:33px;overflow:hidden;color:#000;white-space:pre-line;text-align:left;overflow-wrap:anywhere;transition:max-height .2s ease}.VReadMore_content_2bJnZ.VReadMore_edit_ozQyO{color:#a09f9f}.VReadMore_contentOpen_1EiJn{max-height:100vh;max-height:calc(var(--vh, 1vh)*100);transition:max-height .5s ease}.VReadMore_content_2bJnZ.VReadMore_sm_3Vi2s{font-weight:400;font-size:10px;line-height:14px}.VReadMore_content_2bJnZ.VReadMore_md_19uHM{font-size:12px}.VReadMore_content_2bJnZ.VReadMore_lg_2Lf6z,.VReadMore_content_2bJnZ.VReadMore_md_19uHM{font-weight:400;line-height:18px}.VReadMore_content_2bJnZ.VReadMore_lg_2Lf6z{font-size:14px}.VReadMore_content_2bJnZ.VReadMore_xl_3BDhR{font-weight:400;font-size:16px;line-height:20px}.VReadMore_button_2A4f7{justify-content:flex-start;-moz-column-gap:9px;column-gap:9px;border-radius:0!important}.VReadMore_button_2A4f7:hover{transform:scale(1);opacity:.7}.VReadMore_name_3LQ1L{display:grid;color:var(--supster-secondary);font-weight:400;white-space:normal;overflow-wrap:anywhere;-moz-column-gap:9px;column-gap:9px}.VReadMore_name_3LQ1L.VReadMore_sm_3Vi2s{font-size:10px;line-height:14px}.VReadMore_name_3LQ1L.VReadMore_md_19uHM{font-size:12px;line-height:18px}.VReadMore_name_3LQ1L.VReadMore_lg_2Lf6z{font-size:14px;line-height:18px}.VReadMore_name_3LQ1L.VReadMore_xl_3BDhR{font-size:16px;line-height:20px}.VReadMore_icon_3VP91{align-self:center;justify-self:flex-start;width:7px;height:4px;transform:rotate(0);transition:transform .5s;fill:var(--supster-secondary)}.VReadMore_iconTransform_3D0dD{transform:rotate(-180deg)}", ""]);
// Exports
exports.locals = {
	"section": "VReadMore_section_24Zu-",
	"wrap": "VReadMore_wrap_J3pAP",
	"btns-wrap": "VReadMore_btns-wrap_HrekT",
	"content": "VReadMore_content_2bJnZ",
	"edit": "VReadMore_edit_ozQyO",
	"contentOpen": "VReadMore_contentOpen_1EiJn",
	"sm": "VReadMore_sm_3Vi2s",
	"md": "VReadMore_md_19uHM",
	"lg": "VReadMore_lg_2Lf6z",
	"xl": "VReadMore_xl_3BDhR",
	"button": "VReadMore_button_2A4f7",
	"name": "VReadMore_name_3LQ1L",
	"icon": "VReadMore_icon_3VP91",
	"iconTransform": "VReadMore_iconTransform_3D0dD"
};
module.exports = exports;
