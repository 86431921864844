/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'hashtag': {
    width: 14,
    height: 18,
    viewBox: '0 0 14 18',
    data: '<path pid="0" d="M8.102 13.195H5.477L4.586 18H2.617l.89-4.805H.802v-1.851h3.058l.68-3.668H1.691V5.812h3.2L5.805.938h1.957l-.914 4.875h2.636l.914-4.875h1.957l-.914 4.875h2.438v1.864h-2.79l-.679 3.668H13v1.851h-2.941L9.168 18H7.211l.89-4.805zm-2.274-1.851h2.625l.68-3.668H6.508l-.68 3.668z"/>'
  }
})
