/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit-outline': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M17.854 4.854L13.147.146a.5.5 0 00-.707 0L1.978 10.608a.493.493 0 00-.116.198c-.003.008-.01.013-.012.021L.019 17.365a.5.5 0 00.616.616l6.539-1.83c.007-.003.012-.01.02-.012a.489.489 0 00.199-.116l10.46-10.461a.5.5 0 000-.708zM2.582 11.92l1.75 1.749 1.749 1.749-4.86 1.361 1.361-4.86zm4.457 3.042l-2-2-2-2 9.754-9.755 4 4.001-9.754 9.754z"/>'
  }
})
