/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface RulesTypes {
  [key: string]: ((value: string) => false | string)[]
}

// https://stackoverflow.com/questions/18884249/checking-whether-something-is-iterable
function isIterable(obj: any) {
  return Symbol.iterator in Object(obj)
}

export default async function validate(rules: any, value: string | boolean) {
  const arr = isIterable(rules) ? rules : [rules]
  for (const rule of arr) {
    let error = rule(value)

    if (error.then) {
      // eslint-disable-next-line no-await-in-loop
      error = await error
    }

    if (error) {
      return error
    }
  }
  return ''
}
