// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VVideo_section_1LMqj{background:#000}.VVideo_content_2GW-p,.VVideo_section_1LMqj{position:relative;width:100%;height:100%}.VVideo_content_2GW-p{display:block;cursor:pointer}.VVideo_video_FmKP3{display:block;width:100%}.VVideo_image_30STe,.VVideo_video_FmKP3{height:100%}.VVideo_processing_d-BdA{display:grid;grid-auto-rows:1fr auto;width:100%;height:100%;background-color:var(--supster-primary);row-gap:5px}.VVideo_processing_d-BdA.VVideo_sm_2VEd9{padding:7px}.VVideo_processing_d-BdA.VVideo_lg_36j-S{padding:15px}.VVideo_processingIcon_39gAd{align-self:center;justify-self:center;width:45px;height:45px;color:var(--supster-secondary)}.VVideo_processingText_3AZ3o{color:var(--supster-secondary);font-weight:700;text-align:center;border-radius:6px}.VVideo_processingText_3AZ3o.VVideo_sm_2VEd9{font-size:12px;line-height:12px}.VVideo_processingText_3AZ3o.VVideo_lg_36j-S{font-size:17px;line-height:17px}.VVideo_button_4eI7N{position:absolute!important;left:3px;width:auto!important}.VVideo_buttonText_FmqIa{align-self:center;color:currentColor;font-weight:400;font-size:12px}.VVideo_button_4eI7N.VVideo_left_rWN_p{border-radius:9px 9px 9px 3px!important}.VVideo_button_4eI7N.VVideo_right_2JL8Q{right:3px;left:auto;border-radius:9px 9px 3px 9px!important}.VVideo_button_4eI7N.VVideo_bottom_2oLUi{bottom:3px}.VVideo_button_4eI7N.VVideo_top_1ckdo{top:3px}.VVideo_button_4eI7N.VVideo_slider_2JVBX{left:40px}.VVideo_button_4eI7N.VVideo_play_28aFy{top:50%;left:50%}.VVideo_button_4eI7N.VVideo_play_28aFy,.VVideo_button_4eI7N.VVideo_play_28aFy:hover{transform:translate(-50%,-50%)}.VVideo_loader_1lGEZ{z-index:103!important;background-color:hsla(0,0%,100%,.3)!important}.VVideo_icon_1nIrP{display:block;fill:currentColor}", ""]);
// Exports
exports.locals = {
	"section": "VVideo_section_1LMqj",
	"content": "VVideo_content_2GW-p",
	"video": "VVideo_video_FmKP3",
	"image": "VVideo_image_30STe",
	"processing": "VVideo_processing_d-BdA",
	"sm": "VVideo_sm_2VEd9",
	"lg": "VVideo_lg_36j-S",
	"processingIcon": "VVideo_processingIcon_39gAd",
	"processingText": "VVideo_processingText_3AZ3o",
	"button": "VVideo_button_4eI7N",
	"buttonText": "VVideo_buttonText_FmqIa",
	"left": "VVideo_left_rWN_p",
	"right": "VVideo_right_2JL8Q",
	"bottom": "VVideo_bottom_2oLUi",
	"top": "VVideo_top_1ckdo",
	"slider": "VVideo_slider_2JVBX",
	"play": "VVideo_play_28aFy",
	"loader": "VVideo_loader_1lGEZ",
	"icon": "VVideo_icon_1nIrP"
};
module.exports = exports;
