// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VInput_container_vTlu7{position:relative;display:block;width:100%}.VInput_label_I3T6S{display:grid;row-gap:6px}.VInput_title_1I1XG{width:100%;padding-left:3px;overflow:hidden;font-size:12px;line-height:12px;white-space:nowrap;text-overflow:ellipsis}.VInput_error_2GCMp,.VInput_title_1I1XG{font-weight:400}.VInput_error_2GCMp{position:absolute;left:3px;margin-top:3.5px;color:#fb4e4e;font-size:10px;line-height:1}.VInput_error_2GCMp.VInput_auth_3Gzil{position:relative;left:15px;margin:6px 0}.VInput_disabled_2x71v{opacity:.4}", ""]);
// Exports
exports.locals = {
	"container": "VInput_container_vTlu7",
	"label": "VInput_label_I3T6S",
	"title": "VInput_title_1I1XG",
	"error": "VInput_error_2GCMp",
	"auth": "VInput_auth_3Gzil",
	"disabled": "VInput_disabled_2x71v"
};
module.exports = exports;
