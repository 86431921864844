import { Module } from '../types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { Tab } from '../constants'
import Images from '../modules/profile-images/store'
import Videos from '../modules/profile-videos/store'

const module: Module = {
  state: () => ({
    app: null,
    clientApp: null,
    [Tab.IMAGES]: [],
    [Tab.VIDEOS]: [],
    [Tab.PRODUCTS]: [],
    [Tab.CHATS]: [],
    pages: {
      [Tab.IMAGES]: 1,
      [Tab.VIDEOS]: 1,
      [Tab.PRODUCTS]: 1,
      [Tab.CHATS]: 1,
    },
    tariffs: null,
    appSupportChats: {},
    isClientEditingError: true,
  }),
  modules: {
    Images,
    Videos,
  },
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default module
