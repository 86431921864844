<template>
  <v-drawer name="comment-edit" :title="$t('editYourComment')" @close="onClose">
    <transition-group :class="$style.drawer" tag="div" name="scroll-group" appear>
      <text-input
        key="input"
        v-model="commentText"
        :placeholder="$t('commentText')"
        :class="$style.input"
        radius="0"
        padding="15px"
        :rules="rules"
        shadow
        :autofocus="true"
        @error="error = $event"
      />
      <div key="confirmButton" :class="$style.buttonWrapper">
        <v-button
          color="primary"
          :class="$style.button"
          size="xl"
          :is-loading="pageLoading"
          :is-disabled="!commentText || error"
          @click="updateComment"
          >{{ $t('saveChanges') }}</v-button
        >
      </div>
    </transition-group>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VButton from '@elements/VButton.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import { vLength, vRequired } from '@utils/validations'

export default Vue.extend({
  name: 'CommentEditDrawer',
  components: { VDrawer, VButton, TextInput },
  data() {
    return {
      commentText: '',
      error: false,
    }
  },
  computed: {
    ...mapGetters('Page', ['pageLoading']),
    ...mapGetters('Drawers', ['drawer', 'drawerParams']),
    ...mapGetters('Comments', ['selectedCommentParams', 'selectedComment']),
    rules() {
      return [vRequired(), vLength(2, 1000)]
    },
    isSub() {
      return !!this.selectedComment?.parent
    },
  },
  watch: {
    selectedCommentParams: {
      handler() {
        this.reset()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('Comments', ['putComment']),
    ...mapMutations('Comments', ['setSelectedCommentParams', 'setIsEditComment']),
    ...mapMutations('Drawers', ['setDrawer']),
    onClose() {
      this.reset()
    },
    reset() {
      this.setIsEditComment(false)
      this.commentText = this.selectedComment?.text ?? ''
      this.error = false
    },
    async updateComment() {
      await this.putComment({
        id: this.selectedComment?.id,
        text: this.commentText,
        isSub: this.isSub,
      })

      this.setDrawer({ name: 'comment-edit', isVisible: false })
      this.setSelectedCommentParams(null)
      this.reset()
    },
  },
})
</script>

<style lang="scss" module>
.drawer {
  & > * {
    padding: 0 15px 15px 15px;
  }
}

.input {
  margin: 0;
  padding: 0;
}

.buttonWrapper {
  padding: 15px;
}
</style>
