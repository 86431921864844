<template>
  <transition name="fade">
    <div :class="[$style.notification, isReply && $style.reply]">
      <div :class="$style.container">
        <VAvatar :class="$style.avatar" :src="notificationIcon" />
        <div :class="$style.content">
          <div :class="[$style.text, $style.title]">
            {{ notificationTitle }}
          </div>
          <div :class="[$style.text, $style.body]">
            {{ notificationBody }}
          </div>
        </div>
      </div>
      <LoadingLoader v-if="isLoading" :is-loading="isLoading" :size="7" :delay="0" />
      <MessageInput v-else-if="isReply" :focus="isReply" @click.native.stop @send="sendMessage" />
      <div :class="$style.actionBar" />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import MessageInput from '@elements/inputs/list/MessageInput.vue'
import { isMyChannel } from '@modules/chat/services/chat'
import LoadingLoader from '@loaders/list/LoadingLoader.vue'
import ChatApi from '@modules/chat/api/chat'
import { MessageMode } from '@common-types/chat/chat-message'

export default {
  name: 'Notification',
  props: {
    isReply: Boolean,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  components: { LoadingLoader, MessageInput, VAvatar },
  computed: {
    ...mapGetters('Notifications', [
      'notificationBody',
      'notificationTitle',
      'notificationIcon',
      'message',
    ]),
    ...mapGetters('Chat', ['localId']),
    ...mapGetters('Client', ['isClientOwner']),
  },
  methods: {
    async sendMessage(text) {
      this.isLoading = true
      const imOwner = this.isClientOwner && isMyChannel(this.message.channel)
      const mode = imOwner ? MessageMode.APP : MessageMode.CLIENT
      const mode_app_id = imOwner ? this.appId() : null
      await ChatApi.postChatMessage({
        local_id: this.localId,
        channel_id: this.message?.channel?.id,
        body: text,
        mode,
        mode_app_id,
      })
      this.isLoading = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" module>
.notification {
  padding: 15px 15px 6px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  background: white;
  border-radius: 10px;
  border: 1px solid $secondary-light;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  &.reply {
    padding-bottom: 15px;
    background: white;
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 9px;
    overflow: hidden;

    .avatar {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 3px;
      overflow: hidden;

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .title {
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
      }

      .body {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }

  .actionBar {
    width: 42px;
    height: 4px;
    border-radius: 2px;
    background: $secondary-light;
  }
}
</style>
