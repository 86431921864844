import { Module } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const module: Module = {
  state: () => ({
    blocks: null,
    appBlockIds: null,
    blockAppsNames: null,
  }),
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default module
