// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextInput_input_oeLzR{position:relative;padding:11px;width:100%;color:var(--supster-secondary);font-weight:400;font-size:15px;line-height:20px;word-wrap:break-word;background-color:#fff;border:1px solid #e5e3e3;border-radius:6px;outline:none;transition:border .3s}.TextInput_input_oeLzR::-moz-placeholder{overflow:hidden;color:var(--supster-secondary);white-space:nowrap;text-overflow:ellipsis;opacity:.5}.TextInput_input_oeLzR::placeholder{overflow:hidden;color:var(--supster-secondary);white-space:nowrap;text-overflow:ellipsis;opacity:.5}.TextInput_inputError_1_XRF{border:1px solid #fb4e4e}.TextInput_input_oeLzR:disabled{cursor:not-allowed}.TextInput_input_oeLzR.TextInput_error_5Cl32{border:1px solid #fb4e4e}.TextInput_input_oeLzR.TextInput_search_1y06V{padding:11px 11px 11px 31px}.TextInput_input_oeLzR.TextInput_shadow_3L5j5{background:#f8f8f8}.TextInput_icon_fRjda{position:absolute;top:50%;left:11px;width:14px;height:14px;transform:translateY(-50%)}", ""]);
// Exports
exports.locals = {
	"input": "TextInput_input_oeLzR",
	"inputError": "TextInput_inputError_1_XRF",
	"error": "TextInput_error_5Cl32",
	"search": "TextInput_search_1y06V",
	"shadow": "TextInput_shadow_3L5j5",
	"icon": "TextInput_icon_fRjda"
};
module.exports = exports;
