import { axiosJWT } from '@plugins/axios'
import { AppBlock } from './types'

export default {
  postAppBlock: async (app_id: string): Promise<ResType<AppBlock>> => {
    return axiosJWT.post('client_app_blocks', { app_id })
  },
  deleteAppBlock: async (id: number): Promise<ResType<AppBlock>> => {
    return axiosJWT.delete(`client_app_blocks/${id}`)
  },
  getAppBlock: async (id: string): Promise<AppBlock> => {
    return axiosJWT.get(`client_app_blocks/${id}`)
  },
  getAppBlocks: async (): Promise<ResType<AppBlock[]>> => {
    return axiosJWT.get('client_app_blocks')
  },
}
