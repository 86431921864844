// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VDropdown_dropdown_vM0C-{position:relative;z-index:1;width:100%;-webkit-user-select:none;-moz-user-select:none;user-select:none}.VDropdown_button_3MQ3H{justify-content:space-between;overflow:visible;text-align:left;border-radius:0}.VDropdown_buttonNoArrow_1tGEK{display:block}.VDropdown_button_3MQ3H:hover{transform:scale(1) translate(0)!important;opacity:.7}.VDropdown_icon_rMyqu{align-self:center;justify-self:flex-start;transform:rotate(0);transition:transform .5s;fill:var(--supster-main-text-color)}.VDropdown_iconTransform_1kdVn{transform:rotate(-90deg)}.VDropdown_icon_rMyqu.VDropdown_secondary_2lP4l{fill:#fff}.VDropdown_icon_rMyqu.VDropdown_transparent-light_1vG2u{fill:var(--supster-secondary)}.VDropdown_icon_rMyqu.VDropdown_transparent-light-background_2nXZL{fill:#000}.VDropdown_name_21rpE{align-self:center;justify-self:flex-start;width:100%;font-size:15px;line-height:20px;text-align:left}", ""]);
// Exports
exports.locals = {
	"dropdown": "VDropdown_dropdown_vM0C-",
	"button": "VDropdown_button_3MQ3H",
	"buttonNoArrow": "VDropdown_buttonNoArrow_1tGEK",
	"icon": "VDropdown_icon_rMyqu",
	"iconTransform": "VDropdown_iconTransform_1kdVn",
	"secondary": "VDropdown_secondary_2lP4l",
	"transparent-light": "VDropdown_transparent-light_1vG2u",
	"transparent-light-background": "VDropdown_transparent-light-background_2nXZL",
	"name": "VDropdown_name_21rpE"
};
module.exports = exports;
