import OrdersApi from '@services/order'
import { Actions, TabType } from './types'

const actions: Actions = {
  async getOrders(
    { state, dispatch, commit, rootGetters },
    { scroll, tab }: { scroll: InfiniteType; tab: TabType }
  ) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const orders = await OrdersApi.getOrders({
        delivery: tab === 'all' ? null : tab === 'completed' ? 3 : [2, 1, 0],
        page: state.orders[tab].page,
        limit: 30,
        'order[created]': 'desc',
      })
      commit('addOrders', { orders, tab })
      if (scroll) {
        orders.length ? scroll.loaded() : scroll.complete()
      }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
