// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Notifications_notifications_1-uRQ{position:absolute;top:0;z-index:3;width:100%;min-height:80px;padding:42px 6px 0 6px;overflow:visible;color:var(--supster-secondary);transform:translateY(-100%);transition:background-color .3s ease,transform .3s ease,-webkit-backdrop-filter .3s ease;transition:background-color .3s ease,backdrop-filter .3s ease,transform .3s ease;transition:background-color .3s ease,backdrop-filter .3s ease,transform .3s ease,-webkit-backdrop-filter .3s ease}.Notifications_notifications_1-uRQ.Notifications_open_3rG4U{transform:translateY(0)}.Notifications_notifications_1-uRQ.Notifications_high_1s1GE{height:100%;background:rgba(0,0,0,.6);-webkit-backdrop-filter:blur(1.5px);backdrop-filter:blur(1.5px)}", ""]);
// Exports
exports.locals = {
	"notifications": "Notifications_notifications_1-uRQ",
	"open": "Notifications_open_3rG4U",
	"high": "Notifications_high_1s1GE"
};
module.exports = exports;
