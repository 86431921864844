// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CodeForm_form_Z1fgV{grid-auto-rows:min-content;grid-auto-flow:row}.CodeForm_inner_2bKky{display:grid;row-gap:9px}.CodeForm_input_3PpD9{margin:0;padding:0}.CodeForm_wrapper_3ib3y{display:grid;grid-template-columns:repeat(2,1fr);-moz-column-gap:20px;column-gap:20px}.CodeForm_actions_3DmTF{display:flex;justify-content:flex-end;padding:15px}.CodeForm_actions_3DmTF *{color:#737373!important}.CodeForm_actions_3DmTF .CodeForm_action_3t1Mz{display:flex;flex-direction:column;align-items:flex-end;justify-content:space-between}.CodeForm_actions_3DmTF .CodeForm_action_3t1Mz button{padding-right:0;padding-left:0}.CodeForm_actions_3DmTF .CodeForm_changePhone_3W8QB{align-items:flex-start}.CodeForm_actions_3DmTF .CodeForm_changePhone_3W8QB button{justify-content:start}.CodeForm_buttonContainer_DNbzB{padding:15px}.CodeForm_button_3nA0M{display:grid;grid-auto-flow:column;justify-content:center;-moz-column-gap:9px;column-gap:9px}.CodeForm_caption_3qg4H{padding:15px;color:#737373;font-size:12px;line-height:18px;text-align:left;border-top:1px solid #e5e3e3}.CodeForm_title_3wAc0{font-weight:500;line-height:12px;text-transform:uppercase}.CodeForm_text_1-tPI,.CodeForm_title_3wAc0{font-size:12px}.CodeForm_text_1-tPI{font-weight:400;line-height:18px}", ""]);
// Exports
exports.locals = {
	"form": "CodeForm_form_Z1fgV",
	"inner": "CodeForm_inner_2bKky",
	"input": "CodeForm_input_3PpD9",
	"wrapper": "CodeForm_wrapper_3ib3y",
	"actions": "CodeForm_actions_3DmTF",
	"action": "CodeForm_action_3t1Mz",
	"changePhone": "CodeForm_changePhone_3W8QB",
	"buttonContainer": "CodeForm_buttonContainer_DNbzB",
	"button": "CodeForm_button_3nA0M",
	"caption": "CodeForm_caption_3qg4H",
	"title": "CodeForm_title_3wAc0",
	"text": "CodeForm_text_1-tPI"
};
module.exports = exports;
