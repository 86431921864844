// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatPinnedMessages_content_3qNPi{grid-template-columns:auto 1fr auto;padding-left:9px;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"content": "ChatPinnedMessages_content_3qNPi"
};
module.exports = exports;
