import { getToken } from 'firebase/messaging'
import { getMessagingApp, vapidKey } from '@plugins/firebase'
import PushApi from '@services/push_token'
import { Actions } from '../types'

const actions: Actions = {
  async postNotificationsToken({ commit, dispatch }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const messaging = await getMessagingApp()
      if (messaging) {
        const token = await getToken(messaging, { vapidKey })
        if (token) {
          commit('setNotificationToken', token)
          await PushApi.postNotificationsToken(token)
        }
      }
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
