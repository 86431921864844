<template>
  <v-drawer name="noname" :title="$t('enter.name')" @close="onClose">
    <transition-group :class="$style.drawer" tag="div" name="scroll-group" appear>
      <div key="description" :class="[$style.text, $style.description]">
        {{ $t('toLeaveCommentNeedEnterName') }}
      </div>
      <text-input
        ref="inputName"
        key="input"
        v-model="name"
        :placeholder="$t('yourName')"
        :class="$style.input"
        radius="0"
        padding="15px"
        :rules="rules"
        shadow
        :autofocus="true"
        @error="error = $event"
      />
      <i18n key="agreement" :class="[$style.text, $style.agreement]" path="agreement" tag="p">
        <template #privacyPolicy>
          <router-link
            :to="
              Trans.i18nRoute({
                name: 'Documents',
                params: {
                  slug: privacyDocId,
                },
              })
            "
            :class="[$style.link]"
            >{{ $t('withPrivacyPolicy') }}</router-link
          >
        </template>
        <template #rulesPlatform>
          <router-link
            :to="
              Trans.i18nRoute({
                name: 'Documents',
                params: {
                  slug: userAgreementDocId,
                },
              })
            "
            :class="[$style.link]"
            >{{ $t('withRulesPlatform') }}</router-link
          >
        </template>
      </i18n>
      <div key="confirmButton" :class="$style.buttonWrapper">
        <v-button
          color="primary"
          :class="$style.button"
          size="xl"
          :is-loading="pageLoading"
          :is-disabled="!name || error"
          @click="onConfirm"
          >{{ $t('confirm.confirm') }}</v-button
        >
      </div>
    </transition-group>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VButton from '@elements/VButton.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import { vLength, vName, vRequired } from '@utils/validations'
import Trans from '@plugins/i18n/translation'

export default Vue.extend({
  name: 'NoNameDrawer',
  components: { VDrawer, VButton, TextInput },
  data() {
    return {
      name: '',
      error: false,
      Trans,
    }
  },
  computed: {
    ...mapGetters('Client', ['clientId']),
    ...mapGetters('Page', ['pageLoading']),
    ...mapGetters('Drawers', ['drawer', 'drawerParams']),
    ...mapGetters('Countries', ['privacyDocId', 'userAgreementDocId']),
    rules() {
      return [vRequired(), vName(), vLength(2, 30)]
    },
  },
  methods: {
    ...mapActions('Client', ['putClient']),
    ...mapMutations('Drawers', ['setDrawer']),
    onClose() {
      this.name = ''
    },
    async onConfirm() {
      const client = await this.putClient({
        id: this.clientId,
        client: { name: this.name },
      })
      if (client.id) {
        this.$bus.$emit('noname-confirm', this.drawerParams)
        this.setDrawer({ name: 'noname', isVisible: false })
        this.name = ''
      }
    },
  },
})
</script>

<style lang="scss" module>
.drawer {
  & > * {
    padding: 0 15px 15px 15px;
  }
}

.input {
  margin: 0;
  padding: 0;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.agreement {
  padding: 15px 45px;
  text-align: center;
  box-shadow: inset 0 -1px 0 #e5e3e3;
}

.link {
  color: black;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.buttonWrapper {
  padding: 15px;
}
</style>
