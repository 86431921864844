// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatDownScroller_element_1_lgN{position:absolute;right:20px;bottom:10px;width:35px;height:35px}.ChatDownScroller_content_3tWu1{position:relative;display:flex;align-items:center;justify-content:center;width:100%;height:100%;color:#fff;background:#000;border-radius:50%;cursor:pointer;transition:opacity .3s}.ChatDownScroller_content_3tWu1:hover{opacity:.8}.ChatDownScroller_count_vGvb5{position:absolute;top:-9px;left:50%;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:2px 6px;color:#fff;font-weight:500;font-size:12px;line-height:12px;background-color:var(--supster-secondary);border-radius:9px;transform:translateX(-50%)}", ""]);
// Exports
exports.locals = {
	"element": "ChatDownScroller_element_1_lgN",
	"content": "ChatDownScroller_content_3tWu1",
	"count": "ChatDownScroller_count_vGvb5"
};
module.exports = exports;
