import { Module } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const module: Module = {
  state: () => ({
    clients: [],
  }),
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default module
