import { Module } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const module: Module = {
  state: () => ({
    promotionalList: [],
    promotional: null,
    isDenied: false,
    redirectUrl: null,
    page: 1,
    modal: {
      posts: {
        data: null,
        page: 1,
      },
      channels: {
        data: null,
        page: 1,
      },
      visible: false,
      type: 'posts',
    },
  }),
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default module
