import Centrifuge from 'centrifuge'
import md5 from 'md5'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { ConvertStatuses } from '@services/media/types'
import {
  fromCurrentDevice,
  isFirstMessageInTetATetChat,
  isMyMessage,
} from '@modules/messages/services'
import { hasUnreadMessages } from '@modules/chat/helpers/chat'
import {
  isChannel,
  isMessage,
  isStory,
  isMediaObject,
  isToken,
  isCreated,
  isRead,
  isRemoved,
  isUpdated,
} from './helpers'

export default Vue.extend({
  name: 'CentrifugeProvider',
  provide() {
    return {
      centrifugeProvider: this,
    }
  },
  render() {
    return this.$scopedSlots.default()
  },
  data() {
    return {
      centrifuge: Centrifuge,
      message: {
        eventType: null,
        data: null,
      },
    }
  },
  computed: {
    ...mapGetters('Me', ['accessToken']),
    ...mapGetters('Client', ['clientId']),
    ...mapGetters('Chat', ['localId', 'referencedMessageId', 'chatId']),
    md5ClientId() {
      return this.clientId ? md5(`${this.clientId}`) : null
    },
  },
  watch: {
    accessToken(token) {
      this.onDisconnect()
      if (token) {
        this.onConnect()
      }
    },
  },
  created() {
    this.onConnect()
    this.startWatchingWindowFocus()
  },
  destroyed() {
    this.onDisconnect()
    this.stopWatchingWindowFocus()
  },
  methods: {
    ...mapMutations('Me', ['setAccessToken']),
    ...mapMutations('Chats', [
      'setChatsUnreadCount',
      'decreaseChatsUnreadCount',
      'deleteChatFromChats',
      'replaceChatInChats',
      'updateChatsItem',
      'incrementChatsUnreadCount',
    ]),
    ...mapActions('Chats', ['swapChat']),
    ...mapMutations('Chat', [
      'deleteMessage',
      'replaceMessage',
      'replaceMessages',
      'pushMessage',
      'replaceMessageMediaObject',
      'resetReferencedMessageId',
      'setIsReply',
      'setIsEdit',
      'updateChat',
      'incrementChatNewMessageCount',
    ]),
    ...mapMutations('Profile', [
      'deleteProfileChat',
      'replaceProfileChat',
      'replaceProfilePostsMediaObject',
    ]),
    ...mapMutations('Search', ['deleteSearchChat', 'replaceSearchChat']),
    ...mapMutations('Stories', ['unshiftStory']),
    ...mapMutations('Feed', ['replaceFeedMediaObject']),
    ...mapMutations('Post', ['replacePostMediaObject']),
    onConnect() {
      this.centrifuge = this.getCentrifugeInstance()
      this.centrifuge.on('connect', (ctx) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('connected', ctx)
        }
      })
      this.centrifuge.on('disconnect', this.onDisconnect)
      this.centrifuge.subscribe(this.md5ClientId, this.onMessage)
      this.centrifuge.connect()
    },
    reconnect() {
      this.onDisconnect()
      this.onConnect()
      this.$bus.$emit('centrifuge-reconnect')
    },
    startWatchingWindowFocus() {
      window.addEventListener('focus', this.reconnect)
    },
    stopWatchingWindowFocus() {
      window.removeEventListener('focus', this.reconnect)
    },
    onMessage(event) {
      const { data } = event
      const {
        new_message_channels_count: unreadChatsCount,
        event_type: eventType,
        entity_type: entityType,
        entities,
      } = data
      const [entity] = entities

      if (isMessage(entityType)) {
        const isThisDevice = fromCurrentDevice({ message: entity, store: this.$store })
        if (isRead(eventType)) {
          if (this.chatId) {
            this.replaceMessages(entities)
          }
        }
        if (isCreated(eventType)) {
          if (isThisDevice) {
            return
          }
          const chatFields = {
            id: entity.channel.id,
            last_event_time: entity.channel.last_event_time,
          }
          this.updateChatsItem(chatFields)
          let swapedChat = entity.channel
          if (!isMyMessage({ message: entity, store: this.$store })) {
            this.incrementChatsUnreadCount(entity.channel.id)
            swapedChat = { ...entity.channel, channel_subscription: { new_message_count: 1 } }
          }
          this.swapChat(swapedChat)
          const fromCurrentChat = entity?.channel?.id === this.chatId
          if (fromCurrentChat && !isFirstMessageInTetATetChat(entity)) {
            this.updateChat(chatFields)
            if (!isMyMessage({ message: entity, store: this.$store })) {
              this.incrementChatNewMessageCount()
            }
            this.pushMessage(entity)
          }
        }
        if (isUpdated(eventType)) {
          if (isThisDevice) {
            return
          }
          if (this.chatId) {
            this.replaceMessage(entity)
          }
        }
        if (isRemoved(eventType)) {
          if (isThisDevice) {
            return
          }
          this.deleteMessage(entity?.id)
          this.replaceChatInChats(entity?.channel)
          if (this.referencedMessageId === entity?.id) {
            this.resetReferencedMessageId()
            this.setIsReply(false)
            this.setIsEdit(false)
          }
        }

        this.message = {
          eventType,
          data: entity,
        }
      }

      if (isMediaObject(entityType)) {
        if (entity?.convert_status === ConvertStatuses.CONVERTED) {
          this.replaceMessageMediaObject(entity)
          this.replaceFeedMediaObject(entity)
          this.replacePostMediaObject(entity)
          this.replaceProfilePostsMediaObject(entity)
        }
      }

      if (unreadChatsCount != null) {
        this.setChatsUnreadCount(unreadChatsCount)
      }

      if (isToken(entityType) && isUpdated(eventType)) {
        this.setAccessToken(entity)
      }

      if (isStory(entityType) && isCreated(eventType)) {
        this.unshiftStory(entity)
      }

      if (isChannel(entityType)) {
        if (isRemoved(eventType)) {
          this.deleteChatFromChats(entity?.id)
          this.deleteProfileChat(entity?.id)
          this.deleteSearchChat(entity?.id)
          if (hasUnreadMessages(entity)) {
            this.decreaseChatsUnreadCount()
          }
        }
        if (isUpdated(eventType)) {
          this.replaceChatInChats(entity)
          this.replaceProfileChat(entity)
          this.replaceSearchChat(entity)
        }
      }
    },
    onDisconnect() {
      if (this.centrifuge && this.centrifuge.isConnected()) {
        console.log('disconnect')
        this.centrifuge.disconnect()
      }
    },
    getCentrifugeInstance(options) {
      const instance = new Centrifuge(process.env.VUE_APP_API_WEBSOCKET_URL, options)
      instance.setToken(this.accessToken)
      return instance
    },
  },
})
