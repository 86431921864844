// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatInvite_wrapper_4uAUv{width:100%;padding:2px;border-radius:6px;background:linear-gradient(45deg,#df9630,#e09a30,#edd031,#edd031)}.ChatInvite_wrapper_4uAUv .ChatInvite_chatInvite_vFuUN{display:flex;flex-direction:column;gap:6px;align-items:center;width:100%;padding:18px;background:#fff;border-radius:6px}.ChatInvite_wrapper_4uAUv .ChatInvite_chatInvite_vFuUN .ChatInvite_name_3F_QD{font-weight:500;font-size:15px;line-height:22px}.ChatInvite_wrapper_4uAUv .ChatInvite_chatInvite_vFuUN .ChatInvite_desc_4vc5c{color:#a09f9f;font-weight:400;font-size:9px;line-height:12px}.ChatInvite_wrapper_4uAUv .ChatInvite_chatInvite_vFuUN .ChatInvite_button_1MMsF{width:auto;color:var(--supster-primary)}", ""]);
// Exports
exports.locals = {
	"wrapper": "ChatInvite_wrapper_4uAUv",
	"chatInvite": "ChatInvite_chatInvite_vFuUN",
	"name": "ChatInvite_name_3F_QD",
	"desc": "ChatInvite_desc_4vc5c",
	"button": "ChatInvite_button_1MMsF"
};
module.exports = exports;
