/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dialog': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" d="M11 0H2a2 2 0 00-2 2v5a2 2 0 002 2h5l4 4V9a2 2 0 002-2V2a2 2 0 00-2-2z"/>'
  }
})
