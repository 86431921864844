import { replaceEntityMediaObject } from '@helpers/media-objects'

import { Mutations, TemplateMessage } from '@modules/chat/types'

import { Message } from '@common-types/chat/chat-message'

const mutations: Mutations = {
  setChat(state, chat) {
    state.chat = chat
  },
  setChatTempAvatar(state, avatarUrl) {
    state.tempAvatar = avatarUrl
  },
  updateChat(state, chatFields) {
    state.chat = state.chat && { ...state.chat, ...chatFields }
  },
  setChatNewMessageCount(state, messageCount) {
    state.chat = state.chat
      ? {
          ...state.chat,
          channel_subscription: {
            ...(state.chat.channel_subscription ?? []),
            new_message_count: messageCount,
          },
        }
      : null
  },
  incrementChatNewMessageCount(state) {
    state.chat = state.chat
      ? {
          ...state.chat,
          channel_subscription: {
            ...(state.chat.channel_subscription ?? []),
            new_message_count: state.chat.channel_subscription.new_message_count + 1,
          },
        }
      : null
  },
  setChatIsSubscribed(state, isSubscribed) {
    state.chat = state.chat
      ? {
          ...state.chat,
          is_subscribed: isSubscribed,
        }
      : null
  },
  setMessages(state, messages) {
    state.messages = messages
    state.nextPage = 1
  },
  unshiftMessages(state, messages) {
    if (!state.messages || state.nextPage === 1) {
      state.messages = messages
    } else {
      state.messages.unshift(...messages)
    }
  },
  pushMessage(state, message) {
    if (state.messages) {
      const lastMessage = state.messages.pop()
      if (!lastMessage || lastMessage?.id === message.id) {
        state.messages.push(message)
      } else {
        state.messages.push(lastMessage, message)
      }
      return
    }
    state.messages = [message]
  },
  pushTempMessage(state, message: TemplateMessage) {
    state.tempMessages.push(message)
  },
  pushMessages(state, messages) {
    if (state.messages) {
      state.messages.push(...messages)
      return
    }
    state.messages = messages
  },
  replaceMessage(state, message) {
    state.messages =
      state.messages?.map((item) => (item.id === message.id ? message : item)) ?? null
  },
  replaceMessages(state, messages) {
    messages.forEach((message: any) => {
      state.messages = state.messages?.map((item) => (item.id === message.id ? message : item))
    })
  },
  replaceMessageProperties(state, { messageId, ...properties }) {
    state.messages = state.messages.map((message) =>
      message.id === messageId ? { ...message, ...properties } : message
    )
  },
  replaceMessageMediaObject(state, mediaObject) {
    state.messages =
      state.messages?.map((message) => replaceEntityMediaObject(message, mediaObject)) ?? null
  },
  deleteMessage(state, messageId) {
    state.messages = state.messages
      .filter(({ id }) => id !== messageId)
      .map((message) => (message.parent?.id === messageId ? { ...message, parent: null } : message))
  },
  refreshMessages(state, messages) {
    state.messages = messages
  },
  setTempMessages(state, tempMessages) {
    state.tempMessages = tempMessages
  },
  deleteTempMessages(state, messageIds: string[]) {
    state.tempMessages = state.tempMessages.filter(({ tempId }) => !messageIds?.includes(tempId))
  },
  deleteTempMessage(state, tempMessageId: string) {
    state.tempMessages = state.tempMessages.filter(({ tempId }) => tempId !== tempMessageId)
  },
  increaseNextPage(state) {
    state.nextPage += 1
  },
  setNextPage(state, nextPage) {
    state.nextPage = nextPage
  },
  decreasePrevPage(state) {
    state.prevPage = state.prevPage > 0 ? state.prevPage - 1 : 0
  },
  setPrevPage(state, prevPage) {
    state.prevPage = prevPage
  },
  resetChat(state) {
    state.chat = null
  },
  resetMessages(state) {
    state.messages = []
    state.tempMessages = []
    state.nextPage = 1
    state.prevPage = 0
    state.afterGoToPinnedMessage = false
    state.selectedMessageId = null
    state.pinnedMessages = []
    state.pinnedMessagesPointer = 0
  },
  setSelectedMessageId(state, messageId) {
    state.selectedMessageId = messageId
  },
  resetSelectedMessageId(state) {
    state.selectedMessageId = null
  },
  setReferencedMessageId(state, messageId) {
    state.referencedMessageId = messageId
  },
  resetReferencedMessageId(state) {
    state.referencedMessageId = null
  },
  pushPinnedMessages(state, messages: Message[]) {
    const pinnedMessages = [...state.pinnedMessages, ...messages]
    pinnedMessages.sort((a, b) => Number(a.sort_index) - Number(b.sort_index))
    state.pinnedMessages = [...new Map(pinnedMessages.map((item) => [item.id, item])).values()]
  },
  unshiftPinnedMessage(state, message) {
    state.pinnedMessages.unshift(message)
  },
  deletePinnedMessage(state, messageId) {
    state.pinnedMessages = state.pinnedMessages.filter(({ id }) => id !== messageId) ?? null
  },
  resetPinnedMessages(state) {
    state.pinnedMessages = []
  },
  setIsReply(state, isReply) {
    state.isReply = isReply
  },
  setIsEdit(state, isEdit) {
    state.isEdit = isEdit
  },
  setLocalId(state) {
    state.localId = new Date().getTime() % 1000000000
  },
  resetLocalId(state) {
    state.localId = null
  },
  increasePinnedMessagesPointer(state) {
    const currentPointer = state.pinnedMessagesPointer
    const lastIndex = state.pinnedMessages.length - 1
    state.pinnedMessagesPointer = currentPointer < lastIndex ? currentPointer + 1 : 0
  },
  decreasePinnedMessagesPointer(state) {
    const currentPointer = state.pinnedMessagesPointer
    const pinnedMessagesLength = state.pinnedMessages.length
    state.pinnedMessagesPointer = currentPointer > 0 ? currentPointer - 1 : pinnedMessagesLength - 1
  },
  resetPinnedMessagesPointer(state) {
    state.pinnedMessagesPointer = 0
  },
  setAfterGoToPinnedMessage(state, afterGoToPinnedMessage) {
    state.afterGoToPinnedMessage = afterGoToPinnedMessage
  },
  setIsChatLoading(state, isChatLoading) {
    state.isChatLoading = isChatLoading
  },
  setChatGptVersion(state, version) {
    state.gptVersion = version
  },
  setIsDemoMode(state, isDemoMode) {
    state.isDemoMode = isDemoMode
  },
}

export default mutations
