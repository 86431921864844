<template>
  <div :class="$style.section">
    <h3 :class="$style.title" v-html="title"></h3>
    <p :class="$style.text" v-html="text"></p>
    <v-button size="lg" color="black-primary" @click="activate">
      Activate Supster Business
    </v-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import Redirect from '@constants/redirect'

export default Vue.extend({
  name: 'ProfileActivation',
  components: { VButton },
  props: {
    title: String,
    text: String,
  },
  computed: {
    ...mapGetters('App', ['isSplitTestApp5']),
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    activate() {
      if (this.isSplitTestApp5) {
        window.location.href = Redirect.GPT_BUILDER
        return
      }
      this.setDrawer({ name: 'phone', isVisible: true })
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  grid-gap: 12px;
  padding: 15px;
  background: $primary;
  border-radius: 6px;
}

.title {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
