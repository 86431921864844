<template>
  <main
    ref="main"
    id="main"
    :class="[$style.main, !pageIsScroll && $style.noScroll]"
    :style="stylePadding"
  >
    <keep-alive>
      <router-view />
    </keep-alive>
  </main>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'VMain',
  components: {},
  data() {
    return {
      scrollWidth: 0,
    }
  },
  computed: {
    ...mapGetters('App', ['myApps']),
    ...mapGetters('Me', ['isLoggedIn']),
    ...mapGetters('Countries', ['currentCountry']),
    ...mapGetters('Page', ['pageIsScroll']),
    stylePadding() {
      return `padding-right: ${this.scrollWidth && !this.pageIsScroll ? this.scrollWidth : 0}px`
    },
  },
  methods: {
    ...mapActions('App', ['getApps']),
    ...mapActions('Countries', ['getCurrentCountry', 'getCountries']),
    ...mapMutations('Drawers', ['setDrawer']),
  },
  updated() {
    this.scrollWidth = this.$refs.main.offsetWidth - this.$refs.main.clientWidth
  },
  async mounted() {
    if (!this.currentCountry) {
      this.getCurrentCountry()
    }
    if (!this.myApps) {
      await this.getApps()
    }
  },
})
</script>

<style lang="scss" module>
.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &.noScroll {
    overflow-y: hidden;
  }
}
</style>
