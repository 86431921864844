<template>
  <div>
    <v-drawer
      name="comment-report"
      :is-back="!isSelectingActions"
      :title="drawerTitle"
      @back="onBack"
      @close="onClose"
    >
      <transition-group :class="$style.drawer" tag="div" name="scroll-horizontal" appear>
        <div v-if="isSelectingActions" key="actions">
          <v-user-preview
            :class="$style.userPreview"
            :user-avatar="userAvatar"
            :user-title="userName"
          >
            <template #subtitle>
              <v-date-gap :date="commentDate" />
            </template>
          </v-user-preview>
          <v-list :class="$style.list" :list="menu" @click="onMenu($event)" />
        </div>
        <VList key="claims" v-if="isSelectingClaims" :list="claims" @click="onSelectClaim" />
      </transition-group>
    </v-drawer>

    <VModal
      :is-visible="isBlockModal"
      :title="isBlockUser ? $t('block.app.user') : $t('block.comment')"
      is-confirm
      no-validation
      :is-loading="isLoading"
      @cancel="isBlockModal = false"
      @submit="onBlock"
    >
      <template #accept>
        {{ $t('confirm.accept') }}
      </template>
      <template #cancel>
        {{ $t('confirm.cancel') }}
      </template>
    </VModal>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VDrawer from '@drawers/VDrawer.vue'
import VList from '@elements/VList.vue'
import VDateGap from '@layouts/VDateGap.vue'
import VUserPreview from '@layouts/VUserPreview.vue'
import VModal from '@modals/VModal.vue'
import { CommentClaims } from '@components/layouts/post/list/config'

export default Vue.extend({
  name: 'CommentReportDrawer',
  components: { VDrawer, VList, VUserPreview, VDateGap, VModal },
  props: { isPostPage: Boolean },
  data() {
    return {
      isBlockModal: false,
      isLoading: false,
      isSelectingActions: true,
      isSelectingClaims: false,
      isBlockUser: false,
      isClaimUser: false,
    }
  },
  computed: {
    ...mapGetters('Comments', ['selectedComment', 'selectedCommentParams']),
    menu() {
      return [
        { name: 'claim-user', title: this.$t('report.user') },
        { name: 'claim', title: this.$t('report.comment') },
        { name: 'block-user', title: this.$t('blockUser') },
        { name: 'block', title: this.$t('block.comment') },
        { name: 'cancel', color: 'grey', title: this.$t('cancel') },
      ]
    },
    user() {
      return this.selectedComment?.client ?? null
    },
    userAvatar() {
      return this.user?.avatar ?? null
    },
    userName() {
      return this.user?.name ?? ''
    },
    commentDate() {
      return this.selectedComment?.updated ?? this.selectedComment?.created ?? ''
    },
    claims() {
      return CommentClaims.map((claim) => {
        return {
          name: claim,
          title: this.$t(`claims.${claim}`),
        }
      })
    },

    drawerTitle() {
      return (this.isSelectingClaims && this.$t('report.reason')) || ''
    },
    isSubComment() {
      return !!this.selectedCommentParams?.parentId
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Comments', ['setSelectedCommentParams', 'removeComment', 'removeSubComment']),
    ...mapActions('Comments', ['deleteCommentsByClientId']),
    ...mapActions('Blocks', ['postBlock', 'getBlocks', 'deleteBlock']),
    async onMenu(link) {
      if (link === 'block-user' || link === 'block') {
        this.isBlockUser = link === 'block-user'
        this.isBlockModal = true
        return
      }
      if (link === 'claim' || link === 'claim-user') {
        this.isClaimUser = link === 'claim-user'
        this.isSelectingActions = false
        this.isSelectingClaims = true
        return
      }
      this.onClose()
      this.setSelectedCommentParams(null)
    },

    async onBlock() {
      this.isLoading = true
      if (this.isBlockUser) {
        await this.postBlock(this.user?.id)
        this.deleteCommentsByClientId(this.user?.id)
        this.$notify({
          type: 'success',
          title: `${this.$t('userIsBlocked')}`,
        })
        this.isBlockUser = false
      } else {
        this.onRemoveComment()
      }
      this.isLoading = false
      this.isBlockModal = false
      this.onClose()
    },
    onRemoveComment() {
      if (this.isSubComment) {
        this.removeSubComment({
          commentId: this.selectedCommentParams?.commentId,
          parentId: this.selectedCommentParams?.parentId,
        })
      } else {
        this.removeComment(this.selectedCommentParams?.commentId)
      }
    },
    onSelectClaim(claimName) {
      this.onClose()
      this.setDrawer({
        name: 'claim-confirm',
        isVisible: true,
        params: {
          claimName,
          commentId: this.selectedCommentParams?.commentId,
          parentId: this.selectedCommentParams?.parentId,
          clientId: this.user?.id,
          isClaimUser: this.isClaimUser,
        },
      })
    },
    onBack() {
      this.isSelectingClaims = false
      this.isSelectingActions = true
    },
    onClose() {
      this.onBack()
      this.setDrawer({ name: 'comment-report', isVisible: false })
    },
  },
})
</script>

<style lang="scss" module>
.userPreview {
  padding: 9px;
  border-bottom: 1px solid $secondary-light;
}

.list {
  height: inherit;
}
</style>
