/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warn-triangle': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<path pid="0" d="M17.72 12.924L10.79 1.025A2.052 2.052 0 009 0c-.696 0-1.393.342-1.791 1.025L.28 12.925C-.516 14.29.48 16 2.071 16H15.93c1.592 0 2.587-1.709 1.79-3.076zm-.861 1.542a1.05 1.05 0 01-.93.534H2.07a1.05 1.05 0 01-.93-.534c-.09-.156-.27-.57.003-1.038l6.93-11.9C8.35 1.052 8.813 1 9 1c.186 0 .65.052.927.528l6.929 11.9c.272.468.093.882.003 1.038zM10 13a1 1 0 11-2 0 1 1 0 012 0zm0-7v4a1 1 0 11-2 0V6a1 1 0 012 0z" _fill="#000"/>'
  }
})
