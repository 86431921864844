import store from '@store/index'
import { ToastTypes } from '@modules/toasts/constants'
import i18n from '@plugins/i18n'
import { isNotAllowed } from '@helpers/media-devices'
import { MediaDevicesAccessError, PermissionState } from '../common-types/media-devices'

export const hasUserMedia = () => {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
}

export const handleMediaServiceError = (error: DOMException) => {
  const { name } = error
  if (isNotAllowed(name as MediaDevicesAccessError)) {
    store.commit('Toasts/pushToast', {
      title: i18n.t('mediaDevicesPermissionNotification.label'),
      message: i18n.t('mediaDevicesPermissionNotification.desc'),
      type: ToastTypes.INFO,
    })
  }
}

export const requestPermission = async (): Promise<void> => {
  if (!hasUserMedia()) {
    store.commit('Toasts/pushToast', {
      title: i18n.t('mediaDevicesNotSupported.label'),
      message: i18n.t('mediaDevicesNotSupported.desc'),
      type: ToastTypes.INFO,
    })
  }
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    if (stream) {
      stream.getTracks().forEach((track) => track.stop())
    }
  } catch (error) {
    handleMediaServiceError(error as DOMException)
  }
}

export const getCurrentPermissionState = async (): Promise<PermissionState> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const permission = await navigator.permissions.query({ name: 'microphone' })
  return permission.state as PermissionState
}

export const isPermissionGranted = async (): Promise<boolean> => {
  const state = await getCurrentPermissionState()
  return state === PermissionState.GRANTED
}
export default {
  requestPermission,
}
