import { DataType as AppType } from '@services/app/types'
import { MediaObject } from '@services/media/types'
import { DataType as ProductType } from '@services/product/types'
import { Client } from '@services/client/types'
import { Tab } from '@modules/profile/constants'

export interface Post {
  app: AppType
  body: string | null
  comment_count: number
  comments_enabled: number
  commerce: boolean
  created: DateType
  id: number
  is_accessible: boolean
  is_liked: boolean
  like_count: number
  location: null
  media_objects: MediaObject[]
  name: string | null
  products: ProductType[]
  promotional_page_id: number | null
  sender: {
    avatar: MediaObject
    client: Client
    client_id: number
    created: DateType
    email: string
    email_confirm_status: number
    id: number
    name: string
    phone: string
    phone_confirm_status: number
    status: number
    updated: DateType
  }
  status: number
  tags: null
  updated: DateType
  view_count: number
}

export type Posts = Post[]
export type PostId = Post['id']
export type PostIds = PostId[]
export type PostsObjectMap = Record<PostId, Post>

export enum PostSource {
  SEARCH = 'SEARCH',
  PROFILE = 'PROFILE',
}

export type PostMeta = {
  postId: PostId
  source: PostSource
  tabName: Tab
  appId: string
}
