<template>
  <div :class="$style.container">
    <div :class="[$style.indicator, isRight && $style.indicatorRight]" />
    <div
      v-for="({ label, value }, index) of items"
      :key="index"
      :class="[$style.item, currentIndex === index && $style.itemActive]"
      @click="onClickItem(value, index)"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VLabeledToggle',
  props: {
    items: {
      type: Array,
      default: () => [],
      validator: (v) => v.length === 2,
    },
    defaultIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentIndex: this.defaultIndex,
    }
  },
  computed: {
    isRight() {
      return this.currentIndex === 1
    },
  },
  methods: {
    onClickItem(value, index) {
      this.currentIndex = index
      this.$emit('click-item', value)
    },
  },
})
</script>

<style module lang="scss">
.container {
  position: relative;
  display: flex;
  min-width: 300px;
  width: 100%;
  height: 36px;
  justify-content: stretch;
  align-items: stretch;
  padding: 3px;
  border-radius: 6px;
  background: white;
  box-shadow: 0px 8px 20px 0px rgba(39, 20, 96, 0.07);

  .indicator {
    position: absolute;
    left: 3px;
    right: 50%;
    top: 3px;
    bottom: 3px;
    border-radius: 3px;
    transition: all 0.3s ease;
    background: $primary-light-background;

    &Right {
      right: 3px;
      left: 50%;
    }
  }

  .item {
    color: $secondary;
    transition: color, background-color 0.3s ease;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    font-size: 12px;
    line-height: 18px;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background: $grey;
    }

    &Active {
      color: $primary-light-text-color;
      transition: background-color 0s ease;

      &:hover {
        background: transparent;
      }
    }
  }
}
</style>
