/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye': {
    width: 13,
    height: 9,
    viewBox: '0 0 13 9',
    data: '<path pid="0" d="M10.095 1.525A5.007 5.007 0 006.5 0a5.007 5.007 0 00-3.595 1.525L0 4.5l2.905 2.975A5.007 5.007 0 006.5 9a5.007 5.007 0 003.595-1.525L13 4.5l-2.905-2.975zm-.716 5.252a3.999 3.999 0 01-5.758 0L1.398 4.5 3.62 2.223a3.999 3.999 0 015.758 0L11.602 4.5 9.38 6.777zM6.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0 2a.5.5 0 11.001-1.001A.5.5 0 016.5 5z"/>'
  }
})
