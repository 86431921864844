/* eslint-disable camelcase */
import { VuexActions, VuexGetters, VuexModule, VuexMutations } from '@store/types'

export enum Tab {
  POSTS = 'POSTS',
  CHATS = 'CHATS',
  TAGS = 'TAGS',
}

export interface State {
  tab: Tab
  query: string
}

export type Module = VuexModule<State>

export type Mutations = VuexMutations<State>

export type Actions = VuexActions<State>

export type Getters = VuexGetters<State>
