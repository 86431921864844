<template>
  <v-button
    :class="$style.container"
    :color="color"
    :is-circle="isCircle"
    :is-disabled="isDisabled"
    :is-disabled-padding="isDisabledPadding"
    @click="onButton"
  >
    <slot />
    <input
      :class="$style.input"
      ref="input"
      type="file"
      :accept="accept"
      :multiple="isMultiple"
      @input.prevent="onInput"
    />
  </v-button>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VFile',
  components: { VButton },
  props: {
    color: String,
    isCircle: Boolean,
    isDisabled: Boolean,
    isDisabledPadding: Boolean,
    fileTypes: {
      type: Array,
      default: () => ['image', 'video'],
    },
    isMultiple: Boolean,
    accept: {
      type: String,
      default:
        'image/png, image/jpeg, video/mp4, video/mpeg, video/ogg, video/x-ms-wmv, video/x-flv, video/x-msvideo',
    },
  },
  computed: {
    ...mapGetters('Files', ['filesValid']),
  },
  methods: {
    ...mapActions('Files', ['validateFiles']),
    ...mapActions('Error', ['notify']),
    onButton() {
      this.$refs.input.click()
    },
    async onInput({ target: { files } }) {
      await this.validateFiles({
        files: [...files],
        fileTypes: this.fileTypes,
        isMultiple: this.isMultiple,
      })
      if (this.filesValid) {
        this.$emit('files')
      }
      this.$refs.input.value = null
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
}

.input {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}
</style>
