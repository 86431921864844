<template>
  <v-drawer name="cart" :title="$t('cart.title')">
    <div :class="$style.content">
      <div v-if="cartProductsLength">
        <cart-product v-for="product in cartProducts" :key="product.id" :product="product" />
      </div>
      <v-no-data v-else size="sm" :text="`${$t('cart.empty')} 🛒`" />
    </div>
    <div :class="$style.footer">
      <v-button size="xl" color="primary" :is-disabled="!cartProductsLength" @click="onCart">
        {{ $t('orders.checkout') }}
      </v-button>
    </div>
  </v-drawer>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'
import VNoData from '@layouts/VNoData.vue'
import CartProduct from '@views/cart/list/CartProduct.vue'

export default Vue.extend({
  name: 'CartDrawer',
  components: { VDrawer, CartProduct, VNoData, VButton },
  props: {
    isVisible: Boolean,
  },
  computed: {
    ...mapGetters('Cart', ['cartProducts', 'cartProductsLength']),
  },
  // watch: {
  //   $route: {
  //     handler() {
  //       this.setDrawer({ name: 'cart', isVisible: false })
  //     },
  //   },
  // },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    onCart() {
      this.$router.push({ name: 'Cart' })
    },
  },
})
</script>
<style lang="scss" module>
.content {
  display: grid;
  grid-template-rows: 1fr min-content;
  height: calc(100% - 79px);
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid $secondary-light;
}

.product {
  border-top: 1px solid $secondary-light;

  &:first-child {
    border-top: 0;
  }
}

.footer {
  display: grid;
  padding: 15px;
  background-color: white;
  border-top: 1px solid $secondary-light;
}
</style>
