<template>
  <div :class="[$style.container, isAccessible && $style.convert]">
    <div :class="[$style.content, isBig && $style.lg]">
      <div :class="$style['icon-wrap']">
        <icon v-if="!isAccessible" :class="$style.icon" name="lock" />
      </div>
      <VPostPreviewMedia v-if="!isAccessible && !isVideo" :class="$style.preview" :post="post" />
      <v-button
        @click="$emit('click')"
        :class="[$style.button, !isBig && $style.round]"
        :size="isBig ? 'xl' : 'md'"
        :color="isBig ? 'black-primary' : 'black-primary-light'"
      >
        <span>{{ $t('subscribersOnly') }}</span>
      </v-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VButton from '@elements/VButton.vue'
import { hasVideo } from '@helpers/posts'
import VPostPreviewMedia from './VPostPreviewMedia.vue'

export default Vue.extend({
  name: 'VPostPreviewSubscribe',
  components: { VButton, VPostPreviewMedia },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isBig() {
      return (
        this.$route.name === 'Post' || this.$route.name === 'Feed' || this.$route.name === 'Product'
      )
    },
    isVideo() {
      return hasVideo(this.post)
    },
    isAccessible() {
      return this.post?.is_accessible ?? false
    },
  },
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  padding-bottom: 100%;
  background: $primary;

  &.convert {
    background: transparent;
  }
}

.icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  display: grid;
  grid-auto-rows: 1fr auto;
  width: 100%;
  height: 100%;
  row-gap: 5px;
  padding: 7px;
  transform: translate(-50%, -50%);

  &.lg {
    padding: 15px;
  }
}

.icon {
  align-self: center;
  justify-self: center;
  width: 40px;
  height: 46px;
  color: $main-text-color;
}

.button {
  grid-auto-columns: auto;

  &.round {
    border-radius: 6px 6px 3px 3px !important;
  }
}
</style>
