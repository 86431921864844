import md5 from 'md5'
import { VueConstructor } from 'vue'
import config from './config'
import {
  CacheManagerOptions,
  CacheHandlersMap,
  MapId,
  CacheHandler,
  AddMethodOptions,
} from './types'

const extractDataFromEvent = (event: MessageEvent) => {
  const { data } = event
  const { meta: eventMessageName, payload } = data ?? {}
  const { cacheName, updatedURL } = payload ?? {}

  return { eventMessageName, cacheName, updatedURL }
}

export default {
  messageName: config.messageName,

  cacheHandlersMaps: [] as CacheHandlersMap[],

  async cacheEventHandler(event: MessageEvent): Promise<void> {
    if (!window.caches) {
      return
    }
    const { eventMessageName, cacheName, updatedURL } = extractDataFromEvent(event)
    const isUpdateMessage = eventMessageName === this.messageName && cacheName && updatedURL
    if (!isUpdateMessage) {
      return
    }
    const neededCacheHandlersMaps = this.cacheHandlersMaps.filter(({ regExpURL }) =>
      regExpURL.test(updatedURL)
    )
    if (!neededCacheHandlersMaps) {
      return
    }
    const cache = await caches.open(cacheName)
    const updatedResponse = await cache.match(updatedURL)
    const neededCacheHandlers = neededCacheHandlersMaps.reduce(
      (handlers: CacheHandler[], { cacheHandlers }) => handlers.concat(cacheHandlers),
      []
    )
    const canUpdate = updatedResponse && neededCacheHandlers?.length
    if (canUpdate) {
      const { data, ...params } = await updatedResponse.json()
      neededCacheHandlers.forEach((cacheHandler) => cacheHandler(data, updatedURL, params))
    }
  },

  add({ regExpURL, cacheHandlers }: AddMethodOptions): MapId {
    const mapId = md5(new Date().toString())
    this.cacheHandlersMaps.push({ regExpURL, mapId, cacheHandlers })
    return mapId
  },

  remove(mapIds: MapId[]): void {
    this.cacheHandlersMaps = this.cacheHandlersMaps.filter(({ mapId: id }) => !mapIds.includes(id))
  },

  install(Vue: VueConstructor, options?: CacheManagerOptions): void {
    this.messageName = options?.messagesName ?? this.messageName
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if ('serviceWorker' in navigator && !isSafari) {
      navigator.serviceWorker.addEventListener('message', this.cacheEventHandler.bind(this))
    }
    Vue.prototype.$cacheManager = {
      add: this.add.bind(this),
      remove: this.remove.bind(this),
    }
  },
}
