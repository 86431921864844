<template>
  <div :class="$style.page">
    <VGrid :length="postIdsLength" is-infinite @infinite="getPosts">
      <div :style="wrapperStyles">
        <VPost
          v-for="post in visiblePosts"
          :key="`post-${post.id}`"
          :post="post"
          :observers="observers"
          @mounted="addComponentHeight"
        />
      </div>
      <template #placeholder>
        <PPost v-for="i in placeholdersCount" :key="i" />
      </template>
    </VGrid>
    <VReportMenu />
    <!--    <v-post-share :post="metaPost" />-->
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PPost from '@placeholders/PPost.vue'
import VGrid from '@layouts/VGrid.vue'
import VReportMenu from '@layouts/post/list/VReportMenu.vue'
import VPost from '@layouts/post/VPost.vue'
import store from '@store/index'
import Config, { VirtualizationConfig } from './config'

export default Vue.extend({
  name: 'SearchFeed',
  components: { VPost, VReportMenu, VGrid, PPost },
  computed: {
    ...mapGetters('Search/Posts/Feed', [
      'postIds',
      'postIdsLength',
      'visiblePosts',
      'visiblePostsIds',
      'wrapperPaddingTop',
      'wrapperPaddingBottom',
      'averageComponentHeight',
      'startIndex',
    ]),
    wrapperStyles() {
      return {
        paddingTop: `${this.wrapperPaddingTop}px`,
        paddingBottom: `${this.wrapperPaddingBottom}px`,
      }
    },
    observers() {
      const autoplayObserver = new IntersectionObserver(this.autoplayObserverCallback.bind(this), {
        root: document.getElementById('main'),
        threshold: 1.0,
      })
      return [autoplayObserver]
    },
    placeholdersCount() {
      return Config.POSTS_FEED_PLACEHOLDERS_COUNT
    },
    mainPostId() {
      return this.$route.params?.id
    },
  },
  methods: {
    ...mapMutations('Search/Posts/Feed', [
      'addComponentHeight',
      'setStartIndex',
      'setVisiblePosts',
    ]),
    ...mapMutations('Feed', ['setIntersectedPostId']),
    ...mapActions('Search/Posts', ['getPosts']),
    autoplayObserverCallback(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (isIntersecting) {
          this.setIntersectedPostId(Number(target.dataset.postId))
        }
      })
    },
    onPostComponentMounted({ height, postId }) {
      this.addComponentHeight({ postId, height })
    },
    scrollListener(event) {
      const scrollTopPadding = event.target.scrollTop - this.averageComponentHeight
      const scrollTop = scrollTopPadding > 0 ? scrollTopPadding : 0
      const startIndex =
        Math.floor(scrollTop / this.averageComponentHeight) * VirtualizationConfig.SCROLL_STEP
      this.checkStartIndex(startIndex)
    },
    checkStartIndex(index) {
      if (this.startIndex !== index) {
        this.setStartIndex(index)
      }
    },
    connectScrollWatcher() {
      const mainWrapper = document.getElementById('main')
      mainWrapper.addEventListener('scroll', this.scrollListener)
    },
    deleteScrollWatcher() {
      const mainWrapper = document.getElementById('main')
      mainWrapper.removeEventListener('scroll', this.scrollListener)
    },
  },
  watch: {
    visiblePostsIds: {
      handler(postIds) {
        this.setVisiblePosts(postIds)
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Search/Posts/postIdsLength']) {
      return next()
    }
    return next({ ...to, name: 'Post' })
  },
  mounted() {
    this.connectScrollWatcher()
  },
  beforeDestroy() {
    this.deleteScrollWatcher()
  },
})
</script>

<style lang="scss" module>
.page {
  position: relative;
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  color: $secondary;
}
</style>
