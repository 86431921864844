// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatButtonNotification_subscribingButton_2nYck{border-radius:0!important}.ChatButtonNotification_subscribingButton_2nYck:hover{transform:translate(0)}.ChatButtonNotification_subscribingButtonIos_23-57{padding-bottom:27px!important}", ""]);
// Exports
exports.locals = {
	"subscribingButton": "ChatButtonNotification_subscribingButton_2nYck",
	"subscribingButtonIos": "ChatButtonNotification_subscribingButtonIos_23-57"
};
module.exports = exports;
