<template>
  <div :class="$style.burger" @click="setPageIsMenu(!pageIsMenu)">
    <button :class="[$style.button, pageIsMenu && $style.buttonOpen]">
      <div :class="[$style.wrapper, pageIsMenu && $style.wrapperOpen]"></div>
    </button>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'VBurger',
  computed: {
    ...mapGetters('Page', ['pageIsMenu']),
  },
  methods: {
    ...mapMutations('Page', ['setPageIsMenu']),
  },
})
</script>
<style lang="scss" module>
.burger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  width: 21px;
  height: 11px;
  background-color: transparent;
  transition: all 0.2s ease-in;

  &Open {
    transform: rotate(180deg);
  }
}

.wrapper {
  display: block;
  width: 100%;
  height: 1px;
  background: $main-text-color;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  &:before,
  &:after {
    display: block;
    width: 100%;
    height: 1px;
    background: $main-text-color;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:before {
    position: absolute;
    left: 0;
    transform: translateY(-5px);
    content: '';
  }

  &:after {
    position: absolute;
    left: 0;
    transform: translateY(5px);
    content: '';
  }

  &Open {
    background: transparent !important;

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }
}
</style>
