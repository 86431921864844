/* eslint-disable import/prefer-default-export */
export function abbreviateNumber(count: number, locale = 'en'): string {
  if (count > 999999) {
    return `${(count / 1000000).toFixed(1)}${locale === 'ru' ? 'млн' : 'm'}`
  }
  if (count > 9999) {
    return `${(count / 1000).toFixed(1)}${locale === 'ru' ? 'тыс.' : 'k'}`
  }
  return count.toString()
}

export function shortAbbreviateNumber(count: number): string {
  const number = Number(Number(count).toFixed(0))
  if (number < 100) {
    return number.toString()
  }
  return '100+'
}
