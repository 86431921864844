// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VHeaderButton_button_3KSOj{display:flex;align-items:center;justify-content:center;width:45px;height:45px;background:transparent;color:var(--supster-main-text-color)}.VHeaderButton_button_3KSOj.VHeaderButton_disabled_1DfgS{opacity:.3}.VHeaderButton_button_3KSOj.VHeaderButton_active_s1hvh{background-color:rgba(0,0,0,.6)}", ""]);
// Exports
exports.locals = {
	"button": "VHeaderButton_button_3KSOj",
	"disabled": "VHeaderButton_disabled_1DfgS",
	"active": "VHeaderButton_active_s1hvh"
};
module.exports = exports;
