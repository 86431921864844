/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tags': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<g filter="url(#filter0_d_1001_101)"><path pid="0" d="M16.687 0H11.54c-.635 0-1.243.252-1.692.7L.758 9.792a2.588 2.588 0 000 3.66l3.791 3.791a2.588 2.588 0 003.66 0l9.09-9.09c.449-.449.701-1.057.701-1.692V1.313C18 .588 17.412 0 16.687 0zM13.5 6a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" _fill="#fff"/></g><defs><filter id="filter0_d_1001_101" x="0" y="0" width="19" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="1" dy="1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1001_101"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1001_101" result="shape"/></filter></defs>'
  }
})
