/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-growth': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.709 4.703c-.2.2-.45.29-.71.29-.26 0-.51-.1-.71-.29l-.29-.29v4.59c0 .55-.45 1-1 1h-4v4c0 .55-.45 1-1 1h-5c-.55 0-1-.45-1-1s.45-1 1-1h4v-4c0-.55.45-1 1-1h4v-3.59l-.29.29c-.2.2-.45.29-.71.29-.26 0-.51-.1-.71-.29a.996.996 0 010-1.41l2-2a1 1 0 011.42 0l2 2c.39.39.39 1.02 0 1.41z"/>'
  }
})
