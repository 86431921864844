/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question-colored': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<rect pid="0" width="16" height="16" rx="8" _fill="#000"/><path pid="1" d="M7.09 10.654a4 4 0 01.078-.798c.056-.26.146-.5.27-.72.108-.212.228-.38.36-.504.132-.128.28-.254.444-.378.164-.128.346-.298.546-.51.164-.18.272-.358.324-.534.052-.18.078-.372.078-.576 0-.132-.014-.256-.042-.372a.709.709 0 00-.15-.3.89.89 0 00-.432-.312 1.638 1.638 0 00-.564-.096c-.164 0-.322.022-.474.066a.966.966 0 00-.378.192.852.852 0 00-.264.354c-.056.14-.082.294-.078.462H5.284c.04-.4.148-.77.324-1.11.18-.344.416-.618.708-.822.228-.168.484-.294.768-.378.288-.084.576-.126.864-.126.44 0 .854.068 1.242.204.392.136.718.36.978.672.18.204.316.44.408.708.096.268.144.54.144.816 0 .412-.09.788-.27 1.128-.176.34-.4.654-.672.942-.128.136-.25.256-.366.36a8.192 8.192 0 00-.324.306 1.553 1.553 0 00-.24.306 1.401 1.401 0 00-.198.48c-.02.128-.03.308-.03.54H7.09zM7.102 13v-1.59H8.62V13H7.102z" _fill="#fff"/>'
  }
})
