import { Getters } from './types'

const getters: Getters = {
  accessToken: (state) => state.accessToken,
  isLoggedIn: (state) => !!state.accessToken,
  cId: (state) => state.cId,
  stream: (state) => state.stream,
  subId: (state) => state.subId,
  refId: (state) => state.refId,
  isSkipInstall: (state) => state.isSkipInstall === 'true',
  isPassInstall: (state) => state.isPassInstall === 'true',
}

export default getters
