// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileEmptyTab_content_3CNEZ{height:100%;width:100%;display:flex;gap:16px;flex-direction:column;align-items:center;justify-content:center;color:#e5e3e3}.ProfileEmptyTab_content_3CNEZ .ProfileEmptyTab_icon_1-O9o{width:60px;height:60px}.ProfileEmptyTab_content_3CNEZ .ProfileEmptyTab_text_3qMu8{font-weight:500;font-size:24px;line-height:28px;text-align:center}", ""]);
// Exports
exports.locals = {
	"content": "ProfileEmptyTab_content_3CNEZ",
	"icon": "ProfileEmptyTab_icon_1-O9o",
	"text": "ProfileEmptyTab_text_3qMu8"
};
module.exports = exports;
