/* eslint-disable camelcase */
import { VuexActions, VuexGetters, VuexModule, VuexMutations } from '@store/types'
import { DataType as AppType } from '@services/app/types'

interface ClientAppType extends AppType {
  images?: File[] | null
  videos?: File[] | null
}

export enum StateNames {
  CLIENT_APP = 'CLIENT_APP',
  MY_APP = 'MY_APP',
  OTHER_APP = 'OTHER_APP',
}

export interface State {
  [StateNames.MY_APP]: AppType | null
  myApps: AppType[] | null
  [StateNames.OTHER_APP]: AppType | null
  [StateNames.CLIENT_APP]: ClientAppType | null
  currentAppName: StateNames
}

export type Module = VuexModule<State>

export type Mutations = VuexMutations<State>

export type Actions = VuexActions<State>

export type Getters = VuexGetters<State>
