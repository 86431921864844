/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'folder-arrow': {
    width: 24,
    height: 22,
    viewBox: '0 0 24 22',
    data: '<path pid="0" d="M12 11v10M8 15l4-4 4 4" _stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M8 21H3a2 2 0 01-2-2V1h8l3 4h11v14a2 2 0 01-2 2h-5" _stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
