/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'edit-round': {
    width: 33,
    height: 33,
    viewBox: '0 0 33 33',
    data: '<rect pid="0" x="1.5" y="1.5" width="30" height="30" rx="15" _fill="#000"/><path pid="1" d="M23 12.843l-7.786 7.786-2.843-2.843L20.157 10 23 12.843zM10 23l3.949-1.105-2.844-2.844L10 23z" _fill="#fff"/><rect pid="2" x="1.5" y="1.5" width="30" height="30" rx="15" _stroke="#fff" stroke-width="3"/>'
  }
})
