/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'donate': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.53 7.515l.136-2.197c.495.148.89.429 1.034.783a.499.499 0 10.926-.377c-.283-.696-1.008-1.22-1.897-1.426l.047-.767a.5.5 0 00-.467-.53c-.306-.04-.514.192-.53.468l-.047.747c-1.443.074-2.54.911-2.59 2.034-.042.975.75 1.661 2.338 2.05l-.149 2.409c-.443-.106-.833-.301-1.077-.564-.122-.131-.265-.342-.253-.598a.501.501 0 00-1-.043c-.02.475.16.931.519 1.32.413.446 1.042.76 1.749.897l-.046.748a.5.5 0 10.998.062l.046-.746c1.507-.075 2.682-.934 2.732-2.07.044-1.028-.856-1.816-2.469-2.2zM6.142 6.294c.023-.553.704-1 1.528-1.079l-.127 2.071c-.655-.179-1.423-.494-1.401-.992zm2.186 4.492l.14-2.26c.821.221 1.555.607 1.531 1.146-.024.558-.77 1.03-1.67 1.114zM8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 15c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z" _fill="#EDD031"/>'
  }
})
