<template>
  <div :class="$style.section">
    <v-button
      :class="$style.button"
      color="transparent"
      is-disabled-padding
      @click="onCounterRemove"
    >
      <svg :class="$style.icon" viewBox="0 0 6 12" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.130003 6.33623L5.12993 11.8364C5.22856 11.9448
          5.3643 12 5.50004 12C5.62016 12 5.74076 11.957 5.83647
          11.8701C6.04056 11.6841 6.0557 11.3681
          5.87016 11.1635L1.17589 5.99979L5.87016 0.836031C6.0557
          0.631434 6.04056 0.315505 5.83647 0.129463C5.63285 -0.0551139
          5.31499 -0.0409533 5.12993 0.163155L0.130003
          5.66335C-0.0433344 5.85427 -0.0433344 6.1453 0.130003 6.33623Z"
        />
      </svg>
    </v-button>
    <span :class="$style.amount">{{ counter }}</span>
    <v-button :class="$style.button" color="transparent" @click="onCounterAdd" is-disabled-padding
      ><svg :class="$style.icon" viewBox="0 0 6 12" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.87 6.33623L0.870068 11.8364C0.771437 11.9448 0.635696 12 0.499956
          12C0.37984 12 0.259237 11.957 0.163535 11.8701C-0.0405638
          11.6841 -0.0557004 11.3681 0.129844 11.1635L4.82411 5.99979L0.129844
          0.836031C-0.0557004 0.631434 -0.0405637 0.315505 0.163535 0.129463C0.367145
          -0.0551139 0.685012 -0.0409533 0.870068 0.163155L5.87
          5.66335C6.04333 5.85427 6.04333 6.1453 5.87 6.33623Z"
        />
      </svg>
    </v-button>
  </div>
</template>

<script>
import Vue from 'vue'

import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VCounter',
  components: { VButton },
  data() {
    return { counter: this.value }
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value(value) {
      this.counter = value
    },
  },
  methods: {
    onCounterRemove() {
      this.$emit('input', this.counter === 0 ? 0 : this.counter--)
      this.$emit('remove')
    },
    onCounterAdd() {
      this.$emit('input', this.counter++)
      this.$emit('add')
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-self: center;
  column-gap: 3px;
}

.amount {
  align-self: center;
  min-width: 30px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
}

.button {
  align-self: center;

  &:hover {
    transform: none;
  }
}

.icon {
  width: 6px;
  height: 12px;
  color: currentColor;
}
</style>
