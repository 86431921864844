/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'lock': {
    width: 40,
    height: 46,
    viewBox: '0 0 40 46',
    data: '<path pid="0" d="M35 20h-3v-8c0-6.617-5.383-12-12-12S8 5.383 8 12v8H5c-2.757 0-5 2.243-5 5v16c0 2.757 2.243 5 5 5h30c2.757 0 5-2.243 5-5V25c0-2.757-2.243-5-5-5zm-23-8c0-4.411 3.589-8 8-8s8 3.589 8 8v8H12v-8zm24 29c0 .551-.449 1-1 1H5c-.551 0-1-.449-1-1V25c0-.551.449-1 1-1h30c.551 0 1 .449 1 1v16zM22 31v4a2 2 0 01-4 0v-4a2 2 0 014 0z"/>'
  }
})
