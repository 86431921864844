// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPost_section_3PMHt{position:relative;display:grid;box-shadow:inset 0 -1px 0 var(--supster-secondary)}.VPost_section_3PMHt:last-child{box-shadow:none}.VPost_placeholder_3-W8d{height:500px}", ""]);
// Exports
exports.locals = {
	"section": "VPost_section_3PMHt",
	"placeholder": "VPost_placeholder_3-W8d"
};
module.exports = exports;
