import i18n from '@plugins/i18n'
import { Getters } from './types'

const getters: Getters = {
  pageBack: (state) => state.back,
  pageLastRouteFromFooter: (state) => state.lastRouteFromFooter,
  pageIsHeader: (state) => state.isHeader,
  pageIsFooter: (state) => state.isFooter,
  pageIsMenu: (state) => state.isMenu,
  pageIsOffline: (state) => state.isOffline,
  pageLoading: (state) => state.loading,
  pageTitle: (state) => i18n.t(state.title.name, [state.title.value]),
  pageTitleType: (state) => state.title.type,
  pageIsScroll: (state) => state.isScroll,
  isIOS() {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
  },
  isPWAInstalled: () => {
    return window.matchMedia('(display-mode: standalone)').matches
  },
  isIOSInstalled: () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line dot-notation
    'standalone' in window.navigator && window.navigator['standalone'],
  isMobile() {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
  },
  isFirstLaunch: (state) => state.isFirstLaunch,
  isDesktop: (state) => state.isDesktop,
}

export default getters
