// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileCover_section_1AMpQ{display:grid;grid-template-columns:repeat(3,1fr);padding:6px;-moz-column-gap:1.5px;column-gap:1.5px;row-gap:1.5px}.ProfileCover_cover_2KkPo{background-color:#fff;border-top:1px solid #e5e3e3}.ProfileCover_single-section_t3wLz{display:block}.ProfileCover_container_4rCfy{position:relative;padding-bottom:100%;overflow:hidden;cursor:pointer}.ProfileCover_container_4rCfy:last-child{border-radius:0 6px 6px 0}.ProfileCover_container_4rCfy:first-child{border-radius:6px 0 0 6px}.ProfileCover_single-container_Wovnc{padding-bottom:146px;border-radius:6px!important}.ProfileCover_image_2ngTl{position:absolute;top:50%;left:50%;width:100%;height:100%;transform:translate(-50%,-50%)}.ProfileCover_image_2ngTl .ProfileCover_edit_1ZCcG{position:absolute;right:4px;bottom:4px}", ""]);
// Exports
exports.locals = {
	"section": "ProfileCover_section_1AMpQ",
	"cover": "ProfileCover_cover_2KkPo",
	"single-section": "ProfileCover_single-section_t3wLz",
	"container": "ProfileCover_container_4rCfy",
	"single-container": "ProfileCover_single-container_Wovnc",
	"image": "ProfileCover_image_2ngTl",
	"edit": "ProfileCover_edit_1ZCcG"
};
module.exports = exports;
