/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'feed': {
    width: 18,
    height: 15,
    viewBox: '0 0 18 15',
    data: '<path pid="0" d="M4 0H2a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V2a2 2 0 00-2-2zm1 4c0 .551-.449 1-1 1H2c-.551 0-1-.449-1-1V2c0-.551.449-1 1-1h2c.551 0 1 .449 1 1v2zM4 9H2a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2zm1 4c0 .551-.449 1-1 1H2c-.551 0-1-.449-1-1v-2c0-.551.449-1 1-1h2c.551 0 1 .449 1 1v2zM8 1.5a.5.5 0 01.5-.5h9a.5.5 0 110 1h-9a.5.5 0 01-.5-.5zm0 2a.5.5 0 01.5-.5h6a.5.5 0 110 1h-6a.5.5 0 01-.5-.5zm10 7a.5.5 0 01-.5.5h-9a.5.5 0 010-1h9a.5.5 0 01.5.5zm-3 2a.5.5 0 01-.5.5h-6a.5.5 0 010-1h6a.5.5 0 01.5.5z"/>'
  }
})
