/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'eye-hide': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M3.7 3.7a1.25 1.25 0 00-.122 1.627l.122.14 6.723 6.725a16.643 16.643 0 00-6.591 9.583 1.25 1.25 0 002.425.607 14.15 14.15 0 015.966-8.39l3.017 3.016a6.666 6.666 0 009.427 9.425l9.865 9.867a1.25 1.25 0 001.888-1.628l-.122-.14-10.188-10.19.002-.004-2-1.996-4.784-4.784h.004l-4.8-4.795.001-.003-1.888-1.883L5.467 3.7a1.25 1.25 0 00-1.767 0zm13.307 15.075l5.891 5.893a4.167 4.167 0 01-5.891-5.893zM20 9.167a16.66 16.66 0 00-4.815.708l2.062 2.06a14.172 14.172 0 0116.498 10.453 1.25 1.25 0 002.425-.605A16.672 16.672 0 0020 9.167zm.325 5.85l6.335 6.333a6.672 6.672 0 00-6.335-6.333z" _fill="#fff" fill-opacity=".6"/>'
  }
})
