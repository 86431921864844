/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share': {
    width: 14,
    height: 12,
    viewBox: '0 0 14 12',
    data: '<path pid="0" d="M14 0L7.34 12 6.266 5.112 0 1.657 14 0z"/>'
  }
})
