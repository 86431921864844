// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BlockingDrawer_container_kSNjc{position:relative;display:flex;flex-direction:column;justify-content:flex-end;height:460px;background:#fff}.BlockingDrawer_container_kSNjc .BlockingDrawer_background_aayeo{position:absolute;top:0;align-self:center;width:100%;height:445px;background-repeat:no-repeat;background-position:50%;background-size:cover}@media screen and (max-height:850px){.BlockingDrawer_container_kSNjc .BlockingDrawer_background_aayeo{background-size:contain}}.BlockingDrawer_container_kSNjc .BlockingDrawer_content_2kSB5{padding:15px}.BlockingDrawer_container_kSNjc .BlockingDrawer_content_2kSB5 .BlockingDrawer_title_3N3DO{margin-bottom:21px;font-weight:500;font-size:18px;line-height:18px;text-align:center}.BlockingDrawer_container_kSNjc .BlockingDrawer_content_2kSB5 .BlockingDrawer_text_2bIUi{max-width:200px;margin:0 auto 15px;font-size:12px;line-height:18px;text-align:center}", ""]);
// Exports
exports.locals = {
	"container": "BlockingDrawer_container_kSNjc",
	"background": "BlockingDrawer_background_aayeo",
	"content": "BlockingDrawer_content_2kSB5",
	"title": "BlockingDrawer_title_3N3DO",
	"text": "BlockingDrawer_text_2bIUi"
};
module.exports = exports;
