<template>
  <v-input :disabled="disabled" :error="error" :error-to-show="errorToShow" :title="title">
    <input
      ref="input"
      :class="[
        $style.input,
        {
          [$style.search]: hasIconSlot || icon,
          [$style.error]: errorToShow,
          [$style.disabled]: disabled,
          [$style.shadow]: shadow,
        },
      ]"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      :style="{
        borderRadius: radius,
        padding: padding,
      }"
      type="text"
      @input="onInput"
      @keyup.enter="onEnter"
      @blur="fireBlur"
      :required="required"
    />
    <icon v-if="icon" :class="$style.icon" :name="icon" />
    <div v-if="hasIconSlot" :class="$style.icon">
      <slot name="icon" />
    </div>
  </v-input>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import validate from '@utils/validate'

import VInput from '@elements/inputs/VInput.vue'

export default Vue.extend({
  name: 'TextInput',
  components: { VInput },
  props: {
    title: String,
    value: String,
    placeholder: String,
    icon: String,
    rules: {
      default: () => [],
      type: Array,
    },
    disabled: Boolean,
    radius: String,
    shadow: Boolean,
    padding: String,
    autofocus: {
      default: false,
      type: Boolean,
    },
    required: Boolean,
  },
  data() {
    return {
      error: '',
      errorToShow: '',
    }
  },
  computed: {
    ...mapGetters('Page', ['isIOS']),
    hasIconSlot() {
      return !!this.$slots.icon
    },
  },
  watch: {
    error(val) {
      this.$emit('error', !!val)
    },
    value() {
      this.validate(this.value)
    },
  },
  async mounted() {
    if (this.autofocus) {
      this.$refs.input.focus()
      if (this.isIOS) {
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight / 3)
        }, 300)
      }
    }
    this.error = await validate(this.rules, this.value)
  },
  methods: {
    async validate(value) {
      this.error = await validate(this.rules, value)
      this.errorToShow = this.error
      this.fireError()
    },
    onInput({ target: { value } }) {
      this.fireInput(value)
      if (value) {
        this.validate(value)
      } else {
        this.suppressError()
      }
    },
    onEnter({ target: { value } }) {
      this.$emit('enter', value)
    },
    suppressError() {
      this.error = ''
      this.fireError()
    },
    fireError() {
      this.$emit('error', !!this.error)
    },
    fireInput(value) {
      this.$emit('input', value)
    },
    fireBlur() {
      this.$emit('blur')
    },
  },
})
</script>
<style lang="scss" module>
.input {
  position: relative;
  width: 100%;
  padding: 11px;

  @include input();

  &:disabled {
    cursor: not-allowed;
  }

  &.error {
    border: 1px solid $error;
  }

  &.search {
    padding: 11px 11px 11px 31px;
  }

  &.shadow {
    background: $shadow;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 11px;
  width: 14px;
  height: 14px;
  transform: translateY(-50%);
}
</style>
