import { Claims } from '@config/claims'

export default {
  claims: {
    [Claims.DONT_LIKE]: 'Мне это не нравится',
    [Claims.SPAM]: 'Это спам',
    [Claims.SEXUAL]: 'Изображение обнаженного тела или действий сексуального характера',
    [Claims.HATE]: 'Враждебные высказывания или символы',
    [Claims.FALSE_INFORMATION]: 'Ложная информация',
    [Claims.BULLYING]: 'Травля или преследование',
    [Claims.VIOLENCE]: 'Насилие или опасные организации',
    [Claims.SCAM]: 'Мошенничество или обман',
    [Claims.INTELLECTUAL]: 'Нарушение прав на интеллектуальную собственность',
    [Claims.SALE_ILLEGAL]: 'Продажа незаконных или подлежащих правовому регулированию товаров',
    [Claims.SUICIDE]: 'Самоубийство или нанесение себе увечий',
    [Claims.EATING_DISORDERS]: 'Расстройства пищевого поведения',
    [Claims.SUICIDE_OR_EATING_DISORDERS]:
      'Самоубийство, нанесение себе увечий или расстройства пищевого поведения',
    [Claims.SOMETHING_ELSE]: 'Другое',
  },
}
