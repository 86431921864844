/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-sm': {
    width: 11,
    height: 7,
    viewBox: '0 0 11 7',
    data: '<path pid="0" d="M9.866.838l-5.6 6c-.09.1-.22.16-.36.16-.14 0-.27-.06-.37-.16l-3.4-3.6c-.19-.2-.18-.52.02-.71.2-.19.52-.18.71.02l3.04 3.22 5.24-5.61c.19-.2.51-.21.71-.02.19.18.2.5.01.7z"/>'
  }
})
