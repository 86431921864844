import { replaceEntityMediaObject } from '@helpers/media-objects'
import { MediaObject } from '@services/media/types'
import { Chat } from '@common-types/chat'
import { Tab } from '../constants'
import { Mutations, Content, MutationParams } from '../types'
import { increasePage, postTabs, resetPages, tabs } from '../services'
import { Post } from '../../../common-types/post'

type Params<T extends keyof MutationParams> = Pick<MutationParams, T>

const mutations: Mutations = {
  setProfileContent(state, { content, tab }: Params<'content' | 'tab'>) {
    state[tab] = content
    state.pages = { ...state.pages, [tab]: 2 }
  },
  pushProfileContent(state, { content, tab }: Params<'content' | 'tab'>) {
    state[tab] = [...state[tab], ...content] as Content
    state.pages = increasePage({ pages: state.pages, tab })
  },
  setProfileContentItem(state, { contentItem, tab }: Params<'contentItem' | 'tab'>) {
    state[tab] = state[tab].map((p) => (p.id === contentItem.id ? contentItem : p)) as Content
  },
  addProfileContentItem(state, { contentItem, tab }: Params<'contentItem' | 'tab'>) {
    state[tab] = [contentItem, ...state[tab]] as Content
  },
  setProfileContentItemProperties(
    state,
    { id, tab, ...properties }: Params<'id' | 'tab'> & Partial<Post> & Partial<Chat>
  ) {
    state[tab] = state[tab].map((p) => (p.id === id ? { ...p, ...properties } : p)) as Content
  },
  setProfilePost(state, post: Post) {
    postTabs.forEach((tab) => {
      state[tab] = state[tab].map((p) => (p.id === post.id ? post : p))
    })
  },
  deleteProfilePost(state, postId: Post['id']) {
    postTabs.forEach((tab) => {
      state[tab] = state[tab].filter(({ id }) => id !== postId)
    })
  },
  deleteProfileChat(state, chatId: Chat['id']) {
    state[Tab.CHATS] = state[Tab.CHATS].filter(({ id }) => id !== chatId)
  },
  replaceProfileChat(state, chat: Chat) {
    state[Tab.CHATS] = state[Tab.CHATS].map((item) => (item.id === chat.id ? chat : item))
  },
  resetProfileContent(state) {
    tabs.forEach((tab) => {
      state[tab] = []
    })
    state.pages = resetPages()
  },
  setProfileTariffs(state, tariffs) {
    state.tariffs = tariffs
  },
  resetProfileTariffs(state) {
    state.tariffs = null
  },

  replaceProfilePostsMediaObject(state, mediaObject: MediaObject) {
    postTabs.forEach((tab) => {
      state[tab] = state[tab].map((post) => replaceEntityMediaObject(post, mediaObject))
    })
  },
  resetProfileClientApp(state) {
    state.clientApp = null
  },
  setProfileClientApp(state, data) {
    state.clientApp = { ...state.clientApp, ...data }
  },
  setAppSupportChat(state, { appId, appSupportChat }) {
    state.appSupportChats = {
      ...state.appSupportChats,
      [appId]: appSupportChat,
    }
  },
  setIsClientEditingError(state, isClientEditingError) {
    state.isClientEditingError = isClientEditingError
  },
}

export default mutations
