import { Claims } from '@config/claims'

export const ProfileClaims = [
  Claims.SPAM,
  Claims.DONT_LIKE,
  Claims.SUICIDE_OR_EATING_DISORDERS,
  Claims.SALE_ILLEGAL,
  Claims.SEXUAL,
  Claims.HATE,
  Claims.VIOLENCE,
  Claims.BULLYING,
  Claims.INTELLECTUAL,
  Claims.SCAM,
  Claims.FALSE_INFORMATION,
]

export const PostClaims = [
  Claims.DONT_LIKE,
  Claims.SPAM,
  Claims.SEXUAL,
  Claims.SCAM,
  Claims.HATE,
  Claims.FALSE_INFORMATION,
  Claims.BULLYING,
  Claims.VIOLENCE,
  Claims.INTELLECTUAL,
  Claims.SALE_ILLEGAL,
  Claims.SUICIDE,
  Claims.EATING_DISORDERS,
  Claims.SOMETHING_ELSE,
]

export const CommentClaims = [
  Claims.SPAM,
  Claims.SEXUAL,
  Claims.HATE,
  Claims.VIOLENCE,
  Claims.SALE_ILLEGAL,
  Claims.BULLYING,
  Claims.INTELLECTUAL,
  Claims.FALSE_INFORMATION,
  Claims.SUICIDE_OR_EATING_DISORDERS,
  Claims.DONT_LIKE,
  Claims.SOMETHING_ELSE,
]

export default {}
