/* eslint-disable camelcase */
import { Getters } from './types'

const getters: Getters = {
  client: (state) => state.client,
  clientAvatar: (state) => (type: 'content_url' | 'preview_url') =>
    state.client?.avatar ? state.client?.avatar[type] : '',
  clientId: (state) => state.client?.id ?? null,
  clientEmail: (state) => state.client?.email ?? null,
  clientName: (state) => state.client?.name ?? null,
  clientLoginToken: (state) => state.loginToken,
  isClientOwner: (state, g, rs, rootGetters) =>
    state.client?.id === rootGetters['App/appClientId'](),
  isClientVerified: (state) => state.client?.verification_id ?? false,
  clientFollowCount: (state) => state.client?.app_subscriptions_count ?? 0,
  clientBot: (state) => state.clientBot,
  hasClientBotLimit: (s, getters) => !!getters.clientBot?.bot_limit,
  clientBotWelcomeLogo: (s, getters) => getters.clientBot?.owner_app?.empty_bot_chat_logo,
  clientBotWelcomeMessage: (s, getters) => getters.clientBot?.owner_app?.empty_bot_chat_message,
  hasSubscriptions: (state) => state.hasSubscriptions,
  initPromotionalId: (state) => state.initPromotionalId,
  gptVersion: (state) => state.gptVersion,
}

export default getters
