/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shop-2': {
    width: 21,
    height: 19,
    viewBox: '0 0 21 19',
    data: '<g filter="url(#filter0_d_1002_1)"><path pid="0" d="M19.802 5.402A1 1 0 0019 5h-5V4c0-2.206-1.794-4-4-4S6 1.794 6 4v1H1a1 1 0 00-.958 1.285l3.272 11a1 1 0 00.958.715h11.455a1 1 0 00.959-.715l3.272-11a.998.998 0 00-.156-.883zM10 2c1.102 0 2 .897 2 2v1H8V4c0-1.103.897-2 2-2zm4.981 14H5.02L2.34 7H6v1a1 1 0 102 0V7h4v1a1 1 0 102 0V7h3.66l-2.679 9z" _fill="#fff"/></g><defs><filter id="filter0_d_1002_1" x="0" y="0" width="21" height="19" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dx="1" dy="1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1002_1"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1002_1" result="shape"/></filter></defs>'
  }
})
