// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileBlocking_profileBlock_1tPqu{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:100%;flex:1}.ProfileBlocking_profileBlock_1tPqu .ProfileBlocking_icon_3Ov_3{margin-bottom:9px}.ProfileBlocking_profileBlock_1tPqu .ProfileBlocking_desc_1dS6W{color:#e5e3e3;font-weight:500;font-size:24px;font-family:Roboto,sans-serif;font-style:normal;line-height:28px;text-align:center}", ""]);
// Exports
exports.locals = {
	"profileBlock": "ProfileBlocking_profileBlock_1tPqu",
	"icon": "ProfileBlocking_icon_3Ov_3",
	"desc": "ProfileBlocking_desc_1dS6W"
};
module.exports = exports;
