// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VMain_main_1xqno{position:relative;width:100%;height:100%;overflow-x:hidden;overflow-y:auto}.VMain_main_1xqno.VMain_noScroll_2Sy65{overflow-y:hidden}", ""]);
// Exports
exports.locals = {
	"main": "VMain_main_1xqno",
	"noScroll": "VMain_noScroll_2Sy65"
};
module.exports = exports;
