import { mapGetters } from 'vuex'
import { ClientType } from '@services/client/types'
import store from '@store/index'
import i18n from '@plugins/i18n'
import { ChatType } from '@common-types/chat'
import { AvatarUrlGettersMap, TitleGettersMap } from '../types'
import { getInterlocutor } from './interlocutor'

const Mapper = {
  $store: store,
  ...mapGetters('App', ['appSupportChatName', 'appSupportChatAvatar', 'appName', 'appAvatar']),
  ...mapGetters('Client', ['clientId', 'isClientOwner']),
}

export const titleGettersMap: TitleGettersMap = {
  default: (chat) => chat.name ?? '',
  [ChatType.SUPPORT_CHAT]: () => `${i18n.t('chat.support')}`,
  [ChatType.APP_SUPPORT_CHAT]: (chat) => {
    const chatAppName = Mapper.appSupportChatName()(chat.app)
    const appName = Mapper.appName()(chat.app)
    const isClientOwner = Mapper.isClientOwner()
    const nameForClient = chatAppName ?? appName ?? chat.name
    const isCreator = chat.client?.id === Mapper.clientId()
    const isTemplateChat = 'temp' in chat
    const alterChatNameForOwner = isTemplateChat
      ? nameForClient
      : titleGettersMap[ChatType.PERSONAL_CHAT]?.(chat)
    const nameForOwner = isCreator ? nameForClient : alterChatNameForOwner ?? chatAppName ?? ''
    return isClientOwner ? nameForOwner : nameForClient
  },
  [ChatType.PERSONAL_CHAT]: (chat) => {
    const interlocutor = getInterlocutor(chat)
    const isChatGPTBot = interlocutor?.type === ClientType.BOT
    const commonTitle = isChatGPTBot ? chat.name ?? '' : interlocutor?.name ?? chat.name ?? ''
    return chat?.chat_gpt_system_prompt?.name ?? commonTitle
  },
}

export const avatarUrlGettersMap: AvatarUrlGettersMap = {
  default: (chat) => {
    const { preview_url, content_url } = chat.image ?? {}
    return preview_url ?? content_url ?? ''
  },
  [ChatType.APP_SUPPORT_CHAT]: (chat) => {
    const chatAppAvatar = Mapper.appSupportChatAvatar()(chat.app)
    const appAvatar = Mapper.appAvatar()(chat.app)
    const isClientOwner = Mapper.isClientOwner()
    const { preview_url, content_url } = chatAppAvatar ?? appAvatar ?? chat.image
    const clientAvatarUrl = preview_url ?? content_url ?? ''
    const isCreator = chat.client?.id === Mapper.clientId()
    const isTemplateChat = 'temp' in chat
    const ownerAvatarUrl = isTemplateChat
      ? clientAvatarUrl
      : avatarUrlGettersMap[ChatType.PERSONAL_CHAT]?.(chat) ?? ''
    return isClientOwner && !isCreator ? ownerAvatarUrl : clientAvatarUrl
  },
  [ChatType.PERSONAL_CHAT]: (chat) => {
    const interlocutor = getInterlocutor(chat)
    const isPromptChat = !!chat?.chat_gpt_system_prompt
    const avatar = isPromptChat ? chat?.chat_gpt_system_prompt?.image : interlocutor?.avatar
    const { preview_url, content_url } = avatar ?? {}
    return preview_url ?? content_url ?? ''
  },
}

export default {
  titleGettersMap,
  avatarUrlGettersMap,
}
