/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { DataType, GetParams } from './types'

export default {
  getVerifications: async (params: GetParams = null): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosJWT.get('client_verifications', {
      params,
    })
    return data
  },
  postVerification: async (verification: DataType): Promise<ResType<DataType>> => {
    const { access_token, data }: ResType<DataType> = await axiosJWT.post(
      'client_verifications',
      verification
    )
    return { access_token, data }
  },
  putVerification: async (id: number, verification: DataType): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.put(
      `client_verifications/${id}`,
      verification
    )
    return data
  },
}
