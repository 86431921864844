// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VUserPreview_userPreview_1mYty{display:flex;align-items:center}.VUserPreview_userPreview_1mYty .VUserPreview_desc_3oPpn{display:flex;flex-direction:column;gap:9px;justify-content:center;margin-left:9px}.VUserPreview_userPreview_1mYty .VUserPreview_desc_3oPpn .VUserPreview_title_2NJ9K{font-weight:500;font-size:15px}.VUserPreview_userPreview_1mYty .VUserPreview_desc_3oPpn .VUserPreview_subtitle_TOGhm{color:#a09f9f;font-size:12px}", ""]);
// Exports
exports.locals = {
	"userPreview": "VUserPreview_userPreview_1mYty",
	"desc": "VUserPreview_desc_3oPpn",
	"title": "VUserPreview_title_2NJ9K",
	"subtitle": "VUserPreview_subtitle_TOGhm"
};
module.exports = exports;
