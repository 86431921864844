// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatInput_container_wbLlP{position:relative;width:100%;height:100%}.ChatInput_container_wbLlP .ChatInput_reference_2eukb{color:var(--supster-secondary)}.ChatInput_content_2gqH7{display:flex;align-items:center;padding:12px;background:#fff;box-shadow:inset 0 1px 0 #e5e3e3}.ChatInput_content_2gqH7 .ChatInput_file_1sujU{flex-basis:42px}.ChatInput_content_2gqH7 .ChatInput_input_1tXPz{display:flex;flex-grow:1;align-items:center}.ChatInput_button_qogi6{align-self:flex-end;width:42px;height:42px}", ""]);
// Exports
exports.locals = {
	"container": "ChatInput_container_wbLlP",
	"reference": "ChatInput_reference_2eukb",
	"content": "ChatInput_content_2gqH7",
	"file": "ChatInput_file_1sujU",
	"input": "ChatInput_input_1tXPz",
	"button": "ChatInput_button_qogi6"
};
module.exports = exports;
