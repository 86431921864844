import { Mutations } from './types'

const mutations: Mutations = {
  setUser(state, user) {
    state.user = user ? { ...state.user, ...user } : null
  },
  setUserEmail(state, email) {
    state.user = state.user ? { ...state.user, email } : null
  },
}

export default mutations
