/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chats': {
    width: 18,
    height: 19,
    viewBox: '0 0 18 19',
    data: '<path pid="0" d="M3 7h10v1H3V7zm0 3h8V9H3v1zm0-4h12V5H3v1zm15-4v11a2 2 0 01-2 2v4l-6-4H2a2 2 0 01-2-2V2a2 2 0 012-2h14a2 2 0 012 2zm-1 0c0-.551-.449-1-1-1H2c-.551 0-1 .449-1 1v11c0 .551.449 1 1 1h8.303l.252.168L15 17.131V14h1c.551 0 1-.449 1-1V2z"/>'
  }
})
