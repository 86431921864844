/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { Block, PostParams } from './types'

export default {
  postBlock: async ({ app_id, client_id }: PostParams): Promise<ResType<Block>> => {
    const response: ResType<Block> = await axiosJWT.post('blocks', { app_id, client_id })
    return response
  },
  deleteBlock: async (client_id: number): Promise<ResType<Block>> => {
    const response = await axiosJWT.delete(`blocks/${client_id}`)
    return response
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBlocks: async (): Promise<ResType<Block[]>> => {
    return axiosJWT.get(`blocks`)
  },
}
