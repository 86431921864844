<template>
  <div id="supster-app-container" :class="$style.page" :style="cssVariables">
    <AppLeftSection />
    <router-view v-if="isInstallPage" />
    <div
      v-else
      :class="[
        $style.container,
        !isMobile && $style.desktop,
        isMobile && $style.mobile,
        'lightgallery-container',
      ]"
    >
      <transition v-if="!isLoggedIn" name="page" mode="out-in" appear>
        <router-view />
      </transition>
      <CentrifugeProvider v-else>
        <div
          :class="[
            $style.content,
            pageIsMenu && $style.open,
            !pageIsHeader && $style.noHeader,
            !pageIsFooter && $style.noFooter,
            !pageIsHeader && !pageIsFooter && $style.fullHeight,
          ]"
        >
          <HeaderWrapper v-if="pageIsHeader" />

          <VMain />
          <VFooter v-if="pageIsFooter" />
          <VMenu v-if="!isDesktop" />
          <CartDrawer />
          <NoNameDrawer />
          <CommentEditDrawer />
          <CommentReportDrawer />
          <AuthDrawer />
          <AdminNoVerified />
          <ClaimAcceptedDrawer />
          <AccessDeniedDrawer />
          <ClaimConfirmDrawer />
          <BlockingDrawer />
          <PermissionDrawer />
          <Notifications />
          <ChatNameEditDrawer />
          <SubscriptionExpiredDrawer />
          <LocalesDrawer />
        </div>
      </CentrifugeProvider>
      <Toasts />
    </div>
    <AppRightSection />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AdminNoVerified from '@modals/list/AdminNoVerified.vue'
import CentrifugeProvider from '@providers/CentrifugeProvider'
import HeaderWrapper from '@wrappers/HeaderWrapper.vue'
import VMain from '@layouts/VMain.vue'
import VFooter from '@layouts/VFooter.vue'
import VMenu from '@layouts/menu/VMenu.vue'
import CartDrawer from '@drawers/list/CartDrawer.vue'
import CommentEditDrawer from '@drawers/list/CommentEditDrawer.vue'
import CommentReportDrawer from '@drawers/list/CommentReportDrawer.vue'
import ClaimAcceptedDrawer from '@components/drawers/list/ClaimAcceptedDrawer.vue'
import ClaimConfirmDrawer from '@components/drawers/list/ClaimConfirmDrawer.vue'
import ChatNameEditDrawer from '@components/drawers/list/ChatNameEditDrawer.vue'
import BlockingDrawer from '@drawers/list/BlockingDrawer.vue'
import PermissionDrawer from '@drawers/list/PermissionDrawer.vue'
import NoNameDrawer from '@drawers/list/NoNameDrawer.vue'
import AuthDrawer from '@drawers/list/auth/AuthDrawer.vue'

import HitApi from '@services/hit'
import Toasts from '@/modules/toasts/Toasts.vue'
import Notifications from '@modules/notifications/Notifications.vue'
import AccessDeniedDrawer from '@drawers/list/AccessDeniedDrawer.vue'
import { getShade, Shades } from '@helpers/colors'
import './assets/style/app.scss'
import AppLeftSection from '@views/AppLeftSection.vue'
import AppRightSection from '@modules/app-right-section/AppRightSection.vue'
import SubscriptionExpiredDrawer from '@drawers/list/SubscriptionExpiredDrawer.vue'
import LocalesDrawer from '@drawers/list/LocalesDrawer.vue'

export default Vue.extend({
  name: 'App',
  components: {
    LocalesDrawer,
    SubscriptionExpiredDrawer,
    AppRightSection,
    AppLeftSection,
    AccessDeniedDrawer,
    Notifications,
    AdminNoVerified,
    BlockingDrawer,
    CartDrawer,
    CentrifugeProvider,
    ClaimAcceptedDrawer,
    ClaimConfirmDrawer,
    ChatNameEditDrawer,
    CommentEditDrawer,
    CommentReportDrawer,
    HeaderWrapper,
    AuthDrawer,
    NoNameDrawer,
    PermissionDrawer,
    Toasts,
    VFooter,
    VMain,
    VMenu,
  },
  metaInfo() {
    return {
      title: this.appUserName(),
    }
  },
  computed: {
    ...mapGetters('Me', ['isLoggedIn']),
    ...mapGetters('App', [
      'appId',
      'appColor',
      'appName',
      'appUserName',
      'isDarkMode',
      'isTooLightColor',
      'isTooDarkColor',
      'isMyAppGPT',
      'appDescription',
    ]),
    ...mapGetters('Page', [
      'pageIsHeader',
      'pageIsFooter',
      'pageIsMenu',
      'isPWAInstalled',
      'pageTitle',
      'isDesktop',
    ]),
    ...mapGetters('Client', ['hasSubscriptions']),
    ...mapGetters('User', ['userEmail']),
    needAuth() {
      return this.hasSubscriptions && !this.userEmail
    },
    cssVariables() {
      const primary = this.appColor()
      return {
        '--supster-primary': primary,
        '--supster-secondary': 'black',
        '--supster-main-background': this.isDarkMode ? 'black' : 'white',
        '--supster-main-text-color': this.isDarkMode ? 'white' : 'black',
        '--supster-primary-light-shade': getShade(primary, Shades.LIGHT),
        '--supster-primary-dark-shade': getShade(primary, Shades.DARK),
        '--supster-primary-gradient':
          'linear-gradient(180deg, var(--supster-primary-light-shade) 0%, var(--supster-primary-dark-shade) 100%)',
        '--supster-footer-background': this.isDarkMode ? 'white' : '#262626',
        '--supster-footer-gradient': this.isDarkMode
          ? 'linear-gradient(180deg, #F0F0F0 0%, rgba(240, 240, 240, 0) 52.6%)'
          : 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 50%)',
        '--supster-primary-dark-background': this.isTooDarkColor ? 'white' : primary,
        '--supster-primary-light-background': this.isTooLightColor ? 'black' : primary,
        '--supster-primary-red-dark':
          this.isTooDarkColor || this.isTooLightColor ? '#fb4e4e' : primary,
        '--supster-primary-light-background-adaptive': this.isTooLightColor
          ? getShade(primary, Shades.DARK)
          : primary,
        '--supster-primary-dark-text-color': this.isTooDarkColor
          ? primary
          : 'var(--supster-main-text-color)',
        '--supster-primary-light-text-color': this.isTooLightColor
          ? primary
          : 'var(--supster-main-text-color)',
        '--supster-primary-dark-gradient': this.isTooDarkColor
          ? 'white'
          : 'var(--supster-primary-gradient)',
        '--supster-primary-light-gradient': this.isTooLightColor
          ? 'black'
          : 'var(--supster-primary-gradient)',
      }
    },
    isMobile() {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    },
    isInstallPage() {
      return this.$route.name === 'Install'
    },
    isInstruction() {
      return this.isMobile && !this.isPWAInstalled
    },
  },
  watch: {
    '$route.fullPath': {
      handler() {
        const { name } = this.$route
        if (name && this.isLoggedIn && this.appId()) {
          HitApi.postHit(this.appId(), window.location.href)
        }
      },
      immediate: true,
    },
  },
  created() {
    this.init()
    this.setDrawer({
      name: 'auth',
      isVisible: this.needAuth,
      params: { afterPayment: true },
    })
    this.setPageIsOffline(!window.navigator?.onLine)
    window.addEventListener('offline', () => {
      this.setPageIsOffline(true)
    })
    window.addEventListener('online', () => {
      this.setPageIsOffline(false)
    })
    const themeColor = document.querySelector('meta[name="theme-color"]')
    if (themeColor) {
      themeColor.setAttribute('content', this.appColor())
    }
    document.title = this.appName().charAt(0).toUpperCase() + this.appName().slice(1)
  },

  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Page', ['setPageIsOffline', 'setIsDesktop']),
    ...mapActions('Prompts', ['getPrompts']),
    init() {
      this.onResize()
      window.addEventListener('resize', this.onResize)
      this.checkPrompts()
    },
    onResize(event) {
      const { innerWidth } = event?.target ?? window
      if (innerWidth <= 768 && this.isDesktop) {
        this.setIsDesktop(false)
      }
      if (innerWidth > 768 && !this.isDesktop) {
        this.setIsDesktop(true)
      }
    },
    checkPrompts() {
      this.getPrompts()
    },
  },
})
</script>
<style lang="scss" module>
.page {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  min-height: 300px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  color: $main-text-color;
  background-color: $primary;
  background-image: url('~@assets/images/svg/desktop-background.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.container {
  position: fixed !important;
  bottom: 0;
  left: 50%;
  z-index: 4;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);

  &.desktop {
    max-width: $widthDesktop;

    @media only screen and (max-width: $widthDesktop) {
      left: 0;
      transform: translateX(0);
    }
  }

  &.mobile {
    max-width: $width;

    @media only screen and (max-width: $width) {
      left: 0;
      transform: translateX(0);
    }
  }

  @media (min-width: $width) {
    height: calc(100% - 48px);
    overflow: hidden;
    border: 8px solid black;
    border-bottom: none;
    border-radius: 24px 24px 0 0;
  }
}

.content {
  top: 0;
  bottom: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
  justify-self: center;
  width: 100%;
  max-width: $width;
  height: 100%;
  min-height: unset;
  background-color: white;
  transition: all 0.7s;

  &.open {
    left: 0;
    transform: translateX(-270px);

    @media (min-width: $width) {
      transform: translateX(0);
    }
  }

  &.noHeader {
    grid-template-rows: 1fr auto;
  }

  &.noFooter {
    grid-template-rows: auto 1fr;
  }

  &.fullHeight {
    grid-template-rows: 1fr;
  }
}
</style>
