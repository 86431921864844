<template>
  <ul :class="$style.list">
    <li
      v-for="item in 5"
      :key="`star-${item}`"
      :class="[$style.item, $style[size]]"
      @click="onChange(item)"
    >
      <svg
        :class="[$style.icon, $style[color], { [$style.active]: value >= item }]"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <!-- eslint-disable -->
        <path
          d="M13.1421 11.1383L12.9659 11.3189L13.0065 11.568L13.903 17.0643L9.2419 14.4876L9 14.3539L8.7581 14.4876L4.09695 17.0643L4.99348 11.568L5.0341 11.3189L4.85788 11.1383L1.03742 7.22264L6.29433 6.41947L6.55807 6.37917L6.67143 6.13765L9 1.17674L11.3286 6.13765L11.4419 6.37917L11.7057 6.41947L16.9626 7.22264L13.1421 11.1383Z"
        />
        <!-- eslint-enable -->
      </svg>
    </li>
  </ul>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VStarRating',
  props: {
    value: {
      default: 0,
      validator: (v) => v >= 0 && v <= 5,
      type: Number,
    },
    color: {
      default: 'default',
      validator: (v) => ['default', 'primary'].includes(v),
      type: String,
    },
    size: {
      default: 'md',
      validator: (v) => ['sm', 'md', 'lg', 'xl'].includes(v),
      type: String,
    },
  },
  methods: {
    onChange(item) {
      this.$emit('input', item === this.value ? --item : item)
    },
  },
})
</script>
<style lang="scss" module>
.list {
  display: grid;
  grid-template-columns: repeat(5, min-content);
  column-gap: 3px;
}

.item {
  display: grid;
  align-self: center;
  cursor: pointer;

  &.sm {
    width: 10px;
    height: 10px;
  }

  &.md {
    width: 18px;
    height: 18px;
  }

  &.lg {
    width: 26px;
    height: 26px;
  }

  &.xl {
    width: 34px;
    height: 34px;
  }
}

.icon {
  width: 100%;
  height: 100%;
  transition: stroke $transition, fill $transition;
  stroke: $secondary-light;

  &.default {
    stroke: $secondary-light;
  }

  &.primary {
    stroke: $primary;
  }

  &.active {
    fill: $primary;
    stroke: $primary;
  }
}
</style>
