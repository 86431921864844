<template>
  <div
    :class="[
      $style.container,
      pageIsMenu && $style.containerMenu,
      active && $style.containerActive,
    ]"
  >
    <icon :name="icon" width="18" height="18" />
    <div :class="$style.label">
      {{ label }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'AppRightSectionAction',
  props: {
    icon: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('Page', ['pageIsMenu']),
  },
})
</script>

<style module lang="scss">
.container {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  padding: 5px 10px;
  border-radius: 5px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  &Menu {
    color: black;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &.active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
