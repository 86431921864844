<template>
  <div :class="$style.wrap">
    <div @click="$emit('toLoginForm', 'email')" :class="$style.button">
      {{ $t('login.loginOptions.email') }}
      <icon :class="$style.icon" name="back" width="6" height="12" />
    </div>
    <div @click="$emit('toLoginForm', 'phone')" :class="$style.button">
      {{ $t('login.loginOptions.phone') }}
      <icon :class="$style.icon" name="back" width="6" height="12" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'LoginOptions',
})
</script>

<style lang="scss" module>
.wrap {
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 18px 16px 15px;
  font-size: 15px;
  line-height: 14px;
  background: #f8f8f8;
  border: 1px solid #e5e3e3;
  cursor: pointer;
  &:first-child {
    margin-bottom: 8px;
  }
  &:last-child {
    margin-bottom: 15px;
  }
}

.icon {
  transform: rotate(180deg);
}
</style>
