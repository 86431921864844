/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'three-dots-loader': {
    width: 16,
    height: 16,
    viewBox: '0 0 100 50',
    data: '<circle pid="0" cx="20" cy="25" r="8"><animate attributeName="cy" dur="1s" values="10;35;10" repeatCount="indefinite" begin=".1"/></circle><circle pid="1" cx="50" cy="25" r="8"><animate attributeName="cy" dur="1s" values="10;35;10" repeatCount="indefinite" begin=".2"/></circle><circle pid="2" cx="80" cy="25" r="8"><animate attributeName="cy" dur="1s" values="10;35;10" repeatCount="indefinite" begin=".3"/></circle>'
  }
})
