import i18n from '@plugins/i18n'
import PostApi from '@services/post'
import { Tab } from '@modules/profile/constants'
import { Actions } from './types'

const actions: Actions = {
  async getPosts({ dispatch, commit }, params) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      return await PostApi.getPosts(params)
    } catch (error) {
      dispatch(
        'notify',
        {
          type: 'error',
          title: `${i18n.t('errors.posts.caption')}`,
          text: `${i18n.t('errors.posts.descriptionLoad')}`,
        },
        { root: true }
      )
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async getPost({ dispatch, commit }, id) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const post = await PostApi.getPost(id)
      commit('setPost', post)
      return post
    } catch (error) {
      dispatch(
        'Error/notify',
        {
          type: 'error',
          title: `${i18n.t('errors.posts.caption')}`,
          text: `${i18n.t('errors.posts.descriptionLoad')}`,
        },
        { root: true }
      )
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async postPost({ dispatch, commit }, params) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const post = await PostApi.postPost(params)
      commit('Feed/setFeedNewPost', post, { root: true })
      commit(
        'Profile/addProfileContentItem',
        {
          contentItem: post,
          tab: Tab.IMAGES,
        },
        { root: true }
      )
      commit('App/setAppPostCount', 'increment', { root: true })
      return post
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async putPost({ dispatch, commit }, { id, params }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const post = await PostApi.putPost(id, params)
      commit('changePost', post)
      commit('Feed/setFeedPost', post, { root: true })
      commit('Profile/Images/setPost', post, { root: true })
      commit('Profile/Videos/setPost', post, { root: true })
      commit('Profile/Videos/Feed/setPost', post, { root: true })
      commit('Profile/Images/Feed/setPost', post, { root: true })
      commit('Search/Posts/setPost', post, { root: true })
      return post
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return error
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async deletePost({ dispatch, commit }, id): Promise<boolean> {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const success = await PostApi.deletePost(id)
      if (success) {
        commit('setPost', null)
        commit('Feed/deleteFeedPost', id, { root: true })
        commit('Profile/deleteProfilePost', id, { root: true })
        commit('Search/deleteSearchPost', id, { root: true })
        commit('App/setAppPostCount', 'decrement', { root: true })
      }
      return success
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      return false
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
  async setPostLike({ dispatch, commit }, { isLike, id }) {
    try {
      commit('Page/setPageLoading', false, { root: true })
      const post = isLike ? await PostApi.postPostLike(id) : await PostApi.postPostUnlike(id)
      commit('changePost', post)
      commit('Feed/setFeedPost', post, { root: true })
      commit('Profile/Images/setPost', post, { root: true })
      commit('Profile/Videos/setPost', post, { root: true })
      commit('Profile/Videos/Feed/setPost', post, { root: true })
      commit('Profile/Images/Feed/setPost', post, { root: true })
      commit('Search/Posts/setPost', post, { root: true })
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
