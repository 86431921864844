/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-link': {
    width: 800,
    height: 800,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 10l1.393 1.435L9 3.828V20h2V3.828l7.586 7.586L20 10 10 0 0 10" _fill="#000" fill-rule="evenodd"/>'
  }
})
