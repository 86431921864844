<template>
  <div :class="[$style.element, notSupported && $style.notSupported]">
    <div v-if="convertation === 0 && !src" :class="$style.convertation">
      <img
        :class="$style.convertationIcon"
        :src="require('@assets/images/svg/playButton.svg')"
        alt=""
        draggable="false"
      />
    </div>
    <video v-else-if="isVideo && !isIOS" :class="$style.content" :src="src" />
    <img v-else :class="$style.content" :src="src" alt="" />
    <div
      :class="[
        $style.cover,
        (isRemove || progress) && $style.coverBackground,
        error && $style.coverError,
      ]"
    >
      <transition name="slide-up" mode="out-in">
        <v-button
          v-if="error"
          :class="$style.button"
          :style="{ width: `${radius * 1.5}px`, height: `${radius * 1.5}px` }"
          @click="$emit('refresh', id)"
        >
          <icon name="refresh" :class="$style.icon" />
        </v-button>
        <progress-ring
          v-else-if="progress"
          :color="color"
          :class="$style.ring"
          :progress="progress"
          :stroke-width="strokeWidth"
          :radius="radius"
          :icon="progress === 100 ? 'check' : 'folder-arrow'"
        />
        <v-button
          v-else-if="isRemove"
          :class="$style.button"
          :style="{ width: `${radius * 1.5}px`, height: `${radius * 1.5}px` }"
          @click="$emit('remove', id)"
        >
          <icon name="close" :class="$style.icon" />
        </v-button>
      </transition>
      <div v-if="notSupported" :class="$style.filePreview">
        <icon name="file" width="45" height="45" />
        <p :class="$style.filePreviewName">
          {{ fileName }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ProgressRing from '@layouts/ProgressRing.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VCover',
  components: {
    VButton,
    ProgressRing,
  },
  props: {
    color: String,
    progress: {
      type: Number,
      default: 0,
    },
    fileName: {
      type: String,
      default: 'file',
    },
    mime: {
      type: String,
      default: 'image',
    },
    id: [String, Number],
    src: String,
    error: Boolean,
    strokeWidth: {
      type: [String, Number],
      default: 3,
    },
    radius: {
      type: [String, Number],
      default: 30,
    },
    isRemove: {
      type: Boolean,
      default: true,
    },
    convertation: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('Page', ['isIOS']),
    isVideo() {
      return !!this.mime?.includes('video')
    },
    notSupported() {
      return !!this.mime?.includes('x-ms')
    },
  },
})
</script>
<style lang="scss" module>
.element {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.notSupported {
  height: 138px;
}

.filePreview {
  display: flex;
  align-items: center;
  align-self: center;
  justify-self: center;

  &Name {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: background $transition ease-in;

  &Background {
    background-color: rgba($secondary-light, 0.7);
  }

  &Error {
    background-color: rgba($error, 0.7);
  }
}

.button {
  position: relative;
  align-self: center;
  justify-self: center;
}

.ring {
  align-self: center;
  justify-self: center;
  width: 60px;
  height: 60px;
}

.icon {
  width: 100%;
  height: 100%;
}

.convertation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $primary;

  &Icon {
    width: 20px;
    height: 20px;
  }
}
</style>
