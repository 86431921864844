// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatGptWelcomeRequest_request_353aI{display:grid;grid-template-columns:12px 1fr 6px;align-items:center;padding:9px 18px 9px 12px;gap:10px;background:#fff;border:1px solid var(--supster-primary-light-background);border-radius:6px;cursor:pointer}.ChatGptWelcomeRequest_request_353aI:hover{background:#f8f8f8}.ChatGptWelcomeRequest_request_353aI svg{fill:var(--supster-primary-light-background)}.ChatGptWelcomeRequest_request_353aI p{line-height:22px;font-size:14px}", ""]);
// Exports
exports.locals = {
	"request": "ChatGptWelcomeRequest_request_353aI"
};
module.exports = exports;
