// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileInfo_section_y4bUn{display:grid;align-self:flex-start;row-gap:12px}.ProfileInfo_placeholder_1tcMm{opacity:.5}.ProfileInfo_profile_A4gUB span{color:#a09f9f}.ProfileInfo_profile_A4gUB svg{fill:#a09f9f}.ProfileInfo_content_vo7iq{display:grid;grid-auto-columns:max-content;grid-auto-flow:column;grid-gap:12px;color:#000}.ProfileInfo_content_vo7iq.ProfileInfo_edit_1JpZD,.ProfileInfo_text_2I90Q{color:#737373}.ProfileInfo_text_2I90Q{display:flex;flex-direction:column-reverse;align-items:center;font-weight:400;font-size:12px;line-height:12px}.ProfileInfo_text_2I90Q span{color:#000;font-weight:500}.ProfileInfo_text_2I90Q.ProfileInfo_edit_1JpZD,.ProfileInfo_text_2I90Q.ProfileInfo_edit_1JpZD span{color:#a09f9f}.ProfileInfo_textarea_16gHr textarea{width:90%;height:62px;font-weight:400;font-size:12px;line-height:16px}.ProfileInfo_buttons_1nGe8{position:absolute;right:20px;bottom:10px;display:grid;grid-template-rows:repeat(2,auto)}.ProfileInfo_buttons_1nGe8 button:nth-child(2){margin-top:5px}", ""]);
// Exports
exports.locals = {
	"section": "ProfileInfo_section_y4bUn",
	"placeholder": "ProfileInfo_placeholder_1tcMm",
	"profile": "ProfileInfo_profile_A4gUB",
	"content": "ProfileInfo_content_vo7iq",
	"edit": "ProfileInfo_edit_1JpZD",
	"text": "ProfileInfo_text_2I90Q",
	"textarea": "ProfileInfo_textarea_16gHr",
	"buttons": "ProfileInfo_buttons_1nGe8"
};
module.exports = exports;
