<template>
  <v-button :class="$style.container" size="md" @click="onShare">
    <icon name="share" width="14" height="12" />
    {{ $t('share') }}
  </v-button>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VPostShare',
  components: { VButton },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async onShare() {
      if (navigator.share) {
        await navigator.share({
          url: `https://${this.post.app.name}.${process.env.VUE_APP_BASE_DOMAIN}/${this.locale}/post/${this.post.id}?ref=${this.clientId}`,
        })
      } else {
        console.log('Метод navigator.share не поддерживается в этом браузере.')
      }
    },
  },
  computed: {
    ...mapGetters('Client', ['clientId']),
    ...mapGetters('Locale', ['locale']),
  },
})
</script>

<style lang="scss" module>
.container {
  border-radius: 100px !important;
}
</style>
