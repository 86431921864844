import Vue from 'vue'
import get from 'lodash/get'
import i18n from '@plugins/i18n'
import { Actions } from './types'

const actions: Actions = {
  notify(_, options) {
    Vue.notify(options)
  },
  notifyServerError({ state }, error) {
    const status = get(error, 'status', undefined)

    if (status && state.errors.includes(status)) {
      Vue.notify({
        type: 'error',
        title: `${i18n.t(`errors.api.${status}.title`)}`,
        text: `${i18n.t(`errors.api.${status}.text`)}`,
      })
    } else {
      Vue.notify({
        type: 'error',
        title: `${i18n.t('errors.title')}`,
        text: `${i18n.t('errors.message')}`,
      })
    }
  },
}

export default actions
