/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<g _fill="currentColor"><path pid="0" d="M7 5l5 4-5 4V5z"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M4 0a4 4 0 00-4 4v10a4 4 0 004 4h10a4 4 0 004-4V4a4 4 0 00-4-4H4zm10 1H4a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V4a3 3 0 00-3-3z"/></g>'
  }
})
