/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TranslateResult } from 'vue-i18n'
import i18n from '@plugins/i18n'

export interface RulesTypes {
  [key: string]: ((value: string) => false | TranslateResult)[]
}

export function vRequired() {
  return (value: string) => {
    if (!value) {
      return i18n.t('errors.validations.required')
    }
    return false
  }
}

export function vEmail() {
  return (value: string) => {
    const isValid = /([a-zA-Z0-9]+)@([a-zA-Z]+){2,}\.([a-zA-Z]+){2,}/.test(value)
    if (value && !isValid) {
      return i18n.t('errors.validations.email')
    }
    return false
  }
}

export function vPassword() {
  return (value: string) => {
    const isValid = {
      minimum8Chars: /^.{7,}$/.test(value),
      withoutSpaces: /^\S+$/.test(value),
      сyrillic: /[а-яА-ЯЁё-і]/.test(value),
    }

    if (!isValid.minimum8Chars) {
      return i18n.t('errors.validations.password.minChars', {
        characters: 7,
      })
    }
    if (!isValid.withoutSpaces) {
      return i18n.t('errors.validations.password.spaces')
    }
    if (isValid.сyrillic) {
      return i18n.t('errors.validations.password.cyrillic')
    }
    return false
  }
}

export function vName() {
  return (value: string) => {
    const isValid = /^[a-zA-ZZа-яА-Я][a-zA-Zа-яА-Я0-9_\-.]*(?:\s[a-zA-Zа-яА-Я0-9_\-.]+)*$/.test(
      value
    )
    if (value && !isValid) {
      return i18n.t('errors.validations.name')
    }
    return false
  }
}

export function vSurname() {
  return (value: string) => {
    const isValid = /^([а-яёА-ЯЁa-zA-Z'-.]+(?: [а-яёА-ЯЁa-zA-Z'-.]+)?)$/.test(value)
    if (value && !isValid) {
      return i18n.t('errors.validations.surname')
    }
    return false
  }
}

export function vLetters() {
  return (value: string) => {
    const isValid = /^[а-яёА-ЯЁa-zA-Z]+$/.test(value)
    if (value && !isValid) {
      return i18n.t('errors.validations.letters')
    }
    return false
  }
}

export function vTitle() {
  return (value: string) => {
    const isValid = /\S\W*/.test(value)
    if (value && !isValid) {
      return i18n.t('errors.validations.title')
    }
    return false
  }
}

export function vCreditCardName() {
  return (value: string) => {
    const isValid = /^[A-Z]+(?:\s[A-Z]+)?$/.test(value)
    if (value && !isValid) {
      return i18n.t('errors.validations.creditCardName')
    }
    return false
  }
}

export function vNumbers() {
  return (value: string) => {
    const isValid = /^[0-9]+$/.test(value)
    if (value && !isValid) {
      return i18n.t('errors.validations.numbers')
    }
    return false
  }
}

export function vLength(min: number, max: number) {
  return (value: string) => {
    if (!value) {
      return false
    }
    if (value.length < min) {
      return i18n.t('errors.validations.lengthLessThanMin', {
        min,
        characters: i18n.tc('characters', min),
      })
    }
    if (value.length > max) {
      return i18n.t('errors.validations.lengthGreaterThanMax', {
        max,
        characters: i18n.tc('characters', max),
      })
    }

    return false
  }
}
