import { Getters } from './types'

const getters: Getters = {
  following(state) {
    return state.following.data
  },
  followingLength(state) {
    return state.following.data?.length ?? null
  },
}

export default getters
