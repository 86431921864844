var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'lightgallery-element',
    _vm.$style.mediaWrapper,
    _vm.isHorizontal && _vm.$style.horizontal,
    _vm.isMonoHorizontal && _vm.$style.monoHorizontal,
    _vm.isChatGPTBot && _vm.$style.wide,
  ],attrs:{"href":_vm.isVideo ? null : _vm.contentUrl,"data-src":_vm.isVideo ? null : _vm.contentUrl,"data-video":_vm.isVideo ? _vm.dataVideo : null,"data-sub-html":" ","data-lg-size":_vm.lgSize,"data-poster":_vm.isVideo ? _vm.previewUrl : null}},[_c('v-media',{class:_vm.$style.media,attrs:{"media":_vm.media,"inner-classes":[_vm.$style.content],"is-observed":_vm.isVideo && _vm.index === 0,"no-play":_vm.index > 0}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }