/* eslint-disable camelcase */
import MeApi from '@services/me'
import { Actions } from './types'

const actions: Actions = {
  async getMe({ state, commit, dispatch }, params) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const { access_token, app, client, user } = await MeApi.getMe(params, !!state.accessToken)
      if (access_token) {
        commit('setAccessToken', access_token)
      }
      commit('App/setApp', app, { root: true })
      commit('Client/setClient', client, { root: true })
      commit('User/setUser', user, { root: true })
    } catch (error) {
      dispatch('Error/notifyServerError', error, { root: true })
      throw new Error()
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
