<template>
  <div :class="$style.form" color="primary" @submit="onSubmit">
    <div :class="$style.caption">
      {{ isPhoneType ? $t('login.phoneCaption') : $t('login.emailCaption') }}
    </div>
    <mask-input
      v-if="isPhoneType"
      v-model="formData.phoneNumber"
      :class="$style.input"
      type="tel"
      :rules="rules.phone"
      :title="$t('phone')"
      mode="inner-title"
      :mask="mask"
      masked
      :tokens="symbolsToken"
      placeholder="..."
    />
    <auth-input
      v-else
      @input="error = ''"
      :forseError="error"
      ref="email"
      v-model="formData.email"
      :class="$style.input"
      type="email"
      :title="$t('login.email')"
      mode="inner-title"
      placeholder="..."
      :rules="rules.email"
    />
    <div :class="$style.buttonContainer">
      <v-button
        size="xl"
        color="primary"
        :is-disabled="!isValid"
        :is-loading="isLoading"
        :class="$style.button"
        @click="onSubmit"
      >
        {{ $t('proceed') }}
      </v-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

import { mapGetters, mapMutations } from 'vuex'
import { vEmail, vRequired } from '@utils/validations'
import MaskInput from '@elements/inputs/list/MaskInput.vue'
import VButton from '@elements/VButton.vue'
import AuthInput from '@components/elements/inputs/list/AuthInput.vue'
import validate from '@utils/validate'

export default Vue.extend({
  name: 'RecoveryForm',
  components: {
    MaskInput,
    VButton,
    AuthInput,
  },
  props: {
    isLoading: Boolean,
    formType: String,
  },
  data() {
    return {
      formData: {
        email: '',
        phoneNumber: '',
      },
      error: '',
      symbolsToken: {
        '#': {
          pattern: /[0-9]/,
        },
      },
    }
  },
  computed: {
    ...mapGetters('Page', ['pageIsFooter']),
    rules() {
      return {
        phone: [vRequired()],
        email: [vRequired(), vEmail()],
      }
    },
    isEmailType() {
      return this.formType === 'email'
    },
    isPhoneType() {
      return this.formType === 'phone'
    },

    mask() {
      // return ['+# (###) ### ####']
      return ['+#######################']
    },
    isValid() {
      return !!this.formData.phoneNumber || !!this.formData.email
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Page', ['setPageIsFooter']),
    closeProfileDrawer() {
      this.setDrawer({ name: 'login', isVisible: false })
    },
    async onSubmit() {
      this.error = await validate(this.rules.email, this.formData.email)

      if (!this.error) {
        this.$emit('submit', this.formData)
      }
    },
  },
})
</script>

<style lang="scss" module>
.form {
  grid-auto-rows: min-content;
  grid-auto-flow: row;
}

.caption {
  padding: 15px;
  color: #737373;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  border-top: 1px solid #e5e3e3;
}

.inner {
  display: grid;
  row-gap: 9px;
}

.input {
  margin: 0;
  padding: 0;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

.buttonContainer {
  padding: 15px;
}

.button {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 9px;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
