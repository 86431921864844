import { Mutations } from '../types'

const mutations: Mutations = {
  setNotificationToken(state, token) {
    state.notificationsToken = token
  },
  setNotification(state, notification) {
    state.notification = notification
  },
  setNotificationMessage(state, message) {
    state.message = message
  },
}

export default mutations
