import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import store from '@store/index'

import pages, { routeNamesGPT, routeNamesTestCode6 } from '@router/routes/pages'
import subPages from '@router/routes/subPages'
import settings from '@router/routes/settings'
import Trans from '@plugins/i18n/translation'
import currencies from '@utils/currencies'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes: [
    {
      path: '/:locale?',
      component: { render: (h: any) => h('router-view') },
      beforeEnter: Trans.routeMiddleware,
      children: [
        ...pages,
        ...subPages,
        ...settings,
        {
          path: '',
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            return next({ name: 'Profile', params: { locale: Trans.currentLocale } })
          },
        },
      ],
    },
  ],
})

router.beforeEach(({ matched, name, path, params, fullPath }, _, next) => {
  const isSplitTestApp = store.getters['App/isSplitTestApp']
  const isCurrencyUSD = store.getters['App/appCurrencyCode']() === currencies.USD.code
  const locale = isCurrencyUSD && isSplitTestApp ? `en` : Trans.currentLocale

  if (!path.includes(`/ru`) && !path.includes(`/en`)) {
    next({ path: `/${locale}${fullPath}` })
  }

  const isRequiresAuth = matched.some((record) => record.meta?.requiresAuth)
  const isLoggedIn = store.getters['Me/isLoggedIn']
  const isMyAppGTP = store.getters['App/isMyAppGPT']
  const hasSubscriptions = store.getters['Client/hasSubscriptions']
  const initPromotionalId = store.getters['Client/initPromotionalId']
  const isSplitTestApp4 = store.getters['App/isSplitTestApp4']
  const isSplitTestApp5 = store.getters['App/isSplitTestApp5']
  const isSplitTestApp6 = store.getters['App/isSplitTestApp6']
  const isLandingMode = store.getters['App/isLandingMode']
  const isFirstLaunch = store.getters['Page/isFirstLaunch']
  const isRouteFromGPT = routeNamesGPT.includes(name as string)
  const isRouteTest6 = routeNamesTestCode6.includes(name as string)
  const firstEnterWithoutSubscriptionsRoutes = ['Documents', 'Promotional']

  const promoRoute = {
    name: 'Promotional',
    params: { id: initPromotionalId, locale },
    query: { gpt: 'true', firstenter: 'true' },
  }

  if (isLandingMode && isFirstLaunch) {
    return name === 'Introduction' ? next() : next({ name: 'Introduction', params: { step: '1' } })
  }

  if (name === 'Introduction') {
    return next(`/${locale}/`)
  }

  if (isMyAppGTP) {
    if (isSplitTestApp4 || isSplitTestApp5) {
      if (isRequiresAuth && !isLoggedIn) {
        return next({ name: 'Install' })
      }

      if (isLoggedIn && name === 'Install') {
        return next({ name: 'Chats', params: { locale: params.locale } })
      }

      return isRouteFromGPT
        ? next({})
        : next({ name: 'Chats', params: { locale: params.locale }, replace: true })
    }
    if (isSplitTestApp) {
      if (isRequiresAuth && !isLoggedIn) {
        return next({ name: 'Install' })
      }

      if (isLoggedIn && name === 'Install') {
        return hasSubscriptions
          ? next({ name: 'NewGptChat', params: { locale: params.locale } })
          : next(promoRoute)
      }

      return hasSubscriptions || firstEnterWithoutSubscriptionsRoutes.includes(name as string)
        ? isRouteFromGPT
          ? next({})
          : next({ name: 'NewGptChat', params: { locale: params.locale }, replace: true })
        : next(promoRoute)
    }

    if (isRequiresAuth && !isLoggedIn) {
      return next({ name: 'Install' })
    }

    if (isLoggedIn && name === 'Install') {
      return next({ name: 'NewGptChat', params: { locale: params.locale } })
    }

    return isRouteFromGPT
      ? next({})
      : next({ name: 'NewGptChat', params: { locale: params.locale }, replace: true })
  }

  if (isSplitTestApp6) {
    if (isRequiresAuth && !isLoggedIn) {
      return next({ name: 'Install' })
    }

    if (isLoggedIn && name === 'Install') {
      return next({ name: 'Chats', params: { locale: params.locale } })
    }

    return isRouteTest6
      ? next({})
      : next({ name: 'Chats', params: { locale: params.locale }, replace: true })
  }

  if (isRequiresAuth && !isLoggedIn) {
    return next({ name: 'Install' })
  }

  if (isLoggedIn && name === 'Install') {
    return next({ name: 'Profile' })
  }

  return next()
})

export default router
