<template>
  <div :class="$style.content">
    <icon :name="iconName" width="60" height="60" />
    <span :class="$style.text">{{ $t(labelI18n) }}</span>
  </div>
</template>

<script>
import { Tab } from '@modules/profile/constants'

export default {
  name: 'ProfileEmptyTab',
  props: {
    tabName: {
      type: String,
      default: Tab.IMAGES,
      validator: (v) => [Tab.IMAGES, Tab.VIDEOS, Tab.PRODUCTS].includes(v),
    },
  },
  computed: {
    tabsMap() {
      return {
        [Tab.IMAGES]: {
          icon: 'instagram',
          label: 'noPhoto',
        },
        [Tab.VIDEOS]: {
          icon: 'youtube',
          label: 'noVideo',
        },
        [Tab.PRODUCTS]: {
          icon: 'shop',
          label: 'underConstruction',
        },
      }
    },
    iconName() {
      return this.tabsMap[this.tabName]?.icon ?? ''
    },
    labelI18n() {
      return this.tabsMap[this.tabName]?.label ?? ''
    },
  },
}
</script>

<style lang="scss" module>
.content {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $secondary-light;

  .icon {
    width: 60px;
    height: 60px;
  }

  .text {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }
}
</style>
