import { GetParams } from '@modules/chat/types'
import { Chat } from '@common-types/chat'

// eslint-disable-next-line no-shadow
export enum ChatsFilterNames {
  ALL = 'ALL',
  OWN = 'OWN',
  FOLLOWED = 'FOLLOWED',
  PRIVATE = 'PRIVATE',
  FILTERED = 'FILTERED',
}

type Filters = { [k in ChatsFilterNames]?: (chat: Chat, params?: any) => boolean }
type RequestParams = { [k in ChatsFilterNames]?: (params?: any) => GetParams }

export const ChatsFilters: Filters = {
  [ChatsFilterNames.OWN]: (chat, { clientId }) => chat.client.id === clientId,
  [ChatsFilterNames.FOLLOWED]: (chat, { clientId }) =>
    [1, 2].includes(chat.type) && chat.is_subscribed && chat.client.id !== clientId,
  [ChatsFilterNames.PRIVATE]: (chat) => chat.type === 3,
  [ChatsFilterNames.ALL]: Boolean,
}

export const ChatsRequestParams: RequestParams = {
  [ChatsFilterNames.OWN]: ({ appId }) => ({ app_id: appId, type: [1, 2], own: true }),
  [ChatsFilterNames.FOLLOWED]: () => ({ followed: true, type: [1, 2], own: false }),
  [ChatsFilterNames.PRIVATE]: () => ({ followed: true, type: 3 }),
  [ChatsFilterNames.ALL]: () => ({}),
}

export const getChatsRequestParams = (chatsName: ChatsFilterNames, params?: any): GetParams =>
  ChatsRequestParams[chatsName]?.(params) ?? {}

export default { ChatsFilterNames, ChatsFilters }
