/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'layout': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 4a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1V5a1 1 0 00-1-1H5zm2 1H5v2h2V5zM4 11a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1v-2zm1 0h2v2H5v-2zM11 4a1 1 0 00-1 1v2a1 1 0 001 1h2a1 1 0 001-1V5a1 1 0 00-1-1h-2zm2 1h-2v2h2V5zM10 11a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2zm1 0h2v2h-2v-2z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M0 4a4 4 0 014-4h10a4 4 0 014 4v10a4 4 0 01-4 4H4a4 4 0 01-4-4V4zm4-3h10a3 3 0 013 3v10a3 3 0 01-3 3H4a3 3 0 01-3-3V4a3 3 0 013-3z" _fill="#000"/>'
  }
})
