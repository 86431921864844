// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPostPreviewMedia_container_3NmUP{position:relative;padding-bottom:100%;overflow:hidden}.VPostPreviewMedia_container_3NmUP.VPostPreviewMedia_blur_ac7XK{filter:blur(10px)}.VPostPreviewMedia_infoicons_2y0It{position:absolute;bottom:0;left:0}.VPostPreviewMedia_infoicons_2y0It svg{width:30px;height:30px}.VPostPreviewMedia_slider_31AD1{position:absolute!important;top:0;left:0;width:100%!important;height:100%}.VPostPreviewMedia_cover_2UPgl{position:absolute;bottom:0;left:0;z-index:1;width:100%;height:42px;background:linear-gradient(180deg,transparent,rgba(0,0,0,.45))}", ""]);
// Exports
exports.locals = {
	"container": "VPostPreviewMedia_container_3NmUP",
	"blur": "VPostPreviewMedia_blur_ac7XK",
	"infoicons": "VPostPreviewMedia_infoicons_2y0It",
	"slider": "VPostPreviewMedia_slider_31AD1",
	"cover": "VPostPreviewMedia_cover_2UPgl"
};
module.exports = exports;
