<template>
  <v-image
    :class="[$style.section, $style[size], isRect && $style.rect]"
    :src="src"
    :srcset="srcset"
    @error="$emit('error')"
  />
</template>

<script>
import Vue from 'vue'
import VImage from '@elements/VImage.vue'

export default Vue.extend({
  name: 'VAvatarImage',
  components: { VImage },
  props: {
    src: String,
    srcset: String,
    name: String,
    size: {
      default: 'sm',
      validator: (v) => ['xs', 'sm', 'md', 'md-2', 'md-3', 'md-4', 'md-5', 'lg', 'xl'].includes(v),
      type: String,
    },
    isRect: Boolean,
  },
})
</script>

<style lang="scss" module>
.section {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;

  &.rect {
    border-radius: 39px;
  }

  &.xs,
  &.sm {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
  }

  &.md,
  &.md-2,
  &.md-3,
  &.md-4,
  &.md-5 {
    width: calc(100% - 9px);
    height: calc(100% - 9px);
  }

  &.lg {
    width: calc(100% - 15px);
    height: calc(100% - 15px);
  }

  &.xl {
    width: calc(100% - 17px);
    height: calc(100% - 17px);
  }
}
</style>
