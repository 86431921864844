import { Post, PostId, PostIds, Posts, PostsObjectMap } from '@common-types/post'
import { hasVideo as mediaObjectsHasVideo } from '@helpers/media-objects'

export const splitPosts = ({ postIds, postId }: { postIds: PostIds; postId: PostId }): PostIds => {
  let isNextPosts = false
  const getIsNextPosts = (id: PostId) => {
    if (id === postId) {
      isNextPosts = true
    }
    return isNextPosts
  }
  return postIds.filter((id) => getIsNextPosts(id))
}

export const getPostIds = (posts: Posts): PostIds => posts.map(({ id }) => id)

export const convertPostsArrayToObjectMap = (posts: Posts): PostsObjectMap => {
  const entries: [PostId, Post][] = posts.map((post) => [post.id, post])
  return Object.fromEntries(entries)
}

export const hasVideo = (post: Post): boolean => mediaObjectsHasVideo(post.media_objects)

export default {
  splitPosts,
}
