import { checker } from '@helpers/index'
import {
  SubscriptionStatus,
  SubscriptionStatusRecurrent,
} from '@services/subscription/types/contants'

type SubscriptionStatusCheckerParams = {
  status: SubscriptionStatus
  statusRecurrent: SubscriptionStatusRecurrent
}

export const isActive = ({ status, statusRecurrent }: SubscriptionStatusCheckerParams): boolean =>
  status === SubscriptionStatus.ACCEPTED && statusRecurrent === SubscriptionStatusRecurrent.PAID

export const isRejected = ({ status, statusRecurrent }: SubscriptionStatusCheckerParams): boolean =>
  status === SubscriptionStatus.ACCEPTED && statusRecurrent === SubscriptionStatusRecurrent.NOT_PAID

export const isCanceled = checker(SubscriptionStatus.STOPPED)

export default {
  isActive,
  isCanceled,
  isRejected,
}
