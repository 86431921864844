<template>
  <div :class="$style.section">
    <h1 :class="[$style.title, $style[size]]">{{ content }}</h1>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VNoData',
  props: {
    text: String,
    size: {
      type: String,
      default: 'xl',
      validator: (v) => ['sm', 'md', 'lg', 'xl'].includes(v),
    },
  },
  computed: {
    content() {
      return this.text ?? ` ${this.$t('no.data')} 🥺`
    },
  },
})
</script>
<style lang="scss" module>
.section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 50px 0;
  background: transparent;
}

.title {
  display: block;
  text-align: center;

  &.sm {
    font-size: 18px;
  }

  &.md {
    font-size: 22px;
  }

  &.lg {
    font-size: 26px;
  }

  &.xl {
    font-size: 30px;
  }
}
</style>
