/* eslint-disable camelcase */
import { axios } from '@plugins/axios'
import { DataType, GetParams } from './types'

export default {
  getInstall: async (params: GetParams): Promise<ResType<DataType>> => {
    const { app, posts, data }: ResType<DataType> = await axios.get('install', { params })
    return { app, posts, data }
  },
}
