<template>
  <div :class="$style.container">
    <img v-if="src" :src="src" alt="Image" :class="$style.image" />
    <div :class="$style.socials">
      <div v-if="instagramLink" :class="$style.social">
        <icon name="instagram-colored" width="24" height="24" />
        <a :href="instagramLink">{{ instagramLabel }}</a>
      </div>
      <div v-if="telegramLink" :class="$style.social">
        <icon name="telegram-colored" width="24" height="24" />
        <a :href="telegramLink">{{ telegramLabel }}</a>
      </div>
    </div>
    <ChatMessage :message="message" :is-my-message="false" is-demo :markdown-body="promptMessage" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ChatMessage from '@modules/chat/modules/ChatMessage.vue'
import Influencers from '@assets/json/influencers.json'

export default Vue.extend({
  name: 'ChatGptWelcomeSocialNetworks',
  components: { ChatMessage },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  computed: {
    ...mapGetters('Chat', ['promptName', 'promptAvatar', 'promptMessage']),
    src() {
      return (
        this.influencer?.image &&
        `${this.publicPath}placeholders/${this.influencer?.placeholder}.png`
      )
    },
    influencer() {
      return Influencers.find((influencer) => influencer.placeholder === this.promptName)
    },
    instagramLink() {
      return this.influencer?.instagram
    },
    instagramLabel() {
      const parts = this.instagramLink?.split('/')?.reverse()
      return parts?.[0] || parts?.[1] || this.instagramLink
    },
    telegramLabel() {
      const parts = this.telegramLink?.split('/')?.reverse()
      return parts?.[0] || parts?.[1] || this.telegramLink
    },
    telegramLink() {
      return this.influencer?.telegram
    },
    message() {
      return {
        client: {
          avatar: this.promptAvatar,
        },
      }
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .image {
    height: 300px;
    object-fit: cover;
  }

  .socials {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .social {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}
</style>
