import Config from '@config/index'
import { getComment } from '../services/comments'
import {
  addSubComment,
  removeSubComment,
  removeSubCommentsMapItems,
  pushSubComments,
  replaceSubComment,
} from '../services/sub-comments-map'
import {
  addSubCommentId,
  cleanUpThread,
  putThreadProperties,
  setThreadProperties,
} from '../services/threads-map'
import { Mutations } from '../types'

const mutations: Mutations = {
  setSubComments(state, { parentId, subComments }) {
    const hasMore =
      !!subComments?.length && subComments.length >= Config.Comments.SUB_COMMENTS_PAGE_LIMIT
    state.threadsMap = setThreadProperties({
      threadsMap: state.threadsMap,
      parentId,
      hasMore,
    })
    state.subCommentsMap = { ...state.subCommentsMap, [parentId]: subComments }
  },
  pushSubComments(state, { subComments, parentId }) {
    const { updatedSubCommentsMap, hasMore } = pushSubComments({
      subCommentsMap: state.subCommentsMap,
      subComments,
      parentId,
    })
    state.subCommentsMap = updatedSubCommentsMap
    state.threadsMap = setThreadProperties({
      threadsMap: state.threadsMap,
      parentId,
      hasMore,
    })
  },
  addSubComment(state, subComment) {
    state.subCommentsMap = addSubComment({
      subCommentsMap: state.subCommentsMap,
      subComment,
    })
    const comment = getComment({
      comments: state.comments,
      commentId: subComment.parent.id,
    })
    const hasMore = !!comment?.comment_count
    state.threadsMap = addSubCommentId({
      threadsMap: state.threadsMap,
      parentId: subComment.parent.id,
      subCommentId: subComment.id,
      hasMore,
    })
  },
  replaceSubComment(state, subComment) {
    state.subCommentsMap = replaceSubComment({
      subCommentsMap: state.subCommentsMap,
      subComment,
    })
  },
  deleteSubComment(state, { commentId, parentId }) {
    state.subCommentsMap = removeSubComment({
      subCommentsMap: state.subCommentsMap,
      parentId,
      subCommentId: commentId,
    })
    state.threadsMap = cleanUpThread({
      threadsMap: state.threadsMap,
      parentId,
      commentId,
    })
  },
  removeSubComment(state, { commentId, parentId }) {
    state.subCommentsMap = removeSubComment({
      subCommentsMap: state.subCommentsMap,
      parentId,
      subCommentId: commentId,
    })
  },
  deleteSubCommentsByClientId(state, clientId) {
    const entries = [...Object.entries(state.subCommentsMap)]
    const updatedEntries = entries.map(([parentId, subComments]) => {
      const deletedSubCommentIds = subComments
        .filter((subComment) => subComment.client.id === clientId)
        .map(({ id }) => id)
      const linkedThread = state.threadsMap[Number(parentId)]
      const { deletedSubCommentsCount, addedSubCommentIds } = linkedThread
      const deletedSubCommentIdsNeedMarkAsDeleted = deletedSubCommentIds.filter(
        (deletedSubCommentId) => !addedSubCommentIds.includes(deletedSubCommentId)
      )
      const updatedDeletedSubCommentsCount =
        deletedSubCommentsCount + deletedSubCommentIdsNeedMarkAsDeleted.length
      const updatedAddedSubCommentIds = addedSubCommentIds.filter(
        (addedSubCommentId) => !deletedSubCommentIds.includes(addedSubCommentId)
      )
      state.threadsMap = putThreadProperties({
        threadsMap: state.threadsMap,
        parentId: Number(parentId),
        deletedSubCommentsCount: updatedDeletedSubCommentsCount,
        addedSubCommentIds: updatedAddedSubCommentIds,
        addedSubCommentsCount: updatedAddedSubCommentIds.length,
      })
      const updatedSubComments = subComments.filter(
        (subComment) => subComment.client.id !== clientId
      )
      return [parentId, updatedSubComments]
    })
    state.subCommentsMap = Object.fromEntries(updatedEntries)
  },
  removeSubCommentsMapItems(state, parentIds) {
    state.subCommentsMap = removeSubCommentsMapItems({
      subCommentsMap: state.subCommentsMap,
      parentIds,
    })
  },
  resetExtraSubCommentsCount(state, { parentId }) {
    state.threadsMap = putThreadProperties({
      threadsMap: state.threadsMap,
      parentId,
      deletedSubCommentsCount: 0,
      addedSubCommentsCount: 0,
    })
  },
}

export default mutations
