import { ExtendedService } from '@modules/messages/types'

import { ChatType } from '@common-types/chat'

export const saveTempMessageInLocalStorage: ExtendedService<'tempMessage' | 'channelId', void> = ({
  tempMessage,
  channelId,
}) => {
  try {
    const key = `temp-messages-${channelId}`
    const tempMessagesString = localStorage.getItem(key)
    const tempMessages = tempMessagesString ? JSON.parse(tempMessagesString) : []
    tempMessages.push(tempMessage)
    const updatedTempMessagesString = JSON.stringify(tempMessages)
    localStorage.setItem(key, updatedTempMessagesString)
  } catch (error) {
    console.log({ error })
  }
}

export const isTetATetChat = (chatType: ChatType): boolean =>
  [ChatType.PERSONAL_CHAT, ChatType.APP_SUPPORT_CHAT, ChatType.SUPPORT_CHAT].includes(chatType)

export default {
  saveTempMessageInLocalStorage,
}
