// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatMedia_medias_3CHpq{position:relative;display:flex;flex-wrap:wrap;width:100%;-moz-column-gap:6px;column-gap:6px;row-gap:6px;cursor:pointer}.ChatMedia_medias_3CHpq .ChatMedia_blockOverlay_tSXqb{position:absolute;z-index:2;width:100%;height:100%}", ""]);
// Exports
exports.locals = {
	"medias": "ChatMedia_medias_3CHpq",
	"blockOverlay": "ChatMedia_blockOverlay_tSXqb"
};
module.exports = exports;
