<template>
  <v-drawer name="claim-confirm" :title="$t('report.confirmReason')" @close="onClose">
    <transition-group :class="$style.drawer" tag="div" name="scroll-group" appear>
      <div key="description" :class="[$style.text]">
        {{ $t(`claims.${claimName}`) }}
      </div>
      <text-input
        v-if="isOther"
        key="textInput"
        v-model="claimDescription"
        placeholder="..."
        :class="$style.input"
        radius="0"
        padding="15px"
        :rules="rules"
        shadow
        :autofocus="true"
        @error="error = $event"
      />
      <div key="confirmButton" :class="$style.buttonWrapper">
        <v-button
          color="primary"
          :class="$style.button"
          size="xl"
          :is-loading="pageLoading"
          :is-disabled="error"
          @click="onConfirm"
          >{{ $t('confirm.confirm') }}</v-button
        >
      </div>
    </transition-group>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VButton from '@elements/VButton.vue'
import VDrawer from '@drawers/VDrawer.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import { Claims } from '@config/claims'
import { vLength } from '@utils/validations'

export default Vue.extend({
  name: 'ClaimConfirmDrawer',
  components: { VDrawer, VButton, TextInput },
  data() {
    return {
      claimDescription: '',
      error: false,
    }
  },
  computed: {
    ...mapGetters('Drawers', ['drawerParams']),
    ...mapGetters('Page', ['pageLoading']),
    ...mapGetters('Comments', ['selectedComment', 'selectedCommentParams']),
    claimName() {
      return this.drawerParams?.claimName ?? ''
    },
    isOther() {
      return this.claimName === Claims.SOMETHING_ELSE
    },
    rules() {
      return [vLength(0, 150)]
    },
    commentId() {
      return this.drawerParams?.commentId
    },
    parentId() {
      return this.drawerParams?.parentId
    },
    isClaimUser() {
      return this.drawerParams?.isClaimUser
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Comments', ['removeComment', 'removeSubComment', 'setSelectedCommentParams']),
    onClose() {
      this.claimDescription = ''
      this.setDrawer({ name: 'claim-confirm', isVisible: false })
    },
    onConfirm() {
      if (!this.isClaimUser) {
        this.onClaimComment()
      }
      this.setSelectedCommentParams(null)
      this.setDrawer({ name: 'claim-accepted', isVisible: true })
      this.onClose()
    },
    onClaimComment() {
      if (this.parentId) {
        this.removeSubComment({
          commentId: this.commentId,
          parentId: this.parentId,
        })
      } else {
        this.removeComment(this.commentId)
      }
    },
  },
})
</script>

<style lang="scss" module>
.drawer {
  & > * {
    padding: 0 15px 15px 15px;
  }
}

.input {
  margin: 0;
  padding: 0;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.buttonWrapper {
  padding: 15px;
}
</style>
