import { Module } from './types'
import getters from './getters'
import mutations from './mutations'

const module: Module = {
  state: () => ({
    back: {
      route: null,
      isDisabled: false,
    },
    lastRouteFromFooter: '',
    isFooter: true,
    isHeader: true,
    isMenu: false,
    isOffline: false,
    loading: false,
    title: {
      name: '',
      value: '',
      type: '',
    },
    isScroll: true,
    isFirstLaunch: false,
    isDesktop: false,
  }),
  getters,
  mutations,
  namespaced: true,
}

export default module
