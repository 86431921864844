/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shield': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M9.227 3l1.722 1.768c-.16 1.687-.74 3.655-2.365 5.055a6.65 6.65 0 01-1.585 1.013C5.784 10.29 3.424 8.733 3.05 4.769L4.773 3h4.454zM14 3.665c0 2.487-.6 5.968-3.458 8.43-1.718 1.481-3.418 1.883-3.49 1.9a.24.24 0 01-.104 0C6.878 13.977 0 12.304 0 3.664c0-.041.017-.081.048-.113L3.446.062A.217.217 0 013.601 0H10.4c.06 0 .116.023.155.062l3.399 3.49c.03.032.047.072.047.113zm-1.004.339L10.071 1H3.929L1.004 4.004C1.159 10.82 5.984 12.659 7 12.97c.439-.134 1.663-.577 2.888-1.633 2.446-2.107 3.054-5.088 3.107-7.334z"/>'
  }
})
