<template>
  <div :class="[$style.section, !isSendButtonVisible && $style.sectionNoButton]">
    <div v-if="isRecording" :class="$style.recordingTip">
      <div :class="$style.recordingPoint" />
      <div :class="$style.recordingTime">{{ recordingTime }}</div>
      <div :class="$style.recordingText">{{ recordingTip }}</div>
    </div>
    <textarea
      v-else
      ref="input"
      :class="$style.input"
      :value="text"
      name="text"
      wrap="hard"
      type="text"
      :rows="rows"
      :placeholder="placeholder"
      @focus="$emit('focus')"
      @input="onInput"
      @keydown.enter.stop.prevent="onSendButtonClicked"
      @blur="$emit('blur')"
      :maxlength="maxLength"
    />
    <icon v-if="isLoading" :class="$style.loader" name="three-dots-loader" width="24" height="24" />
    <v-button
      v-if="!isLoading && isSendButtonVisible"
      :color="color"
      :class="[$style.sendButton, isRecording && $style.active]"
      :is-disabled="isDisabled"
      is-circle
      @click="onSendButtonClicked"
      v-longpress="{ handler: onSendButtonLongPress }"
    >
      <icon :name="iconName" width="18" height="18" />
    </v-button>
    <div
      v-if="isRecording"
      :class="$style.sendButtonOverlay"
      @pointerleave="onSendButtonPointerLeave"
      @pointerup="onSendButtonPointerCancel"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VButton from '@elements/VButton.vue'
import { startRecording, stopRecording } from '@services/opus-recorder'
import { toTimeString } from '@utils/time'

let timer = null
let time = 0

export default Vue.extend({
  name: 'MessageInput',
  components: { VButton },
  props: {
    focus: Boolean,
    color: {
      type: String,
      default: 'primary',
    },
    value: {
      default: '',
      type: String,
    },
    placeholder: String,
    isVisible: {
      default: true,
      type: Boolean,
    },
    isSendButtonVisible: {
      default: true,
      type: Boolean,
    },
    maxLength: Number,
    isLoading: {
      default: false,
      type: Boolean,
    },
    onlyText: Boolean,
  },
  data() {
    return {
      text: this.value,
      rows: 1,
      isRecording: false,
      isRecordingCanceled: false,
      recordingTime: '00:00',
    }
  },
  computed: {
    ...mapGetters('App', ['appColor']),
    isMicrophone() {
      return !this.text?.trim() && !this.onlyText
    },
    iconName() {
      return this.isMicrophone ? 'microphone' : 'send'
    },
    recordingTip() {
      return `< ${this.$t('recordingTip')}`
    },
    isDisabled() {
      return this.onlyText && !this.text?.trim()
    },
  },
  watch: {
    focus: {
      handler(focus) {
        if (!focus) {
          return
        }
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      },
      immediate: true,
    },
    value(value) {
      this.rows = 1
      this.text = value
      this.$nextTick(() => this.recalcInputHeight(this.$refs.input))
    },
  },
  methods: {
    onInput(event) {
      const { target } = event
      this.text = target.value
      this.$emit('input', this.text.trim())
    },
    recalcInputHeight(target) {
      const computedStyle = getComputedStyle(target)
      const lineHeight = parseInt(computedStyle.lineHeight, 10)
      const paddingTop = parseInt(computedStyle.paddingTop, 10)
      const paddingBottom = parseInt(computedStyle.paddingBottom, 10)
      const height = target.scrollHeight - (paddingTop + paddingBottom)
      this.rows = this.text.trim() ? Math.floor(height / lineHeight) : 1
    },
    onKeyDown(event) {
      if (event.code === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        this.onSendButtonClicked()
      }
    },
    onSendButtonClicked() {
      if (this.isLoading) {
        return
      }
      if (this.text.trim()) {
        this.$emit('send', this.text.trim())
      }
      this.text = ''
      this.rows = 1
    },
    async onSendButtonLongPress() {
      if (!this.isMicrophone) {
        return
      }
      this.isRecordingCanceled = false
      this.isRecording = true
      this.startTimer()
      this.$emit('record-start')
      const blob = await startRecording()
      if (blob && !this.isRecordingCanceled) {
        this.$emit('record-end', blob)
      }
    },
    startTimer() {
      timer = setInterval(() => {
        time += 1
        this.recordingTime = toTimeString(time, true)
      }, 1000)
    },
    async onSendButtonPointerCancel() {
      if (this.isRecording) {
        stopRecording()
        this.reset()
      }
    },
    onSendButtonPointerLeave() {
      if (this.isRecording) {
        this.reset()
        this.isRecordingCanceled = true
        this.$emit('record-cancel')
        stopRecording()
      }
    },
    reset() {
      this.isRecording = false
      this.recordingTime = '00:00'
      clearInterval(timer)
      timer = null
      time = 0
    },
  },
})
</script>
<style lang="scss" module>
.section {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 42px;
  width: 100%;
  height: 100%;
  column-gap: 9px;

  .loader {
    fill: $primary-light-background;
  }

  &NoButton {
    grid-template-columns: 1fr;
  }

  .recordingTip {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
    height: 42px;
    overflow: hidden;
    color: $grey-dark;
    font-size: 12px;

    .recordingPoint {
      width: 10px;
      height: 10px;
      background-color: $error;
      border-radius: 50%;
    }
  }

  .sendButtonOverlay {
    position: absolute;
    right: -12px;
    bottom: -12px;
    z-index: 2;
    width: 200px;
    height: 200px;
    background-color: black;
    border-top-left-radius: 100%;
    opacity: 0.1;
  }
}

.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 42px;
  max-height: 200px;
  padding: 9px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  color: $secondary;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  word-wrap: break-word;
  border: 1px solid $secondary-light;
  border-radius: 21px;
  outline: none;
  resize: none;

  @include scrollbar();

  &:placeholder-shown {
    height: 42px;
  }
}

.sendButton {
  position: relative;
  flex-basis: 42px;
  flex-shrink: 0;
  align-self: flex-end;
  height: 42px;
  overflow: visible;
  border: 0 !important;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out, color 0.2s ease-in-out;

  &.active {
    color: white;
    background-color: $error;
  }
}

.icon {
  position: relative;
  width: 18px;
  height: 18px;
  fill: currentColor;
}
</style>
