// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Toasts_toasts_1UfCe{display:flex;flex-direction:column;padding:6px;width:100%;position:absolute;bottom:0;z-index:1002}", ""]);
// Exports
exports.locals = {
	"toasts": "Toasts_toasts_1UfCe"
};
module.exports = exports;
