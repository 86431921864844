import MarkdownIt from 'markdown-it'
import Hljs from 'highlight.js'
import Mathjax3 from 'markdown-it-mathjax3'

const tableRenderer = (tokens, idx, options, env, self) => {
  if (tokens[idx].type === 'table_open') {
    return `<div class="markdown-table-wrapper">\n${self.renderToken(tokens, idx, options)}`
  }
  if (tokens[idx].type === 'table_close') {
    return `${self.renderToken(tokens, idx, options)}\n</div>`
  }
  return self.renderToken(tokens, idx, options)
}

const markDown = new MarkdownIt({
  linkify: true,
  highlight(code, lang) {
    const language = Hljs.getLanguage(lang) ? lang : 'plaintext'
    return `
      <pre class="hljs-code-container">
        <div class="hljs-code-header">
          <span class="hljs-language">${language}</span>
          <button class="hljs-copy-button" data-copied="false">
            <div class='hljs-copy-button-icon'></div>
            <div class='hljs-copy-button-text'>Copy code</div>
          </button>
        </div>
        <code class="hljs language-${language}">${
      Hljs.highlight(code, { language, ignoreIllegals: true }).value
    }</code>
      </pre>`
  },
})
markDown.use(Mathjax3)

markDown.renderer.rules.table_open = tableRenderer
markDown.renderer.rules.table_close = tableRenderer

export default markDown
