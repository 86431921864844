<template>
  <div :class="$style.section" ref="section">
    <template v-if="isReadyToLoad">
      <div v-if="isConvert" :class="$style.content" @click="onVideo">
        <video
          v-if="isVideo"
          ref="video"
          preload="auto"
          :loop="isLoop"
          autoplay
          playsinline
          webkit-playsinline
          x5-playsinline
          :class="$style.video"
          :poster="preview || require('@assets/images/videoPreview.png')"
          :controls="false"
          :muted="isMuted"
          v-on="$listeners"
        >
          <source v-if="!hls" :src="src" type="video/mp4" />
        </video>
        <v-image
          v-else
          :class="$style.image"
          :src="src"
          :srcset="preview || require('@assets/images/videoPreview.png')"
        />
      </div>
      <div v-else :class="[$style.processing, $style[size]]">
        <img
          :class="$style.processingIcon"
          :src="require('@assets/images/svg/playButton.svg')"
          alt=""
          draggable="false"
        />
        <p v-if="!isSmall" :class="[$style.processingText, $style[size]]">
          {{ $t('videoProcessing') }}...
        </p>
      </div>
    </template>
    <transition name="fade-kit" mode="out-in">
      <v-button
        v-if="isPlayer && isConvert && !isPlay"
        color="primary"
        :class="[$style.button, $style.play]"
        @click="!isVideo ? (noPlay ? null : onVideo) : onPlay"
      >
        <icon name="playsimple" width="12" height="12" />
      </v-button>
    </transition>
    <transition name="slide-up-kit" mode="out-in">
      <v-button
        v-if="!noControl"
        :class="[
          $style.button,
          $style.left,
          $style[position],
          { [$style.slider]: isSlider && position !== 'top' },
        ]"
        size="sm"
        color="white"
        is-div
      >
        <div :class="$style.buttonText">{{ playTime }}</div>
      </v-button>
    </transition>
    <v-video-button
      v-if="isPlayer ? isVideo && !noPlay : !noControl"
      :class="[$style.button, $style.right, $style[position]]"
      :is-video="isVideo && isConvert"
      :is-muted="isMuted"
      :is-div="isSmallSize"
      :is-disabled="!preview || noAudio"
      @click.native="onMuted"
    />
    <overlay-loading-loader :class="$style.loader" :is-loading="isLoading" :size="10" :delay="0" />
  </div>
</template>
<script>
import Vue from 'vue'
import Hls from 'hls.js'

import { toTimeString } from '@utils/time'

import VButton from '@elements/VButton.vue'
import VImage from '@elements/VImage.vue'
import OverlayLoadingLoader from '@loaders/list/OverlayLoadingLoader.vue'
import HLSConfig from '@config/hls'
import VVideoButton from './list/VVideoButton.vue'

let player = null

export default Vue.extend({
  components: { VButton, VImage, OverlayLoadingLoader, VVideoButton },
  name: 'VVideo',
  props: {
    src: String,
    hls: String,
    duration: {
      type: Number,
      default: 0,
    },
    preview: String,
    size: {
      default: 'lg',
      validator: (v) => ['sm', 'lg'].includes(v),
      type: String,
    },
    position: {
      default: 'bottom',
      validator: (v) => ['top', 'bottom'].includes(v),
      type: String,
    },
    isAutoplay: Boolean,
    isLoop: Boolean,
    isActive: Boolean,
    isReadyToLoad: Boolean,
    isConvert: Boolean,
    isSlider: Boolean,
    isPlayer: Boolean,
    isPaused: Boolean,
    noPlay: Boolean,
    noControl: Boolean,
    isSmall: Boolean,
    mute: Boolean,
  },
  data() {
    return {
      playTime: toTimeString(this.duration / 1000),
      timerTick: null,
      isVideo: false,
      noAudio: false,
      isMuted: true,
      isPlay: false,
      isLoading: false,
    }
  },
  computed: {
    isSmallSize() {
      return this.size === 'sm'
    },
    isProcessingSlot() {
      return !!this.$slots.processing
    },
  },
  watch: {
    isVideo: {
      handler(isVideo) {
        if (isVideo) {
          this.$nextTick(this.init)
        }
        this.toggleTimerTick()
      },
      immediate: true,
    },
    isActive: {
      handler(isActive) {
        if (isActive && this.isAutoplay) {
          this.onVideo()
        } else {
          this.onReset()
        }
      },
      immediate: true,
    },
    isAutoplay: {
      handler(autoplay) {
        if (this.noPlay || !this.isActive) {
          return
        }
        if (autoplay) {
          this.onVideo()
        } else {
          this.onReset()
        }
      },
      immediate: true,
    },
    isPaused() {
      this.onPlay()
    },
    src: {
      handler() {
        if (this.isVideo) {
          this.$nextTick(this.init)
          this.onPlay()
        }
      },
    },
  },
  methods: {
    init() {
      if (this.hls) {
        if (Hls.isSupported()) {
          player = new Hls(HLSConfig)
          player.attachMedia(this.$refs.video)
          player.on(Hls.Events.MEDIA_ATTACHED, () => {
            this.isLoading = true
            player.loadSource(String(this.hls))
            this.onPlay()
          })
          player.on(Hls.Events.BUFFER_CREATED, (_, { tracks }) => {
            if (typeof tracks === 'object' && typeof tracks?.audio === 'undefined') {
              this.noAudio = true
            }
          })
          player.on(Hls.Events.BUFFER_APPENDED, () => {
            if (this.isLoading) {
              this.isLoading = false
            }
          })
          player.on(Hls.Events.ERROR, (_, { details }) => {
            if (details === Hls.ErrorDetails.AUDIO_TRACK_LOAD_ERROR) {
              this.noAudio = true
            }
          })
        } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
          this.$refs.video.src = this.hls
          this.$refs.video.addEventListener('loadstart', () => {
            this.isLoading = true
          })
          this.$refs.video.addEventListener('loadedmetadata', () => {
            this.isLoading = false
            this.onPlay()
          })
        }
      }
    },
    onReset() {
      if (this.isPlay) {
        this.$refs.video.pause()
        this.isPlay = false
      }
      if (player) {
        player.stopLoad()
        player.detachMedia()
      }
      this.isVideo = false
      this.noAudio = false
      this.isMuted = true
      this.isPlay = false
      clearInterval(this.timerTick)
      this.playTime = toTimeString(this.duration / 1000)
    },
    toggleTimerTick() {
      clearInterval(this.timerTick)
      if (this.isVideo) {
        this.timerTick = setInterval(this.tick, this.timerTick ? 1000 : 0)
      }
    },
    tick() {
      const time = this.$refs.video?.duration - this.$refs.video?.currentTime
      if (time) {
        this.playTime = toTimeString(time)
      }
    },
    onVideo() {
      this.$emit('click')
      if (this.noPlay) {
        return
      }
      if (!this.isSmallSize) {
        if (!this.isVideo && this.src) {
          this.isVideo = true
          return
        }
        if (this.isPlayer) {
          this.onPlay()
          return
        }
        this.onMuted()
      }
    },
    onMuted() {
      if (!this.noAudio && !this.mute) {
        this.isMuted = !this.isMuted
      }
    },
    onPlay() {
      const { video } = this.$refs
      if (!video) {
        return
      }
      if (this.isPlay) {
        video.pause()
      } else {
        video.play().catch(() => {
          // DONOTHING: https://developer.chrome.com/blog/play-request-was-interrupted/
        })
      }
      this.isPlay = !this.isPlay
    },
  },
})
</script>
<style lang="scss" module>
.section {
  position: relative;
  width: 100%;
  height: 100%;
  background: black;
}

.content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.video {
  display: block;
  width: 100%;
  height: 100%;
}

.image {
  height: 100%;
}

.processing {
  display: grid;
  grid-auto-rows: 1fr auto;
  width: 100%;
  height: 100%;
  background-color: $primary;
  row-gap: 5px;

  &.sm {
    padding: 7px;
  }

  &.lg {
    padding: 15px;
  }

  &Icon {
    align-self: center;
    justify-self: center;
    width: 45px;
    height: 45px;
    color: $secondary;
  }

  &Text {
    color: $secondary;
    font-weight: bold;
    text-align: center;
    border-radius: 6px;

    &.sm {
      font-size: 12px;
      line-height: 12px;
    }

    &.lg {
      font-size: 17px;
      line-height: 17px;
    }
  }
}

.button {
  position: absolute !important;
  left: 3px;
  //z-index: 102 !important;
  width: auto !important;

  &Text {
    align-self: center;
    color: currentColor;
    font-weight: 400;
    font-size: 12px;
  }

  &.left {
    border-radius: 9px 9px 9px 3px !important;
  }

  &.right {
    right: 3px;
    left: auto;
    border-radius: 9px 9px 3px 9px !important;
  }

  &.bottom {
    bottom: 3px;
  }

  &.top {
    top: 3px;
  }

  &.slider {
    left: 40px;
  }

  &.play {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:hover {
      transform: translate(-50%, -50%);
    }
  }
}

.loader {
  z-index: 103 !important;
  background-color: rgba(white, 0.3) !important;
}

.icon {
  display: block;
  fill: currentColor;
}
</style>
