<template>
  <div v-if="isDesktop" :class="[$style.container, pageIsMenu && $style.open]">
    <VMenu :class="[$style.menu, pageIsMenu && $style.menuOpen]" desktop />
    <AppRightSectionBurger :class="isBurgerHidden && $style.hidden" />
    <AppRightSectionFooter />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AppRightSectionBurger from '@modules/app-right-section/AppRightSectionBurger.vue'
import VMenu from '@layouts/menu/VMenu.vue'
import AppRightSectionFooter from '@modules/app-right-section/AppRightSectionFooter.vue'
import RoutersNames from '@router/routes/routes-names'

export default Vue.extend({
  name: 'AppRightSection',
  components: { AppRightSectionFooter, AppRightSectionBurger, VMenu },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    ...mapGetters('Page', ['pageIsMenu', 'isDesktop']),
    chatPagesNames() {
      return [
        RoutersNames.CHAT,
        RoutersNames.SUPPORT_CHAT,
        RoutersNames.NEW_GPT_CHAT,
        RoutersNames.CHAT_SHARED,
      ]
    },
    isBurgerHidden() {
      return this.chatPagesNames.includes(this.$route.name)
    },
  },
})
</script>

<style module lang="scss">
.container {
  height: 100%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;

  &.open {
    z-index: 5;
  }

  .hidden {
    visibility: hidden;
  }

  @media (max-width: $width) {
    display: none;
  }

  .menu {
    width: 100%;

    &Open {
      transform: translateX(0);
    }
  }
}
</style>
