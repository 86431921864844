/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 16,
    height: 14,
    viewBox: '0 0 16 14',
    data: '<path pid="0" d="M14 12h2v1h-2v1.001h-1v-1H0v-1h13v-2h1v2zM8 5H7v2H0v1h7v1h1V8h8V7H8V5zM2.998 2V0h-1v2H0v1h1.999v1h1V3H16V2H2.999z"/>'
  }
})
