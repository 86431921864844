<template>
  <div :class="[$style.section, $style[size]]">{{ abbreviateCount }}</div>
</template>

<script>
import Vue from 'vue'
import { shortAbbreviateNumber } from '@utils/numbers'

export default Vue.extend({
  name: 'VAvatarCount',
  props: { size: String, count: Number },
  computed: {
    abbreviateCount() {
      return shortAbbreviateNumber(this.count)
    },
  },
})
</script>

<style lang="scss" module>
.section {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $primary-light-text-color;
  font-weight: 500;
  background-color: $primary-light-background;
  border-radius: 100%;

  &.xs {
    min-width: 12px;
    min-height: 12px;
    padding: 2px;
    font-size: 8px;
  }

  &.sm {
    min-width: 14px;
    min-height: 14px;
    padding: 2px;
    font-size: 8px;
  }

  &.md {
    min-width: 16px;
    min-height: 16px;
    padding: 3px;
    font-size: 10px;
  }

  &.md-2 {
    min-width: 16px;
    min-height: 16px;
    padding: 3px;
    font-size: 10px;
  }

  &.md-3 {
    min-width: 16px;
    min-height: 16px;
    padding: 3px;
    font-size: 10px;
  }

  &.lg {
    min-width: 26px;
    min-height: 26px;
    padding: 4px;
    font-size: 15px;
  }

  &.xl {
    min-width: 32px;
    min-height: 32px;
    padding: 6px;
    font-size: 18px;
  }
}
</style>
