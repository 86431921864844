<template>
  <div :class="$style.container">
    <div v-if="isButton" :class="$style.button" @click="onButtonClick">
      <icon v-if="isLoading" name="three-dots-loader" width="30" height="15" color="white" />
      <div v-else>{{ buttonText }}</div>
      <icon
        v-if="isLimit"
        name="arrow-link"
        :class="$style.buttonIcon"
        width="10"
        height="10"
        color="white"
      />
    </div>
    <ChatMessageMidjourneyActions :message="message" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import PromotionalApi from '@services/promotional'
import ChatMessageMidjourneyActions from '@modules/chat/modules/ChatMessageMidjourneyActions.vue'

import { GptVersion } from '@common-types/chat'

export default Vue.extend({
  name: 'ChatMessageActions',
  components: { ChatMessageMidjourneyActions },
  data() {
    return {
      isLinkLoading: false,
    }
  },
  props: {
    message: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLimit: Boolean,
    isUnknownError: Boolean,
  },
  computed: {
    ...mapGetters('Chat', ['chat', 'chatGptVersion']),
    ...mapGetters('Locale', ['locale']),
    isButton() {
      return this.isLimit || this.isUnknownError
    },
    isLoading() {
      return this.isLinkLoading
    },
    gptVersionLimitMessageI18nCaption() {
      return this.chatGptVersion === GptVersion.GPT_4 ? 'open' : 'getUnlimited'
    },
    buttonText() {
      const caption = this.isLimit ? this.gptVersionLimitMessageI18nCaption : 'repeatRequest'
      return this.$t(`gpt.${caption}`)
    },
  },
  methods: {
    onButtonClick() {
      return this.isLimit ? this.goToSubscriptionPage() : this.$emit('resend-last-message')
    },
    async goToSubscriptionPage() {
      this.isLinkLoading = true
      const app_id = this.message?.client?.owner_app?.id
      const subscriptions = await PromotionalApi.getPromotionals({
        app_id,
        page: 1,
        limit: 1,
      })
      if (subscriptions && subscriptions[0]) {
        await this.$router.push({
          name: 'Promotional',
          params: { id: subscriptions[0].id, locale: this.locale },
          query: { chat_id: this.chat?.id, gpt: true },
        })
      }
      this.isLinkLoading = false
    },
  },
})
</script>

<style module lang="scss">
.container {
  .button {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    color: white;
    text-transform: uppercase;
    background: black;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: $primary;
      border-radius: 6px;
      opacity: 0.5;
      content: '';
    }

    &Icon {
      position: absolute;
      top: 0;
      right: 0;
      margin: 3px;
      transform: rotate(45deg);
    }
  }
}
</style>
