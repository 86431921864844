export enum ToastTypes {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
}

export enum Positions {
  TOP = 'top',
  BOTTOM = 'bottom',
}
