<template>
  <div :class="$style.container">
    <div :class="$style.bar">
      <div :class="$style.lineWrapper">
        <div :style="{ width: `${progress}%` }" :class="$style.line">
          <div :class="$style.icon">{{ progressTrunc }}%</div>
        </div>
      </div>
    </div>
    <div :class="$style.waitingText">
      <span>{{ complete ? $t('gpt.waitingMinuteMore') : $t('gpt.waitingMinute') }} </span>
      <span :class="$style.waitingDots">{{ waitingDots }} </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ChatProgressLoader',
  data() {
    return {
      progress: 0,
      timer: null,
      dotsTimer: null,
      waitingDots: '.',
      complete: false,
    }
  },
  methods: {
    getWidth() {
      let seconds = 60
      this.timer = setInterval(() => {
        seconds--
        this.progress += 1.67
        if (seconds <= 0) {
          this.complete = true
          clearInterval(this.timer)
        }
      }, 1000)
    },
    dots() {
      this.dotsTimer = setInterval(() => {
        if (this.waitingDots.length === 3) {
          this.waitingDots = ''
        }
        this.waitingDots += '.'
      }, 500)
    },
  },
  computed: {
    iconStyle() {
      return {
        left: this.progress > 0 ? `calc(${this.progress}%  - 5%)` : `-15px`,
      }
    },
    progressTrunc() {
      return Math.trunc(this.progress)
    },
  },
  mounted() {
    this.getWidth()
    this.dots()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.dotsTimer)
  },
})
</script>

<style module lang="scss">
.container {
  width: 100%;
  padding-top: 40px;
}

.bar {
  position: relative;
  width: 90%;
  height: 14px;
  margin: 0 auto;
  margin-bottom: 5px;
}

.lineWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
  background: linear-gradient(0deg, #dee1e2 16%, #d2d2d2 88%);
}

.line {
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 100%;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none"><path opacity="0.22" d="M5.83992 14.8301H0.169922L5.83992 0.830078H11.5199L5.83992 14.8301Z" fill="white"/></svg>'),
    linear-gradient(90deg, #0081ea 0%, #7fffe0 100%);
  border-radius: 20px;
  transition: width 1s linear;
  content: '';
}

.icon {
  position: absolute;
  right: -15px;
  top: -40px;
  display: flex;
  justify-content: center;
  justify-self: start;
  width: 30px;
  height: 36px;
  margin-bottom: 5px;
  padding-top: 10px;
  color: #fff;
  font-size: 10px;
  background-image: url('~@assets/images/svg/progress-icon.svg');
  transition: left 1s linear;
}

.iconWrap {
  width: 90%;
  margin: 0 auto;
}

.waitingText {
  font-size: 10px;
  line-height: 18px;
  text-align: center;
}

.waitingDots {
  display: inline-block;
  width: 20px;
}
</style>
