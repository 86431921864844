// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AppRightSectionFooter_container_AISnt{position:relative;z-index:3;display:flex;gap:22px;align-items:center}@media(max-width:768px){.AppRightSectionFooter_container_AISnt{display:none}}.AppRightSectionFooter_container_AISnt .AppRightSectionFooter_language_alNkl{padding:5px 10px;border-radius:5px;cursor:pointer;transition:background-color .2s ease-in-out}.AppRightSectionFooter_container_AISnt .AppRightSectionFooter_language_alNkl .AppRightSectionFooter_locale_1upJ7{color:var(--supster-main-text-color)}.AppRightSectionFooter_container_AISnt .AppRightSectionFooter_language_alNkl.AppRightSectionFooter_active_1RISQ,.AppRightSectionFooter_container_AISnt .AppRightSectionFooter_language_alNkl:hover{background-color:hsla(0,0%,100%,.3)}.AppRightSectionFooter_container_AISnt .AppRightSectionFooter_languageLabel_1jNs7{font-weight:400;font-size:12px;line-height:18px}.AppRightSectionFooter_open_3Odup{z-index:6}.AppRightSectionFooter_open_3Odup .AppRightSectionFooter_language_alNkl .AppRightSectionFooter_locale_1upJ7{color:#000}.AppRightSectionFooter_open_3Odup .AppRightSectionFooter_language_alNkl.AppRightSectionFooter_active_1RISQ,.AppRightSectionFooter_open_3Odup .AppRightSectionFooter_language_alNkl:hover{background-color:rgba(0,0,0,.1)}", ""]);
// Exports
exports.locals = {
	"container": "AppRightSectionFooter_container_AISnt",
	"language": "AppRightSectionFooter_language_alNkl",
	"locale": "AppRightSectionFooter_locale_1upJ7",
	"active": "AppRightSectionFooter_active_1RISQ",
	"languageLabel": "AppRightSectionFooter_languageLabel_1jNs7",
	"open": "AppRightSectionFooter_open_3Odup"
};
module.exports = exports;
