// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RecoveryForm_form_40ouy{grid-auto-rows:min-content;grid-auto-flow:row}.RecoveryForm_caption_2oEPJ{padding:15px;color:#737373;font-size:12px;line-height:18px;text-align:left;border-top:1px solid #e5e3e3}.RecoveryForm_inner_3D96z{display:grid;row-gap:9px}.RecoveryForm_input_30v5B{margin:0;padding:0}.RecoveryForm_wrapper_MiQXB{display:grid;grid-template-columns:repeat(2,1fr);-moz-column-gap:20px;column-gap:20px}.RecoveryForm_buttonContainer_BndH2{padding:15px}.RecoveryForm_button_3aWli{display:grid;grid-auto-flow:column;justify-content:center;-moz-column-gap:9px;column-gap:9px}.RecoveryForm_title_2JNbH{font-weight:500;line-height:12px;text-transform:uppercase}.RecoveryForm_text_F6E74,.RecoveryForm_title_2JNbH{font-size:12px}.RecoveryForm_text_F6E74{font-weight:400;line-height:18px}", ""]);
// Exports
exports.locals = {
	"form": "RecoveryForm_form_40ouy",
	"caption": "RecoveryForm_caption_2oEPJ",
	"inner": "RecoveryForm_inner_3D96z",
	"input": "RecoveryForm_input_30v5B",
	"wrapper": "RecoveryForm_wrapper_MiQXB",
	"buttonContainer": "RecoveryForm_buttonContainer_BndH2",
	"button": "RecoveryForm_button_3aWli",
	"title": "RecoveryForm_title_2JNbH",
	"text": "RecoveryForm_text_F6E74"
};
module.exports = exports;
