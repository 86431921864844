// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VMenu_menu_lB24o{position:absolute;top:0;right:0;z-index:2;display:grid;grid-template-rows:auto 1fr auto;width:270px;height:100%;padding:13px 42px 42px 42px;overflow-x:hidden;overflow-y:auto;background-color:var(--supster-primary);border-left:3px solid var(--supster-secondary);transform:translateX(100%);visibility:hidden;opacity:0;row-gap:15px}.VMenu_menu_lB24o,.VMenu_menu_lB24o.VMenu_open_3_z8S{transition:transform .7s,visibility .7s,opacity .7s}.VMenu_menu_lB24o.VMenu_open_3_z8S{visibility:visible;opacity:1}.VMenu_content_3ql7B{display:grid;grid-auto-rows:30px;grid-auto-flow:row;row-gap:20px}.VMenu_content-bottom_1_gva{display:flex;justify-content:space-between}.VMenu_logo_bOujy{width:92px;height:28px}.VMenu_item_QB1o5{display:grid;grid-auto-flow:column;align-items:center;align-self:center;justify-self:flex-start;color:var(--supster-main-text-color);font-weight:400;font-size:15px;line-height:22px;-moz-column-gap:6px;column-gap:6px;cursor:pointer;transition:opacity .3s}.VMenu_item_QB1o5:hover:not(.VMenu_disabled_3830e){opacity:.6}.VMenu_item_QB1o5.VMenu_small_Uxofa{font-size:12px}.VMenu_item_QB1o5.VMenu_bolder_3dp19{font-weight:500}.VMenu_item_QB1o5.VMenu_disabled_3830e{cursor:not-allowed;opacity:.4}.VMenu_item_QB1o5 .VMenu_icon_2uK7a{align-self:center;width:17px;height:13px;color:var(--supster-main-text-color)}.VMenu_item_QB1o5 .VMenu_price_3pMjw{justify-self:flex-end;padding:6px 9px!important;border:1px solid var(--supster-secondary)!important}.VMenu_language_2nK_h span{font-weight:400;font-size:12px}.VMenu_desktop_-NuE_{background-color:#fff;border:none;padding-top:96px}.VMenu_desktop_-NuE_ .VMenu_content_3ql7B .VMenu_item_QB1o5{color:#000;font-size:12px;font-weight:400;line-height:18px}.VMenu_desktop_-NuE_ .VMenu_content_3ql7B .VMenu_bolder_3dp19{font-size:16px;font-weight:700;line-height:26px}", ""]);
// Exports
exports.locals = {
	"menu": "VMenu_menu_lB24o",
	"open": "VMenu_open_3_z8S",
	"content": "VMenu_content_3ql7B",
	"content-bottom": "VMenu_content-bottom_1_gva",
	"logo": "VMenu_logo_bOujy",
	"item": "VMenu_item_QB1o5",
	"disabled": "VMenu_disabled_3830e",
	"small": "VMenu_small_Uxofa",
	"bolder": "VMenu_bolder_3dp19",
	"icon": "VMenu_icon_2uK7a",
	"price": "VMenu_price_3pMjw",
	"language": "VMenu_language_2nK_h",
	"desktop": "VMenu_desktop_-NuE_"
};
module.exports = exports;
