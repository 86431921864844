import { Client } from '@services/client/types'
import store from '@store/index'

import { Chat } from '@common-types/chat'

export const getInterlocutor = (chat: Chat): Partial<Client> | null => {
  const currentClientId = store.getters['Client/clientId']
  const { client, recipient } = chat
  const interlocutor = client?.id === currentClientId ? recipient : client
  return interlocutor ?? null
}

export default {
  getInterlocutor,
}
