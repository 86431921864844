/* eslint-disable camelcase */
import { Getters } from './types'

const getters: Getters = {
  promotionalList: (state) => state.promotionalList ?? null,
  promotionalApp: (state) => state.promotional?.app ?? null,
  promotionalTariffs: (state) => state.promotional?.tariffs ?? null,
  promotionalDesignType: (state) => state.promotional?.design_type ?? null,
  getRedirectUrl: (state) => state.redirectUrl,
  isDenied: (state) => state.isDenied,
  modalVisible: (state) => state.modal.visible,
  promotionalMedia: (state) => state.modal[state.modal.type].data,
  promotionalMediaLength: (state) => state.modal[state.modal.type].data?.length ?? null,
  promotionalMediaType: (state) => state.modal.type,
  promotionalId: (state) => state.promotional?.id ?? null,
}

export default getters
