// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProgressRing_content_1jH-h{position:relative;width:auto;height:auto}.ProgressRing_progress_2JKon{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.ProgressRing_circle_2EYxX{transform:rotate(-90deg) scaleY(-1);transform-origin:center;transition:all .3s;will-change:transform;transform-box:fill-box}.ProgressRing_circle_2EYxX.ProgressRing_default_1ojlt{stroke:#e5e3e3}.ProgressRing_circle_2EYxX.ProgressRing_around-primary_31qGg{stroke:var(--supster-main-text-color)}.ProgressRing_circle_2EYxX.ProgressRing_around-dark_eOZUe{stroke:var(--supster-primary-dark-background)}.ProgressRing_circle_2EYxX.ProgressRing_around-light_2-Rcz{stroke:var(--supster-primary-light-background)}.ProgressRing_circle_2EYxX.ProgressRing_primary_1Yrtl{stroke:var(--supster-primary)}.ProgressRing_circle_2EYxX.ProgressRing_secondary_D40Py{stroke:var(--supster-secondary)}.ProgressRing_circle_2EYxX.ProgressRing_white_1mWsg{stroke:#fff}.ProgressRing_circle_2EYxX.ProgressRing_gradient_1EEWw{stroke:url(#gradient_primary)}.ProgressRing_icon_1SEDt{position:absolute;top:50%;left:50%;width:15px;height:15px;transform:translate(-50%,-50%)}.ProgressRing_icon_1SEDt.ProgressRing_primary_1Yrtl{color:var(--supster-primary)}.ProgressRing_icon_1SEDt.ProgressRing_secondary_D40Py{color:var(--supster-secondary)}.ProgressRing_icon_1SEDt.ProgressRing_white_1mWsg{stroke:#fff}.ProgressRing_icon_1SEDt.ProgressRing_default_1ojlt{color:#e5e3e3}", ""]);
// Exports
exports.locals = {
	"content": "ProgressRing_content_1jH-h",
	"progress": "ProgressRing_progress_2JKon",
	"circle": "ProgressRing_circle_2EYxX",
	"default": "ProgressRing_default_1ojlt",
	"around-primary": "ProgressRing_around-primary_31qGg",
	"around-dark": "ProgressRing_around-dark_eOZUe",
	"around-light": "ProgressRing_around-light_2-Rcz",
	"primary": "ProgressRing_primary_1Yrtl",
	"secondary": "ProgressRing_secondary_D40Py",
	"white": "ProgressRing_white_1mWsg",
	"gradient": "ProgressRing_gradient_1EEWw",
	"icon": "ProgressRing_icon_1SEDt"
};
module.exports = exports;
