import { Mutations } from '@store/stories/types'

const mutations: Mutations = {
  setStories(state, stories) {
    state.stories.data = [...(state.stories.data || []), ...stories]
    state.stories.page++
  },
  unshiftStory(state, story) {
    state.stories.data = [story, ...(state.stories.data || [])]
  },
  deleteStory(state, id) {
    if (state.stories.data) {
      state.stories.data = state.stories.data.filter(({ id: storyId }) => storyId !== id)
    }
  },
  setStory(state, story) {
    state.story = story
  },
  resetStories(state) {
    state.stories.data = null
    state.stories.page = 1
  },
  setStoriesIsOpen(state, isOpened) {
    state.isOpened = isOpened
  },
}

export default mutations
