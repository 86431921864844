// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClaimAcceptedDrawer_container_ayK5k{position:relative;display:flex;flex-direction:column;justify-content:flex-end;height:600px;background:var(--supster-primary);color:var(--supster-main-text-color)}.ClaimAcceptedDrawer_container_ayK5k .ClaimAcceptedDrawer_background_1ATMb{position:absolute;top:0;align-self:center;width:100%;height:490px;background-repeat:no-repeat;background-position:50%;background-size:100% 100%;--webkit-mask-size:contain;-webkit-mask-size:contain;mask-size:contain;background-color:var(--supster-main-text-color)}@media screen and (max-height:850px){.ClaimAcceptedDrawer_container_ayK5k .ClaimAcceptedDrawer_background_1ATMb{background-size:contain}}.ClaimAcceptedDrawer_container_ayK5k .ClaimAcceptedDrawer_content_7l-cK{padding:15px}.ClaimAcceptedDrawer_container_ayK5k .ClaimAcceptedDrawer_content_7l-cK .ClaimAcceptedDrawer_title_BggHQ{margin-bottom:21px;text-align:center}.ClaimAcceptedDrawer_container_ayK5k .ClaimAcceptedDrawer_content_7l-cK .ClaimAcceptedDrawer_text_2t9df{max-width:200px;margin:0 auto 15px;font-size:12px;line-height:18px;text-align:center}", ""]);
// Exports
exports.locals = {
	"container": "ClaimAcceptedDrawer_container_ayK5k",
	"background": "ClaimAcceptedDrawer_background_1ATMb",
	"content": "ClaimAcceptedDrawer_content_7l-cK",
	"title": "ClaimAcceptedDrawer_title_BggHQ",
	"text": "ClaimAcceptedDrawer_text_2t9df"
};
module.exports = exports;
