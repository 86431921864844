// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VPostPreviewBadges_badges_bhCMR{position:relative}.VPostPreviewBadges_actions_GMmVk{position:absolute;bottom:0;left:0;z-index:2;display:grid;grid-auto-columns:min-content;grid-auto-flow:column;grid-gap:9px;width:100%;padding:10px 15px}.VPostPreviewBadges_actions_GMmVk.VPostPreviewBadges_small_1lnGD{padding:3px 3px}.VPostPreviewBadges_badge_2NOuq{display:flex;align-items:center;justify-content:center;padding:0 6px}.VPostPreviewBadges_badge_2NOuq.VPostPreviewBadges_price_1I2Cg{top:6px;left:6px;border-radius:3px 9px 9px 9px}.VPostPreviewBadges_badge_2NOuq.VPostPreviewBadges_price_1I2Cg,.VPostPreviewBadges_badge_2NOuq.VPostPreviewBadges_shop_XQpFe{position:absolute;z-index:2;color:var(--supster-secondary);background:#fff}.VPostPreviewBadges_badge_2NOuq.VPostPreviewBadges_shop_XQpFe{top:9px;right:9px;padding:6px;border-radius:9px 3px 9px 9px}.VPostPreviewBadges_badge_2NOuq.VPostPreviewBadges_shop_XQpFe.VPostPreviewBadges_small_1lnGD{top:3px;right:3px}.VPostPreviewBadges_badge_2NOuq.VPostPreviewBadges_discount_3A1sC{color:#fff;background:var(--supster-secondary);border-radius:9px 9px 9px 3px}.VPostPreviewBadges_badge_2NOuq.VPostPreviewBadges_discount_3A1sC.VPostPreviewBadges_primary_pT9AF{color:var(--supster-secondary);background:var(--supster-primary)}.VPostPreviewBadges_badge_2NOuq.VPostPreviewBadges_rating_1C0u6{display:grid;grid-auto-columns:auto;grid-auto-flow:column;grid-gap:6px;justify-self:flex-end;color:#fff;background:var(--supster-secondary)}.VPostPreviewBadges_badge_2NOuq .VPostPreviewBadges_text_1WbHi{font-weight:500;font-size:15px;line-height:22px}.VPostPreviewBadges_badge_2NOuq .VPostPreviewBadges_text_1WbHi.VPostPreviewBadges_small_1lnGD{font-weight:400;font-size:12px;line-height:18px}.VPostPreviewBadges_badge_2NOuq svg{display:block}", ""]);
// Exports
exports.locals = {
	"badges": "VPostPreviewBadges_badges_bhCMR",
	"actions": "VPostPreviewBadges_actions_GMmVk",
	"small": "VPostPreviewBadges_small_1lnGD",
	"badge": "VPostPreviewBadges_badge_2NOuq",
	"price": "VPostPreviewBadges_price_1I2Cg",
	"shop": "VPostPreviewBadges_shop_XQpFe",
	"discount": "VPostPreviewBadges_discount_3A1sC",
	"primary": "VPostPreviewBadges_primary_pT9AF",
	"rating": "VPostPreviewBadges_rating_1C0u6",
	"text": "VPostPreviewBadges_text_1WbHi"
};
module.exports = exports;
