export default class VirtualizationCache<ItemId, Item extends { id: ItemId }> {
  protected cacheMap: Map<ItemId, Item> = new Map()

  getMapFromItems(items: Item[]): Map<ItemId, Item> {
    return new Map(items.map((item) => [item.id, item]))
  }

  setItems(items: Item[]): void {
    this.cacheMap = this.getMapFromItems(items)
  }

  addItem(item: Item): void {
    this.cacheMap.set(item.id, item)
  } // pushCacheContent

  addItems(items: Item[]): void {
    items.forEach((item) => this.addItem(item))
  } // pushCacheContent

  resetCache(): void {
    this.cacheMap = new Map()
  } // resetCacheContent

  getCacheMap(): Map<ItemId, Item> {
    return this.cacheMap
  } // getCacheContent

  getItem(itemId: ItemId): Item | undefined {
    return this.cacheMap.get(itemId)
  } // getCacheContentItem

  deleteItem(itemId: ItemId): void {
    this.cacheMap.delete(itemId)
  } // deleteCacheContentItem

  getItems(itemIds: ItemId[]): Item[] {
    return itemIds.map(this.getItem.bind(this)) as Item[]
  } // getPartialCacheContent

  setItem(item: Item): void {
    this.cacheMap.set(item.id, item)
  } // setCacheContentItem
}
