<template>
  <div :class="$style.container">
    <v-avatar
      :class="$style.avatarWrapper"
      :src="avatarSrc"
      :srcset="avatarSrcset"
      progress-color="primary"
      :to-app="postApp"
    >
      <template #rightContent>
        <div :class="[$style.center]">
          <div :class="[$style.content, $style.ml9]">
            <div :class="$style.info">
              <h5 :class="$style.name">{{ name }}</h5>
              <p :class="$style.location" v-if="location">{{ location }}</p>
            </div>
          </div>
        </div>
      </template>
    </v-avatar>
    <v-button :class="$style.menu" color="transparent-light" @click="onMenu">
      <icon name="dots-4" width="3" height="18" />
    </v-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VPostHeader',
  components: { VAvatar, VButton },
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('App', ['appName']),
    postApp() {
      return this.post?.app
    },
    name() {
      return this.post?.app?.name ?? ''
    },
    avatarSrc() {
      return this.post?.app?.image?.content_url ?? null
    },
    avatarSrcset() {
      return this.post?.app?.image?.preview_url ?? null
    },
    location() {
      return this.post?.location ?? null
    },
  },
  methods: {
    ...mapMutations('Stories', ['resetStories']),
    ...mapMutations('Post', ['setPost']),
    ...mapMutations('Drawers', ['setDrawer']),
    onMenu() {
      this.setPost(this.post)
      this.setDrawer({ name: 'post-menu', isVisible: true })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  justify-content: space-between;
  gap: 9px;
  padding: 6px 8px 6px 6px;
  color: $secondary;

  .avatarWrapper {
    flex-grow: 1;
  }
}

.center {
  align-self: center;
}

.ml9 {
  margin-left: 9px;
}

.content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-self: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  overflow: hidden;
}

.name {
  align-self: center;
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.location {
  align-self: center;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.4;
}

.menu {
  flex-basis: 20px;
  flex-shrink: 0;
  width: 20px;
}
</style>
