// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CartDrawer_content_1_gpd{display:grid;grid-template-rows:1fr min-content;height:calc(100% - 79px);overflow-x:hidden;overflow-y:auto}.CartDrawer_content_1_gpd,.CartDrawer_product_1xD_O{border-top:1px solid #e5e3e3}.CartDrawer_product_1xD_O:first-child{border-top:0}.CartDrawer_footer_p280u{display:grid;padding:15px;background-color:#fff;border-top:1px solid #e5e3e3}", ""]);
// Exports
exports.locals = {
	"content": "CartDrawer_content_1_gpd",
	"product": "CartDrawer_product_1xD_O",
	"footer": "CartDrawer_footer_p280u"
};
module.exports = exports;
