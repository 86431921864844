// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfilePostsBlock_container_2SmHn{height:315px;overflow:hidden;grid-template-rows:repeat(2,auto);display:grid;gap:1px;grid-template-columns:1fr 1fr 1fr}.ProfilePostsBlock_container_2SmHn.ProfilePostsBlock_bigLeft_1DocI{grid-template-columns:1fr 2fr;grid-auto-flow:column}.ProfilePostsBlock_container_2SmHn.ProfilePostsBlock_bigLeft_1DocI .ProfilePostsBlock_post_2QTmv:last-child{grid-row:1/3}.ProfilePostsBlock_container_2SmHn.ProfilePostsBlock_bigRight_2lOYg{grid-template-columns:2fr 1fr}.ProfilePostsBlock_container_2SmHn.ProfilePostsBlock_bigRight_2lOYg .ProfilePostsBlock_post_2QTmv:first-child{grid-row:1/3}", ""]);
// Exports
exports.locals = {
	"container": "ProfilePostsBlock_container_2SmHn",
	"bigLeft": "ProfilePostsBlock_bigLeft_1DocI",
	"post": "ProfilePostsBlock_post_2QTmv",
	"bigRight": "ProfilePostsBlock_bigRight_2lOYg"
};
module.exports = exports;
