var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.$style.section,
    _vm.$style[_vm.size],
    { [_vm.$style[_vm.color]]: _vm.iconName },
    _vm.$style[_vm.iconName],
    _vm.$style[`around-${_vm.environment}`],
  ]},[_c('icon',{class:[
      _vm.$style.icon,
      _vm.iconName && _vm.$style[_vm.size],
      _vm.$style[_vm.color],
      _vm.$style[`${_vm.iconName}-${_vm.size}-icon`],
    ],attrs:{"name":_vm.iconName || 'no-avatar',"color":_vm.color}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }