<template>
  <div :class="$style.wrapper">
    <div :class="$style.chatInvite">
      <v-avatar size="md-3" progress-color="primary" />
      <span :class="$style.name">Support</span>
      <span :class="$style.desc">Уже 2K подписчиков</span>
      <v-button :class="$style.button">
        <span>{{ $t('follow') }}</span>
      </v-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'ChatInvite',

  components: {
    VAvatar,
    VButton,
  },
})
</script>

<style lang="scss" module>
.wrapper {
  width: 100%;
  padding: 2px;
  border-radius: 6px;

  @include gradient-background-gold;

  .chatInvite {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    width: 100%;
    padding: 18px;
    background: white;
    border-radius: 6px;

    .name {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }

    .desc {
      color: $secondary-medium;
      font-weight: 400;
      font-size: 9px;
      line-height: 12px;
    }

    .button {
      width: auto;
      color: $primary;
    }
  }
}
</style>
