import MoneyApi from '@services/money'
import i18n from '@plugins/i18n'
import { Actions } from './types'

const actions: Actions = {
  async getMoneys({ commit, dispatch }, { scroll, params }) {
    try {
      commit('Page/setPageLoading', true, { root: true })
      const orders = await MoneyApi.getMoneys(params)
      commit('addOrders', orders)
      if (scroll) {
        orders.length ? scroll.loaded() : scroll.complete()
      }
    } catch (error) {
      dispatch(
        'Error/notify',
        {
          type: 'error',
          title: `${i18n.t('errors.finances.caption')}`,
          text: `${i18n.t('errors.finances.descriptionLoad')}`,
        },
        { root: true }
      )
    } finally {
      commit('Page/setPageLoading', false, { root: true })
    }
  },
}

export default actions
