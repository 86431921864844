import { Module } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const module: Module = {
  state: () => ({
    orders: {
      all: {
        data: null,
        page: 1,
      },
      'in-process': {
        data: null,
        page: 1,
      },
      completed: {
        data: null,
        page: 1,
      },
    },
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default module
