<template>
  <div
    @click="isOpen = true"
    :class="[$style.header, isOpen && $style.open, isChatClientOwner && $style.owner]"
  >
    <h3 v-if="(!isChatClientOwner && isOpen) || !isOpen" :class="$style.title">
      {{ pageTitle }}
      <span v-if="!isOpen" :class="$style.counter">{{
        $tc('followersDeclination', chat.follower_count)
      }}</span>
    </h3>
    <v-avatar
      :src="getChatAvatar('content_url')"
      :srcset="getChatAvatar('preview_url')"
      :size="avatarSize"
      :goToAppAction="false"
      progress-color="white"
      :class="$style.avatar"
      :progress-width="2"
      :progress-count="100"
    >
      <v-file
        v-if="isOpen && isChatClientOwner"
        :class="$style.editAvatar"
        color="transparent"
        :is-disabled-padding="true"
        @files="editChatAvatar(files)"
        :fileTypes="['image']"
      >
        <icon :class="$style.icon" name="edit-round" />
      </v-file>
    </v-avatar>
    <template v-if="isOpen">
      <small
        @click="goToApp"
        v-if="!isChatClientOwner"
        :class="[$style.owner, !isChatClientOwner && $style.underline]"
        >{{ chatApp.name }}
      </small>
      <span :class="$style.counter">{{ $tc('followersDeclination', chat.follower_count) }}</span>
      <button
        v-if="!isChatClientOwner && chatChannelSubscription"
        @click="leaveChat"
        :class="$style.leave"
      >
        {{ this.$t('leave') }}
        <icon name="leave" width="12" height="14" />
      </button>
      <icon
        @click.stop="isOpen = false"
        :class="$style.close"
        name="close"
        width="13"
        height="13"
      />
      <div v-if="isChatClientOwner" :class="$style.editableChatName" @click="editChatName">
        {{ chat.name }}
      </div>
      <div @click="copyLink" :class="$style.link">
        <div class="">
          <span>{{ $t('link') }}</span>
          <b>{{ chatLink }}</b>
        </div>
        <icon name="copy" width="14" height="12" :color="appColor()" />
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { Clipboard } from 'v-clipboard'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import VFile from '@elements/VFile.vue'
import { Tab } from '@/modules/profile/constants'

export default Vue.extend({
  name: 'VHeaderChannel',
  components: {
    VAvatar,
    VFile,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters('Page', ['pageTitle']),
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Client', ['clientId']),
    ...mapGetters('App', ['appColor', 'appOriginalId']),
    ...mapGetters('Chat', [
      'chat',
      'getChatAvatar',
      'chatApp',
      'isChatClientOwner',
      'chatId',
      'chatChannelSubscription',
    ]),
    ...mapGetters('Files', ['files']),
    avatarSize() {
      if (this.isChatClientOwner && this.isOpen) {
        return 'md-5'
      }
      if (!this.isChatClientOwner && this.isOpen) {
        return 'md-4'
      }
      return 'xs'
    },
    chatLink() {
      return `${this.chatApp.name}.${process.env.VUE_APP_BASE_DOMAIN}/chats/${this.chatId}`
    },
    isChatOwnerApp() {
      return this.appOriginalId() === this.chatApp.original_id
    },
  },
  methods: {
    ...mapActions('Media', ['postMedia']),
    ...mapActions('Chat', ['putChat', 'deleteChatSubscription']),
    ...mapMutations('Page', ['setPageBack']),
    ...mapMutations('Profile', ['setProfileContentItem']),
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Chats', ['deleteChatFromChats']),
    ...mapMutations('Search', ['setSearchContentItem']),
    ...mapMutations('Toasts', ['pushToast']),
    goToApp() {
      if (this.isChatOwnerApp) {
        this.$router.push({ name: 'Profile', params: { locale: this.locale } })
      }
      this.$router.push({
        name: 'OtherProfile',
        params: { locale: this.locale, id: this.chatApp.id },
      })
    },
    async editChatAvatar(files) {
      const media = await this.postMedia(files[0].data)
      await this.putChat({
        id: this.chatId,
        data: {
          image_id: media[0].id,
        },
      })
      this.setContentAfterUpdate(this.chat)
    },

    async leaveChat() {
      const success = await this.deleteChatSubscription(this.chatChannelSubscription.id)
      if (success) {
        const chat = { ...this.chat, channel_subscription: null, is_subscribed: false }
        this.setContentAfterUpdate(chat)
        this.deleteChatFromChats(this.chatId)
      }
      this.$router.go(-1)
    },

    setContentAfterUpdate(contentItem) {
      this.setProfileContentItem({ contentItem, tab: Tab.CHATS })
      this.setSearchContentItem({ contentItem, tab: Tab.CHATS })
    },

    editChatName() {
      this.setDrawer({ name: 'chat-name-edit', isVisible: true })
    },
    copyLink() {
      Clipboard.copy(`https://${this.chatLink}?ref=${this.clientId}`)
      this.pushToast({
        message: this.$t('linkCopied'),
        type: 'success',
      })
    },
  },
})
</script>

<style lang="scss" module>
.counter {
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.avatar {
  position: relative;
  z-index: 1;
  padding-right: 12px;
  * {
    transition: none !important;
  }
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-left: 45px;
  cursor: pointer;
  &.open {
    grid-template-columns: 1fr;
    justify-items: center;
    max-height: 500px;
    padding-top: 12px;
    padding-left: 0;
    cursor: default;
    .title {
      margin-bottom: 6px;
    }
    .avatar {
      order: -1;
      margin-bottom: 12px;
      padding-right: 0;
    }
    .counter {
      margin-top: 0;
      margin-bottom: 6px;
      &.owner {
        margin-bottom: 15px;
      }
    }
    &.owner {
      .counter {
        margin-bottom: 15px;
      }
    }
  }
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.owner {
  margin-bottom: 6px;
  font-size: 10px;
  line-height: 10px;
  cursor: pointer;
  &.underline {
    text-decoration: underline;
  }
}

.leave {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  background: transparent;
  svg {
    margin-left: 6px;
  }
  path {
    fill: transparent;
  }
}

.link {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: calc(100% - 24px);
  margin: 0 auto;
  margin-bottom: 9px;
  padding: 5px 15px;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  column-gap: 12px;
  background: rgba(0, 0, 0, 0.81);
  border-radius: 6px;
  cursor: pointer;
  div {
    display: flex;
    flex-direction: column;
  }
  b {
    font-weight: 500;
  }
}

.editableChatName {
  width: calc(100% - 24px);
  margin: 0 auto;
  margin-bottom: 9px;
  padding: 10px 12px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  background: #ffffff;
  border: 1px solid #e5e3e3;
  border-radius: 6px;
  cursor: pointer;
}

.editAvatar {
  position: absolute;
  bottom: -2px;
  left: -2px;
  width: 33px;
  height: 33px;
  fill: none;
  & .icon {
    width: 33px;
    height: 33px;
    fill: none;
  }
}
</style>
