import Vue from 'vue'
import './utils/viewport'
import eventBus from '@plugins/eventBus'
import { axios, axiosJWT } from '@plugins/axios'
import CacheManager from '@plugins/cacheManager'
import EventLongpress from '@plugins/eventLongpress'
import EventSwipe from '@plugins/eventSwipe'
import '@plugins/meta'
import '@plugins/scrollBehavior'
import '@plugins/svgicon'
import '@plugins/notification'
import '@plugins/infiniteLoading'
import '@plugins/mask'
import '@plugins/marked'
import '@plugins/lazyLoad'
import { initSentry } from '@services/setnry'
import {
  bootApp,
  hideLoader,
  initVue,
  makeErrorLoader,
  makeSubscriptionCancellationPage,
  routingGuard,
} from '@services/index'
import store from './store'

import '../registerServiceWorker'

const loader = document.querySelector('#loader')
const uri = window.location.search.substring(1)
const accessToken = new URLSearchParams(uri).get('auth-token')
const refreshToken = new URLSearchParams(uri).get('refresh-token')

const { isSubscriptionCancellationPage } = routingGuard()

if (isSubscriptionCancellationPage) {
  makeSubscriptionCancellationPage(loader)
} else {
  Vue.prototype.$bus = eventBus
  Vue.config.productionTip = false
  Vue.prototype.$notifyServerError = (payload: number) =>
    store.dispatch('Error/notifyServerError', payload)
  axios.prototype.$store = store
  axiosJWT.prototype.$store = store

  initSentry()

  Vue.use(CacheManager)
  Vue.use(EventLongpress)
  Vue.use(EventSwipe)

  if (accessToken) {
    store.commit('Me/setAccessToken', accessToken)
  }

  bootApp({ accessToken, refreshToken })
    .then(() => {
      hideLoader(loader)
    })
    .catch(() => {
      makeErrorLoader(loader)
    })
    .finally(initVue)
}
