/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'delete': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<path pid="0" d="M11.5 2H.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h.559l.945 7.563A.5.5 0 002.5 14h7a.5.5 0 00.496-.438L10.941 6h.559a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM9.059 13H2.94l-.875-7h7.868l-.875 7zM11 5H1V3h10v2zM3 .5a.5.5 0 01.5-.5h5a.5.5 0 110 1h-5A.5.5 0 013 .5z"/>'
  }
})
