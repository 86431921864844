// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AuthDrawer_content_1M9jD{position:relative;display:grid;width:100%;padding:15px;overflow:hidden;box-shadow:inset 0 1px 0 #e5e3e3}.AuthDrawer_button_3k7W5{width:auto}.AuthDrawer_button_3k7W5.AuthDrawer_reg_apTZ4{margin-bottom:8px}.AuthDrawer_container_x0lB5{display:flex;flex-direction:column;align-items:center;padding:30px}.AuthDrawer_container_x0lB5 .AuthDrawer_icon_XBDx5{justify-self:center;width:90px;height:90px;margin-bottom:15px;fill:var(--supster-primary)!important}.AuthDrawer_container_x0lB5 .AuthDrawer_congrats_2Liwy{margin-bottom:15px;font-weight:700;font-size:22px;text-align:center}.AuthDrawer_container_x0lB5 .AuthDrawer_congratsDesc_2R8SU{margin-bottom:15px;color:rgba(0,0,0,.5);font-size:14px;text-align:center}", ""]);
// Exports
exports.locals = {
	"content": "AuthDrawer_content_1M9jD",
	"button": "AuthDrawer_button_3k7W5",
	"reg": "AuthDrawer_reg_apTZ4",
	"container": "AuthDrawer_container_x0lB5",
	"icon": "AuthDrawer_icon_XBDx5",
	"congrats": "AuthDrawer_congrats_2Liwy",
	"congratsDesc": "AuthDrawer_congratsDesc_2R8SU"
};
module.exports = exports;
