/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { MediaObject, PostMediaParams } from './types'

export default {
  postMedia: async ({
    file,
    fileId,
    fileResolution,
    callback,
  }: PostMediaParams): Promise<MediaObject> => {
    const { width, height, duration } = fileResolution
    const formData = new FormData()
    formData.append('file', file)
    if (width) {
      formData.append('width', width.toString())
    }
    if (height) {
      formData.append('height', height.toString())
    }
    if (duration) {
      formData.append('duration', duration.toString())
    }
    const { data }: ResType<MediaObject> = await axiosJWT.post('media_objects', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: ({ loaded, total }) => {
        if (typeof callback === 'function') {
          callback({ progress: Math.floor((loaded * 100) / total), fileId })
        }
      },
    })
    return data
  },
  postVoiceMessage: async (file: File): Promise<MediaObject> => {
    const formData = new FormData()
    formData.append('file', file)
    const { data }: ResType<MediaObject> = await axiosJWT.post('media_objects', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  },
}
