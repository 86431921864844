<template>
  <div :class="[$style.medias]">
    <ChatMediaItem
      v-for="(item, index) in media"
      :key="item.id"
      :media="item"
      :index="index"
      :is-mono-horizontal="isMonoHorizontal(item, index)"
    >
    </ChatMediaItem>
    <div v-if="!isOpenable" :class="$style.blockOverlay" />
  </div>
</template>

<script>
import Vue from 'vue'
import ChatMediaItem from './ChatMediaItem.vue'

export default Vue.extend({
  name: 'ChatMedia',
  components: { ChatMediaItem },
  props: {
    media: {
      type: Array,
      default: () => [],
    },
    isOpenable: Boolean,
  },
  mounted() {
    this.$nextTick(() => {
      if (window.myLightGallery) {
        window.myLightGallery.refresh()
      }
    })
  },
  methods: {
    isMonoHorizontal(media, index) {
      return this.media.length < 3 || index === 0
    },
  },
})
</script>

<style lang="scss" module>
.medias {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // height: 100%;
  column-gap: 6px;
  row-gap: 6px;
  cursor: pointer;

  .blockOverlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
}
</style>
