/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fire': {
    width: 16,
    height: 18,
    viewBox: '0 0 16 18',
    data: '<path pid="0" clip-rule="evenodd" d="M16 13.134c-.589 2.929-2.515 4.63-5.512 4.866 2.73-4.394-3.317-5.858-2.247-8.74-1.124.472-2.194 2.41-1.07 6.33-.696-.472-1.66-1.275-1.552-2.22-1.017 1.323-.535 3.07.428 4.63-3.532-.095-4.87-2.598-6.047-5.339.589.426 1.124.945 1.926 1.04-1.98-3.78-.053-6.047 1.927-8.835-.428 1.465-.535 2.788 0 3.874.535-4.724 3.8-7.18 7.17-8.74-2.3 3.78 3.96 5.244 1.124 9.732 1.338-.283 1.766-1.134 2.194-1.937.963 1.937 1.552 3.874-.588 5.811.749.236 1.498-.141 2.247-.472z"/>'
  }
})
