/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ok': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<rect pid="0" height="512" rx="64" ry="64" width="512" _fill="#f48420"/><g _fill="#fff"><path pid="1" d="M256.005 258.475c55.91 0 101.229-45.325 101.229-101.238 0-55.91-45.319-101.237-101.23-101.237-55.919 0-101.237 45.327-101.237 101.237 0 55.911 45.318 101.238 101.238 101.238zm0-151.042c27.5 0 49.804 22.295 49.804 49.804 0 27.51-22.304 49.805-49.804 49.805-27.51 0-49.813-22.295-49.813-49.805 0-27.509 22.303-49.804 49.813-49.804z"/><path pid="2" d="M330.116 266.368c-28.44 22.41-74.111 22.41-74.111 22.41s-45.681 0-74.118-22.41c-21.04-16.575-36.869-7.029-42.583 4.46-9.964 20.014 1.29 29.69 26.701 45.998 21.704 13.935 51.52 19.14 70.774 21.085l-16.077 16.074c-22.641 22.633-44.493 44.49-59.653 59.652-9.067 9.062-9.067 23.77 0 32.83l2.735 2.733c9.07 9.067 23.766 9.067 32.828 0l59.66-59.651 59.653 59.65c9.067 9.068 23.763 9.068 32.833 0l2.735-2.732c9.06-9.074 9.06-23.768 0-32.83l-59.665-59.66-16.122-16.122c19.272-1.98 48.772-7.21 70.288-21.03 25.406-16.308 36.668-25.984 26.702-45.998-5.712-11.486-21.55-21.034-42.58-4.46z"/></g>'
  }
})
