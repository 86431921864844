<template>
  <div :class="$style.menu">
    <div
      v-for="({ label, icon, iconWidth, iconHeight, event }, index) in menuItemsComputed"
      :key="index"
      :class="$style.item"
      @click="onClickItem(event)"
    >
      <div :class="$style.itemLabel">{{ $t(`${label}`) }}</div>
      <div :class="$style.itemIcon">
        <icon
          :class="$style.icon"
          :name="icon"
          color="black"
          :width="iconWidth"
          :height="iconHeight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MessageType } from '@common-types/chat/chat-message'
import { menuItems, excludeRules } from '../config'

export default Vue.extend({
  name: 'ChatMessageMenu',

  props: {
    message: {
      type: [Object, null],
      default: null,
    },
    isMyMessage: Boolean,
    isPinned: Boolean,
  },
  computed: {
    ...mapGetters('Chat', ['isChatCommon', 'isChatClientOwner', 'selectedMessage']),
    isVoiceMessage() {
      return this.selectedMessage?.type === MessageType.VOICE
    },
    menuItemsComputed() {
      return menuItems.filter(
        ({ id }) =>
          !excludeRules.myMessage(this.isMyMessage, this.isVoiceMessage)?.includes(id) &&
          (this.isChatClientOwner || !excludeRules.commonChat(this.isChatCommon)?.includes(id)) &&
          !excludeRules.pinnedMessage(this.isPinned)?.includes(id)
      )
    },
  },
  methods: {
    onClickItem(event) {
      this.$emit(event)
      this.$emit('click-item')
    },
  },
})
</script>

<style lang="scss" module>
.menu {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  border-radius: 6px 0 6px 6px;

  .item {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 14px 15px;
    border-bottom: 1px solid black;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: $shadow;
    }

    &:last-child {
      border-bottom: 0;
    }

    &Label {
      font-weight: 400;
      font-size: 15px;
      line-height: 14px;
    }

    &Icon {
      /* stylelint-disable-next-line selector-type-no-unknown */
      .icon {
        display: block;
      }
    }
  }
}
</style>
