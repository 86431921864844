/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { Comment, Comments, GetParams, PostParams, SubComment, SubComments } from './types'

export default {
  getComments: async (params: GetParams): Promise<Comments | SubComments> => {
    const { data }: ResType<Comments> = await axiosJWT.get('comments', { params })
    return data === null ? [] : data
  },
  getComment: async (id: number): Promise<Comment | SubComment> => {
    const { data }: ResType<Comment> = await axiosJWT.get(`comments/${id}`)
    return data
  },
  postComment: async (comment: PostParams): Promise<Comment | SubComment> => {
    const { data }: ResType<Comment | SubComment> = await axiosJWT.post('comments', comment)
    return data
  },
  putComment: async (id: number, comment: Partial<Comment>): Promise<Comment | SubComment> => {
    const { data }: ResType<Comment> = await axiosJWT.put(`comments/${id}`, comment)
    return data
  },
  deleteComment: async (id: number): Promise<boolean> => {
    const { success }: ResType<{ success: boolean }> = await axiosJWT.delete(`comments/${id}`)
    return success ?? false
  },
  postCommentLike: async (id: number): Promise<Comment | SubComment> => {
    const { data }: ResType<Comment> = await axiosJWT.post(`comments/${id}/like`)
    return data
  },
  postCommentUnlike: async (id: number): Promise<Comment | SubComment> => {
    const { data }: ResType<Comment> = await axiosJWT.post(`comments/${id}/unlike`)
    return data
  },
}
