<template>
  <v-modal
    :title="title"
    :is-visible="isVisible"
    :is-valid.sync="isValid"
    :is-loading="isLoading"
    is-confirm
    no-scrollbar
    @update:is-visible="onVisible"
    @cancel="onVisible(false)"
    @submit="onSubmit"
  >
    <template #accept>
      {{ $t('confirm.accept') }}
    </template>
    <template #cancel>
      {{ $t('confirm.cancel') }}
    </template>
    <div :class="$style.content">
      <template v-if="isMyAppGPT">
        <text-input
          v-model="formData.name"
          :rules="rules.name"
          :title="$t('title')"
          :placeholder="$t('enter.title')"
          :autofocus="true"
          required
        />
      </template>
      <template v-else-if="!isChatPersonal">
        <text-input
          v-model="formData.name"
          :rules="rules.name"
          :title="$t('title')"
          :placeholder="$t('enter.title')"
          :autofocus="true"
        />
        <v-textarea
          v-model="formData.description"
          :title="$t('description')"
          :rules="rules.description"
          :placeholder="$t('enter.description')"
        />
        <v-input>
          <v-file accept="image/*">
            {{ $t('uploadImage') }}
          </v-file>
        </v-input>
        <transition name="zoom">
          <v-cover
            v-if="fileUrl"
            color="secondary"
            :src="fileUrl"
            :progress="fileProgress"
            :error="fileError"
            :class="$style.file"
            @remove="file = null"
          />
        </transition>
      </template>
      <template v-else>
        <text-input
          v-model="formData.id"
          :rules="rules.id"
          :title="$t('userId')"
          :placeholder="$t('enter.userId')"
        />
      </template>
    </div>
  </v-modal>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VModal from '@modals/VModal.vue'
import VFile from '@elements/VFile.vue'
import VInput from '@elements/inputs/VInput.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import VTextarea from '@elements/VTextarea.vue'
import { vLength, vRequired, vTitle } from '@utils/validations'

import VCover from '@layouts/VCover.vue'
import { isChatPersonal } from '@helpers/chats'
import {
  initOtherChat,
  initPersonalChatByClientId,
} from '@modules/chat/services/chat-initializations'

export default Vue.extend({
  name: 'CreateChannelModal',
  components: {
    VModal,
    VFile,
    TextInput,
    VTextarea,
    VCover,
    VInput,
  },
  props: {
    type: Number,
    isVisible: Boolean,
  },
  data() {
    return {
      formData: {
        name: '',
        description: '',
        id: null,
      },
      progress: 0,
      isValid: false,
      isLoading: false,
      titles: ['channels.create', 'channels.create', 'chats.createGroup'],
    }
  },
  computed: {
    ...mapGetters('App', ['appId', 'isMyAppGPT']),
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Files', ['files', 'fileLoadParams']),
    ...mapGetters('Clients', ['someClient']),
    ...mapGetters('Client', ['client']),
    ...mapGetters('Chat', ['chat']),
    isChatPersonal() {
      return isChatPersonal(this.type)
    },
    title() {
      const title = this.titles[this.type ?? 1]
      return this.$t(title)
    },
    rules() {
      return {
        name: [vRequired(), vTitle(), vLength(2, 30)],
        description: [vLength(0, 500)],
        id: [vRequired()],
      }
    },
    file: {
      get() {
        return this.files?.[0] ?? null
      },
      set() {
        this.resetFiles()
      },
    },
    fileUrl() {
      return this.file?.url ?? null
    },
    fileProgress() {
      return this.fileLoadParams(this.file?.id)?.progress ?? 0
    },
    fileError() {
      return !!this.fileLoadParams(this.file?.id)?.error
    },
    fileContent() {
      return this.file?.content ?? null
    },
  },
  methods: {
    ...mapActions('Chat', ['postChat', 'getChat']),
    ...mapActions('Media', ['postMedia']),
    ...mapMutations('Files', ['resetFiles', 'setFileProgress']),
    ...mapMutations('Media', ['resetMedia']),
    ...mapActions('Clients', ['getClientFromId']),
    ...mapMutations('Toasts', ['pushToast']),
    async onSubmit() {
      this.isLoading = true
      const { id } = this.formData
      if (this.isChatPersonal) {
        await initPersonalChatByClientId(id)
      } else {
        await initOtherChat({
          progressCallback: this.setFileProgress,
          chatType: this.type,
          formData: this.formData,
        })
      }
      this.reset()
      this.isLoading = false
      this.onVisible(false)
      await this.$router.push({ name: 'Chats', params: { id: this.chat.id, locale: this.locale } })
    },
    onVisible(event) {
      this.$emit('update:is-visible', event)
      this.reset()
    },
    reset() {
      this.resetMedia()
      this.resetFiles()
      this.formData = {
        name: '',
        description: '',
        id: null,
      }
    },
  },
})
</script>
<style lang="scss" module>
.content {
  display: grid;
  row-gap: 20px;
}

.file {
  width: 100%;
  max-height: 200px;
}
</style>
