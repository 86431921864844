import { Module } from './types'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const module: Module = {
  state: () => ({
    order: null,
  }),
  actions,
  mutations,
  getters,
  namespaced: true,
}

export default module
