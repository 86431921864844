import md5 from 'md5'
import { Toast, ToastOptions } from './types'
import { ToastTypes } from './constants'

export const createToast = (options: ToastOptions): Toast => ({
  id: md5(new Date().toISOString()),
  title: options.title ?? '',
  message: options.message ?? '',
  type: options.type ?? ToastTypes.INFO,
})

export default {
  createToast,
}
