/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trash': {
    width: 16,
    height: 18,
    viewBox: '0 0 16 18',
    data: '<path pid="0" d="M15.5 2H.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h.922l1.31 10.561a.5.5 0 00.496.439h9.545a.5.5 0 00.497-.439L14.579 7h.921a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5zm-3.168 15H3.669L2.429 7h11.142l-1.239 10zM15 6H1V3h14v3zM4.092.5a.5.5 0 01.5-.5h6.817a.5.5 0 110 1H4.592a.5.5 0 01-.5-.5z" _fill="#000"/>'
  }
})
