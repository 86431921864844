<template>
  <div>
    <v-drawer name="upload-menu">
      <div
        v-for="{ name, icon, type, disabled, hidden } in menu"
        :key="name"
        :class="[$style.item, hidden && $style.hidden]"
      >
        <component
          :is="componentName(name)"
          :file-types="['image', 'video']"
          :class="[$style.button, disabled && $style.disabled]"
          @click="onMenu(name, type)"
          @files="onMenu(name, type)"
        >
          <icon :class="$style.icon" :name="icon" />
          <span :class="$style.title">{{ $t(`uploadMenuDrawer.${name}`) }}</span>
        </component>
      </div>
    </v-drawer>
    <CreateChannelModal :is-visible.sync="isChatModalVisible" :type="chatType" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import VFile from '@elements/VFile.vue'
import VButton from '@elements/VButton.vue'
import CreateChannelModal from '@modals/list/CreateChannelModal.vue'
import { createChat } from '@modules/chat/helpers/chat'
import VDrawer from '../VDrawer.vue'

export default Vue.extend({
  name: 'UploadMenuDrawer',
  components: { VFile, VButton, VDrawer, CreateChannelModal },
  data() {
    return {
      chatType: null,
      isChatModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('App', ['appId', 'isMyAppGPT', 'isSplitTestApp6']),
    ...mapGetters('Client', ['client', 'clientName', 'isClientOwner', 'clientBot']),
    ...mapGetters('Media', ['mediaIds']),
    ...mapGetters('Locale', ['locale']),
    menu() {
      return this.isMyAppGPT
        ? [{ name: 'chatGPT', type: 3, icon: 'openai' }]
        : [
            // { name: 'stories', icon: 'menu-stories', hidden: !this.isClientOwner },
            {
              name: 'post',
              icon: 'menu-post',
              hidden: !this.isClientOwner || this.isSplitTestApp6,
            },
            { name: 'chatGroup', type: 2, icon: 'menu-chat-group', hidden: !this.isClientOwner },
            { name: 'chatPersonal', type: 3, icon: 'menu-chat-personal' },
            {
              name: 'chatChannel',
              type: 1,
              icon: 'menu-chat-channel',
              hidden: !this.isClientOwner,
            },
          ]
    },
  },
  methods: {
    ...mapMutations('Drawers', ['setDrawer']),
    ...mapMutations('Media', ['resetMedia']),
    ...mapActions('Media', ['postMedia']),
    ...mapActions('Stories', ['postStory']),
    ...mapMutations('Chat', ['setChat']),
    componentName(name) {
      return name === 'stories' || name === 'post' ? 'v-file' : 'v-button'
    },
    async onMenu(name, type) {
      this.setDrawer({ name: 'upload-menu', isVisible: false })
      if (name === 'stories') {
        await this.postMedia()
        await this.postStory({
          app_id: this.appId(),
          media_objects: this.mediaIds,
        })
        this.resetMedia()
      } else if (name === 'post') {
        await this.$router.push({ name: 'PostCreate' })
      } else {
        this.chatType = type
        if (!this.clientName) {
          this.setDrawer({ name: 'noname', isVisible: true })
          this.$bus.$on('noname-confirm', () => this.onConfirmName(name))
        } else {
          this.onConfirmName(name)
        }
      }
    },
    onConfirmName(name) {
      this.$bus.$off('noname-confirm')
      if (name === 'chatGPT') {
        this.goToTempGTPChat()
      } else {
        this.onCreateModal()
      }
    },
    onCreateModal() {
      this.isChatModalVisible = true
    },
    async goToTempGTPChat() {
      const { id, name, avatar } = this.clientBot
      const chat = createChat({
        type: 3,
        client_id: id,
        name,
        image: avatar,
        client: this.client,
        recipient: this.clientBot,
      })
      this.setChat(chat)
      await this.$router.push({
        name: 'Chat',
        params: { id: chat.id, locale: this.locale },
      })
    },
  },
})
</script>

<style lang="scss" module>
.item {
  padding: 14px;
  background: $secondary;
  box-shadow: inset 0px -1px 0px #262626;

  .disabled {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;
  }
  &.hidden {
    display: none;
  }

  &:last-child {
    box-shadow: none;
  }

  & .button {
    grid-gap: 14px;
    grid-template-columns: auto 1fr;
    justify-content: flex-start !important;
    padding: 0 !important;
    text-align: left;
    border-radius: 0 !important;
    transform: none !important;
  }

  & .icon {
    width: 20px;
    height: 20px;
    fill: $primary-dark-background;
  }

  & .title {
    color: white;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  }
}
</style>
