/* eslint-disable camelcase */
import { axios, axiosJWT } from '@plugins/axios'
import { DataType, GetParams, CancelSubscriptionType } from './types'

export default {
  getSubscriptions: async (params: GetParams): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosJWT.get('subscriptions', { params })
    return data === null ? [] : data
  },
  getSubscription: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.get(`subscriptions/${id}`)
    return data
  },
  deleteSubscription: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.delete(`subscriptions/${id}`)
    return data
  },
  postSubscriptionCancel: async (secretKey: string): Promise<DataType> => {
    const { data }: ResType<DataType> = await axios.post(`subscriptions/cancel`, {
      secret_key: secretKey,
    })
    return data
  },
  postSubscriptionMonthTrial: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.post(`subscriptions/${id}/renew/free`)
    return data
  },
  postSubscriptionCancellationToEmail: async (id: number): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.post(`subscriptions/${id}/cancel_request`)
    return data
  },
  updateSubscription: async (
    id: number,
    subscription: CancelSubscriptionType
  ): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.put(`subscriptions/${id}`, subscription)
    return data
  },
}
