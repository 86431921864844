<template>
  <VNoData v-if="noData" key="chat" size="sm" :text="`${$t('no.chat')}`" />
  <ChatObserverConnection v-else>
    <ChatObserverCache @new-message="onNewMessage" @load-messages="onLoadMessages">
      <div :class="[$style.section]">
        <div v-if="selectedMessageId" :class="$style.overlay" @click="deselectMessage" />
        <div
          id="lightgallery"
          :class="[$style.content, isContentBackground && $style.background]"
          ref="chat"
        >
          <ChatGptVersionIndicator />
          <ChatPinnedMessages v-if="hasPinnedMessages" @click="goToPinnedMessage" />
          <div
            ref="scroller"
            :class="[
              $style.scrollWrapper,
              selectedMessageId && $style.noScroll,
              'chat-popover-container',
            ]"
            @scroll="handleScroll"
          >
            <ChatGptWelcome v-if="isWelcomePanel" @click-suggestion="text = $event" />
            <ChatMessages
              v-else-if="showChatMessages"
              :boundary="$refs.scroller"
              @infinite="onLoadMessages"
              @infinite-bottom="onLoadMessages($event, 'bottom')"
              @force-refresh="onForceRefresh"
              @resend-last-message="resendLastMessage"
              @refresh-messages="onRefreshMessages"
              :waiting="isWaitingResponse"
              :waiting-midjourney="isMidjourneyWaitingResponse"
              :waiting-dots="waitingDots"
              :is-response-error="isResponseError"
            />
            <ChatLoadingPlaceholder v-else-if="chatIsLoading" />
            <ChatDownScroller
              :is-visible="isDownScroller"
              :count="chatUnreadMessageCount"
              @click="onScrollBottom"
            />
          </div>
        </div>
        <ChatInput
          v-if="isInput"
          v-model="text"
          :class="$style.input"
          :is-loading="isInputLoading"
          @files="isModal = true"
          @send="onSendMessage"
          @update="onUpdateMessage"
        />
        <ChatButtonNotification v-else-if="chatId" />
        <ChatMediaModal
          :is-visible.sync="isModal"
          :text="text"
          @send="onSendMessage"
          @update="onUpdateMessage"
        />
      </div>
    </ChatObserverCache>
  </ChatObserverConnection>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import PChatMessage from '@placeholders/PChatMessage.vue'
// import OverlayContentLoader from '@loaders/list/OverlayContentLoader.vue'

import { debounce } from 'lodash'
import ChatMediaModal from '@modals/list/ChatMediaModal.vue'
import VNoData from '@layouts/VNoData.vue'
import { isChatPersonal, isSupportChat, startMediaViewer } from '@helpers/chats'
import ChatInput from '@modules/chat/modules/ChatInput.vue'
import ChatMessages from '@modules/chat/modules/ChatMessages.vue'
import ChatPinnedMessages from '@modules/chat/modules/ChatPinnedMessages.vue'
import ChatDownScroller from '@modules/chat/modules/ChatDownScroller.vue'
import ChatButtonNotification from '@modules/chat/modules/ChatButtonNotification.vue'
import ChatObserverCache from '@modules/chat/modules/ChatObserverCache'
import ChatObserverConnection from '@modules/chat/modules/ChatObserverConnection'
import ChatProgressLoader from '@modules/chat/modules/ChatProgressLoader.vue'
import { getTitle, isMyChannel } from '@modules/chat/services/chat'
import { isLimitMessage, isMyMessage } from '@modules/messages/services'
import {
  createMockMessage,
  lastMessageIsMine,
  myLastMessage,
} from '@modules/chat/services/messages'
import PromotionalApi from '@services/promotional'
import {
  initChatById,
  initPersonalChatByClientId,
  initSupportChat,
  initTempGptChat,
} from '@modules/chat/services/chat-initializations'
import { isAppSupportBung, isAppSupportChat } from '@modules/chat/helpers/chat'
import ChatLoadingPlaceholder from '@modules/chat/modules/ChatLoadingPlaceholder.vue'
import ChatGptVersionIndicator from '@modules/chat/modules/chat-gpt-welcome/ChatGptVersionIndicator.vue'
import { ChatType, GptVersion } from '@common-types/chat'
import { MessageType, MessageMode } from '@common-types/chat/chat-message'
import ChatGptWelcome from './modules/chat-gpt-welcome/ChatGptWelcome.vue'

const waitingConditions = {}

export default Vue.extend({
  name: 'Chat',
  components: {
    ChatGptVersionIndicator,
    ChatLoadingPlaceholder,
    VNoData,
    // PChatMessage,
    ChatInput,
    ChatMessages,
    ChatPinnedMessages,
    ChatMediaModal,
    ChatDownScroller,
    ChatButtonNotification,
    ChatObserverCache,
    ChatObserverConnection,
    ChatGptWelcome,
    // ChatProgressLoader,
  },
  data() {
    return {
      text: '',
      beforeRouteName: null,
      isLoading: true,
      isModal: false,
      isSendMessage: false,
      isDownScroller: false,
      isFirstMessageLoad: true,
      isTempMessagesExtracted: false,
      isInputLoading: false,
      isWaitingResponse: false,
      isResponseError: false,
      isMidjourneyWaitingResponse: false,
      waitingDots: '...',
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setPageBack({
        isDisabled: false,
        route:
          !from?.name ||
          from?.query?.firstenter ||
          from?.name === 'Introduction' ||
          from?.name === 'PaymentSuccess'
            ? 'chats'
            : null,
      })
    })
  },
  beforeRouteLeave(_, __, next) {
    const { myLightGallery } = window
    if (myLightGallery && myLightGallery.lgOpened) {
      myLightGallery.closeGallery()
      return next(false)
    }
    return next()
  },
  computed: {
    ...mapGetters('Files', ['filesData']),
    ...mapGetters('Chat', [
      'chat',
      'chatId',
      'chatName',
      'chatPartnerId',
      'chatPartnerName',
      'chatTempMessages',
      'chatMessagesLength',
      'chatMessages',
      'chatUnreadMessageCount',
      'chatIsSubscribed',
      'isChatClientOwner',
      'isChat',
      'selectedMessageId',
      'referencedMessageId',
      'localId',
      'hasPinnedMessages',
      'afterGoToPinnedMessage',
      'isChatGPTBot',
      'chatIsLoading',
      'isClientBotFromCurrentApp',
      'gptVersion',
      'isPrompt',
      'chatApp',
      'chatPromptModel',
      'chatGptVersion',
    ]),
    ...mapGetters('Client', [
      'client',
      'clientId',
      'clientBot',
      'isClientOwner',
      'hasSubscriptions',
    ]),
    ...mapGetters('Client', { clientGptVersion: 'gptVersion' }),
    ...mapGetters('Clients', ['someClient', 'someClientAvatarImage', 'someClientName']),
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('App', [
      'app',
      'appId',
      'appName',
      'appClientId',
      'appAvatarSrcset',
      'isSplitTestApp2',
      'isSplitTestApp5',
    ]),
    ...mapGetters('Page', ['pageIsOffline']),
    isContentBackground() {
      return this.isSplitTestApp5 && !this.chat?.temp && this.appName() === this.chatApp?.name
    },
    paramsPromptId() {
      return this.$route?.query?.promptId
    },
    showChatMessages() {
      return this.chatId
    },
    isWelcomePanel() {
      return this.isChatGPTBot && this.chat?.temp && !this.chatIsLoading
    },
    backRouteName() {
      return { name: 'Chats' }
    },
    paramsIsClientId() {
      return !!this.$route?.query?.isClientId
    },
    paramsId() {
      return Number(this.$route?.params?.id ?? 0)
    },
    isInput() {
      return (
        this.paramsIsClientId ||
        this.isChatClientOwner ||
        isAppSupportChat(this.chat?.type) ||
        isSupportChat(this.chat?.type) ||
        (this.chatIsSubscribed && this.isChat)
      )
    },
    noData() {
      return !this.chat && !this.paramsIsClientId && !this.chatIsLoading
    },
    isSupportChat() {
      return this.$route.name === 'SupportChat' || isSupportChat(this.chat?.type)
    },
    isNewGptChat() {
      return this.$route.name === 'NewGptChat'
    },
    isPersonalChatBung() {
      return isChatPersonal(this.chat?.type) && this.chat?.temp
    },
  },
  async created() {
    this.isLoading = true
    this.$bus.$on('send-midjourney-action', this.sendMidjourneyAction)
    if (!this.chatId) {
      await this.initChat()
    }
    if (!this.chatId) {
      this.isLoading = false
      await this.$router.push(this.backRouteName)
    }
    this.setPageTitle({
      name: getTitle(this.chat),
    })
    this.setLocalId()
    this.setPageIsFooter(false)
    this.isLoading = false
  },
  mounted() {
    this.$nextTick(() => startMediaViewer())
  },
  beforeDestroy() {
    this.$bus.$off('send-midjourney-action')
    this.resetLocalId()
    this.onReadMessages()
    this.resetChat()
    this.resetMessages()
    this.resetPinnedMessages()
    this.resetFilesResolution()
  },
  methods: {
    ...mapMutations('Page', ['setPageTitle', 'setPageIsFooter', 'setPageBack']),
    ...mapMutations('Chat', [
      'setChat',
      'resetChat',
      'resetMessages',
      'setTempMessages',
      'replaceMessage',
      'refreshMessages',
      'setChatIsSubscribed',
      'resetPinnedMessages',
      'resetSelectedMessageId',
      'setLocalId',
      'resetLocalId',
      'updateChat',
      'pushTempMessage',
      'pushMessage',
      'resetReferencedMessageId',
      'setIsEdit',
      'setIsReply',
      'setAfterGoToPinnedMessage',
    ]),
    ...mapActions('Chats', ['swapChat']),
    ...mapMutations('Chats', ['resetChatUnreadMessageCountInChats', 'updateChatsItem']),
    ...mapMutations('Files', ['resetFilesResolution']),
    ...mapActions('Clients', ['getClientFromId']),
    ...mapActions('Chat', [
      'getChat',
      'getChatMessages',
      'setChatNewMessageCount',
      'postChatMessagesReadAll',
      'postChat',
      'putChat',
      'postChatSubscription',
      'postChatMessage',
      'putChatMessage',
      'postTempChatMessages',
    ]),
    async initChat() {
      if (this.paramsIsClientId) {
        await initPersonalChatByClientId(this.paramsId)
      } else if (this.isSupportChat) {
        await initSupportChat()
      } else if (this.paramsId) {
        await initChatById(this.paramsId)
      } else if (this.isNewGptChat) {
        await initTempGptChat(this.paramsPromptId)
      }
    },

    goToPinnedMessage(message) {
      this.scrollToMessage(message)
    },

    handleScroll() {
      debounce(this.onScroll, 50)
    },

    onScroll() {
      const element = this.$refs.scroller
      if (element.offsetHeight + element.scrollTop + 500 >= element.scrollHeight) {
        this.isDownScroller = false
        return
      }
      this.isDownScroller = true
    },

    onScrollBottom() {
      this.$nextTick(() => {
        const container = this.$refs.scroller
        if (container) {
          container.scroll({
            top: container.scrollHeight,
            behavior: this.isDownScroller ? 'smooth' : 'instant',
          })
          this.onReadMessages()
        }
      })
    },

    async scrollToMessage(message) {
      this.$nextTick(() => {
        const element = document.getElementById(`message-${message.id}`)
        if (element) {
          element.scrollIntoView({
            behavior: 'instant',
            block: 'center',
          })
          element.classList.add('message--highlighted')
          setTimeout(() => {
            element.classList.remove('message--highlighted')
          }, 3000)
        }
      })
    },

    deselectMessage() {
      if (this.selectedMessageId) {
        this.resetSelectedMessageId()
      }
    },
    async onReadMessages() {
      if (this.chatIsSubscribed && this.chatUnreadMessageCount !== 0) {
        this.postChatMessagesReadAll(this.chatId)
        this.resetChatUnreadMessageCountInChats(this.chatId)
      }
    },
    onRefreshMessages(messages) {
      if (messages?.length && this.isChatGPTBot) {
        if (lastMessageIsMine(messages)) {
          if (waitingConditions[this.chatId]) {
            this.continueTimers()
            this.initAnsweringConditions()
          } else {
            this.isResponseError = true
          }
        } else {
          this.clearAnsweringTimers()
        }
      }
      this.onScrollBottom()
    },
    onForceRefresh() {
      this.resetMessages()
      this.onLoadMessages()
    },
    async onLoadMessages(scroll, direction) {
      if (this.afterGoToPinnedMessage) {
        this.$nextTick(() => {
          this.setAfterGoToPinnedMessage(false)
          scroll.loaded()
        })
        return
      }
      if (this.chat) {
        const isBottomDirection = direction === 'bottom'
        const messages = await this.getChatMessages({
          id: this.chat?.id,
          scroll,
          isBottomDirection,
        })

        if (this.isFirstMessageLoad) {
          if (this.isChatGPTBot) {
            const swSupport = 'serviceWorker' in navigator
            const registration = swSupport ? await navigator.serviceWorker.getRegistration() : null
            const isSWActive = registration
              ? registration.active && registration.active.scriptURL.includes('sw.js')
              : false
            if (!swSupport || !isSWActive) {
              if (messages?.length) {
                if (lastMessageIsMine(messages)) {
                  if (waitingConditions[this.chatId]) {
                    this.continueTimers()
                    this.initAnsweringConditions()
                  } else {
                    this.isResponseError = true
                  }
                } else {
                  this.clearAnsweringTimers()
                }
              }
            }
            this.onScrollBottom()
          }
          this.isFirstMessageLoad = false
        }

        if (!this.isTempMessagesExtracted) {
          this.extractTempMessagesFromStore()
        }
      }
    },
    async extractTempMessagesFromStore() {
      if (this.isTempMessagesExtracted) {
        return
      }
      try {
        const tempMessagesJsonString = localStorage.getItem(`temp-messages-${this.chatId}`)
        this.isTempMessagesExtracted = true
        const tempMessages = JSON.parse(tempMessagesJsonString) ?? []
        if (!tempMessages) {
          return
        }
        this.setTempMessages(tempMessages)

        if (!this.pageIsOffline) {
          if (this.chatTempMessages?.length) {
            await this.postTempChatMessages()
          }
          localStorage.removeItem(`temp-messages-${this.chatId}`)
        }
      } catch (error) {
        console.log({ error })
      }
    },
    async onSendMessage({ text, media, type = MessageType.MIXED }) {
      if (
        this.text.includes('/imagine') ||
        type === 4 ||
        this.chatPromptModel === 'midjourney' ||
        this.chatGptVersion === 'midjourney'
      ) {
        this.isMidjourneyWaitingResponse = true
      }
      if (this.chatMessagesLength === 0) {
        // eslint-disable-next-line no-undef
        ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'first-message-send')
      }
      if (this.chatMessagesLength === 1) {
        // eslint-disable-next-line no-undef
        ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'second-message-send')
      }
      if (
        this.isSplitTestApp2 &&
        !this.hasSubscriptions &&
        this.isChatGPTBot &&
        this.isClientBotFromCurrentApp
      ) {
        await this.goToSubscriptionPage(this.appId())
        return
      }
      this.isSendMessage = true
      const imOwner = this.isClientOwner && isMyChannel(this.chat)
      const mode = imOwner ? MessageMode.APP : MessageMode.CLIENT
      const mode_app_id = imOwner ? this.appId() : null
      const mockMessage = createMockMessage({
        local_id: this.localId,
        channel: this.chat,
        body: text ?? this.text,
        parent_id: this.referencedMessageId ?? null,
        has_parent: !!this.referencedMessageId,
        mediaObjectsIds: media,
        mode,
        client: mode === MessageMode.CLIENT ? this.client : null,
        mode_app: mode === MessageMode.APP ? this.app : null,
        type,
      })
      if (isAppSupportBung(this.chat)) {
        this.pushMessage(mockMessage)
        await this.postChat({
          app_id: this.appId(this.chat?.app),
          type: ChatType.APP_SUPPORT_CHAT,
          start_message_body: text,
          start_message_media_objects: media,
          start_message_type: type,
          start_message_local_id: this.localId,
        })
        return
      }
      if (isSupportChat(this.chat?.type) && this.chat?.temp) {
        this.pushMessage(mockMessage)
        await this.postChat({
          type: ChatType.SUPPORT_CHAT,
          start_message_body: text,
          start_message_media_objects: media,
          start_message_type: type,
          start_message_local_id: this.localId,
        })
        return
      }
      if (this.isChatGPTBot) {
        this.startAnsweringTimers()
        if (this.chat?.temp) {
          const chatName = text.slice(0, 50)
          const chatModel = this.isSplitTestApp5
            ? GptVersion.GPT_4
            : this.gptVersion ?? this.clientGptVersion
          const gptVersion = this.chatPromptModel ?? chatModel ?? GptVersion.GPT_3
          this.pushMessage(mockMessage)
          await this.postChat({
            type: 3,
            name: chatName,
            client_id: this.clientBot?.id,
            start_message_body: text,
            start_message_type: type,
            start_message_local_id: this.localId,
            gpt_version: gptVersion,
            chat_gpt_system_prompt_id: this.chat?.chat_gpt_system_prompt?.id ?? null,
          })
          if (!this.isPrompt) {
            this.setPageTitle({
              name: chatName,
            })
          }
          return
        }
      }
      if (this.isPersonalChatBung) {
        this.pushMessage(mockMessage)
        await this.postChat({
          client_id: this.chat?.recipient?.id,
          type: 3,
          start_message_body: text,
          start_message_media_objects: media,
          start_message_type: type,
          start_message_local_id: this.localId,
        })
        return
      }
      this.pushTempMessage(mockMessage)
      if (this.referencedMessageId) {
        this.setIsEdit(false)
        this.setIsReply(false)
      }
      this.onScrollBottom()
      const message = await this.postChatMessage({
        local_id: this.localId,
        channel_id: this.chatId,
        body: text ?? this.text,
        media_objects: media,
        parent_id: this.referencedMessageId ?? null,
        mode,
        mode_app_id,
        tempId: mockMessage.tempId,
        type,
      })
      if (this.referencedMessageId) {
        this.resetReferencedMessageId()
      }
      if (message?.media_objects?.length) {
        for (const item of message?.media_objects) {
          this.$set(item, 'height', 300)
        }
      }
      const chatFields = {
        id: message.channel.id,
        channel_subscription: { ...message.channel.channel_subscription },
        last_event_time: message.channel.last_event_time,
      }
      this.updateChat(chatFields)
      this.updateChatsItem(chatFields)
      this.swapChat(message?.channel)
      this.text = ''
      this.onScrollBottom()
    },
    async onUpdateMessage({ text, media }) {
      await this.putChatMessage({
        id: this.referencedMessageId,
        body: text ?? text,
        media_objects: media,
      })
      this.text = ''
    },
    onNewMessage(message) {
      if (message && !this.isLoading && !isMyMessage({ message, store: this.$store })) {
        this.onScrollBottom()
      }
      if (this.isChatGPTBot && !isMyMessage({ message, store: this.$store })) {
        this.clearAnsweringTimers()
        if (isLimitMessage(message)) {
          // eslint-disable-next-line no-undef
          ym(process.env.VUE_APP_YM_COUNTER, 'reachGoal', 'messages-limit-end')
          // // transfer to the subscription page after getting a limitation message
          // const app_id = message?.client?.owner_app?.id
          // this.goToSubscriptionPage(app_id)
        }
      }
    },
    async goToSubscriptionPage(app_id) {
      const subscriptions = await PromotionalApi.getPromotionals({
        app_id,
      })
      if (subscriptions && subscriptions[0]) {
        this.$router.push({
          name: 'Promotional',
          params: { id: subscriptions[0].id, locale: this.locale },
          query: { chat_id: this.chat?.id, gpt: true },
        })
      }
    },
    clearAnsweringTimers() {
      if (waitingConditions[this.chatId]) {
        clearTimeout(waitingConditions[this.chatId].timer)
        clearInterval(waitingConditions[this.chatId].interval)
        delete waitingConditions[this.chatId]
      }
      this.isInputLoading = false
      this.isWaitingResponse = false
      this.waitingDots = ''
      this.isResponseError = false
      this.isMidjourneyWaitingResponse = false
    },
    continueTimers() {
      clearTimeout(waitingConditions[this.chatId].timer)
      clearInterval(waitingConditions[this.chatId].interval)
      this.startAnsweringTimers()
    },
    initAnsweringConditions() {
      this.isResponseError = false
      this.isInputLoading = true
      this.isWaitingResponse = true
    },
    startAnsweringTimers() {
      this.initAnsweringConditions()
      const { chatId } = this
      if (!waitingConditions[chatId]) {
        waitingConditions[chatId] = {
          counter: 0,
          timer: 0,
          interval: 0,
          duration: 120000,
        }
      }
      waitingConditions[chatId].timer = setTimeout(() => {
        this.clearAnsweringTimers()
        this.isResponseError = true
      }, waitingConditions[chatId].duration)
      waitingConditions[chatId].interval = setInterval(() => {
        if (this.isWaitingResponse) {
          waitingConditions[chatId].counter += 1
          if (waitingConditions[chatId].counter % 2 === 0) {
            waitingConditions[chatId].duration -= 1000
          }
          this.waitingDots = '.'.repeat(waitingConditions[chatId].counter % 3 || 3)
        } else {
          this.clearAnsweringTimers()
        }
      }, 500)
      this.onScrollBottom()
    },
    resendLastMessage() {
      const lastMessage = myLastMessage(this.chatMessages)
      if (lastMessage) {
        this.onSendMessage({
          text: lastMessage.body,
        })
      }
    },
    sendMidjourneyAction(action) {
      this.onSendMessage({
        text: action,
        type: MessageType.ACTION,
      })
    },
  },
})
</script>
<style lang="scss" module>
.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $shadow;
  &Ios {
    height: calc(100% - #{$header + 18px});
  }

  .overlay {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.3;
    transition: opacity 0.3s;
  }

  .content {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;

    .scrollWrapper {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 9px;
      overflow-x: hidden;
      overflow-y: scroll;

      .waiting {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;
        color: $secondary;

        .waitingDots {
          width: 20px;
        }
      }
      .waitingError {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;
        color: #e54d42;
      }
    }

    .noScroll {
      overflow: hidden;
    }
  }
  .background {
    background: url('~@assets/images/valor_background.png');
    background-repeat: repeat;
    background-size: 25%;
  }

  .input {
    flex: 0;
  }
}
</style>
