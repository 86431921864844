<template>
  <div
    :class="[
      $style.section,
      $style[size],
      { [$style[color]]: iconName },
      $style[iconName],
      $style[`around-${environment}`],
    ]"
  >
    <icon
      :name="iconName || 'no-avatar'"
      :class="[
        $style.icon,
        iconName && $style[size],
        $style[color],
        $style[`${iconName}-${size}-icon`],
      ]"
      :color="color"
    />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VAvatarIcon',
  props: {
    iconName: String,
    size: String,
    color: String,
    environment: {
      default: 'light',
      type: String,
      validator: (v) => ['light', 'dark', 'primary'].includes(v),
    },
  },
})
</script>

<style lang="scss" module>
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  object-fit: cover;
  overflow: hidden;
  background: $shadow-light-2;
  border-radius: 100%;

  &.xs,
  &.sm {
    width: calc(100% - 7px);
    height: calc(100% - 7px);
  }

  &.md,
  &.md-2 {
    width: calc(100% - 9px);
    height: calc(100% - 9px);
  }
  &.md-3,
  &.md-4,
  &.md-5 {
    width: calc(100% - 12px);
    height: calc(100% - 12px);
  }

  &.lg {
    width: calc(100% - 15px);
    height: calc(100% - 15px);
  }

  &.xl {
    width: calc(100% - 17px);
    height: calc(100% - 17px);
  }

  &.primary {
    background: $primary;
  }

  &.secondary {
    background: $secondary;
  }

  &.white {
    background: white;
  }
  &.openai {
    background-color: $primary-light-background;

    .icon {
      color: $primary-light-text-color;
      width: 32px;
      height: 32px;

      &.openai-xs-icon {
        width: 20px;
        height: 20px;
      }
      &.openai-lg-icon {
        width: 55px;
        height: 55px;
      }
    }

    &.around-dark {
      background-color: $primary-dark-background;

      .icon {
        color: $primary-dark-text-color;
      }
    }

    &.around-primary {
      background-color: $main-text-color;

      .icon {
        color: $primary;
      }
    }
  }
}

.icon {
  display: block;

  &.xs {
    width: 12px;
    height: 12px;
  }

  &.sm {
    width: 14px;
    height: 14px;
  }

  &.md {
    width: 18px;
    height: 18px;
  }

  &.md-2 {
    width: 20px;
    height: 20px;
  }
  &.md-3 {
    width: 54px;
    height: 54px;
  }

  &.lg {
    width: 36px;
    height: 36px;
  }

  &.xl {
    width: 54px;
    height: 54px;
  }

  &.primary {
    color: $secondary;
  }

  &.secondary {
    color: $primary;
  }

  &.white {
    color: black;
  }
}
</style>
