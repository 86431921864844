/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tumblr': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<g fill-rule="nonzero"><path pid="0" d="M409.756 0H102.244C45.996 0 0 46.024 0 102.244v307.529c0 56.221 45.996 102.241 102.244 102.241h307.512c56.213 0 102.244-46.02 102.244-102.241V102.244C512 46.024 465.969 0 409.756 0z" _fill="#061a34"/><path pid="1" d="M344.173 415.476h-53.95c-48.577 0-84.78-24.999-84.78-84.797v-95.764h-44.157v-51.851c48.577-12.618 68.905-54.428 71.239-90.634h50.45v82.223h58.865v60.262h-58.865v83.386c0 24.996 12.622 33.636 32.703 33.636h28.495v63.539z" _fill="#fff"/></g>'
  }
})
