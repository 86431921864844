import { checker } from '@helpers/index'
import {
  MediaDevicesAccessError,
  MediaDevicesNotAllowedErrorType,
} from '../common-types/media-devices'

export const isAccessDenied = checker(MediaDevicesNotAllowedErrorType.DENIED)
export const isNotAllowed = checker(MediaDevicesAccessError.NOT_ALLOWED)

export default {
  isAccessDenied,
  isNotAllowed,
}
