import { Mutations } from './types'

const mutations: Mutations = {
  setFinances(state, finances) {
    state.finances = [...(state.finances || []), ...finances]
    state.page++
  },
}

export default mutations
