/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'openLock': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" d="M11.5 8H3V5c0-2.206 1.794-4 4-4s4 1.794 4 4h1c0-2.757-2.243-5-5-5S2 2.243 2 5v3.05c-1.14.233-2 1.243-2 2.45v3C0 14.878 1.122 16 2.5 16h9c1.378 0 2.5-1.122 2.5-2.5v-3C14 9.122 12.878 8 11.5 8zm1.5 5.5c0 .827-.673 1.5-1.5 1.5h-9c-.827 0-1.5-.673-1.5-1.5v-3C1 9.673 1.673 9 2.5 9h9c.827 0 1.5.673 1.5 1.5v3zm-5-2v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z"/><defs><linearGradient id="paint0_linear" x1="7" y1="16" x2="7" y2="0" gradientUnits="userSpaceOnUse"><stop offset=".117" stop-color="currentColor"/><stop offset=".327" stop-color="currentColor"/><stop offset=".659" stop-color="currentColor"/><stop offset=".877" stop-color="currentColor"/></linearGradient></defs>'
  }
})
