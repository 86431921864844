<template>
  <v-modal :is-visible="modal('admin-no-verified')" @close="onClose">
    <div :class="$style.container">
      <h4 v-html="$t('modals.adminNoVerified.title')" :class="$style.title"></h4>
      <p :class="$style.description">{{ $t('modals.adminNoVerified.description') }}</p>
      <v-button size="xl" color="primary" @click="onClose">{{ $t('confirm.ok') }}</v-button>
    </div>
  </v-modal>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import VModal from '../VModal.vue'
import VButton from '../../elements/VButton.vue'

export default Vue.extend({
  name: 'AdminNoVerified',
  components: { VButton, VModal },
  computed: {
    ...mapGetters('Modals', ['modal']),
  },
  methods: {
    ...mapMutations('Modals', ['setModal']),
    onClose() {
      this.setModal({ name: 'admin-no-verified', isVisible: false })
    },
  },
})
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-gap: 18px;
  padding: 20px;
  text-align: center;
}

.title {
  color: $secondary;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
}

.description {
  color: $grey-dark;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>
