import { Getters } from '../types'

const getters: Getters = {
  notificationsToken: (state) => state.notificationsToken,
  notification: (state) => state.notification,
  notificationTitle: (state) => state.notification?.title,
  notificationBody: (state) => state.notification?.body,
  notificationIcon: (state) => state.notification?.icon,
  message: (state) => state.message,
}

export default getters
