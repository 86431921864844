import { VueConstructor } from 'vue'
import { getSwipeDirection, isAccessibleHandlers } from '@plugins/eventSwipe/helpers'
import { BindingValue, SwipeDirection } from '@plugins/eventSwipe/types'

export default {
  install(Vue: VueConstructor): void {
    Vue.directive('swipe', {
      bind: (el, binding) => {
        let startYPosition: number
        let startXPosition: number
        let directions: SwipeDirection[] | null = null

        const value: BindingValue = binding?.value ?? {}

        if (!isAccessibleHandlers(value)) {
          return
        }

        const start = (event: TouchEvent) => {
          directions = null
          startYPosition = event.touches[0].clientY
          startXPosition = event.touches[0].clientX
        }

        const move = (event: TouchEvent) => {
          event.preventDefault()
          event.stopPropagation()
          const offsetY = event.changedTouches[0].clientY - startYPosition
          const offsetX = event.changedTouches[0].clientX - startXPosition
          if (!directions) {
            directions = getSwipeDirection(offsetX, offsetY)
            return
          }
          directions.forEach((direction) => {
            const handler = value[direction]
            if (handler) {
              handler(event, value.args)
            }
          })
        }

        el.addEventListener('touchstart', start)
        el.addEventListener('touchmove', move)
      },
    })
  },
}
