<template>
  <v-loader :class="$style.loader" :is-loading="isLoading" :size="size" :delay="delay">
    <template #icon>
      <div :style="style" :class="[$style.block, $style['blockLeft'], filterClass]"></div>
      <div :style="style" :class="[$style.block, $style['blockRight'], filterClass]"></div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="15" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 26 -7"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </template>
  </v-loader>
</template>
<script>
import Vue from 'vue'

import VLoader from '@loaders/VLoader.vue'

export default Vue.extend({
  name: 'ContentLoader',
  components: { VLoader },
  props: {
    delay: Number,
    size: Number,
    isLoading: Boolean,
  },
  computed: {
    filterClass() {
      return {
        [this.$style.blockFilter]: !/^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      }
    },
    style() {
      return {
        top: `calc(50% - ${this.size}px / 2)`,
        left: `calc(50% - ${this.size}px / 2)`,
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    },
  },
})
</script>
<style lang="scss" module>
$animating: 1.2s;
$easing: cubic-bezier(0.35, 0, 0.25, 1);

@keyframes rotateLeft {
  //
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform-origin: -50% -50%;
  }
}

@keyframes rotateRight {
  //
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform-origin: 150% 150%;
  }
}

.loader {
  filter: url('#goo');
}

.block {
  position: absolute;
  background-color: $primary;
  border-radius: 100%;
  will-change: all;
  mask-image: radial-gradient(white, black);

  &Filter {
    filter: url('#goo');
  }

  &Left {
    animation: rotateLeft $animating $easing infinite;
  }

  &Right {
    animation: rotateRight $animating $easing infinite;
  }
}
</style>
