import { Getters, DocTemplateType, docLangType } from './types'

const getters: Getters = {
  documentLink: (state, g, rs, rootGetters) => (name: DocTemplateType) => {
    const host = process.env.NODE_ENV === 'production' ? 'supster.supster.me' : 'my.supsterdev.com'
    const lang: docLangType = rootGetters['Countries/isCurrentCountryRu']
      ? 'ru'
      : rootGetters['Countries/isCurrentCountryIndia']
      ? 'in'
      : 'en'
    return `https://${host}/static/${name}-${lang}.pdf`
  },
}

export default getters
