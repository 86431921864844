// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatMessagePopover_selectedMessage_Gsygs{z-index:2}", ""]);
// Exports
exports.locals = {
	"selectedMessage": "ChatMessagePopover_selectedMessage_Gsygs"
};
module.exports = exports;
