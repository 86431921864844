import { getAvatarUrl, getTitle, isLimitMessage, isMyMessage } from '@modules/messages/services'
import { Message } from '@common-types/chat/chat-message'
import { ExtendedService, Notification, Service } from '../types'
import Config from '../config'

let timerId: number

export const setNotification: Service<void> = ({ store, notification }) =>
  store.commit('Notifications/setNotification', notification, { root: true })
export const stopNotificationTimer = (): void => {
  if (timerId) {
    clearTimeout(timerId)
  }
}
export const setNotificationTimer = (store: any): void => {
  if (timerId) {
    clearTimeout(timerId)
  }
  timerId = setTimeout(() => {
    setNotification({ store, notification: null })
    clearTimeout(timerId)
  }, Config.DURATION)
}

export const showNotification: Service<void> = ({ notification, store }) => {
  setNotification({ store, notification })
  setNotificationTimer(store)
}

export const getNotification: ExtendedService<'message' | 'store', Notification> = ({
  store,
  message,
}) => {
  const icon = message ? getAvatarUrl(message) : ''
  const title = message ? getTitle(message) : ''
  const body = message.body ?? ''
  const chatId = message?.channel?.id ?? 0
  return { title, body, icon, chatId }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createMessageHandler = (store: any) => (message: Message) => {
  const isNeedToNotify = message && !isMyMessage({ message, store }) && !isLimitMessage(message)
  if (isNeedToNotify) {
    const notification = getNotification({ store, message })
    showNotification({ notification, store })
  }
}

export default {
  createMessageHandler,
}
