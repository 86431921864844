import { Mutations } from './types'

const mutations: Mutations = {
  addClient(state, client) {
    state.clients.push(client)
  },
  resetClients(state) {
    state.clients = []
  },
}

export default mutations
