<template>
  <v-input :disabled="disabled" :error="error" :error-to-show="errorToShow" :title="title">
    <textarea
      :class="[$style.textarea, errorToShow && $style.textareaError]"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      name="text"
      wrap="hard"
      type="text"
      @input="onInput"
    />
  </v-input>
</template>
<script>
import Vue from 'vue'
import validate from '@utils/validate'

import VInput from '@elements/inputs/VInput.vue'

export default Vue.extend({
  name: 'VTextarea',
  components: { VInput },
  props: {
    title: String,
    value: String,
    placeholder: String,
    rules: {
      default: () => [],
      type: Array,
    },
    disabled: Boolean,
  },
  data() {
    return {
      error: '',
      errorToShow: '',
    }
  },
  watch: {
    value() {
      this.validate()
    },
  },
  async mounted() {
    this.error = await validate(this.rules, this.value)
  },
  methods: {
    async validate() {
      this.error = await validate(this.rules, this.value)
      this.errorToShow = this.error
      return this.error
    },
    onInput({ target: { value } }) {
      this.$emit('input', value)
    },
  },
})
</script>
<style lang="scss" module>
.textarea {
  width: 100%;
  height: 100px;
  padding: 11px;
  resize: none;

  @include input();

  @include scrollbar();

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
