import { Module } from './types'

import mutations from './mutations'
import getters from './getters'

const Modals: Module = {
  state: () => ({
    modals: {
      'admin-no-verified': false,
    },
  }),
  mutations,
  getters,
  namespaced: true,
}

export default Modals
