/* eslint-disable camelcase */
import { ChatsFilterNames, getChatsRequestParams } from '@config/index'
import { GetParams } from '@modules/chat/types'
import { hasAppSupportChat } from '@modules/chats/services'
import { Getters } from '../types'

const getters: Getters = {
  appSupportChat: (state, g, rs, rootGetters) => {
    const app = rootGetters['App/app']
    return { ...state.appSupportChat, app }
  },
  isEditChats: (state) => state.isEditChats,
  chatsTab: (state) => state.chatsTabs.find(({ name }) => name === state.chatsName),
  chats: (_, getters) => getters.chatsTab?.chats ?? [],
  promptChats: (s, g, rs, rootGetters) => rootGetters['Prompts/getPromptChats'] ?? [],
  extendedChats: (_, getters, rs, rootGetters) => {
    const isMyAppGPT = rootGetters['App/isMyAppGPT']
    const isSplitTestApp6 = rootGetters['App/isSplitTestApp6']
    if (isMyAppGPT) {
      return [...getters.promptChats, ...getters.chats]
    }
    const { chats } = getters
    const appName = rootGetters['App/appName']()
    const noIncluding = hasAppSupportChat(chats, appName) || rootGetters['Client/isClientOwner']
    return noIncluding || isSplitTestApp6 ? chats : [getters.appSupportChat, ...chats]
  },
  chatsName: (state) => state.chatsName ?? '',
  chatsPage: (_, getters) => getters.chatsTab?.page ?? null,
  chatsLength: (_, getters) => getters.chats?.length ?? 0,
  noData: (_, getters) => !!getters.chatsLength,
  chatsUnreadCount: (state) => state.chatsUnreadCount,
  chatsParams:
    (s, rs, g, rootGetters) =>
    (chatsName: ChatsFilterNames): GetParams => {
      const appId = rootGetters['App/appId']()
      return getChatsRequestParams(chatsName, { appId }) ?? {}
    },
}

export default getters
