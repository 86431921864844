<template>
  <section :class="$style.section">
    <VTabs
      :tabs="tabs"
      :active-index.sync="tabIndex"
      :class-name="$style.tabs"
      color="transparent-light"
      @tab="onChangeTabIndex"
      is-single-tab
    >
      <template v-if="!isClientOwner && isEditable" #header>
        <div :class="$style.activation">
          <ProfileActivation
            :title="$t(`activation.${tabName}.title`)"
            :text="$t(`activation.${tabName}.text`)"
          />
        </div>
      </template>
      <template :class="$style.content" #content>
        <ProfileImages v-if="tabName === 'IMAGES'" />
        <ProfileVideos v-else-if="tabName === 'VIDEOS'" />
        <ProfileMedia v-else :key="$route.name" :tab-name="tabName" :is-editable="isEditable" />
      </template>
    </VTabs>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import VTabs from '@layouts/VTabs.vue'
import { Tab } from '@modules/profile/constants'
import ProfileImages from '@modules/profile/modules/profile-images/ProfileImages.vue'
import ProfileVideos from '@modules/profile/modules/profile-videos/ProfileVideos.vue'
import ProfileActivation from './ProfileActivation.vue'
import ProfileMedia from './ProfileMedia.vue'

export default Vue.extend({
  name: 'ProfileTabs',
  components: { ProfileVideos, ProfileImages, VTabs, ProfileActivation, ProfileMedia },
  props: {
    isEditable: Boolean,
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  computed: {
    ...mapGetters('App', ['currentApp', 'appId']),
    ...mapGetters('Client', ['isClientOwner']),
    tabName() {
      return this.tabs[this.tabIndex].name
    },
    isChannels() {
      return this.tabName === Tab.CHATS
    },
    tabs() {
      return [
        { value: this.$t('photos'), name: Tab.IMAGES, icon: 'instagram' },
        { value: this.$t('videos'), name: Tab.VIDEOS, icon: 'youtube' },
        { value: this.$t('shop'), name: Tab.PRODUCTS, icon: 'shop' },
        { value: this.$t('channels.channels'), name: Tab.CHATS, icon: 'channels' },
      ]
    },
  },
  methods: {
    ...mapActions('Profile', ['getProfileContent']),
    setTabIndexByQueryName() {
      const index = this.tabs.findIndex(({ name }) => name === this.$route.query.tab)
      const isDisabled = this.tabs[index]?.disabled ?? false
      this.tabIndex = index < 1 || isDisabled ? 0 : index
    },
    onChangeTabIndex(name) {
      if (this.$route.query.tab !== name) {
        this.$router.replace({ query: { tab: name } })
      }
    },
    async preloadMedia() {
      const needPreloadTabNames = this.tabs
        .map(({ name }) => name)
        .filter((name) => name !== this.tabName && name !== Tab.PRODUCTS)
      for await (const tabName of needPreloadTabNames) {
        await this.getProfileContent({
          tab: tabName,
          app_id: this.appId(this.currentApp),
        })
      }
    },
  },
  created() {
    this.setTabIndexByQueryName()
    this.preloadMedia()
  },
})
</script>

<style lang="scss" module>
.section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  width: 100%;
  min-height: 100%;
  background: $shadow;
  transition: background $transition;
}

.prelaunch {
  flex: 1;
}

.tabs {
  background-color: white;
  border-top: 1px solid $secondary-light;
}

.activation {
  padding: 0 6px 6px;
}
</style>
