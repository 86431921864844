// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileAvatar_button_2a2q8{position:absolute;bottom:0;left:0;z-index:2}.ProfileAvatar_button_2a2q8.ProfileAvatar_chat_Olvz2 .ProfileAvatar_icon_3YcYU{width:13px;height:13px}.ProfileAvatar_button_2a2q8.ProfileAvatar_edit_aGTSm,.ProfileAvatar_button_2a2q8.ProfileAvatar_edit_aGTSm .ProfileAvatar_icon_3YcYU{width:33px;height:33px;fill:none}", ""]);
// Exports
exports.locals = {
	"button": "ProfileAvatar_button_2a2q8",
	"chat": "ProfileAvatar_chat_Olvz2",
	"icon": "ProfileAvatar_icon_3YcYU",
	"edit": "ProfileAvatar_edit_aGTSm"
};
module.exports = exports;
