import { Module, StateNames } from './types'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const module: Module = {
  state: () => ({
    [StateNames.MY_APP]: null,
    myApps: null,
    [StateNames.CLIENT_APP]: null,
    [StateNames.OTHER_APP]: null,
    currentAppName: StateNames.MY_APP,
  }),
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default module
