<template>
  <v-drawer name="phone" :title="title" @close="onClose">
    <v-form v-model="isValid" @submit="onSubmit">
      <transition-group tag="div" :name="transitionName" appear>
        <div v-if="step === 0" :key="step">
          <p :class="$style.text" v-html="$t('createApp.drawer.phoneText')"></p>
          <div :class="$style.field">
            <span :class="$style.description">{{ $t('createApp.drawer.phoneDescription') }}</span>
            <mask-input
              v-model="phone"
              :value="phone"
              :rules="rules.phone"
              placeholder="..."
              :mask="['+# ### ###-##-##']"
              :tokens="phoneToken"
              type="tel"
            />
          </div>
          <div :class="$style.button">
            <v-button size="xl" color="primary" :is-disabled="!isValid" @click="onProceed">
              {{ $t('proceed') }}
            </v-button>
          </div>
        </div>
        <div v-if="step === 1" :key="step">
          <p :class="$style.text">{{ $t('createApp.drawer.code.text') }}</p>
          <div :class="$style.field">
            <span :class="$style.description">{{ $t('createApp.drawer.code.description') }}</span>
            <text-input v-model="code" :value="code" :rules="rules.code" :autofocus="true" />
          </div>
          <div :class="$style.buttons">
            <div :class="$style.phone" @click="onBack">
              <span>
                <icon name="arrow-left" width="5" height="8" />
                {{ $t('createApp.drawer.code.changePhone') }}
              </span>
              <p>+{{ phone }}</p>
            </div>
            <div :class="[$style.code, !isValidResend && $style.codeDisabled]" @click="onResend">
              <span>
                <icon name="arrow-left" width="5" height="8" />
                {{ $t('createApp.drawer.code.resendCode') }}
              </span>
              <p>in: {{ timeString }}</p>
            </div>
          </div>
          <div :class="$style.button">
            <v-button size="xl" color="primary" :is-disabled="!isValid" type="submit">
              {{ $t('proceed') }}
            </v-button>
          </div>
        </div>
        <div v-if="step === 2" :key="step">
          <p :class="$style.text">{{ $t('createApp.drawer.greet.text') }}</p>
          <p :class="$style.url">
            {{ $t('createApp.drawer.greet.url') }} <a :href="appUrl.url">{{ appUrl.name }}</a>
          </p>
          <div :class="$style.actions">
            <div :class="$style.action">
              <icon name="copy" width="14" height="12" />
              <span>{{ $t('createApp.drawer.greet.copy') }}</span>
            </div>
            <div :class="$style.action">
              <icon name="copy" width="14" height="12" />
              <span>{{ $t('createApp.drawer.greet.admin') }}</span>
            </div>
          </div>
        </div>
      </transition-group>
    </v-form>
  </v-drawer>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { vLength, vNumbers, vRequired } from '@utils/validations'

import VDrawer from '@drawers/VDrawer.vue'
import VForm from '@elements/VForm.vue'
import MaskInput from '@elements/inputs/list/MaskInput.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import VButton from '@elements/VButton.vue'
import i18n from '@plugins/i18n'
import { ToastTypes } from '@modules/toasts/constants'

export default Vue.extend({
  name: 'PhoneVerification',
  components: { VForm, VDrawer, VButton, MaskInput, TextInput },
  props: { isVisible: Boolean },
  data() {
    return {
      isValid: false,
      step: 0,
      lastPhone: '',
      phone: '',
      phoneToken: {
        '#': {
          pattern: /[0-9]/,
        },
      },
      code: '',
      time: 0,
      timer: null,
      appUrl: {
        url: '/',
        name: 'Supster.me/Darina.69',
      },
    }
  },
  computed: {
    phoneNumberOrigin() {
      return this.phone.replace(/[^0-9]/g, '')
    },
    timeString() {
      const minutes = Math.floor(this.time / 60)
      const seconds = Math.floor(this.time % 60)
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    },
    title() {
      if (this.step === 0) {
        return this.$t('createApp.drawer.phoneTitle')
      }
      if (this.step === 1) {
        return this.$t('createApp.drawer.code.title')
      }
      return this.$t('createApp.drawer.greet.title')
    },
    isBack() {
      return this.step !== 0
    },
    transitionName() {
      return this.step ? 'scroll-group' : 'scroll-horizontal'
    },
    rules() {
      return {
        phone: [vRequired(), vNumbers()],
        code: [vRequired(), vLength(0, 4)],
      }
    },
    isValidResend() {
      return !this.time
    },
  },
  methods: {
    ...mapMutations('Toasts', ['pushToast']),
    ...mapActions('Client', ['postClientPhone']),
    onClose() {
      this.step = 0
      this.phone = ''
      this.code = ''
    },
    onBack() {
      if (this.step !== 0) {
        this.step--
      }
    },
    onProceed() {
      if (this.isValid) {
        if (this.step === 0) {
          if (this.lastPhone === this.phone) {
            this.step = 1
            return
          }
          this.onSubmitPhone()
        }
      }
    },
    onSubmit() {
      this.step++
    },
    onResend() {
      this.startTimer()
      this.code = ''
      this.onSubmitPhone()
    },
    startTimer() {
      if (this.timer) {
        return
      }
      this.time = 30
      this.timer = setInterval(() => {
        this.time -= 1
        if (this.time < 1) {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    async onSubmitPhone() {
      this.isLoadingPhone = true
      const { error } = await this.postClientPhone(this.phoneNumberOrigin)
      if (error) {
        this.pushToast({
          type: ToastTypes.ERROR,
          title: `${i18n.t('errors.login.caption')}`,
          message: `${i18n.t('errors.login.descriptionLoad')}`,
        })
      } else {
        this.lastPhone = this.phone
        this.step = 1
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
        this.startTimer()
      }
      this.isLoadingPhone = false
    },
  },
})
</script>

<style lang="scss" module>
.text {
  padding: 0 15px 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.field {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0 15px;
  background: $shadow;
  border-top: 1px solid $secondary-light;
  border-bottom: 1px solid $secondary-light;

  & input {
    background: $shadow;
    border: 0;
  }
}

.description {
  color: $secondary-medium;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.buttons {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 15px;
  box-shadow: inset 0 -1px 0 $secondary-light;
}

.phone,
.code {
  display: grid;
  grid-gap: 6px;
  cursor: pointer;

  & span {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: min-content auto;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  & p {
    font-weight: 500;
    font-size: 15px;
    line-height: 14px;
  }
}

.code p {
  text-align: right;
}
.codeDisabled {
  opacity: 0.5;
  cursor: auto;
}

.button {
  padding: 15px;
}

.url {
  padding: 0 15px 15px;
  color: $secondary-medium;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  box-shadow: inset 0 -1px 0 #e5e3e3;

  & a {
    color: $secondary;
    font-weight: 500;
  }
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 48px;
  box-shadow: inset 0 -1px 0 $secondary-light;
}

.action {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-child {
    box-shadow: inset -1px 0 0 #e5e3e3;
  }

  & span {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
