/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file': {
    width: 64,
    height: 64,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M33.5 9c-7.2 0-13 5.8-13 13v80c0 7.2 5.8 13 13 13h61c7.2 0 13-5.8 13-13V22c0-7.2-5.8-13-13-13h-61zm0 6h61c3.9 0 7 3.1 7 7v80c0 3.9-3.1 7-7 7h-61c-3.9 0-7-3.1-7-7V22c0-3.9 3.1-7 7-7zm0 7v15h61V22h-61zm4 29c-1.7 0-3 1.3-3 3s1.3 3 3 3h51c1.7 0 3-1.3 3-3s-1.3-3-3-3h-51zm0 15c-1.7 0-3 1.3-3 3s1.3 3 3 3h51c1.7 0 3-1.3 3-3s-1.3-3-3-3h-51zm0 15c-1.7 0-3 1.3-3 3s1.3 3 3 3H64c1.7 0 3-1.3 3-3s-1.3-3-3-3H37.5z"/>'
  }
})
