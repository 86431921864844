import Vue from 'vue'
import { Mutations } from './types'

const mutations: Mutations = {
  setFiles(state, files) {
    state.files = files
    state.filesLoadParams = state.files
      ? Object.fromEntries(
          state.files?.map(({ id }) => [
            id,
            {
              complete: false,
              error: false,
              progress: 0,
            },
          ])
        )
      : null
  },
  removeFile(state, fileId) {
    state.files = state.files?.filter(({ id }) => id !== fileId) ?? null
    if (state.filesLoadParams?.[fileId]) {
      Vue.delete(state.filesLoadParams, fileId)
    }
  },
  setFileProgress(state, { fileId, progress }) {
    state.filesLoadParams = {
      ...(state.filesLoadParams ?? {}),
      [fileId]: { ...(state.filesLoadParams?.[fileId] ?? {}), progress },
    }
  },
  setFileError(state, { fileId, error }) {
    state.filesLoadParams = {
      ...(state.filesLoadParams ?? {}),
      [fileId]: { ...(state.filesLoadParams?.[fileId] ?? {}), error },
    }
  },
  setFileComplete(state, { fileId, complete }) {
    state.filesLoadParams = {
      ...(state.filesLoadParams ?? {}),
      [fileId]: { ...(state.filesLoadParams?.[fileId] ?? {}), complete },
    }
  },
  setFilesValid(state, isValid) {
    state.filesValid = isValid
  },
  setFileResolution(state, { mediaId, resolution }) {
    state.filesResolution = {
      ...(state.filesResolution || {}),
      [mediaId]: resolution,
    }
  },
  resetFilesResolution(state) {
    state.filesResolution = null
  },
  resetFilesError(state) {
    state.filesLoadParams = state.filesLoadParams
      ? Object.fromEntries(
          Object.entries(state.filesLoadParams).map(([key, params]) => [
            key,
            { ...params, error: false },
          ])
        )
      : null
  },
  resetFiles(state) {
    state.files = null
  },
}

export default mutations
