/* eslint-disable camelcase */
import { axiosJWT } from '@plugins/axios'
import { Chat } from '@common-types/chat'
import { DataType, GetParams } from './types'

export default {
  getApps: async (params: GetParams): Promise<DataType[]> => {
    const { data }: ResType<DataType[]> = await axiosJWT.get('apps', { params })
    return data === null ? [] : data
  },
  getApp: async (id: string): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.get(`apps/${id}`)
    return data
  },
  putApp: async (id: string, app: DataType): Promise<DataType> => {
    const { data }: ResType<DataType> = await axiosJWT.put(`apps/${id}`, app)
    return data
  },
  postAppSubscription: async (app_id: string): Promise<ResType<DataType>> => {
    const { data, access_token }: ResType<DataType> = await axiosJWT.post('app_subscriptions', {
      app_id,
    })
    return { data, access_token }
  },
  deleteAppSubscription: async (sub_id: string): Promise<ResType<DataType>> => {
    const { success, access_token, data }: ResType<DataType> = await axiosJWT.delete(
      `app_subscriptions/${sub_id}`
    )
    return { success, access_token, data }
  },
  getAppSupportChatExistence: async (appId: string): Promise<Chat | boolean> => {
    const { data }: ResType<Chat> = await axiosJWT.get(`apps/${appId}/chat`)
    return data
  },
  getChatAccessibleCode: async (appId: string): Promise<string> => {
    const { data }: ResType<string> = await axiosJWT.get(`apps/${appId}/chat-accessible-code`)
    return data
  },
}
