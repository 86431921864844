/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 16,
    height: 16,
    viewBox: '0 0 64 64',
    data: '<rect pid="0" height="64" rx="11.2" ry="11.2" width="64" _fill="#385997"/><path pid="1" d="M54.4 29.6h-10V22c0-1.86 2.14-2 2.8-2H54V9.6h-6.8C39.67 9.6 34 14.93 34 22v7.6h-8.4V40H34v24h10.4V40h8.4z" _fill="#fff"/>'
  }
})
