<template>
  <div v-show="!hidden" :class="$style.opportunity">
    <icon :class="$style.icon" :name="icon" width="14" height="14" />
    <div :class="$style.desc">
      <p>{{ text }}</p>
      <p v-if="subText" :class="$style.subText">{{ subText }}</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ChatGptWelcomeOpportunity',
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    subText: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
      default: '',
    },
    hidden: Boolean,
  },
})
</script>

<style module lang="scss">
.opportunity {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 12px;
  gap: 9px;
  background: white;
  border-radius: 6px;

  .desc {
    display: flex;
    flex-direction: column;
    gap: 3px;
    font-size: 12px;
    line-height: 18px;

    .subText {
      font-size: 10px;
      line-height: 12px;
    }
  }

  svg {
    fill: $primary-light-background;
  }

  p {
    line-height: 22px;
    font-size: 14px;
  }
}
</style>
