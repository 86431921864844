// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UploadMenuDrawer_item_2Cg6X{padding:14px;background:var(--supster-secondary);box-shadow:inset 0 -1px 0 #262626}.UploadMenuDrawer_item_2Cg6X .UploadMenuDrawer_disabled_2uv0O{cursor:default;opacity:.4;pointer-events:none}.UploadMenuDrawer_item_2Cg6X.UploadMenuDrawer_hidden_2fc9p{display:none}.UploadMenuDrawer_item_2Cg6X:last-child{box-shadow:none}.UploadMenuDrawer_item_2Cg6X .UploadMenuDrawer_button_2pM3q{grid-gap:14px;grid-template-columns:auto 1fr;justify-content:flex-start!important;padding:0!important;text-align:left;border-radius:0!important;transform:none!important}.UploadMenuDrawer_item_2Cg6X .UploadMenuDrawer_icon_bvYgL{width:20px;height:20px;fill:var(--supster-primary-dark-background)}.UploadMenuDrawer_item_2Cg6X .UploadMenuDrawer_title_1nWb5{color:#fff;font-weight:500;font-size:15px;line-height:22px}", ""]);
// Exports
exports.locals = {
	"item": "UploadMenuDrawer_item_2Cg6X",
	"disabled": "UploadMenuDrawer_disabled_2uv0O",
	"hidden": "UploadMenuDrawer_hidden_2fc9p",
	"button": "UploadMenuDrawer_button_2pM3q",
	"icon": "UploadMenuDrawer_icon_bvYgL",
	"title": "UploadMenuDrawer_title_1nWb5"
};
module.exports = exports;
