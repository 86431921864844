// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProfileTabs_section_1jtCU{flex:1;display:flex;flex-direction:column}.ProfileTabs_content_udZc7{position:relative;width:100%;min-height:100%;background:#f8f8f8;transition:background .3s}.ProfileTabs_prelaunch_37yPZ{flex:1}.ProfileTabs_tabs_2qknY{background-color:#fff;border-top:1px solid #e5e3e3}.ProfileTabs_activation_15hGL{padding:0 6px 6px}", ""]);
// Exports
exports.locals = {
	"section": "ProfileTabs_section_1jtCU",
	"content": "ProfileTabs_content_udZc7",
	"prelaunch": "ProfileTabs_prelaunch_37yPZ",
	"tabs": "ProfileTabs_tabs_2qknY",
	"activation": "ProfileTabs_activation_15hGL"
};
module.exports = exports;
