// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SearchPostsBlock_container_18jFY{height:315px;overflow:hidden;grid-template-rows:repeat(2,auto);display:grid;gap:1px;grid-template-columns:1fr 1fr 1fr}.SearchPostsBlock_container_18jFY.SearchPostsBlock_bigLeft_16J8I{grid-template-columns:1fr 2fr;grid-auto-flow:column}.SearchPostsBlock_container_18jFY.SearchPostsBlock_bigLeft_16J8I .SearchPostsBlock_post_20T4j:last-child{grid-row:1/3}.SearchPostsBlock_container_18jFY.SearchPostsBlock_bigRight_3nwLo{grid-template-columns:2fr 1fr}.SearchPostsBlock_container_18jFY.SearchPostsBlock_bigRight_3nwLo .SearchPostsBlock_post_20T4j:first-child{grid-row:1/3}", ""]);
// Exports
exports.locals = {
	"container": "SearchPostsBlock_container_18jFY",
	"bigLeft": "SearchPostsBlock_bigLeft_16J8I",
	"post": "SearchPostsBlock_post_20T4j",
	"bigRight": "SearchPostsBlock_bigRight_3nwLo"
};
module.exports = exports;
