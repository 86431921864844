<template>
  <v-input :disabled="disabled" :error="error" :error-to-show="error" type="auth">
    <div :class="mode && $style[mode]">
      <label :class="[$style['inner-label'], isNewPassword && $style.isNewPassword]">{{
        title
      }}</label>
      <input
        :placeholder="placeholder"
        v-model="input"
        :type="type"
        :class="[
          $style.input,
          error && $style.inputError,
          mode && $style[mode],
          shadow && $style.shadow,
        ]"
      />
    </div>
  </v-input>
</template>
<script>
import Vue from 'vue'
import validate from '@utils/validate'
import VInput from '@elements/inputs/VInput.vue'

export default Vue.extend({
  name: 'AuthInput',
  components: {
    VInput,
  },
  props: {
    title: String,
    placeholder: String,
    value: String,
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: Array,
      default: () => [],
    },

    disabled: Boolean,
    mode: {
      type: String,
      default: '',
    },
    shadow: Boolean,
    isNewPassword: Boolean,
    forseError: String,
  },
  data() {
    return {
      error: '',
    }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  async mounted() {
    this.input = this.value
  },
  methods: {
    async validate() {
      this.error = await validate(this.rules, this.value)

      return this.error
    },
  },
  watch: {
    forseError() {
      if (this.forseError) {
        this.error = this.forseError
      } else {
        this.error = ''
      }
    },
  },
})
</script>
<style lang="scss" module>
.input {
  width: 100%;
  padding: 11px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  word-wrap: break-word;
  border: 1px solid $secondary-light;
  border-radius: 6px;
  outline: none;
  transition: border $transition;

  &::placeholder {
    color: rgba(black, 0.5);
    font-size: 16px;
  }

  &Error {
    border: 1px solid $error;
  }

  &.inner-title {
    background: transparent;
    border: 0;
  }

  &.shadow {
    background: $shadow;
  }
}

.inner-title {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  background: $shadow;
  border: 1px solid $secondary-light;

  .inner-label {
    min-width: 70px;
    color: rgba(black, 0.5);
    font-size: 14px;
    white-space: nowrap;
    &.isNewPassword {
      min-width: 110px;
    }
  }
}
</style>
