export enum CountryCodes {
  RU = 'RU',
  IN = 'IN',
  EN = 'EN',
  DEFAULT = 'DEFAULT',
}

export const countryCodeValues = Object.values(CountryCodes)

export default {
  CountryCodes,
}
