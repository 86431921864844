/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'multi-content-white': {
    width: 30,
    height: 29,
    viewBox: '0 0 30 29',
    data: '<g filter="url(#filter0_d_3203_596)"><path pid="0" d="M12 20h8v1c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V11c0-1.1.9-2 2-2h1v8c0 1.65 1.35 3 3 3zM22 5H12c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2z" _fill="#fff" fill-opacity=".85"/></g><defs><filter id="filter0_d_3203_596" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_3203_596"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_3203_596" result="shape"/></filter></defs>'
  }
})
