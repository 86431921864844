import { LandingType, TestCode } from '@services/app/types'
import { checker } from '@helpers/index'

export const isSplitTestApp = checker(TestCode.OPEN_PROMO_IMMEDIATELY)

export const isSplitTestApp2 = checker(TestCode.OPEN_PROMO_AFTER_FIRST_MESSAGE_SEND)

export const isSplitTestApp3 = checker(TestCode.DEFAULT_WITH_NEW_PROMPT)

export const isSplitTestApp4 = checker(TestCode.DEFAULT_WITH_CHATS_LIST)

export const isSplitTestApp5 = checker(TestCode.DEFAULT_WITH_OTHER_WELCOME_MESSAGE)
export const isSplitTestApp6 = checker(TestCode.BLOGGER_COURSES)

export const isLandingMode = checker(LandingType.INTRODUCTION)
export default {
  isSplitTestApp,
  isSplitTestApp2,
}
