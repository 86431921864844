import Vue from 'vue'
import { PostIds } from '@common-types/post'
import PostsCache from '@modules/search/modules/posts/services/cache'
import { Mutations } from '../types'

const mutations: Mutations = {
  addComponentHeight(state, { postId, height }) {
    Vue.set(state.componentsHeights, postId, height)
  },
  setStartIndex(state, startIndex) {
    state.startIndex = startIndex
  },
  setVisiblePosts(state, postIds: PostIds) {
    state.visiblePosts = PostsCache.getItems(postIds)
  },
}

export default mutations
