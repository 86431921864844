<template>
  <div :class="$style.section">
    <div :class="$style.wrap">
      <p
        :class="[$style.content, $style[size], isOpen && $style.contentOpen, isEdit && $style.edit]"
      >
        {{ content }}
      </p>
      <div :class="$style['btns-wrap']">
        <slot />
        <div v-if="isVisibleDropdown">
          <v-button
            size="xl"
            color="transparent"
            :class="[$style.button, className]"
            :is-disabled="isDisabled"
            is-disabled-padding
            @click="isOpen = !isOpen"
          >
            <span :class="[$style.name, $style[size]]">
              {{ $t(`${name}.${isOpen ? 'open' : 'close'}`) }}</span
            >
            <icon :class="[$style.icon, isOpen && $style.iconTransform]" name="arrow-bottom" />
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'VReadMore',
  components: { VButton },
  props: {
    isEdit: Boolean,
    size: String,
    name: String,
    className: String,
    btnWrapClass: String,
    width: {
      default: 768,
      type: Number,
    },
    content: {
      required: true,
      type: String,
      default: '',
    },
    isDisabled: Boolean,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    isVisibleDropdown() {
      return this.width / 5 < this.content?.length || /[\n\r\f\v]{1,}/.test(this.content)
    },
  },
})
</script>
<style lang="scss" module>
.section {
  width: 100%;
}

.wrap {
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 16px;
}

.btns-wrap {
  display: grid;
  grid-column-gap: 21px;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.content {
  height: 100%;
  max-height: 33px;
  overflow: hidden;
  color: black;
  white-space: pre-line;
  text-align: left;
  overflow-wrap: anywhere;
  transition: max-height 0.2s ease;
  &.edit {
    color: $secondary-medium;
  }
  &Open {
    max-height: 100vh;
    transition: max-height 0.5s ease;
  }

  &.sm {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }

  &.md {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  &.lg {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  &.xl {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
}

.button {
  justify-content: flex-start;
  column-gap: 9px;
  border-radius: 0 !important;

  &:hover {
    transform: scale(1);
    opacity: 0.7;
  }
}

.name {
  display: grid;
  color: $secondary;
  font-weight: 400;
  white-space: initial;
  overflow-wrap: anywhere;
  column-gap: 9px;

  &.sm {
    font-size: 10px;
    line-height: 14px;
  }

  &.md {
    font-size: 12px;
    line-height: 18px;
  }

  &.lg {
    font-size: 14px;
    line-height: 18px;
  }

  &.xl {
    font-size: 16px;
    line-height: 20px;
  }
}

.icon {
  align-self: center;
  justify-self: flex-start;
  width: 7px;
  height: 4px;
  transform: rotate(0);
  transition: transform 0.5s;
  fill: $secondary;

  &Transform {
    transform: rotate(-180deg);
  }
}
</style>
