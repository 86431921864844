<template>
  <picture :class="[$style.container, !src && $style.noImage]" v-on="$listeners">
    <source :srcset="srcset" type="image/webp" />
    <img
      v-if="src"
      :class="$style.image"
      :src="srcset || src"
      :alt="alt"
      draggable="false"
      decoding="auto"
      v-on="$listeners"
    />
    <slot v-else-if="!src && preview" name="preview"></slot>
    <icon v-else name="camera" :class="$style.icon" />
    <slot name="content" />
  </picture>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VImage',
  props: {
    src: String,
    srcset: [String, null],
    preview: {
      type: String,
      default: 'camera',
    },
    alt: {
      type: String,
      default: 'Image',
    },
  },
})
</script>
<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: $shadow-light-2;

  &.noImage {
    background-color: $grey;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icon {
  width: 40px;
  color: $secondary-medium;
}
</style>
