import {
  removeThreads,
  increaseThreadPage,
  putThreadProperties,
  setThreadIsVisible,
} from '../services/threads-map'
import { Mutations } from '../types'

const mutations: Mutations = {
  setThreadIsVisible(state, parentId) {
    state.threadsMap = setThreadIsVisible({
      threadsMap: state.threadsMap,
      parentId,
    })
  },
  setThreadProperties(state, { parentId, ...properties }) {
    state.threadsMap = putThreadProperties({
      threadsMap: state.threadsMap,
      parentId,
      ...properties,
    })
  },
  removeThreads(state, parentIds) {
    state.threadsMap = removeThreads({
      threadsMap: state.threadsMap,
      parentIds,
    })
  },
  increaseThreadPage(state, parentId) {
    state.threadsMap = increaseThreadPage({
      threadsMap: state.threadsMap,
      parentId,
    })
  },
  setThreadPage(state, { parentId, page }) {
    state.threadsMap = putThreadProperties({
      threadsMap: state.threadsMap,
      parentId,
      page,
    })
  },
  setAddedSubCommentsCount(state, { parentId, addedSubCommentsCount }) {
    state.threadsMap = putThreadProperties({
      threadsMap: state.threadsMap,
      parentId,
      addedSubCommentsCount,
    })
  },
  setDeletedSubCommentsCount(state, { parentId, deletedSubCommentsCount }) {
    const updatedThreadsMap = putThreadProperties({
      threadsMap: state.threadsMap,
      parentId,
      deletedSubCommentsCount,
    })
    state.threadsMap = updatedThreadsMap
  },
}

export default mutations
