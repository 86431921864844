// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChatGptWelcomeOpportunity_opportunity_2mQ9x{display:flex;flex-direction:row;align-items:center;padding:9px 12px;gap:9px;background:#fff;border-radius:6px}.ChatGptWelcomeOpportunity_opportunity_2mQ9x .ChatGptWelcomeOpportunity_desc_1BDJw{display:flex;flex-direction:column;gap:3px;font-size:12px;line-height:18px}.ChatGptWelcomeOpportunity_opportunity_2mQ9x .ChatGptWelcomeOpportunity_desc_1BDJw .ChatGptWelcomeOpportunity_subText_2HIZ3{font-size:10px;line-height:12px}.ChatGptWelcomeOpportunity_opportunity_2mQ9x svg{fill:var(--supster-primary-light-background)}.ChatGptWelcomeOpportunity_opportunity_2mQ9x p{line-height:22px;font-size:14px}", ""]);
// Exports
exports.locals = {
	"opportunity": "ChatGptWelcomeOpportunity_opportunity_2mQ9x",
	"desc": "ChatGptWelcomeOpportunity_desc_1BDJw",
	"subText": "ChatGptWelcomeOpportunity_subText_2HIZ3"
};
module.exports = exports;
