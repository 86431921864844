import { DataType as Promotional } from '@services/promotional/types'
import { isRejected } from '@views/subscriptions/services/supscription'

export const alreadyHadSubscribed = (promotional: Promotional): boolean => {
  return promotional.tariffs.some((tariff) => {
    const { status, status_recurrent: statusRecurrent } = tariff.my_subscription ?? {}
    return isRejected({ status, statusRecurrent })
  })
}

export default {
  alreadyHadSubscribed,
}
