import { RouteConfig } from 'vue-router'

const subPages: Array<RouteConfig> = [
  {
    path: 'settings',
    name: 'settings',
    meta: { requiresAuth: true },
    component: () => import('@views/settings/Settings.vue'),
  },
  {
    path: 'notifications',
    name: 'notifications',
    meta: { requiresAuth: true },
    component: () => import('@views/settings/Notifications.vue'),
  },
]

export default subPages
