import axiosLib, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import * as Sentry from '@sentry/vue'

const instance = axiosLib.create({
  baseURL: `/api/app/v1/`,
  headers: { 'Content-Type': 'application/json' },
})

const instanceJWT = axiosLib.create({
  baseURL: `/api/app/v1/`,
  headers: { 'Content-Type': 'application/json' },
})

const instanceBilling = axiosLib.create({
  baseURL: `${process.env.VUE_APP_API_BILLING_URL}/`,
  headers: { 'Content-Type': 'application/json' },
})

const isAuthError = (error: AxiosError) => error.response?.status === 401
const isLoggedIn = () => instanceJWT.prototype.$store.getters['Me/isLoggedIn']
const getAccessToken = () => instanceJWT.prototype.$store.getters['Me/accessToken']

const responseFulfilled = (response: AxiosResponse) => {
  return response?.data
}

function responseRejected(error: AxiosError) {
  Sentry.setTag('n-type', 'response')
  Sentry.captureException(error)
  return !error
    ? Promise.reject()
    : error.response
    ? Promise.reject(error.response)
    : Promise.reject(error)
}

async function requestFulfilledJWT(config: AxiosRequestConfig) {
  if (isLoggedIn()) {
    config.headers.Authorization = `Bearer ${getAccessToken()}`
  }

  return config
}

async function responseRejectedJWT(error: AxiosError) {
  Sentry.setTag('n-type', 'responseJWT')
  Sentry.captureException(error)
  return !error
    ? Promise.reject()
    : !error.response
    ? Promise.reject(error)
    : isAuthError(error) && isLoggedIn()
    ? instanceJWT(error.config)
    : Promise.reject(error.response)
}

instance.interceptors.response.use(responseFulfilled, responseRejected)

instanceJWT.interceptors.request.use(requestFulfilledJWT)
instanceJWT.interceptors.response.use(responseFulfilled, responseRejectedJWT)

instanceBilling.interceptors.request.use(requestFulfilledJWT)
instanceBilling.interceptors.response.use(responseFulfilled, responseRejectedJWT)

export const axios: AxiosInstance = instance
export const axiosJWT: AxiosInstance = instanceJWT
export const axiosBilling: AxiosInstance = instanceBilling
