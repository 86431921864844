<template>
  <ul :class="$style.list">
    <li
      v-for="({ name, title, icon, disabled, color }, index) of visibleList"
      :key="`list-${index}`"
      :class="[
        $style.item,
        disabled && $style.disabled,
        color && $style[color],
        isChecked(name) && $style.checked,
      ]"
      @click="$emit('click', name)"
    >
      <div
        :class="[$style.content, disabled && $style.disabled, isChecked(name) && $style.checked]"
      >
        <icon v-if="icon" :class="$style.icon" :name="icon" />
        <h3 :class="$style.title">{{ title }}</h3>
        <icon v-if="isChecked(name)" :class="$style.icon" name="check" width="19" height="13" />
      </div>
    </li>
  </ul>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VList',
  props: {
    list: {
      type: Array,
      require: true,
    },
    checkedName: {
      type: String,
      default: '',
    },
  },
  computed: {
    visibleList() {
      return this.list?.filter(({ hidden }) => !hidden)
    },
  },

  methods: {
    isChecked(name) {
      return !!this.checkedName && !!name && this.checkedName === name
    },
  },
})
</script>
<style lang="scss" module>
.list {
  display: grid;
  grid-auto-rows: min-content;
  grid-auto-flow: row;
  width: 100%;
  height: 100%;
}

.item {
  padding: 17px 15px;
  color: $secondary;
  box-shadow: inset 0 -1px 0 $secondary-light;
  cursor: pointer;
  transition: opacity $transition;

  &:hover {
    opacity: 0.6;
  }

  &.disabled {
    cursor: not-allowed;

    &:hover {
      opacity: 1;
    }
  }

  &.checked {
    background-color: $primary;
    color: $main-text-color;
  }

  &.red {
    color: $error;
  }

  &.grey {
    color: white;
    background: $grey-dark;
  }
}

.content {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;

  &.disabled {
    opacity: 0.2;
  }

  &.checked {
    grid-template-columns: 1fr auto;
  }
}

.image {
  align-self: center;
}

.icon {
  align-self: center;
  width: 30px;
  height: 30px;
}

.title {
  align-self: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
}
</style>
