// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes ContentLoader_rotateLeft_MXvns{0%{transform:rotate(0deg)}to{transform:rotate(1turn);transform-origin:-50% -50%}}@keyframes ContentLoader_rotateRight_1YC7G{0%{transform:rotate(0deg)}to{transform:rotate(1turn);transform-origin:150% 150%}}.ContentLoader_loader_31jTR{filter:url(#goo)}.ContentLoader_block_2VvP8{position:absolute;background-color:var(--supster-primary);border-radius:100%;will-change:all;-webkit-mask-image:radial-gradient(#fff,#000);mask-image:radial-gradient(#fff,#000)}.ContentLoader_blockFilter_27tL0{filter:url(#goo)}.ContentLoader_blockLeft_3rwJm{animation:ContentLoader_rotateLeft_MXvns 1.2s cubic-bezier(.35,0,.25,1) infinite}.ContentLoader_blockRight_YFnuA{animation:ContentLoader_rotateRight_1YC7G 1.2s cubic-bezier(.35,0,.25,1) infinite}", ""]);
// Exports
exports.locals = {
	"loader": "ContentLoader_loader_31jTR",
	"block": "ContentLoader_block_2VvP8",
	"blockFilter": "ContentLoader_blockFilter_27tL0",
	"blockLeft": "ContentLoader_blockLeft_3rwJm",
	"rotateLeft": "ContentLoader_rotateLeft_MXvns",
	"blockRight": "ContentLoader_blockRight_YFnuA",
	"rotateRight": "ContentLoader_rotateRight_1YC7G"
};
module.exports = exports;
