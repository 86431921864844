/* eslint-disable camelcase */
import { DataType as CurrencyType } from '@services/currency/types'
import { MediaObject } from '@services/media/types'
import { DataType as UserType } from '@services/user/types'

export interface AppBlockDefinition {
  id: string
}

export enum AppMode {
  DEFAULT = 'default',
  CHAT_GPT = 'chat_gpt',
}

export enum TestCode {
  DEFAULT = 0,
  OPEN_PROMO_IMMEDIATELY = 1,
  OPEN_PROMO_AFTER_FIRST_MESSAGE_SEND = 2,
  DEFAULT_WITH_NEW_PROMPT = 3,
  DEFAULT_WITH_CHATS_LIST = 4,
  DEFAULT_WITH_OTHER_WELCOME_MESSAGE = 5,
  BLOGGER_COURSES = 6,
}

export enum LandingType {
  DEFAULT = 0,
  INTRODUCTION = 1,
}

export interface DataType {
  comment_count: number
  currency: CurrencyType
  description: string
  follower_count: number
  header_images: MediaObject[]
  i_blocked_app: AppBlockDefinition | null
  id: string
  image: MediaObject
  like_count: number
  my_app_subscription: {
    id: number
  } | null
  name: string | null
  original_id: number
  personal_chat_avatar: MediaObject | null
  personal_chat_name: string | null
  post_count: number
  primary_color: string | null
  status: number
  title: string | null
  user: UserType
  user_id: number
  type: AppMode
  test_code: TestCode
  landing_type: LandingType
  prompts: string[]
}

interface GetParamsType {
  Authorization?: string
  search?: string
  subscribed?: boolean
  own?: boolean
}

export type GetParams = (GetParamsType & ReqParamsType) | null
